type TEventCallback = (...params: any) => void;

interface IEvent {
  callback: TEventCallback;
}

interface IEvents {
  [key: string]: IEvent[];
}

const events: IEvents = {};
const eventsOnce: IEvents = {};

function trigger(eventsToTrigger: IEvent[], params: any[]) {
  eventsToTrigger.forEach((event) => {
    event.callback(params as []);
  });
}

function on(name: string, callback: TEventCallback) {
  (events[name] = events[name] || []).push({ callback });
}

function once(name: string, callback: TEventCallback) {
  (eventsOnce[name] = eventsOnce[name] || []).push({ callback });
}

function off(name: string, callback: TEventCallback) {
  if (!events[name]) {
    return;
  }

  if (callback) {
    events[name].forEach((event, index) => {
      if (event.callback === callback) {
        events[name].splice(index, 1);
      }
    });
  } else {
    delete events[name];
  }
}

function emit(name: string, ...params: any[]) {
  // regular event callbacks
  if (name in events) {
    trigger(events[name], params);
  }

  // once event callbacks
  if (name in eventsOnce) {
    trigger(eventsOnce[name], params);
    delete eventsOnce[name];
  }
}

export default {
  emit,
  off,
  on,
  once,
};
