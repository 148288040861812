import { Tooltip } from '@dna-script-inc/shared-ui-library';
import Typography from '@mui/material/Typography';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';
import { TRunInProject } from 'src/types';

type TAssignedInstrument = NonNullable<TRunInProject['assignedInstrument']>;

function useGetAssignedInstrumentColumn<
  T extends {
    assignedInstrument?: TAssignedInstrument | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Cell: ({ renderedCellValue }) => (
      <Tooltip placement="bottom-start" title={renderedCellValue}>
        <Typography overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <span>{renderedCellValue}</span>
        </Typography>
      </Tooltip>
    ),
    Header: () => <Translate id="projects.detail.table.runs.assignedInstrument" testid="instrument-header" />,
    accessorFn: (row) => row.assignedInstrument?.name ?? '',
    header: t('projects.detail.table.runs.assignedInstrument'),
    muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.assignedInstrument.filter') },
    size: 150,
  };
}

export default useGetAssignedInstrumentColumn;
