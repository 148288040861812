import { graphql } from 'src/gql';

export const SERVICE_INFO_FIELDS_FRAGMENT = graphql(`
  fragment ServiceInfoFields on ServiceInfoDTO {
    server
    client
    connection
    status
    message
    type
  }
`);

export const QUERY_ALL_SYSTEM_INFO = graphql(`
  query SystemInfo {
    systemInfo {
      ...ServiceInfoFields
    }
  }
`);

export const QUERY_REDBOX_VERSION = graphql(`
  query RedboxVersion {
    getRedboxVersion
  }
`);

export const QUERY_SUPPORTED_INSTRUMENT_VERSIONS = graphql(`
  query SupportedInstrumentVersions {
    getSupportedInstrumentVersions
  }
`);

export const QUERY_GET_TIMEZONES = graphql(`
  query GetAvailableTimeZones {
    getAvailableTimeZones {
      id
      label
      offset
    }
  }
`);

export const QUERY_DESIGN_CONTEXT_SWITCH = graphql(`
  query DesignContextSwitch {
    designContextSwitch
  }
`);
