import { MRT_ColumnDef } from 'material-react-table';

import useGetAssignedInstrumentColumn from 'src/components/table/columns/useGetAssignedInstrumentColumn';
import useGetCreatorColumn from 'src/components/table/columns/useGetCreatorColumn';
// eslint-disable-next-line max-len
import useGetCustomProcessDefinitionFileColumn from 'src/components/table/columns/useGetCustomProcessDefinitionFileColumn';
import useGetDesignIdColumn from 'src/components/table/columns/useGetDesignIdColumn';
import useGetEndDateColumn from 'src/components/table/columns/useGetEndDateColumn';
import useGetIdColumn from 'src/components/table/columns/useGetIdColumn';
import useGetKitTypeColumn from 'src/components/table/columns/useGetKitTypeColumn';
import useGetNameColumn from 'src/components/table/columns/useGetNameColumn';
import useGetPassingPercentageColumn from 'src/components/table/columns/useGetPassingPercentageColumn';
import useGetRunStatusColumn from 'src/components/table/columns/useGetRunStatusColumn';
import useGetScheduledStartDateColumn from 'src/components/table/columns/useGetScheduledStartDateColumn';
import useGetSizeRangeColumn from 'src/components/table/columns/useGetSizeRangeColumn';
import useGetUpdatedAtColumn from 'src/components/table/columns/useGetUpdatedAtColumn';
import { useUserContext } from 'src/hooks';
import { TRunInProject } from 'src/types';

function useGetProjectRunsColumns(creators: string[]) {
  const kitTypeColumn = useGetKitTypeColumn<TRunInProject>();
  const creatorColumn = useGetCreatorColumn<TRunInProject>(creators);
  const nameColumn = useGetNameColumn<TRunInProject>({
    filterTitle: 'projects.detail.table.runs.name.filter',
    headerTitle: 'projects.detail.table.runs.name',
  });
  const parentIdnaColumn = useGetDesignIdColumn<TRunInProject>();
  const customProcessDefinitionFileColumn = useGetCustomProcessDefinitionFileColumn<TRunInProject>();
  const runStatusColumn = useGetRunStatusColumn<TRunInProject>();
  const assignedInstrumentColumn = useGetAssignedInstrumentColumn<TRunInProject>();
  const runIdColumn = useGetIdColumn<TRunInProject>({
    filterTitle: 'projects.detail.table.runs.id.filter',
    headerTitle: 'projects.detail.table.runs.id',
  });
  const passingPercentageColumn = useGetPassingPercentageColumn<TRunInProject>();
  const sizeRangeColumn = useGetSizeRangeColumn<TRunInProject>();
  const scheduledStartDateColumn = useGetScheduledStartDateColumn<TRunInProject>();
  const endDateColumn = useGetEndDateColumn<TRunInProject>();
  const updatedAtColumn = useGetUpdatedAtColumn<TRunInProject>();

  const columns: MRT_ColumnDef<TRunInProject>[] = [
    nameColumn,
    runStatusColumn,
    kitTypeColumn,
    parentIdnaColumn,
    customProcessDefinitionFileColumn,
    creatorColumn,
    assignedInstrumentColumn,
    runIdColumn,
    passingPercentageColumn,
    sizeRangeColumn,
    scheduledStartDateColumn,
    endDateColumn,
    updatedAtColumn,
  ];
  const { organization } = useUserContext();
  return columns.filter((x) => x !== customProcessDefinitionFileColumn || organization?.customProcessEnabled);
}

export default useGetProjectRunsColumns;
