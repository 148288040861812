import React, { FC, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { TextInput } from 'src/components/form';
import { useTranslation } from 'src/containers/i18n';
import { getErrorForHelperText } from 'src/utils/reactHookForm';

export interface ISequenceNameProps {
  currentName?: string;
  isDisabled: boolean;
  onChangeField: (value: string) => void;
}

let changeFreezeTimeoutId = 0;
export const SequenceName: FC<ISequenceNameProps> = ({ onChangeField, currentName, isDisabled }) => {
  const {
    formState: { errors },
    control,
    reset,
  } = useFormContext<{ name: string }>();
  const t = useTranslation();

  useEffect(() => {
    reset({
      name: currentName,
    });
  }, [currentName, reset]);

  return (
    <Controller
      render={({ field }) => (
        <TextInput
          id="sequenceName"
          type="text"
          onChange={(e) => {
            const { value: _value } = e.target;
            field.onChange(e);
            clearTimeout(changeFreezeTimeoutId);
            changeFreezeTimeoutId = window.setTimeout(() => {
              onChangeField(_value);
            }, 200);
          }}
          value={field.value || ''}
          name="name"
          error={Boolean(errors.name)}
          helperText={getErrorForHelperText(errors.name)}
          label={t('runs.plateEditor.editWindow.sequenceName.title')}
          placeholder={t('runs.plateEditor.editWindow.sequenceName.placeholder')}
          disabled={isDisabled}
        />
      )}
      name="name"
      defaultValue={currentName}
      control={control}
    />
  );
};
