/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SaveModal } from '@dna-script-inc/shared-ui-library';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { NormalizationType } from '__generated__/globalTypes';
import { Checkbox, TextInput } from 'src/components/form';
import { useTranslation } from 'src/containers/i18n';
import { useKitTypeConcentrationLimits } from 'src/hooks/useKitTypeConcentrationLimits';
import { useRunContext } from 'src/pages/runs/container';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';
import { isDisabledNormalization } from 'src/utils/nucleotide';
import { getErrorForHelperText } from 'src/utils/reactHookForm';
import { shouldNotHaveOnlySpaces, validateTitle } from 'src/utils/validator';

import NormalizationTypeForm from './NormalizationTypeForm';

const CheckboxContainer = styled.div`
  margin-top: 15px;
  .MuiFormControlLabel-root {
    margin: 0;
  }
`;

const Container = styled(Grid)`
  padding: 10px;
`;

type TFormValues = { name: string };

interface IEditTemplateModalProps {
  normalization?: NormalizationType;
  onClose: () => void;
  value: string;
}

const EditTemplateModal: React.FC<IEditTemplateModalProps> = ({ onClose, value, normalization }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TFormValues>();
  const t = useTranslation();
  const { templateDetail } = useRunContext();
  const { changeTemplateSettings } = useSequenceEditorStore();

  const [normalizationType, setNormalizationValue] = useState(normalization || NormalizationType.NONE);

  const concentrationLimits = useKitTypeConcentrationLimits(templateDetail?.kitType);
  const [concentrationValue, setConcentrationValue] = useState(
    templateDetail?.targetConcentration || concentrationLimits.defaultValue,
  );

  const [shouldApplyPlateConcentration, setShouldApplyPlateConcentration] = useState(
    templateDetail?.sequences?.every((x) => x.usePlateNorm) || false,
  );

  useEffect(() => {
    setShouldApplyPlateConcentration(templateDetail?.sequences?.every((x) => x.usePlateNorm) || false);
  }, [setShouldApplyPlateConcentration, templateDetail?.sequences]);

  const handleConcentrationChange = (val: number) => {
    setConcentrationValue(val);
  };

  const handleNormalizationChange = (event: { target: { value: NormalizationType } }) => {
    setNormalizationValue(event.target.value);
  };

  const onUpdate = (data: TFormValues) => {
    const { name } = data;
    changeTemplateSettings({
      normalizationType,
      targetConcentration: normalizationType === NormalizationType.TARGET ? concentrationValue : undefined,
      templateName: name.trim(),
      usePlateNorm: shouldApplyPlateConcentration,
    });
    onClose();
  };

  const nameRegister = register('name', {
    maxLength: {
      message: t('general.form.validation.name.maxLength', {
        label: t('plates.detailsPage.details.modal.name'),
        length: 120,
      }),
      value: 120,
    },
    required: t('general.form.validation.fields.required'),
    validate: {
      shouldNotHaveOnlySpaces,
      validateTitle: (val: string) => validateTitle(val) || t('runs.errors.invalidCharacter'),
    },
  });

  return (
    <SaveModal
      isOpen
      onReject={onClose}
      onSubmit={handleSubmit(onUpdate)}
      title={t('plates.detailsPage.details.modal.title')}
      rejectButtonTitle={t('plates.detailsPage.buttons.cancel')}
      submitButtonTitle={t('plates.detailsPage.buttons.update')}
    >
      <Container>
        <TextInput
          variant="outlined"
          fullWidth
          label={t('plates.detailsPage.details.modal.name')}
          defaultValue={value}
          error={Boolean(errors.name)}
          helperText={getErrorForHelperText(errors.name)}
          inputRef={nameRegister.ref}
          {...nameRegister}
        />
        <Grid container direction="column" item xs={12}>
          <Grid item xs={12}>
            <CheckboxContainer>
              <Checkbox
                label="runs.plateEditor.editWindow.sequenceField.additional.title"
                checked={shouldApplyPlateConcentration}
                onChange={(e) => setShouldApplyPlateConcentration(e.target.checked)}
              />
            </CheckboxContainer>
            <NormalizationTypeForm
              kitType={templateDetail?.kitType}
              concentrationValue={concentrationValue}
              normalizationType={normalizationType}
              handleConcentrationChange={handleConcentrationChange}
              handleNormalizationChange={handleNormalizationChange}
              disabled={!shouldApplyPlateConcentration}
              disableConcentration={
                isDisabledNormalization(normalizationType) || normalizationType === NormalizationType.LOWEST
              }
            />
          </Grid>
        </Grid>
      </Container>
    </SaveModal>
  );
};

export default EditTemplateModal;
