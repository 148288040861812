import { NAME_REGEX_PATTER } from 'src/config/constants';
import { translate } from 'src/containers/i18n/I18nContainer';

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validateEmail = (email: string): boolean => {
  const re = EMAIL_PATTERN;
  return re.test(email);
};

export const validateHasLowercaseLetter = (value: string): boolean => {
  return /[a-z]/.test(value);
};

export const validateHasUppercaseLetter = (value: string): boolean => {
  return /[A-Z]/.test(value);
};

export const validateHasNumber = (value: string): boolean => {
  return /\d/.test(value);
};

export const validateHasSpecialCharacter = (value: string): boolean => {
  return /[~!@#$%^&*)=(_+-]/.test(value);
};

export const validateMinLength = (value: string, minLength: number): boolean => value.length < minLength;

export const validateTitle = (value: string) => {
  return NAME_REGEX_PATTER.test(value);
};

export const validateProjectName = (value: string) => {
  return (value?.trim().length ?? 0) > 0;
};

export const shouldNotHaveOnlySpaces = (value: string) =>
  value.trim() ? undefined : translate('general.form.validation.fields.required');

export const getValidateMaxNameLength = (maxLength: number) => (value: string) => {
  return value.length > maxLength ? 'general.form.validation.name.maxLength' : undefined;
};
