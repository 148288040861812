import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import RestrictedRoute from 'src/components/routes/RestrictedRoute';
import { ERoutes } from 'src/config/routes';
import { ApplicationProvider } from 'src/containers/application';
import { useUserContext } from 'src/hooks';
import NotFoundPage from 'src/pages/notFound';
import SequenceFileRouter from 'src/pages/runs';
import { RunDetailsPage } from 'src/pages/runs/pages/RunDetailsPage/RunDetailsPage';

import { ProjectDetailsPage, ProjectsPage } from './pages';

const GeneProjectRoutes: React.FC = () => {
  const { userPermissions } = useUserContext();
  const {
    pages,
    project: { canViewProjectDetails },
  } = userPermissions;
  return (
    <Switch>
      <RestrictedRoute exact path={ERoutes.APPLICATIONS__PROJECTS__DESIGN} accessible={pages.canViewProjectsSection}>
        <ProjectsPage isDesignProjects />
      </RestrictedRoute>
      <RestrictedRoute
        exact
        accessible={canViewProjectDetails}
        path={ERoutes.APPLICATIONS__PROJECTS__DESIGN__projectId__RUNS__TEMPLATE__templateId}
        component={SequenceFileRouter}
      />
      <RestrictedRoute
        exact
        accessible={canViewProjectDetails}
        path={ERoutes.APPLICATIONS__PROJECTS__DESIGN__projectId__RUNS__TEMPLATE__templateId__DETAILS}
        component={SequenceFileRouter}
      />
      <RestrictedRoute
        exact
        accessible={canViewProjectDetails}
        path={ERoutes.APPLICATIONS__PROJECTS__DESIGN__projectId__REPORT__id}
        render={() => (
          <ApplicationProvider>
            <RunDetailsPage />
          </ApplicationProvider>
        )}
      />
      <RestrictedRoute
        exact
        accessible={canViewProjectDetails}
        path={ERoutes.APPLICATIONS__PROJECTS__DESIGN__DETAILS__id}
        component={ProjectDetailsPage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default GeneProjectRoutes;
