import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import { useToast } from 'src/containers/toast';
import { MUTATION_RESTORE_TEMPLATE } from 'src/services/gql/mutations/templates';
import { QUERY_ALL_TEMPLATES, QUERY_ARCHIVED_TEMPLATES, QUERY_MY_TEMPLATES } from 'src/services/gql/queries/templates';
import { getErrorMessage } from 'src/utils/errors';
import { isNotEmpty } from 'src/utils/ui';

import { useGetSequenceQueryFilters } from './useGetSequenceQueryFilters';

function useRestoreTemplate(templateId: string) {
  const showToast = useToast();

  const filter = useGetSequenceQueryFilters();

  const refetchQueries = useMemo(() => {
    const queries = [
      'ProjectDetail',
      {
        query: QUERY_MY_TEMPLATES,
        variables: { filter },
      },
      {
        query: QUERY_ALL_TEMPLATES,
        variables: { filter },
      },
      {
        query: QUERY_ARCHIVED_TEMPLATES,
        variables: { filter },
      },
    ];
    return queries.filter(isNotEmpty);
  }, [filter]);

  const [restoreTemplate] = useMutation(MUTATION_RESTORE_TEMPLATE, {
    awaitRefetchQueries: true,
    onCompleted() {
      showToast({
        textToTranslate: 'templates.toast.restore.success.text',
        title: 'common.toast.success.title',
      });
    },
    onError(e) {
      showToast({
        isError: true,
        text: getErrorMessage(e),
      });
    },
    refetchQueries,
    variables: {
      id: templateId,
    },
  });

  return restoreTemplate;
}

export default useRestoreTemplate;
