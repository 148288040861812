import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo } from 'react';

import { KitTypeChip } from 'src/components/KitTypeChip';
import { Translate, useTranslation } from 'src/containers/i18n';
import { KitType } from 'src/gql/graphql';
import { getKitTypeLabelByValue } from 'src/utils/runs';

type TKitType = {
  kitType?: KitType | null;
};

function useGetKitTypeColumn<T extends TKitType>() {
  const t = useTranslation();

  const column: MRT_ColumnDef<T> = useMemo(
    () => ({
      Cell: ({ row }) => <KitTypeChip type={row.original.kitType as KitType} />,
      Header: () => <Translate id="projects.detail.table.printJobs.kitType" testid="kit-header" />,
      accessorFn: (row) => row?.kitType,
      enableGlobalFilter: false,
      filterSelectOptions: Object.values(KitType).map((k) => ({ text: getKitTypeLabelByValue(k), value: k })),
      filterVariant: 'multi-select',
      header: t('projects.detail.table.printJobs.kitType'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.printJobs.kitType.filter') },
      sortingFn: (rowA, rowB, columnId) => {
        const rowALabel = rowA.getValue(columnId) ? getKitTypeLabelByValue(rowA.getValue(columnId)) : '';
        const rowBLabel = rowB.getValue(columnId) ? getKitTypeLabelByValue(rowB.getValue(columnId)) : '';
        if (rowALabel === rowBLabel) {
          return 0;
        }
        return rowALabel > rowBLabel ? 1 : -1;
      },
    }),
    [t],
  );

  return column;
}

export default useGetKitTypeColumn;
