import Paper from '@mui/material/Paper';
import * as React from 'react';
import styled from 'styled-components';

import { FEATURE_TOGGLES } from 'src/config/constants';
import {
  REAGENT_CODE_A,
  REAGENT_CODE_C,
  REAGENT_CODE_G,
  REAGENT_CODE_SYNTH,
  REAGENT_CODE_T,
  REAGENTS_COLORS,
} from 'src/config/reagents';

const StyledPaper = styled(Paper)`
  padding: 40px 20px 30px;
  margin-top: 20px;
`;

const Title = styled.h5`
  font-weight: 500;
  margin-bottom: 20px;
`;

const Reagents = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Reagent = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: ${(props) => props.color};
`;

const Label = styled.div`
  font-size: 14px;

  > span {
    margin-left: 30px;
  }
`;

const ReagentUsage: React.FC = () => {
  if (!FEATURE_TOGGLES.REAGENTS_USE_ESTIMATE) {
    return null;
  }
  return (
    <StyledPaper>
      <Title>Reagents use estimate</Title>
      <Reagents>
        <Reagent>
          <Dot color={REAGENTS_COLORS[REAGENT_CODE_A][1]} />
          <Label>
            A<span>7%</span>
          </Label>
        </Reagent>
        <Reagent>
          <Dot color={REAGENTS_COLORS[REAGENT_CODE_C][1]} />
          <Label>
            C<span>10%</span>
          </Label>
        </Reagent>
        <Reagent>
          <Dot color={REAGENTS_COLORS[REAGENT_CODE_G][1]} />
          <Label>
            G<span>5%</span>
          </Label>
        </Reagent>
        <Reagent>
          <Dot color={REAGENTS_COLORS[REAGENT_CODE_T][1]} />
          <Label>
            T<span>6%</span>
          </Label>
        </Reagent>
        <Reagent>
          <Dot color={REAGENTS_COLORS[REAGENT_CODE_SYNTH][1]} />
          <Label>
            Cycles
            <span>96</span>
          </Label>
        </Reagent>
      </Reagents>
    </StyledPaper>
  );
};

export default ReagentUsage;
