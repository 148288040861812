import React, { useCallback, useMemo, useState } from 'react';

import { GetPlateConfigsQuery } from 'src/gql/graphql';
import { IModsAndClicksStructure } from 'src/services/gql/models/run';
import { useCurrentTemplate } from 'src/stores/sequenceEditor/hooks';

import RunContext, { IRunContext, ISuggestedSlot } from './RunContext';

interface IRunContainerProps {
  children: React.ReactNode;
  dataConfigs?: GetPlateConfigsQuery;
  modsAndClicksStructure: IModsAndClicksStructure | undefined;
  projectDetail: IProjectDetail | null;
  refetchTemplate: () => void;
}

export const RunProvider = ({
  children,
  dataConfigs,
  modsAndClicksStructure,
  projectDetail,
  refetchTemplate,
}: IRunContainerProps) => {
  const [isUsingPlateView, setIsUsingPlateView] = useState(true);
  const [loadingSaveTemplate, setLoadingSaveTemplate] = useState(false);
  const [printDetail, setPrintDetail] = useState<ICreateProjectRun | null>(null);
  const [step, setStep] = useState(0);
  const [suggestedSlot, setSuggestedSlot] = useState<ISuggestedSlot | null>(null);

  const templateDetails = useCurrentTemplate();

  const modsAndClicksRegex = useMemo(
    () => new RegExp(`(${modsAndClicksStructure?.modsAndClicks?.join('|') ?? ''})`, 'gi'),
    [modsAndClicksStructure?.modsAndClicks],
  );
  const composeModsChunksFromString = useCallback(
    (str: string) =>
      str
        .trim()
        .split(modsAndClicksRegex)
        .map((chunk) => {
          if (modsAndClicksRegex.test(chunk)) {
            return chunk;
          }
          return chunk.split('');
        })
        .flat()
        .filter((nuc) => nuc !== ' '),
    [modsAndClicksRegex],
  );

  const setPrintDetailWithKitType = useCallback(
    (printDetailWithKit: ICreateProjectRun | null) => {
      if (!printDetailWithKit) {
        return;
      }
      setPrintDetail({ ...printDetailWithKit, kitType: templateDetails?.kitType ?? undefined });
    },
    [templateDetails?.kitType],
  );

  const templateName = templateDetails?.name ?? '';
  const context: IRunContext = useMemo(
    () => ({
      composeModsChunksFromString,
      dataConfigs,
      isUsingPlateView,
      loadingSaveTemplate,
      modsAndClicksRegex,
      modsAndClicksStructure,
      printDetail,
      projectDetail,
      refetchTemplate,
      setIsUsingPlateView,
      setLoadingSaveTemplate,
      setPrintDetail: setPrintDetailWithKitType,
      setStep,
      setSuggestedSlot,
      step,
      suggestedSlot,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      templateDetail: templateDetails!,
      templateName,
    }),
    [
      composeModsChunksFromString,
      dataConfigs,
      isUsingPlateView,
      loadingSaveTemplate,
      modsAndClicksRegex,
      modsAndClicksStructure,
      printDetail,
      projectDetail,
      refetchTemplate,
      setIsUsingPlateView,
      setPrintDetailWithKitType,
      step,
      suggestedSlot,
      templateDetails,
      templateName,
    ],
  );

  return <RunContext.Provider value={context}>{children}</RunContext.Provider>;
};

export default RunContext.Consumer;
