import { Theme } from '@mui/material';
import { Descendant } from 'slate';

import { TSequenceAcceptedByInlineEditor } from './InlineEditor/InlineEditor';
import { ENodeType } from './custom-type';
import { slateSequenceFieldRules, checkSequenceFieldRules, MOD_REGEX } from './sequenceFieldRules';

// Define a serializing function that takes a value and returns a string.
export const serialize = (value: Descendant[]): string => {
  return value
    .map((x) => {
      if (!('type' in x)) {
        return x.text;
      }
      if (x.type === ENodeType.MOD) {
        return x.chunk;
      }
      if (x.type === ENodeType.ERROR) {
        return x.chunk;
      }
      if (x.type === ENodeType.WARNING) {
        return x.text;
      }
      if (x.children) {
        return serialize(x.children);
      }
      return '';
    })
    .flat()
    .join('');
};

export const getValidInsertedMods = (value: Descendant[]) => {
  const mods: string[] = [];
  value.forEach((x) => {
    if (!('type' in x)) {
      return;
    }
    if (x.type === ENodeType.MOD) {
      mods.push(x.chunk);
    }
    if (x.children) {
      mods.push(...getValidInsertedMods(x.children));
    }
  });
  return mods;
};

export const getAmountOfMods = (value: Descendant[]) => {
  let count = 0;
  value.forEach((x) => {
    if (!('type' in x)) {
      return;
    }
    if (x.type === ENodeType.MOD) {
      if (x.chunk?.match(MOD_REGEX)) {
        count++;
      }
    }
    if (x.children) {
      count += getAmountOfMods(x.children);
    }
  });
  return count;
};

export const getSlateState = ({
  sequence,
  chunks = [],
  mods = [],
  theme,
}: {
  chunks: string[];
  mods?: string[];
  sequence: Partial<ISequence> | undefined;
  theme: Theme;
}) => {
  const reducerFunction = checkSequenceFieldRules(theme, sequence, mods);
  return slateSequenceFieldRules
    .reduce(reducerFunction, [...chunks])
    .map((x) => {
      if (typeof x === 'string') {
        return {
          text: x,
        };
      }

      if (x.type === ENodeType.WARNING) {
        return {
          bgColor: x.bgColor,
          chunk: x.text,
          message: x.message,
          text: x.text,
          type: x.type,
        };
      }

      return [
        {
          bgColor: x.bgColor,
          children: [{ text: x.text }],
          chunk: x.text,
          message: x.message,
          type: x.type,
        },
        {
          text: '',
        },
      ];
    })
    .flat();
};

export function getKeyFromSequence(sequence?: TSequenceAcceptedByInlineEditor) {
  return [
    sequence?.id,
    sequence?.well,
    sequence?.nucErrors?.map((x) => JSON.stringify(x)).join('_'),
    sequence?.nucWarnings?.map((x) => JSON.stringify(x)).join('_'),
  ].join('-');
}
