import { PlateType, KitType } from '__generated__/globalTypes';
import { PLATE_SIZE_384, PLATE_SIZE_96 } from 'src/config/constants';

export const PLATE_TYPE = {
  [PlateType.PLATE_96]: PLATE_SIZE_96,
  [PlateType.PLATE_384]: PLATE_SIZE_384,
};

export enum InstrumentType {
  STX100 = 'STX-100',
  STX200 = 'STX-200',
}

export const PLATE_SIZES = [
  { label: PLATE_TYPE[PlateType.PLATE_96], value: PlateType.PLATE_96 },
  { label: PLATE_TYPE[PlateType.PLATE_384], value: PlateType.PLATE_384 },
];

export const getPlateSizeLabelByValue = (plateType?: string | null) =>
  PLATE_SIZES.find(({ value }) => value === plateType)?.label.toString() ?? '';

export type TKitProperties = {
  color: string;
  instrument: InstrumentType;
  label: string;
  mark: undefined | string;
  plateType: PlateType;
  templateLabel: string;
  value: KitType;
};

export const KIT_TYPES: TKitProperties[] = [
  {
    color: '#0086C9',
    instrument: InstrumentType.STX100,
    label: 'SYNTAX 60-cycle Kit, 96 Oligos',
    mark: undefined,
    plateType: PlateType.PLATE_96,
    templateLabel: 'SYNTAX 60-cycle Kit,\n 96 Oligos',
    value: KitType.B3_96,
  },
  {
    color: '#FFCA6C',
    instrument: InstrumentType.STX100,
    label: 'SYNTAX 80-Cycle Synthesis Kit, 96 Oligos',
    mark: undefined,
    plateType: PlateType.PLATE_96,
    templateLabel: 'SYNTAX 80-Cycle Synthesis Kit,\n 96 Oligos',
    value: KitType.B4_96,
  },
  {
    color: '#D0D5DD',
    instrument: InstrumentType.STX200,
    label: '384 Hi-Plex',
    mark: 'HX',
    plateType: PlateType.PLATE_384,
    templateLabel: '384 Hi-Plex',
    value: KitType.B5_HPLEX_384,
  },
  {
    color: '#D0D5DD',
    instrument: InstrumentType.STX200,
    label: '96 Hi-Fidelity',
    mark: 'HF',
    plateType: PlateType.PLATE_96,
    templateLabel: '96 Hi-Fidelity',
    value: KitType.B5_HPURE_96,
  },
  {
    color: '#D0D5DD',
    instrument: InstrumentType.STX200,
    label: '96 Standard',
    mark: 'S',
    plateType: PlateType.PLATE_96,
    templateLabel: '96 Standard',
    value: KitType.B5_HMASS_96,
  },
  {
    color: '#D0D5DD',
    instrument: InstrumentType.STX200,
    label: '384 Hi-Plex Bulk',
    mark: 'HXB',
    plateType: PlateType.PLATE_384,
    templateLabel: '384 Hi-Plex Bulk',
    value: KitType.B5_HPLEX_384_BULK,
  },
  {
    color: '#D0D5DD',
    instrument: InstrumentType.STX200,
    label: '96 Hi-Fidelity Bulk',
    mark: 'HFB',
    plateType: PlateType.PLATE_96,
    templateLabel: '96 Hi-Fidelity Bulk',
    value: KitType.B5_HPURE_96_BULK,
  },
  {
    color: '#D0D5DD',
    instrument: InstrumentType.STX200,
    label: '96 Standard Bulk',
    mark: 'SB',
    plateType: PlateType.PLATE_96,
    templateLabel: '96 Standard Bulk',
    value: KitType.B5_HMASS_96_BULK,
  },
];

export const SEQUENCE_FILE_TEMPLATES = [
  {
    label: '96_template.csv',
    title: '96 CSV',
    type: PlateType.PLATE_96,
  },
  {
    label: '96_template.xls',
    title: '96 XLS',
    type: PlateType.PLATE_96,
  },
  {
    label: 'B5_384-template.csv',
    title: '384 CSV',
    type: PlateType.PLATE_384,
  },
  {
    label: 'B5_384-template.xls',
    title: '384 XLS',
    type: PlateType.PLATE_384,
  },
];

export const SAMPLE_PLATES = [
  {
    label: '96_custom_iDNA_template.csv',
    title: '96 CSV',
    type: PlateType.PLATE_96,
  },
  {
    label: '96_custom_iDNA_template.xls',
    title: '96 XLS',
    type: PlateType.PLATE_96,
  },
  {
    label: '384_custom_iDNA_template.csv',
    title: '384 CSV',
    type: PlateType.PLATE_384,
  },
  {
    label: '384_custom_iDNA_template.xls',
    title: '384 XLS',
    type: PlateType.PLATE_384,
  },
];

const getKitTypeFromValue = (kitType?: string | null) => KIT_TYPES.find(({ value }) => value === kitType);

export const isKitV1Instrument = (kitType?: string | null) =>
  getKitTypeFromValue(kitType)?.instrument === InstrumentType.STX200;

export const getKitTypeLabelByValue = (kitType?: string | null) => {
  const kitProperties = getKitTypeFromValue(kitType);
  if (!kitProperties) return '';
  return kitProperties.mark ?? kitProperties.label;
};

export const getKitTypeFullLabel = (kitType?: string | null) => {
  const kitProperties = getKitTypeFromValue(kitType);
  if (!kitProperties) return '';
  return kitProperties.label;
};

export const getKitTypeColorByValue = (kitType?: string | null) => getKitTypeFromValue(kitType)?.color ?? '#D5D9EB';
