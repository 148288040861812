/* eslint-disable camelcase */
import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Translate, useTranslation } from 'src/containers/i18n';

const StyledReleaseVersion = styled.span`
  text-decoration: underline;
`;

function useGetReleaseVersionColumn<
  T extends {
    releaseVersion?: string | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return useMemo(
    () => ({
      Cell: ({ renderedCellValue }) => <StyledReleaseVersion>{renderedCellValue}</StyledReleaseVersion>,
      Header: () => <Translate id="downloads.listPage.table.header.version" />,
      accessorFn: (row) => row.releaseVersion,
      header: t('downloads.listPage.table.header.version'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('downloads.listPage.table.header.version') },
    }),
    [t],
  );
}

export default useGetReleaseVersionColumn;
