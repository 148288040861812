import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ERoutes } from 'src/config/routes';
import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

const StyledLink = styled.span`
  color: inherit;
  word-break: break-all;
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  word-break: break-all;
`;

export type TBreadcrumbsItem = {
  link?: string;
} & (
  | {
      title: TTranslationKeys;
    }
  | {
      customTitle: string;
    }
);

interface IBreadcrumbsProps {
  items: TBreadcrumbsItem[];
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ items }) => {
  const history = useHistory();

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      id="breadcrumb"
      sx={{
        minWidth: '20vw',
      }}
    >
      <StyledLink onClick={() => history.push(ERoutes.HOMEPAGE)}>
        <Translate id="nav.home" />
      </StyledLink>
      {items.map((item) => {
        const useCustomTitle = 'customTitle' in item;
        const title = 'customTitle' in item ? item.customTitle : item.title;
        const translateNode = useCustomTitle ? <span>{title}</span> : <Translate id={item.title} />;
        if (item.link) {
          return (
            <StyledLink key={title} onClick={() => history.push(item.link || ERoutes.HOMEPAGE)}>
              {translateNode}
            </StyledLink>
          );
        }
        return (
          <StyledTypography key={title} color="textPrimary">
            {translateNode}
          </StyledTypography>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
