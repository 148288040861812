/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/display-name */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import isEmpty from 'lodash-es/isEmpty';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { NormalizationType, SequenceScore, SequenceStatus, ProjectType, RoleType } from '__generated__/globalTypes';
import { SequenceRightAsideControls } from 'src/components/sequences/SequenceRightAside/SequenceRightAsideControls';
import ErrorsModalSection from 'src/components/sequences/errorsSection';
import { SequenceFieldEditable } from 'src/components/sequences/sequence/SequenceField';
import { CheckBoxLabel, StyledSequenceText } from 'src/components/sequences/sequence/SequenceField/styled';
import { SequenceName } from 'src/components/sequences/sequence/SequenceName';
import WarningsModalSection from 'src/components/sequences/warningsSection';
import { DEFAULT_CONCENTRATION_KEY } from 'src/containers/application/constants';
import { Translate, useTranslation } from 'src/containers/i18n';
import useUserContext from 'src/hooks/useUserContext';
import NormalizationTypeForm from 'src/pages/runs/components/NormalizationTypeForm';
import { useRunContext } from 'src/pages/runs/container';
import { StatusColorCircle } from 'src/pages/runs/pages/sequenceFileDetails/components/onHover/styled';
import { useHasWellStateChanged, useIsTemplateOwner, useSelectedSequenceState } from 'src/stores/sequenceEditor/hooks';
import { isSelectingMoreThanOneWellSelector } from 'src/stores/sequenceEditor/selectors';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';
import { calcNumberOfNucs, getSequenceStatusLabel, isDisabledNormalization } from 'src/utils/nucleotide';
import { hasRole } from 'src/utils/permissions/general';
import { getErrorScoreValueText } from 'src/utils/plates';

import { useKitTypeConcentrationLimits } from '../../../../../../../hooks/useKitTypeConcentrationLimits';

const Header = styled(Grid)`
  color: #a8a9ad;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
`;

const ItalicParagraph = styled.p`
  font-style: italic;
`;

export const TemplateDetailsEditor = () => {
  const sequence = useSelectedSequenceState();
  const {
    clearWellFields,
    undoLastActionOnSelectedWells,
    resetSelectedWells,
    sequenceConcentrationDefault,
    templateDetails,
    updateNormalization,
    updateConcentration,
    updateWellName,
    updateWellSequence,
    updateWellConcentration,
  } = useSequenceEditorStore();
  const {
    userPermissions: {
      sequenceFiles: { canEditPlate },
    },
    userProfile,
  } = useUserContext();
  const t = useTranslation();
  const reactHookUseForm = useForm();
  const isOwner = useIsTemplateOwner();
  const isSelectingMultipleWells = useSequenceEditorStore(isSelectingMoreThanOneWellSelector);
  const multipleWellsLabel = t('runs.plateEditor.header.multiple.values.title');

  const { modsAndClicksStructure, composeModsChunksFromString, modsAndClicksRegex, templateDetail } = useRunContext();

  const isDisabled =
    (hasRole(RoleType.SERVICE, userProfile) && !isOwner) ||
    (!canEditPlate && !isOwner) ||
    templateDetail?.projectType === ProjectType.GENE;

  const hasWellStateChanged = useHasWellStateChanged(sequence?.well ?? '');
  const { min: minConcentration } = useKitTypeConcentrationLimits(templateDetails?.kitType);

  if (!sequence) {
    return null;
  }

  const {
    status: sequenceStatus,
    data: sequenceData,
    errors,
    nucErrors,
    nucChunks,
    dataChunks,
    warnings,
    nucWarnings,
    targetConcentration,
    normalizationType,
    score,
    well,
    wellIndex,
    errorScoreValue,
    name,
  } = sequence;

  const nucWarningsLabels = (nucWarnings ?? [])?.map((nuc) => nuc?.message);
  const warningsToDisplay = [...(warnings || []), ...(nucWarningsLabels?.map((x) => ({ message: x })) || [])];

  const onUndo = undoLastActionOnSelectedWells;
  const onReset = resetSelectedWells;
  const onDelete = () => clearWellFields(sequence.well);
  const isShowWarnings = isEmpty(errors) && isEmpty(nucErrors);
  const concentration =
    targetConcentration ??
    sequenceConcentrationDefault[templateDetails?.kitType || DEFAULT_CONCENTRATION_KEY].defaultValue;
  const disableConcentration =
    isDisabledNormalization(normalizationType) || normalizationType === NormalizationType.LOWEST;
  const leadingSequence = (templateDetails?.prefixes ?? [])?.find((x) => x?.wellIndex === wellIndex)?.data || '';
  const ntsValue = calcNumberOfNucs(dataChunks);
  const shouldScoreBeIgnored = sequenceStatus === SequenceStatus.DELETED || !sequenceData;
  const statusForColorCircle = isSelectingMultipleWells || shouldScoreBeIgnored ? undefined : score ?? undefined;
  const scoreLabel = shouldScoreBeIgnored ? SequenceScore.UNKNOWN : score;
  const statusLabel = getSequenceStatusLabel(scoreLabel);
  const errorScoreValueFixed = sequenceData ? errorScoreValue : 0;

  const handleNormalizationChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newNormalization = ev.target.value as NormalizationType;
    updateNormalization(newNormalization);
    if (newNormalization === NormalizationType.TARGET) {
      updateWellConcentration(well, minConcentration);
    }
  };

  return (
    <FormProvider {...reactHookUseForm}>
      <SequenceRightAsideControls
        handleDelete={onDelete}
        handleReset={onReset}
        handleUndo={onUndo}
        isDisabled={isDisabled}
        isMultipleWells={isSelectingMultipleWells}
        isWellStateLocallyChanged={hasWellStateChanged}
        status={sequenceStatus}
      >
        <>
          {!isSelectingMultipleWells && (
            <>
              <ErrorsModalSection
                // @ts-ignore
                errors={errors}
                // @ts-ignore
                nucErrors={nucErrors}
                // @ts-ignore
                nucleos={nucChunks}
                // @ts-ignore
                data={dataChunks}
              />
              {isShowWarnings && (
                <WarningsModalSection
                  // @ts-ignore
                  warnings={warningsToDisplay}
                />
              )}
            </>
          )}
          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <CheckBoxLabel>
                <Translate id="runs.plateEditor.editWindow.sequenceField.additional.title" />
              </CheckBoxLabel>
              <NormalizationTypeForm
                concentrationValue={concentration}
                disableConcentration={disableConcentration}
                disabled={isDisabled}
                handleConcentrationChange={(val) => {
                  if (val !== targetConcentration) {
                    updateConcentration(val);
                  }
                }}
                handleNormalizationChange={handleNormalizationChange}
                kitType={templateDetail?.kitType}
                normalizationType={normalizationType}
              />
            </Grid>

            <Grid item style={{ fontStyle: isSelectingMultipleWells ? 'italic' : 'normal' }} xs={12}>
              <SequenceName
                key={well}
                currentName={isSelectingMultipleWells ? multipleWellsLabel : name}
                isDisabled={isDisabled || isSelectingMultipleWells}
                onChangeField={(newName) => {
                  updateWellName(well, newName);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {Boolean(leadingSequence.length) && (
                <>
                  <Header>
                    <Translate id="runs.plateEditor.popper.header.prefix" />
                  </Header>
                  <Box pt={1} />

                  {isSelectingMultipleWells ? (
                    <ItalicParagraph> {multipleWellsLabel} </ItalicParagraph>
                  ) : (
                    <StyledSequenceText>{leadingSequence}</StyledSequenceText>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <SequenceFieldEditable
                composeModsChunksFromString={composeModsChunksFromString}
                onChange={(e) => {
                  const shouldUseComposeModsFunction =
                    !isEmpty(modsAndClicksStructure?.modsAndClicks) && e.search(modsAndClicksRegex) !== -1;
                  const chunksArray = shouldUseComposeModsFunction
                    ? composeModsChunksFromString(e)
                    : e.trim().split('');
                  updateWellSequence(well, { chunks: chunksArray, sequence: e });
                }}
                // @ts-ignore
                disabled={isDisabled}
                isMultipleWells={isSelectingMultipleWells}
                modsAndClicksRegex={modsAndClicksRegex}
                modsAndClicksStructure={modsAndClicksStructure}
                multipleWellsLabel={multipleWellsLabel}
                sequence={sequence}
              />
            </Grid>

            <Grid item xs={6}>
              <Header>
                <Translate id="runs.plateEditor.popper.header.nts" />
              </Header>
              <Box style={{ fontStyle: isSelectingMultipleWells ? 'italic' : 'normal' }}>
                {isSelectingMultipleWells ? multipleWellsLabel : ntsValue || ''}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Header>
                <Translate id="runs.plateEditor.popper.header.score" />
              </Header>
              <Box>
                <StatusColorCircle status={statusForColorCircle} />
                <Translate
                  id={isSelectingMultipleWells ? 'runs.plateEditor.popper.header.score.unavailable' : statusLabel}
                />
                {!isSelectingMultipleWells ? getErrorScoreValueText(errorScoreValueFixed) : ''}
              </Box>
            </Grid>
          </Grid>
        </>
      </SequenceRightAsideControls>
    </FormProvider>
  );
};
