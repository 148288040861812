/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useDateFormat, DateUtils, DateTime } from '@dna-script-inc/shared-ui-library';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { getKitTypeFullLabel } from 'src/utils/runs';

import { EstimatedReagents } from './EstimatedReagents';

import { CentrifugationType, KitType } from '../../../../../../gql/graphql';
import { CENTRIFUGATION_LABEL_MAPPING } from '../AddForm';

const StyledPaper = styled.div`
  flex: 1;
`;

const Title = styled.h5`
  font-size: 24px;
`;

const SubTitle = styled.p`
  margin-top: 3px;
  font-size: 14px;

  > span {
    color: #0168b4;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const Cell = styled.div`
  min-width: 190px;

  & + & {
    margin-left: 20px;
  }
`;

const CellTitle = styled.div`
  margin-bottom: 12px;
  color: #9fa1a6;
  font-weight: 500;
  font-size: 13px;
`;

const CellValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  white-space: pre-line;
`;

const Icon = styled.div`
  margin-right: 20px;
`;

const SectionTitle = styled.div`
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
`;

const Description = styled.p`
  font-size: 14px;
`;

// NOTE: commenting notifications feature until we have support for this feature
// const NotificationsDescription = styled(Description)`
//   color: #999;
// `;

// const NotificationsWrapper = styled.div`
//   flex: 1;
// `;

// const Notifications = styled.div`
//   margin-top: 25px;
// `;

// const Notification = styled.div`
//   display: flex;
//   align-items: flex-end;
//   margin-bottom: 20px;

//   > div + div {
//     margin-left: 20px;
//   }
// `;

// enum ENotificationType {
//   EMAIL = 'email',
//   SMS = 'sms',
// }

// interface INotification {
//   type: ENotificationType.EMAIL | ENotificationType.SMS;
//   value: string;
// }

const getPrintTime = (startDate: string, endDate: string, dateLocal: (date: string) => DateTime): string => {
  const endTimeDate = dateLocal(endDate);
  const startTime = dateLocal(startDate).toFormat('ff');
  const endTime =
    endTimeDate.day !== dateLocal(startDate).day ? endTimeDate.toFormat('ff') : endTimeDate.toFormat('h:mm a');

  return `${startTime} - \n${endTime}`;
};

interface IPrintDetailsProps {
  printDetail: ICreateProjectRun | null;
}

const PrintDetails = ({ printDetail }: IPrintDetailsProps) => {
  const t = useTranslation();
  const { userProfile } = useUserContext();

  const { dateLocal, dateFromNow } = useDateFormat(userProfile?.timeZone?.id);

  // NOTE: commenting notifications feature until we have support for this feature
  // const [notifications, setNotifications] = React.useState<INotification[]>([]);

  // const changeNotificationType = React.useCallback(
  //   (notification: INotification, type: string) => {
  //     notification.type = type as INotification['type'];
  //     setNotifications([...notifications]);
  //   },
  //   [notifications],
  // );

  // const changeNotificationValue = React.useCallback(
  //   (notification: INotification, value: string) => {
  //     notification.value = value;
  //     setNotifications([...notifications]);
  //   },
  //   [notifications],
  // );

  // const addNotification = React.useCallback(() => {
  //   setNotifications([
  //     ...notifications,
  //     {
  //       type: ENotificationType.EMAIL,
  //       value: '',
  //     },
  //   ]);
  // }, [notifications]);

  const estimatedDuration = DateUtils.getDuration(
    dateLocal(printDetail?.scheduledStartTime ?? ''),
    dateLocal(printDetail?.scheduledEndTime ?? ''),
  );

  const modifiedBasesCount = useMemo(() => {
    if (!printDetail) {
      return null;
    }
    const { modifiedCount = 0, clicksCount = 0 } = printDetail;
    return modifiedCount + clicksCount;
  }, [printDetail]);

  if (!printDetail) {
    return <div>{t('printDetails.modal.noRunDetails')}</div>;
  }

  const endTime = dateLocal(printDetail.scheduledEndTime);
  const UTCOffset = endTime?.toFormat('ZZ') ?? '';

  return (
    <StyledPaper>
      <Title id="runId">{t('printDetails.modal.title', { id: printDetail.id })}</Title>
      <SubTitle>
        {t('printDetails.modal.subTitle')}{' '}
        <span id="scheduleCreator">
          {printDetail.user.firstName} {printDetail.user.lastName}
        </span>
        , {dateFromNow(printDetail.createdTime)}
      </SubTitle>
      <Row>
        <Cell>
          <CellTitle>{t('printDetails.modal.scheduledDate')}</CellTitle>
          <CellValue id="scheduleDate">
            {getPrintTime(printDetail.scheduledStartTime, printDetail.scheduledEndTime, dateLocal)}
            <CellTitle>{UTCOffset && `${UTCOffset} UTC`}</CellTitle>
          </CellValue>
        </Cell>
        <Cell>
          <CellTitle>{t('printDetails.modal.timeEstimate')}</CellTitle>
          <CellValue id="timeEstimate">{estimatedDuration}</CellValue>
        </Cell>
        <Cell>
          <CellTitle>{t('printDetails.modal.instrument')}</CellTitle>
          <CellValue id="instrumentId">{printDetail.instrument.name}</CellValue>
        </Cell>
        {printDetail.centrifugationType && (
          <Cell>
            <CellTitle>{t('printDetails.modal.centrifugationType')}</CellTitle>
            <CellValue id="centrifugationType">
              {t(
                CENTRIFUGATION_LABEL_MAPPING(printDetail.kitType === KitType.B5_HPLEX_384)[
                  printDetail.centrifugationType as CentrifugationType
                ],
              )}
            </CellValue>
          </Cell>
        )}
      </Row>
      <Row>
        <Cell>
          <CellTitle>{t('printDetails.modal.kit')}</CellTitle>
          <CellValue id="oligos">{getKitTypeFullLabel(printDetail.kitType)}</CellValue>
        </Cell>
        <Cell>
          <CellTitle>{t('printDetails.modal.oligos')}</CellTitle>
          <CellValue id="oligos">{printDetail.sequenceCount}</CellValue>
        </Cell>
        <Cell>
          <CellTitle>{t('printDetails.modal.length')}</CellTitle>
          <CellValue id="length">
            {printDetail.shortestSequence}-{printDetail.longestSequence}
          </CellValue>
        </Cell>
        <Cell>
          <CellTitle>{t('printDetails.modal.modifiedBase')}</CellTitle>
          <CellValue id="rare">{modifiedBasesCount}</CellValue>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <CellTitle>{t('printDetails.modal.itemList')}</CellTitle>
          <CellValue>
            <EstimatedReagents estimatedReagents={printDetail.estimatedReagents} />
          </CellValue>
        </Cell>
      </Row>
      <Row>
        <Icon>
          <FormatAlignLeft />
        </Icon>
        <div>
          <SectionTitle>{t('printDetails.modal.description')}</SectionTitle>
          <Description>{printDetail.description}</Description>
        </div>
      </Row>
      {/* NOTE: commenting notifications feature until we have support for this feature */}
      {/* <Row>
        <Icon>
          <NotificationImportant />
        </Icon>
        <NotificationsWrapper>
          <SectionTitle>{t('printDetails.modal.notifications')}</SectionTitle>
          <NotificationsDescription>{t('printDetails.modal.notificationsDescription')}</NotificationsDescription>
          <Notifications>
            {notifications.map((notification) => (
              <Notification key={notification.type}>
                <Select
                  id="notificationOption"
                  onChange={(e) => changeNotificationType(notification, e.target.value)}
                  options={[
                    {
                      label: t('printDetails.modal.labels.email'),
                      value: ENotificationType.EMAIL,
                    },
                    {
                      label: t('printDetails.modal.labels.sms'),
                      value: ENotificationType.SMS,
                    },
                  ]}
                  value={notification.type}
                />
                <TextInput
                  id="email"
                  label={t(
                    notification.type === ENotificationType.EMAIL
                      ? 'printDetails.modal.labels.emailAddress'
                      : 'printDetails.modal.labels.phoneNumber',
                  )}
                  onChange={(e) => changeNotificationValue(notification, e.target.value)}
                  value={notification.value}
                />
              </Notification>
            ))}
            <Button
              color="secondary"
              id="addNotification"
              onClick={addNotification}
              title={
                notifications.length === 0
                  ? 'printDetails.modal.buttons.addNotification'
                  : 'printDetails.modal.buttons.addAnotherNotification'
              }
              variant="outlined"
            />
          </Notifications>
        </NotificationsWrapper>
      </Row> */}
    </StyledPaper>
  );
};

export default PrintDetails;
