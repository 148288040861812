import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import styled from 'styled-components';

import UploadSequenceFileButton from 'src/components/buttons/UploadSequenceFileButton';
import { ERoutes } from 'src/config/routes';
import { Translate } from 'src/containers/i18n';
import { ProjectDetailQuery, ProjectType } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import { TTranslationKeys } from 'src/i18n';
import Spiral from 'src/resources/images/spiral.png';
import { getPermissionForProject } from 'src/utils/permissions/project';

const SpiralImage = styled.img`
  max-width: 200px;
  max-height: 200px;
`;

const NoProjects = styled.div`
  margin: 20px 0;
  font-size: 16px;
`;

const NoPlannedRunsText = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom: 30px;
`;

interface IEmptyDataProps {
  projectDetail: NonNullable<ProjectDetailQuery['getProjectDetailsById']>;
  title: TTranslationKeys;
}

const EmptyData = ({ title, projectDetail }: IEmptyDataProps) => {
  const {
    userProfile,
    userPermissions: {
      project: { canCreateRuns },
    },
  } = useUserContext();

  const permissions = getPermissionForProject(userProfile, projectDetail);

  const isGeneProject = projectDetail.projectType === ProjectType.GENE;

  return (
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" p={3}>
      <SpiralImage src={Spiral} />
      <NoProjects>
        <Translate id={title} />
      </NoProjects>
      {canCreateRuns && permissions.projects.details.canCreateRuns && !isGeneProject && (
        <>
          <NoPlannedRunsText>
            <Translate id="project.noRuns.helper.text" />
          </NoPlannedRunsText>
          <UploadSequenceFileButton
            route={ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__SEQUENCE_FILES__NEW.replace(
              ':projectId',
              projectDetail.id,
            )}
          />
        </>
      )}
    </Box>
  );
};

export default EmptyData;
