import styled from 'styled-components';

import { UNAVAILABLE_CONCENTRATION_COLOR } from '../../../../../utils/nucleotide';

export const SequenceChartBlock = styled.div<{
  borderStyle: string;
  radius: string;
  isTarget: boolean;
  position: number;
}>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.radius};
  font-size: 14px;
  font-weight: 500;
  border: ${(props) => props.borderStyle};
  ${(props) =>
    props.isTarget
      ? `
    background-image: var(--report-concentration-gradient);
    background-position: ${props.position}%;
    background-size: 2000% 1000%;
    color: ${props.position > 40 ? '#fff' : '#000'};
  `
      : `
    background-color: ${UNAVAILABLE_CONCENTRATION_COLOR};
    color: #fff;
  `}
`;

export const SequenceChartInnerCircle = styled.div<{
  radius: string;
}>`
  display: flex;
  width: 40%;
  height: 40%;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: ${(props) => props.radius};
`;
