import { NormalizationType } from '__generated__/globalTypes';
import { useKitTypeConcentrationLimits } from 'src/hooks/useKitTypeConcentrationLimits';
import { useRunContext } from 'src/pages/runs/container';

function useGetDefaultPropertiesOfEmptyWell() {
  const { templateDetail } = useRunContext();
  const concentrationLimits = useKitTypeConcentrationLimits(templateDetail?.kitType);

  const normalizationType = templateDetail?.normalizationType;
  const targetConcetration =
    normalizationType === NormalizationType.TARGET
      ? templateDetail?.targetConcentration
      : concentrationLimits.defaultValue;

  const getDefaultPropertiesOfEmptyWell = () => {
    return {
      normalizationType,
      targetConcetration,
      usePlateNorm: true,
    };
  };

  return getDefaultPropertiesOfEmptyWell;
}

export default useGetDefaultPropertiesOfEmptyWell;
