import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import styled from 'styled-components';

import { SequenceScore, PlateType } from '__generated__/globalTypes';
import { usePlateDetailsLogic } from 'src/hooks/usePlateDetailsLogic';
import { useRunContext } from 'src/pages/runs/container';
import { LOCALLY_CHANGED } from 'src/stores/sequenceEditor/constants';

export const StyledErrorIcon = styled(ErrorIcon)`
  color: #ff0000;
  height: 85%;
  width: 90%;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  color: ${(props) => props.theme.colors.primary.warning[500]};
  height: 85%;
  width: 70%;
`;

export const StyledSaveIcon = styled(SaveIcon)`
  color: #808080;
  height: 85%;
  width: 90%;
`;

const CircleWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35%;
  width: 35%;
`;

const ContainerWithContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContentContainer = styled(ContainerWithContent)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const EmptySequenceWrapper = styled(ContainerWithContent)<{ isPlate384?: boolean }>`
  height: 100%;
  width: 100%;
  ${(props) =>
    props.isPlate384 &&
    `
      border-radius: 4px;
      background-color: #cdcdcd;
    `}
`;

const EmptySequenceFirstCircle = styled(ContainerWithContent)`
  border-radius: 50%;
  height: 60%;
  width: 60%;
  background-color: #fff;
  border: #cdcdcd solid 3px;
`;

const EmptySequenceSecondCircle = styled(ContainerWithContent)<{ isPlate384?: boolean }>`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  border: #cdcdcd solid ${(props) => (props.isPlate384 ? `5px` : `7px`)};
`;

const EmptySequence = ({ score }: { score: SequenceScore | string }) => {
  const { templateDetail } = useRunContext();
  const { plate } = usePlateDetailsLogic();

  const isPlate384 = templateDetail?.plateType === PlateType.PLATE_384 || plate?.plateType === PlateType.PLATE_384;

  return (
    <StyledContentContainer>
      <EmptySequenceWrapper isPlate384={isPlate384}>
        <EmptySequenceSecondCircle isPlate384={isPlate384}>
          <EmptySequenceFirstCircle />
        </EmptySequenceSecondCircle>
        <CircleWrapper>
          {score === LOCALLY_CHANGED && <StyledSaveIcon />}
          {score === SequenceScore.IMPOSSIBLE && <StyledErrorIcon />}
          {(score === SequenceScore.DIFFICULT || score === SequenceScore.EXTREME) && <StyledWarningIcon />}
        </CircleWrapper>
      </EmptySequenceWrapper>
    </StyledContentContainer>
  );
};

export default EmptySequence;
