import WarningsIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { isNotEmpty } from 'src/utils/ui';

import CloseButton from './CloseButton';

const WarningsContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const StyledAlert = styled(Alert)`
  width: 100%;
`;

interface IWarningsProps {
  warnings: (ITemplateMessage | null)[] | null;
}

const Warnings = ({ warnings }: IWarningsProps) => {
  const [localWarnings, setLocalWarnings] = React.useState<ITemplateMessage[] | null>(null);
  const close = () => setLocalWarnings(null);

  React.useEffect(() => {
    if (warnings) {
      setLocalWarnings(warnings.filter(isNotEmpty));
    }
  }, [warnings, warnings?.length]);

  if (!localWarnings?.length) return null;

  return (
    <WarningsContainer container>
      <StyledAlert
        severity="warning"
        icon={<WarningsIcon fontSize="inherit" />}
        action={
          <CloseButton onClick={close}>
            <Translate id="common.alert.warnings.closeButton" />
          </CloseButton>
        }
      >
        <AlertTitle>
          <Translate id="common.alert.warnings.title" />
        </AlertTitle>
        {localWarnings.map(({ message }) => (
          <div key={message}>{message}</div>
        ))}
      </StyledAlert>
    </WarningsContainer>
  );
};

export default Warnings;
