import { graphql } from 'src/gql';

export const QUERY_CURRENT_USER = graphql(`
  query CurrentUser {
    getCurrentUser {
      id
      username
      firstName
      lastName
      status
      title
      workPhone
      cellPhone
      role {
        name
        description
        type
      }
      address {
        country
        state
        city
        street1
        street2
        zip
      }
      organizationName
      tooltipOnSeqPageIsClosed
      changePasswordAfterFirstLogin
      validLicense
      cloud
      timeZone {
        id
        label
        offset
      }
    }
  }
`);

export const QUERY_USER_BY_ID = graphql(`
  query UserById($id: ID!) {
    getUserById(id: $id) {
      address {
        country
        state
        city
        street1
        street2
        zip
      }
      cellPhone
      countOfProjects
      createdAt
      firstName
      groups {
        id
        name
        description
        status
        creatorFirstName
        creatorLastName
        updatedAt: lastUpdatedDate
        totalGroupMembers
        members
      }
      id
      lastName
      organizationId
      role {
        id
        name
        description
        type
      }
      status
      timeZone {
        id
        label
        offset
      }
      title
      updatedAt
      username
      workPhone
    }
  }
`);

export const QUERY_USERS_LIST = graphql(`
  query UsersList(
    $searchText: String!
    $page: Int!
    $size: Int!
    $orderField: String!
    $orderDirection: Direction!
    $statuses: [UserStatus]
  ) {
    getUsers(
      filter: {
        searchText: $searchText
        page: { page: $page, size: $size }
        sort: { field: $orderField, direction: $orderDirection }
        statuses: $statuses
      }
    ) {
      data {
        id
        username
        title
        firstName
        lastName
        status
        role {
          id
          type
          name
        }
        groups
        updatedAt
        createdAt
      }
      totalElementsCount
    }
  }
`);

export const QUERY_USERS_SHORT_LIST = graphql(`
  query UsersShortList {
    getUsers(
      filter: {
        searchText: ""
        page: { page: 0, size: 999 }
        sort: { field: "firstName", direction: ASC }
        statuses: [ACTIVE]
      }
    ) {
      data {
        id
        firstName
        lastName
        role {
          id
          name
          type
        }
      }
    }
  }
`);

export const QUERY_ADMINS_LIST = graphql(`
  query AdminsList {
    getListOfAdminsForOrganization {
      id
      firstName
      lastName
    }
  }
`);

export const GET_REMOTE_USERS = graphql(`
  query GetRemoteUsers {
    getRemoteUsers {
      id
      user {
        id
        username
        firstName
        lastName
      }
      organization {
        id
        name
      }
      role {
        id
        name
        description
        type
        authority
      }
      status
      expirationDate
      createdAt
    }
  }
`);
