import * as AccountPermissions from './account';
import { IDefaultAccountsPermissions } from './account';
import {
  DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
  DEFAULT_DETAILS_PERMISSIONS,
  DEFAULT_PROJECT_LIST_PERMISSIONS,
  DEFAULT_SETTINGS_PERMISSIONS,
  ICustomProcessesPermissions,
  IGeneralProjectPermissions,
} from './project';

interface IPagesAccess {
  canViewAccountsSection: boolean;
  canViewApplicationSection: boolean;
  canViewDashboardSection: boolean;
  canViewDesignSection: boolean;
  canViewDownloadsSection: boolean;
  canViewFilesSection: boolean;
  canViewInstrumentsSection: boolean;
  canViewInventorySection: boolean;
  canViewOrderListSection: boolean;
  canViewOrganization: boolean;
  canViewPlatesSection: boolean;
  canViewProductsSection: boolean;
  canViewProfileSection: boolean;
  canViewProjectsSection: boolean;
  canViewQuoteListSection: boolean;
  canViewReportsSection: boolean;
  canViewServiceSection: boolean;
  canViewStoreSection: boolean;
  canViewSupportSection: boolean;
  canViewTeams: boolean;
  canViewUsers: boolean;
  canViewCustomProcesses: boolean;
}

const DEFAULT_PAGES_ACCESS: IPagesAccess = {
  canViewAccountsSection: false,
  canViewApplicationSection: false,
  // TODO: change to false after testing
  canViewCustomProcesses: true,

  canViewDashboardSection: true,

  canViewDesignSection: false,

  canViewDownloadsSection: false,

  canViewFilesSection: false,

  canViewInstrumentsSection: false,

  canViewInventorySection: false,

  canViewOrderListSection: false,

  canViewOrganization: false,

  canViewPlatesSection: false,

  canViewProductsSection: false,

  canViewProfileSection: false,

  canViewProjectsSection: false,

  canViewQuoteListSection: false,

  canViewReportsSection: false,

  canViewServiceSection: false,

  canViewStoreSection: false,

  canViewSupportSection: false,

  canViewTeams: false,

  canViewUsers: false,
};

interface IGeneralPlateEditorPermissions {
  canAccessPlateSettings: boolean;
  canAddPlate: boolean;
  canEditPlate: boolean;
  canOrderPlate: boolean;
  canViewPlatesDetails: boolean;
}

const DEFAULT_GENERAL_PLATE_EDITOR_PERMISSIONS: IGeneralPlateEditorPermissions = {
  canAccessPlateSettings: false,
  canAddPlate: false,
  canEditPlate: false,
  canOrderPlate: false,
  canViewPlatesDetails: false,
};

interface IInventoryPermissions {
  canAddInventoryItem: boolean;
  canEditInventory: boolean;
  canReorder: boolean;
  canViewKitDetails: boolean;
  canViewKitsList: boolean;
  canViewQueue: boolean;
}

const DEFAULT_INVENTORY_PERMISSIONS: IInventoryPermissions = {
  canAddInventoryItem: false,
  canEditInventory: false,
  canReorder: false,
  canViewKitDetails: false,
  canViewKitsList: false,
  canViewQueue: false,
};

interface IInstrumentsPermissions {
  canCreateInstruments: boolean;
  canDeleteInstruments: boolean;
  canDownloadLogs: boolean;
  canFilterInstruments: boolean;
  canManageInstrumentUsers: boolean;
  canManageInstruments: boolean;
  canSortInstruments: boolean;
  canViewInstrumentDetails: boolean;
  canViewQueue: boolean;
  canViewRunHistory: boolean;
}

const DEFAULT_INSTRUMENTS_PERMISSIONS: IInstrumentsPermissions = {
  canCreateInstruments: false,
  canDeleteInstruments: false,
  canDownloadLogs: false,
  canFilterInstruments: false,
  canManageInstrumentUsers: false,
  canManageInstruments: false,
  canSortInstruments: false,
  canViewInstrumentDetails: false,
  canViewQueue: false,
  canViewRunHistory: false,
};

interface IRunsPermissions {
  canCancel: boolean;
  canDelete: boolean;
  canDownloadSample: boolean;
  canEditPlate: boolean;
  canPrint: boolean;
  canReschedule: boolean;
  canSchedule: boolean;
  canUploadFile: boolean;
  canViewAllFiles: boolean;
  canViewConfirmation: boolean;
  // NOTE: Also allows to schedule print
  canViewPrintJobDetails: boolean;
}

const DEFAULT_RUNS_PERMISSIONS: IRunsPermissions = {
  canCancel: false,
  canDelete: false,
  canDownloadSample: false,
  canEditPlate: false,
  canPrint: false,
  canReschedule: false,
  canSchedule: false,
  canUploadFile: false,
  canViewAllFiles: false,
  canViewConfirmation: false,
  canViewPrintJobDetails: false,
};
interface IReportsPermissions {
  canDelete: boolean;
  canDownloadItemList: boolean;
  canDownloadReport: boolean;
  canSyncRunReports: boolean;
  canViewAllReports: boolean;
  canViewReportDetails: boolean;
  canViewReportsList: boolean;
  canViewRunReportData: boolean;
  canViewRunsDetails: boolean;
}

const DEFAULT_REPORTS_PERMISSIONS: IReportsPermissions = {
  canDelete: false,
  canDownloadItemList: false,
  canDownloadReport: false,
  canSyncRunReports: false,
  canViewAllReports: false,
  canViewReportDetails: false,
  canViewReportsList: false,
  canViewRunReportData: false,
  canViewRunsDetails: false,
};

export interface IUserPermissions {
  accounts: IDefaultAccountsPermissions;
  instruments: IInstrumentsPermissions;
  pages: IPagesAccess;
  plateEditor: IGeneralPlateEditorPermissions;
  project: IGeneralProjectPermissions;
  runReports: IReportsPermissions;
  sequenceFiles: IRunsPermissions;
  customProcesses: ICustomProcessesPermissions;
}

const DEFAULT_PERMISSIONS: IUserPermissions = {
  accounts: {
    ...AccountPermissions.DEFAULT_ORGANIZATION_PERMISSIONS,
    ...AccountPermissions.DEFAULT_USERS_PERMISSIONS,
    ...AccountPermissions.DEFAULT_TEAMS_PERMISSIONS,
  },
  customProcesses: DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
  instruments: DEFAULT_INSTRUMENTS_PERMISSIONS,
  pages: DEFAULT_PAGES_ACCESS,
  plateEditor: DEFAULT_GENERAL_PLATE_EDITOR_PERMISSIONS,
  project: {
    ...DEFAULT_PROJECT_LIST_PERMISSIONS,
    ...DEFAULT_DETAILS_PERMISSIONS,
    ...DEFAULT_SETTINGS_PERMISSIONS,
  },
  runReports: DEFAULT_REPORTS_PERMISSIONS,
  sequenceFiles: DEFAULT_RUNS_PERMISSIONS,
};

export {
  DEFAULT_GENERAL_PLATE_EDITOR_PERMISSIONS,
  DEFAULT_INSTRUMENTS_PERMISSIONS,
  DEFAULT_INVENTORY_PERMISSIONS,
  DEFAULT_PAGES_ACCESS,
  DEFAULT_PERMISSIONS,
  DEFAULT_REPORTS_PERMISSIONS,
  DEFAULT_RUNS_PERMISSIONS,
};
