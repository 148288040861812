import { useQuery } from '@apollo/client';
import { HelixLoader, DateTime } from '@dna-script-inc/shared-ui-library';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ISimpleInstrument } from 'src/@types/instruments';
import { TimezoneWarningModal } from 'src/components/TimezoneWarningModal';
import { ICalendarItem } from 'src/components/calendar/Calendar';
import { useToast } from 'src/containers/toast';
import { CentrifugationType, KitType } from 'src/gql/graphql';
import { useShouldShowTimezoneWarning } from 'src/hooks/useShouldShowTimezoneWarning';
import { useToggle } from 'src/hooks/useToggle';
import { QUERY_INSTRUMENTS_FOR_PRINT } from 'src/services/gql/queries/instruments';
import { GET_CENTRIFUGATION_PROCESSES } from 'src/services/gql/queries/templates';
import { getErrorMessage } from 'src/utils/errors';

import AddForm from './AddForm';
import PrintGeneForm from './PrintGeneForm';

const PAPER_PROPS = {
  sx: {
    width: 400,
  },
};

interface IRunPrintModalProps {
  isGeneProject?: boolean;
  isOpen: boolean;
  isReprintWells?: boolean;
  onCancel: () => void;
  onCreateRunSuccess: (projectRun: ICreateProjectRun) => void;
  onSubmit: () => void;
  projectId?: string;
  templateId: string;
  kitType: KitType | undefined | null;
  versions?: string[];
}

const SpinnerWrapper = styled.div`
  width: 320px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function getCentrifugationProcess(centrifugationProcesses: string[] | undefined) {
  if (!centrifugationProcesses || centrifugationProcesses.length === 0) {
    return CentrifugationType.DEFAULT;
  }
  if (centrifugationProcesses.includes(CentrifugationType.ELUTION)) {
    return CentrifugationType.ELUTION;
  }
  return centrifugationProcesses[0] as CentrifugationType;
}

const PrintNowModal = ({
  isOpen: hasModalOpen,
  onSubmit,
  onCancel,
  templateId,
  kitType,
  onCreateRunSuccess,
  projectId,
  isGeneProject,
  versions,
  isReprintWells,
}: IRunPrintModalProps) => {
  const showToast = useToast();

  const [instruments, setInstruments] = useState<ISimpleInstrument[]>([]);
  const [slot, setSlot] = useState<ICalendarItem>(() => {
    const now = DateTime.now();
    const nowDate = now.toJSDate();
    return {
      centrifugationType: CentrifugationType.DEFAULT,
      color: '',
      end: nowDate,
      endDate: now,
      instrumentId: '',
      start: nowDate,
      startDate: now,
      title: '',
    };
  });

  const { shouldShowTimezoneWarning, clearTimezoneWarningFlag } = useShouldShowTimezoneWarning();

  const { isOpen, open, close } = useToggle(!shouldShowTimezoneWarning && hasModalOpen);
  useEffect(() => {
    if (!shouldShowTimezoneWarning && hasModalOpen) {
      open();
    } else {
      close();
    }
  }, [shouldShowTimezoneWarning, hasModalOpen, open, close]);

  const { loading: loadingInstruments } = useQuery(QUERY_INSTRUMENTS_FOR_PRINT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const loadedInstruments = (data?.findSuitableInstrumentsForPrint as ISimpleInstrument[]) ?? [];
      setInstruments(loadedInstruments);
      setSlot((currentSlot) => ({ ...currentSlot, instrumentId: loadedInstruments[0]?.id ?? '' }));
    },
    onError: (e) => {
      showToast({
        isError: true,
        persist: true,
        text: getErrorMessage(e),
      });
      onCancel();
      close();
    },
    skip: !isOpen,
    variables: {
      templateId,
    },
  });

  const { loading: loadingProcesses } = useQuery(GET_CENTRIFUGATION_PROCESSES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSlot((currentSlot) => ({
        ...currentSlot,
        centrifugationType: getCentrifugationProcess(data?.getCentrifugationProcesses),
      }));
    },
    variables: {
      kitType: kitType ?? KitType.B5_HPURE_96,
    },
  });

  const loading = loadingProcesses || loadingInstruments;

  const handleChange = useCallback(
    (newSlot: Partial<ICalendarItem>) => {
      setSlot((currentSlot) => ({ ...currentSlot, ...newSlot }));
    },
    [setSlot],
  );

  const handleTimezoneWarningCancel = useCallback(() => {
    open();
    clearTimezoneWarningFlag();
  }, [open, clearTimezoneWarningFlag]);

  if (shouldShowTimezoneWarning && !isOpen && hasModalOpen) {
    return <TimezoneWarningModal onCancel={handleTimezoneWarningCancel} />;
  }

  return (
    <Dialog open={isOpen} PaperProps={PAPER_PROPS}>
      {loading && (
        <SpinnerWrapper>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '200px',
            }}
          >
            <HelixLoader width={100} />
          </Box>
        </SpinnerWrapper>
      )}

      {!loading && !isGeneProject && (
        <AddForm
          instruments={instruments}
          item={slot}
          onCancel={onCancel}
          onChange={handleChange}
          onConfirmed={onSubmit}
          onCreateRunSuccess={onCreateRunSuccess}
          onCreateRunFailure={onCancel}
          printNow
          templateId={templateId}
          kitType={kitType}
        />
      )}

      {!loading && isGeneProject && projectId && (
        <PrintGeneForm
          instruments={instruments}
          isReprintWells={isReprintWells}
          item={slot}
          onCancel={onCancel}
          onChange={handleChange}
          onConfirmed={onSubmit}
          projectId={projectId}
          versions={versions}
        />
      )}
    </Dialog>
  );
};

export default PrintNowModal;
