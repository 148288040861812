import { ApolloError } from '@apollo/client';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';
import { formatErrorMessage } from 'src/utils/common';

import { Block } from './Block';
import Center from './Center';

import { getErrorMessage } from '../../utils/errors';

const StyledBlock = styled(Block)`
  margin-top: 32px;
  padding: 32px 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-self: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
`;

const StyledWarningIcon = styled(WarningIcon)`
  font-size: 60px;
  color: var(--warning);
`;

const Note = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
`;

interface IErrorCardProps {
  error: string | ApolloError;
  title?: TTranslationKeys;
}

const ErrorCard: React.FC<IErrorCardProps> = (props) => {
  const { error, title } = props;
  const message = typeof error === 'string' ? error : getErrorMessage(error);

  return (
    <Center>
      <StyledBlock>
        <Wrapper>
          <StyledWarningIcon />
          <Typography variant="h2" gutterBottom>
            <Translate id={title ?? 'common.alert.warning.title'} />
          </Typography>
          <Note>{formatErrorMessage(message)}</Note>
        </Wrapper>
      </StyledBlock>
    </Center>
  );
};

export default ErrorCard;
