import { graphql } from '../../../gql/index';

export const QUERY_RUN_SCHEDULE = graphql(`
  query RunScheduler($instrumentIds: [ID], $startDate: String, $endDate: String) {
    getRunSchedule(filter: { instrumentIds: $instrumentIds, startDate: $startDate, endDate: $endDate }) {
      id
      instrumentId
      sheetId
      templateName
      scheduledStartTime
      scheduledEndTime
      startTime
      endTime
      status
    }
  }
`);

export const QUERY_JOB_DURATION = graphql(`
  query JobDuration($templateId: ID!, $instrumentId: ID!) {
    getJobDuration(inputData: { templateId: $templateId, instrumentId: $instrumentId })
  }
`);

export const RUNS_DATA_FIELDS_FRAGMENT = graphql(`
  fragment RunsDataFields on RunPerUserDTO {
    countOfMods
    countOfPassedSequences
    countOfTotalSequences
    endDate
    id
    kitType
    longestSequence
    metaLink
    name
    parentIDNAId
    parentIDNAName
    percentOfPassedOligos
    plateType
    reportMetaLink
    scheduledStartDate
    shortestSequence
    status
    updatedAt
    assignedInstrument {
      id
      name
    }
    project {
      id
      name
      description
    }
    creator {
      id
      firstName
      lastName
    }
    customProcess {
      id
      name
      downloadUrl
    }
  }
`);

export const QUERY_RUNS_USER = graphql(`
  query QueryRunsUser($filter: RunPerProjectFilterDTO!) {
    getRunsPerUser(filter: $filter) {
      data {
        ...RunsDataFields
      }
      totalElementsCount
    }
  }
`);

export const QUERY_RUNS_ORGANIZATION = graphql(`
  query QueryRunsOrganization($filter: RunPerProjectFilterDTO!) {
    getRunsPerOrganizationByUser(filter: $filter) {
      data {
        ...RunsDataFields
      }
      totalElementsCount
    }
  }
`);

export const QUERY_RUN_DETAILS = graphql(`
  query QueryRunDetails($runId: ID!, $projectId: ID!, $shouldIncludeProject: Boolean!) {
    getRunReportDetails(runId: $runId) {
      id
      name
      description
      status
      project {
        id
        name
        description
        projectType
      }
      instrument {
        id
        name
        serialNumber
        version
      }
      shortestSequence
      longestSequence
      countOfMods
      countOfClicks
      metaLink
      reportMetaLink
      startTime
      endTime
      plateType
      kitType
      scheduledStartTime
      scheduledEndTime
      creator {
        id
        firstName
        lastName
      }
      sequences {
        wellIndex
        well
        data
        name
        length
        modsCount
        modStatus
        clicksCount
        targetConcetration
        actualConcentration
        yield
        volume
        purity
        errorType
        status
        score
        prefixData
        prefixNucChunks
        prefixDataChunks
        nucChunks
        dataChunks
        validationLength
        normalizationType
        modified
        lastUpdatedDate
        crossElution {
          wellsFrom
          wellsTo
        }
      }
      countOfPassedSequences
      percentOfPassedSequences
      failMessage
      reagentGroups {
        name
        code
        reagents {
          code
          displayName
          partNumber
          lotNumber
          expireDate
          days
          amount
        }
      }
      yieldSettings {
        values {
          min
          max
          level
        }
      }
      volumeSettings {
        min
        max
      }
      pqaInfo {
        conditionName
        plateTitle
        comment
      }
    }
    getProjectDetailsById(id: $projectId) @include(if: $shouldIncludeProject) {
      name
      projectType
    }
  }
`);

export const QUERY_PQA_STATISTIC = graphql(`
  query GetPqaStatistic($runId: ID!) {
    getPqaStatistic(runId: $runId) {
      groupsInfo {
        groupName
        minSizePmol
        maxInvalidWellsNumber
        totalWellNumber
        averageYieldPmol
        minInGroup
        maxInGroup
        standardDeviationPmol
        coefficientOfVariation
        invalidWellsNumber
      }
      modsInfo {
        wellMods {
          well
          sequenceName
          mods {
            label
            couplingPercent
            level
          }
          conclusion
        }
        status
        modsConfig {
          mod
          couplingLevels {
            level
            min
            max
          }
        }
      }
    }
  }
`);

export const QUERY_GET_RUNS_GROUPS_PER_PROJECT = graphql(`
  query ProjectRunsGroups($projectId: ID!) {
    getRunGroupsPerProject(filter: { projectId: $projectId }) {
      data {
        name
        version
        queued
        completed
        status
        numberOfPlates
        runs {
          id
          name
          status
          kitType
          countOfPassedSequences
          countOfTotalSequences
          shortestSequence
          longestSequence
          countOfMods
          scheduledStartDate
          endDate
          assignedInstrument {
            id
            name
            __typename
          }
          creator {
            id
            firstName
            lastName
            __typename
          }
          percentOfPassedOligos
          metaLink
          reportMetaLink
          plateType
          parentIDNAId
          parentIDNAName
          version
          __typename
        }
      }
      totalElementsCount
      __typename
    }
  }
`);
