import { Button } from '@dna-script-inc/shared-ui-library';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import zIndex from '@mui/material/styles/zIndex';
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'src/containers/i18n';

const PopperWrapper = styled(Popper)`
  position: relative;
  max-width: 390px;
  z-index: ${zIndex.speedDial};
`;

const PopperArrow = styled.span`
  position: absolute;
  width: 1.3em;
  height: 1.3em;
  box-sizing: border-box;
  left: 28px;
  bottom: -8px;
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px 5px -2px rgb(0 0 0 / 50%);
    background-color: ${(props) => props.theme.colors.primary.base.white};
    transform: rotate(45deg);
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export interface IInfoPopperProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  id: string | undefined;
  isOpen: boolean;
}

const InfoPopper = ({ anchorEl, isOpen, handleClose, children, id }: React.PropsWithChildren<IInfoPopperProps>) => {
  const t = useTranslation();
  return (
    <PopperWrapper
      anchorEl={anchorEl}
      id={id}
      onResize={undefined}
      onResizeCapture={undefined}
      open={isOpen}
      placement="top-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <div>
            <Paper sx={{ padding: '16px' }} elevation={3}>
              <CloseButton
                aria-label="close"
                variant="tertiary-gray"
                onClick={handleClose}
                size="sm"
                title={t('common.button.close')}
              >
                <CloseIcon fontSize="small" />
              </CloseButton>
              {children}
            </Paper>
            <PopperArrow />
          </div>
        </Fade>
      )}
    </PopperWrapper>
  );
};

export default InfoPopper;
