import { Button, IconPrinter } from '@dna-script-inc/shared-ui-library';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { first, noop } from 'lodash-es';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ProjectType } from '__generated__/globalTypes';
import UploadSequenceFileButton from 'src/components/buttons/UploadSequenceFileButton';
import { PageHeading } from 'src/components/layout/PageHeading';
import { ERoutes } from 'src/config/routes';
import { Translate } from 'src/containers/i18n';
import { ProjectDetailQuery } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import { useTemplates } from 'src/hooks/useTemplateIds';
import { useToggle } from 'src/hooks/useToggle';
import PrintNowModal from 'src/pages/runs/pages/scheduler/components/PrintNowModal';
import { getPermissionForProject } from 'src/utils/permissions/project';

const DescriptionWrapper = styled.div`
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.54);
`;

interface IProjectHeaderProps {
  projectDetail: NonNullable<ProjectDetailQuery['getProjectDetailsById']>;
}
const ProjectHeader: React.FC<IProjectHeaderProps> = ({ projectDetail }) => {
  const {
    userProfile,
    userPermissions: {
      project: { canEditProject, canCreateRuns },
    },
    hasValidLicense,
    hasPrintingEnabled,
  } = useUserContext();

  const { templates, loading } = useTemplates(projectDetail.id);

  const permissions = getPermissionForProject(userProfile, projectDetail);
  const isGeneProject = projectDetail.projectType === ProjectType.GENE;
  const { toggle, isOpen } = useToggle();
  const { toggle: toggleConfirmation } = useToggle();

  const printIsDisabled = !hasPrintingEnabled() || !hasValidLicense() || loading;
  const history = useHistory();

  const firstTemplate = first(templates);

  return (
    <>
      <PageHeading
        breadcrumbs={[
          {
            link: isGeneProject ? ERoutes.APPLICATIONS__PROJECTS__DESIGN : ERoutes.APPLICATIONS__PROJECTS,
            title: 'projects.title',
          },
          {
            customTitle: projectDetail?.name ?? '',
          },
        ]}
        renderControls={() => (
          <Grid container spacing={1} justifyContent="flex-end">
            {canEditProject && permissions.projects.settings.canManageAccess && !isGeneProject && (
              <Grid item>
                <Button
                  variant="secondary-gray"
                  id="manageProject"
                  onClick={() => {
                    history.push(ERoutes.APPLICATIONS__PROJECTS__MANAGE__id.replace(':id', projectDetail.id));
                  }}
                >
                  <Translate id="projects.detail.button.manageProject" />
                </Button>
              </Grid>
            )}
            {canCreateRuns && permissions.projects.details.canCreateRuns && !isGeneProject && (
              <Grid item>
                <UploadSequenceFileButton
                  route={ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__SEQUENCE_FILES__NEW.replace(
                    ':projectId',
                    projectDetail.id,
                  )}
                />
              </Grid>
            )}
            {canCreateRuns && permissions.projects.details.canCreateRuns && isGeneProject && (
              <Grid item>
                <Button
                  variant="primary"
                  disabled={printIsDisabled}
                  id="printGene"
                  onClick={toggle}
                  startIcon={<IconPrinter />}
                >
                  <Translate id="projects.detail.button.print.gene" />
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        renderTitle={() => projectDetail.name}
      />

      {firstTemplate && (
        <PrintNowModal
          isGeneProject
          isOpen={isOpen && isGeneProject}
          onCancel={toggle}
          onCreateRunSuccess={noop}
          onSubmit={() => {
            toggle();
            toggleConfirmation();
          }}
          projectId={projectDetail.id}
          templateId={firstTemplate.id}
          kitType={firstTemplate.kitType}
        />
      )}
      {projectDetail.description && (
        <DescriptionWrapper>
          <Typography id="description" variant="body2">
            {projectDetail.description}
          </Typography>
        </DescriptionWrapper>
      )}
    </>
  );
};

export default ProjectHeader;
