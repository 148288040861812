import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Info from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import styled from 'styled-components';

import ModalOnHover from 'src/components/modalOnHover';
import { Translate } from 'src/containers/i18n';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TitleContainer = styled(RowContainer)`
  margin-bottom: 5px;
`;

const Title = styled.span`
  font-weight: 500;
`;

const InfoItem = styled(RowContainer)`
  margin-left: 20px;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const StyledInfoIcon = styled(Info)`
  margin-left: 5px;
  font-size: 22px;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  font-size: 22px;
  color: #4bb667;
  margin-right: 10px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 22px;
  color: ${(props) => props.theme.colors.primary.error[700]};
  margin-right: 10px;
`;

const StyledWarningIcon = styled(WarningIcon)`
  font-size: 22px;
  color: ${(props) => props.theme.colors.primary.warning[500]};
  margin-right: 10px;
`;

const ModalContainer = styled.div`
  width: 25vw;
  font-size: 13px;
  padding: 20px 20px 0;
  font-weight: 300;
  text-align: left;
`;

const ModalItem = styled.div`
  margin-bottom: 20px;
`;

const InfoScoreModal = () => (
  <ModalOnHover
    placement="bottom-end"
    popperContent={
      <ModalContainer>
        <ModalItem>
          <TitleContainer>
            <StyledCheckCircleIcon />
            <Title>
              <Translate id="runs.plateEditor.header.info.score.modal.accepted.title" />
            </Title>
          </TitleContainer>
          <Translate id="runs.plateEditor.header.info.score.modal.accepted.description" />
        </ModalItem>
        <ModalItem>
          <TitleContainer>
            <StyledWarningIcon />
            <Title>
              <Translate id="runs.plateEditor.header.info.score.modal.difficult.title" />
            </Title>
          </TitleContainer>
          <Translate id="runs.plateEditor.header.info.score.modal.difficult.description" />
        </ModalItem>
        <ModalItem>
          <TitleContainer>
            <StyledErrorIcon />
            <Title>
              <Translate id="runs.plateEditor.header.info.score.modal.error.title" />
            </Title>
          </TitleContainer>
          <Translate id="runs.plateEditor.header.info.score.modal.error.description" />
        </ModalItem>
      </ModalContainer>
    }
  >
    <InfoItem>
      <Translate id="runs.plateEditor.header.info.score.title" />
      <StyledInfoIcon />
    </InfoItem>
  </ModalOnHover>
);

export default InfoScoreModal;
