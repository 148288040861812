import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { CenteredGraphqlError } from 'src/components/CenteredGraphqlError';
import { contentWithRightSidebarContainerStyles } from 'src/components/RightSidebarContainer';
import Errors from 'src/components/alert/Errors';
import Warnings from 'src/components/alert/Warnings';
import { FormattedError } from 'src/components/common';
import { PageCenteredSpinner } from 'src/components/common/CenteredSpinner';
import { ERoutes } from 'src/config/routes';
import { ApplicationProvider } from 'src/containers/application';
import { TemplateStatus } from 'src/gql/graphql';
import useGetModsAndClicksStructure from 'src/hooks/useGetModsAndClicksStructure';
import { QUERY_PROJECT_DETAIL } from 'src/services/gql/queries/projects';
import { GET_TEMPLATE_BY_ID, QUERY_TEMPLATE_CONFIGS } from 'src/services/gql/queries/templates';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';

import Header from './components/Header';
import RunConsumer, { RunProvider } from './container';
import useParseTemplateErrorsAndWarnings from './hooks/useParseTemplateErrorsAndWarnings';
import { SequenceFilePlate, TemplateSchedulerPage } from './pages';
import TemplateOutdatedPage from './pages/outdated';
// eslint-disable-next-line max-len
import { TemplateDetailsRightAside } from './pages/sequenceFileDetails/components/SequenceFileDetailsRightAside/SequenceFileDetailsRightAside';

import NotFoundPage from '../notFound/NotFoundPage';

// eslint-disable-next-line max-len
const SequenceFileRouter = () => {
  const { projectId, templateId } = useParams<{
    projectId: string;
    templateId: string;
  }>();

  const {
    data: dataTemplate,
    loading: loadingTemplate,
    error: errorTemplate,
    refetch,
  } = useQuery(GET_TEMPLATE_BY_ID, {
    fetchPolicy: 'no-cache',
    skip: !templateId,
    variables: { id: templateId },
  });

  const refetchTemplate = () => {
    refetch({ id: templateId });
  };

  const {
    data: dataConfigs,
    loading: loadingConfigs,
    error: errorConfig,
  } = useQuery(QUERY_TEMPLATE_CONFIGS, {
    fetchPolicy: 'no-cache',
    skip: !templateId,
    variables: { id: templateId },
  });

  const {
    data: dataProject,
    loading: loadingProject,
    error: errorProject,
  } = useQuery(QUERY_PROJECT_DETAIL, {
    fetchPolicy: 'no-cache',
    skip: !projectId,
    variables: { id: projectId },
  });

  const projectDetail = (dataProject?.getProjectDetailsById ?? null) as IProjectDetail | null;
  const templateDetail = dataTemplate?.getTemplateDetails;
  const templateKitType = templateDetail?.kitType;

  const modsAndClicksStructure = useGetModsAndClicksStructure(templateKitType);

  const matchFilePage = useRouteMatch({
    exact: true,
    path: ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__DETAILS,
    strict: false,
  });

  const matchProjectFilePage = useRouteMatch({
    exact: true,
    path: ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__DETAILS,
    strict: false,
  });

  const matchGeneProjectFilePage = useRouteMatch({
    exact: true,
    path: ERoutes.APPLICATIONS__PROJECTS__DESIGN__projectId__RUNS__TEMPLATE__templateId__DETAILS,
    strict: false,
  });

  const match = matchFilePage || matchProjectFilePage || matchGeneProjectFilePage;

  const { initializeTemplateDetails, setLoading } = useSequenceEditorStore();
  const { errors, warnings } = useParseTemplateErrorsAndWarnings(templateDetail);

  useEffect(() => {
    setLoading(loadingTemplate);
  }, [loadingTemplate, setLoading]);

  useEffect(() => {
    initializeTemplateDetails(templateDetail);
  }, [initializeTemplateDetails, templateDetail]);

  if (loadingTemplate || loadingProject || loadingConfigs) {
    return <PageCenteredSpinner />;
  }

  if (errorTemplate) {
    return <FormattedError error={errorTemplate} />;
  }

  if (errorConfig) {
    return <FormattedError error={errorConfig} />;
  }

  if (errorProject) {
    return <CenteredGraphqlError error={errorProject} />;
  }

  const restrictedRoutes = templateDetail && templateDetail.status !== TemplateStatus.OUTDATED && (
    <Switch>
      <Route
        component={SequenceFilePlate}
        exact
        path={ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__DETAILS}
      />
      <Route
        component={TemplateSchedulerPage}
        exact
        path={ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__SCHEDULE}
      />
      <Route
        component={SequenceFilePlate}
        exact
        path={ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__DETAILS}
      />
      <Route
        component={SequenceFilePlate}
        exact
        path={ERoutes.APPLICATIONS__PROJECTS__DESIGN__projectId__RUNS__TEMPLATE__templateId__DETAILS}
      />
      <Route
        component={TemplateSchedulerPage}
        exact
        path={ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__SCHEDULE}
      />

      <Route component={NotFoundPage} />
    </Switch>
  );

  return (
    <ApplicationProvider>
      <RunProvider
        dataConfigs={dataConfigs}
        modsAndClicksStructure={modsAndClicksStructure}
        projectDetail={projectDetail}
        refetchTemplate={refetchTemplate}
      >
        <RunConsumer>
          {({ isUsingPlateView }) => (
            <>
              <Grid container spacing={3}>
                <Grid
                  item
                  style={match && isUsingPlateView ? contentWithRightSidebarContainerStyles : undefined}
                  xs={12}
                >
                  <Errors errors={errors} />
                  <Warnings warnings={warnings} />
                  <Header />
                  <Switch>
                    {restrictedRoutes}

                    <Route component={TemplateOutdatedPage} />
                  </Switch>
                </Grid>
              </Grid>
              {match && isUsingPlateView && <TemplateDetailsRightAside />}
            </>
          )}
        </RunConsumer>
      </RunProvider>
    </ApplicationProvider>
  );
};

export default SequenceFileRouter;
