import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { AccessDenied } from 'src/components/common';
import { ERoutes } from 'src/config/routes';
import { useTranslation } from 'src/containers/i18n';

const NotFoundPage: React.FC = () => {
  const t = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    history.push(ERoutes.NOT_FOUND);
  }, [history]);

  return <AccessDenied error={t('notFound.title')} />;
};

export default NotFoundPage;
