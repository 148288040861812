import { useCallback, useEffect } from 'react';
import { Editor, Transforms } from 'slate';

import { UNDO_SEQUENCE_DATA_EVENT } from 'src/stores/sequenceEditor/events';

import { getSlateState } from '../utils';

function useResetEditorState(editor: Editor, slateState: ReturnType<typeof getSlateState>) {
  const setEditorState = useCallback(() => {
    Transforms.removeNodes(editor, { at: [0] });
    Transforms.insertNodes(
      editor,
      {
        children: slateState.length > 0 ? slateState : [{ text: '' }],
        type: 'paragraph',
      },
      { at: [0] },
    );
  }, [editor, slateState]);

  useEffect(() => {
    document.addEventListener(UNDO_SEQUENCE_DATA_EVENT, setEditorState);
    return () => {
      document.removeEventListener(UNDO_SEQUENCE_DATA_EVENT, setEditorState);
    };
  }, [setEditorState]);
}

export default useResetEditorState;
