import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import styled from 'styled-components';

import { KitType, PlateType } from '__generated__/globalTypes';
import { TextInput } from 'src/components/form';
import useTranslation from 'src/containers/i18n/useTranslation';
import { getMyPlatesQueryOptions } from 'src/hooks/useMyPlatesQuery';
import { QUERY_PLATES_FOR_DROPDOWN } from 'src/services/gql/queries/templates';
import { TPlateForDropdown } from 'src/types';

const StyledTextInput = styled(TextInput)`
  width: 200px;
`;

type TSelectIDNAPlateProps = {
  kitType: KitType | undefined | null;
  plateType: PlateType | undefined;
  idnaValue: TPlateForDropdown | null | undefined;
  setIdnaValue: (idnaValue: TPlateForDropdown | null | undefined) => void;
};

export default function SelectIDNAPlate({ kitType, plateType, idnaValue, setIdnaValue }: TSelectIDNAPlateProps) {
  const t = useTranslation();
  const { data, loading } = useQuery(QUERY_PLATES_FOR_DROPDOWN, {
    ...getMyPlatesQueryOptions(kitType ?? undefined),
    skip: !kitType,
  });

  const platesOptions = (data?.getIDNAPlates?.data ?? []).filter(
    (plate) => plate?.plateType === plateType && plate?.kitType === kitType,
  );

  return (
    <Grid container direction="column" sx={{ paddingLeft: '16px' }}>
      <Grid item container alignItems="center">
        <Autocomplete
          componentsProps={{
            paper: {
              sx: {
                width: 250,
              },
            },
          }}
          id="plates"
          options={platesOptions}
          loading={loading}
          value={idnaValue}
          getOptionLabel={(option) => option?.name || ''}
          onChange={(_e, item) => {
            setIdnaValue(item);
          }}
          clearOnBlur
          ListboxProps={{
            style: {
              wordBreak: 'break-all',
            },
          }}
          renderInput={(params) => (
            <StyledTextInput
              {...params}
              sx={{
                marginBottom: '16px',
                marginRight: '8px',
                marginTop: '16px',
              }}
              variant="outlined"
              color="secondary"
              disabled={loading}
              size="small"
              label={t('runs.create.uploadFiles.selectPlateSetup.label.custom')}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
