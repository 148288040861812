import ApiConfig from 'src/config/api';
import { composeUrl } from 'src/utils/api';

export const getFeatureFlags = async () => {
  return new Promise((resolve, reject) => {
    fetch(ApiConfig.API_ROOT + ApiConfig.ENDPOINTS.Flags)
      .then((response) => {
        if (response.status < 400) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function downloadInstructionsSetZip(projectId: string, version?: string) {
  const url = composeUrl(ApiConfig.ENDPOINTS.DownloadInstructions, {
    projectId,
    ...(version ? { version } : {}),
  });
  return (
    fetch(url)
      .then((result) => result.blob())
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .catch((err) => err)
  );
}
