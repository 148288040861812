import Info from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import styled from 'styled-components';

import { KitType } from '__generated__/globalTypes';
import ModalOnHover from 'src/components/modalOnHover';
import SequenceItem from 'src/components/sequences/sequence/sequenceItem';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useKitTypeConcentrationLimits } from 'src/hooks/useKitTypeConcentrationLimits';
import { useRunContext } from 'src/pages/runs/container';

const GridDescription = styled(Grid)``;

const DescriptionItem = styled.div`
  & + & {
    margin-top: 15px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const COLOR_BOX_SIZE = 20;
const OLIGO_SIZE = 56;
const MARK_SIZE = 40;
const MARK_WIDTH = 1;

const ConcentrationColorRange = styled.div`
  display: inline-block;
  height: ${COLOR_BOX_SIZE}px;
  position: relative;
  background: var(--report-concentration-gradient);
  flex-grow: 1;

  &:first-child::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: ${COLOR_BOX_SIZE}px;
    height: ${MARK_SIZE}px;
    width: ${MARK_WIDTH}px;
    background-color: black;
  }

  &:last-child::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: ${COLOR_BOX_SIZE}px;
    height: ${MARK_SIZE}px;
    width: ${MARK_WIDTH}px;
    background-color: black;
  }
`;

const MinConcentrationValue = styled.div`
  position: absolute;
  bottom: ${COLOR_BOX_SIZE + MARK_SIZE}px;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
  font-weight: 400;
`;

const MaxConcentrationValue = styled.div`
  position: absolute;
  bottom: ${COLOR_BOX_SIZE + MARK_SIZE}px;
  width: 100%;
  text-align: center;
  transform: translateX(50%);
  font-weight: 400;
`;

const StyledConcentrationItemsWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  padding-top: ${MARK_SIZE}px;
`;

const InfoItem = styled(RowContainer)`
  margin-left: 20px;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const StyledInfoIcon = styled(Info)`
  margin-left: 5px;
  font-size: 22px;
`;

const ModalContainer = styled.div`
  width: 500px;
  padding: 20px 20px 0;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
`;

const ModalItem = styled(Grid)`
  margin-bottom: 20px;
`;

const MinMaxContainer = styled(Box)`
  margin-top: 8px;
`;

const ModalTitle = styled(Grid)`
  margin-bottom: 15px;
  font-weight: 400;
`;

interface IInfoScoreModalProps {
  kitType: KitType | undefined | null;
}

const InfoScoreModal = ({ kitType }: IInfoScoreModalProps) => {
  const { min, max } = useKitTypeConcentrationLimits(kitType);
  const { dataConfigs } = useRunContext();

  const t = useTranslation();

  return (
    <ModalOnHover
      placement="bottom-end"
      popperContent={
        <ModalContainer>
          <ModalItem container>
            <ModalTitle item xs={12}>
              <Translate id="runs.plateEditor.header.info.sequence.modal.title" />
            </ModalTitle>
            <Grid item xs={2}>
              <Box width={OLIGO_SIZE} height={OLIGO_SIZE} position="relative">
                <SequenceItem
                  kitType={kitType}
                  sequence={
                    {
                      nucChunks: new Array(dataConfigs?.getPlateConfigs?.maxErrorMers || 0),
                      targetConcetration: min ?? 0,
                    } as ISequence
                  }
                />
              </Box>
            </Grid>
            <GridDescription item xs={10}>
              <DescriptionItem>
                {t('runs.plateEditor.header.info.sequence.greyCircle.firstSentence', {
                  value: dataConfigs?.getPlateConfigs?.maxErrorMers || '',
                })}
              </DescriptionItem>
              <DescriptionItem>
                <Translate id="runs.plateEditor.header.info.sequence.greyCircle.secondSentence" />
              </DescriptionItem>
              <StyledConcentrationItemsWrapper>
                <ConcentrationColorRange>
                  <MinConcentrationValue>{min}</MinConcentrationValue>
                  <MaxConcentrationValue>{max}</MaxConcentrationValue>
                </ConcentrationColorRange>
              </StyledConcentrationItemsWrapper>
              <MinMaxContainer>
                <Translate id="runs.plateEditor.header.info.sequence.concentration.minMax" />
              </MinMaxContainer>
            </GridDescription>
          </ModalItem>
        </ModalContainer>
      }
    >
      <InfoItem>
        <Translate id="runs.plateEditor.header.info.sequence.title" />
        <StyledInfoIcon />
      </InfoItem>
    </ModalOnHover>
  );
};

export default InfoScoreModal;
