/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import { Button } from '@dna-script-inc/shared-ui-library';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { isEmpty, last, sortBy } from 'lodash-es';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { RunStatus } from '__generated__/globalTypes';
import { CenteredSpinner } from 'src/components/common';
import useDownloadInstructionsFile from 'src/components/table/runsTable/useDownloadInstructionsFile';
import { Translate, useTranslation } from 'src/containers/i18n';
import { ProjectDetailQuery } from 'src/gql/graphql';
import { useToggle } from 'src/hooks/useToggle';
import { downloadInstructionsSetZip } from 'src/services/api/common';
import { QUERY_SHORT_RUNS_GROUPS_PER_PROJECT } from 'src/services/gql/queries/projects';

import DownloadingModal from './DownloadingModal';
import ReprintWellsModal from './ReprintWellsModal';

const StyledPaper = styled(Paper)`
  background: ${(props) => props.color};
  padding: 12px 16px;
  margin-bottom: 30px;
`;

const WarningTypography = styled(Typography)`
  color: #663c00;
  width: 100%;
`;

const SuccessTypography = styled(Typography)`
  color: #1e4620;
  width: 100%;
`;

const SyledWarningIcon = styled(WarningIcon)`
  color: #eb663c;
`;

const StyledCheckIcon = styled(CheckCircleOutlineIcon)`
  color: #49977d;
`;

interface IInstructionSetProps {
  projectDetail: NonNullable<ProjectDetailQuery['getProjectDetailsById']>;
}

const MAX_ALLOWED_PLATES_FOR_HAMILTON = 5;

function InstructionSet({ projectDetail }: IInstructionSetProps) {
  const t = useTranslation();
  const { countOfRuns, runsInQueue, id, countOfTemplates } = projectDetail;

  const { data, loading } = useQuery(QUERY_SHORT_RUNS_GROUPS_PER_PROJECT, {
    fetchPolicy: 'network-only',
    variables: {
      projectId: projectDetail.id,
    },
  });

  const wellsToReprint = React.useMemo(() => {
    return data?.getRunGroupsPerProject?.data?.filter((g) =>
      g?.runs?.some((r) => r?.countOfPassedSequences !== 0 && Number(r?.percentOfPassedOligos) < 100),
    );
  }, [data]);

  const lastRunGroup = React.useMemo(() => {
    return last(sortBy(data?.getRunGroupsPerProject?.data, ['version']));
  }, [data]);

  const { open, isOpen, close } = useToggle();
  const { open: openReprintWells, isOpen: isOpenReprintWells, close: closeReprintWells } = useToggle();

  const getInstructionFile = useCallback(async () => {
    // eslint-disable-next-line no-return-await, @typescript-eslint/no-unsafe-return
    return await downloadInstructionsSetZip(id);
  }, [id]);

  const { handleDownloadInstructions, instructionFile } = useDownloadInstructionsFile(getInstructionFile, close);

  const isAllOligosPassed = lastRunGroup?.runs?.every((r) => r?.percentOfPassedOligos === 100);
  const isInProgress = lastRunGroup && Number(lastRunGroup?.queued) > 0;
  const isFailed = lastRunGroup?.status === RunStatus.FAILED;

  const isCompleted = lastRunGroup?.status === RunStatus.COMPLETED && isAllOligosPassed && instructionFile;
  const shouldWellsBeReprinted = !isEmpty(wellsToReprint);

  const instructionsPaperColor = React.useMemo(() => {
    if (isFailed) {
      return '#FDECEA';
    }

    if (isCompleted) {
      return '#EDF7ED';
    }

    return '#fff4e5';
  }, [isCompleted, isFailed]);

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <StyledPaper color={instructionsPaperColor}>
      <Grid container spacing={1}>
        <DownloadingModal handleSubmit={handleDownloadInstructions} isVisible={isOpen} />
        <ReprintWellsModal
          onCancel={closeReprintWells}
          onCreateRunSuccess={closeReprintWells}
          onSubmit={closeReprintWells}
          open={isOpenReprintWells}
          projectId={projectDetail.id}
          versions={wellsToReprint?.filter((well) => well?.version).map((well) => String(well?.version)) || []}
        />
        <Grid container item justifyContent="center" xs={1}>
          {isCompleted ? <StyledCheckIcon fontSize="large" /> : <SyledWarningIcon fontSize="large" />}
        </Grid>
        <Grid item xs={11}>
          {isCompleted && (
            <>
              <SuccessTypography gutterBottom variant="h6">
                <Translate id="projects.detail.tabs.instructions.completed" />
              </SuccessTypography>
              <SuccessTypography gutterBottom variant="body2">
                <Translate id="projects.detail.tabs.instructions.done" />
              </SuccessTypography>
            </>
          )}
          <Box
            sx={{
              marginBottom: '12px',
            }}
          >
            <Button
              variant="secondary"
              disabled={!isCompleted}
              id="downloadInstructions"
              onClick={open}
              startIcon={<GetAppIcon />}
              uppercase
              title={t('projects.detail.tabs.instructions.download.btn')}
            />
          </Box>
          {isInProgress && (
            <>
              <WarningTypography gutterBottom variant="h6">
                <Translate
                  data={{ countOfRuns: countOfRuns ?? 0 }}
                  id="projects.detail.tabs.instructions.not.completed"
                />
              </WarningTypography>

              {isEmpty(wellsToReprint) && (
                <WarningTypography gutterBottom variant="body2">
                  <Translate
                    data={{ runsInQueue: runsInQueue ?? 0 }}
                    id={
                      runsInQueue === 1
                        ? 'projects.detail.tabs.instructions.remaining.singular'
                        : 'projects.detail.tabs.instructions.remaining.plural'
                    }
                  />{' '}
                  {!isCompleted && (
                    <Translate
                      data={{ runsInQueue: runsInQueue ?? 0 }}
                      id="projects.detail.tabs.instructions.condition"
                    />
                  )}
                </WarningTypography>
              )}
            </>
          )}

          {!isAllOligosPassed && !shouldWellsBeReprinted && (
            <WarningTypography gutterBottom variant="body2">
              <Translate id="projects.detail.tabs.instructions.not.all.oligos" />
            </WarningTypography>
          )}

          {isFailed && (
            <WarningTypography gutterBottom variant="body2">
              <Translate id="projects.detail.tabs.instructions.failed" />
            </WarningTypography>
          )}

          {shouldWellsBeReprinted && (
            <>
              <WarningTypography variant="h6">
                <Translate id="projects.detail.tabs.instructions.failed.wells.title" />
              </WarningTypography>
              <WarningTypography variant="body2">
                <Translate id="projects.detail.tabs.instructions.check.failed.wells" />
              </WarningTypography>
              <WarningTypography gutterBottom variant="body2">
                <Translate id="projects.detail.tabs.instructions.failed.wells" />
              </WarningTypography>
              <Box
                sx={{
                  marginRight: '16px',
                }}
              >
                <Button
                  variant="primary"
                  disabled={(countOfTemplates ?? 0) >= MAX_ALLOWED_PLATES_FOR_HAMILTON}
                  id="reprintWells"
                  onClick={openReprintWells}
                  startIcon={<RefreshIcon />}
                  uppercase
                >
                  <Translate id="projects.detail.tabs.instructions.reprint.btn" />
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default InstructionSet;
