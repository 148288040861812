import { graphql } from 'src/gql';

export const MUTATION_PROJECT_CREATE = graphql(`
  mutation ProjectCreate(
    $name: String!
    $description: String!
    $budget: Float!
    $currency: String!
    $owner: EntityByIdDTO!
    $admin: EntityByIdDTO!
    $users: [EntityByIdDTO!]
    $restrictedAccess: Boolean
    $projectType: ProjectType!
  ) {
    createProject(
      inputData: {
        name: $name
        description: $description
        budget: $budget
        currency: $currency
        owner: $owner
        admin: $admin
        users: $users
        restrictedAccess: $restrictedAccess
        projectType: $projectType
      }
    ) {
      id
    }
  }
`);

export const MUTATION_PROJECT_UPDATE = graphql(`
  mutation ProjectUpdate(
    $id: ID!
    $name: String!
    $description: String!
    $budget: Float!
    $currency: String!
    $owner: EntityByIdDTO!
    $admin: EntityByIdDTO!
    $users: [EntityByIdDTO!]
    $restrictedAccess: Boolean
  ) {
    updateProject(
      inputData: {
        id: $id
        name: $name
        description: $description
        budget: $budget
        currency: $currency
        owner: $owner
        admin: $admin
        users: $users
        restrictedAccess: $restrictedAccess
      }
    ) {
      id
    }
  }
`);

export const MUTATION_PROJECT_TEMPLATE_CREATE = graphql(`
  mutation ProjectTemplateCreate(
    $fileName: String
    $content: String!
    $projectId: ID!
    $parentPlateId: ID
    $plateType: PlateType
    $type: ContentType
    $kitType: KitType
    $runPurityEstimate: Boolean
  ) {
    createTemplate(
      inputData: {
        fileName: $fileName
        content: $content
        projectId: $projectId
        parentPlateId: $parentPlateId
        plateType: $plateType
        type: $type
        kitType: $kitType
        runPurityEstimate: $runPurityEstimate
      }
    ) {
      id
      name
      status
      format
      createdAt
      updatedAt
      plateType
      creator {
        id
        firstName
        lastName
      }
      countOfRuns
      countOfMods
      countOfSequences
      shortestSequence
      longestSequence
      averageSequence
      errors {
        ...TemplateMessageDTOFragment
      }
      warnings {
        ...TemplateMessageDTOFragment
      }
    }
  }
`);

export const MUTATION_DELETE_PROJECT = graphql(`
  mutation DeleteProject($id: ID!) {
    deleteProject(projectId: $id) {
      status
      message
    }
  }
`);

export const MUTATION_RESTORE_PROJECT = graphql(`
  mutation RestoreProject($id: ID!) {
    restoreProject(projectId: $id) {
      status
      message
    }
  }
`);
