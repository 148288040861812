/* eslint-disable camelcase */
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

interface IUseGetIdColumnParams {
  headerTitle: TTranslationKeys;
  filterTitle: TTranslationKeys;
  testid?: string | '';
}

function useGetIdColumn<
  T extends {
    id?: string | null;
  },
>({ headerTitle, filterTitle, testid }: IUseGetIdColumnParams): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Header: () => <Translate id={headerTitle} testid={testid} />,
    accessorFn: (row) => row.id,
    header: t(headerTitle),
    muiTableHeadCellFilterTextFieldProps: { placeholder: t(filterTitle) },
  };
}

export default useGetIdColumn;
