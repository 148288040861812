/* eslint-disable no-param-reassign */
import { InMemoryCache, ApolloClient, createHttpLink, TypePolicies } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { GRAPHQL_URL } from 'src/config/common';
import { EVENT_SESSION_EXPIRED } from 'src/config/events';
import Events from 'src/utils/events';

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const ERROR_401 = 'User is not logged to system or session is expired';

const logoutLink = onError(({ response }) => {
  const hasError = (response?.errors || []).some((error) => error.message === ERROR_401);

  if (hasError) {
    Events.emit(EVENT_SESSION_EXPIRED);
  }
});

const OPTIONAL_FIELDS = {
  InstrumentDTO: ['image', 'serviceDate'],
  InstrumentDetailsDTO: ['image', 'serviceDate'],
  InstrumentReagentStatusDTO: ['expirationDate'],
  ProjectByIdDTO: ['description'],
  ProjectDetailDTO: ['description', 'geneDetail'],
  RunPerProjectDTO: ['customProcess', 'endDate', 'metaLink', 'parentIDNAId', 'parentIDNAName', 'reportMetaLink'],
  RunPerUserDTO: ['customProcess', 'endDate', 'metaLink', 'parentIDNAId', 'parentIDNAName', 'reportMetaLink'],
  SequenceDTO: ['nucWarnings', 'nucErrors', 'warnings', 'prefixDataChunks', 'prefixNucChunks', 'errors'],
  ServiceInfoDTO: ['message'],
  TemplatePerProjectDTO: ['parentIDNAId', 'parentIDNAName', 'partNumber'],
  TemplatePerUserDTO: ['parentIDNAId', 'parentIDNAName', 'partNumber', 'project'],
};

type TField = keyof typeof OPTIONAL_FIELDS;

const generateTypePoliciesForCache = () => {
  const typePolicies = Object.keys(OPTIONAL_FIELDS).reduce((typeObj, type) => {
    typeObj[type] = {
      fields: OPTIONAL_FIELDS[type as TField].reduce(
        (
          fieldsObj: {
            [x: string]: {
              read(field: string): string | null;
            };
          },
          field: string | number,
        ) => {
          fieldsObj[field] = {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            read(field: string) {
              return field || null;
            },
          };
          return fieldsObj;
        },
        {},
      ),
    };
    return typeObj;
  }, {} as TypePolicies);

  return typePolicies;
};

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: {
      RecentWorkItemDTO: ['RecentRunWorkItemDTO', 'RecentTemplateWorkItemDTO'],
    },
    typePolicies: generateTypePoliciesForCache(),
  }),
  link: logoutLink.concat(httpLink),
});

export default apolloClient;
