import React from 'react';

export interface IWell {
  actualConcentration?: number | null;
  well: string;
}

interface IRunReportContext {
  isEditableGeneProject: boolean;
  isRunReportWellsUpdated: boolean;
  loading: boolean;
  updateRunConcentration: () => void;
  updatedWells: IWell[];
  setUpdatedWells: React.Dispatch<React.SetStateAction<IWell[]>>;
}

export const RunReportContext: React.Context<IRunReportContext> = React.createContext<IRunReportContext>(
  {} as IRunReportContext,
);

export function useRunReportUpdate() {
  return React.useContext<IRunReportContext>(RunReportContext);
}
