import styled from 'styled-components';

export enum ETagColor {
  BLUE = '#0668B2',
  DARK_BLUE = '#040444',
  GREEN = '#4BB667',
  GREY = '#aaa',
  ORANGE = '#FF772E',
  RED = '#B8342A',
  YELLOW = '#FFCB6C',
}

const bgColors = [ETagColor.YELLOW];
const isInvertedTextColor = (color: ETagColor) => bgColors.includes(color);

interface ITagProps {
  color: ETagColor;
}

export const Tag = styled.div<ITagProps>`
  min-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  color: ${(props) => (isInvertedTextColor(props.color) ? '#000' : '#fff')};
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  text-shadow: ellipsis;
`;
