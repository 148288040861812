import { Button } from '@dna-script-inc/shared-ui-library';
import styled from 'styled-components';

const StyledIconButton = styled(Button)`
  :hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary.base.white};
  }
`;

export default StyledIconButton;
