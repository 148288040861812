import { useTranslation } from 'src/containers/i18n';

export default function useGetReportTranslations() {
  const t = useTranslation();
  return {
    'details.creator': t('runs.details.reportDetails.creator'),
    'details.endDate': t('runs.details.reportDetails.endDate'),
    'details.instrumentName': t('runs.details.reportDetails.instrument'),
    'details.instrumentVersion': t('runs.details.reportDetails.instrumentVersion'),
    'details.project': t('runs.details.reportDetails.project'),
    'details.runID': t('runs.details.reportDetails.runId'),
    'details.startDate': t('runs.details.reportDetails.startDate'),
    'details.summary.oligos': t('runs.details.reportDetails.oligos'),
    'details.summary.passingOligos': t('runs.details.plateView.passingOligos'),
    'details.summary.size': t('runs.details.plateView.sizeRange'),
    'details.summary.totalOligos': t('runs.details.plateView.totalOligos'),
    'details.table.columns.amount': t('reports.table.reportDetails.amountUsed'),
    'details.table.columns.days': t('reports.table.reportDetails.daysOnSystem'),
    'details.table.columns.expiration': t('reports.table.reportDetails.expirationDate'),
    'details.table.columns.lot': t('reports.table.reportDetails.lot'),
    'details.table.columns.part': t('reports.table.reportDetails.part'),
    'details.table.columns.plate': t('reports.table.reportDetails.plate'),
    'details.table.columns.reagent': t('reports.table.reportDetails.reagentName'),
    'details.title': t('runs.details.reportDetails.details'),
    'header.legend': t('runs.details.reportDetails.legend'),
    'legend.concentration': t('runs.details.legend.concentration'),
    'legend.mods.ACCEPTABLE': t('runs.details.legend.mod.ACCEPTABLE'),
    'legend.mods.LOW_COUPLING': t('runs.details.legend.mod.LOW_COUPLING'),
    'legend.mods.NON_QUANTIFIABLE': t('runs.details.legend.mod.NON_QUANTIFIABLE'),
    'legend.mods.NO_MODIFICATION': t('runs.details.legend.mod.NO_MODIFICATION'),
    'legend.mods.SUCCESSFUL_COUPLING': t('runs.details.legend.mod.SUCCESSFUL_COUPLING'),
    'plate.concentration.tabs.actual': t('runs.details.plateView.concentration.tab.actual'),
    'plate.concentration.tabs.target': t('runs.details.plateView.concentration.tab.target'),
    'plate.type.unsupported': t('runs.details.unsupportedPlateType'),
    'preview.empty': t('runs.details.aside.tab.inspector.desc'),
    'preview.sections.concentration': t('runs.details.plateView.tab.concentration'),
    'preview.sections.concentrationSet': t('runs.details.set.concentration'),
    'preview.sections.lastUpdate': t('runs.details.aside.lastUpdated'),
    'preview.sections.leading': t('runs.plateEditor.popper.header.prefix'),
    'preview.sections.nts': t('runs.plateEditor.popper.header.nts'),
    'preview.sections.preview': t('runs.details.aside.sequencePreview'),
    'preview.sections.score': t('runs.plateEditor.header.info.score.title'),
    'preview.sections.sequence': t('runs.plateEditor.header.info.sequence.title'),
    'preview.sections.sequenceName': t('runs.plateEditor.editWindow.sequenceName.title'),
    'preview.sections.volume': t('runs.details.aside.actualVolume'),
    'preview.sections.yield': t('runs.details.plateView.tab.yield'),
    'preview.title': t('runs.details.aside.tab.inspector'),
    'tabs.concentation': t('runs.details.plateView.tab.concentration'), // TODO: rename to concentration
    'tabs.details': t('runs.details.plateView.details'),
    'tabs.modifications': t('runs.details.plateView.tab.modifications'),
    'tabs.volume': t('runs.details.plateView.tab.volume'),
    'tabs.yield': t('runs.details.plateView.tab.yield'),
  };
}
