/* eslint-disable @typescript-eslint/unbound-method */
import { TimeWarningModal } from '@dna-script-inc/shared-ui-library';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ERoutes } from 'src/config/routes';
import { useTranslation, Translate } from 'src/containers/i18n';
import { useShouldShowTimezoneWarning } from 'src/hooks/useShouldShowTimezoneWarning';
import useUserContext from 'src/hooks/useUserContext';

interface ITimezoneWarningModalProps {
  onCancel: () => void;
}

export const TimezoneWarningModal = ({ onCancel }: ITimezoneWarningModalProps) => {
  const t = useTranslation();
  const { userProfile } = useUserContext();
  const { shouldShowTimezoneWarning, clearTimezoneWarningFlag } = useShouldShowTimezoneWarning();

  const history = useHistory();
  const handleSubmit = () => {
    clearTimezoneWarningFlag();
    history.push(ERoutes.PROFILE);
  };

  const handleClose = useCallback(() => {
    clearTimezoneWarningFlag();
    onCancel();
  }, [onCancel, clearTimezoneWarningFlag]);

  return (
    <TimeWarningModal
      title={t('accounts.timezone.modal.warning.title')}
      isOpen={shouldShowTimezoneWarning}
      onSubmit={handleSubmit}
      onReject={handleClose}
      rejectButtonTitle={t('common.button.cancel')}
      submitButtonTitle={t('accounts.timezone.modal.warning.btn')}
    >
      <Translate
        id="accounts.timezone.modal.warning.body"
        data={{ serverTimeZone: userProfile?.timeZone?.label || '' }}
      />
    </TimeWarningModal>
  );
};
