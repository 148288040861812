import { Paper } from '@mui/material';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  border-radius: 8px;
  height: calc(100vh - 400px);
  margin-top: 16px;
  padding: 8px;
  width: 100%;
`;

export const StyledPaperWithMorePadding = styled(Paper)`
  border-radius: 8px;
  padding: 16px;
`;
