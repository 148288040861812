/* eslint-disable react/display-name */
import isEqual from 'lodash-es/isEqual';
import React, { memo } from 'react';

import { SequenceChart } from 'src/components/sequences/sequence/SequenceChart/index';
import EmptySequence from 'src/components/sequences/sequence/emptySequence';
import { useWellSequenceScore } from 'src/stores/sequenceEditor/hooks';

import { ISequenceItemProps } from '../SequenceChart/SequenceChart.interfaces';

const sequenceItemPropsAreEqual = (prevSequenceProps: ISequenceItemProps, nextSequenceProps: ISequenceItemProps) => {
  return (
    prevSequenceProps.isSelected === nextSequenceProps.isSelected &&
    isEqual(prevSequenceProps?.sequence, nextSequenceProps?.sequence)
  );
};

export const SequenceItem = memo(({ sequence, isSelected, kitType }: ISequenceItemProps) => {
  const score = useWellSequenceScore(sequence?.well);

  if (!sequence?.nucChunks?.length) {
    return <EmptySequence score={score} />;
  }

  return <SequenceChart isSelected={isSelected} kitType={kitType} sequence={sequence} />;
}, sequenceItemPropsAreEqual);
