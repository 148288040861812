/* eslint-disable react/display-name */
import { Button, DNAButtonProps, IconFilePlus03 } from '@dna-script-inc/shared-ui-library';
import React from 'react';

type TNewFileButtonProps = Omit<DNAButtonProps, 'variant' | 'destructive' | 'startIcon' | 'endIcon'>;

const NewFileButton = React.forwardRef((props: TNewFileButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  return <Button {...props} variant="primary" ref={ref} startIcon={<IconFilePlus03 />} />;
});

NewFileButton.defaultProps = {
  size: 'md',
};

export default NewFileButton;
