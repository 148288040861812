import React from 'react';

import { II18nData, TTranslationKeys } from 'src/i18n';

export type TToastPayload<T = TTranslationKeys> = {
  isError?: boolean;
  persist?: boolean;
  text?: string | string[];
  textToTranslate?: TTranslationKeys;
  timeout?: number;
  title?: T;
  titleNoTranslate?: string;
  titleData?: II18nData;
};

export interface IToastItem extends TToastPayload {
  id: string;
}

export interface IToastContext {
  current: {
    toastShow: <T>(payload: TToastPayload<T>) => void;
  };
}

const ToastContext: React.Context<IToastContext> = React.createContext<IToastContext>({
  current: {
    toastShow: () => undefined,
  },
});

export default ToastContext;
