import { graphql } from 'src/gql';

export const QUERY_INSTRUMENTS_LIST = graphql(`
  query InstrumentsList($page: Int!, $pageSize: Int!, $sortField: String!, $sortDirection: Direction!) {
    getAllInstruments(
      filter: { page: { page: $page, size: $pageSize }, sort: { field: $sortField, direction: $sortDirection } }
    ) {
      data {
        id
        name
        serialNumber
        version
        model {
          id
          name
          supportedSoftware
        }
        image
        installationDate
        lastUpdatedDate
        serviceDate
        location
        status
      }
      totalElementsCount
    }
  }
`);

export const QUERY_INSTRUMENT_DETAILS = graphql(`
  query InstrumentsDetails($id: ID!) {
    getInstrumentDetailsById(id: $id) {
      id
      image
      installationDate
      instrumentType
      kitType
      lastUpdatedDate
      location
      mqttPassword
      name
      restrictedAccess
      serialNumber
      serviceDate
      serviceReminder
      softwareVersion
      status
      version
      versionsUpdateMode
      model {
        id
        name
        supportedSoftware
      }
      members {
        id
        firstName
        lastName
        permission
      }
    }
  }
`);

export const QUERY_INSTRUMENT_STATUS = graphql(`
  query InstrumentStatus($id: ID!) {
    getInstrumentStatus(id: $id) {
      reagents {
        reagentUsageUnion
        volumePercentageLeft
        expirationDate
        isUsable
      }
      cycles {
        plateType
        cyclesCount
      }
    }
  }
`);

export const QUERY_INSTRUMENT_QUEUE = graphql(`
  query InstrumentQueue($instrumentId: ID!, $startDate: String!, $endDate: String!) {
    getInstrumentsInQueue(filter: { instrumentId: $instrumentId, startDate: $startDate, endDate: $endDate }) {
      id
      name
      startDate
      endDate
    }
  }
`);

export const QUERY_INSTRUMENT_HISTORY = graphql(`
  query InstrumentHistory($instrumentId: ID!, $startDate: String!, $endDate: String!) {
    getRunHistoryPerInstrument(
      instrumentFilterDTO: { instrumentId: $instrumentId, startDate: $startDate, endDate: $endDate }
    ) {
      data {
        completedDate
        countOfMods
        countOfPassedSequences
        countOfTotalSequences
        id
        longestSequence
        metaLink
        name
        percentOfPassedOligos
        project {
          id
          name
        }
        reportMetaLink
        shortestSequence
        startDate
        status
        creator {
          id
          firstName
          lastName
        }
        kitType
        parentIDNAId
        scheduledStartDate
        endDate
        updatedAt
        customProcess {
          id
          name
        }
      }
      totalElementsCount
    }
  }
`);

export const QUERY_INSTRUMENTS_FOR_PRINT = graphql(`
  query InstrumentsForPrint($templateId: ID!) {
    findSuitableInstrumentsForPrint(templateId: $templateId) {
      id
      name
    }
  }
`);

export const QUERY_VALIDATE_INSTRUMENT_KIT_TYPE = graphql(`
  query ValidateInstrumentKitType($templateId: ID!, $instrumentId: ID!, $description: String) {
    validateInstrumentKitType(
      inputData: { templateId: $templateId, instrumentId: $instrumentId, description: $description }
    ) {
      result
      message
    }
  }
`);

export const QUERY_INSTRUMENTS_MODELS = graphql(`
  query InstrumentsModels {
    getInstrumentModels {
      id
      name
      version
      supportedSoftware
    }
  }
`);

export const QUERY_GET_INSTRUMENT_LOG = graphql(`
  query GetInstrumentLogById($logId: ID!) {
    getInstrumentLogById(logId: $logId) {
      id
      fromDate
      toDate
      downloadUrl
      status
      errors
      userId
    }
  }
`);
