import IconClose from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';

import { IToastItem } from './ToastContext';

const ToastWrapper = styled.div<{ isError?: boolean }>`
  position: relative;
  width: 360px;
  border-left: 4px solid
    ${(props) => (props.isError ? props.theme.colors.primary.error[600] : props.theme.colors.secondary.green[400])};
  padding: 12px 20px;
  border-radius: 5px;
  background-color: #fff;
  z-index: 9999;
  opacity: 0;
  animation: opacity 0.5s forwards;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08), 0 7px 10px rgba(0, 0, 0, 0.08), 0 4px 5px rgba(0, 0, 0, 0.16);
  word-break: break-word;
  margin-bottom: 8px;

  @keyframes opacity {
    100% {
      opacity: 1;
    }
  }
`;

const ToastTitle = styled.h5`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`;

const ToastText = styled.p`
  margin-top: 8px;
  font-size: 15px;
  line-height: 24px;
  white-space: break-spaces;
`;

const ToastClose = styled.div`
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
`;

interface IToastComponentProps {
  onClose: (id: string) => void;
}

const ToastComponent: React.FC<IToastItem & IToastComponentProps> = ({
  title,
  titleData,
  titleNoTranslate,
  text,
  textToTranslate,
  isError,
  onClose,
  id,
}) => {
  const textNode = Array.isArray(text) ? (
    <>
      {text.map((t) => (
        <p key={t}>{t}</p>
      ))}
    </>
  ) : (
    text
  );
  const handleclose = useCallback(() => {
    onClose(id);
  }, [onClose, id]);

  return (
    <ToastWrapper isError={isError}>
      {title ? (
        <ToastTitle id="toastTitle">
          <Translate id={title} data={titleData} />
        </ToastTitle>
      ) : (
        <ToastTitle id="toastTitle">
          <p>{titleNoTranslate}</p>
        </ToastTitle>
      )}

      {textToTranslate ? (
        <ToastText id="toastText">
          <Translate id={textToTranslate} />
        </ToastText>
      ) : (
        <ToastText id="toastText">{textNode}</ToastText>
      )}
      <ToastClose>
        <IconButton id="toastClose" color="primary" size="small" onClick={handleclose}>
          <IconClose />
        </IconButton>
      </ToastClose>
    </ToastWrapper>
  );
};

export default ToastComponent;
