import { useLazyQuery, useMutation } from '@apollo/client';
import get from 'lodash-es/get';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { SequenceInfoDTO, SequenceStatus } from '__generated__/globalTypes';
import useTranslation from 'src/containers/i18n/useTranslation';
import { useToast } from 'src/containers/toast';
import { useUserContext } from 'src/hooks';
import { useTabs } from 'src/hooks/useTabs';
import { MUTATION_PLATE_UPDATE } from 'src/services/gql/mutations/templates';
import { VALIDATE_IDNA_TEMPLATE } from 'src/services/gql/queries/templates';
import { useCurrentTemplate, useSelectedSequenceState } from 'src/stores/sequenceEditor/hooks';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';
import { TPlateDetailsRaw } from 'src/types';
import { getErrorMessage } from 'src/utils/errors';
import { StackSequencesService } from 'src/utils/nucleotide';

export enum EPlateDetailsTab {
  EDIT,
  PLATE,
}

const IDNA_NAME_ERROR = 'iDNA Plate named';

function getSequenceInfoDTO(sequence: IPartialSequence): SequenceInfoDTO {
  return {
    data: sequence.dataChunks?.join('') || '',
    id: sequence.id,
    name: sequence.name || '',
    status: (sequence.data || '').trim().length > 0 ? sequence.status : SequenceStatus.DELETED,
    well: sequence.well || '',
    wellIndex: sequence.wellIndex,
  } as SequenceInfoDTO;
}

function excludeEmptyWells(sequence: IPartialSequence): boolean {
  return (sequence.data ?? '').trim().length > 0 || Boolean(sequence.id);
}

function formatWellsForSave(sequences: IPartialSequence[] | null) {
  if (sequences) {
    return sequences.filter(excludeEmptyWells)?.map(getSequenceInfoDTO);
  }
  return [];
}

export const usePlateDetailsLogic = () => {
  const t = useTranslation();
  const showToast = useToast();
  const { tab, handleChangeTab, setTab } = useTabs(EPlateDetailsTab.EDIT);
  const selectedSequence = useSelectedSequenceState();
  const currentPlate = useCurrentTemplate();

  const {
    templateDetails: plate,
    updateTemplateDetails: updatePlate,
    updateWellSequence: updatePlateSequence,
    setLoading,
    initializeTemplateDetails,
  } = useSequenceEditorStore();

  const [updatePlateMutation, { loading: loadingUpdatePlate }] = useMutation(MUTATION_PLATE_UPDATE, {
    // refetchQueries: [ 'PlateById' ],
    //  awaitRefetchQueries: true,
    onCompleted: (data) => {
      initializeTemplateDetails(data?.updateIDNATemplate as TPlateDetailsRaw);
      showToast({
        textToTranslate: 'plates.detailsPage.detail.update.success.description',
        title: 'plates.detailsPage.detail.update.success.title',
      });
      if (plate?.id) {
        StackSequencesService.clearStackById(plate.id);
      }
    },
    onError(e) {
      const errMsg = getErrorMessage(e);

      showToast({
        isError: true,
        text: errMsg.includes(IDNA_NAME_ERROR) ? errMsg : t('plates.detailsPage.details.update.error.general'),
        title: 'common.alert.errors.title',
      });
    },
  });

  const [validateIDNATemplate, { loading: validationLoading }] = useLazyQuery(VALIDATE_IDNA_TEMPLATE, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const validatedIDNATemplateUpdate = get(data, 'validateIDNATemplateUpdate');
      const validatedSequences = get(validatedIDNATemplateUpdate, 'sequences');

      const hasError = validatedSequences?.some((seq) => seq?.status === SequenceStatus.ERROR);

      if (hasError) {
        initializeTemplateDetails(validatedIDNATemplateUpdate as TPlateDetailsRaw);
      }

      if (!currentPlate?.id) {
        return;
      }
      updatePlateMutation({
        variables: {
          inputData: {
            name: currentPlate?.name,
            sequences: formatWellsForSave(currentPlate?.sequences as ISequence[]),
            templateId: currentPlate.id,
          },
        },
      });
    },
    onError(e) {
      showToast({
        isError: true,
        text: getErrorMessage(e),
        title: 'common.alert.errors.title',
      });
    },
  });

  const reactHookUseForm = useForm({
    defaultValues: {
      name: plate?.name,
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const handleSave = useCallback(() => {
    if (!currentPlate) {
      return;
    }
    if (currentPlate.name?.length === 0) {
      setTab(EPlateDetailsTab.EDIT);
      showToast({
        isError: true,
        textToTranslate: 'plates.detailsPage.details.update.error.emptyName',
        title: 'plates.detailsPage.detail.update.error.title',
      });
      setTimeout(() => {
        reactHookUseForm.trigger();
      }, 0);
      return;
    }

    if (currentPlate.sequences?.every((x) => x?.data?.trim().length === 0)) {
      showToast({
        isError: true,
        textToTranslate: 'plates.detailsPage.details.update.error.allWellsEmpty',
        title: 'plates.detailsPage.detail.update.error.title',
      });
      return;
    }

    setLoading(true);
    validateIDNATemplate({
      variables: {
        update: {
          name: currentPlate.name,
          sequences: formatWellsForSave(currentPlate.sequences as ISequence[]),
          templateId: currentPlate.id,
        },
      },
    });

    setLoading(false);
  }, [currentPlate, setLoading, validateIDNATemplate, setTab, showToast, reactHookUseForm]);

  const onSequenceChange = useCallback(
    (value: string) => {
      if (!selectedSequence) {
        return;
      }
      updatePlateSequence(selectedSequence.well, { chunks: value.split(''), sequence: value });
    },
    [selectedSequence, updatePlateSequence],
  );

  const {
    userPermissions: {
      plateEditor: { canAccessPlateSettings },
    },
  } = useUserContext();

  return {
    canAccessPlateSettings,
    handleChangeTab,
    handleSave,
    handleSubmit: reactHookUseForm.handleSubmit,
    loadingUpdatePlate,
    onSequenceChange,
    plate,
    reactHookUseForm,
    setTab,
    tab,
    updatePlate,
    validationLoading,
  };
};
