/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { differenceWith, isEqual, reduce } from 'lodash-es';

import { TSequenceInGrid } from 'src/components/sequences/hooks/types';

import { TWell } from '../../create/types';

const WELL_STATUSES = ['nominal', 'warning', 'error'] as const;
export type TWellStatus = (typeof WELL_STATUSES)[number];
type TWellWithPosition = TWell & Pick<TSequenceInGrid, 'wellPosition' | 'wellIndex'> & { status: TWellStatus };

export const selectUpdatedWellAndReturnChange = (newPlateValue: TWellWithPosition[], plate: TWellWithPosition[]) => {
  const differentRows = differenceWith(newPlateValue, plate, isEqual);
  if (differentRows.length !== 1) {
    return null;
  }
  const newRow = differentRows[0];
  const oldRow = plate.find((p) => p.well === newRow.well);
  const propertyChanges: Record<string, TWellWithPosition[keyof TWellWithPosition]> = reduce(
    newRow,
    (result, value, key) => {
      if (value !== oldRow?.[key as keyof TWellWithPosition]) {
        return { ...result, [key]: value };
      }
      return result;
    },
    {},
  );
  const propertyKeys = Object.keys(propertyChanges);
  if (propertyKeys.length === 0) {
    return null;
  }

  return propertyKeys.map((propertyChanged) => {
    const newPropertyValue = propertyChanges[propertyChanged];
    return { newPropertyValue, propertyChanged, well: newRow.well };
  });
};
