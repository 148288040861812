import { DateTime } from '@dna-script-inc/shared-ui-library';
import React from 'react';

import { NormalizationType } from '__generated__/globalTypes';
import { GetPlateConfigsQuery } from 'src/gql/graphql';
import { IModsAndClicksStructure } from 'src/services/gql/models/run';
import { TTemplateDetails } from 'src/stores/sequenceEditor/types';

export interface ISuggestedSlot {
  endDate?: DateTime;
  instrumentId?: string;
  startDate: DateTime;
}

export interface ISetTemplateSettingsParams {
  normalizationType: NormalizationType;
  targetConcentration?: number;
  templateName: string;
  usePlateNorm?: boolean;
}

export interface IRunContext {
  composeModsChunksFromString: (str: string) => string[];
  dataConfigs?: GetPlateConfigsQuery;
  isUsingPlateView: boolean;
  loadingSaveTemplate: boolean;
  modsAndClicksRegex: RegExp;
  modsAndClicksStructure: IModsAndClicksStructure | undefined;
  printDetail: ICreateProjectRun | null;
  projectDetail: IProjectDetail | null;
  refetchTemplate: () => void;
  setIsUsingPlateView: (isUsingPlateView: boolean) => void;
  setLoadingSaveTemplate: (loadingSaveTemplate: boolean) => void;
  setPrintDetail: (printDetail: ICreateProjectRun | null) => void;
  setStep: (step: number) => void;
  setSuggestedSlot: (slot: ISuggestedSlot | null) => void;
  step: number;
  suggestedSlot: ISuggestedSlot | null;
  templateDetail: TTemplateDetails | undefined;
  templateName: string;
}

const RunContext: React.Context<IRunContext> = React.createContext<IRunContext>({} as IRunContext);

export default RunContext;
