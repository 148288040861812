import { useQuery } from '@apollo/client';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { AccessDenied, CenteredSpinner } from 'src/components/common';
import { PageWrapper } from 'src/components/layout';
import { useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import ProjectMainForm from 'src/pages/projects/components/ProjectMainForm';
import ProjectUsersForm from 'src/pages/projects/components/ProjectUsersForm';
import { ProjectManageProvider, IProjectManage } from 'src/pages/projects/containers/projectManage';
import { QUERY_PROJECT_DETAIL } from 'src/services/gql/queries/projects';
import { getPermissionForProject } from 'src/utils/permissions/project';
import { isNotEmpty } from 'src/utils/ui';

import ProjectsManageHeader from './components/ProjectsManageHeader';

const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    border-bottom: 2px solid #cfd8dc;
  }
`;

type TProjectsManagePageTab = 'details' | 'users';

interface IMatchParams {
  id: string;
}

const ProjectsManagePage = ({ match }: RouteComponentProps<IMatchParams>) => {
  const t = useTranslation();
  const { id } = match.params;
  const [tab, setTab] = React.useState<TProjectsManagePageTab>('details');
  const { userPermissions, userProfile } = useUserContext();
  const {
    project: { canEditProject },
  } = userPermissions;

  const handleChangeTab = React.useCallback((_event: React.SyntheticEvent, newValue: TProjectsManagePageTab) => {
    setTab(newValue);
  }, []);

  const { loading, data, error } = useQuery(QUERY_PROJECT_DETAIL, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  const project: IProjectManage = React.useMemo<IProjectManage>(() => {
    if (data) {
      const result = data.getProjectDetailsById;
      if (!result?.owner || !result.admin) {
        return {} as IProjectManage;
      }
      return {
        admin: {
          firstName: result.admin.firstName || '',
          id: result.admin.id,
          lastName: result.admin.lastName || '',
        },
        budget: result.budget || 0,
        description: result.description || '',
        id: result.id,
        members: (result.members ?? []).filter(isNotEmpty).map((member) => ({
          accessPermission: member.accessPermission || '',
          firstName: member.firstName || '',
          id: member.id,
          lastName: member.lastName || '',
          roles:
            member.roles?.filter(isNotEmpty).map((role) => ({
              id: role.id,
              name: role.name,
              type: role.type,
            })) || [],
        })),
        name: result.name || '',
        owner: {
          firstName: result.owner.firstName || '',
          id: result.owner.id,
          lastName: result.owner.lastName || '',
        },
        restrictedAccess: result.restrictedAccess,
      };
    }
    return {} as IProjectManage;
  }, [data]);

  const permissions = React.useMemo(() => getPermissionForProject(userProfile, project), [userProfile, project]);

  if (loading) {
    return <CenteredSpinner />;
  }

  if (error) {
    return <AccessDenied error={error.message} />;
  }

  if (!canEditProject && !permissions.projects.settings.canManageAccess) {
    return <AccessDenied />;
  }

  return (
    <ProjectManageProvider project={project}>
      <PageWrapper>
        <ProjectsManageHeader />
        <StyledTabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}>
          {canEditProject && <Tab id="detailsTab" value="details" label={t('projects.manage.tabs.details')} />}
          {permissions.projects.settings.canManageAccess && (
            <Tab id="usersTab" value="users" label={t('projects.manage.tabs.users')} />
          )}
        </StyledTabs>
        {tab === 'details' && <ProjectMainForm />}
        {tab === 'users' && <ProjectUsersForm />}
      </PageWrapper>
    </ProjectManageProvider>
  );
};

export default ProjectsManagePage;
