import React, { useState, useContext, useMemo } from 'react';

import { ModalOnHoverContext } from 'src/components/modalOnHover/Context/ModalOnHoverContext';

export const ModalOnHoverProvider = ({ children }: React.PropsWithChildren<Record<string, unknown>>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);

  const contextValue = useMemo(
    () => ({
      anchorEl,
      arrowRef,
      setAnchorEl,
      setArrowRef,
    }),
    [anchorEl, arrowRef],
  );

  return <ModalOnHoverContext.Provider value={contextValue}>{children}</ModalOnHoverContext.Provider>;
};

export const useModalOnHoverContext = () => useContext(ModalOnHoverContext);
