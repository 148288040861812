import React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import imageWallpaper from 'src/resources/images/wallpaper_optimized.png';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Wallpaper = styled.div`
  position: sticky;
  top: 0;
  background: url(${imageWallpaper}) repeat-y;
  height: 100vh;
  width: 422px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Banner = styled.div`
  background-color: #050736;
  padding-bottom: 15px;
`;

const BannerTextWrapper = styled.div`
  margin: 30px;
  font-family: 'DINNext';
  color: #fff;
`;

const BannerLine = styled.div`
  height: 5px;
  background-color: ${(props) => props.theme.palette.secondary.main};
`;

const BannerTitle = styled.h2`
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
`;

const BannerText = styled.p`
  font-weight: 300;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding: 40px 0;
  overflow: auto;
  max-height: 100%;
`;

const ContentInner = styled.div`
  width: 500px;
`;

interface IAuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<IAuthLayoutProps> = ({ children }) => (
  <Wrapper>
    <Wallpaper>
      <Banner>
        <BannerTextWrapper>
          <BannerTitle>
            <Translate id="unauthorized.title" />
          </BannerTitle>
          <BannerText>
            <Translate id="unauthorized.description" />
          </BannerText>
        </BannerTextWrapper>
        <BannerLine />
      </Banner>
    </Wallpaper>
    <Content>
      <ContentInner>{children}</ContentInner>
    </Content>
  </Wrapper>
);

export default AuthLayout;
