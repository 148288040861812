import { Tooltip } from '@dna-script-inc/shared-ui-library';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Typography from '@mui/material/Typography';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import styled from 'styled-components';

import { Translate, useTranslation } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

const StyledNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function FileName({ cellValue, displayFileIcon = true }: { cellValue: React.ReactNode; displayFileIcon?: boolean }) {
  const cellWithTooltip = (
    <Tooltip placement="bottom-start" title={cellValue}>
      <Typography color="textPrimary" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        <span>{cellValue}</span>
      </Typography>
    </Tooltip>
  );

  if (!displayFileIcon) {
    return cellWithTooltip;
  }
  return (
    <StyledNameContainer>
      <InsertDriveFileIcon sx={{ marginRight: '8px' }} />
      {cellWithTooltip}
    </StyledNameContainer>
  );
}

interface IUseGetNameColumnParams {
  headerTitle: TTranslationKeys;
  filterTitle: TTranslationKeys;
  displayFileIcon?: boolean;
}

function useGetNameColumn<
  T extends {
    name?: string | null;
  },
>({ headerTitle, filterTitle, displayFileIcon = true }: IUseGetNameColumnParams): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Cell: ({ renderedCellValue }) => <FileName cellValue={renderedCellValue} displayFileIcon={displayFileIcon} />,
    Header: () => <Translate id={headerTitle} testid="name-header" />,
    accessorFn: (row) => row.name ?? '',
    header: t(headerTitle),
    muiTableBodyCellProps: { sx: { whiteSpace: 'nowrap' } },
    muiTableHeadCellFilterTextFieldProps: { placeholder: t(filterTitle) },
    size: 200,
  };
}

export default useGetNameColumn;
