import Tabs from '@mui/material/Tabs';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  margin-top: 24px;

  .MuiTabs-flexContainer {
    border-bottom: 2px solid #cfd8dc;
  }
`;

export default StyledTabs;
