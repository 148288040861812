/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An arbitrary precision signed decimal */
  BigDecimal: { input: any; output: any };
  /** A 64-bit signed integer */
  Long: { input: any; output: any };
};

export enum AccessPermission {
  ADMIN = 'ADMIN',
  SERVICE = 'SERVICE',
  WRITE = 'WRITE',
}

export type ActiveModsAndClicksDto = {
  __typename?: 'ActiveModsAndClicksDTO';
  modsAndClicks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  validClickPairs?: Maybe<Array<Maybe<DoubleClickPair>>>;
};

export type AdditionalItemDto = {
  __typename?: 'AdditionalItemDTO';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressDto = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AddressInputDto = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type AttachmentDto = {
  content?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentLinkDto = {
  __typename?: 'AttachmentLinkDTO';
  id?: Maybe<Scalars['ID']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum CentrifugationType {
  DEFAULT = 'DEFAULT',
  ELUTION = 'ELUTION',
  QUANTIFICATION = 'QUANTIFICATION',
}

export type CloningVectorDto = {
  __typename?: 'CloningVectorDTO';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sequence: Scalars['String']['output'];
};

export type ConcentrationLimitsDto = {
  __typename?: 'ConcentrationLimitsDTO';
  defaultValue: Scalars['BigDecimal']['output'];
  kitType?: Maybe<KitType>;
  max: Scalars['BigDecimal']['output'];
  min: Scalars['BigDecimal']['output'];
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  workNumber?: Maybe<Scalars['String']['output']>;
};

export type ContactDto = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum ContentFormat {
  CSV = 'CSV',
  FASTA = 'FASTA',
}

export enum ContentType {
  MANUAL = 'MANUAL',
  UPLOAD = 'UPLOAD',
}

export enum CoolerDoorStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  UNKNOWN = 'UNKNOWN',
}

export type CoolerStatusDto = {
  __typename?: 'CoolerStatusDTO';
  doorStatus?: Maybe<CoolerDoorStatus>;
  isTrayPresent?: Maybe<Scalars['Boolean']['output']>;
  sippersStatus?: Maybe<SipperStatus>;
  temperature?: Maybe<Scalars['Float']['output']>;
};

export enum CouplingLevel {
  ACCEPTABLE = 'ACCEPTABLE',
  LOW = 'LOW',
  SUCCESSFUL = 'SUCCESSFUL',
}

export type CouplingRateDto = {
  __typename?: 'CouplingRateDTO';
  nucleotide: Scalars['String']['output'];
  rate: Scalars['BigDecimal']['output'];
};

export type CreateAndStartRunDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type CreateGroupDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateIdnaPlateDto = {
  content: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  kitType?: InputMaybe<KitType>;
  plateType?: InputMaybe<PlateType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateInstrumentDto = {
  admin?: InputMaybe<EntityByIdDto>;
  installationDate?: InputMaybe<Scalars['String']['input']>;
  instrumentType?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Array<InputMaybe<EntityByIdDto>>>;
  modelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  printJobVersion?: InputMaybe<PrintJobVersion>;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  serialNumber: Scalars['String']['input'];
  serviceReminder?: InputMaybe<Scalars['Boolean']['input']>;
  systemVersion: InstrumentVersion;
};

export type CreateProjectDto = {
  admin: EntityByIdDto;
  badge?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner: EntityByIdDto;
  projectType: ProjectType;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<EntityByIdDto>>>;
};

export type CreateTemplateDto = {
  content: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  kitType?: InputMaybe<KitType>;
  normalizationType?: InputMaybe<NormalizationType>;
  parentPlateId?: InputMaybe<Scalars['ID']['input']>;
  plateType?: InputMaybe<PlateType>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectType?: InputMaybe<ProjectType>;
  runBioSecurityService?: InputMaybe<Scalars['Boolean']['input']>;
  runPurityEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  targetConcentration?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<ContentType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CrossElutionDto = {
  __typename?: 'CrossElutionDTO';
  wellsFrom?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  wellsTo?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CustomProcessCreateDto = {
  data: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CustomProcessInfoDto = {
  __typename?: 'CustomProcessInfoDTO';
  downloadUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['ID']['output']>;
};

export type CustomProcessPageDto = {
  __typename?: 'CustomProcessPageDTO';
  data?: Maybe<Array<Maybe<CustomProcessInfoDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type CustomProcessUpdateDto = {
  data: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type DeckDto = {
  __typename?: 'DeckDTO';
  isLidPresented?: Maybe<Scalars['Boolean']['output']>;
  isTipboxPresented?: Maybe<Scalars['Boolean']['output']>;
};

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum DoorStatus {
  CLOSED_LOCKED = 'CLOSED_LOCKED',
  CLOSED_UNLOCKED = 'CLOSED_UNLOCKED',
  OPEN = 'OPEN',
  UNKNOWN = 'UNKNOWN',
}

export type DoubleClickPair = {
  __typename?: 'DoubleClickPair';
  fluorophore?: Maybe<Scalars['String']['output']>;
  quencher?: Maybe<Scalars['String']['output']>;
};

export type DynamicSecurityRequest = {
  command: UserOperation;
  instrumentId: Scalars['String']['input'];
};

export type DynamicSecurityResponse = {
  __typename?: 'DynamicSecurityResponse';
  instrumentId?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
};

export type EntityByIdDto = {
  id: Scalars['ID']['input'];
};

export type ErrorDto = {
  __typename?: 'ErrorDTO';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type GeneDetailDto = {
  __typename?: 'GeneDetailDTO';
  vector?: Maybe<CloningVectorDto>;
  vectorPrimers?: Maybe<Array<Scalars['String']['output']>>;
};

export type GeneRunSchedulerCreateDto = {
  instrumentId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type GroupByIdDto = {
  __typename?: 'GroupByIdDTO';
  creator?: Maybe<UserInGroupDto>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<UserInGroupDto>>>;
};

export type GroupByIdWithUserDetailsDto = {
  __typename?: 'GroupByIdWithUserDetailsDTO';
  creator?: Maybe<UserInGroupDto>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<UserForGroupByIdDto>>>;
};

export type GroupModel = {
  __typename?: 'GroupModel';
  creatorFirstName?: Maybe<Scalars['String']['output']>;
  creatorId?: Maybe<Scalars['ID']['output']>;
  creatorLastName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  members?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  totalGroupMembers?: Maybe<Scalars['Int']['output']>;
};

export type GroupPageDto = {
  __typename?: 'GroupPageDTO';
  data?: Maybe<Array<Maybe<GroupByIdDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupsFilterDto = {
  page: PageInfoDto;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort: SortInfoDto;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum HardwareVersion {
  UNSUPPORTED = 'UNSUPPORTED',
  V0 = 'V0',
  V1 = 'V1',
}

export type IdnaDetailsDto = {
  __typename?: 'IDNADetailsDTO';
  designUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type InstallationFileFilterDto = {
  page: PageInfoDto;
  sort: SortInfoDto;
};

export type InstallationFileInfoDto = {
  __typename?: 'InstallationFileInfoDTO';
  fileName?: Maybe<Scalars['String']['output']>;
  releaseDate?: Maybe<Scalars['String']['output']>;
  releaseVersion?: Maybe<Scalars['String']['output']>;
};

export type InstallationFilePageDto = {
  __typename?: 'InstallationFilePageDTO';
  data?: Maybe<Array<Maybe<InstallationFileInfoDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type InstrumentByIdDto = {
  __typename?: 'InstrumentByIdDTO';
  id?: Maybe<Scalars['ID']['output']>;
  modelId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  printJobVersion?: Maybe<PrintJobVersion>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  version?: Maybe<InstrumentVersion>;
};

export type InstrumentComponentStatusDto = {
  __typename?: 'InstrumentComponentStatusDTO';
  cooler?: Maybe<CoolerStatusDto>;
  /** # 0 to 100 (%) */
  door?: Maybe<DoorStatus>;
  enclosureTemperature?: Maybe<Scalars['Float']['output']>;
  initialization?: Maybe<Scalars['Int']['output']>;
  /** # Celsius */
  stations?: Maybe<Array<Maybe<StationStatusDto>>>;
  waste?: Maybe<WasteStatusDto>;
};

/** ############# */
export type InstrumentConsumablesStatusDto = {
  __typename?: 'InstrumentConsumablesStatusDTO';
  accessoryRackVials?: Maybe<Array<Maybe<ReagentDetailedStatusDto>>>;
  kitType?: Maybe<KitType>;
  reagentBottles?: Maybe<Array<Maybe<ReagentDetailedStatusDto>>>;
  remainingCycles?: Maybe<Array<Maybe<InstrumentRemainingCyclesDto>>>;
};

export type InstrumentDto = {
  __typename?: 'InstrumentDTO';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['String']['output']>;
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  model?: Maybe<InstrumentModelDto>;
  name?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  serviceDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  version?: Maybe<InstrumentVersion>;
};

export type InstrumentDetailedStatusDto = {
  __typename?: 'InstrumentDetailedStatusDTO';
  componentStatus?: Maybe<InstrumentComponentStatusDto>;
  consumablesStatus?: Maybe<InstrumentConsumablesStatusDto>;
  processStatus?: Maybe<ProcessStatusDto>;
};

export type InstrumentDetailsDto = {
  __typename?: 'InstrumentDetailsDTO';
  hardwareVersion?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  installationDate?: Maybe<Scalars['String']['output']>;
  instrumentType?: Maybe<Scalars['String']['output']>;
  kitType?: Maybe<KitType>;
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  members?: Maybe<Array<Maybe<UserInInstrumentDetailsDto>>>;
  model?: Maybe<InstrumentModelDto>;
  mqttPassword?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  printJobVersion?: Maybe<PrintJobVersion>;
  restrictedAccess?: Maybe<Scalars['Boolean']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  serviceDate?: Maybe<Scalars['String']['output']>;
  serviceReminder?: Maybe<Scalars['Boolean']['output']>;
  softwareVersion?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  version?: Maybe<InstrumentVersion>;
  versionsUpdateMode?: Maybe<InstrumentVersionsUpdateMode>;
};

export type InstrumentErrorDto = {
  __typename?: 'InstrumentErrorDTO';
  /** @deprecated Is not used any more by instrument, use type/unit instead */
  code?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  mitigation?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type InstrumentFilterDto = {
  page: PageInfoDto;
  sort: SortInfoDto;
};

export type InstrumentInQueueDto = {
  __typename?: 'InstrumentInQueueDTO';
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type InstrumentInQueueFilterDto = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  instrumentId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type InstrumentKitTypeValidationResponseDto = {
  __typename?: 'InstrumentKitTypeValidationResponseDTO';
  message?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type InstrumentLogCreateDto = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  instrumentId?: InputMaybe<Scalars['ID']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type InstrumentLogDto = {
  __typename?: 'InstrumentLogDTO';
  downloadUrl?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<InstrumentLogStatus>;
  toDate?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum InstrumentLogStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  TIMED_OUT = 'TIMED_OUT',
}

export type InstrumentModelDto = {
  __typename?: 'InstrumentModelDTO';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  supportedSoftware: Array<InstrumentVersion>;
  version: HardwareVersion;
};

export type InstrumentPageDto = {
  __typename?: 'InstrumentPageDTO';
  data?: Maybe<Array<Maybe<InstrumentDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type InstrumentPerRunDto = {
  __typename?: 'InstrumentPerRunDTO';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  printJobVersion?: Maybe<PrintJobVersion>;
};

export type InstrumentReagentStatusDto = {
  __typename?: 'InstrumentReagentStatusDTO';
  expirationDate?: Maybe<Scalars['String']['output']>;
  isUsable?: Maybe<Scalars['Boolean']['output']>;
  reagentUsageUnion: ReagentUsageUnion;
  volumePercentageLeft?: Maybe<Scalars['Float']['output']>;
};

export type InstrumentRemainingCyclesDto = {
  __typename?: 'InstrumentRemainingCyclesDTO';
  cyclesCount?: Maybe<Scalars['Int']['output']>;
  plateType: PlateType;
};

export type InstrumentStatusDto = {
  __typename?: 'InstrumentStatusDTO';
  cycles?: Maybe<Array<Maybe<InstrumentRemainingCyclesDto>>>;
  reagents?: Maybe<Array<Maybe<InstrumentReagentStatusDto>>>;
};

export enum InstrumentVersion {
  UNSUPPORTED = 'UNSUPPORTED',
  V_1_2 = 'V_1_2',
  V_1_3 = 'V_1_3',
  V_1_3_5 = 'V_1_3_5',
  V_2_0 = 'V_2_0',
  V_3_0 = 'V_3_0',
  V_4_0 = 'V_4_0',
  V_5_0 = 'V_5_0',
  V_5_1 = 'V_5_1',
  V_5_3 = 'V_5_3',
  V_5_4 = 'V_5_4',
  V_5_6 = 'V_5_6',
  V_5_7 = 'V_5_7',
  V_6_0 = 'V_6_0',
}

export enum InstrumentVersionsUpdateMode {
  ANY = 'ANY',
  INSTRUMENT_ONLY = 'INSTRUMENT_ONLY',
}

export type JobDurationDto = {
  instrumentId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type KitInfoDto = {
  __typename?: 'KitInfoDTO';
  concentrationProperties: ConcentrationLimitsDto;
  kitLabel?: Maybe<Scalars['String']['output']>;
  kitType: KitType;
  plateConfig: KitPlateConfig;
};

export type KitPlateConfig = {
  __typename?: 'KitPlateConfig';
  maxCyclesPerTemplate: Scalars['Int']['output'];
  maxErrorMers: Scalars['Int']['output'];
  maxIdnaMers?: Maybe<Scalars['Int']['output']>;
  maxModsPerSequence?: Maybe<Scalars['Int']['output']>;
  maxModsPerTemplate?: Maybe<Scalars['Int']['output']>;
  maxWarningMers: Scalars['Int']['output'];
  minErrorMers: Scalars['Int']['output'];
  minIdnaMers?: Maybe<Scalars['Int']['output']>;
  minWarningMers: Scalars['Int']['output'];
  type: PlateType;
  wellCountConfig: Array<WellCountConfig>;
};

export enum KitType {
  B3_96 = 'B3_96',
  B4_96 = 'B4_96',
  B5_HMASS_96 = 'B5_HMASS_96',
  B5_HMASS_96_BULK = 'B5_HMASS_96_BULK',
  B5_HPLEX_384 = 'B5_HPLEX_384',
  B5_HPLEX_384_BULK = 'B5_HPLEX_384_BULK',
  B5_HPURE_96 = 'B5_HPURE_96',
  B5_HPURE_96_BULK = 'B5_HPURE_96_BULK',
}

export type KitTypeKeyValueDto = {
  __typename?: 'KitTypeKeyValueDTO';
  kitInfo?: Maybe<Array<Maybe<KitInfoDto>>>;
  plateType: PlateType;
};

export type LicenseContentDto = {
  __typename?: 'LicenseContentDTO';
  code?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validTill?: Maybe<Scalars['String']['output']>;
};

export type LicenseDataDto = {
  __typename?: 'LicenseDataDTO';
  code?: Maybe<Scalars['String']['output']>;
  installationName?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validTill?: Maybe<Scalars['String']['output']>;
};

export type LoginDto = {
  password: Scalars['String']['input'];
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  userName: Scalars['String']['input'];
};

export type LoginEventDto = {
  __typename?: 'LoginEventDTO';
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  type?: Maybe<LoginEventType>;
  username?: Maybe<Scalars['String']['output']>;
};

export type LoginEventFilterDto = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInfoDto>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInfoDto>;
};

export type LoginEventPageDto = {
  __typename?: 'LoginEventPageDTO';
  data?: Maybe<Array<Maybe<LoginEventDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export enum LoginEventType {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export type MachineWashDto = {
  __typename?: 'MachineWashDTO';
  type?: Maybe<MachineWashType>;
};

export enum MachineWashType {
  END_OF_KIT = 'END_OF_KIT',
  END_OF_RUN = 'END_OF_RUN',
  MAINTENANCE = 'MAINTENANCE',
  UNKNOWN = 'UNKNOWN',
}

export type MissingRequirementsDto = {
  __typename?: 'MissingRequirementsDTO';
  capsMismatch?: Maybe<Scalars['Boolean']['output']>;
  kitType?: Maybe<Scalars['String']['output']>;
  plateMissingRequirements?: Maybe<Array<Maybe<PlateRequirementDto>>>;
  reagentMissingRequirement?: Maybe<ReagentMissingRequirementDto>;
};

export enum ModStatus {
  ACCEPTABLE = 'ACCEPTABLE',
  LOW_COUPLING = 'LOW_COUPLING',
  NON_QUANTIFIABLE = 'NON_QUANTIFIABLE',
  NO_MODIFICATION = 'NO_MODIFICATION',
  SUCCESSFUL_COUPLING = 'SUCCESSFUL_COUPLING',
}

export type Mutation = {
  __typename?: 'Mutation';
  activateGroup?: Maybe<OperationResultDto>;
  addUser?: Maybe<OperationResultDto>;
  addUserToGroup?: Maybe<OperationResultDto>;
  changePassword?: Maybe<OperationResultDto>;
  changePasswordForUser?: Maybe<OperationResultDto>;
  confirmEmail?: Maybe<OperationResultDto>;
  copyIDNATemplate?: Maybe<TemplateDetailsDto>;
  createAndStartRun?: Maybe<RunSchedulerCreateResponseDto>;
  createCustomProcess?: Maybe<CustomProcessInfoDto>;
  createGeneRunScheduler?: Maybe<Array<Maybe<RunSchedulerCreateResponseDto>>>;
  createGroup?: Maybe<GroupByIdDto>;
  createIDNAPlate?: Maybe<TemplateDetailsDto>;
  createInstrument?: Maybe<InstrumentByIdDto>;
  createProject?: Maybe<ProjectByIdDto>;
  createRunScheduler?: Maybe<RunSchedulerCreateResponseDto>;
  createTemplate?: Maybe<TemplateDetailsDto>;
  deactivateRemoteUser?: Maybe<OperationResultDto>;
  deleteCustomProcess?: Maybe<OperationResultDto>;
  deleteIDNATemplate?: Maybe<OperationResultDto>;
  deleteInstrument?: Maybe<OperationResultDto>;
  deleteProject?: Maybe<OperationResultDto>;
  deleteRun?: Maybe<OperationResultDto>;
  deleteSessions?: Maybe<OperationResultDto>;
  deleteTemplate?: Maybe<OperationResultDto>;
  deleteUser?: Maybe<OperationResultDto>;
  deprecateGroup?: Maybe<OperationResultDto>;
  dynamicSecurityUserManagement?: Maybe<DynamicSecurityResponse>;
  enableUser?: Maybe<OperationResultDto>;
  forgotPassword?: Maybe<OperationResultDto>;
  inviteRemoteUser?: Maybe<OperationResultDto>;
  inviteUser?: Maybe<OperationResultDto>;
  loginAs?: Maybe<OperationResultDto>;
  logout?: Maybe<OperationResultDto>;
  markNotificationAsRead?: Maybe<OperationResultDto>;
  reactivateUser?: Maybe<OperationResultDto>;
  regenerateCredentialsForInstrument?: Maybe<OperationResultDto>;
  removeUserFromGroup?: Maybe<OperationResultDto>;
  reprintFailedWells?: Maybe<Scalars['Boolean']['output']>;
  requestInstrumentLog?: Maybe<InstrumentLogDto>;
  resendEmailActivation?: Maybe<OperationResultDto>;
  resendInvitationEmail?: Maybe<OperationResultDto>;
  resetPassword?: Maybe<OperationResultDto>;
  restoreIDNATemplate?: Maybe<OperationResultDto>;
  restoreProject?: Maybe<OperationResultDto>;
  restoreTemplate?: Maybe<OperationResultDto>;
  setPasswordForInvitedUser?: Maybe<OperationResultDto>;
  switchToOriginalOrganization?: Maybe<OperationResultDto>;
  switchToRemoteOrganization?: Maybe<OperationResultDto>;
  synchronizeInstrumentKey?: Maybe<OperationResultDto>;
  updateConcentration?: Maybe<Scalars['Boolean']['output']>;
  updateCustomProcess?: Maybe<CustomProcessInfoDto>;
  updateGroup?: Maybe<GroupByIdDto>;
  updateIDNATemplate?: Maybe<TemplateDetailsDto>;
  updateInstrument?: Maybe<InstrumentByIdDto>;
  updateOrganizationData?: Maybe<OperationResultDto>;
  updateOrganizationLicense?: Maybe<LicenseDataDto>;
  updatePqaRunInfo?: Maybe<OperationResultDto>;
  updateProject?: Maybe<ProjectByIdDto>;
  updateRemoteUser?: Maybe<OperationResultDto>;
  updateTemplate?: Maybe<TemplateDetailsDto>;
  updateUser?: Maybe<UserDetailedDto>;
  updateUserPreferences?: Maybe<OperationResultDto>;
  updateUserProfile?: Maybe<UserDetailedDto>;
  uploadRunReport?: Maybe<UploadReportResponseDto>;
};

export type MutationActivateGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAddUserArgs = {
  user: UserAddDto;
};

export type MutationAddUserToGroupArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MutationChangePasswordForUserArgs = {
  newPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationConfirmEmailArgs = {
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationCopyIdnaTemplateArgs = {
  inputData: TemplateCopyDto;
};

export type MutationCreateAndStartRunArgs = {
  inputData?: InputMaybe<CreateAndStartRunDto>;
};

export type MutationCreateCustomProcessArgs = {
  create: CustomProcessCreateDto;
};

export type MutationCreateGeneRunSchedulerArgs = {
  inputData: GeneRunSchedulerCreateDto;
};

export type MutationCreateGroupArgs = {
  groupData?: InputMaybe<CreateGroupDto>;
};

export type MutationCreateIdnaPlateArgs = {
  inputData?: InputMaybe<CreateIdnaPlateDto>;
};

export type MutationCreateInstrumentArgs = {
  inputData?: InputMaybe<CreateInstrumentDto>;
};

export type MutationCreateProjectArgs = {
  inputData: CreateProjectDto;
};

export type MutationCreateRunSchedulerArgs = {
  inputData?: InputMaybe<RunSchedulerCreateDto>;
};

export type MutationCreateTemplateArgs = {
  inputData?: InputMaybe<CreateTemplateDto>;
};

export type MutationDeactivateRemoteUserArgs = {
  organizationRemoteUserId: Scalars['ID']['input'];
};

export type MutationDeleteCustomProcessArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteIdnaTemplateArgs = {
  templateId: Scalars['ID']['input'];
};

export type MutationDeleteInstrumentArgs = {
  applyDeleteInQueueJobs?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId: Scalars['ID']['input'];
};

export type MutationDeleteProjectArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationDeleteRunArgs = {
  runId: Scalars['ID']['input'];
};

export type MutationDeleteSessionsArgs = {
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationDeleteTemplateArgs = {
  templateId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeprecateGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDynamicSecurityUserManagementArgs = {
  inputData: DynamicSecurityRequest;
};

export type MutationEnableUserArgs = {
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationInviteRemoteUserArgs = {
  inputData: RemoteUserInviteDto;
};

export type MutationInviteUserArgs = {
  user: UserInviteDto;
};

export type MutationLoginAsArgs = {
  login?: InputMaybe<LoginDto>;
};

export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['ID']['input'];
};

export type MutationReactivateUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRegenerateCredentialsForInstrumentArgs = {
  instrumentId: Scalars['ID']['input'];
};

export type MutationRemoveUserFromGroupArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationReprintFailedWellsArgs = {
  inputData?: InputMaybe<ReprintFailedWellDto>;
};

export type MutationRequestInstrumentLogArgs = {
  request?: InputMaybe<InstrumentLogCreateDto>;
};

export type MutationResendEmailActivationArgs = {
  email: Scalars['String']['input'];
};

export type MutationResendInvitationEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationRestoreIdnaTemplateArgs = {
  templateId: Scalars['ID']['input'];
};

export type MutationRestoreProjectArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationRestoreTemplateArgs = {
  templateId: Scalars['ID']['input'];
};

export type MutationSetPasswordForInvitedUserArgs = {
  password: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationSwitchToRemoteOrganizationArgs = {
  organizationRemoteUserId: Scalars['ID']['input'];
};

export type MutationSynchronizeInstrumentKeyArgs = {
  instrumentId: Scalars['ID']['input'];
};

export type MutationUpdateConcentrationArgs = {
  inputData: UpdateConcentrationDto;
};

export type MutationUpdateCustomProcessArgs = {
  update: CustomProcessUpdateDto;
};

export type MutationUpdateGroupArgs = {
  groupData?: InputMaybe<UpdateGroupDto>;
};

export type MutationUpdateIdnaTemplateArgs = {
  inputData: TemplateUpdateDto;
};

export type MutationUpdateInstrumentArgs = {
  inputData?: InputMaybe<UpdateInstrumentDto>;
};

export type MutationUpdateOrganizationDataArgs = {
  organization: OrganizationUpdateDto;
};

export type MutationUpdateOrganizationLicenseArgs = {
  license: Scalars['String']['input'];
};

export type MutationUpdatePqaRunInfoArgs = {
  inputData?: InputMaybe<UpdatePqaRunInfoDto>;
};

export type MutationUpdateProjectArgs = {
  inputData: UpdateProjectDto;
};

export type MutationUpdateRemoteUserArgs = {
  inputData: RemoteUserUpdateDto;
};

export type MutationUpdateTemplateArgs = {
  update?: InputMaybe<TemplateUpdateDto>;
};

export type MutationUpdateUserArgs = {
  user: UserUpdateDto;
};

export type MutationUpdateUserPreferencesArgs = {
  inputData: UserPreferencesRequestDto;
};

export type MutationUpdateUserProfileArgs = {
  user: UserProfileUpdateDto;
};

export type MutationUploadRunReportArgs = {
  inputData?: InputMaybe<UploadReportRequestDto>;
};

export enum NormalizationType {
  LOWEST = 'LOWEST',
  NONE = 'NONE',
  TARGET = 'TARGET',
}

export type NotificationDto = {
  __typename?: 'NotificationDTO';
  additionalData?: Maybe<Array<Maybe<AdditionalItemDto>>>;
  body?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type NotificationPageDto = {
  __typename?: 'NotificationPageDTO';
  data?: Maybe<Array<Maybe<NotificationDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type NowRunsPerOrganizationByUserFilterDto = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum Nucleo {
  A = 'A',
  B = 'B',
  C = 'C',
  CLICK = 'CLICK',
  D = 'D',
  G = 'G',
  H = 'H',
  K = 'K',
  M = 'M',
  MOD = 'MOD',
  MOD_UNKNOWN = 'MOD_UNKNOWN',
  N = 'N',
  R = 'R',
  S = 'S',
  T = 'T',
  UNKNOWN = 'UNKNOWN',
  V = 'V',
  W = 'W',
  Y = 'Y',
}

export type NucleotideDto = {
  __typename?: 'NucleotideDTO';
  data?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Nucleo>;
};

export type NucleotideErrorDto = {
  __typename?: 'NucleotideErrorDTO';
  group?: Maybe<SequenceMessageGroup>;
  index?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OperationResultDto = {
  __typename?: 'OperationResultDTO';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type OrganizationDto = {
  __typename?: 'OrganizationDTO';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type OrganizationDataDto = {
  __typename?: 'OrganizationDataDTO';
  address?: Maybe<Address>;
  aesKey?: Maybe<Scalars['String']['output']>;
  biosecurityEnabled?: Maybe<Scalars['Boolean']['output']>;
  clicksEnabled?: Maybe<Scalars['Boolean']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customProcessEnabled?: Maybe<Scalars['Boolean']['output']>;
  degeneratesEnabled?: Maybe<Scalars['Boolean']['output']>;
  geneEnabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  license?: Maybe<LicenseContentDto>;
  maxLoginFailedAttempts?: Maybe<Scalars['Int']['output']>;
  modsEnabled?: Maybe<Scalars['Boolean']['output']>;
  printingEnabled?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<OrganizationStatus>;
  timeZone?: Maybe<TimeZoneDto>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type OrganizationRemoteUserDto = {
  __typename?: 'OrganizationRemoteUserDTO';
  createdAt?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organization?: Maybe<OrganizationDto>;
  role?: Maybe<RoleDto>;
  status?: Maybe<RemoteUserStatus>;
  user?: Maybe<UserDto>;
};

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export type OrganizationUpdateDto = {
  address?: InputMaybe<AddressDto>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactDto>;
  maxLoginFailedAttempts?: InputMaybe<Scalars['Int']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type PageInfoDto = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type PlateConfigDto = {
  __typename?: 'PlateConfigDTO';
  maxCyclesPerTemplate?: Maybe<Scalars['Int']['output']>;
  maxErrorMers?: Maybe<Scalars['Int']['output']>;
  maxWarningMers?: Maybe<Scalars['Int']['output']>;
  minErrorMers?: Maybe<Scalars['Int']['output']>;
  minWarningMers?: Maybe<Scalars['Int']['output']>;
};

export enum PlateMode {
  DESALT = 'DESALT',
  GAUGE_SYNTHESIS = 'GAUGE_SYNTHESIS',
  OLIGO = 'OLIGO',
  SYNTHESIS = 'SYNTHESIS',
  TRANSFER = 'TRANSFER',
  UNKNOWN = 'UNKNOWN',
}

export type PlateRequirementDto = {
  __typename?: 'PlateRequirementDTO';
  designId?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  plateMode?: Maybe<PlateMode>;
  plateType?: Maybe<PlateType>;
};

export enum PlateType {
  PLATE_96 = 'PLATE_96',
  PLATE_384 = 'PLATE_384',
}

export type PqaCouplingLevel = {
  __typename?: 'PqaCouplingLevel';
  level: CouplingLevel;
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type PqaModConfig = {
  __typename?: 'PqaModConfig';
  couplingLevels: Array<PqaCouplingLevel>;
  mod: Scalars['String']['output'];
};

export type PqaRunGroupStatisticDto = {
  __typename?: 'PqaRunGroupStatisticDTO';
  averageYieldPmol: Scalars['Float']['output'];
  coefficientOfVariation: Scalars['Float']['output'];
  groupName: Scalars['String']['output'];
  invalidWellsNumber: Scalars['Int']['output'];
  maxInGroup: Scalars['Float']['output'];
  maxInvalidWellsNumber: Scalars['Int']['output'];
  minInGroup: Scalars['Float']['output'];
  minSizePmol: Scalars['Int']['output'];
  standardDeviationPmol: Scalars['Float']['output'];
  totalWellNumber: Scalars['Int']['output'];
};

export type PqaRunInfoDto = {
  __typename?: 'PqaRunInfoDTO';
  comment?: Maybe<Scalars['String']['output']>;
  conditionName?: Maybe<Scalars['String']['output']>;
  plateTitle?: Maybe<Scalars['String']['output']>;
};

export type PqaRunModDto = {
  __typename?: 'PqaRunModDTO';
  couplingPercent: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  level: CouplingLevel;
};

export type PqaRunModsStatisticDto = {
  __typename?: 'PqaRunModsStatisticDTO';
  modsConfig: Array<PqaModConfig>;
  status: PqaRunModsStatus;
  wellMods: Array<PqaRunWellModsDto>;
};

export enum PqaRunModsStatus {
  FAIL = 'FAIL',
  PASS = 'PASS',
}

export type PqaRunStatisticDto = {
  __typename?: 'PqaRunStatisticDTO';
  groupsInfo: Array<Maybe<PqaRunGroupStatisticDto>>;
  modsInfo?: Maybe<PqaRunModsStatisticDto>;
};

export type PqaRunWellModsDto = {
  __typename?: 'PqaRunWellModsDTO';
  conclusion: CouplingLevel;
  mods: Array<PqaRunModDto>;
  sequenceName: Scalars['String']['output'];
  well: Scalars['String']['output'];
};

export enum PrimerType {
  ASSEMBLY = 'ASSEMBLY',
  ERROR_CORRECTION = 'ERROR_CORRECTION',
  NONE = 'NONE',
  VECTOR = 'VECTOR',
}

export type PrintJobDto = {
  __typename?: 'PrintJobDTO';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum PrintJobVersion {
  V_1 = 'V_1',
  V_2 = 'V_2',
}

export type PrintRequirementsMatchingDto = {
  __typename?: 'PrintRequirementsMatchingDTO';
  deck?: Maybe<DeckDto>;
  errors?: Maybe<Array<Maybe<ErrorDto>>>;
  isPrintingEnabled?: Maybe<Scalars['Boolean']['output']>;
  missingRequirements?: Maybe<MissingRequirementsDto>;
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  waste?: Maybe<WasteBottleStatusDto>;
};

export type ProcessAbstract = MachineWashDto | PrintJobDto;

/** ############# */
export type ProcessStatusDto = {
  __typename?: 'ProcessStatusDTO';
  /** # seconds */
  currentStep?: Maybe<StepInfoDto>;
  errors?: Maybe<Array<Maybe<InstrumentErrorDto>>>;
  /**  ISO8601 */
  estimatedRemainingTime?: Maybe<Scalars['Long']['output']>;
  process?: Maybe<ProcessAbstract>;
  startTime?: Maybe<Scalars['String']['output']>;
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ProjectByIdDto = {
  __typename?: 'ProjectByIdDTO';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectType?: Maybe<ProjectType>;
};

export type ProjectDto = {
  __typename?: 'ProjectDTO';
  admin?: Maybe<UserInProjectDto>;
  color?: Maybe<Scalars['String']['output']>;
  completedRuns?: Maybe<Scalars['Int']['output']>;
  countOfDraftTemplates?: Maybe<Scalars['Int']['output']>;
  countOfErrorTemplates?: Maybe<Scalars['Int']['output']>;
  countOfOligos?: Maybe<Scalars['Int']['output']>;
  countOfPassedOligos?: Maybe<Scalars['Int']['output']>;
  countOfReadyTemplates?: Maybe<Scalars['Int']['output']>;
  countOfRuns?: Maybe<Scalars['Int']['output']>;
  countOfWarningTemplates?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<UserInProjectDto>;
  id: Scalars['ID']['output'];
  members?: Maybe<Array<Maybe<UserInProjectDetailsDto>>>;
  name: Scalars['String']['output'];
  percentOfOligosMore200?: Maybe<Scalars['Float']['output']>;
  percentOfPassedOligos?: Maybe<Scalars['Float']['output']>;
  projectType: ProjectType;
  restrictedAccess?: Maybe<Scalars['Boolean']['output']>;
  runsInQueue?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ProjectDetailDto = {
  __typename?: 'ProjectDetailDTO';
  admin?: Maybe<UserInProjectDto>;
  budget?: Maybe<Scalars['Float']['output']>;
  completedRuns?: Maybe<Scalars['Int']['output']>;
  countOfDraftTemplates?: Maybe<Scalars['Int']['output']>;
  countOfErrorTemplates?: Maybe<Scalars['Int']['output']>;
  countOfRuns?: Maybe<Scalars['Int']['output']>;
  countOfTemplates?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expense?: Maybe<Scalars['Float']['output']>;
  geneDetail?: Maybe<GeneDetailDto>;
  id: Scalars['ID']['output'];
  members?: Maybe<Array<Maybe<UserInProjectDetailsDto>>>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<ProjectOrganizationDto>;
  owner?: Maybe<UserInProjectDto>;
  percentOfPassedOligos?: Maybe<Scalars['Float']['output']>;
  projectType: ProjectType;
  restrictedAccess?: Maybe<Scalars['Boolean']['output']>;
  runsInQueue?: Maybe<Scalars['Int']['output']>;
  status: Status;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ProjectFilterDto = {
  projectType: ProjectType;
  status: Status;
};

export type ProjectOrganizationDto = {
  __typename?: 'ProjectOrganizationDTO';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ProjectPageDto = {
  __typename?: 'ProjectPageDTO';
  data?: Maybe<Array<Maybe<ProjectDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type ProjectSettingsDto = {
  __typename?: 'ProjectSettingsDTO';
  budget?: Maybe<Scalars['Float']['output']>;
  budgetEnforced?: Maybe<Scalars['Boolean']['output']>;
  budgetPerProject?: Maybe<Scalars['Float']['output']>;
  budgetPerProjectEnforced?: Maybe<Scalars['Boolean']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectPerOrg?: Maybe<Scalars['Int']['output']>;
  projectPerOrgEnforced?: Maybe<Scalars['Boolean']['output']>;
  projectPerUser?: Maybe<Scalars['Int']['output']>;
  projectPerUserEnforced?: Maybe<Scalars['Boolean']['output']>;
};

export enum ProjectType {
  GENE = 'GENE',
  SYNTAX = 'SYNTAX',
}

export type Query = {
  __typename?: 'Query';
  allGroups?: Maybe<GroupPageDto>;
  designContextSwitch?: Maybe<Scalars['String']['output']>;
  findSuitableInstrumentsForPrint?: Maybe<Array<Maybe<InstrumentByIdDto>>>;
  getActiveModsAndClicks?: Maybe<ActiveModsAndClicksDto>;
  getAllCustomProcesses?: Maybe<CustomProcessPageDto>;
  getAllInstruments?: Maybe<InstrumentPageDto>;
  getAllKitTypesInfo?: Maybe<Array<Maybe<KitInfoDto>>>;
  getAllProjects?: Maybe<ProjectPageDto>;
  getAllRoles?: Maybe<Array<Maybe<RoleDto>>>;
  getAllSequenceConcentrationLimitsByProjectType: Array<ConcentrationLimitsDto>;
  getAvailableTimeZones?: Maybe<Array<Maybe<TimeZoneDto>>>;
  getCentrifugationProcesses: Array<CentrifugationType>;
  getCurrentUser?: Maybe<UserDetailedDto>;
  getDefaultProjectForUser?: Maybe<ProjectByIdDto>;
  getGroupById?: Maybe<GroupByIdWithUserDetailsDto>;
  getIDNAPlateDetails?: Maybe<TemplateDetailsDto>;
  getIDNAPlates?: Maybe<TemplatePerUserPageDto>;
  getIDNAPlatesArchived?: Maybe<TemplatePerUserPageDto>;
  getInstallationFiles?: Maybe<InstallationFilePageDto>;
  getInstrumentDetailedStatus?: Maybe<InstrumentDetailedStatusDto>;
  getInstrumentDetailsById?: Maybe<InstrumentDetailsDto>;
  getInstrumentLogById?: Maybe<InstrumentLogDto>;
  getInstrumentModels?: Maybe<Array<Maybe<InstrumentModelDto>>>;
  getInstrumentStatus?: Maybe<InstrumentStatusDto>;
  getInstrumentsInQueue?: Maybe<Array<Maybe<InstrumentInQueueDto>>>;
  /** @deprecated AC of CN-9601 issue - duration now calculated by instrument for print job */
  getJobDuration?: Maybe<Scalars['Int']['output']>;
  getListOfAdminsForOrganization?: Maybe<Array<Maybe<UserInGroupDto>>>;
  getLoginEvents?: Maybe<LoginEventPageDto>;
  getMyNotifications?: Maybe<NotificationPageDto>;
  getMyProjects?: Maybe<ProjectPageDto>;
  getNowRunsPerOrganizationByUser?: Maybe<Array<Maybe<RunPerUserDto>>>;
  getOrganizationData?: Maybe<OrganizationDataDto>;
  getOrganizationOnPremiseLicenses?: Maybe<Array<Maybe<LicenseDataDto>>>;
  getPlateConfigs?: Maybe<PlateConfigDto>;
  getPqaStatistic: PqaRunStatisticDto;
  getProjectDetailsById?: Maybe<ProjectDetailDto>;
  getProjectSettingsForOrganization?: Maybe<ProjectSettingsDto>;
  getProjectsForApprove?: Maybe<Array<Maybe<ProjectByIdDto>>>;
  getRecentWork?: Maybe<RecentWorkDto>;
  getRedboxVersion?: Maybe<Scalars['String']['output']>;
  getRemoteOrganizations?: Maybe<Array<Maybe<OrganizationRemoteUserDto>>>;
  getRemoteUsers?: Maybe<Array<Maybe<OrganizationRemoteUserDto>>>;
  getRunGroupsPerProject?: Maybe<RunGroupPerProjectPageDto>;
  getRunHistoryPerInstrument?: Maybe<RunsPerInstrumentPageDto>;
  getRunLightInfo?: Maybe<RunLightInfoDto>;
  getRunReportDetails?: Maybe<RunReportDetailsDto>;
  getRunSchedule?: Maybe<Array<Maybe<RunsScheduleDto>>>;
  getRunStatisticPerInstrument?: Maybe<Array<Maybe<RunsPerInstrument>>>;
  getRunsPerOrganizationByUser?: Maybe<RunPerUserPageDto>;
  getRunsPerProject?: Maybe<RunPerProjectPageDto>;
  getRunsPerUser?: Maybe<RunPerUserPageDto>;
  /** @deprecated Use getAllSequenceConcentrationLimits instead */
  getSequenceConcentrationLimits?: Maybe<ConcentrationLimitsDto>;
  getServerTimeZone?: Maybe<TimeZoneDto>;
  getSupportedIDNAKitTypes?: Maybe<Array<Maybe<KitTypeKeyValueDto>>>;
  getSupportedInstrumentVersions: Array<InstrumentVersion>;
  getSupportedKitTypes?: Maybe<Array<Maybe<KitTypeKeyValueDto>>>;
  getTemplateDetails?: Maybe<TemplateDetailsDto>;
  getTemplatesArchived?: Maybe<TemplatePerUserPageDto>;
  getTemplatesAvailableForCurrentUser?: Maybe<TemplatePerUserPageDto>;
  getTemplatesPerProject?: Maybe<TemplatePerProjectPageDto>;
  getTemplatesPerUser?: Maybe<TemplatePerUserPageDto>;
  getUserById?: Maybe<UserDetailedDto>;
  getUsers?: Maybe<UserPageDto>;
  getUsersForNewProject?: Maybe<UsersForNewProjectDto>;
  isValidCustomProcess?: Maybe<Scalars['Boolean']['output']>;
  systemInfo: Array<ServiceInfoDto>;
  validateIDNATemplateUpdate?: Maybe<TemplateDetailsDto>;
  validateInstrumentKitType?: Maybe<InstrumentKitTypeValidationResponseDto>;
  validateTemplateUpdate?: Maybe<TemplateDetailsDto>;
  validateVerificationCode?: Maybe<OperationResultDto>;
};

export type QueryAllGroupsArgs = {
  filter?: InputMaybe<GroupsFilterDto>;
};

export type QueryFindSuitableInstrumentsForPrintArgs = {
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetActiveModsAndClicksArgs = {
  kitType?: InputMaybe<KitType>;
};

export type QueryGetAllInstrumentsArgs = {
  filter?: InputMaybe<InstrumentFilterDto>;
};

export type QueryGetAllProjectsArgs = {
  filter: ProjectFilterDto;
};

export type QueryGetAllSequenceConcentrationLimitsByProjectTypeArgs = {
  projectType: ProjectType;
};

export type QueryGetCentrifugationProcessesArgs = {
  kitType: KitType;
};

export type QueryGetGroupByIdArgs = {
  groupId: Scalars['ID']['input'];
};

export type QueryGetIdnaPlateDetailsArgs = {
  templateId: Scalars['ID']['input'];
};

export type QueryGetIdnaPlatesArgs = {
  filter?: InputMaybe<TemplateFilterDto>;
};

export type QueryGetIdnaPlatesArchivedArgs = {
  filter?: InputMaybe<TemplateFilterDto>;
};

export type QueryGetInstallationFilesArgs = {
  filter?: InputMaybe<InstallationFileFilterDto>;
};

export type QueryGetInstrumentDetailedStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInstrumentDetailsByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInstrumentLogByIdArgs = {
  logId: Scalars['ID']['input'];
};

export type QueryGetInstrumentStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInstrumentsInQueueArgs = {
  filter?: InputMaybe<InstrumentInQueueFilterDto>;
};

export type QueryGetJobDurationArgs = {
  inputData?: InputMaybe<JobDurationDto>;
};

export type QueryGetLoginEventsArgs = {
  filter?: InputMaybe<LoginEventFilterDto>;
};

export type QueryGetMyNotificationsArgs = {
  pageInfo?: InputMaybe<PageInfoDto>;
};

export type QueryGetMyProjectsArgs = {
  filter: ProjectFilterDto;
};

export type QueryGetNowRunsPerOrganizationByUserArgs = {
  filter?: InputMaybe<NowRunsPerOrganizationByUserFilterDto>;
};

export type QueryGetPlateConfigsArgs = {
  templateId: Scalars['ID']['input'];
};

export type QueryGetPqaStatisticArgs = {
  runId: Scalars['ID']['input'];
};

export type QueryGetProjectDetailsByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRecentWorkArgs = {
  filter: RecentWorkFilterDto;
};

export type QueryGetRunGroupsPerProjectArgs = {
  filter?: InputMaybe<RunPerProjectFilterDto>;
};

export type QueryGetRunHistoryPerInstrumentArgs = {
  instrumentFilterDTO?: InputMaybe<RunPerInstrumentFilterDto>;
};

export type QueryGetRunLightInfoArgs = {
  runId: Scalars['Long']['input'];
};

export type QueryGetRunReportDetailsArgs = {
  runId: Scalars['ID']['input'];
};

export type QueryGetRunScheduleArgs = {
  filter?: InputMaybe<RunScheduleFilterDto>;
};

export type QueryGetRunStatisticPerInstrumentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRunsPerOrganizationByUserArgs = {
  filter?: InputMaybe<RunPerProjectFilterDto>;
};

export type QueryGetRunsPerProjectArgs = {
  filter?: InputMaybe<RunPerProjectFilterDto>;
};

export type QueryGetRunsPerUserArgs = {
  filter?: InputMaybe<RunPerProjectFilterDto>;
};

export type QueryGetSequenceConcentrationLimitsArgs = {
  kitType: KitType;
};

export type QueryGetTemplateDetailsArgs = {
  templateId: Scalars['ID']['input'];
};

export type QueryGetTemplatesArchivedArgs = {
  filter?: InputMaybe<TemplateFilterDto>;
};

export type QueryGetTemplatesAvailableForCurrentUserArgs = {
  filter?: InputMaybe<TemplateFilterDto>;
};

export type QueryGetTemplatesPerProjectArgs = {
  filter?: InputMaybe<TemplateFilterDto>;
};

export type QueryGetTemplatesPerUserArgs = {
  filter?: InputMaybe<TemplateFilterDto>;
};

export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetUsersArgs = {
  filter?: InputMaybe<UserFilterDto>;
};

export type QueryIsValidCustomProcessArgs = {
  process: Scalars['String']['input'];
};

export type QueryValidateIdnaTemplateUpdateArgs = {
  update: TemplateUpdateDto;
};

export type QueryValidateInstrumentKitTypeArgs = {
  inputData?: InputMaybe<RunSchedulerCreateDto>;
};

export type QueryValidateTemplateUpdateArgs = {
  update: TemplateUpdateDto;
};

export type QueryValidateVerificationCodeArgs = {
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
};

export type ReagentDetailedStatusDto = {
  __typename?: 'ReagentDetailedStatusDTO';
  capacity?: Maybe<Scalars['BigDecimal']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  deadVolume?: Maybe<Scalars['BigDecimal']['output']>;
  /**  ISO8601 */
  expirationTime?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  /**  ISO8601 */
  isUsable?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** # ul */
  loadTime?: Maybe<Scalars['String']['output']>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  remainingVolume?: Maybe<Scalars['BigDecimal']['output']>;
  /** # ul */
  uniqueNumber?: Maybe<Scalars['String']['output']>;
};

export type ReagentMissingRequirementDto = {
  __typename?: 'ReagentMissingRequirementDTO';
  accessories?: Maybe<Array<Maybe<ReagentRequirementDto>>>;
  trayBottles?: Maybe<Array<Maybe<ReagentRequirementDto>>>;
};

export type ReagentRequirementDto = {
  __typename?: 'ReagentRequirementDTO';
  code?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export enum ReagentUsageUnion {
  NUCLEOTIDES = 'NUCLEOTIDES',
  PSP = 'PSP',
  SYNTH = 'SYNTH',
}

export type RecentRunWorkItemDto = RecentWorkItemDto & {
  __typename?: 'RecentRunWorkItemDTO';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  reportUrl?: Maybe<Scalars['String']['output']>;
  runStatus?: Maybe<Scalars['String']['output']>;
  sheetUrl?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RecentTemplateWorkItemDto = RecentWorkItemDto & {
  __typename?: 'RecentTemplateWorkItemDTO';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  templateStatus?: Maybe<TemplateStatus>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RecentWorkDto = {
  __typename?: 'RecentWorkDTO';
  items?: Maybe<Array<Maybe<RecentWorkItemDto>>>;
};

export type RecentWorkFilterDto = {
  runs: Scalars['Boolean']['input'];
  size: Scalars['Int']['input'];
  templates: Scalars['Boolean']['input'];
};

export type RecentWorkItemDto = {
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RemoteUserInviteDto = {
  email: Scalars['String']['input'];
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['ID']['input'];
};

export enum RemoteUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type RemoteUserUpdateDto = {
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type ReprintFailedWellDto = {
  instrumentId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['ID']['input']>;
};

export type ResultLinkDto = {
  __typename?: 'ResultLinkDTO';
  id: Scalars['ID']['output'];
};

export type RoleDto = {
  __typename?: 'RoleDTO';
  authority?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<RoleType>;
};

export enum RoleType {
  ADMIN = 'ADMIN',
  SERVICE = 'SERVICE',
  USER = 'USER',
}

export type RunCheckInfoDto = {
  __typename?: 'RunCheckInfoDTO';
  id: Scalars['ID']['output'];
  printRequirementsMatching?: Maybe<PrintRequirementsMatchingDto>;
  status?: Maybe<RunStatus>;
};

export type RunGroupPerProjectPageDto = {
  __typename?: 'RunGroupPerProjectPageDTO';
  data?: Maybe<Array<Maybe<RunPerGroupDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type RunLightInfoDto = {
  __typename?: 'RunLightInfoDTO';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  reportMetaLink?: Maybe<Scalars['String']['output']>;
  scheduledEndDate?: Maybe<Scalars['String']['output']>;
  scheduledStartDate?: Maybe<Scalars['String']['output']>;
  status: RunStatus;
  updatedAt: Scalars['String']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type RunPerGroupDto = {
  __typename?: 'RunPerGroupDTO';
  completed?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfPlates?: Maybe<Scalars['Int']['output']>;
  queued?: Maybe<Scalars['Int']['output']>;
  runs?: Maybe<Array<Maybe<RunPerProjectDto>>>;
  status?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type RunPerInstrumentDto = {
  __typename?: 'RunPerInstrumentDTO';
  completedDate?: Maybe<Scalars['String']['output']>;
  countOfMods?: Maybe<Scalars['Int']['output']>;
  countOfPassedSequences?: Maybe<Scalars['Int']['output']>;
  countOfTotalSequences?: Maybe<Scalars['Int']['output']>;
  creator?: Maybe<UserInInstrumentRunDto>;
  customProcess?: Maybe<CustomProcessInfoDto>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kitType?: Maybe<KitType>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  metaLink?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentIDNAId?: Maybe<Scalars['ID']['output']>;
  percentOfPassedOligos?: Maybe<Scalars['Float']['output']>;
  project?: Maybe<ProjectByIdDto>;
  projectName?: Maybe<Scalars['String']['output']>;
  reportMetaLink?: Maybe<Scalars['String']['output']>;
  scheduledStartDate?: Maybe<Scalars['String']['output']>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type RunPerInstrumentFilterDto = {
  endDate: Scalars['String']['input'];
  instrumentId?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['String']['input'];
};

export type RunPerProjectDto = {
  __typename?: 'RunPerProjectDTO';
  assignedInstrument?: Maybe<InstrumentPerRunDto>;
  countOfMods?: Maybe<Scalars['Int']['output']>;
  countOfPassedSequences?: Maybe<Scalars['Int']['output']>;
  countOfTotalSequences?: Maybe<Scalars['Int']['output']>;
  creator?: Maybe<UserInProjectDto>;
  customProcess?: Maybe<CustomProcessInfoDto>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kitType?: Maybe<KitType>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  metaLink?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentIDNAId?: Maybe<Scalars['ID']['output']>;
  parentIDNAName?: Maybe<Scalars['String']['output']>;
  percentOfPassedOligos?: Maybe<Scalars['Float']['output']>;
  plateType?: Maybe<PlateType>;
  reportMetaLink?: Maybe<Scalars['String']['output']>;
  scheduledEndDate?: Maybe<Scalars['String']['output']>;
  scheduledStartDate?: Maybe<Scalars['String']['output']>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type RunPerProjectFilterDto = {
  projectId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type RunPerProjectPageDto = {
  __typename?: 'RunPerProjectPageDTO';
  data?: Maybe<Array<Maybe<RunPerProjectDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type RunPerUserDto = {
  __typename?: 'RunPerUserDTO';
  assignedInstrument?: Maybe<InstrumentPerRunDto>;
  countOfMods?: Maybe<Scalars['Int']['output']>;
  countOfPassedSequences?: Maybe<Scalars['Int']['output']>;
  countOfTotalSequences?: Maybe<Scalars['Int']['output']>;
  creator?: Maybe<UserInProjectDto>;
  customProcess?: Maybe<CustomProcessInfoDto>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kitType?: Maybe<KitType>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  metaLink?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentIDNAId?: Maybe<Scalars['ID']['output']>;
  parentIDNAName?: Maybe<Scalars['String']['output']>;
  percentOfPassedOligos?: Maybe<Scalars['Float']['output']>;
  plateType?: Maybe<PlateType>;
  project?: Maybe<ProjectByIdDto>;
  reportMetaLink?: Maybe<Scalars['String']['output']>;
  scheduledEndDate?: Maybe<Scalars['String']['output']>;
  scheduledStartDate?: Maybe<Scalars['String']['output']>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type RunPerUserPageDto = {
  __typename?: 'RunPerUserPageDTO';
  data?: Maybe<Array<Maybe<RunPerUserDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type RunReagentBottleDto = {
  __typename?: 'RunReagentBottleDTO';
  label?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  volumeMl?: Maybe<Scalars['Float']['output']>;
};

export type RunReagentDto = {
  __typename?: 'RunReagentDTO';
  amount?: Maybe<Scalars['Float']['output']>;
  bottles?: Maybe<Array<Maybe<RunReagentBottleDto>>>;
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
};

export type RunReagentDetailsDto = {
  __typename?: 'RunReagentDetailsDTO';
  amount?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  days?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  expireDate?: Maybe<Scalars['String']['output']>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  rawCode?: Maybe<Scalars['String']['output']>;
  rawGroupCode?: Maybe<Scalars['String']['output']>;
};

export type RunReagentGroupDto = {
  __typename?: 'RunReagentGroupDTO';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reagents?: Maybe<Array<Maybe<RunReagentDetailsDto>>>;
};

export type RunReportDetailsDto = {
  __typename?: 'RunReportDetailsDTO';
  centrifugationType?: Maybe<CentrifugationType>;
  countOfClicks?: Maybe<Scalars['Int']['output']>;
  countOfMods?: Maybe<Scalars['Int']['output']>;
  countOfPassedSequences?: Maybe<Scalars['Int']['output']>;
  countOfSequences?: Maybe<Scalars['Int']['output']>;
  creator?: Maybe<UserInProjectDto>;
  customProcess?: Maybe<CustomProcessInfoDto>;
  description?: Maybe<Scalars['String']['output']>;
  durationInSeconds?: Maybe<Scalars['Long']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  estimatedReagents?: Maybe<Array<Maybe<RunReagentDto>>>;
  failMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idnaTemplate?: Maybe<TemplatePerProjectDto>;
  instrument?: Maybe<InstrumentByIdDto>;
  kitType?: Maybe<KitType>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  metaLink?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentOfPassedSequences?: Maybe<Scalars['Float']['output']>;
  plateType?: Maybe<PlateType>;
  pqaInfo?: Maybe<PqaRunInfoDto>;
  project?: Maybe<ProjectByIdDto>;
  reagentGroups?: Maybe<Array<Maybe<RunReagentGroupDto>>>;
  reportMetaLink?: Maybe<Scalars['String']['output']>;
  scheduledEndTime?: Maybe<Scalars['String']['output']>;
  scheduledStartTime?: Maybe<Scalars['String']['output']>;
  sequences?: Maybe<Array<Maybe<RunSequenceDto>>>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RunStatus>;
  volumeSettings?: Maybe<RunReportVolumeSettingsDto>;
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  yieldSettings?: Maybe<RunReportYieldSettingsDto>;
};

export type RunReportVolumeSettingsDto = {
  __typename?: 'RunReportVolumeSettingsDTO';
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export enum RunReportYieldLevel {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  NORMAL = 'NORMAL',
}

export type RunReportYieldSetting = {
  __typename?: 'RunReportYieldSetting';
  level: RunReportYieldLevel;
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type RunReportYieldSettingsDto = {
  __typename?: 'RunReportYieldSettingsDTO';
  values: Array<RunReportYieldSetting>;
};

export type RunScheduleFilterDto = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  instrumentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type RunSchedulerCreateDto = {
  centrifugationType?: InputMaybe<CentrifugationType>;
  customProcessId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type RunSchedulerCreateResponseDto = {
  __typename?: 'RunSchedulerCreateResponseDTO';
  centrifugationType?: Maybe<CentrifugationType>;
  clicksCount?: Maybe<Scalars['Int']['output']>;
  createdTime?: Maybe<Scalars['String']['output']>;
  customProcess?: Maybe<CustomProcessInfoDto>;
  description?: Maybe<Scalars['String']['output']>;
  durationInSeconds?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  estimatedReagents?: Maybe<Array<Maybe<RunReagentDto>>>;
  id?: Maybe<Scalars['ID']['output']>;
  idnaTemplate?: Maybe<TemplatePerProjectDto>;
  instrument?: Maybe<InstrumentByIdDto>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  metalink?: Maybe<Scalars['String']['output']>;
  modifiedCount?: Maybe<Scalars['Int']['output']>;
  scheduledEndTime?: Maybe<Scalars['String']['output']>;
  scheduledStartTime?: Maybe<Scalars['String']['output']>;
  sequenceCount?: Maybe<Scalars['Int']['output']>;
  sheetId?: Maybe<Scalars['ID']['output']>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserInGroupDto>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type RunSequenceDto = {
  __typename?: 'RunSequenceDTO';
  actualConcentration?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Int']['output']>;
  clicksCount?: Maybe<Scalars['Int']['output']>;
  couplingRates?: Maybe<Array<Maybe<CouplingRateDto>>>;
  crossElution?: Maybe<CrossElutionDto>;
  data?: Maybe<Scalars['String']['output']>;
  dataChunks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  errorType?: Maybe<Scalars['String']['output']>;
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  modStatus?: Maybe<ModStatus>;
  modified?: Maybe<Scalars['Boolean']['output']>;
  modsCount?: Maybe<Scalars['Int']['output']>;
  /**  Using this directly produces very big JSON content  */
  name?: Maybe<Scalars['String']['output']>;
  normalizationType?: Maybe<NormalizationType>;
  nucChunks?: Maybe<Array<Maybe<Nucleo>>>;
  nucleotides?: Maybe<Array<Maybe<NucleotideDto>>>;
  prefixData?: Maybe<Scalars['String']['output']>;
  prefixDataChunks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  prefixNucChunks?: Maybe<Array<Maybe<Nucleo>>>;
  prefixes?: Maybe<Array<Maybe<NucleotideDto>>>;
  /** @deprecated Use primerType instead */
  primer?: Maybe<Scalars['Boolean']['output']>;
  primerType?: Maybe<PrimerType>;
  purity?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<SequenceScore>;
  sequenceId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<RunSequenceStatus>;
  targetConcetration?: Maybe<Scalars['Float']['output']>;
  usePlateNorm?: Maybe<Scalars['Boolean']['output']>;
  validationLength?: Maybe<Scalars['Int']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
  well?: Maybe<Scalars['String']['output']>;
  wellIndex?: Maybe<Scalars['Int']['output']>;
  yield?: Maybe<Scalars['Float']['output']>;
};

export enum RunSequenceStatus {
  FAILED = 'FAILED',
  PASSED = 'PASSED',
}

export enum RunStatus {
  ABORTED = 'ABORTED',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_QUEUE = 'IN_QUEUE',
  PROCESS_COMPLETED = 'PROCESS_COMPLETED',
}

export type RunsPerInstrument = {
  __typename?: 'RunsPerInstrument';
  count?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type RunsPerInstrumentPageDto = {
  __typename?: 'RunsPerInstrumentPageDTO';
  data?: Maybe<Array<Maybe<RunPerInstrumentDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type RunsScheduleDto = {
  __typename?: 'RunsScheduleDTO';
  endTime?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instrumentId?: Maybe<Scalars['ID']['output']>;
  scheduledEndTime?: Maybe<Scalars['String']['output']>;
  scheduledStartTime?: Maybe<Scalars['String']['output']>;
  sheetId?: Maybe<Scalars['ID']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  templateName?: Maybe<Scalars['String']['output']>;
};

export type SequenceDto = {
  __typename?: 'SequenceDTO';
  blockNumber?: Maybe<Scalars['Int']['output']>;
  clicksCount?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  dataChunks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  errorGroups?: Maybe<Array<Maybe<SequenceMessageGroup>>>;
  errorScoreValue?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<SequenceMessageDto>>>;
  id?: Maybe<Scalars['ID']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  lengthWithPrefixes?: Maybe<Scalars['Int']['output']>;
  modsCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  normalizationType?: Maybe<NormalizationType>;
  nucChunks?: Maybe<Array<Maybe<Nucleo>>>;
  nucErrors?: Maybe<Array<Maybe<NucleotideErrorDto>>>;
  nucWarnings?: Maybe<Array<Maybe<NucleotideErrorDto>>>;
  nucleotides?: Maybe<Array<Maybe<NucleotideDto>>>;
  prefixDataChunks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  prefixNucChunks?: Maybe<Array<Maybe<Nucleo>>>;
  prefixes?: Maybe<Array<Maybe<NucleotideDto>>>;
  /** @deprecated Use primerType instead */
  primer?: Maybe<Scalars['Boolean']['output']>;
  primerType?: Maybe<PrimerType>;
  purity?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<SequenceScore>;
  sequenceWithoutModsOrClicks?: Maybe<Scalars['String']['output']>;
  /**  Using this directly produces very big JSON content  */
  status?: Maybe<SequenceStatus>;
  targetConcetration?: Maybe<Scalars['Float']['output']>;
  usePlateNorm?: Maybe<Scalars['Boolean']['output']>;
  validationLength?: Maybe<Scalars['Int']['output']>;
  warnings?: Maybe<Array<Maybe<SequenceMessageDto>>>;
  well?: Maybe<Scalars['String']['output']>;
  wellIndex?: Maybe<Scalars['Int']['output']>;
};

export type SequenceInfoDto = {
  data: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  normalizationType?: InputMaybe<NormalizationType>;
  status: SequenceStatus;
  targetConcetration?: InputMaybe<Scalars['Float']['input']>;
  usePlateNorm?: InputMaybe<Scalars['Boolean']['input']>;
  well?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceMessageDto = {
  __typename?: 'SequenceMessageDTO';
  group?: Maybe<SequenceMessageGroup>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum SequenceMessageGroup {
  CLICK_POSITION = 'CLICK_POSITION',
  CONCENTRATION = 'CONCENTRATION',
  DIFFICULTY = 'DIFFICULTY',
  LENGTH = 'LENGTH',
  MOD_COUNT = 'MOD_COUNT',
  MOD_UNKNOWN = 'MOD_UNKNOWN',
  NUC_UNKNOWN = 'NUC_UNKNOWN',
  SCREEN_HAZARD = 'SCREEN_HAZARD',
  UNKNOWN = 'UNKNOWN',
}

export enum SequenceScore {
  DIFFICULT = 'DIFFICULT',
  EXTREME = 'EXTREME',
  IMPOSSIBLE = 'IMPOSSIBLE',
  STANDARD = 'STANDARD',
  UNKNOWN = 'UNKNOWN',
}

export enum SequenceStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  WARN = 'WARN',
}

export type ServiceInfoDto = {
  __typename?: 'ServiceInfoDTO';
  client?: Maybe<Scalars['String']['output']>;
  connection?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  server?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ServiceStatus>;
  type?: Maybe<ServiceType>;
};

export enum ServiceStatus {
  ERROR = 'ERROR',
  OK = 'OK',
  WARNING = 'WARNING',
}

export enum ServiceType {
  DB = 'DB',
  IOT = 'IOT',
  KAFKA = 'KAFKA',
  LICENSE = 'LICENSE',
  NOTIFICATION = 'NOTIFICATION',
  PURITY = 'PURITY',
  REDIS = 'REDIS',
}

export enum SipperStatus {
  LOWERED = 'LOWERED',
  LOWERING = 'LOWERING',
  RAISED = 'RAISED',
  RAISING = 'RAISING',
  UNKNOWN = 'UNKNOWN',
}

export type SortInfoDto = {
  direction: Direction;
  field: Scalars['String']['input'];
};

export enum SortProjectField {
  CREATOR = 'CREATOR',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
}

export type SortProjectInfoDto = {
  direction: Direction;
  field: SortProjectField;
};

export type StationStatusDto = {
  __typename?: 'StationStatusDTO';
  name?: Maybe<Scalars['String']['output']>;
  temperature?: Maybe<Scalars['Float']['output']>;
};

export enum Status {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type StepInfoDto = {
  __typename?: 'StepInfoDTO';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeForRunCheckInfo?: Maybe<RunCheckInfoDto>;
  subscribeForRunReport?: Maybe<RunReportDetailsDto>;
};

export type SubscriptionSubscribeForRunCheckInfoArgs = {
  runId: Scalars['ID']['input'];
};

export type SubscriptionSubscribeForRunReportArgs = {
  runId: Scalars['ID']['input'];
};

export type TemplateCopyDto = {
  name: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type TemplateDetailsDto = {
  __typename?: 'TemplateDetailsDTO';
  averageSequence?: Maybe<Scalars['Int']['output']>;
  countOfClicks?: Maybe<Scalars['Int']['output']>;
  countOfMods?: Maybe<Scalars['Int']['output']>;
  countOfRuns?: Maybe<Scalars['Int']['output']>;
  countOfSequences?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<UserInProjectDto>;
  emptySequences?: Maybe<Array<Maybe<SequenceDto>>>;
  errors?: Maybe<Array<Maybe<TemplateMessageDto>>>;
  exportMetaLink?: Maybe<Scalars['String']['output']>;
  format?: Maybe<ContentFormat>;
  id: Scalars['ID']['output'];
  kitType?: Maybe<KitType>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  normalizationType?: Maybe<NormalizationType>;
  parentIDNAId?: Maybe<Scalars['ID']['output']>;
  parentIDNAName?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  plateType?: Maybe<PlateType>;
  prefixes?: Maybe<Array<Maybe<SequenceDto>>>;
  projectOwner?: Maybe<UserInProjectDto>;
  projectType?: Maybe<ProjectType>;
  sequences?: Maybe<Array<Maybe<SequenceDto>>>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TemplateStatus>;
  targetConcentration?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<ContentType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  warnings?: Maybe<Array<Maybe<TemplateMessageDto>>>;
};

export type TemplateFilterDto = {
  kitType?: InputMaybe<KitType>;
  plateType?: InputMaybe<PlateType>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectType?: InputMaybe<ProjectType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type TemplateMessageDto = {
  __typename?: 'TemplateMessageDTO';
  message?: Maybe<Scalars['String']['output']>;
  messageGroup?: Maybe<TemplateMessageGroup>;
};

export enum TemplateMessageGroup {
  NUCLEOTIDE = 'NUCLEOTIDE',
  SEQUENCE = 'SEQUENCE',
  TEMPLATE = 'TEMPLATE',
  UNKNOWN = 'UNKNOWN',
}

export type TemplatePerProjectDto = {
  __typename?: 'TemplatePerProjectDTO';
  averageSequence?: Maybe<Scalars['Int']['output']>;
  countOfClicks?: Maybe<Scalars['Int']['output']>;
  countOfMods?: Maybe<Scalars['Int']['output']>;
  countOfRuns?: Maybe<Scalars['Int']['output']>;
  countOfSequences?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<UserInProjectDto>;
  exportMetaLink?: Maybe<Scalars['String']['output']>;
  format?: Maybe<ContentFormat>;
  id: Scalars['ID']['output'];
  kitType?: Maybe<KitType>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentIDNAId?: Maybe<Scalars['ID']['output']>;
  parentIDNAName?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  plateType?: Maybe<PlateType>;
  projectOwner?: Maybe<UserInProjectDto>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TemplateStatus>;
  type?: Maybe<ContentType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TemplatePerProjectPageDto = {
  __typename?: 'TemplatePerProjectPageDTO';
  data?: Maybe<Array<Maybe<TemplatePerProjectDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type TemplatePerUserDto = {
  __typename?: 'TemplatePerUserDTO';
  averageSequence?: Maybe<Scalars['Int']['output']>;
  countOfClicks?: Maybe<Scalars['Int']['output']>;
  countOfMods?: Maybe<Scalars['Int']['output']>;
  countOfRuns?: Maybe<Scalars['Int']['output']>;
  countOfSequences?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<UserInProjectDto>;
  creatorId?: Maybe<Scalars['ID']['output']>;
  exportMetaLink?: Maybe<Scalars['String']['output']>;
  format?: Maybe<ContentFormat>;
  id: Scalars['ID']['output'];
  kitType?: Maybe<KitType>;
  longestSequence?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentIDNAId?: Maybe<Scalars['ID']['output']>;
  parentIDNAName?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  plateType?: Maybe<PlateType>;
  project?: Maybe<ProjectByIdDto>;
  projectOwner?: Maybe<UserInProjectDto>;
  shortestSequence?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TemplateStatus>;
  type?: Maybe<ContentType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TemplatePerUserPageDto = {
  __typename?: 'TemplatePerUserPageDTO';
  data?: Maybe<Array<Maybe<TemplatePerUserDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export enum TemplateStatus {
  ACTIVE = 'ACTIVE',
  DELETE = 'DELETE',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
  LOCKED = 'LOCKED',
  OUTDATED = 'OUTDATED',
  READY = 'READY',
  WARNING = 'WARNING',
}

export type TemplateUpdateDto = {
  name?: InputMaybe<Scalars['String']['input']>;
  normalizationType?: InputMaybe<NormalizationType>;
  parentPlateId?: InputMaybe<Scalars['ID']['input']>;
  runBioSecurityService?: InputMaybe<Scalars['Boolean']['input']>;
  runPurityEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  sequences: Array<SequenceInfoDto>;
  targetConcentration?: InputMaybe<Scalars['Float']['input']>;
  templateId: Scalars['ID']['input'];
};

export type TimeZoneDto = {
  __typename?: 'TimeZoneDTO';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  offset: Scalars['Int']['output'];
};

export type UpdateConcentrationDto = {
  projectId: Scalars['ID']['input'];
  runId: Scalars['ID']['input'];
  wellInfo: Array<WellInfoDto>;
};

export type UpdateGroupDto = {
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  status?: InputMaybe<Status>;
};

export type UpdateInstrumentDto = {
  admin?: InputMaybe<EntityByIdDto>;
  id?: InputMaybe<Scalars['ID']['input']>;
  installationDate?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Array<InputMaybe<EntityByIdDto>>>;
  modelId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  serialNumber: Scalars['String']['input'];
  serviceReminder?: InputMaybe<Scalars['Boolean']['input']>;
  systemVersion?: InputMaybe<InstrumentVersion>;
};

export type UpdatePqaRunInfoDto = {
  comment?: InputMaybe<Scalars['String']['input']>;
  conditionName?: InputMaybe<Scalars['String']['input']>;
  plateTitle?: InputMaybe<Scalars['String']['input']>;
  runId: Scalars['ID']['input'];
};

export type UpdateProjectDto = {
  admin: EntityByIdDto;
  badge?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  owner: EntityByIdDto;
  projectType?: InputMaybe<ProjectType>;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<EntityByIdDto>>>;
};

export type UploadReportRequestDto = {
  content?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UploadReportResponseDto = {
  __typename?: 'UploadReportResponseDTO';
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  failMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instrumentId?: Maybe<Scalars['ID']['output']>;
  metaLink?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  reportMetaLink?: Maybe<Scalars['String']['output']>;
  scheduledEndTime?: Maybe<Scalars['String']['output']>;
  scheduledStartTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type UserAddDto = {
  firstName: Scalars['String']['input'];
  groupIds: Array<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UserDto = {
  __typename?: 'UserDTO';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserDetailedDto = {
  __typename?: 'UserDetailedDTO';
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']['output']>;
  cellPhone?: Maybe<Scalars['String']['output']>;
  changePasswordAfterFirstLogin?: Maybe<Scalars['Boolean']['output']>;
  cloud?: Maybe<Scalars['Boolean']['output']>;
  countOfProjects?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  defaultProjectId?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  geneEnabled?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<Maybe<GroupModel>>>;
  homePhone?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mainOrganization?: Maybe<OrganizationDto>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  printingEnabled?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<RoleDto>;
  status?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<TimeZoneDto>;
  title?: Maybe<Scalars['String']['output']>;
  tooltipOnSeqPageIsClosed?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  validLicense?: Maybe<Scalars['Boolean']['output']>;
  workPhone?: Maybe<Scalars['String']['output']>;
};

export type UserFilterDto = {
  loggedUserExcluded?: InputMaybe<Scalars['Boolean']['input']>;
  page: PageInfoDto;
  searchText: Scalars['String']['input'];
  sort?: InputMaybe<SortInfoDto>;
  statuses?: InputMaybe<Array<InputMaybe<UserStatus>>>;
};

export type UserForGroupByIdDto = {
  __typename?: 'UserForGroupByIdDTO';
  dateAdded?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RoleDto>;
  status?: Maybe<Scalars['String']['output']>;
};

export type UserInGroupDto = {
  __typename?: 'UserInGroupDTO';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type UserInInstrumentDetailsDto = {
  __typename?: 'UserInInstrumentDetailsDTO';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
};

export type UserInInstrumentRunDto = {
  __typename?: 'UserInInstrumentRunDTO';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserInProjectDto = {
  __typename?: 'UserInProjectDTO';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserInProjectDetailsDto = {
  __typename?: 'UserInProjectDetailsDTO';
  accessPermission?: Maybe<AccessPermission>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<RoleDto>>>;
};

export type UserInviteDto = {
  firstName: Scalars['String']['input'];
  groupIds: Array<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UserListViewDto = {
  __typename?: 'UserListViewDTO';
  createdAt?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RoleDto>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum UserOperation {
  CREATE_CLIENT = 'CREATE_CLIENT',
  SET_CLIENT_PASSWORD = 'SET_CLIENT_PASSWORD',
}

export type UserPageDto = {
  __typename?: 'UserPageDTO';
  data?: Maybe<Array<Maybe<UserListViewDto>>>;
  totalElementsCount?: Maybe<Scalars['Int']['output']>;
};

export type UserPreferencesRequestDto = {
  tooltipOnSeqPageIsClosed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserProfileUpdateDto = {
  address: AddressInputDto;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  homePhone?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workPhone?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED',
  INACTIVE = 'INACTIVE',
}

export type UserUpdateDto = {
  address: AddressInputDto;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  groupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  roleId?: InputMaybe<Scalars['ID']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workPhone?: InputMaybe<Scalars['String']['input']>;
};

export type UsersForNewProjectDto = {
  __typename?: 'UsersForNewProjectDTO';
  admin?: Maybe<UserInProjectDetailsDto>;
  members?: Maybe<Array<Maybe<UserInProjectDetailsDto>>>;
  owner?: Maybe<UserInProjectDetailsDto>;
};

export type WasteBottleStatusDto = {
  __typename?: 'WasteBottleStatusDTO';
  connected?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  needToBeEmptied?: Maybe<Scalars['Boolean']['output']>;
  underPressure?: Maybe<Scalars['Boolean']['output']>;
};

export type WasteStatusDto = {
  __typename?: 'WasteStatusDTO';
  bottleConnected?: Maybe<Scalars['Boolean']['output']>;
  bottleFillLevel?: Maybe<Scalars['Int']['output']>;
  /** # 0 to 100 (%) */
  bottleNeedsToBeEmptied?: Maybe<Scalars['Boolean']['output']>;
  bottleUnderPressure?: Maybe<Scalars['Boolean']['output']>;
  vacuumPressure?: Maybe<Scalars['Float']['output']>;
};

export type WellCountConfig = {
  __typename?: 'WellCountConfig';
  maxErrorMers: Scalars['Int']['output'];
  wellCountMax: Scalars['Int']['output'];
};

export type WellInfoDto = {
  actualConcentration: Scalars['Float']['input'];
  well: Scalars['String']['input'];
};

export type LoginMutationVariables = Exact<{
  userName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  rememberMe: Scalars['Boolean']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  loginAs?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type EnableUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
}>;

export type EnableUserMutation = {
  __typename?: 'Mutation';
  enableUser?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type DeleteSessionsMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
}>;

export type DeleteSessionsMutation = {
  __typename?: 'Mutation';
  deleteSessions?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type SetPasswordForInvitedUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type SetPasswordForInvitedUserMutation = {
  __typename?: 'Mutation';
  setPasswordForInvitedUser?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type AuthConfirmEmailMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
}>;

export type AuthConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type CustomProcessCreateMutationVariables = Exact<{
  input: CustomProcessCreateDto;
}>;

export type CustomProcessCreateMutation = {
  __typename?: 'Mutation';
  createCustomProcess?: {
    __typename?: 'CustomProcessInfoDTO';
    id?: string | null;
    name?: string | null;
    organizationId?: string | null;
  } | null;
};

export type CustomProcessDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CustomProcessDeleteMutation = {
  __typename?: 'Mutation';
  deleteCustomProcess?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type DeleteGroupByIdMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteGroupByIdMutation = {
  __typename?: 'Mutation';
  deprecateGroup?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type RestoreGroupByIdMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RestoreGroupByIdMutation = {
  __typename?: 'Mutation';
  activateGroup?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createGroup?: { __typename?: 'GroupByIdDTO'; id: string } | null;
};

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Status>;
}>;

export type UpdateGroupMutation = {
  __typename?: 'Mutation';
  updateGroup?: {
    __typename?: 'GroupByIdDTO';
    id: string;
    name: string;
    description?: string | null;
    status?: string | null;
    creator?: { __typename?: 'UserInGroupDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
    users?: Array<{
      __typename?: 'UserInGroupDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
  } | null;
};

export type AddUserToGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type AddUserToGroupMutation = {
  __typename?: 'Mutation';
  addUserToGroup?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type RemoveUserFromGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type RemoveUserFromGroupMutation = {
  __typename?: 'Mutation';
  removeUserFromGroup?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type InstrumentCreateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  instrumentType?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['ID']['input'];
  serialNumber: Scalars['String']['input'];
  systemVersion: InstrumentVersion;
  installationDate?: InputMaybe<Scalars['String']['input']>;
  serviceReminder?: InputMaybe<Scalars['Boolean']['input']>;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  admin?: InputMaybe<EntityByIdDto>;
  members?: InputMaybe<Array<InputMaybe<EntityByIdDto>> | InputMaybe<EntityByIdDto>>;
  printJobVersion?: InputMaybe<PrintJobVersion>;
}>;

export type InstrumentCreateMutation = {
  __typename?: 'Mutation';
  createInstrument?: { __typename?: 'InstrumentByIdDTO'; id?: string | null } | null;
};

export type InstrumentUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['ID']['input'];
  serialNumber: Scalars['String']['input'];
  systemVersion: InstrumentVersion;
  installationDate?: InputMaybe<Scalars['String']['input']>;
  serviceReminder?: InputMaybe<Scalars['Boolean']['input']>;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  admin?: InputMaybe<EntityByIdDto>;
  members?: InputMaybe<Array<InputMaybe<EntityByIdDto>> | InputMaybe<EntityByIdDto>>;
}>;

export type InstrumentUpdateMutation = {
  __typename?: 'Mutation';
  updateInstrument?: { __typename?: 'InstrumentByIdDTO'; id?: string | null } | null;
};

export type InstrumentDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  applyDeleteInQueueJobs?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type InstrumentDeleteMutation = {
  __typename?: 'Mutation';
  deleteInstrument?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type SynchronizeInstrumentKeyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SynchronizeInstrumentKeyMutation = {
  __typename?: 'Mutation';
  synchronizeInstrumentKey?: {
    __typename?: 'OperationResultDTO';
    status?: number | null;
    message?: string | null;
  } | null;
};

export type RequestInstrumentLogMutationVariables = Exact<{
  fromDate?: InputMaybe<Scalars['String']['input']>;
  instrumentId?: InputMaybe<Scalars['ID']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type RequestInstrumentLogMutation = {
  __typename?: 'Mutation';
  requestInstrumentLog?: {
    __typename?: 'InstrumentLogDTO';
    id: string;
    fromDate?: string | null;
    toDate?: string | null;
    downloadUrl?: string | null;
    status?: InstrumentLogStatus | null;
    errors?: Array<string | null> | null;
    userId?: string | null;
  } | null;
};

export type DynamicSecurityUserManagementMutationVariables = Exact<{
  inputData: DynamicSecurityRequest;
}>;

export type DynamicSecurityUserManagementMutation = {
  __typename?: 'Mutation';
  dynamicSecurityUserManagement?: {
    __typename?: 'DynamicSecurityResponse';
    instrumentId?: string | null;
    password?: string | null;
  } | null;
};

export type UpdateOrganizationDataMutationVariables = Exact<{
  organization: OrganizationUpdateDto;
}>;

export type UpdateOrganizationDataMutation = {
  __typename?: 'Mutation';
  updateOrganizationData?: {
    __typename?: 'OperationResultDTO';
    status?: number | null;
    message?: string | null;
  } | null;
};

export type UpdateOrganizationLicenseMutationVariables = Exact<{
  license: Scalars['String']['input'];
}>;

export type UpdateOrganizationLicenseMutation = {
  __typename?: 'Mutation';
  updateOrganizationLicense?: {
    __typename?: 'LicenseDataDTO';
    code?: string | null;
    validFrom?: string | null;
    validTill?: string | null;
    installationName?: string | null;
  } | null;
};

export type SwitchToRemoteOrganizationMutationVariables = Exact<{
  organizationRemoteUserId: Scalars['ID']['input'];
}>;

export type SwitchToRemoteOrganizationMutation = {
  __typename?: 'Mutation';
  switchToRemoteOrganization?: {
    __typename?: 'OperationResultDTO';
    status?: number | null;
    message?: string | null;
  } | null;
};

export type SwitchToOriginalOrganizationMutationVariables = Exact<{ [key: string]: never }>;

export type SwitchToOriginalOrganizationMutation = {
  __typename?: 'Mutation';
  switchToOriginalOrganization?: {
    __typename?: 'OperationResultDTO';
    status?: number | null;
    message?: string | null;
  } | null;
};

export type ProjectCreateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  budget: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  owner: EntityByIdDto;
  admin: EntityByIdDto;
  users?: InputMaybe<Array<EntityByIdDto> | EntityByIdDto>;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  projectType: ProjectType;
}>;

export type ProjectCreateMutation = {
  __typename?: 'Mutation';
  createProject?: { __typename?: 'ProjectByIdDTO'; id?: string | null } | null;
};

export type ProjectUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  budget: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  owner: EntityByIdDto;
  admin: EntityByIdDto;
  users?: InputMaybe<Array<EntityByIdDto> | EntityByIdDto>;
  restrictedAccess?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProjectUpdateMutation = {
  __typename?: 'Mutation';
  updateProject?: { __typename?: 'ProjectByIdDTO'; id?: string | null } | null;
};

export type ProjectTemplateCreateMutationVariables = Exact<{
  fileName?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  parentPlateId?: InputMaybe<Scalars['ID']['input']>;
  plateType?: InputMaybe<PlateType>;
  type?: InputMaybe<ContentType>;
  kitType?: InputMaybe<KitType>;
  runPurityEstimate?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProjectTemplateCreateMutation = {
  __typename?: 'Mutation';
  createTemplate?: {
    __typename?: 'TemplateDetailsDTO';
    id: string;
    name?: string | null;
    status?: TemplateStatus | null;
    format?: ContentFormat | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    plateType?: PlateType | null;
    countOfRuns?: number | null;
    countOfMods?: number | null;
    countOfSequences?: number | null;
    shortestSequence?: number | null;
    longestSequence?: number | null;
    averageSequence?: number | null;
    creator?: {
      __typename?: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type RestoreProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RestoreProjectMutation = {
  __typename?: 'Mutation';
  restoreProject?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type CreateRunSchedulerMutationVariables = Exact<{
  templateId: Scalars['ID']['input'];
  instrumentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  customProcessId?: InputMaybe<Scalars['ID']['input']>;
  centrifugationType?: InputMaybe<CentrifugationType>;
}>;

export type CreateRunSchedulerMutation = {
  __typename?: 'Mutation';
  createRunScheduler?: {
    __typename?: 'RunSchedulerCreateResponseDTO';
    id?: string | null;
    createdTime?: string | null;
    scheduledStartTime?: string | null;
    scheduledEndTime?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    status?: string | null;
    description?: string | null;
    metalink?: string | null;
    centrifugationType?: CentrifugationType | null;
    longestSequence?: number | null;
    shortestSequence?: number | null;
    modifiedCount?: number | null;
    clicksCount?: number | null;
    sequenceCount?: number | null;
    durationInSeconds?: number | null;
    instrument?: { __typename?: 'InstrumentByIdDTO'; id?: string | null; name?: string | null } | null;
    user?: { __typename?: 'UserInGroupDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
    estimatedReagents?: Array<{
      __typename?: 'RunReagentDTO';
      code?: string | null;
      displayName?: string | null;
      amount?: number | null;
      bottles?: Array<{
        __typename?: 'RunReagentBottleDTO';
        label?: string | null;
        volumeMl?: number | null;
      } | null> | null;
    } | null> | null;
    idnaTemplate?: { __typename?: 'TemplatePerProjectDTO'; id: string; name?: string | null } | null;
  } | null;
};

export type CreateGeneRunSchedulerMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  instrumentId: Scalars['ID']['input'];
}>;

export type CreateGeneRunSchedulerMutation = {
  __typename?: 'Mutation';
  createGeneRunScheduler?: Array<{
    __typename?: 'RunSchedulerCreateResponseDTO';
    id?: string | null;
    sheetId?: string | null;
    scheduledStartTime?: string | null;
    scheduledEndTime?: string | null;
    startTime?: string | null;
    createdTime?: string | null;
    endTime?: string | null;
    status?: string | null;
    description?: string | null;
    metalink?: string | null;
    longestSequence?: number | null;
    shortestSequence?: number | null;
    sequenceCount?: number | null;
    durationInSeconds?: number | null;
    instrument?: {
      __typename?: 'InstrumentByIdDTO';
      id?: string | null;
      name?: string | null;
      serialNumber?: string | null;
      version?: InstrumentVersion | null;
    } | null;
    user?: {
      __typename?: 'UserInGroupDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      role?: string | null;
    } | null;
  } | null> | null;
};

export type UploadRunReportMutationVariables = Exact<{
  fileName?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
}>;

export type UploadRunReportMutation = {
  __typename?: 'Mutation';
  uploadRunReport?: {
    __typename?: 'UploadReportResponseDTO';
    id?: string | null;
    projectId?: string | null;
    projectName?: string | null;
    instrumentId?: string | null;
    scheduledStartTime?: string | null;
    scheduledEndTime?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    metaLink?: string | null;
    reportMetaLink?: string | null;
    description?: string | null;
    status?: string | null;
  } | null;
};

export type DeleteRunMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteRunMutation = {
  __typename?: 'Mutation';
  deleteRun?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type ReprintFailedWellsMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  instrumentId: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ReprintFailedWellsMutation = { __typename?: 'Mutation'; reprintFailedWells?: boolean | null };

export type UpdateConcentrationMutationVariables = Exact<{
  runId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  wellInfo: Array<WellInfoDto> | WellInfoDto;
}>;

export type UpdateConcentrationMutation = { __typename?: 'Mutation'; updateConcentration?: boolean | null };

export type UpdatePqaRunInfoMutationVariables = Exact<{
  runId: Scalars['ID']['input'];
  conditionName?: InputMaybe<Scalars['String']['input']>;
  plateTitle?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdatePqaRunInfoMutation = {
  __typename?: 'Mutation';
  updatePqaRunInfo?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type PlateCreateMutationVariables = Exact<{
  fileName?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  plateType?: InputMaybe<PlateType>;
  kitType?: InputMaybe<KitType>;
}>;

export type PlateCreateMutation = {
  __typename?: 'Mutation';
  createIDNAPlate?: {
    __typename?: 'TemplateDetailsDTO';
    id: string;
    name?: string | null;
    status?: TemplateStatus | null;
    format?: ContentFormat | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    plateType?: PlateType | null;
    kitType?: KitType | null;
    countOfRuns?: number | null;
    countOfMods?: number | null;
    countOfSequences?: number | null;
    shortestSequence?: number | null;
    longestSequence?: number | null;
    averageSequence?: number | null;
    creator?: {
      __typename?: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type PlateUpdateMutationVariables = Exact<{
  inputData: TemplateUpdateDto;
}>;

export type PlateUpdateMutation = {
  __typename?: 'Mutation';
  updateIDNATemplate?: {
    __typename?: 'TemplateDetailsDTO';
    id: string;
    name?: string | null;
    status?: TemplateStatus | null;
    format?: ContentFormat | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    countOfRuns?: number | null;
    countOfMods?: number | null;
    countOfClicks?: number | null;
    countOfSequences?: number | null;
    shortestSequence?: number | null;
    longestSequence?: number | null;
    averageSequence?: number | null;
    plateType?: PlateType | null;
    parentIDNAId?: string | null;
    parentIDNAName?: string | null;
    kitType?: KitType | null;
    exportMetaLink?: string | null;
    normalizationType?: NormalizationType | null;
    targetConcentration?: number | null;
    projectType?: ProjectType | null;
    creator?: {
      __typename: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    prefixes?: Array<{
      __typename?: 'SequenceDTO';
      wellIndex?: number | null;
      data?: string | null;
      nucChunks?: Array<Nucleo | null> | null;
    } | null> | null;
    sequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
    emptySequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateTemplateMutationVariables = Exact<{
  templateId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sequences: Array<SequenceInfoDto> | SequenceInfoDto;
  normalizationType?: InputMaybe<NormalizationType>;
  targetConcentration?: InputMaybe<Scalars['Float']['input']>;
  runPurityEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  parentPlateId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateTemplateMutation = {
  __typename?: 'Mutation';
  updateTemplate?: {
    __typename?: 'TemplateDetailsDTO';
    id: string;
    name?: string | null;
    status?: TemplateStatus | null;
    plateType?: PlateType | null;
    errors?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    prefixes?: Array<{
      __typename?: 'SequenceDTO';
      wellIndex?: number | null;
      nucChunks?: Array<Nucleo | null> | null;
      data?: string | null;
    } | null> | null;
    sequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CopyTemplateMutationVariables = Exact<{
  parentId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type CopyTemplateMutation = {
  __typename?: 'Mutation';
  copyIDNATemplate?: { __typename?: 'TemplateDetailsDTO'; id: string } | null;
};

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTemplateMutation = {
  __typename?: 'Mutation';
  deleteTemplate?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type DeleteIdnaTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteIdnaTemplateMutation = {
  __typename?: 'Mutation';
  deleteIDNATemplate?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type RestoreTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RestoreTemplateMutation = {
  __typename?: 'Mutation';
  restoreTemplate?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type RestoreIdnaTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RestoreIdnaTemplateMutation = {
  __typename?: 'Mutation';
  restoreIDNATemplate?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type ChangePasswordForUserMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
}>;

export type ChangePasswordForUserMutation = {
  __typename?: 'Mutation';
  changePasswordForUser?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  workPhone?: InputMaybe<Scalars['String']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  address: AddressInputDto;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  groupIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserUpdateMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'UserDetailedDTO';
    id: string;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    status?: string | null;
    cellPhone?: string | null;
    workPhone?: string | null;
    countOfProjects?: number | null;
    updatedAt?: string | null;
    createdAt?: string | null;
    address?: {
      __typename?: 'Address';
      country?: string | null;
      state?: string | null;
      city?: string | null;
      street1?: string | null;
      street2?: string | null;
      zip?: string | null;
    } | null;
    role?: { __typename?: 'RoleDTO'; name?: string | null; description?: string | null; type?: RoleType | null } | null;
    groups?: Array<{
      __typename?: 'GroupModel';
      id: string;
      name: string;
      description?: string | null;
      status?: string | null;
      creatorFirstName?: string | null;
      creatorLastName?: string | null;
      totalGroupMembers?: number | null;
      updatedAt?: string | null;
    } | null> | null;
    timeZone?: { __typename?: 'TimeZoneDTO'; id: string; label: string; offset: number } | null;
  } | null;
};

export type UserProfileUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  workPhone?: InputMaybe<Scalars['String']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  address: AddressInputDto;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserProfileUpdateMutation = {
  __typename?: 'Mutation';
  updateUserProfile?: {
    __typename?: 'UserDetailedDTO';
    id: string;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    status?: string | null;
    cellPhone?: string | null;
    workPhone?: string | null;
    countOfProjects?: number | null;
    updatedAt?: string | null;
    createdAt?: string | null;
    timeZone?: { __typename?: 'TimeZoneDTO'; id: string; label: string; offset: number } | null;
    address?: {
      __typename?: 'Address';
      country?: string | null;
      state?: string | null;
      city?: string | null;
      street1?: string | null;
      street2?: string | null;
      zip?: string | null;
    } | null;
    role?: { __typename?: 'RoleDTO'; name?: string | null; description?: string | null; type?: RoleType | null } | null;
    groups?: Array<{
      __typename?: 'GroupModel';
      id: string;
      name: string;
      description?: string | null;
      status?: string | null;
      creatorFirstName?: string | null;
      creatorLastName?: string | null;
      totalGroupMembers?: number | null;
      updatedAt?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteUserByIdMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserByIdMutation = {
  __typename?: 'Mutation';
  deleteUser?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type UserInviteMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  username: Scalars['String']['input'];
  groupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserInviteMutation = {
  __typename?: 'Mutation';
  inviteUser?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type UserCreateMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
  groupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserCreateMutation = {
  __typename?: 'Mutation';
  addUser?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type UserInviteResendMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type UserInviteResendMutation = {
  __typename?: 'Mutation';
  resendInvitationEmail?: { __typename?: 'OperationResultDTO'; message?: string | null } | null;
};

export type UserReactivateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserReactivateMutation = {
  __typename?: 'Mutation';
  reactivateUser?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type UpdateUserPreferencesMutationVariables = Exact<{
  tooltipOnSeqPageIsClosed: Scalars['Boolean']['input'];
}>;

export type UpdateUserPreferencesMutation = {
  __typename?: 'Mutation';
  updateUserPreferences?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type InviteRemoteUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
  expirationDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type InviteRemoteUserMutation = {
  __typename?: 'Mutation';
  inviteRemoteUser?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type UpdateRemoteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  expirationDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateRemoteUserMutation = {
  __typename?: 'Mutation';
  updateRemoteUser?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type DeactivateRemoteUserMutationVariables = Exact<{
  organizationRemoteUserId: Scalars['ID']['input'];
}>;

export type DeactivateRemoteUserMutation = {
  __typename?: 'Mutation';
  deactivateRemoteUser?: { __typename?: 'OperationResultDTO'; status?: number | null; message?: string | null } | null;
};

export type ValidateVerificationCodeQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
}>;

export type ValidateVerificationCodeQuery = {
  __typename?: 'Query';
  validateVerificationCode?: {
    __typename?: 'OperationResultDTO';
    status?: number | null;
    message?: string | null;
  } | null;
};

export type AllCustomProcessesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCustomProcessesQuery = {
  __typename?: 'Query';
  getAllCustomProcesses?: {
    __typename?: 'CustomProcessPageDTO';
    data?: Array<{
      __typename?: 'CustomProcessInfoDTO';
      id?: string | null;
      name?: string | null;
      organizationId?: string | null;
      downloadUrl?: string | null;
    } | null> | null;
  } | null;
};

export type IsValidCustomProcessQueryVariables = Exact<{
  process: Scalars['String']['input'];
}>;

export type IsValidCustomProcessQuery = { __typename?: 'Query'; isValidCustomProcess?: boolean | null };

type RecentWorkRecentRunWorkItemDtoFragment = {
  __typename: 'RecentRunWorkItemDTO';
  sheetUrl?: string | null;
  reportUrl?: string | null;
  runStatus?: string | null;
  id: string;
  name?: string | null;
  updated?: string | null;
};

type RecentWorkRecentTemplateWorkItemDtoFragment = {
  __typename: 'RecentTemplateWorkItemDTO';
  templateStatus?: TemplateStatus | null;
  id: string;
  name?: string | null;
  updated?: string | null;
};

export type RecentWorkFragment = RecentWorkRecentRunWorkItemDtoFragment | RecentWorkRecentTemplateWorkItemDtoFragment;

export type QueryGetDashboardDataQueryVariables = Exact<{ [key: string]: never }>;

export type QueryGetDashboardDataQuery = {
  __typename?: 'Query';
  getDefaultProjectForUser?: { __typename?: 'ProjectByIdDTO'; id?: string | null; name?: string | null } | null;
};

export type QueryGetRecentWorkQueryVariables = Exact<{
  recentWork: RecentWorkFilterDto;
}>;

export type QueryGetRecentWorkQuery = {
  __typename?: 'Query';
  getRecentWork?: {
    __typename?: 'RecentWorkDTO';
    items?: Array<
      | {
          __typename: 'RecentRunWorkItemDTO';
          sheetUrl?: string | null;
          reportUrl?: string | null;
          runStatus?: string | null;
          id: string;
          name?: string | null;
          updated?: string | null;
        }
      | {
          __typename: 'RecentTemplateWorkItemDTO';
          templateStatus?: TemplateStatus | null;
          id: string;
          name?: string | null;
          updated?: string | null;
        }
      | null
    > | null;
  } | null;
};

export type QueryGetNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type QueryGetNotificationsQuery = {
  __typename?: 'Query';
  getMyNotifications?: {
    __typename?: 'NotificationPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'NotificationDTO';
      id: string;
      body?: string | null;
      createdDate?: string | null;
      additionalData?: Array<{
        __typename?: 'AdditionalItemDTO';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type QueryNotificationRunDetailsQueryVariables = Exact<{
  runId: Scalars['ID']['input'];
}>;

export type QueryNotificationRunDetailsQuery = {
  __typename?: 'Query';
  getRunReportDetails?: {
    __typename?: 'RunReportDetailsDTO';
    id?: string | null;
    name?: string | null;
    description?: string | null;
    status?: RunStatus | null;
    failMessage?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    scheduledStartTime?: string | null;
    scheduledEndTime?: string | null;
    instrument?: { __typename?: 'InstrumentByIdDTO'; id?: string | null; name?: string | null } | null;
    creator?: {
      __typename?: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type RunLightInfoQueryVariables = Exact<{
  runId: Scalars['Long']['input'];
}>;

export type RunLightInfoQuery = {
  __typename?: 'Query';
  getRunLightInfo?: {
    __typename?: 'RunLightInfoDTO';
    id: string;
    name?: string | null;
    status: RunStatus;
    scheduledStartDate?: string | null;
    scheduledEndDate?: string | null;
    updatedAt: string;
    reportMetaLink?: string | null;
    version?: number | null;
  } | null;
};

export type GroupsListQueryVariables = Exact<{
  searchText: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  orderField: Scalars['String']['input'];
  orderDirection: Direction;
  status?: InputMaybe<Scalars['String']['input']>;
}>;

export type GroupsListQuery = {
  __typename?: 'Query';
  allGroups?: {
    __typename?: 'GroupPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'GroupByIdDTO';
      id: string;
      name: string;
      description?: string | null;
      status?: string | null;
      updatedAt?: string | null;
      creator?: {
        __typename?: 'UserInGroupDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      users?: Array<{
        __typename?: 'UserInGroupDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GroupByIdQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;

export type GroupByIdQuery = {
  __typename?: 'Query';
  getGroupById?: {
    __typename?: 'GroupByIdWithUserDetailsDTO';
    id: string;
    name: string;
    description?: string | null;
    status?: string | null;
    updatedAt?: string | null;
    creator?: { __typename?: 'UserInGroupDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
    users?: Array<{
      __typename?: 'UserForGroupByIdDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      status?: string | null;
      dateAdded?: string | null;
      role?: {
        __typename?: 'RoleDTO';
        id: string;
        name?: string | null;
        description?: string | null;
        type?: RoleType | null;
        authority?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type InstallationFilesPageQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  orderField: Scalars['String']['input'];
  orderDirection: Direction;
}>;

export type InstallationFilesPageQuery = {
  __typename?: 'Query';
  getInstallationFiles?: {
    __typename?: 'InstallationFilePageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'InstallationFileInfoDTO';
      fileName?: string | null;
      releaseVersion?: string | null;
      releaseDate?: string | null;
    } | null> | null;
  } | null;
};

export type InstrumentsListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortField: Scalars['String']['input'];
  sortDirection: Direction;
}>;

export type InstrumentsListQuery = {
  __typename?: 'Query';
  getAllInstruments?: {
    __typename?: 'InstrumentPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'InstrumentDTO';
      id: string;
      name?: string | null;
      serialNumber?: string | null;
      version?: InstrumentVersion | null;
      image?: string | null;
      installationDate?: string | null;
      lastUpdatedDate?: string | null;
      serviceDate?: string | null;
      location?: string | null;
      status?: string | null;
      model?: {
        __typename?: 'InstrumentModelDTO';
        id: string;
        name: string;
        supportedSoftware: Array<InstrumentVersion>;
      } | null;
    } | null> | null;
  } | null;
};

export type InstrumentsDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InstrumentsDetailsQuery = {
  __typename?: 'Query';
  getInstrumentDetailsById?: {
    __typename?: 'InstrumentDetailsDTO';
    id: string;
    image?: string | null;
    installationDate?: string | null;
    instrumentType?: string | null;
    kitType?: KitType | null;
    lastUpdatedDate?: string | null;
    location?: string | null;
    mqttPassword?: string | null;
    name?: string | null;
    restrictedAccess?: boolean | null;
    serialNumber?: string | null;
    serviceDate?: string | null;
    serviceReminder?: boolean | null;
    softwareVersion?: string | null;
    status?: string | null;
    version?: InstrumentVersion | null;
    versionsUpdateMode?: InstrumentVersionsUpdateMode | null;
    model?: {
      __typename?: 'InstrumentModelDTO';
      id: string;
      name: string;
      supportedSoftware: Array<InstrumentVersion>;
    } | null;
    members?: Array<{
      __typename?: 'UserInInstrumentDetailsDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      permission?: string | null;
    } | null> | null;
  } | null;
};

export type InstrumentStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InstrumentStatusQuery = {
  __typename?: 'Query';
  getInstrumentStatus?: {
    __typename?: 'InstrumentStatusDTO';
    reagents?: Array<{
      __typename?: 'InstrumentReagentStatusDTO';
      reagentUsageUnion: ReagentUsageUnion;
      volumePercentageLeft?: number | null;
      expirationDate?: string | null;
      isUsable?: boolean | null;
    } | null> | null;
    cycles?: Array<{
      __typename?: 'InstrumentRemainingCyclesDTO';
      plateType: PlateType;
      cyclesCount?: number | null;
    } | null> | null;
  } | null;
};

export type InstrumentQueueQueryVariables = Exact<{
  instrumentId: Scalars['ID']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
}>;

export type InstrumentQueueQuery = {
  __typename?: 'Query';
  getInstrumentsInQueue?: Array<{
    __typename?: 'InstrumentInQueueDTO';
    id?: string | null;
    name?: string | null;
    startDate?: string | null;
    endDate?: string | null;
  } | null> | null;
};

export type InstrumentHistoryQueryVariables = Exact<{
  instrumentId: Scalars['ID']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
}>;

export type InstrumentHistoryQuery = {
  __typename?: 'Query';
  getRunHistoryPerInstrument?: {
    __typename?: 'RunsPerInstrumentPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'RunPerInstrumentDTO';
      completedDate?: string | null;
      countOfMods?: number | null;
      countOfPassedSequences?: number | null;
      countOfTotalSequences?: number | null;
      id: string;
      longestSequence?: number | null;
      metaLink?: string | null;
      name?: string | null;
      percentOfPassedOligos?: number | null;
      reportMetaLink?: string | null;
      shortestSequence?: number | null;
      startDate?: string | null;
      status?: string | null;
      kitType?: KitType | null;
      parentIDNAId?: string | null;
      scheduledStartDate?: string | null;
      endDate?: string | null;
      updatedAt?: string | null;
      project?: { __typename?: 'ProjectByIdDTO'; id?: string | null; name?: string | null } | null;
      creator?: {
        __typename?: 'UserInInstrumentRunDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      customProcess?: { __typename?: 'CustomProcessInfoDTO'; id?: string | null; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type InstrumentsForPrintQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;

export type InstrumentsForPrintQuery = {
  __typename?: 'Query';
  findSuitableInstrumentsForPrint?: Array<{
    __typename?: 'InstrumentByIdDTO';
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type ValidateInstrumentKitTypeQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
  instrumentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type ValidateInstrumentKitTypeQuery = {
  __typename?: 'Query';
  validateInstrumentKitType?: {
    __typename?: 'InstrumentKitTypeValidationResponseDTO';
    result?: string | null;
    message?: string | null;
  } | null;
};

export type InstrumentsModelsQueryVariables = Exact<{ [key: string]: never }>;

export type InstrumentsModelsQuery = {
  __typename?: 'Query';
  getInstrumentModels?: Array<{
    __typename?: 'InstrumentModelDTO';
    id: string;
    name: string;
    version: HardwareVersion;
    supportedSoftware: Array<InstrumentVersion>;
  } | null> | null;
};

export type GetInstrumentLogByIdQueryVariables = Exact<{
  logId: Scalars['ID']['input'];
}>;

export type GetInstrumentLogByIdQuery = {
  __typename?: 'Query';
  getInstrumentLogById?: {
    __typename?: 'InstrumentLogDTO';
    id: string;
    fromDate?: string | null;
    toDate?: string | null;
    downloadUrl?: string | null;
    status?: InstrumentLogStatus | null;
    errors?: Array<string | null> | null;
    userId?: string | null;
  } | null;
};

export type ActiveJobsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
}>;

export type ActiveJobsQuery = {
  __typename?: 'Query';
  getNowRunsPerOrganizationByUser?: Array<{
    __typename?: 'RunPerUserDTO';
    id: string;
    name?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    status?: string | null;
    scheduledStartDate?: string | null;
    scheduledEndDate?: string | null;
    assignedInstrument?: { __typename?: 'InstrumentPerRunDTO'; id: string; name?: string | null } | null;
  } | null> | null;
};

export type GetOrganizationDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationDataQuery = {
  __typename?: 'Query';
  getOrganizationData?: {
    __typename?: 'OrganizationDataDTO';
    id?: string | null;
    status?: OrganizationStatus | null;
    companyName?: string | null;
    aesKey?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    maxLoginFailedAttempts?: number | null;
    printingEnabled?: boolean | null;
    geneEnabled?: boolean | null;
    clicksEnabled?: boolean | null;
    modsEnabled?: boolean | null;
    degeneratesEnabled?: boolean | null;
    customProcessEnabled?: boolean | null;
    license?: {
      __typename?: 'LicenseContentDTO';
      code?: string | null;
      validFrom?: string | null;
      validTill?: string | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      title?: string | null;
      workNumber?: string | null;
      mobileNumber?: string | null;
    } | null;
    address?: {
      __typename?: 'Address';
      country?: string | null;
      state?: string | null;
      city?: string | null;
      street1?: string | null;
      street2?: string | null;
      zip?: string | null;
    } | null;
    timeZone?: { __typename?: 'TimeZoneDTO'; id: string; label: string; offset: number } | null;
  } | null;
};

export type GetLoginEventsQueryVariables = Exact<{
  filter?: InputMaybe<LoginEventFilterDto>;
}>;

export type GetLoginEventsQuery = {
  __typename?: 'Query';
  getLoginEvents?: {
    __typename?: 'LoginEventPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'LoginEventDTO';
      id?: string | null;
      username?: string | null;
      ipAddress?: string | null;
      type?: LoginEventType | null;
      remarks?: string | null;
      createdAt?: string | null;
    } | null> | null;
  } | null;
};

export type RemoteOrganizationFragmentFragment = {
  __typename?: 'OrganizationRemoteUserDTO';
  id: string;
  status?: RemoteUserStatus | null;
  expirationDate?: string | null;
  createdAt?: string | null;
  user?: {
    __typename?: 'UserDTO';
    id: string;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  organization?: { __typename?: 'OrganizationDTO'; id: string; name?: string | null } | null;
  role?: {
    __typename?: 'RoleDTO';
    id: string;
    name?: string | null;
    description?: string | null;
    type?: RoleType | null;
    authority?: string | null;
  } | null;
};

export type GetRemoteOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRemoteOrganizationsQuery = {
  __typename?: 'Query';
  getRemoteOrganizations?: Array<{
    __typename?: 'OrganizationRemoteUserDTO';
    id: string;
    status?: RemoteUserStatus | null;
    expirationDate?: string | null;
    createdAt?: string | null;
    user?: {
      __typename?: 'UserDTO';
      id: string;
      username?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    organization?: { __typename?: 'OrganizationDTO'; id: string; name?: string | null } | null;
    role?: {
      __typename?: 'RoleDTO';
      id: string;
      name?: string | null;
      description?: string | null;
      type?: RoleType | null;
      authority?: string | null;
    } | null;
  } | null> | null;
};

export type ProjectItemInTableDtoFragment = {
  __typename?: 'ProjectDTO';
  completedRuns?: number | null;
  countOfDraftTemplates?: number | null;
  countOfErrorTemplates?: number | null;
  countOfOligos?: number | null;
  countOfPassedOligos?: number | null;
  countOfReadyTemplates?: number | null;
  countOfRuns?: number | null;
  countOfWarningTemplates?: number | null;
  createdAt?: string | null;
  id: string;
  name: string;
  percentOfOligosMore200?: number | null;
  percentOfPassedOligos?: number | null;
  restrictedAccess?: boolean | null;
  runsInQueue?: number | null;
  status?: string | null;
  updatedAt?: string | null;
  creator?: { __typename?: 'UserInProjectDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
  admin?: { __typename?: 'UserInProjectDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
};

export type ProjectsListQueryVariables = Exact<{
  status: Status;
  projectType: ProjectType;
}>;

export type ProjectsListQuery = {
  __typename?: 'Query';
  getAllProjects?: {
    __typename?: 'ProjectPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'ProjectDTO';
      completedRuns?: number | null;
      countOfDraftTemplates?: number | null;
      countOfErrorTemplates?: number | null;
      countOfOligos?: number | null;
      countOfPassedOligos?: number | null;
      countOfReadyTemplates?: number | null;
      countOfRuns?: number | null;
      countOfWarningTemplates?: number | null;
      createdAt?: string | null;
      id: string;
      name: string;
      percentOfOligosMore200?: number | null;
      percentOfPassedOligos?: number | null;
      restrictedAccess?: boolean | null;
      runsInQueue?: number | null;
      status?: string | null;
      updatedAt?: string | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      admin?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type MyProjectsListQueryVariables = Exact<{
  status: Status;
  projectType: ProjectType;
}>;

export type MyProjectsListQuery = {
  __typename?: 'Query';
  getMyProjects?: {
    __typename?: 'ProjectPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'ProjectDTO';
      completedRuns?: number | null;
      countOfDraftTemplates?: number | null;
      countOfErrorTemplates?: number | null;
      countOfOligos?: number | null;
      countOfPassedOligos?: number | null;
      countOfReadyTemplates?: number | null;
      countOfRuns?: number | null;
      countOfWarningTemplates?: number | null;
      createdAt?: string | null;
      id: string;
      name: string;
      percentOfOligosMore200?: number | null;
      percentOfPassedOligos?: number | null;
      restrictedAccess?: boolean | null;
      runsInQueue?: number | null;
      status?: string | null;
      updatedAt?: string | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      admin?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProjectDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectDetailQuery = {
  __typename?: 'Query';
  getProjectDetailsById?: {
    __typename?: 'ProjectDetailDTO';
    budget?: number | null;
    completedRuns?: number | null;
    countOfDraftTemplates?: number | null;
    countOfErrorTemplates?: number | null;
    countOfRuns?: number | null;
    countOfTemplates?: number | null;
    createdAt?: string | null;
    currency?: string | null;
    description?: string | null;
    expense?: number | null;
    id: string;
    name?: string | null;
    percentOfPassedOligos?: number | null;
    projectType: ProjectType;
    restrictedAccess?: boolean | null;
    runsInQueue?: number | null;
    updatedAt?: string | null;
    owner?: { __typename?: 'UserInProjectDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
    admin?: { __typename?: 'UserInProjectDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
    members?: Array<{
      __typename?: 'UserInProjectDetailsDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      accessPermission?: AccessPermission | null;
      roles?: Array<{
        __typename?: 'RoleDTO';
        id: string;
        name?: string | null;
        type?: RoleType | null;
        authority?: string | null;
      } | null> | null;
    } | null> | null;
    organization?: { __typename?: 'ProjectOrganizationDTO'; id: string; name?: string | null } | null;
    geneDetail?: {
      __typename?: 'GeneDetailDTO';
      vectorPrimers?: Array<string> | null;
      vector?: { __typename?: 'CloningVectorDTO'; id: string; name: string; sequence: string } | null;
    } | null;
  } | null;
};

export type ProjectTemplatesQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectTemplatesQuery = {
  __typename?: 'Query';
  getTemplatesPerProject?: {
    __typename?: 'TemplatePerProjectPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'TemplatePerProjectDTO';
      countOfClicks?: number | null;
      countOfMods?: number | null;
      countOfRuns?: number | null;
      countOfSequences?: number | null;
      createdAt?: string | null;
      exportMetaLink?: string | null;
      format?: ContentFormat | null;
      id: string;
      kitType?: KitType | null;
      longestSequence?: number | null;
      name?: string | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      partNumber?: string | null;
      plateType?: PlateType | null;
      shortestSequence?: number | null;
      status?: TemplateStatus | null;
      updatedAt?: string | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProjectTemplatesIDsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectTemplatesIDsQuery = {
  __typename?: 'Query';
  getTemplatesPerProject?: {
    __typename?: 'TemplatePerProjectPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{ __typename?: 'TemplatePerProjectDTO'; id: string; kitType?: KitType | null } | null> | null;
  } | null;
};

export type ProjectRunsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectRunsQuery = {
  __typename?: 'Query';
  getRunsPerProject?: {
    __typename?: 'RunPerProjectPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'RunPerProjectDTO';
      countOfMods?: number | null;
      countOfPassedSequences?: number | null;
      countOfTotalSequences?: number | null;
      endDate?: string | null;
      id: string;
      kitType?: KitType | null;
      longestSequence?: number | null;
      metaLink?: string | null;
      name?: string | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      percentOfPassedOligos?: number | null;
      plateType?: PlateType | null;
      reportMetaLink?: string | null;
      scheduledStartDate?: string | null;
      shortestSequence?: number | null;
      status?: string | null;
      updatedAt?: string | null;
      version?: number | null;
      assignedInstrument?: { __typename?: 'InstrumentPerRunDTO'; id: string; name?: string | null } | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      customProcess?: { __typename?: 'CustomProcessInfoDTO'; id?: string | null; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetDefaultProjectForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultProjectForUserQuery = {
  __typename?: 'Query';
  getDefaultProjectForUser?: { __typename?: 'ProjectByIdDTO'; id?: string | null; name?: string | null } | null;
};

export type GetUsersForNewProjectQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersForNewProjectQuery = {
  __typename?: 'Query';
  getUsersForNewProject?: {
    __typename?: 'UsersForNewProjectDTO';
    owner?: {
      __typename?: 'UserInProjectDetailsDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    admin?: {
      __typename?: 'UserInProjectDetailsDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    members?: Array<{
      __typename?: 'UserInProjectDetailsDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      accessPermission?: AccessPermission | null;
      roles?: Array<{
        __typename?: 'RoleDTO';
        id: string;
        name?: string | null;
        type?: RoleType | null;
        authority?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ProjectRunsGroupsShortQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectRunsGroupsShortQuery = {
  __typename?: 'Query';
  getRunGroupsPerProject?: {
    __typename?: 'RunGroupPerProjectPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'RunPerGroupDTO';
      name?: string | null;
      version?: number | null;
      queued?: number | null;
      completed?: number | null;
      status?: string | null;
      numberOfPlates?: number | null;
      runs?: Array<{
        __typename?: 'RunPerProjectDTO';
        id: string;
        name?: string | null;
        status?: string | null;
        countOfPassedSequences?: number | null;
        countOfTotalSequences?: number | null;
        percentOfPassedOligos?: number | null;
        version?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type RolesListQueryVariables = Exact<{ [key: string]: never }>;

export type RolesListQuery = {
  __typename?: 'Query';
  getAllRoles?: Array<{
    __typename?: 'RoleDTO';
    id: string;
    name?: string | null;
    type?: RoleType | null;
  } | null> | null;
};

export type RunSchedulerQueryVariables = Exact<{
  instrumentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type RunSchedulerQuery = {
  __typename?: 'Query';
  getRunSchedule?: Array<{
    __typename?: 'RunsScheduleDTO';
    id?: string | null;
    instrumentId?: string | null;
    sheetId?: string | null;
    templateName?: string | null;
    scheduledStartTime?: string | null;
    scheduledEndTime?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    status?: string | null;
  } | null> | null;
};

export type JobDurationQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
  instrumentId: Scalars['ID']['input'];
}>;

export type JobDurationQuery = { __typename?: 'Query'; getJobDuration?: number | null };

export type RunsDataFieldsFragment = {
  __typename?: 'RunPerUserDTO';
  countOfMods?: number | null;
  countOfPassedSequences?: number | null;
  countOfTotalSequences?: number | null;
  endDate?: string | null;
  id: string;
  kitType?: KitType | null;
  longestSequence?: number | null;
  metaLink?: string | null;
  name?: string | null;
  parentIDNAId?: string | null;
  parentIDNAName?: string | null;
  percentOfPassedOligos?: number | null;
  plateType?: PlateType | null;
  reportMetaLink?: string | null;
  scheduledStartDate?: string | null;
  shortestSequence?: number | null;
  status?: string | null;
  updatedAt?: string | null;
  assignedInstrument?: { __typename?: 'InstrumentPerRunDTO'; id: string; name?: string | null } | null;
  project?: {
    __typename?: 'ProjectByIdDTO';
    id?: string | null;
    name?: string | null;
    description?: string | null;
  } | null;
  creator?: { __typename?: 'UserInProjectDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
  customProcess?: {
    __typename?: 'CustomProcessInfoDTO';
    id?: string | null;
    name?: string | null;
    downloadUrl?: string | null;
  } | null;
};

export type QueryRunsUserQueryVariables = Exact<{
  filter: RunPerProjectFilterDto;
}>;

export type QueryRunsUserQuery = {
  __typename?: 'Query';
  getRunsPerUser?: {
    __typename?: 'RunPerUserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'RunPerUserDTO';
      countOfMods?: number | null;
      countOfPassedSequences?: number | null;
      countOfTotalSequences?: number | null;
      endDate?: string | null;
      id: string;
      kitType?: KitType | null;
      longestSequence?: number | null;
      metaLink?: string | null;
      name?: string | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      percentOfPassedOligos?: number | null;
      plateType?: PlateType | null;
      reportMetaLink?: string | null;
      scheduledStartDate?: string | null;
      shortestSequence?: number | null;
      status?: string | null;
      updatedAt?: string | null;
      assignedInstrument?: { __typename?: 'InstrumentPerRunDTO'; id: string; name?: string | null } | null;
      project?: {
        __typename?: 'ProjectByIdDTO';
        id?: string | null;
        name?: string | null;
        description?: string | null;
      } | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      customProcess?: {
        __typename?: 'CustomProcessInfoDTO';
        id?: string | null;
        name?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type QueryRunsOrganizationQueryVariables = Exact<{
  filter: RunPerProjectFilterDto;
}>;

export type QueryRunsOrganizationQuery = {
  __typename?: 'Query';
  getRunsPerOrganizationByUser?: {
    __typename?: 'RunPerUserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'RunPerUserDTO';
      countOfMods?: number | null;
      countOfPassedSequences?: number | null;
      countOfTotalSequences?: number | null;
      endDate?: string | null;
      id: string;
      kitType?: KitType | null;
      longestSequence?: number | null;
      metaLink?: string | null;
      name?: string | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      percentOfPassedOligos?: number | null;
      plateType?: PlateType | null;
      reportMetaLink?: string | null;
      scheduledStartDate?: string | null;
      shortestSequence?: number | null;
      status?: string | null;
      updatedAt?: string | null;
      assignedInstrument?: { __typename?: 'InstrumentPerRunDTO'; id: string; name?: string | null } | null;
      project?: {
        __typename?: 'ProjectByIdDTO';
        id?: string | null;
        name?: string | null;
        description?: string | null;
      } | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      customProcess?: {
        __typename?: 'CustomProcessInfoDTO';
        id?: string | null;
        name?: string | null;
        downloadUrl?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type QueryRunDetailsQueryVariables = Exact<{
  runId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  shouldIncludeProject: Scalars['Boolean']['input'];
}>;

export type QueryRunDetailsQuery = {
  __typename?: 'Query';
  getRunReportDetails?: {
    __typename?: 'RunReportDetailsDTO';
    id?: string | null;
    name?: string | null;
    description?: string | null;
    status?: RunStatus | null;
    shortestSequence?: number | null;
    longestSequence?: number | null;
    countOfMods?: number | null;
    countOfClicks?: number | null;
    metaLink?: string | null;
    reportMetaLink?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    plateType?: PlateType | null;
    kitType?: KitType | null;
    scheduledStartTime?: string | null;
    scheduledEndTime?: string | null;
    countOfPassedSequences?: number | null;
    percentOfPassedSequences?: number | null;
    failMessage?: string | null;
    project?: {
      __typename?: 'ProjectByIdDTO';
      id?: string | null;
      name?: string | null;
      description?: string | null;
      projectType?: ProjectType | null;
    } | null;
    instrument?: {
      __typename?: 'InstrumentByIdDTO';
      id?: string | null;
      name?: string | null;
      serialNumber?: string | null;
      version?: InstrumentVersion | null;
    } | null;
    creator?: {
      __typename?: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    sequences?: Array<{
      __typename?: 'RunSequenceDTO';
      wellIndex?: number | null;
      well?: string | null;
      data?: string | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      modStatus?: ModStatus | null;
      clicksCount?: number | null;
      targetConcetration?: number | null;
      actualConcentration?: number | null;
      yield?: number | null;
      volume?: number | null;
      purity?: number | null;
      errorType?: string | null;
      status?: RunSequenceStatus | null;
      score?: SequenceScore | null;
      prefixData?: string | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      validationLength?: number | null;
      normalizationType?: NormalizationType | null;
      modified?: boolean | null;
      lastUpdatedDate?: string | null;
      crossElution?: {
        __typename?: 'CrossElutionDTO';
        wellsFrom?: Array<string | null> | null;
        wellsTo?: Array<string | null> | null;
      } | null;
    } | null> | null;
    reagentGroups?: Array<{
      __typename?: 'RunReagentGroupDTO';
      name?: string | null;
      code?: string | null;
      reagents?: Array<{
        __typename?: 'RunReagentDetailsDTO';
        code?: string | null;
        displayName?: string | null;
        partNumber?: string | null;
        lotNumber?: string | null;
        expireDate?: string | null;
        days?: string | null;
        amount?: number | null;
      } | null> | null;
    } | null> | null;
    yieldSettings?: {
      __typename?: 'RunReportYieldSettingsDTO';
      values: Array<{ __typename?: 'RunReportYieldSetting'; min: number; max: number; level: RunReportYieldLevel }>;
    } | null;
    volumeSettings?: { __typename?: 'RunReportVolumeSettingsDTO'; min: number; max: number } | null;
    pqaInfo?: {
      __typename?: 'PqaRunInfoDTO';
      conditionName?: string | null;
      plateTitle?: string | null;
      comment?: string | null;
    } | null;
  } | null;
  getProjectDetailsById?: { __typename?: 'ProjectDetailDTO'; name?: string | null; projectType: ProjectType } | null;
};

export type GetPqaStatisticQueryVariables = Exact<{
  runId: Scalars['ID']['input'];
}>;

export type GetPqaStatisticQuery = {
  __typename?: 'Query';
  getPqaStatistic: {
    __typename?: 'PqaRunStatisticDTO';
    groupsInfo: Array<{
      __typename?: 'PqaRunGroupStatisticDTO';
      groupName: string;
      minSizePmol: number;
      maxInvalidWellsNumber: number;
      totalWellNumber: number;
      averageYieldPmol: number;
      minInGroup: number;
      maxInGroup: number;
      standardDeviationPmol: number;
      coefficientOfVariation: number;
      invalidWellsNumber: number;
    } | null>;
    modsInfo?: {
      __typename?: 'PqaRunModsStatisticDTO';
      status: PqaRunModsStatus;
      wellMods: Array<{
        __typename?: 'PqaRunWellModsDTO';
        well: string;
        sequenceName: string;
        conclusion: CouplingLevel;
        mods: Array<{ __typename?: 'PqaRunModDTO'; label: string; couplingPercent: number; level: CouplingLevel }>;
      }>;
      modsConfig: Array<{
        __typename?: 'PqaModConfig';
        mod: string;
        couplingLevels: Array<{ __typename?: 'PqaCouplingLevel'; level: CouplingLevel; min: number; max: number }>;
      }>;
    } | null;
  };
};

export type ProjectRunsGroupsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ProjectRunsGroupsQuery = {
  __typename?: 'Query';
  getRunGroupsPerProject?: {
    __typename: 'RunGroupPerProjectPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'RunPerGroupDTO';
      name?: string | null;
      version?: number | null;
      queued?: number | null;
      completed?: number | null;
      status?: string | null;
      numberOfPlates?: number | null;
      runs?: Array<{
        __typename: 'RunPerProjectDTO';
        id: string;
        name?: string | null;
        status?: string | null;
        kitType?: KitType | null;
        countOfPassedSequences?: number | null;
        countOfTotalSequences?: number | null;
        shortestSequence?: number | null;
        longestSequence?: number | null;
        countOfMods?: number | null;
        scheduledStartDate?: string | null;
        endDate?: string | null;
        percentOfPassedOligos?: number | null;
        metaLink?: string | null;
        reportMetaLink?: string | null;
        plateType?: PlateType | null;
        parentIDNAId?: string | null;
        parentIDNAName?: string | null;
        version?: number | null;
        assignedInstrument?: { __typename: 'InstrumentPerRunDTO'; id: string; name?: string | null } | null;
        creator?: {
          __typename: 'UserInProjectDTO';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ServiceInfoFieldsFragment = {
  __typename?: 'ServiceInfoDTO';
  server?: string | null;
  client?: string | null;
  connection?: string | null;
  status?: ServiceStatus | null;
  message?: string | null;
  type?: ServiceType | null;
};

export type SystemInfoQueryVariables = Exact<{ [key: string]: never }>;

export type SystemInfoQuery = {
  __typename?: 'Query';
  systemInfo: Array<{
    __typename?: 'ServiceInfoDTO';
    server?: string | null;
    client?: string | null;
    connection?: string | null;
    status?: ServiceStatus | null;
    message?: string | null;
    type?: ServiceType | null;
  }>;
};

export type RedboxVersionQueryVariables = Exact<{ [key: string]: never }>;

export type RedboxVersionQuery = { __typename?: 'Query'; getRedboxVersion?: string | null };

export type SupportedInstrumentVersionsQueryVariables = Exact<{ [key: string]: never }>;

export type SupportedInstrumentVersionsQuery = {
  __typename?: 'Query';
  getSupportedInstrumentVersions: Array<InstrumentVersion>;
};

export type GetAvailableTimeZonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableTimeZonesQuery = {
  __typename?: 'Query';
  getAvailableTimeZones?: Array<{
    __typename?: 'TimeZoneDTO';
    id: string;
    label: string;
    offset: number;
  } | null> | null;
};

export type DesignContextSwitchQueryVariables = Exact<{ [key: string]: never }>;

export type DesignContextSwitchQuery = { __typename?: 'Query'; designContextSwitch?: string | null };

export type SequenceDtoFragmentFragment = {
  __typename?: 'SequenceDTO';
  id?: string | null;
  well?: string | null;
  wellIndex?: number | null;
  name?: string | null;
  length?: number | null;
  modsCount?: number | null;
  prefixNucChunks?: Array<Nucleo | null> | null;
  prefixDataChunks?: Array<string | null> | null;
  nucChunks?: Array<Nucleo | null> | null;
  dataChunks?: Array<string | null> | null;
  status?: SequenceStatus | null;
  targetConcetration?: number | null;
  normalizationType?: NormalizationType | null;
  usePlateNorm?: boolean | null;
  data?: string | null;
  score?: SequenceScore | null;
  errorScoreValue?: number | null;
  errors?: Array<{
    __typename?: 'SequenceMessageDTO';
    message?: string | null;
    group?: SequenceMessageGroup | null;
  } | null> | null;
  warnings?: Array<{
    __typename?: 'SequenceMessageDTO';
    message?: string | null;
    group?: SequenceMessageGroup | null;
  } | null> | null;
  nucErrors?: Array<{
    __typename?: 'NucleotideErrorDTO';
    index?: number | null;
    message?: string | null;
    length?: number | null;
    group?: SequenceMessageGroup | null;
  } | null> | null;
  nucWarnings?: Array<{
    __typename?: 'NucleotideErrorDTO';
    index?: number | null;
    message?: string | null;
    length?: number | null;
  } | null> | null;
};

export type SequenceDtoPlateFragmentFragment = {
  __typename?: 'SequenceDTO';
  id?: string | null;
  well?: string | null;
  wellIndex?: number | null;
  name?: string | null;
  length?: number | null;
  validationLength?: number | null;
  modsCount?: number | null;
  prefixNucChunks?: Array<Nucleo | null> | null;
  prefixDataChunks?: Array<string | null> | null;
  nucChunks?: Array<Nucleo | null> | null;
  dataChunks?: Array<string | null> | null;
  status?: SequenceStatus | null;
  data?: string | null;
  score?: SequenceScore | null;
  errorScoreValue?: number | null;
  errors?: Array<{
    __typename?: 'SequenceMessageDTO';
    message?: string | null;
    group?: SequenceMessageGroup | null;
  } | null> | null;
  warnings?: Array<{
    __typename?: 'SequenceMessageDTO';
    message?: string | null;
    group?: SequenceMessageGroup | null;
  } | null> | null;
  nucErrors?: Array<{
    __typename?: 'NucleotideErrorDTO';
    index?: number | null;
    message?: string | null;
    length?: number | null;
    group?: SequenceMessageGroup | null;
  } | null> | null;
  nucWarnings?: Array<{
    __typename?: 'NucleotideErrorDTO';
    index?: number | null;
    message?: string | null;
    length?: number | null;
  } | null> | null;
};

export type TemplateMessageDtoFragmentFragment = {
  __typename?: 'TemplateMessageDTO';
  messageGroup?: TemplateMessageGroup | null;
  message?: string | null;
};

export type TemplateDetailsDtoFragmentFragment = {
  __typename?: 'TemplateDetailsDTO';
  id: string;
  name?: string | null;
  status?: TemplateStatus | null;
  format?: ContentFormat | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  countOfRuns?: number | null;
  countOfMods?: number | null;
  countOfClicks?: number | null;
  countOfSequences?: number | null;
  shortestSequence?: number | null;
  longestSequence?: number | null;
  averageSequence?: number | null;
  plateType?: PlateType | null;
  parentIDNAId?: string | null;
  parentIDNAName?: string | null;
  kitType?: KitType | null;
  exportMetaLink?: string | null;
  normalizationType?: NormalizationType | null;
  targetConcentration?: number | null;
  projectType?: ProjectType | null;
  creator?: { __typename: 'UserInProjectDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
  errors?: Array<{
    __typename?: 'TemplateMessageDTO';
    messageGroup?: TemplateMessageGroup | null;
    message?: string | null;
  } | null> | null;
  warnings?: Array<{
    __typename?: 'TemplateMessageDTO';
    messageGroup?: TemplateMessageGroup | null;
    message?: string | null;
  } | null> | null;
  prefixes?: Array<{
    __typename?: 'SequenceDTO';
    wellIndex?: number | null;
    data?: string | null;
    nucChunks?: Array<Nucleo | null> | null;
  } | null> | null;
  sequences?: Array<{
    __typename?: 'SequenceDTO';
    id?: string | null;
    well?: string | null;
    wellIndex?: number | null;
    name?: string | null;
    length?: number | null;
    modsCount?: number | null;
    prefixNucChunks?: Array<Nucleo | null> | null;
    prefixDataChunks?: Array<string | null> | null;
    nucChunks?: Array<Nucleo | null> | null;
    dataChunks?: Array<string | null> | null;
    status?: SequenceStatus | null;
    targetConcetration?: number | null;
    normalizationType?: NormalizationType | null;
    usePlateNorm?: boolean | null;
    data?: string | null;
    score?: SequenceScore | null;
    errorScoreValue?: number | null;
    errors?: Array<{
      __typename?: 'SequenceMessageDTO';
      message?: string | null;
      group?: SequenceMessageGroup | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'SequenceMessageDTO';
      message?: string | null;
      group?: SequenceMessageGroup | null;
    } | null> | null;
    nucErrors?: Array<{
      __typename?: 'NucleotideErrorDTO';
      index?: number | null;
      message?: string | null;
      length?: number | null;
      group?: SequenceMessageGroup | null;
    } | null> | null;
    nucWarnings?: Array<{
      __typename?: 'NucleotideErrorDTO';
      index?: number | null;
      message?: string | null;
      length?: number | null;
    } | null> | null;
  } | null> | null;
  emptySequences?: Array<{
    __typename?: 'SequenceDTO';
    id?: string | null;
    well?: string | null;
    wellIndex?: number | null;
    name?: string | null;
    length?: number | null;
    modsCount?: number | null;
    prefixNucChunks?: Array<Nucleo | null> | null;
    prefixDataChunks?: Array<string | null> | null;
    nucChunks?: Array<Nucleo | null> | null;
    dataChunks?: Array<string | null> | null;
    status?: SequenceStatus | null;
    targetConcetration?: number | null;
    normalizationType?: NormalizationType | null;
    usePlateNorm?: boolean | null;
    data?: string | null;
    score?: SequenceScore | null;
    errorScoreValue?: number | null;
    errors?: Array<{
      __typename?: 'SequenceMessageDTO';
      message?: string | null;
      group?: SequenceMessageGroup | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'SequenceMessageDTO';
      message?: string | null;
      group?: SequenceMessageGroup | null;
    } | null> | null;
    nucErrors?: Array<{
      __typename?: 'NucleotideErrorDTO';
      index?: number | null;
      message?: string | null;
      length?: number | null;
      group?: SequenceMessageGroup | null;
    } | null> | null;
    nucWarnings?: Array<{
      __typename?: 'NucleotideErrorDTO';
      index?: number | null;
      message?: string | null;
      length?: number | null;
    } | null> | null;
  } | null> | null;
};

export type TemplateByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TemplateByIdQuery = {
  __typename?: 'Query';
  getTemplateDetails?: {
    __typename?: 'TemplateDetailsDTO';
    id: string;
    name?: string | null;
    status?: TemplateStatus | null;
    format?: ContentFormat | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    countOfRuns?: number | null;
    countOfMods?: number | null;
    countOfClicks?: number | null;
    countOfSequences?: number | null;
    shortestSequence?: number | null;
    longestSequence?: number | null;
    averageSequence?: number | null;
    plateType?: PlateType | null;
    parentIDNAId?: string | null;
    parentIDNAName?: string | null;
    kitType?: KitType | null;
    exportMetaLink?: string | null;
    normalizationType?: NormalizationType | null;
    targetConcentration?: number | null;
    projectType?: ProjectType | null;
    creator?: {
      __typename: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    prefixes?: Array<{
      __typename?: 'SequenceDTO';
      wellIndex?: number | null;
      data?: string | null;
      nucChunks?: Array<Nucleo | null> | null;
    } | null> | null;
    sequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
    emptySequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type TemplatePerUserPageDtoFragmentFragment = {
  __typename?: 'TemplatePerUserDTO';
  id: string;
  name?: string | null;
  format?: ContentFormat | null;
  status?: TemplateStatus | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  plateType?: PlateType | null;
  partNumber?: string | null;
  kitType?: KitType | null;
  type?: ContentType | null;
  countOfRuns?: number | null;
  countOfMods?: number | null;
  countOfClicks?: number | null;
  countOfSequences?: number | null;
  shortestSequence?: number | null;
  longestSequence?: number | null;
  parentIDNAId?: string | null;
  parentIDNAName?: string | null;
  exportMetaLink?: string | null;
  project?: {
    __typename?: 'ProjectByIdDTO';
    id?: string | null;
    name?: string | null;
    projectType?: ProjectType | null;
  } | null;
  creator?: { __typename?: 'UserInProjectDTO'; id: string; firstName?: string | null; lastName?: string | null } | null;
};

export type QueryMyTemplatesQueryVariables = Exact<{
  filter: TemplateFilterDto;
}>;

export type QueryMyTemplatesQuery = {
  __typename?: 'Query';
  getTemplatesPerUser?: {
    __typename?: 'TemplatePerUserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'TemplatePerUserDTO';
      id: string;
      name?: string | null;
      format?: ContentFormat | null;
      status?: TemplateStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      plateType?: PlateType | null;
      partNumber?: string | null;
      kitType?: KitType | null;
      type?: ContentType | null;
      countOfRuns?: number | null;
      countOfMods?: number | null;
      countOfClicks?: number | null;
      countOfSequences?: number | null;
      shortestSequence?: number | null;
      longestSequence?: number | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      exportMetaLink?: string | null;
      project?: {
        __typename?: 'ProjectByIdDTO';
        id?: string | null;
        name?: string | null;
        projectType?: ProjectType | null;
      } | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type QueryAllTemplatesQueryVariables = Exact<{
  filter: TemplateFilterDto;
}>;

export type QueryAllTemplatesQuery = {
  __typename?: 'Query';
  getTemplatesAvailableForCurrentUser?: {
    __typename?: 'TemplatePerUserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'TemplatePerUserDTO';
      id: string;
      name?: string | null;
      format?: ContentFormat | null;
      status?: TemplateStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      plateType?: PlateType | null;
      partNumber?: string | null;
      kitType?: KitType | null;
      type?: ContentType | null;
      countOfRuns?: number | null;
      countOfMods?: number | null;
      countOfClicks?: number | null;
      countOfSequences?: number | null;
      shortestSequence?: number | null;
      longestSequence?: number | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      exportMetaLink?: string | null;
      project?: {
        __typename?: 'ProjectByIdDTO';
        id?: string | null;
        name?: string | null;
        projectType?: ProjectType | null;
      } | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type QueryArchivedTemplatesQueryVariables = Exact<{
  filter: TemplateFilterDto;
}>;

export type QueryArchivedTemplatesQuery = {
  __typename?: 'Query';
  getTemplatesArchived?: {
    __typename?: 'TemplatePerUserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'TemplatePerUserDTO';
      id: string;
      name?: string | null;
      format?: ContentFormat | null;
      status?: TemplateStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      plateType?: PlateType | null;
      partNumber?: string | null;
      kitType?: KitType | null;
      type?: ContentType | null;
      countOfRuns?: number | null;
      countOfMods?: number | null;
      countOfClicks?: number | null;
      countOfSequences?: number | null;
      shortestSequence?: number | null;
      longestSequence?: number | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      exportMetaLink?: string | null;
      project?: {
        __typename?: 'ProjectByIdDTO';
        id?: string | null;
        name?: string | null;
        projectType?: ProjectType | null;
      } | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPlateConfigsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPlateConfigsQuery = {
  __typename?: 'Query';
  getPlateConfigs?: {
    __typename?: 'PlateConfigDTO';
    maxWarningMers?: number | null;
    maxErrorMers?: number | null;
  } | null;
};

export type PlatesPerUserQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type PlatesPerUserQuery = {
  __typename?: 'Query';
  getIDNAPlates?: {
    __typename?: 'TemplatePerUserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'TemplatePerUserDTO';
      id: string;
      name?: string | null;
      status?: TemplateStatus | null;
      format?: ContentFormat | null;
      type?: ContentType | null;
      kitType?: KitType | null;
      plateType?: PlateType | null;
      partNumber?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      countOfRuns?: number | null;
      countOfMods?: number | null;
      countOfSequences?: number | null;
      shortestSequence?: number | null;
      longestSequence?: number | null;
      averageSequence?: number | null;
      exportMetaLink?: string | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      project?: {
        __typename?: 'ProjectByIdDTO';
        id?: string | null;
        name?: string | null;
        description?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ArchivedPlatesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ArchivedPlatesQuery = {
  __typename?: 'Query';
  getIDNAPlatesArchived?: {
    __typename?: 'TemplatePerUserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'TemplatePerUserDTO';
      id: string;
      name?: string | null;
      status?: TemplateStatus | null;
      format?: ContentFormat | null;
      type?: ContentType | null;
      kitType?: KitType | null;
      plateType?: PlateType | null;
      partNumber?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      countOfRuns?: number | null;
      countOfMods?: number | null;
      countOfSequences?: number | null;
      shortestSequence?: number | null;
      longestSequence?: number | null;
      averageSequence?: number | null;
      exportMetaLink?: string | null;
      parentIDNAId?: string | null;
      parentIDNAName?: string | null;
      creator?: {
        __typename?: 'UserInProjectDTO';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      project?: {
        __typename?: 'ProjectByIdDTO';
        id?: string | null;
        name?: string | null;
        description?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PlatesForDropdownQueryVariables = Exact<{
  kitType?: InputMaybe<KitType>;
}>;

export type PlatesForDropdownQuery = {
  __typename?: 'Query';
  getIDNAPlates?: {
    __typename?: 'TemplatePerUserPageDTO';
    data?: Array<{
      __typename?: 'TemplatePerUserDTO';
      id: string;
      name?: string | null;
      plateType?: PlateType | null;
      kitType?: KitType | null;
    } | null> | null;
  } | null;
};

export type PlateByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  shouldIncludeProject: Scalars['Boolean']['input'];
}>;

export type PlateByIdQuery = {
  __typename?: 'Query';
  getIDNAPlateDetails?: {
    __typename?: 'TemplateDetailsDTO';
    id: string;
    name?: string | null;
    status?: TemplateStatus | null;
    format?: ContentFormat | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    countOfRuns?: number | null;
    countOfMods?: number | null;
    countOfClicks?: number | null;
    countOfSequences?: number | null;
    shortestSequence?: number | null;
    longestSequence?: number | null;
    averageSequence?: number | null;
    plateType?: PlateType | null;
    parentIDNAId?: string | null;
    parentIDNAName?: string | null;
    kitType?: KitType | null;
    exportMetaLink?: string | null;
    normalizationType?: NormalizationType | null;
    targetConcentration?: number | null;
    projectType?: ProjectType | null;
    creator?: {
      __typename: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    prefixes?: Array<{
      __typename?: 'SequenceDTO';
      wellIndex?: number | null;
      data?: string | null;
      nucChunks?: Array<Nucleo | null> | null;
    } | null> | null;
    sequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
    emptySequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
  getProjectDetailsById?: { __typename?: 'ProjectDetailDTO'; name?: string | null } | null;
};

export type PlateStatusByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PlateStatusByIdQuery = {
  __typename?: 'Query';
  getIDNAPlateDetails?: { __typename?: 'TemplateDetailsDTO'; id: string; status?: TemplateStatus | null } | null;
};

export type ValidateIdnaTemplateQueryVariables = Exact<{
  update: TemplateUpdateDto;
}>;

export type ValidateIdnaTemplateQuery = {
  __typename?: 'Query';
  validateIDNATemplateUpdate?: {
    __typename?: 'TemplateDetailsDTO';
    id: string;
    name?: string | null;
    status?: TemplateStatus | null;
    format?: ContentFormat | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    countOfRuns?: number | null;
    countOfMods?: number | null;
    countOfClicks?: number | null;
    countOfSequences?: number | null;
    shortestSequence?: number | null;
    longestSequence?: number | null;
    averageSequence?: number | null;
    plateType?: PlateType | null;
    parentIDNAId?: string | null;
    parentIDNAName?: string | null;
    kitType?: KitType | null;
    exportMetaLink?: string | null;
    normalizationType?: NormalizationType | null;
    targetConcentration?: number | null;
    projectType?: ProjectType | null;
    creator?: {
      __typename: 'UserInProjectDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    warnings?: Array<{
      __typename?: 'TemplateMessageDTO';
      messageGroup?: TemplateMessageGroup | null;
      message?: string | null;
    } | null> | null;
    prefixes?: Array<{
      __typename?: 'SequenceDTO';
      wellIndex?: number | null;
      data?: string | null;
      nucChunks?: Array<Nucleo | null> | null;
    } | null> | null;
    sequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
    emptySequences?: Array<{
      __typename?: 'SequenceDTO';
      id?: string | null;
      well?: string | null;
      wellIndex?: number | null;
      name?: string | null;
      length?: number | null;
      modsCount?: number | null;
      prefixNucChunks?: Array<Nucleo | null> | null;
      prefixDataChunks?: Array<string | null> | null;
      nucChunks?: Array<Nucleo | null> | null;
      dataChunks?: Array<string | null> | null;
      status?: SequenceStatus | null;
      targetConcetration?: number | null;
      normalizationType?: NormalizationType | null;
      usePlateNorm?: boolean | null;
      data?: string | null;
      score?: SequenceScore | null;
      errorScoreValue?: number | null;
      errors?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      warnings?: Array<{
        __typename?: 'SequenceMessageDTO';
        message?: string | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucErrors?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
        group?: SequenceMessageGroup | null;
      } | null> | null;
      nucWarnings?: Array<{
        __typename?: 'NucleotideErrorDTO';
        index?: number | null;
        message?: string | null;
        length?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetAllSequenceConcentrationLimitsByProjectTypeQueryVariables = Exact<{
  projectType: ProjectType;
}>;

export type GetAllSequenceConcentrationLimitsByProjectTypeQuery = {
  __typename?: 'Query';
  getAllSequenceConcentrationLimitsByProjectType: Array<{
    __typename?: 'ConcentrationLimitsDTO';
    min: any;
    max: any;
    defaultValue: any;
    kitType?: KitType | null;
  }>;
};

export type GetActiveModsAndClicksQueryVariables = Exact<{
  kitType: KitType;
}>;

export type GetActiveModsAndClicksQuery = {
  __typename?: 'Query';
  getActiveModsAndClicks?: {
    __typename?: 'ActiveModsAndClicksDTO';
    modsAndClicks?: Array<string | null> | null;
    validClickPairs?: Array<{
      __typename?: 'DoubleClickPair';
      fluorophore?: string | null;
      quencher?: string | null;
    } | null> | null;
  } | null;
};

export type GetSupportedKitTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSupportedKitTypesQuery = {
  __typename?: 'Query';
  getSupportedKitTypes?: Array<{
    __typename?: 'KitTypeKeyValueDTO';
    plateType: PlateType;
    kitInfo?: Array<{
      __typename?: 'KitInfoDTO';
      kitType: KitType;
      concentrationProperties: {
        __typename?: 'ConcentrationLimitsDTO';
        defaultValue: any;
        kitType?: KitType | null;
        max: any;
        min: any;
      };
      plateConfig: {
        __typename?: 'KitPlateConfig';
        maxCyclesPerTemplate: number;
        maxErrorMers: number;
        maxIdnaMers?: number | null;
        maxModsPerSequence?: number | null;
        maxModsPerTemplate?: number | null;
        maxWarningMers: number;
        minErrorMers: number;
        minIdnaMers?: number | null;
        minWarningMers: number;
      };
    } | null> | null;
  } | null> | null;
};

export type GetSupportedIdnaKitTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSupportedIdnaKitTypesQuery = {
  __typename?: 'Query';
  getSupportedIDNAKitTypes?: Array<{
    __typename?: 'KitTypeKeyValueDTO';
    plateType: PlateType;
    kitInfo?: Array<{
      __typename?: 'KitInfoDTO';
      kitType: KitType;
      concentrationProperties: {
        __typename?: 'ConcentrationLimitsDTO';
        defaultValue: any;
        kitType?: KitType | null;
        max: any;
        min: any;
      };
      plateConfig: {
        __typename?: 'KitPlateConfig';
        maxCyclesPerTemplate: number;
        maxErrorMers: number;
        maxIdnaMers?: number | null;
        maxModsPerSequence?: number | null;
        maxModsPerTemplate?: number | null;
        maxWarningMers: number;
        minErrorMers: number;
        minIdnaMers?: number | null;
        minWarningMers: number;
      };
    } | null> | null;
  } | null> | null;
};

export type GetAllKitTypesInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllKitTypesInfoQuery = {
  __typename?: 'Query';
  getAllKitTypesInfo?: Array<{ __typename?: 'KitInfoDTO'; kitType: KitType; kitLabel?: string | null } | null> | null;
};

export type GetCentrifugationProcessesQueryVariables = Exact<{
  kitType: KitType;
}>;

export type GetCentrifugationProcessesQuery = {
  __typename?: 'Query';
  getCentrifugationProcesses: Array<CentrifugationType>;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'UserDetailedDTO';
    id: string;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    status?: string | null;
    title?: string | null;
    workPhone?: string | null;
    cellPhone?: string | null;
    organizationName?: string | null;
    tooltipOnSeqPageIsClosed?: boolean | null;
    changePasswordAfterFirstLogin?: boolean | null;
    validLicense?: boolean | null;
    cloud?: boolean | null;
    role?: { __typename?: 'RoleDTO'; name?: string | null; description?: string | null; type?: RoleType | null } | null;
    address?: {
      __typename?: 'Address';
      country?: string | null;
      state?: string | null;
      city?: string | null;
      street1?: string | null;
      street2?: string | null;
      zip?: string | null;
    } | null;
    timeZone?: { __typename?: 'TimeZoneDTO'; id: string; label: string; offset: number } | null;
  } | null;
};

export type UserByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserByIdQuery = {
  __typename?: 'Query';
  getUserById?: {
    __typename?: 'UserDetailedDTO';
    cellPhone?: string | null;
    countOfProjects?: number | null;
    createdAt?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    organizationId?: string | null;
    status?: string | null;
    title?: string | null;
    updatedAt?: string | null;
    username?: string | null;
    workPhone?: string | null;
    address?: {
      __typename?: 'Address';
      country?: string | null;
      state?: string | null;
      city?: string | null;
      street1?: string | null;
      street2?: string | null;
      zip?: string | null;
    } | null;
    groups?: Array<{
      __typename?: 'GroupModel';
      id: string;
      name: string;
      description?: string | null;
      status?: string | null;
      creatorFirstName?: string | null;
      creatorLastName?: string | null;
      totalGroupMembers?: number | null;
      members?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    role?: {
      __typename?: 'RoleDTO';
      id: string;
      name?: string | null;
      description?: string | null;
      type?: RoleType | null;
    } | null;
    timeZone?: { __typename?: 'TimeZoneDTO'; id: string; label: string; offset: number } | null;
  } | null;
};

export type UsersListQueryVariables = Exact<{
  searchText: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  orderField: Scalars['String']['input'];
  orderDirection: Direction;
  statuses?: InputMaybe<Array<InputMaybe<UserStatus>> | InputMaybe<UserStatus>>;
}>;

export type UsersListQuery = {
  __typename?: 'Query';
  getUsers?: {
    __typename?: 'UserPageDTO';
    totalElementsCount?: number | null;
    data?: Array<{
      __typename?: 'UserListViewDTO';
      id: string;
      username?: string | null;
      title?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      status?: string | null;
      groups?: string | null;
      updatedAt?: string | null;
      createdAt?: string | null;
      role?: { __typename?: 'RoleDTO'; id: string; type?: RoleType | null; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type UsersShortListQueryVariables = Exact<{ [key: string]: never }>;

export type UsersShortListQuery = {
  __typename?: 'Query';
  getUsers?: {
    __typename?: 'UserPageDTO';
    data?: Array<{
      __typename?: 'UserListViewDTO';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      role?: { __typename?: 'RoleDTO'; id: string; name?: string | null; type?: RoleType | null } | null;
    } | null> | null;
  } | null;
};

export type AdminsListQueryVariables = Exact<{ [key: string]: never }>;

export type AdminsListQuery = {
  __typename?: 'Query';
  getListOfAdminsForOrganization?: Array<{
    __typename?: 'UserInGroupDTO';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null> | null;
};

export type GetRemoteUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetRemoteUsersQuery = {
  __typename?: 'Query';
  getRemoteUsers?: Array<{
    __typename?: 'OrganizationRemoteUserDTO';
    id: string;
    status?: RemoteUserStatus | null;
    expirationDate?: string | null;
    createdAt?: string | null;
    user?: {
      __typename?: 'UserDTO';
      id: string;
      username?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    organization?: { __typename?: 'OrganizationDTO'; id: string; name?: string | null } | null;
    role?: {
      __typename?: 'RoleDTO';
      id: string;
      name?: string | null;
      description?: string | null;
      type?: RoleType | null;
      authority?: string | null;
    } | null;
  } | null> | null;
};

export const RecentWorkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentWork' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecentWorkItemDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecentRunWorkItemDTO' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sheetUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reportUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'runStatus' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecentTemplateWorkItemDTO' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'templateStatus' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecentWorkFragment, unknown>;
export const RemoteOrganizationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RemoteOrganizationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationRemoteUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authority' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteOrganizationFragmentFragment, unknown>;
export const ProjectItemInTableDtoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectItemInTableDTO' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admin' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'completedRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfDraftTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfErrorTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfReadyTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfWarningTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfOligosMore200' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'runsInQueue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectItemInTableDtoFragment, unknown>;
export const RunsDataFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RunsDataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RunPerUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfTotalSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedInstrument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customProcess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RunsDataFieldsFragment, unknown>;
export const ServiceInfoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceInfoDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'server' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ServiceInfoFieldsFragment, unknown>;
export const SequenceDtoPlateFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOPlateFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SequenceDtoPlateFragmentFragment, unknown>;
export const TemplateMessageDtoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateMessageDtoFragmentFragment, unknown>;
export const SequenceDtoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usePlateNorm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SequenceDtoFragmentFragment, unknown>;
export const TemplateDetailsDtoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateDetailsDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefixes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emptySequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcentration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usePlateNorm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateDetailsDtoFragmentFragment, unknown>;
export const TemplatePerUserPageDtoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplatePerUserPageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplatePerUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplatePerUserPageDtoFragmentFragment, unknown>;
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rememberMe' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginAs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'login' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rememberMe' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'rememberMe' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Logout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const ForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const EnableUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnableUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnableUserMutation, EnableUserMutationVariables>;
export const DeleteSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSessionsMutation, DeleteSessionsMutationVariables>;
export const SetPasswordForInvitedUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetPasswordForInvitedUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPasswordForInvitedUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetPasswordForInvitedUserMutation, SetPasswordForInvitedUserMutationVariables>;
export const AuthConfirmEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthConfirmEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthConfirmEmailMutation, AuthConfirmEmailMutationVariables>;
export const CustomProcessCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomProcessCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomProcessCreateDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomProcess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'create' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomProcessCreateMutation, CustomProcessCreateMutationVariables>;
export const CustomProcessDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomProcessDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomProcess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomProcessDeleteMutation, CustomProcessDeleteMutationVariables>;
export const DeleteGroupByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteGroupById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deprecateGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteGroupByIdMutation, DeleteGroupByIdMutationVariables>;
export const RestoreGroupByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreGroupById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreGroupByIdMutation, RestoreGroupByIdMutationVariables>;
export const CreateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creatorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Status' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creatorId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'creatorId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const AddUserToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserToGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUserToGroupMutation, AddUserToGroupMutationVariables>;
export const RemoveUserFromGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveUserFromGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const InstrumentCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstrumentCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InstrumentVersion' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'installationDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceReminder' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'members' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'printJobVersion' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PrintJobVersion' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'modelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'serialNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'systemVersion' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'systemVersion' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'installationDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'installationDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'serviceReminder' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceReminder' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'restrictedAccess' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'admin' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'members' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'members' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'printJobVersion' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'printJobVersion' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentCreateMutation, InstrumentCreateMutationVariables>;
export const InstrumentUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstrumentUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemVersion' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InstrumentVersion' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'installationDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceReminder' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'members' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'modelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'serialNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'systemVersion' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'systemVersion' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'installationDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'installationDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'serviceReminder' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceReminder' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'restrictedAccess' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'admin' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'members' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'members' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentUpdateMutation, InstrumentUpdateMutationVariables>;
export const InstrumentDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstrumentDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'applyDeleteInQueueJobs' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applyDeleteInQueueJobs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'applyDeleteInQueueJobs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentDeleteMutation, InstrumentDeleteMutationVariables>;
export const SynchronizeInstrumentKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SynchronizeInstrumentKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'synchronizeInstrumentKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SynchronizeInstrumentKeyMutation, SynchronizeInstrumentKeyMutationVariables>;
export const RequestInstrumentLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestInstrumentLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestInstrumentLog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fromDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'toDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestInstrumentLogMutation, RequestInstrumentLogMutationVariables>;
export const DynamicSecurityUserManagementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DynamicSecurityUserManagement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputData' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DynamicSecurityRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dynamicSecurityUserManagement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputData' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'password' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DynamicSecurityUserManagementMutation, DynamicSecurityUserManagementMutationVariables>;
export const UpdateOrganizationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrganizationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationUpdateDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationDataMutation, UpdateOrganizationDataMutationVariables>;
export const UpdateOrganizationLicenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrganizationLicense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'license' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationLicense' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'license' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'license' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validTill' } },
                { kind: 'Field', name: { kind: 'Name', value: 'installationName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationLicenseMutation, UpdateOrganizationLicenseMutationVariables>;
export const SwitchToRemoteOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchToRemoteOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationRemoteUserId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchToRemoteOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationRemoteUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationRemoteUserId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchToRemoteOrganizationMutation, SwitchToRemoteOrganizationMutationVariables>;
export const SwitchToOriginalOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchToOriginalOrganization' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchToOriginalOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchToOriginalOrganizationMutation, SwitchToOriginalOrganizationMutationVariables>;
export const ProjectCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProjectCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'budget' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'budget' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'budget' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'currency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owner' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'admin' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'users' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'restrictedAccess' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectCreateMutation, ProjectCreateMutationVariables>;
export const ProjectUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProjectUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'budget' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityByIdDTO' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'budget' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'budget' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'currency' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owner' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'admin' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'users' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'users' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'restrictedAccess' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'restrictedAccess' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectUpdateMutation, ProjectUpdateMutationVariables>;
export const ProjectTemplateCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProjectTemplateCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentPlateId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plateType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlateType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'KitType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runPurityEstimate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentPlateId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'parentPlateId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'plateType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'plateType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kitType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'runPurityEstimate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'runPurityEstimate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warnings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectTemplateCreateMutation, ProjectTemplateCreateMutationVariables>;
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const RestoreProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreProjectMutation, RestoreProjectMutationVariables>;
export const CreateRunSchedulerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRunScheduler' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customProcessId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'centrifugationType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CentrifugationType' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRunScheduler' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'centrifugationType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'centrifugationType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'templateId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'customProcessId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'customProcessId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instrument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metalink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'centrifugationType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clicksCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sequenceCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'durationInSeconds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimatedReagents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bottles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'volumeMl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idnaTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRunSchedulerMutation, CreateRunSchedulerMutationVariables>;
export const CreateGeneRunSchedulerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGeneRunScheduler' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGeneRunScheduler' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instrument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sheetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metalink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sequenceCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'durationInSeconds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGeneRunSchedulerMutation, CreateGeneRunSchedulerMutationVariables>;
export const UploadRunReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadRunReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadRunReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'serialNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadRunReportMutation, UploadRunReportMutationVariables>;
export const DeleteRunDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRun' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRun' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'runId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRunMutation, DeleteRunMutationVariables>;
export const ReprintFailedWellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReprintFailedWells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reprintFailedWells' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'version' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReprintFailedWellsMutation, ReprintFailedWellsMutationVariables>;
export const UpdateConcentrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateConcentration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wellInfo' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'WellInfoDTO' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateConcentration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'runId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wellInfo' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'wellInfo' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateConcentrationMutation, UpdateConcentrationMutationVariables>;
export const UpdatePqaRunInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePqaRunInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'conditionName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plateTitle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePqaRunInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'runId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'conditionName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'conditionName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'plateTitle' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'plateTitle' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePqaRunInfoMutation, UpdatePqaRunInfoMutationVariables>;
export const PlateCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PlateCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plateType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlateType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'KitType' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIDNAPlate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'plateType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'plateType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kitType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warnings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlateCreateMutation, PlateCreateMutationVariables>;
export const PlateUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PlateUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputData' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateUpdateDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIDNATemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputData' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usePlateNorm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateDetailsDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefixes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emptySequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcentration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlateUpdateMutation, PlateUpdateMutationVariables>;
export const UpdateTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sequences' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceInfoDTO' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'normalizationType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NormalizationType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetConcentration' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runPurityEstimate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentPlateId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'update' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'templateId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sequences' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sequences' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'normalizationType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'normalizationType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'targetConcentration' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'targetConcentration' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'runPurityEstimate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'runPurityEstimate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentPlateId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'parentPlateId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warnings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prefixes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sequences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usePlateNorm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const CopyTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyIDNATemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'templateId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CopyTemplateMutation, CopyTemplateMutationVariables>;
export const DeleteTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const DeleteIdnaTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteIdnaTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIDNATemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIdnaTemplateMutation, DeleteIdnaTemplateMutationVariables>;
export const RestoreTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreTemplateMutation, RestoreTemplateMutationVariables>;
export const RestoreIdnaTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreIDNATemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreIDNATemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreIdnaTemplateMutation, RestoreIdnaTemplateMutationVariables>;
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangePasswordForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePasswordForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePasswordForUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>;
export const UserUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workPhone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cellPhone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInputDTO' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timeZoneId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workPhone' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workPhone' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cellPhone' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cellPhone' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groupIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timeZoneId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'timeZoneId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creatorFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creatorLastName' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updatedAt' },
                        name: { kind: 'Name', value: 'lastUpdatedDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalGroupMembers' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfProjects' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserProfileUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserProfileUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workPhone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cellPhone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInputDTO' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timeZoneId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workPhone' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workPhone' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cellPhone' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cellPhone' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timeZoneId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'timeZoneId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creatorFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creatorLastName' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updatedAt' },
                        name: { kind: 'Name', value: 'lastUpdatedDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalGroupMembers' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfProjects' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>;
export const DeleteUserByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>;
export const UserInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groupIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserInviteMutation, UserInviteMutationVariables>;
export const UserCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groupIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserCreateMutation, UserCreateMutationVariables>;
export const UserInviteResendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserInviteResend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendInvitationEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserInviteResendMutation, UserInviteResendMutationVariables>;
export const UserReactivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserReactivate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactivateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserReactivateMutation, UserReactivateMutationVariables>;
export const UpdateUserPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tooltipOnSeqPageIsClosed' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserPreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tooltipOnSeqPageIsClosed' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tooltipOnSeqPageIsClosed' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;
export const InviteRemoteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteRemoteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expirationDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteRemoteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expirationDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'expirationDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteRemoteUserMutation, InviteRemoteUserMutationVariables>;
export const UpdateRemoteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRemoteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expirationDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRemoteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expirationDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'expirationDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRemoteUserMutation, UpdateRemoteUserMutationVariables>;
export const DeactivateRemoteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeactivateRemoteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationRemoteUserId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deactivateRemoteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationRemoteUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationRemoteUserId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeactivateRemoteUserMutation, DeactivateRemoteUserMutationVariables>;
export const ValidateVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidateVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'verificationCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidateVerificationCodeQuery, ValidateVerificationCodeQueryVariables>;
export const AllCustomProcessesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllCustomProcesses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllCustomProcesses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllCustomProcessesQuery, AllCustomProcessesQueryVariables>;
export const IsValidCustomProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsValidCustomProcess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'process' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isValidCustomProcess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'process' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'process' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsValidCustomProcessQuery, IsValidCustomProcessQueryVariables>;
export const QueryGetDashboardDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryGetDashboardData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDefaultProjectForUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryGetDashboardDataQuery, QueryGetDashboardDataQueryVariables>;
export const QueryGetRecentWorkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryGetRecentWork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recentWork' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecentWorkFilterDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRecentWork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recentWork' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecentWork' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecentWork' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecentWorkItemDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecentRunWorkItemDTO' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sheetUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reportUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'runStatus' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecentTemplateWorkItemDTO' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'templateStatus' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryGetRecentWorkQuery, QueryGetRecentWorkQueryVariables>;
export const QueryGetNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryGetNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMyNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageInfo' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '99' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryGetNotificationsQuery, QueryGetNotificationsQueryVariables>;
export const QueryNotificationRunDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryNotificationRunDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunReportDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'runId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instrument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryNotificationRunDetailsQuery, QueryNotificationRunDetailsQueryVariables>;
export const RunLightInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RunLightInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunLightInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'runId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RunLightInfoQuery, RunLightInfoQueryVariables>;
export const GroupsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderField' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderDirection' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Direction' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchText' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orderField' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'direction' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orderDirection' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updatedAt' },
                        name: { kind: 'Name', value: 'lastUpdatedDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupsListQuery, GroupsListQueryVariables>;
export const GroupByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGroupById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'updatedAt' },
                  name: { kind: 'Name', value: 'lastUpdatedDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authority' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateAdded' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupByIdQuery, GroupByIdQueryVariables>;
export const InstallationFilesPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstallationFilesPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderField' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderDirection' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Direction' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInstallationFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orderField' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'direction' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orderDirection' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'releaseVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'releaseDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstallationFilesPageQuery, InstallationFilesPageQueryVariables>;
export const InstrumentsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstrumentsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortField' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Direction' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'sortField' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'direction' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'model' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'supportedSoftware' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'installationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serviceDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentsListQuery, InstrumentsListQueryVariables>;
export const InstrumentsDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstrumentsDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInstrumentDetailsById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'installationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mqttPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serviceDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serviceReminder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'softwareVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'versionsUpdateMode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportedSoftware' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'permission' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentsDetailsQuery, InstrumentsDetailsQueryVariables>;
export const InstrumentStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstrumentStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInstrumentStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reagents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reagentUsageUnion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'volumePercentageLeft' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isUsable' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cycles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cyclesCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentStatusQuery, InstrumentStatusQueryVariables>;
export const InstrumentQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstrumentQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInstrumentsInQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentQueueQuery, InstrumentQueueQueryVariables>;
export const InstrumentHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstrumentHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunHistoryPerInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentFilterDTO' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'completedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfTotalSequences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customProcess' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentHistoryQuery, InstrumentHistoryQueryVariables>;
export const InstrumentsForPrintDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstrumentsForPrint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSuitableInstrumentsForPrint' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentsForPrintQuery, InstrumentsForPrintQueryVariables>;
export const ValidateInstrumentKitTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidateInstrumentKitType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateInstrumentKitType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'templateId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidateInstrumentKitTypeQuery, ValidateInstrumentKitTypeQueryVariables>;
export const InstrumentsModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstrumentsModels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInstrumentModels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportedSoftware' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstrumentsModelsQuery, InstrumentsModelsQueryVariables>;
export const GetInstrumentLogByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInstrumentLogById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInstrumentLogById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInstrumentLogByIdQuery, GetInstrumentLogByIdQueryVariables>;
export const ActiveJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActiveJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getNowRunsPerOrganizationByUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedInstrument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActiveJobsQuery, ActiveJobsQueryVariables>;
export const GetOrganizationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrganizationData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aesKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'license' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'validTill' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'workNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mobileNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'maxLoginFailedAttempts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printingEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geneEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clicksEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degeneratesEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customProcessEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationDataQuery, GetOrganizationDataQueryVariables>;
export const GetLoginEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLoginEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LoginEventFilterDTO' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLoginEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLoginEventsQuery, GetLoginEventsQueryVariables>;
export const GetRemoteOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRemoteOrganizations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRemoteOrganizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authority' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRemoteOrganizationsQuery, GetRemoteOrganizationsQueryVariables>;
export const ProjectsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Status' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectItemInTableDTO' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectItemInTableDTO' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admin' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'completedRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfDraftTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfErrorTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfReadyTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfWarningTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfOligosMore200' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'runsInQueue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectsListQuery, ProjectsListQueryVariables>;
export const MyProjectsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyProjectsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Status' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMyProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectItemInTableDTO' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectItemInTableDTO' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admin' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'completedRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfDraftTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfErrorTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfReadyTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfWarningTemplates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfOligosMore200' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'runsInQueue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyProjectsListQuery, MyProjectsListQueryVariables>;
export const ProjectDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectDetailsById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedRuns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfDraftTemplates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfErrorTemplates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfTemplates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expense' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'runsInQueue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authority' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geneDetail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vector' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sequence' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'vectorPrimers' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailQuery, ProjectDetailQueryVariables>;
export const ProjectTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTemplatesPerProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>;
export const ProjectTemplatesIDsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectTemplatesIDs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTemplatesPerProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectTemplatesIDsQuery, ProjectTemplatesIDsQueryVariables>;
export const ProjectRunsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectRuns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunsPerProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfTotalSequences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedInstrument' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customProcess' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRunsQuery, ProjectRunsQueryVariables>;
export const GetDefaultProjectForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDefaultProjectForUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDefaultProjectForUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDefaultProjectForUserQuery, GetDefaultProjectForUserQueryVariables>;
export const GetUsersForNewProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUsersForNewProject' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUsersForNewProject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authority' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersForNewProjectQuery, GetUsersForNewProjectQueryVariables>;
export const ProjectRunsGroupsShortDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectRunsGroupsShort' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunGroupsPerProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfPlates' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'runs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countOfTotalSequences' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRunsGroupsShortQuery, ProjectRunsGroupsShortQueryVariables>;
export const RolesListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RolesList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RolesListQuery, RolesListQueryVariables>;
export const RunSchedulerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RunScheduler' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunSchedule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sheetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RunSchedulerQuery, RunSchedulerQueryVariables>;
export const JobDurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'JobDuration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getJobDuration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputData' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'templateId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instrumentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobDurationQuery, JobDurationQueryVariables>;
export const QueryRunsUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryRunsUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RunPerProjectFilterDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunsPerUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RunsDataFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RunsDataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RunPerUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfTotalSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedInstrument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customProcess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryRunsUserQuery, QueryRunsUserQueryVariables>;
export const QueryRunsOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryRunsOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RunPerProjectFilterDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunsPerOrganizationByUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RunsDataFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RunsDataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RunPerUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfTotalSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedInstrument' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customProcess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryRunsOrganizationQuery, QueryRunsOrganizationQueryVariables>;
export const QueryRunDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryRunDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldIncludeProject' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunReportDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'runId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instrument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledEndTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sequences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'well' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clicksCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actualConcentration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'yield' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'purity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'prefixData' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'validationLength' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'crossElution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'wellsFrom' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'wellsTo' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedSequences' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reagentGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reagents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lotNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expireDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'yieldSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'volumeSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pqaInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'conditionName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plateTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectDetailsById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldIncludeProject' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryRunDetailsQuery, QueryRunDetailsQueryVariables>;
export const GetPqaStatisticDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPqaStatistic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPqaStatistic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'runId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'runId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'groupName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minSizePmol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxInvalidWellsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWellNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averageYieldPmol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minInGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxInGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'standardDeviationPmol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coefficientOfVariation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invalidWellsNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modsInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wellMods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'well' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sequenceName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mods' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'couplingPercent' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'conclusion' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modsConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'mod' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'couplingLevels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPqaStatisticQuery, GetPqaStatisticQueryVariables>;
export const ProjectRunsGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectRunsGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRunGroupsPerProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfPlates' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'runs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countOfPassedSequences' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countOfTotalSequences' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduledStartDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignedInstrument' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creator' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'percentOfPassedOligos' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'metaLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reportMetaLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRunsGroupsQuery, ProjectRunsGroupsQueryVariables>;
export const SystemInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SystemInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'systemInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceInfoFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceInfoDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'server' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SystemInfoQuery, SystemInfoQueryVariables>;
export const RedboxVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RedboxVersion' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'getRedboxVersion' } }],
      },
    },
  ],
} as unknown as DocumentNode<RedboxVersionQuery, RedboxVersionQueryVariables>;
export const SupportedInstrumentVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SupportedInstrumentVersions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'getSupportedInstrumentVersions' } }],
      },
    },
  ],
} as unknown as DocumentNode<SupportedInstrumentVersionsQuery, SupportedInstrumentVersionsQueryVariables>;
export const GetAvailableTimeZonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableTimeZones' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAvailableTimeZones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAvailableTimeZonesQuery, GetAvailableTimeZonesQueryVariables>;
export const DesignContextSwitchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DesignContextSwitch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'designContextSwitch' } }],
      },
    },
  ],
} as unknown as DocumentNode<DesignContextSwitchQuery, DesignContextSwitchQueryVariables>;
export const TemplateByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TemplateById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTemplateDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usePlateNorm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateDetailsDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefixes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emptySequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcentration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateByIdQuery, TemplateByIdQueryVariables>;
export const QueryMyTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryMyTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFilterDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTemplatesPerUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplatePerUserPageDTOFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplatePerUserPageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplatePerUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryMyTemplatesQuery, QueryMyTemplatesQueryVariables>;
export const QueryAllTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryAllTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFilterDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTemplatesAvailableForCurrentUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplatePerUserPageDTOFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplatePerUserPageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplatePerUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryAllTemplatesQuery, QueryAllTemplatesQueryVariables>;
export const QueryArchivedTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryArchivedTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFilterDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTemplatesArchived' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplatePerUserPageDTOFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplatePerUserPageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplatePerUserDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryArchivedTemplatesQuery, QueryArchivedTemplatesQueryVariables>;
export const GetPlateConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlateConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlateConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'maxWarningMers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxErrorMers' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPlateConfigsQuery, GetPlateConfigsQueryVariables>;
export const PlatesPerUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlatesPerUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIDNAPlates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlatesPerUserQuery, PlatesPerUserQueryVariables>;
export const ArchivedPlatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedPlates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIDNAPlatesArchived' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchivedPlatesQuery, ArchivedPlatesQueryVariables>;
export const PlatesForDropdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlatesForDropdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'KitType' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIDNAPlates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'kitType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlatesForDropdownQuery, PlatesForDropdownQueryVariables>;
export const PlateByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlateById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldIncludeProject' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIDNAPlateDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectDetailsById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldIncludeProject' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usePlateNorm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateDetailsDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefixes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emptySequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcentration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlateByIdQuery, PlateByIdQueryVariables>;
export const PlateStatusByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlateStatusById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIDNAPlateDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlateStatusByIdQuery, PlateStatusByIdQueryVariables>;
export const ValidateIdnaTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidateIDNATemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateUpdateDTO' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateIDNATemplateUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'update' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateMessageDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateMessageDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'messageGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SequenceDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'well' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixNucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefixDataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcetration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usePlateNorm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nucWarnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dataChunks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorScoreValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateDetailsDTOFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateDetailsDTO' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfRuns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfMods' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfClicks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countOfSequences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longestSequence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageSequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateMessageDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentIDNAName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefixes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'wellIndex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nucChunks' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emptySequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SequenceDTOFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exportMetaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetConcentration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidateIdnaTemplateQuery, ValidateIdnaTemplateQueryVariables>;
export const GetAllSequenceConcentrationLimitsByProjectTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllSequenceConcentrationLimitsByProjectType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllSequenceConcentrationLimitsByProjectType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllSequenceConcentrationLimitsByProjectTypeQuery,
  GetAllSequenceConcentrationLimitsByProjectTypeQueryVariables
>;
export const GetActiveModsAndClicksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveModsAndClicks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'KitType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActiveModsAndClicks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kitType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modsAndClicks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validClickPairs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fluorophore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quencher' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActiveModsAndClicksQuery, GetActiveModsAndClicksQueryVariables>;
export const GetSupportedKitTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSupportedKitTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSupportedKitTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kitInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'concentrationProperties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plateConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'maxCyclesPerTemplate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxErrorMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxIdnaMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxModsPerSequence' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxModsPerTemplate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxWarningMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minErrorMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minIdnaMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minWarningMers' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSupportedKitTypesQuery, GetSupportedKitTypesQueryVariables>;
export const GetSupportedIdnaKitTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSupportedIDNAKitTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSupportedIDNAKitTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'plateType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kitInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'concentrationProperties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plateConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'maxCyclesPerTemplate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxErrorMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxIdnaMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxModsPerSequence' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxModsPerTemplate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxWarningMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minErrorMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minIdnaMers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minWarningMers' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSupportedIdnaKitTypesQuery, GetSupportedIdnaKitTypesQueryVariables>;
export const GetAllKitTypesInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllKitTypesInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllKitTypesInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'kitType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kitLabel' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllKitTypesInfoQuery, GetAllKitTypesInfoQueryVariables>;
export const GetCentrifugationProcessesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCentrifugationProcesses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'KitType' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCentrifugationProcesses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kitType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'kitType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCentrifugationProcessesQuery, GetCentrifugationProcessesQueryVariables>;
export const CurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tooltipOnSeqPageIsClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'changePasswordAfterFirstLogin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validLicense' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cloud' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;
export const UserByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countOfProjects' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creatorFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creatorLastName' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updatedAt' },
                        name: { kind: 'Name', value: 'lastUpdatedDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalGroupMembers' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserByIdQuery, UserByIdQueryVariables>;
export const UsersListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UsersList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderField' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderDirection' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Direction' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserStatus' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchText' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orderField' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'direction' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'orderDirection' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statuses' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalElementsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersListQuery, UsersListQueryVariables>;
export const UsersShortListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UsersShortList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchText' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'IntValue', value: '999' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'StringValue', value: 'firstName', block: false },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'direction' },
                            value: { kind: 'EnumValue', value: 'ASC' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statuses' },
                      value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'ACTIVE' }] },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersShortListQuery, UsersShortListQueryVariables>;
export const AdminsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminsList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getListOfAdminsForOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminsListQuery, AdminsListQueryVariables>;
export const GetRemoteUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRemoteUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRemoteUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authority' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRemoteUsersQuery, GetRemoteUsersQueryVariables>;
