/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */

// NOTE: path parts separated by double underscore
// dynamic parts are written in camel case, so it is easier to use .replace() on them

export enum ERoutes {
  ACCOUNTS = '/accounts',
  ACCOUNTS__ORGANIZATION = '/accounts/organization',
  ACCOUNTS__TEAMS = '/accounts/teams',
  ACCOUNTS__TEAMS__id = '/accounts/teams/:id',
  ACCOUNTS__USERS = '/accounts/users',
  ACCOUNTS__USERS__id = '/accounts/users/:id',

  APPLICATIONS = '/applications',
  APPLICATIONS__PLATES = '/applications/plates',
  APPLICATIONS__PLATES__CREATE = '/applications/plates/create',
  APPLICATIONS__PLATES__plateId__DETAILS = '/applications/plates/:plateId/details',
  APPLICATIONS__PROJECTS = '/applications/projects',
  APPLICATIONS__PROJECTS__CREATE = '/applications/projects/create',
  APPLICATIONS__PROJECTS__CREATE__RESULT = '/applications/projects/create/result',

  APPLICATIONS__PROJECTS__DESIGN = '/applications/design',
  APPLICATIONS__PROJECTS__DESIGN__DETAILS__id = '/applications/design/details/:id',
  APPLICATIONS__PROJECTS__DESIGN__projectId__REPORT__id = '/applications/design/details/:projectId/report/:id',
  APPLICATIONS__PROJECTS__DESIGN__projectId__RUNS__TEMPLATE__templateId = '/applications/design/details/:projectId/runs/template/:templateId',
  APPLICATIONS__PROJECTS__DESIGN__projectId__RUNS__TEMPLATE__templateId__DETAILS = '/applications/design/details/:projectId/runs/template/:templateId/details',

  APPLICATIONS__PROJECTS__DETAILS__id = '/applications/projects/details/:id',
  APPLICATIONS__PROJECTS__DETAILS__id__REPORT__reportId = '/applications/projects/details/:projectId/report/:id',
  APPLICATIONS__PROJECTS__DETAILS__id__tab = '/applications/projects/details/:id/:tab',
  APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId = '/applications/projects/details/:projectId/runs/template/:templateId',
  APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__DETAILS = '/applications/projects/details/:projectId/runs/template/:templateId/details',
  APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__SCHEDULE = '/applications/projects/details/:projectId/runs/template/:templateId/schedule',
  APPLICATIONS__PROJECTS__DETAILS__projectId__SEQUENCE_FILES__NEW = '/applications/projects/details/:projectId/sequences/new-sequence',

  APPLICATIONS__PROJECTS__MANAGE__id = '/applications/projects/manage/:id',
  APPLICATIONS__PROJECTS__RESULT = '/applications/projects/result',
  APPLICATIONS__RUNS = '/applications/runs',
  APPLICATIONS__RUNS__DETAILS_id = '/applications/runs/details/:id',

  APPLICATIONS__SEQUENCE_FILES = '/applications/sequence-files',
  APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId = '/applications/sequence-files/template/:templateId',
  APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__DETAILS = '/applications/sequence-files/template/:templateId/details',
  APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__SCHEDULE = '/applications/sequence-files/template/:templateId/schedule',
  APPLICATIONS__pagePath__PLATES_plateId__DETAILS = ':pagePath/plates/:plateId/details',

  AUTH = '/auth',
  AUTH__CONFIRM_EMAIL__userId__code = '/auth/confirm-email/:userId/:code',
  AUTH__DELETE_SESSIONS__userId__code = '/auth/delete-sessions/:userId/:code',
  AUTH__ENABLE_USER__userId__code = '/auth/enable-account/:userId/:code',
  AUTH__FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH__FORGOT_PASSWORD__CONFIRM = '/auth/forgot-password/confirm',
  AUTH__FORGOT_PASSWORD__SET_NEW_PASSWORD__userId__code = '/auth/forgot-password/set-new-password/:userId/:code',
  AUTH__INVITE__SET_NEW_PASSWORD__userId__code = '/auth/invite/set-new-password/:userId/:code',
  AUTH__LOGIN = '/auth/login',

  CUSTOM_PROCESSES = '/custom-processes',

  DOWNLOADS = '/downloads',

  HOMEPAGE = '/',

  INSTRUMENTS = '/instruments',
  INSTRUMENTS__CREATE = '/instruments/overview/create',
  INSTRUMENTS__DETAILS__id = '/instruments/overview/details/:id',
  INSTRUMENTS__MANAGE__id = '/instruments/overview/manage/:id',
  INSTRUMENTS__OVERVIEW = '/instruments/overview',
  INSTRUMENTS__PRINT_QUEUE = '/instruments/queue',

  NOT_FOUND = '/not-found',
  PROFILE = '/profile',
  REPORTS = '/applications/runs/reports',
  SERVICE = '/service',

  SUPPORT = 'https://www.dnascript.com/support',
}
