import Paper from '@mui/material/Paper';
import styled from 'styled-components';

export const contentWithRightSidebarContainerStyles = {
  paddingRight: 'var(--report-sidebar-width)',
};

export const RightSidebarContainer = styled(Paper)`
  position: fixed;
  top: 55px;
  right: 0;
  width: var(--report-sidebar-width);
  height: calc(100vh - 55px);
  min-height: calc(100% - 55px);
  background-color: white;
  border-radius: 0;
  word-break: break-word;
  overflow: auto;
`;
