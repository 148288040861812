import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { ProjectType } from '__generated__/globalTypes';
import { useUserContext } from 'src/hooks';
import { TTab } from 'src/pages/projects/pages/detail/ProjectsDetailPage';
import PrintJobsTable from 'src/pages/projects/pages/detail/tabs/PrintJobsTable/components/Table';
import { TProjectDetailsById } from 'src/types';
import { getPermissionForProject } from 'src/utils/permissions/project';

import EmptyData from './EmptyData';

import ProjectGeneRunsTable from '../tabs/RunsTable/components/ProjectGeneRunsTable';
import ProjectRunsTable from '../tabs/RunsTable/components/ProjectRunsTable';

const TableWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
`;

interface IProjectsTableProps {
  projectDetail: TProjectDetailsById;
  refetchProject: () => Promise<unknown>;
  tab: TTab;
}

const ProjectsTable = ({ tab, refetchProject, projectDetail }: IProjectsTableProps) => {
  const { projectType } = projectDetail;
  const {
    userProfile,
    userPermissions: {
      project: { canViewPrintJobs, canViewRuns },
    },
  } = useUserContext();
  const permissions = useMemo(() => getPermissionForProject(userProfile, projectDetail), [userProfile, projectDetail]);

  const isShowPrintJobs = useMemo(
    () => canViewPrintJobs || permissions.projects.details.canViewPrintJobs,
    [canViewPrintJobs, permissions.projects.details.canViewPrintJobs],
  );
  const isShowRuns = useMemo(
    () => canViewRuns || permissions.projects.details.canViewRuns,
    [canViewRuns, permissions.projects.details.canViewRuns],
  );

  return (
    <TableWrapper>
      {tab === 'printJobs' && isShowPrintJobs && (
        <PrintJobsTable
          emptyData={<EmptyData projectDetail={projectDetail} title="template.noData" />}
          projectType={projectType}
          refetchProject={refetchProject}
        />
      )}
      {tab === 'runs' && isShowRuns && projectType === ProjectType.GENE && (
        <ProjectGeneRunsTable
          emptyData={<EmptyData projectDetail={projectDetail} title="runs.noData" />}
          refetchProject={refetchProject}
        />
      )}
      {tab === 'runs' && isShowRuns && projectType === ProjectType.SYNTAX && (
        <ProjectRunsTable
          emptyData={<EmptyData projectDetail={projectDetail} title="runs.noData" />}
          refetchProject={refetchProject}
        />
      )}
    </TableWrapper>
  );
};

export default ProjectsTable;
