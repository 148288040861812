import MuiAvatar from '@mui/material/Avatar';
import React from 'react';
import styled from 'styled-components';

interface IAvatarProps {
  size?: 'default' | 'small';
  user: {
    firstName?: string | null;
    lastName?: string | null;
  };
}

const MuiAvatarStyled = styled(MuiAvatar)<{ size?: 'default' | 'small' }>`
  text-transform: uppercase;
  background-color: ${(props) => props.theme.colors.secondary.gray_blue[800]};
  width: ${(props) => (props.size === 'small' ? 20 : 40)}px;
  height: ${(props) => (props.size === 'small' ? 20 : 40)}px;
  font-size: ${(props) => (props.size === 'small' ? 10 : 20)}px;
`;

const Avatar = ({ user, size = 'default' }: IAvatarProps) => {
  return (
    <MuiAvatarStyled size={size}>
      {(user.firstName ?? '')[0]}
      {(user.lastName ?? '')[0]}
    </MuiAvatarStyled>
  );
};

export default Avatar;
