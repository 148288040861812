import { Theme } from '@mui/material';
import isEmpty from 'lodash-es/isEmpty';

import { ENodeType } from './custom-type';

export const MOD_REGEX = /(\/[a-z-0-9]*\/)/gi;

type TChunk =
  | {
      bgColor: string;
      character?: string;
      message: string;
      text: string;
      type: ENodeType;
    }
  | string;

const slateMarkSequenceFieldChunk =
  (chunks: TChunk[], bgColor: string, type: ENodeType = ENodeType.WARNING) =>
  ({ index, message, length }: { index: number; length: number; message: string }) => {
    for (let i = index; i <= index + length - 1; i++) {
      const chunkI = chunks[i];
      if (chunkI) {
        // eslint-disable-next-line no-param-reassign
        chunks[i] = {
          bgColor,
          message,
          text: typeof chunkI === 'string' ? chunkI : chunkI.text,
          type,
        };
      }
    }
  };

let firstModIndex: number;

function getValidModData(theme: Theme, modIndex: number, modMsg?: string, nucErrors?: INucErrors[] | undefined) {
  const modWithErrors = nucErrors?.find((err) => err.index === modIndex);
  if (modWithErrors) {
    return {
      bgColor: theme.colors.primary.error[200],
      message: modWithErrors.message,
    };
  }

  return {
    bgColor: firstModIndex < modIndex ? '#D0D0CE' : '#BBDEFB',
    message: modMsg || '',
  };
}

const slateMarkValidSequenceFileChunk =
  (theme: Theme, mods: string[] | undefined, modMsg?: string, nucErrors?: INucErrors[] | undefined) =>
  (chunk: TChunk, i: number, chunks: TChunk[]) => {
    if (isEmpty(mods) || typeof chunk !== 'string') {
      return;
    }

    const lowercaseMods = mods?.map((mod) => mod.toLowerCase());
    if (lowercaseMods?.includes(chunk.toLowerCase())) {
      const modIndex = lowercaseMods.findIndex((mod) => mod === chunk.toLowerCase());
      const modData = getValidModData(theme, modIndex, modMsg, nucErrors);

      // eslint-disable-next-line no-param-reassign
      chunks[i] = {
        bgColor: modData.bgColor,
        character: mods?.[modIndex] || chunk,
        message: modData.message,
        text: mods?.[modIndex] || chunk,
        type: ENodeType.MOD,
      };

      if (modIndex >= 0) {
        firstModIndex = modIndex;
      }
    }
  };

export const slateSequenceFieldRules = [
  (theme: Theme, chunks: TChunk[], sequence?: Partial<ISequence>, mods?: string[], modMsg?: string) => {
    chunks.forEach(slateMarkValidSequenceFileChunk(theme, mods, modMsg, sequence?.nucErrors));
    sequence?.nucErrors?.forEach(slateMarkSequenceFieldChunk(chunks, theme.colors.primary.error[200], ENodeType.ERROR));
    sequence?.nucWarnings?.forEach(slateMarkSequenceFieldChunk(chunks, theme.colors.primary.warning[200]));
    return chunks;
  },
];

export const checkSequenceFieldRules =
  (theme: Theme, sequence?: Partial<ISequence>, mods?: string[], modsMsg?: string) =>
  (
    accumulatedValues: TChunk[],
    fn: (theme: Theme, str: TChunk[], sequence?: Partial<ISequence>, mods?: string[], modsMsg?: string) => TChunk[],
  ) =>
    fn(theme, accumulatedValues, sequence, mods, modsMsg);

export const clearSequenceFieldText = (str: string) => str.replace(/<.*?>/gis, '');
