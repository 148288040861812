import { Tooltip } from '@dna-script-inc/shared-ui-library';
import React from 'react';
import { Location } from 'slate';
import * as SlateReact from 'slate-react';
import { useSelected, RenderElementProps, RenderLeafProps } from 'slate-react';

import { useTranslation } from 'src/containers/i18n';

import { TCustomElement, ENodeType, TErrorElement, TModElement, TWarningElement } from '../../custom-type';

export type TRenderElementPropsWithMod<T> = Omit<RenderElementProps, 'element'> & {
  element: T;
  startModInput?: (params?: { at?: Location; startingMod?: string }) => void;
};

const ErrorElement = ({ attributes, children, element }: TRenderElementPropsWithMod<TErrorElement>) => {
  const selected = useSelected();
  const focused = SlateReact.useFocused();
  return (
    <Tooltip title=" " description={element.message} arrow>
      <span
        {...attributes}
        contentEditable
        style={{
          backgroundColor: element.bgColor,
          boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        }}
      >
        {children}
        {element.chunk}
      </span>
    </Tooltip>
  );
};

const WarningElement = ({ attributes, children, element }: TRenderElementPropsWithMod<TWarningElement>) => {
  const selected = useSelected();
  const focused = SlateReact.useFocused();
  return (
    <Tooltip title=" " description={element.message} arrow>
      <span
        {...attributes}
        contentEditable
        style={{
          backgroundColor: element.bgColor,
          boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

const ModElement = ({ attributes, children, element, startModInput }: TRenderElementPropsWithMod<TModElement>) => {
  const selected = useSelected();
  const focused = SlateReact.useFocused();
  const t = useTranslation();

  return (
    <Tooltip title=" " description={t('runs.plateEditor.editWindow.mods.click')} arrow>
      <span
        {...attributes}
        contentEditable
        style={{
          backgroundColor: element.bgColor,
          boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        }}
        onClick={() => {
          startModInput?.({
            startingMod: element.chunk,
          });
        }}
        data-type={element.type}
      >
        <mark
          style={{
            backgroundColor: element.bgColor,
            margin: 0,
            padding: 0,
          }}
        >
          {children}
          {element.chunk}
        </mark>
      </span>
    </Tooltip>
  );
};

export const Element = (props: TRenderElementPropsWithMod<TCustomElement>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { attributes, children } = props;
  const { element, startModInput, ...propsWithoutElement } = props;
  switch (element.type) {
    case ENodeType.ERROR:
      return <ErrorElement element={element} {...propsWithoutElement} />;
    case ENodeType.WARNING:
      return <WarningElement element={element} {...propsWithoutElement} />;
    case ENodeType.MOD:
      return <ModElement element={element} startModInput={startModInput} {...propsWithoutElement} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

interface ICustomRenderLeafProps extends RenderLeafProps {
  leaf: RenderLeafProps['leaf'] & {
    type?: ENodeType;
    bgColor?: string;
    message?: string;
  };
}

export const Leaf = ({ attributes, children, leaf }: ICustomRenderLeafProps) => {
  if (leaf.type === ENodeType.WARNING) {
    return (
      <Tooltip title=" " description={leaf.message} arrow>
        <span
          {...attributes}
          contentEditable
          style={{
            backgroundColor: leaf.bgColor,
          }}
        >
          {children}
        </span>
      </Tooltip>
    );
  }
  return <span {...attributes}>{children}</span>;
};
