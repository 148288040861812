import { useMemo, useState } from 'react';

import useUserContext from 'src/hooks/useUserContext';
import { HAS_TIMEZONE_PROMPT_PROCESSED } from 'src/utils/common';
import storage from 'src/utils/storage';

const MINUTES = 60;

export function useShouldShowTimezoneWarning() {
  const { userProfile } = useUserContext();

  const [isOpen, setIsOpen] = useState(!storage.SESSION.get(HAS_TIMEZONE_PROMPT_PROCESSED));

  return useMemo(() => {
    const browserDate = new Date();
    const hasDifferentTimezones =
      browserDate.getTimezoneOffset() + Number(userProfile?.timeZone?.offset) / MINUTES !== 0;
    return {
      clearTimezoneWarningFlag: () => {
        storage.SESSION.set(HAS_TIMEZONE_PROMPT_PROCESSED, true);
        setIsOpen(false);
      },
      shouldShowTimezoneWarning: isOpen && hasDifferentTimezones,
    };
  }, [setIsOpen, isOpen, userProfile?.timeZone?.offset]);
}
