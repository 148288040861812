/* eslint-disable react/display-name */
import { Button, DNAButtonProps } from '@dna-script-inc/shared-ui-library';
import { ArrowForward } from '@mui/icons-material';
import React from 'react';

import { Translate } from 'src/containers/i18n';

type TNextButtonProps = Omit<DNAButtonProps, 'destructive' | 'children' | 'title' | 'startIcon' | 'endIcon'>;

const NextButton = React.forwardRef((props: TNextButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  return (
    <Button {...props} ref={ref} endIcon={<ArrowForward />}>
      <Translate id="projects.create.button.next" />
    </Button>
  );
});

NextButton.defaultProps = {
  size: 'md',
};

export default NextButton;
