import { Button } from '@dna-script-inc/shared-ui-library';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Validate } from 'react-hook-form';
import styled from 'styled-components';

import { TextInput } from 'src/components/form';
import { MIN_PASSWORD_LENGTH } from 'src/config/constants';
import { useTranslation } from 'src/containers/i18n';
import {
  validateHasLowercaseLetter,
  validateHasNumber,
  validateHasSpecialCharacter,
  validateHasUppercaseLetter,
  validateMinLength,
} from 'src/utils/validator';

export const useGetValidationRules = (): Record<string, Validate<string, unknown>> => {
  const t = useTranslation();
  return {
    minLength: (value: string) => {
      return validateMinLength(value, MIN_PASSWORD_LENGTH)
        ? t('general.form.validation.password.minLength')
        : undefined;
    },
    shouldHaveLowercaseLetters: (value: string) =>
      validateHasLowercaseLetter(value) ? undefined : t('general.form.validation.password.shouldHaveLowercase'),
    shouldHaveNumbers: (value: string) =>
      validateHasNumber(value) ? undefined : t('general.form.validation.password.shouldHaveNumber'),
    shouldHaveSpecialCharacters: (value: string) =>
      validateHasSpecialCharacter(value) ? undefined : t('general.form.validation.password.shouldHaveSpecialCharacter'),
    shouldHaveUppercaseLetters: (value: string) =>
      validateHasUppercaseLetter(value) ? undefined : t('general.form.validation.password.shouldHaveUppercase'),
  };
};

const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

interface IPasswordInputProps extends Omit<TextFieldProps, 'type'> {
  handleCopyPassword?: () => void;
  type?: 'password' | 'text';
}

const PasswordInput = (props: React.PropsWithChildren<IPasswordInputProps>) => {
  const [type, setType] = React.useState(props.type || 'password');
  const { handleCopyPassword } = props;

  return (
    <TextInput
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ButtonWrapper onClick={() => setType(type === 'text' ? 'password' : 'text')}>
              {type === 'text' ? <Visibility /> : <VisibilityOff />}
            </ButtonWrapper>
            {handleCopyPassword && (
              <Button variant="tertiary-gray" onClick={handleCopyPassword} size="lg">
                <FileCopyOutlinedIcon />
              </Button>
            )}
          </InputAdornment>
        ),
      }}
      type={type}
    />
  );
};

export default PasswordInput;
