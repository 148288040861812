import { useQuery } from '@apollo/client';
import { Spinner } from '@dna-script-inc/shared-ui-library';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import styled from 'styled-components';

import { CouplingLevel, PqaModConfig, PqaRunModsStatus } from 'src/gql/graphql';
import { QUERY_PQA_STATISTIC } from 'src/services/gql/queries/runs';

enum StatusBackgroundColor {
  GREEN = '#AED8A1',
  RED = '#F5B7B1',
  YELLOW = '#F9E79F',
}

const StyledTableContainer = styled(TableContainer)`
  margin-top: 37px;
  padding: 24px;
`;

const DataCell = styled(TableCell)`
  width: 200px;
`;

const getColorByInvalidWellsCount = (invalidWellsNumber: number, maxInvalidWellsNumber: number) => {
  if (maxInvalidWellsNumber < 0) {
    return 'white';
  }
  if (invalidWellsNumber > maxInvalidWellsNumber) {
    return StatusBackgroundColor.RED;
  }
  return StatusBackgroundColor.GREEN;
};

const getColorByLevel = (level: CouplingLevel) => {
  if (level === CouplingLevel.SUCCESSFUL) {
    return StatusBackgroundColor.GREEN;
  }
  if (level === CouplingLevel.ACCEPTABLE) {
    return StatusBackgroundColor.YELLOW;
  }
  return StatusBackgroundColor.RED;
};

const getLegend = (modConfig: PqaModConfig, level: CouplingLevel) => {
  const couplingLevel = modConfig.couplingLevels.find((item) => item.level === level);
  if (couplingLevel) {
    return `${couplingLevel.min}% - ${couplingLevel.max}%`;
  }
  return '';
};

export const PqaRunStatistic = (data: { runId: string }) => {
  const { data: pqaStatistic, loading } = useQuery(QUERY_PQA_STATISTIC, {
    variables: {
      runId: data.runId || '',
    },
  });

  if (loading) {
    return (
      <Box textAlign="center">
        <Spinner />
      </Box>
    );
  }

  if (!pqaStatistic || !pqaStatistic.getPqaStatistic) {
    return null;
  }

  return (
    <div>
      {pqaStatistic?.getPqaStatistic.groupsInfo.length > 0 && (
        <StyledTableContainer as={Paper}>
          <Typography variant="h4">Yields</Typography>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <DataCell align="center">Total wells count</DataCell>
                <DataCell align="center">Average Yield, pmol</DataCell>
                <DataCell align="center">SD, pmol</DataCell>
                <DataCell align="center">CV, %</DataCell>
                <DataCell align="center">Minimum, pmol</DataCell>
                <DataCell align="center">Maximum, pmol</DataCell>
                <DataCell align="center">Acceptable invalid wells count</DataCell>
                <DataCell align="center">Condition</DataCell>
                <DataCell align="center">Invalid wells count</DataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pqaStatistic.getPqaStatistic.groupsInfo.map((group) => {
                if (!group) return null;
                return (
                  <TableRow key={group.groupName}>
                    <TableCell>{group.groupName}</TableCell>
                    <DataCell align="center">{group.totalWellNumber}</DataCell>
                    <DataCell align="center">{group.averageYieldPmol.toFixed(2)}</DataCell>
                    <DataCell align="center">{group.standardDeviationPmol.toFixed(2)}</DataCell>
                    <DataCell align="center">{(group.coefficientOfVariation * 100).toFixed(2)}</DataCell>
                    <DataCell align="center">{group.minInGroup.toFixed(2)}</DataCell>
                    <DataCell align="center">{group.maxInGroup.toFixed(2)}</DataCell>
                    <DataCell align="center">
                      {group.maxInvalidWellsNumber > -1 ? group.maxInvalidWellsNumber : 'for information'}
                    </DataCell>
                    <DataCell align="center">
                      {group.minSizePmol > -1 ? `< ${group.minSizePmol}pmol` : 'for information'}
                    </DataCell>
                    <DataCell
                      align="center"
                      style={{
                        background: getColorByInvalidWellsCount(group.invalidWellsNumber, group.maxInvalidWellsNumber),
                      }}
                    >
                      {group.invalidWellsNumber}
                    </DataCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
      {pqaStatistic?.getPqaStatistic.modsInfo && (
        <div>
          <StyledTableContainer as={Paper}>
            <Typography variant="h4">Label Efficiency</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Well</TableCell>
                  <TableCell align="center">Label</TableCell>
                  <TableCell align="center">Coupling, %</TableCell>
                  <TableCell align="center">Coupling Level</TableCell>
                  <DataCell align="center">Conclusion</DataCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pqaStatistic.getPqaStatistic.modsInfo.wellMods.map((well) => (
                  <React.Fragment key={well.well}>
                    <TableRow key={well.well}>
                      <TableCell align="center" rowSpan={well.mods.length}>
                        {well.well}
                      </TableCell>
                      <TableCell align="center">{well.mods[0].label}</TableCell>
                      <TableCell align="center">{well.mods[0].couplingPercent}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderColor: getColorByLevel(well.mods[0].level),
                        }}
                      >
                        {well.mods[0].level}
                      </TableCell>
                      <DataCell
                        align="center"
                        rowSpan={well.mods.length}
                        style={{
                          background: getColorByLevel(well.conclusion),
                        }}
                      >
                        {well.conclusion}
                      </DataCell>
                    </TableRow>
                    {well.mods.slice(1).map((mod) => (
                      <TableRow key={mod.label}>
                        <TableCell align="center">{mod.label}</TableCell>
                        <TableCell align="center">{mod.couplingPercent}</TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderColor: getColorByLevel(mod.level),
                          }}
                        >
                          {mod.level}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    Overall Label Result
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      background:
                        pqaStatistic.getPqaStatistic.modsInfo.status === PqaRunModsStatus.FAIL
                          ? StatusBackgroundColor.RED
                          : 'green',
                    }}
                  >
                    {pqaStatistic.getPqaStatistic.modsInfo.status}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box p={2} display="flex" justifyContent="flex-end">
              <Table sx={{ maxWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <DataCell align="center">Well Color criteria</DataCell>
                    <DataCell
                      align="center"
                      style={{
                        background: StatusBackgroundColor.RED,
                      }}
                    >
                      {CouplingLevel.LOW}
                    </DataCell>
                    <DataCell
                      align="center"
                      style={{
                        background: StatusBackgroundColor.YELLOW,
                      }}
                    >
                      {CouplingLevel.ACCEPTABLE}
                    </DataCell>
                    <DataCell
                      align="center"
                      style={{
                        background: StatusBackgroundColor.GREEN,
                      }}
                    >
                      {CouplingLevel.SUCCESSFUL}
                    </DataCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pqaStatistic.getPqaStatistic.modsInfo.modsConfig.map((modConfig) => (
                    <TableRow key={modConfig.mod}>
                      <DataCell align="center">{modConfig.mod}</DataCell>
                      <DataCell align="center">{getLegend(modConfig, CouplingLevel.LOW)}</DataCell>
                      <DataCell align="center">{getLegend(modConfig, CouplingLevel.ACCEPTABLE)}</DataCell>
                      <DataCell align="center">{getLegend(modConfig, CouplingLevel.SUCCESSFUL)}</DataCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </StyledTableContainer>
        </div>
      )}
    </div>
  );
};
