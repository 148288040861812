import { graphql } from '../../../gql';

export const FRAGMENT_SEQUENCE_DTO = graphql(`
  fragment SequenceDTOFragment on SequenceDTO {
    id
    well
    wellIndex
    name
    length
    modsCount
    prefixNucChunks
    prefixDataChunks
    nucChunks
    dataChunks
    status
    targetConcetration
    normalizationType
    usePlateNorm
    errors {
      message
      group
    }
    warnings {
      message
      group
    }
    data
    nucErrors {
      index
      message
      length
      group
    }
    nucWarnings {
      index
      message
      length
    }
    dataChunks
    score
    errorScoreValue
  }
`);

export const FRAGMENT_PLATE_SEQUENCE_DTO = graphql(`
  fragment SequenceDTOPlateFragment on SequenceDTO {
    id
    well
    wellIndex
    name
    length
    validationLength
    modsCount
    prefixNucChunks
    prefixDataChunks
    nucChunks
    dataChunks
    status
    errors {
      message
      group
    }
    warnings {
      message
      group
    }
    data
    nucErrors {
      index
      message
      length
      group
    }
    nucWarnings {
      index
      message
      length
    }
    dataChunks
    score
    errorScoreValue
  }
`);

export const FRAGMENT_TEMPLATE_MESSAGE_DTO = graphql(`
  fragment TemplateMessageDTOFragment on TemplateMessageDTO {
    messageGroup
    message
  }
`);

export const TEMPLATE_DETAILS_DTO_FRAGMENT = graphql(`
  fragment TemplateDetailsDTOFragment on TemplateDetailsDTO {
    id
    name
    status
    format
    createdAt
    updatedAt
    creator {
      id
      firstName
      lastName
      __typename
    }
    countOfRuns
    countOfMods
    countOfClicks
    countOfSequences
    shortestSequence
    longestSequence
    averageSequence
    errors {
      ...TemplateMessageDTOFragment
    }
    warnings {
      ...TemplateMessageDTOFragment
    }
    plateType
    parentIDNAId
    parentIDNAName
    kitType
    prefixes {
      wellIndex
      data
      nucChunks
    }
    sequences {
      ...SequenceDTOFragment
    }
    emptySequences {
      ...SequenceDTOFragment
    }
    exportMetaLink
    normalizationType
    targetConcentration
    projectType
  }
`);

export const GET_TEMPLATE_BY_ID = graphql(`
  query TemplateById($id: ID!) {
    getTemplateDetails(templateId: $id) {
      ...TemplateDetailsDTOFragment
    }
  }
`);

export const TemplatePerUserPageDTOFragment = graphql(`
  fragment TemplatePerUserPageDTOFragment on TemplatePerUserDTO {
    id
    name
    format
    status
    createdAt
    updatedAt
    plateType
    partNumber
    kitType
    type
    project {
      id
      name
      projectType
    }
    creator {
      id
      firstName
      lastName
    }
    countOfRuns
    countOfMods
    countOfClicks
    countOfSequences
    shortestSequence
    longestSequence
    parentIDNAId
    parentIDNAName
    exportMetaLink
  }
`);

export const QUERY_MY_TEMPLATES = graphql(`
  query QueryMyTemplates($filter: TemplateFilterDTO!) {
    getTemplatesPerUser(filter: $filter) {
      data {
        ...TemplatePerUserPageDTOFragment
      }
      totalElementsCount
    }
  }
`);

export const QUERY_ALL_TEMPLATES = graphql(`
  query QueryAllTemplates($filter: TemplateFilterDTO!) {
    getTemplatesAvailableForCurrentUser(filter: $filter) {
      data {
        ...TemplatePerUserPageDTOFragment
      }
      totalElementsCount
    }
  }
`);

export const QUERY_ARCHIVED_TEMPLATES = graphql(`
  query QueryArchivedTemplates($filter: TemplateFilterDTO!) {
    getTemplatesArchived(filter: $filter) {
      data {
        ...TemplatePerUserPageDTOFragment
      }
      totalElementsCount
    }
  }
`);

export const QUERY_TEMPLATE_CONFIGS = graphql(`
  query GetPlateConfigs($id: ID!) {
    getPlateConfigs(templateId: $id) {
      maxWarningMers
      maxErrorMers
    }
  }
`);

export const QUERY_PLATES_PER_USER = graphql(`
  query PlatesPerUser($projectId: ID, $userId: ID) {
    getIDNAPlates(filter: { projectId: $projectId, userId: $userId }) {
      data {
        id
        name
        status
        format
        type
        kitType
        plateType
        partNumber
        createdAt
        updatedAt
        creator {
          id
          firstName
          lastName
        }
        countOfRuns
        countOfMods
        countOfSequences
        shortestSequence
        longestSequence
        averageSequence
        project {
          id
          name
          description
        }
        exportMetaLink
        parentIDNAId
        parentIDNAName
      }
      totalElementsCount
    }
  }
`);

export const QUERY_ARCHIVED_PLATES = graphql(`
  query ArchivedPlates($userId: ID) {
    getIDNAPlatesArchived(filter: { userId: $userId }) {
      data {
        id
        name
        status
        format
        type
        kitType
        plateType
        partNumber
        createdAt
        updatedAt
        creator {
          id
          firstName
          lastName
        }
        countOfRuns
        countOfMods
        countOfSequences
        shortestSequence
        longestSequence
        averageSequence
        project {
          id
          name
          description
        }
        exportMetaLink
        parentIDNAId
        parentIDNAName
      }
      totalElementsCount
    }
  }
`);

export const QUERY_PLATES_FOR_DROPDOWN = graphql(`
  query PlatesForDropdown($kitType: KitType) {
    getIDNAPlates(filter: { kitType: $kitType }) {
      data {
        id
        name
        plateType
        kitType
      }
    }
  }
`);

export const GET_PLATE_BY_ID = graphql(`
  query PlateById($id: ID!, $projectId: ID!, $shouldIncludeProject: Boolean!) {
    getIDNAPlateDetails(templateId: $id) {
      ...TemplateDetailsDTOFragment
    }
    getProjectDetailsById(id: $projectId) @include(if: $shouldIncludeProject) {
      name
    }
  }
`);

export const GET_PLATE_STATUS_BY_ID = graphql(`
  query PlateStatusById($id: ID!) {
    getIDNAPlateDetails(templateId: $id) {
      id
      status
    }
  }
`);

export const VALIDATE_IDNA_TEMPLATE = graphql(`
  query ValidateIDNATemplate($update: TemplateUpdateDTO!) {
    validateIDNATemplateUpdate(update: $update) {
      ...TemplateDetailsDTOFragment
    }
  }
`);

export const GET_SEQUENCE_CONCENTRATION_LIMITS = graphql(`
  query GetAllSequenceConcentrationLimitsByProjectType($projectType: ProjectType!) {
    getAllSequenceConcentrationLimitsByProjectType(projectType: $projectType) {
      min
      max
      defaultValue
      kitType
    }
  }
`);

export const GET_ACTIVE_MODS_AND_CLICKS = graphql(`
  query GetActiveModsAndClicks($kitType: KitType!) {
    getActiveModsAndClicks(kitType: $kitType) {
      modsAndClicks
      validClickPairs {
        fluorophore
        quencher
      }
    }
  }
`);

export const GET_SUPPORTED_KIT_TYPES = graphql(`
  query GetSupportedKitTypes {
    getSupportedKitTypes {
      plateType
      kitInfo {
        kitType
        concentrationProperties {
          defaultValue
          kitType
          max
          min
        }
        plateConfig {
          maxCyclesPerTemplate
          maxErrorMers
          maxIdnaMers
          maxModsPerSequence
          maxModsPerTemplate
          maxWarningMers
          minErrorMers
          minIdnaMers
          minWarningMers
        }
      }
    }
  }
`);

export const GET_SUPPORTED_IDNA_KIT_TYPES = graphql(`
  query GetSupportedIDNAKitTypes {
    getSupportedIDNAKitTypes {
      plateType
      kitInfo {
        kitType
        concentrationProperties {
          defaultValue
          kitType
          max
          min
        }
        plateConfig {
          maxCyclesPerTemplate
          maxErrorMers
          maxIdnaMers
          maxModsPerSequence
          maxModsPerTemplate
          maxWarningMers
          minErrorMers
          minIdnaMers
          minWarningMers
        }
      }
    }
  }
`);

export const GET_ALL_KIT_TYPES_INFO = graphql(`
  query GetAllKitTypesInfo {
    getAllKitTypesInfo {
      kitType
      kitLabel
    }
  }
`);

export const GET_CENTRIFUGATION_PROCESSES = graphql(`
  query GetCentrifugationProcesses($kitType: KitType!) {
    getCentrifugationProcesses(kitType: $kitType)
  }
`);
