import { useQuery } from '@apollo/client';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { ISimpleInstrument } from 'src/@types/instruments';
import { ErrorCard, CenteredSpinner } from 'src/components/common';
import { useTranslation } from 'src/containers/i18n';
import { useRunContext } from 'src/pages/runs/container';
import { QUERY_INSTRUMENTS_FOR_PRINT } from 'src/services/gql/queries/instruments';
import { getErrorMessage } from 'src/utils/errors';

import InstrumentCalendar from './components/InstrumentCalendar';
import { ProjectSchedulerProvider } from './container';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 32px;
`;

const ProjectsSchedulerPage = () => {
  const t = useTranslation();
  const { templateDetail } = useRunContext();
  const [isAvailable, setIsAvailable] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(null);
  const { data, loading } = useQuery(QUERY_INSTRUMENTS_FOR_PRINT, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.findSuitableInstrumentsForPrint?.length === 0) {
        setError({
          message: t('schedule.noInstruments.description'),
        });
      } else {
        setIsAvailable(true);
      }
    },
    onError: (errors) => {
      setError({ message: getErrorMessage(errors) });
    },
    skip: !templateDetail,
    variables: {
      // NOTE: this query is skipped if templateDetail is undefined, so it is safe to use ! operator
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      templateId: templateDetail?.id!,
    },
  });

  const instruments = (data?.findSuitableInstrumentsForPrint || []) as ISimpleInstrument[];

  if (loading) {
    return <CenteredSpinner />;
  }

  if (error) {
    return <ErrorCard title="schedule.noInstruments.title" error={error.message} />;
  }

  return (
    <ProjectSchedulerProvider instruments={instruments}>
      <Wrapper>{isAvailable && <InstrumentCalendar />}</Wrapper>
    </ProjectSchedulerProvider>
  );
};

export default ProjectsSchedulerPage;
