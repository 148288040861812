import { GridRowParams } from '@mui/x-data-grid';
import { isEmpty, omit } from 'lodash-es';
import React, { SetStateAction, useMemo } from 'react';

import { KitType, NormalizationType, PlateType } from '__generated__/globalTypes';
import { TSequenceGridType, TSequenceInGrid } from 'src/components/sequences/hooks/types';
import { TemplateByIdQuery } from 'src/gql/graphql';
import { useRunContext } from 'src/pages/runs/container';
import { useCurrentTemplate } from 'src/stores/sequenceEditor/hooks';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';

import { selectUpdatedWellAndReturnChange, TWellStatus } from './helpers';

import SequenceFileSheet from '../../create/components/SequenceFileSheet';
import { TWell } from '../../create/types';

type TSequenceFileSheetDetailsProps = {
  sequences: TSequenceGridType<TSequenceInGrid>[];
  kitType: KitType;
  plateType: PlateType;
};

type TWellWithPosition = TWell & Pick<TSequenceInGrid, 'wellPosition' | 'wellIndex'> & { status: TWellStatus };

const getStatusFromSequence = (sequence: TSequenceGridType<TSequenceInGrid>): TWellStatus => {
  if (sequence.errors && sequence.errors.length > 0) {
    return 'error';
  }
  if (sequence.warnings && sequence.warnings.length > 0) {
    return 'warning';
  }
  return 'nominal';
};
const getConcentrationFromSequence = (sequence: TSequenceGridType<TSequenceInGrid>): number => {
  if (sequence.normalizationType === NormalizationType.TARGET) {
    return Number(sequence.targetConcentration);
  }
  if (sequence.normalizationType === NormalizationType.NONE) {
    return 0;
  }
  return -1;
};
const getNormalizationFromSequence = (sequence: TSequenceGridType<TSequenceInGrid>): NormalizationType => {
  if ([NormalizationType.TARGET, NormalizationType.NONE].includes(sequence.normalizationType)) {
    return sequence.normalizationType;
  }
  return NormalizationType.LOWEST;
};

const filterSequenceBySearch = (sequence: string, searchText: string) => !searchText || sequence.includes(searchText);

export default function SequenceFileSheetDetails({ sequences, kitType, plateType }: TSequenceFileSheetDetailsProps) {
  const {
    updateWellConcentration,
    updateWellName,
    updateWellSequence,
    updateNormalization,
    clickOnWell,
    selectedWells,
    search: searchText,
  } = useSequenceEditorStore();
  const { modsAndClicksStructure, composeModsChunksFromString, modsAndClicksRegex } = useRunContext();
  const templateDetail = useCurrentTemplate();

  const plate: TWellWithPosition[] = useMemo(() => {
    return sequences
      .filter((s) => s.wellPosition.column >= 0)
      .map((s) => {
        const status = getStatusFromSequence(s);
        const concentration = getConcentrationFromSequence(s);
        const normalization = getNormalizationFromSequence(s);
        return {
          concentration,
          idnaSequence: '',
          name: s.name ?? '',
          normalization,
          sequence: s.data ?? '',
          status,
          well: s.well,
          wellIndex: s.wellIndex,
          wellPosition: s.wellPosition,
        };
      })
      .filter((s) => filterSequenceBySearch(s.sequence, searchText));
  }, [sequences, searchText]);

  const setPlate = (newPlate: SetStateAction<TWell[] | null>) => {
    const newPlateValue = typeof newPlate === 'function' ? newPlate(plate) : newPlate;
    if (!newPlateValue) {
      return;
    }
    const updates = selectUpdatedWellAndReturnChange(
      newPlateValue.map((well) => omit(well, ['plateType', 'kitType'])) as TWellWithPosition[],
      plate,
    );

    if (!updates) {
      return;
    }

    updates.forEach((update) => {
      const { propertyChanged, newPropertyValue, well } = update;
      const isNameChanged = propertyChanged === 'name';
      if (isNameChanged) {
        updateWellName(well, newPropertyValue as string);
        return;
      }

      const isSequenceChanged = propertyChanged === 'sequence';
      if (isSequenceChanged) {
        const newSequence = newPropertyValue as string;
        const shouldUseComposeModsFunction =
          !isEmpty(modsAndClicksStructure?.modsAndClicks) && newSequence.search(modsAndClicksRegex) !== -1;
        const chunksArray = shouldUseComposeModsFunction
          ? composeModsChunksFromString(newSequence)
          : newSequence.trim().split('');
        updateWellSequence(well, { chunks: chunksArray, sequence: newSequence });
        return;
      }

      const isConcentrationChanged = propertyChanged === 'concentration';
      if (isConcentrationChanged) {
        updateWellConcentration(well, newPropertyValue as number);
      }

      if (propertyChanged === 'normalization') {
        updateNormalization(newPropertyValue as NormalizationType);
      }
    });
  };

  const handleRowClick = (params: GridRowParams<TWellWithPosition>) => {
    if (!selectedWells.includes(params.row.well)) {
      clickOnWell(params.row.well, params.row.wellIndex, false);
    }
  };

  return (
    <SequenceFileSheet
      savedPlate={templateDetail as TemplateByIdQuery['getTemplateDetails']}
      plate={plate}
      plateType={plateType}
      setPlate={setPlate}
      kitType={kitType}
      handleRowClick={handleRowClick}
    />
  );
}
