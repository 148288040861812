export function getFormattedPrice(price: number, currency: string | undefined | null) {
  if (!currency) {
    return price;
  }
  const languages = window.navigator.languages.slice();
  const formatter = new Intl.NumberFormat(languages, {
    currency,
    style: 'currency',
  });

  return formatter.format(price);
}
