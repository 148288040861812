import { TemplateByIdQuery, TemplateMessageGroup } from 'src/gql/graphql';

type TErrors = NonNullable<TemplateByIdQuery['getTemplateDetails']>['errors'];
type TWarnings = NonNullable<TemplateByIdQuery['getTemplateDetails']>['warnings'];

const UnwantedGroups = [TemplateMessageGroup.NUCLEOTIDE, TemplateMessageGroup.SEQUENCE];

const filterOutUnwantedGroups = (errors: TErrors | TWarnings) =>
  errors?.filter((error) => !error?.messageGroup || !UnwantedGroups.includes(error?.messageGroup));

const filterOutNull = (errors: TErrors | TWarnings) => errors?.filter((error) => typeof error?.message === 'string');

const transformToTemplateMessageInterface = (errors: TErrors | TWarnings) =>
  errors?.map((error) => ({ message: error?.message ?? '', messageGroup: (error?.messageGroup as string) ?? '' })) ??
  [];

function useParseTemplateErrorsAndWarnings(template: TemplateByIdQuery['getTemplateDetails']): {
  errors: ITemplateMessage[];
  warnings: ITemplateMessage[];
} {
  const errors = transformToTemplateMessageInterface(filterOutNull(filterOutUnwantedGroups(template?.errors ?? [])));
  const warnings = transformToTemplateMessageInterface(
    filterOutNull(filterOutUnwantedGroups(template?.warnings ?? [])),
  );

  return { errors, warnings };
}

export default useParseTemplateErrorsAndWarnings;
