export const getDownloadLink = (link: string): string => {
  return link;
};

export function handlePrivateDownload(link: string, name?: string) {
  const a = document.createElement('a');
  a.href = link;
  a.download = name || '';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function handleDownload(link?: string | null) {
  if (link) {
    const url = getDownloadLink(link);
    handlePrivateDownload(url);
  }
}

export function getPrintJobPdfDetails(printJobId: string) {
  fetch(`/api/run/run-estimation-${printJobId}.pdf`).then((res) => {
    if (res?.url) {
      handlePrivateDownload(res.url, printJobId);
    }
  });
}

export function formatErrorMessage(message?: string) {
  if (typeof message === 'string') {
    return message.replace('GraphQL error: ', '');
  }
  return message;
}

export const HAS_TIMEZONE_PROMPT_PROCESSED = 'HAS_TIMEZONE_PROMPT_PROCESSED';

export function downloadBlob(content: string, filename: string, contentType: string) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

export const MAX_ZIP_LENGTH = 8;
