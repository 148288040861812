import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import noop from 'lodash-es/noop';
import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import { KitType } from '__generated__/globalTypes';
import { DEFAULT_CONCENTRATION_KEY } from 'src/containers/application/constants';
import { useKitTypeConcentrationLimits } from 'src/hooks/useKitTypeConcentrationLimits';

interface ISliderProps {
  $disableConcentration?: boolean;
  $isInspector?: boolean;
  $isLegend?: boolean;
  $isLowerTarget?: boolean;
  $isMultipleValues?: boolean;
  isVolume?: boolean;
}

const CONCENTRATION_BOX_SIZE = 22;
const ConcentrationRangeBox = styled.div`
  flex-grow: 1;
  height: ${CONCENTRATION_BOX_SIZE}px;
  background: var(--report-concentration-gradient);
`;

const ConcentrationContainer = styled(Box)`
  position: relative;
  margin-top: 50px;
  width: 100%;
`;

const MARK_SIZE = 2;

const TARGET_ITEM = '1';
const ACTUAL_ITEM = '2';

const StyledSlider = styled(Slider)<ISliderProps>`
  position: absolute;
  left: ${MARK_SIZE}px;
  bottom: ${CONCENTRATION_BOX_SIZE}px;
  transform: translateY(50%);
  width: calc(100% - ${2 * MARK_SIZE}px);
  color: #000;
  height: 0px;

  & .MuiSlider-markActive {
    background-color: currentColor;
  }

  ${(props) =>
    props.$disableConcentration &&
    `
        & .MuiSlider-thumb {
            &.Mui-disabled {
                display: none;
            }
        }`}

  ${(props) =>
    !props.$isInspector &&
    `
            .MuiSlider-valueLabel {
                margin-left: 0!important;
            }
        `}

    ${(props) =>
    props.$isMultipleValues
      ? `
        .MuiSlider-track {
            opacity: 0
        }
        .MuiSlider-mark {
            background-color: #000000;
        }
        & .MuiSlider-thumb {
            box-shadow: unset;

            &.Mui-disabled {
                width: 1px;
                height:  ${!props.isVolume && props.$isInspector ? `36px` : `14px`};
                margin-left: 0;
                background-color: #000000;
                border-radius: 0;
            }
            ${
              !props.isVolume && props.$isInspector
                ? `
                &:nth-last-child(${props.$isLowerTarget ? TARGET_ITEM : ACTUAL_ITEM}) .MuiSlider-valueLabel {
                    transform: translate(-100%,150%);
                }
                &:nth-last-child(${props.$isLowerTarget ? ACTUAL_ITEM : TARGET_ITEM}) .MuiSlider-valueLabel {
                    transform: translate(-100%, -10px);
                }
                &.Mui-disabled:nth-last-child(${props.$isLowerTarget ? TARGET_ITEM : ACTUAL_ITEM}) {
                    margin-top: 0;
                    top: auto;
                    transform: none;
                }
                &.Mui-disabled:nth-last-child(${props.$isLowerTarget ? ACTUAL_ITEM : TARGET_ITEM}) {
                    margin-top: -32px;
                    top: auto;
                    transform: none;
                }
            `
                : `
                &.Mui-disabled:nth-last-child(1), &.Mui-disabled:nth-last-child(2)  {
                    margin-top: -13px;
                }
                &.Mui-disabled:nth-last-child(1){
                    transform: translate(1px,0);
                }
                &.Mui-disabled:nth-last-child(2) {
                    transform: translate(-1px, 0);
                }
            `
            }

            .MuiSlider-valueLabel {
                left: ${props.$isLegend ? `calc(-50% - 12px)` : `0`};
                padding: 0.35rem 0;
                & > span {
                    transform: none;
                    width: initial;
                    & > span {
                        transform: none;
                    }
                }
            }

            & span {
                background: transparent;
                color: #000000;
                font-size: 14px;
                white-space: nowrap;
                top: -15px;
                ${
                  props.isVolume
                    ? `
                        left: -10px;
                    `
                    : ''
                }
            }
        }

        `
      : `
        & .MuiSlider-thumb {
            box-shadow: unset;
        }
        `}
`;

export interface ISequenceConcentrationProps {
  disableConcentration?: boolean;
  disabled?: boolean;
  isInspector?: boolean;
  isLegend?: boolean;
  isLowerTarget?: boolean;
  kitType: KitType | undefined | null | typeof DEFAULT_CONCENTRATION_KEY;
  marks?: boolean;
  onChange?: (value: number) => void;
  value: number | number[];
  valueLabelFormat?: string | ((value: number, index: number) => ReactNode);
}

export const SequenceConcentration = ({
  value,
  onChange = noop,
  disabled,
  valueLabelFormat,
  isLowerTarget,
  marks = true,
  disableConcentration = false,
  isInspector = false,
  isLegend = false,
  kitType,
}: ISequenceConcentrationProps) => {
  const { min, max } = useKitTypeConcentrationLimits(kitType);
  const sliderRef = useRef<HTMLSpanElement | null>(null);
  useLayoutEffect(() => {
    if (sliderRef.current) {
      const labels = sliderRef.current.querySelectorAll<HTMLSpanElement>('.MuiSlider-valueLabel');
      labels.forEach((x) => {
        const labelLeft = x.getBoundingClientRect().left;
        const parentLeft = sliderRef.current?.getBoundingClientRect().left ?? 0;
        if (labelLeft < parentLeft) {
          const prevMarginLeft = Number.parseFloat(x.style.marginLeft) || 0;
          // eslint-disable-next-line no-param-reassign
          x.style.marginLeft = `${prevMarginLeft + Math.abs(labelLeft - parentLeft)}px`;
        }
      });
    }
  }, [min, max, value]);

  return (
    <ConcentrationContainer>
      <StyledSlider
        key={`slider-${String(value)}`}
        ref={sliderRef}
        aria-labelledby="discrete-slider"
        defaultValue={value}
        $disableConcentration={disableConcentration}
        disabled={disabled}
        $isInspector={isInspector}
        $isLegend={isLegend}
        $isLowerTarget={Boolean(isLowerTarget)}
        $isMultipleValues={Array.isArray(value)}
        marks={marks}
        max={max}
        min={min}
        onChangeCommitted={(_e, v) => {
          onChange(typeof v === 'number' ? v : v[0]);
        }}
        step={isInspector ? 0.1 : 0.5}
        valueLabelDisplay={disableConcentration ? 'off' : 'on'}
        valueLabelFormat={valueLabelFormat}
      />
      <Box display="flex">
        <ConcentrationRangeBox />
      </Box>
    </ConcentrationContainer>
  );
};
