import { TemplateStatus } from '__generated__/globalTypes';
import { TTemplateDetails } from 'src/stores/sequenceEditor/types';

export function isLocked(plate: Pick<TTemplateDetails, 'status'> | null) {
  return plate?.status === TemplateStatus.LOCKED;
}

export function getErrorScoreValueText(errorScoreValue: number | null | undefined) {
  return errorScoreValue ? ` (${errorScoreValue})` : '';
}
