import React, { useState, useEffect, useMemo } from 'react';

import { getFeatureFlags } from '../api/common';

interface IFlags {
  [key: string]: Record<string, unknown>;
}

interface IFeatureFlagsContext {
  flags: IFlags | null;
}

const initialContext: IFeatureFlagsContext = {
  flags: null,
};

const FeatureFlagsContext = React.createContext(initialContext);

export function FeatureFlagsProvider({ children }: { children: React.ReactElement }) {
  const [flags, setFlags] = useState<IFlags | null>(null);

  const featureFlagsContext = useMemo(() => ({ flags }), [flags]);

  useEffect(() => {
    getFeatureFlags().then((data) => setFlags(data as IFlags));
  }, []);

  return <FeatureFlagsContext.Provider value={featureFlagsContext}>{children}</FeatureFlagsContext.Provider>;
}

export function useFeatureFlags() {
  return React.useContext(FeatureFlagsContext);
}

export default FeatureFlagsContext.Consumer;
