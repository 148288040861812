/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessPermission {
  ADMIN = "ADMIN",
  SERVICE = "SERVICE",
  WRITE = "WRITE",
}

export enum ContentFormat {
  CSV = "CSV",
  FASTA = "FASTA",
}

export enum ContentType {
  MANUAL = "MANUAL",
  UPLOAD = "UPLOAD",
}

export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export enum HardwareVersion {
  UNSUPPORTED = "UNSUPPORTED",
  V0 = "V0",
  V1 = "V1",
}

export enum InstrumentLogStatus {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  TIMED_OUT = "TIMED_OUT",
}

export enum InstrumentVersion {
  UNSUPPORTED = "UNSUPPORTED",
  V_1_2 = "V_1_2",
  V_1_3 = "V_1_3",
  V_1_3_5 = "V_1_3_5",
  V_2_0 = "V_2_0",
  V_3_0 = "V_3_0",
  V_4_0 = "V_4_0",
  V_5_0 = "V_5_0",
  V_5_1 = "V_5_1",
  V_5_3 = "V_5_3",
  V_5_4 = "V_5_4",
  V_5_6 = "V_5_6",
  V_5_7 = "V_5_7",
}

export enum InstrumentVersionsUpdateMode {
  ANY = "ANY",
  INSTRUMENT_ONLY = "INSTRUMENT_ONLY",
}

export enum KitType {
  B3_96 = 'B3_96',
  B4_96 = 'B4_96',
  B5_HMASS_96 = 'B5_HMASS_96',
  B5_HMASS_96_BULK = 'B5_HMASS_96_BULK',
  B5_HPLEX_384 = 'B5_HPLEX_384',
  B5_HPLEX_384_BULK = 'B5_HPLEX_384_BULK',
  B5_HPURE_96 = 'B5_HPURE_96',
  B5_HPURE_96_BULK = 'B5_HPURE_96_BULK',
}

export enum LoginEventType {
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

export enum ModStatus {
  ACCEPTABLE = "ACCEPTABLE",
  LOW_COUPLING = "LOW_COUPLING",
  NON_QUANTIFIABLE = "NON_QUANTIFIABLE",
  NO_MODIFICATION = "NO_MODIFICATION",
  SUCCESSFUL_COUPLING = "SUCCESSFUL_COUPLING",
}

export enum NormalizationType {
  LOWEST = "LOWEST",
  NONE = "NONE",
  TARGET = "TARGET",
}

export enum Nucleo {
  A = "A",
  B = "B",
  C = "C",
  CLICK = "CLICK",
  D = "D",
  G = "G",
  H = "H",
  K = "K",
  M = "M",
  MOD = "MOD",
  MOD_UNKNOWN = "MOD_UNKNOWN",
  N = "N",
  R = "R",
  S = "S",
  T = "T",
  UNKNOWN = "UNKNOWN",
  V = "V",
  W = "W",
  Y = "Y",
}

export enum OrganizationStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}

export enum PlateType {
  PLATE_384 = "PLATE_384",
  PLATE_96 = "PLATE_96",
}

export enum PrintJobVersion {
  V_1 = "V_1",
  V_2 = "V_2",
}

export enum ProjectType {
  GENE = "GENE",
  SYNTAX = "SYNTAX",
}

export enum ReagentUsageUnion {
  NUCLEOTIDES = "NUCLEOTIDES",
  PSP = "PSP",
  SYNTH = "SYNTH",
}

export enum RemoteUserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum RoleType {
  ADMIN = "ADMIN",
  SERVICE = "SERVICE",
  USER = "USER",
}

export enum RunReportYieldLevel {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  NORMAL = "NORMAL",
}

export enum RunSequenceStatus {
  FAILED = "FAILED",
  PASSED = "PASSED",
}

export enum RunStatus {
  ABORTED = "ABORTED",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_QUEUE = "IN_QUEUE",
  PROCESS_COMPLETED = "PROCESS_COMPLETED",
}

export enum SequenceMessageGroup {
  CLICK_POSITION = "CLICK_POSITION",
  CONCENTRATION = "CONCENTRATION",
  DIFFICULTY = "DIFFICULTY",
  LENGTH = "LENGTH",
  MOD_COUNT = "MOD_COUNT",
  MOD_UNKNOWN = "MOD_UNKNOWN",
  NUC_UNKNOWN = "NUC_UNKNOWN",
  UNKNOWN = "UNKNOWN",
}

export enum SequenceScore {
  DIFFICULT = "DIFFICULT",
  EXTREME = "EXTREME",
  IMPOSSIBLE = "IMPOSSIBLE",
  STANDARD = "STANDARD",
  UNKNOWN = "UNKNOWN",
}

export enum SequenceStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  ERROR = "ERROR",
  WARN = "WARN",
}

export enum ServiceStatus {
  ERROR = "ERROR",
  OK = "OK",
  WARNING = "WARNING",
}

export enum ServiceType {
  DB = "DB",
  IOT = "IOT",
  KAFKA = "KAFKA",
  LICENSE = "LICENSE",
  NOTIFICATION = "NOTIFICATION",
  PURITY = "PURITY",
  REDIS = "REDIS",
}

export enum Status {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export enum TemplateMessageGroup {
  NUCLEOTIDE = "NUCLEOTIDE",
  SEQUENCE = "SEQUENCE",
  TEMPLATE = "TEMPLATE",
  UNKNOWN = "UNKNOWN",
}

export enum TemplateStatus {
  ACTIVE = "ACTIVE",
  DELETE = "DELETE",
  DRAFT = "DRAFT",
  ERROR = "ERROR",
  FATAL = "FATAL",
  LOCKED = "LOCKED",
  OUTDATED = "OUTDATED",
  READY = "READY",
  WARNING = "WARNING",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  DISABLED = "DISABLED",
  INACTIVE = "INACTIVE",
}

export interface AddressInputDTO {
  country: string;
  state: string;
  city: string;
  street1: string;
  street2: string;
  zip: string;
}

export interface AttachmentDTO {
  fileName?: string | null;
  content?: string | null;
}

export interface CreateOrderDTO {
  shipAddressId?: string | null;
  shipMethodId?: string | null;
  billAddressId?: string | null;
  paymentMethodId?: string | null;
  items?: (CreateOrderItemDTO | null)[] | null;
  poNumber?: string | null;
  additionalInfo?: string | null;
  attachments?: (AttachmentDTO | null)[] | null;
}

export interface CreateOrderItemDTO {
  id?: string | null;
  quantity?: any | null;
}

export interface CreateQuoteDTO {
  shipAddressId?: string | null;
  shipMethodId?: string | null;
  billAddressId?: string | null;
  paymentMethodId?: string | null;
  items?: (CreateQuoteItemDTO | null)[] | null;
  additionalInfo?: string | null;
  attachments?: (AttachmentDTO | null)[] | null;
}

export interface CreateQuoteItemDTO {
  id?: string | null;
  quantity?: any | null;
}

export interface CustomProcessCreateDTO {
  name: string;
  data: string;
}

export interface EntityByIdDTO {
  id: string;
}

export interface LoginEventFilterDTO {
  searchText?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
  page?: PageInfoDTO | null;
  sort?: SortInfoDTO | null;
}

export interface NAddressDTO {
  country?: string | null;
  state?: string | null;
  city?: string | null;
  street1?: string | null;
  street2?: string | null;
  zip?: string | null;
  fullName?: string | null;
}

export interface NContactDTO {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  title?: string | null;
  workNumber?: string | null;
  mobileNumber?: string | null;
}

export interface OrderAddonDTO {
  idnaPlateId?: string | null;
}

export interface OrganizationUpdateDTO {
  maxLoginFailedAttempts?: number | null;
  companyName?: string | null;
  timeZoneId?: string | null;
  contact?: NContactDTO | null;
  address?: NAddressDTO | null;
}

export interface PageInfoDTO {
  page: number;
  size: number;
}

export interface QuoteAddonDTO {
  idnaPlateId?: string | null;
}

export interface RecentWorkFilterDTO {
  size: number;
  runs: boolean;
  templates: boolean;
}

export interface RunPerProjectFilterDTO {
  projectId?: string | null;
  userId?: string | null;
}

export interface SequenceInfoDTO {
  id?: string | null;
  well?: string | null;
  data: string;
  name?: string | null;
  usePlateNorm?: boolean | null;
  normalizationType?: NormalizationType | null;
  targetConcetration?: number | null;
  status: SequenceStatus;
}

export interface SortInfoDTO {
  field: string;
  direction: Direction;
}

export interface TemplateFilterDTO {
  projectId?: string | null;
  userId?: string | null;
  plateType?: PlateType | null;
  kitType?: KitType | null;
  projectType?: ProjectType | null;
}

export interface TemplateUpdateDTO {
  templateId: string;
  name?: string | null;
  normalizationType?: NormalizationType | null;
  targetConcentration?: number | null;
  sequences: SequenceInfoDTO[];
  runPurityEstimate?: boolean | null;
}

export interface TransformQuoteData {
  poNumber?: string | null;
  additionalInfo?: string | null;
  attachments?: (AttachmentDTO | null)[] | null;
}

export interface WellInfoDTO {
  well: string;
  actualConcentration: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
