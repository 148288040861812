import { AccessPermission } from '__generated__/globalTypes';
import { RoleType } from 'src/gql/graphql';
import { IProjectManage } from 'src/pages/projects/containers/projectManage';

import { hasRole } from './general';

interface IDetailsPermissions {
  // NOTE: also controls if can create template files
  canCreateRuns: boolean;
  canDownloadSampleSheet: boolean;
  canEditProject: boolean;
  canViewPrintJobDetails: boolean;
  canViewPrintJobs: boolean;
  canViewProjectDetails: boolean;
  canViewRunReportDetails: boolean;
  canViewRuns: boolean;
}

export const DEFAULT_DETAILS_PERMISSIONS: IDetailsPermissions = {
  canCreateRuns: false,
  canDownloadSampleSheet: false,
  canEditProject: false,
  canViewPrintJobDetails: false,
  canViewPrintJobs: false,
  canViewProjectDetails: false,
  canViewRunReportDetails: false,
  canViewRuns: false,
};

interface ISettingsPermissions {
  canArchiveProject: boolean;
  canManageAccess: boolean;
  canSetApprover: boolean;
  canSetOwner: boolean;
}

export const DEFAULT_SETTINGS_PERMISSIONS: ISettingsPermissions = {
  canArchiveProject: false,
  canManageAccess: false,
  canSetApprover: false,
  canSetOwner: false,
};

interface IProjectListPermissions {
  canCreateProject: boolean;
  canFilterProject: boolean;
  canSearchProject: boolean;
  canSortProjects: boolean;
  canViewAllProjects: boolean;
  canViewProjectDetails: boolean;
  canViewProjectSettings: boolean;
}

export const DEFAULT_PROJECT_LIST_PERMISSIONS: IProjectListPermissions = {
  canCreateProject: false,
  canFilterProject: false,
  canSearchProject: false,
  canSortProjects: false,
  canViewAllProjects: false,
  canViewProjectDetails: false,
  canViewProjectSettings: false,
};

export interface ICustomProcessesPermissions {
  canCreateCustomProcesses: boolean;
}

export const DEFAULT_CUSTOM_PROCESSES_PERMISSIONS: ICustomProcessesPermissions = {
  // TODO: should be false by default
  canCreateCustomProcesses: true,
};

interface IProjectsPermissions {
  projects: {
    details: IDetailsPermissions;
    list: IProjectListPermissions;
    settings: ISettingsPermissions;
    customProcesses: ICustomProcessesPermissions;
  };
}

export interface IGeneralProjectPermissions
  extends IProjectListPermissions,
    ISettingsPermissions,
    IDetailsPermissions {}

const DEFAULT_PERMISSIONS = {
  projects: {
    customProcesses: DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
    details: DEFAULT_DETAILS_PERMISSIONS,
    list: DEFAULT_PROJECT_LIST_PERMISSIONS,
    settings: DEFAULT_SETTINGS_PERMISSIONS,
  },
};

export function getPermissionForProject(user: IUser | null | undefined, project: IProjectManage): IProjectsPermissions {
  if (!user) {
    return DEFAULT_PERMISSIONS;
  }
  const userInMembers = project?.members?.find((x) => x?.id === user.id);
  const isAdmin = project?.admin?.id === user.id || hasRole(AccessPermission.ADMIN, user);
  const isMember = userInMembers?.accessPermission === AccessPermission.WRITE;
  const isOwner = project?.owner?.id === user.id;

  if (isAdmin || isOwner) {
    return {
      projects: {
        customProcesses: DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
        details: {
          ...DEFAULT_DETAILS_PERMISSIONS,
          canCreateRuns: true,
          canDownloadSampleSheet: true,
          canEditProject: true,
          canViewPrintJobDetails: true,
          canViewPrintJobs: true,
          canViewProjectDetails: true,
          canViewRunReportDetails: true,
          canViewRuns: true,
        },
        list: {
          ...DEFAULT_PROJECT_LIST_PERMISSIONS,
          canCreateProject: true,
          canFilterProject: true,
          canSearchProject: true,
          canSortProjects: true,
          canViewAllProjects: true,
          canViewProjectDetails: true,
          canViewProjectSettings: true,
        },
        settings: {
          ...DEFAULT_SETTINGS_PERMISSIONS,
          canArchiveProject: true,
          canManageAccess: user.role.type !== RoleType.SERVICE,
          canSetApprover: true,
          canSetOwner: true,
        },
      },
    };
  }

  if (isMember) {
    return {
      projects: {
        customProcesses: DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
        details: {
          ...DEFAULT_DETAILS_PERMISSIONS,
          canCreateRuns: true,
          canDownloadSampleSheet: true,
          canViewPrintJobDetails: true,
          canViewPrintJobs: true,
          canViewProjectDetails: true,
          canViewRunReportDetails: true,
          canViewRuns: true,
        },
        list: DEFAULT_PROJECT_LIST_PERMISSIONS,
        settings: DEFAULT_SETTINGS_PERMISSIONS,
      },
    };
  }

  if (!project.restrictedAccess) {
    return {
      projects: {
        customProcesses: DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
        details: {
          ...DEFAULT_DETAILS_PERMISSIONS,
          canCreateRuns: true,
          canViewPrintJobs: true,
          canViewProjectDetails: true,
          canViewRuns: true,
        },
        list: DEFAULT_PROJECT_LIST_PERMISSIONS,
        settings: DEFAULT_SETTINGS_PERMISSIONS,
      },
    };
  }

  return DEFAULT_PERMISSIONS;
}
