import cloneDeep from 'lodash-es/cloneDeep';
import range from 'lodash-es/range';

import { NormalizationType, SequenceScore } from '__generated__/globalTypes';

type TRGB = [number, number, number];

const TARGET_CONCENTRATION_OPTIONS = [
  { color: '#d4e5f1', label: '1.0', value: 1 },
  { color: '#b3d6f0', label: '1.5', value: 1.5 },
  { color: '#86c3e8', label: '2.0', value: 2 },
  { color: '#4eafdf', label: '2.5', value: 2.5 },
  { color: '#1b98d4', label: '3.0', value: 3 },
  { color: '#0b83c6', label: '3.5', value: 3.5 },
  { color: '#016aae', label: '4.0', value: 4 },
  { color: '#005b97', label: '4.5', value: 4.5 },
  { color: '#0d346d', label: '5.0', value: 5 },
];

const STEP = 0.5;

function getTargetConcentrationOptions(min: number | undefined, max: number | undefined) {
  if (min === undefined || max === undefined) {
    return [];
  }
  return range(min, max + STEP, STEP).map((x) => ({ label: x.toFixed(1), value: x }));
}

const getColorByNucleotide = (nucleotide: string) => {
  const formattedNucleotide = nucleotide.toLowerCase();
  switch (formattedNucleotide) {
    case 'a':
      return '#05a0c6';
    case 'c':
      return '#be4441';
    case 'g':
      return '#74b341';
    case 't':
      return '#f3bf1a';
    case 'n':
      return '#b4b4b4';
    case 'mod_ispc3':
    case 'mod_i6_tamn':
    case 'mod_ideoxyi':
    case 'mod_ideoxyu':
    case 'mod_ime_dc':
    case 'mod_ibiodt':
    case 'mod_ifluort':
    case 'mod_idevdnas':
    case 'mod_3devdnas':
    case 'u':
    case 'mod':
      return '#ff01ff';
    case 'unknown':
    case 'mod_unknown':
    default:
      return '#e7e7e7';
  }
};

function rgbToHex(rgb: number[]) {
  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
}

function hexToRgb(hex: string): TRGB | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

function pickHex(color1: TRGB | null, color2: TRGB | null, weight: number) {
  const w1 = weight;
  const w2 = 1 - w1;
  if (!color1 || !color2) return [0, 0, 0];
  const rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

export const UNAVAILABLE_CONCENTRATION_COLOR = '#888b8d';

const roundFloor = (num: number) => {
  const f = Math.floor(num);
  if (num - f < STEP) {
    return f;
  }
  return f + STEP;
};

const getColorByConcentration = (concentration?: number, min?: number, max?: number) => {
  if (!concentration || typeof min !== 'number' || !max) {
    return UNAVAILABLE_CONCENTRATION_COLOR;
  }

  const roundedConcentration = roundFloor(concentration);

  const targetConcentrationOptions = getTargetConcentrationOptions(min, max);
  const targetConcentrationOption = targetConcentrationOptions.find((x) => x.value === roundedConcentration);
  if (!targetConcentrationOption) {
    return UNAVAILABLE_CONCENTRATION_COLOR;
  }
  const weight = (roundedConcentration - min) / (max - min);

  const lookupValue =
    weight *
      (TARGET_CONCENTRATION_OPTIONS[TARGET_CONCENTRATION_OPTIONS.length - 1].value -
        TARGET_CONCENTRATION_OPTIONS[0].value) +
    TARGET_CONCENTRATION_OPTIONS[0].value;

  const foundConcentrationIndex = TARGET_CONCENTRATION_OPTIONS.findIndex(({ value }) => value >= lookupValue);
  if (foundConcentrationIndex === -1) {
    // if not found, it is mean that concentration > 5
    return TARGET_CONCENTRATION_OPTIONS[TARGET_CONCENTRATION_OPTIONS.length - 1].color;
  }
  const startValue = TARGET_CONCENTRATION_OPTIONS[foundConcentrationIndex - 1] || {
    color: '#d4e5f1',
    value: 0.5,
  };
  const endValue = TARGET_CONCENTRATION_OPTIONS[foundConcentrationIndex];

  return (
    rgbToHex(
      pickHex(
        hexToRgb(startValue.color),
        hexToRgb(endValue.color),
        1 - (lookupValue - startValue.value) / (endValue.value - startValue.value),
      ),
    ) || TARGET_CONCENTRATION_OPTIONS[foundConcentrationIndex].color
  );
};

// NOTE: see
// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export function getConcentrationTextColorBasedOnBackground(hex: string) {
  const rgb = hexToRgb(hex);
  if (!rgb) {
    return 'initial';
  }
  const [red, green, blue] = rgb;
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? '#000000' : '#ffffff';
}

const getSequenceStatusLabel = (status?: string | null) => {
  switch (status) {
    case SequenceScore.STANDARD:
      return 'runs.plateEditor.statuses.active';
    case SequenceScore.DIFFICULT:
      return 'runs.plateEditor.statuses.warn';
    case SequenceScore.EXTREME:
      return 'runs.plateEditor.statuses.warn';
    case SequenceScore.IMPOSSIBLE:
      return 'runs.plateEditor.statuses.error';
    default:
      return undefined;
  }
};

const ROW_NAMES_96 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const ROW_NAMES_384 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];

export const StackSequencesService = {
  add(id: string, sequence: IPartialSequence) {
    const wellName = sequence?.well;
    if (wellName) {
      if (!this.sequenceStack.has(id)) {
        this.sequenceStack.set(id, new Map());
      }

      if (!this.sequenceStack.get(id)?.has(wellName)) {
        this.sequenceStack.get(id)?.set(wellName, []);
      }

      this.sequenceStack.get(id)?.get(wellName)?.push(cloneDeep(sequence));
    }
  },
  clearStack(id: string, wellName: string | null | undefined) {
    if (this.sequenceStack.has(id) && wellName) {
      if (this.sequenceStack.get(id)?.has(wellName)) {
        this.sequenceStack.get(id)?.set(wellName, []);
      }
    }
  },
  clearStackById(id: string) {
    if (this.sequenceStack.has(id)) {
      this.sequenceStack.set(id, new Map());
    }
  },
  delete(id: string, wellName: string | null | undefined) {
    if (!wellName) {
      return null;
    }

    const currentStack = this.sequenceStack?.get(id);
    const currentWell = currentStack?.get(wellName);
    const length = currentWell?.length;
    if (currentStack && currentWell && length) {
      const lastData = currentWell[currentWell.length - 1];
      currentWell.length -= 1;
      return lastData;
    }

    return null;
  },
  getStack(id: string, wellName: string | null | undefined) {
    if (!wellName) {
      return null;
    }

    return this.sequenceStack?.get(id)?.get(wellName) || null;
  },
  sequenceStack: new Map<string, Map<string, IPartialSequence[]>>(),
};

function markSelectedWells(isSelected: boolean | undefined) {
  return isSelected ? '2px solid #050734' : 'none';
}

function calcNumberOfNucs(nucChunks: (string | null)[] | undefined | null) {
  return (nucChunks ?? []).filter((chunk, i, arr) => chunk?.length === 1 || (i !== 0 && i !== arr.length - 1)).length;
}

function isDisabledNormalization(normalizationType: string | null | undefined) {
  return normalizationType === NormalizationType.NONE;
}

export {
  calcNumberOfNucs,
  getColorByConcentration,
  getColorByNucleotide,
  getSequenceStatusLabel,
  isDisabledNormalization,
  markSelectedWells,
  ROW_NAMES_96,
  ROW_NAMES_384,
  TARGET_CONCENTRATION_OPTIONS,
};
