import * as React from 'react';

import RestrictedRoute from 'src/components/routes/RestrictedRoute';
import { ERoutes } from 'src/config/routes';

import DownloadsTable from './components/DownloadsTable';

export const DownloadsPage = () => {
  return <RestrictedRoute exact path={ERoutes.DOWNLOADS} component={DownloadsTable} />;
};
