import { FormattedTime, DF } from '@dna-script-inc/shared-ui-library';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';

function useGetScheduledStartDateColumn<
  T extends {
    scheduledStartDate?: string | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();
  const { userProfile } = useUserContext();

  return {
    Cell: ({ row }) => (
      <FormattedTime
        date={row.original.scheduledStartDate ?? ''}
        timeZone={userProfile?.timeZone?.id}
        format={DF.SHORT_DATE_TIME_FORMAT}
      />
    ),
    Header: () => <Translate id="projects.detail.table.runs.startDate" testid="start-date-header" />,
    accessorFn: (row) => row.scheduledStartDate,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.detail.table.runs.startDate'),
  };
}

export default useGetScheduledStartDateColumn;
