/* eslint-disable react/require-default-props */
import { first } from 'lodash-es';
import React from 'react';

import { useTranslation } from 'src/containers/i18n';
import { useTemplates } from 'src/hooks/useTemplateIds';
import PrintNowModal from 'src/pages/runs/pages/scheduler/components/PrintNowModal';

interface IReprintWellsModal {
  onCancel: () => void;
  onCreateRunSuccess: (projectRun: ICreateProjectRun) => void;
  onSubmit: () => void;
  open: boolean;
  projectId: string;
  versions?: string[];
}

function ReprintWellsModal({ open, projectId, onCreateRunSuccess, onSubmit, onCancel, versions }: IReprintWellsModal) {
  const { templates } = useTemplates(projectId);
  const t = useTranslation();

  const firstTemplate = first(templates);

  if (!firstTemplate) {
    return <div>{t('common.alert.errors.title')}</div>;
  }

  return (
    <PrintNowModal
      isOpen={open}
      templateId={firstTemplate.id}
      kitType={firstTemplate.kitType}
      projectId={projectId}
      onCreateRunSuccess={onCreateRunSuccess}
      onSubmit={onSubmit}
      onCancel={onCancel}
      versions={versions}
      isGeneProject
      isReprintWells
    />
  );
}

export default ReprintWellsModal;
