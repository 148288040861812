/* eslint-disable consistent-return */
import { useMemo } from 'react';

import { SequenceScore } from '__generated__/globalTypes';
import { useUserContext } from 'src/hooks';

import { LOCALLY_CHANGED } from './constants';
import { areWellStatesDifferent } from './helpers';
import { getCurrentSequencesFromSequenceHistory, getCurrentWellState, getLastWellStateSaved } from './selectors';
import useSequenceEditorStore from './sequenceEditorStore';
import { TSequence, TTemplateDetails } from './types';

export const useHasWellStateChanged = (well: string) => {
  const currentWellState = useSequenceEditorStore(getCurrentWellState(well));
  const lastWellState = useSequenceEditorStore(getLastWellStateSaved(well));
  return areWellStatesDifferent(currentWellState, lastWellState);
};

export const useWellSequenceScore = (well: string | undefined) => {
  const currentWellState = useSequenceEditorStore(getCurrentWellState(well ?? ''));
  const hasWellStateChanged = useHasWellStateChanged(well ?? '');
  if (!well) {
    return SequenceScore.UNKNOWN;
  }
  if (hasWellStateChanged) {
    return LOCALLY_CHANGED;
  }
  return currentWellState?.score || SequenceScore.UNKNOWN;
};

const useCurrentSequences = () => {
  const { sequencesHistory } = useSequenceEditorStore();
  const currentSequences = useMemo(() => getCurrentSequencesFromSequenceHistory(sequencesHistory), [sequencesHistory]);
  return currentSequences;
};

export const useSelectedSequenceState = () => {
  const { sequencesHistory, selectedWells } = useSequenceEditorStore();
  if (!selectedWells.length) {
    return null;
  }
  const firstSelectedSequence = sequencesHistory[selectedWells[0]];
  if (!firstSelectedSequence) {
    return null;
  }
  const { states, currentIndex } = firstSelectedSequence;
  return states[currentIndex];
};

export const useIsTemplateOwner = () => {
  const { templateDetails } = useSequenceEditorStore();
  const { userProfile } = useUserContext();
  return templateDetails?.creator?.id === userProfile?.id;
};

type TUseCurrentTemplateResult = TTemplateDetails & { sequences: TSequence[]; emptySequences: TSequence[] };

export const useCurrentTemplate = (): TUseCurrentTemplateResult | undefined => {
  const currentSequences = useCurrentSequences();
  const { templateDetails, loading } = useSequenceEditorStore();
  if (!templateDetails || loading) {
    return;
  }
  return {
    ...templateDetails,
    emptySequences: templateDetails.emptySequences ?? [],
    sequences: currentSequences,
  };
};

export const useIsTemplateStateChanged = () => {
  const currentTemplate = useCurrentTemplate();
  const { templateDetailsLastSave } = useSequenceEditorStore();
  const areTemplateSettingsDifferent = currentTemplate?.name !== templateDetailsLastSave?.name;
  if (areTemplateSettingsDifferent) {
    return true;
  }
  if (!currentTemplate || !templateDetailsLastSave) return false;
  const { sequences: currentSequences } = currentTemplate;
  const { sequences: lastSavedSequences } = templateDetailsLastSave;
  return currentSequences.some((currentSequence) => {
    const lastSavedCorrespondingSequence = lastSavedSequences.find((seq) => seq.well === currentSequence.well);
    return areWellStatesDifferent(currentSequence, lastSavedCorrespondingSequence);
  });
};
