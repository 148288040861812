import { DateTime } from '@dna-script-inc/shared-ui-library';
import React, { FC, Suspense } from 'react';
import { Calendar as BigCalendar, CalendarProps } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { CentrifugationType } from 'src/gql/graphql';

import luxonLocalizer from './localizer';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

export interface ICalendarItem {
  color?: string;
  draggable?: boolean;
  end: Date;
  endDate: DateTime;
  instrumentId?: string;
  instrumentName?: string;
  preselected?: boolean;
  start: Date;
  startDate: DateTime;
  suggested?: boolean;
  title: string;
  textColor?: string;
  customProcessDefinitionFileId?: string;
  centrifugationType?: CentrifugationType;
  active?: boolean;
}

export type TCalendarProps = Omit<CalendarProps<ICalendarItem, object>, 'localizer'>;

export const Calendar: FC<TCalendarProps> = (props) => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <BigCalendar
        dayLayoutAlgorithm="no-overlap"
        endAccessor="endDate"
        formats={{
          dayFormat: (date, _c, l) => {
            if (!l) {
              return '';
            }
            return l.format(date, 'dddd D');
          },
        }}
        localizer={luxonLocalizer}
        showMultiDayTimes
        startAccessor="startDate"
        step={15}
        timeslots={4}
        {...props}
      />
    </Suspense>
  );
};

export const DragAndDropCalendar = withDragAndDrop<ICalendarItem, object>(Calendar);

export default Calendar;
