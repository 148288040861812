/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useLazyQuery } from '@apollo/client';
import { useDateFormat, DateTime, DateUtils } from '@dna-script-inc/shared-ui-library';
import debounce from 'lodash-es/debounce';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, FC } from 'react';
import styled, { css } from 'styled-components';

import { RunStatus } from '__generated__/globalTypes';
import { DragAndDropCalendar, ICalendarItem } from 'src/components/calendar/Calendar';
import { useToast } from 'src/containers/toast';
import { RunSchedulerQuery } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import useMounted from 'src/hooks/useMounted';
import { useToggle } from 'src/hooks/useToggle';
import { useRunContext } from 'src/pages/runs/container';
import { useProjectSchedulerContext } from 'src/pages/runs/pages/scheduler/container';
import { QUERY_RUN_SCHEDULE } from 'src/services/gql/queries/runs';
import { getContrastTextColor, isNotEmpty } from 'src/utils/ui';

import AddForm from './AddForm';
import ConfirmationModal from './ConfirmationModal';

import luxonLocalizer from '../../../../../components/calendar/localizer';

const HEADER_OFFSET = 80;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  margin-left: 20px;
`;
const Popover = styled.div`
  width: 460px;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  z-index: 1300;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  ${(props: { open: boolean }) =>
    !props.open &&
    css`
      display: none;
    `}
`;

const InstrumentsCalendar: FC = () => {
  const showToast = useToast();
  const { toggle, open, close, isOpen } = useToggle(true);
  const { userProfile } = useUserContext();

  const { dateLocal } = useDateFormat(userProfile?.timeZone?.id);

  const isMounted = useMounted();
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const { setSuggestedSlot, suggestedSlot, templateDetail, printDetail, setPrintDetail } = useRunContext();
  const currentViewRef = useRef<'week' | 'day' | 'month'>('week');
  const { instruments, getColor, setSchedule } = useProjectSchedulerContext();
  const [calendarViewDate, setCalendarViewDate] = useState(new Date());
  const [showConfirmation, toggleConfirmation] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ICalendarItem | null>({
    color: '',
    end: DateUtils.dateNow().plus({ minutes: 3 }).toJSDate(),
    endDate: DateUtils.dateNow().plus({ minutes: 3 }),
    instrumentId: instruments[0]?.id,
    preselected: true,
    start: DateUtils.dateNow().plus({ minutes: 3 }).toJSDate(),
    startDate: DateUtils.dateNow().plus({ minutes: 3 }),
    suggested: true,
    title: '',
  });
  const [startDate, setStartDate] = useState<DateTime>(DateUtils.dateNow().startOf(currentViewRef.current));
  const [endDate, setEndDate] = useState<DateTime>(DateUtils.dateNow().endOf(currentViewRef.current));
  const [data, setData] = useState<RunSchedulerQuery>({ getRunSchedule: null });

  const handleChange = useCallback(
    (newItem: { endDate?: DateTime; instrumentId?: string; startDate?: DateTime }) => {
      setSelectedItem((currentSelectedItem) => {
        if (currentSelectedItem) {
          if (newItem.startDate && newItem.startDate !== currentSelectedItem.startDate) {
            setCalendarViewDate(newItem.startDate.toJSDate());
          }

          return {
            ...currentSelectedItem,
            ...newItem,
          };
        }
        return currentSelectedItem;
      });
    },
    [setSelectedItem, setCalendarViewDate],
  );

  const instrumentsMap = useMemo(() => {
    return new Map(instruments.map(({ id, name }) => [id, name]));
  }, [instruments]);

  const [loadRunSchedule] = useLazyQuery(QUERY_RUN_SCHEDULE, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setData(response);
    },
  });

  const setPopoverPosition = useCallback(() => {
    const eventEls = document.querySelectorAll('.rbc-addons-dnd-resizable');
    if (eventEls.length) {
      const firstEventEl = eventEls[0].parentElement as HTMLElement;
      const lastEventEl = eventEls[eventEls.length - 1].parentElement as HTMLElement;
      const firstElBox = firstEventEl.getBoundingClientRect();
      const lastElBox = lastEventEl.getBoundingClientRect();
      const { left } = firstElBox;
      const { right } = lastElBox;
      const width = right - left;

      if (popoverRef.current) {
        popoverRef.current.style.left = `${left}px`;
        if (left + width / 2 > window.innerWidth / 2) {
          const offset = document.body.offsetWidth - right + width;
          popoverRef.current.style.right = `${offset + width >= window.innerWidth ? 0 : offset}px`;
          popoverRef.current.style.left = 'auto';
        } else {
          const offset = right;
          popoverRef.current.style.right = 'auto';
          popoverRef.current.style.left = `${offset + width >= window.innerWidth ? 0 : offset}px`;
        }
      }
    }
  }, []);

  const debouncedSetPopoverPosition = useMemo(() => {
    return debounce(setPopoverPosition, 300);
  }, [setPopoverPosition]);

  const openPopover = () => {
    open();
    setPopoverPosition();
  };

  const events = useMemo(() => {
    return (data?.getRunSchedule || []).filter(isNotEmpty).map((item) => {
      const color = getColor(item.instrumentId!);

      return {
        color,
        endDate: dateLocal(item.status === RunStatus.IN_PROGRESS ? item.endTime! : item.scheduledEndTime!),
        instrumentId: item?.instrumentId ?? undefined,
        instrumentName: item?.instrumentId ? instrumentsMap.get(item.instrumentId) : undefined,
        startDate: dateLocal(item.status === RunStatus.IN_PROGRESS ? item.startTime! : item.scheduledStartTime!),
        textColor: getContrastTextColor(color),
        title: item.templateName!,
      };
    });
  }, [data?.getRunSchedule, getColor, dateLocal, instrumentsMap]);

  const handleDatesChange = useCallback(
    (range: Date[] | { end: Date; start: Date }) => {
      if (Array.isArray(range)) {
        setStartDate(DateTime.fromJSDate(range[0]));
        if (range.length === 1) {
          setEndDate(DateTime.fromJSDate(range[0]).plus({ hours: 24 }));
        } else {
          setEndDate(DateTime.fromJSDate(range[range.length - 1]));
        }
      } else {
        setStartDate(DateTime.fromJSDate(range.start));
        setEndDate(DateTime.fromJSDate(range.end));
      }
      close();
    },
    [close],
  );

  const reloadData = useCallback(() => {
    return loadRunSchedule({
      variables: {
        endDate: endDate.toISO(),
        instrumentIds: instruments.map((instrument) => instrument.id),
        startDate: startDate.toISO(),
      },
    });
  }, [loadRunSchedule, instruments, startDate, endDate]);

  const handleConfirm = useCallback(() => {
    reloadData();
    setSuggestedSlot(null);
    setSaved(true);
    setTimeout(() => {
      toggleConfirmation(true);
    }, 300);
  }, [reloadData, setSuggestedSlot, setSaved]);

  useEffect(() => {
    if (isMounted) {
      reloadData();
    }
  }, [reloadData, isMounted, startDate, endDate]);

  useEffect(() => {
    const schedule = (data?.getRunSchedule || []).filter(isNotEmpty).map((item) => {
      return {
        end: item.status === RunStatus.IN_PROGRESS ? item.endTime! : item.scheduledEndTime!,
        instrumentId: item.instrumentId!,
        start: item.status === RunStatus.IN_PROGRESS ? item.startTime! : item.scheduledStartTime!,
      };
    });
    setSchedule(schedule);
  }, [setSchedule, data]);

  const calendarEvents = useMemo(() => {
    return [...events, ...(selectedItem ? [selectedItem] : [])].map((item) => ({
      ...item,
      end: dateLocal(item.endDate.toISO() ?? '').toJSDate(),
      start: dateLocal(item.startDate.toISO() ?? '').toJSDate(),
    }));
  }, [dateLocal, events, selectedItem]);

  const formSelectedItem = useMemo(() => {
    const { endDate: selectedEndDate, startDate: selectedStartDate } = selectedItem ?? {
      endDate: DateUtils.dateNow(),
      startDate: DateUtils.dateNow(),
    };
    return {
      color: '#ccc',
      end: selectedEndDate.toJSDate(),
      endDate: selectedEndDate,
      instrumentId: selectedItem?.instrumentId ?? '',
      start: selectedStartDate.toJSDate(),
      startDate: selectedStartDate,
      title: '',
    };
  }, [selectedItem]);

  useLayoutEffect(() => {
    if (selectedItem) {
      setPopoverPosition();

      window.addEventListener('resize', debouncedSetPopoverPosition);
    }

    return () => window.removeEventListener('resize', setPopoverPosition);
  }, [debouncedSetPopoverPosition, selectedItem, setPopoverPosition]);

  useEffect(() => {
    if (isMounted) {
      setStartDate(DateTime.fromJSDate(calendarViewDate).startOf(currentViewRef.current));
      setEndDate(DateTime.fromJSDate(calendarViewDate).endOf(currentViewRef.current));
    }
  }, [isMounted, calendarViewDate, setStartDate, setEndDate]);

  useEffect(() => {
    if (
      isMounted &&
      !suggestedSlot &&
      (selectedItem?.endDate?.diff(selectedItem?.startDate ?? 0, 'minutes').milliseconds ?? 0) > 5
    ) {
      setSuggestedSlot(selectedItem);
    }
  }, [isMounted, suggestedSlot, selectedItem, setSuggestedSlot]);

  useLayoutEffect(() => {
    setPopoverPosition();
  }, [data, setPopoverPosition]);

  useLayoutEffect(() => {
    const eventEl = document.querySelector('.rbc-addons-dnd-resizable');
    if (eventEl) {
      const { y } = eventEl.getBoundingClientRect();
      window.scrollTo(window.scrollX, y - HEADER_OFFSET);
    }
  }, []);

  return (
    <Wrapper>
      <DragAndDropCalendar
        selectable
        events={calendarEvents}
        localizer={luxonLocalizer}
        date={calendarViewDate}
        defaultView={currentViewRef.current}
        onEventDrop={({ event, start, end }) => {
          const eventEndDate = DateTime.fromISO(String(end));
          const eventStartDate = DateTime.fromISO(String(start));
          const changedEvent: ICalendarItem = {
            ...event,
            endDate: eventEndDate,
            startDate: eventStartDate,
          };

          if (DateUtils.dateNow() > eventStartDate) {
            showToast({
              isError: true,
              textToTranslate: 'calendar.invalidSlot.text',
              title: 'calendar.invalidSlot.title',
            });
            return;
          }

          setSelectedItem(changedEvent);
          openPopover();
        }}
        onNavigate={setCalendarViewDate}
        draggableAccessor={(event: ICalendarItem) => Boolean(event.suggested)}
        views={['month', 'week', 'day']}
        onView={(view) => {
          currentViewRef.current = view as 'week' | 'day' | 'month';
        }}
        onRangeChange={handleDatesChange}
        eventPropGetter={(event: ICalendarItem) => {
          if (event.suggested) {
            return {
              className: 'suggested',
              style: {
                backgroundColor: 'rgba(33,150,243,0.2)',
                border: '2px dashed #aaa',
                color: '#333',
              },
            };
          }
          return {
            style: {
              backgroundColor: event.color,
              border: '1px solid #fff',
              color: event.textColor,
            },
          };
        }}
        onSelectEvent={(event: ICalendarItem) => {
          if (event.suggested) {
            openPopover();
          }
        }}
        titleAccessor={({ title, instrumentName }: ICalendarItem) => {
          return `${title}\n${instrumentName ?? ''}`;
        }}
        onDragStart={close}
        onSelectSlot={(slots) => {
          if (!saved) {
            if (DateUtils.dateNow() > DateTime.fromJSDate(slots.start)) {
              showToast({
                isError: true,
                textToTranslate: 'calendar.invalidSlot.text',
                title: 'calendar.invalidSlot.title',
              });
              return;
            }

            setSelectedItem((currentSelectedItem) => {
              if (currentSelectedItem) {
                return {
                  ...currentSelectedItem,
                  endDate: DateTime.fromJSDate(slots.start).plus(
                    currentSelectedItem.endDate.diff(currentSelectedItem.startDate),
                  ),
                  startDate: DateTime.fromJSDate(slots.start),
                };
              }

              return currentSelectedItem;
            });
            openPopover();
          }
        }}
      />
      {showConfirmation && <ConfirmationModal printDetail={printDetail} onClose={() => toggleConfirmation(false)} />}
      {templateDetail && (
        <Popover open={isOpen} ref={popoverRef}>
          <AddForm
            templateId={templateDetail.id}
            kitType={templateDetail.kitType}
            onCreateRunSuccess={setPrintDetail}
            getColor={getColor}
            instruments={instruments}
            item={formSelectedItem}
            onConfirmed={() => {
              toggle();
              handleConfirm();
              setSelectedItem(null);
            }}
            onChange={handleChange}
            onCancel={toggle}
            onCreateRunFailure={toggle}
          />
        </Popover>
      )}
    </Wrapper>
  );
};

export default InstrumentsCalendar;
