/* eslint-disable max-len */
const translations = {
  '': '',
  'account.address': 'Address',
  'account.city': 'City',
  'account.confirmPassword': 'Confirm password',
  'account.create.member.add.name': 'Member name',
  'account.currentPassword': 'Current password',
  'account.fullName': 'Full Name',
  'account.modal.changePassword.buttons.cancel': 'Cancel',
  'account.modal.changePassword.buttons.change': 'Change password',
  'account.modal.changePassword.buttons.generatePassword': 'Generate password',
  'account.modal.changePassword.buttons.submit': 'Submit',
  'account.modal.changePassword.title': 'Change Password',
  'account.modal.changePassword.warning': 'Please, update your password',
  'account.modal.changePhoto.buttons.cancel': 'Cancel',
  'account.modal.changePhoto.buttons.change': 'Choose a New Photo',
  'account.modal.changePhoto.title': 'Update Photo',
  'account.modal.confirm.buttons.userDeactivate': 'Deactivate user',
  'account.modal.resetPassword.title': 'Reset Password',
  'account.newPassword': 'New password',
  'account.password.toast.text': 'Password updated.',
  'account.password.toast.title': 'Success',
  'account.phoneMobile': 'Mobile Number',
  'account.phoneWork': 'Work Number',
  'account.state': 'State',
  'account.street1': 'Address 1',
  'account.street2': 'Address 2',
  'account.teams': 'Teams',
  'account.teams.confirm.buttons.userRemove': 'Remove from team',
  'account.title': 'Title',
  'account.user.detail.error.title': 'Error Updating User',
  'account.user.detail.profile': 'Profile',
  'account.zip': 'Zip Code',
  'accounts.permissions.title.instruments': 'Instruments',
  'accounts.permissions.title.organization': 'Organization',
  'accounts.permissions.title.pages': 'Pages Access',
  'accounts.permissions.title.plateEditor': 'Plates page',
  'accounts.permissions.title.projectDetails': 'Project Detail Page',
  'accounts.permissions.title.projectSettings': 'Projects - Settings Page',
  'accounts.permissions.title.projectsList': 'Projects - List Page',
  'accounts.permissions.title.runReports': 'Reports',
  'accounts.permissions.title.sequenceFiles': 'New Run',
  'accounts.permissions.title.store': 'Ordering',
  'accounts.permissions.title.teams': 'Teams',
  'accounts.permissions.title.users': 'Users',
  'accounts.success.reactivate': ' reactivated',
  'accounts.success.reactivate.title': 'Success',
  'accounts.teams.add.error.title': 'Error Creating Team',
  'accounts.teams.add.success.text': 'Team created.',
  'accounts.teams.add.user.error.title': 'Error adding user to team',
  'accounts.teams.add.user.success': 'User was added  to team successfully',
  'accounts.teams.list.add.button': 'Add',
  'accounts.teams.list.addMember': 'Add Member',
  'accounts.teams.list.addTeam': 'Add team',
  'accounts.teams.list.headCells.admin': 'Team Admin',
  'accounts.teams.list.headCells.date': 'Last updated',
  'accounts.teams.list.headCells.name': 'Team Name',
  'accounts.teams.list.headCells.status': 'Status',
  'accounts.teams.list.headCells.users': 'Members',
  'accounts.teams.list.hideBtn': 'Hide',
  'accounts.teams.list.noData': 'No teams available',
  'accounts.teams.list.option.delete': 'Archive Team',
  'accounts.teams.list.option.manage': 'Manage Team',
  'accounts.teams.list.option.restore': 'Restore Team',
  'accounts.teams.list.option.view': 'View Team',
  'accounts.teams.list.search.placeholder': 'Search team',
  'accounts.teams.list.status.active': 'Active',
  'accounts.teams.list.status.deleted': 'Blocked',
  'accounts.teams.list.tab.active': 'Active',
  'accounts.teams.list.tab.inactive': 'Inactive',
  'accounts.teams.remove.user.error.title': 'Error removing user from team',
  'accounts.teams.remove.user.success': 'User removed from team.',
  'accounts.teams.success.archive': 'Team disabled.',
  'accounts.teams.success.reactivate': 'Team reactivated.',
  'accounts.teams.success.update': 'Team has been updated successfully.',
  'accounts.timezone.modal.warning.body':
    'Your browser time zone is different from your account - {serverTimeZone}, so time data can differ. You may need to update this configuration.',
  'accounts.timezone.modal.warning.btn': 'Configure Time Zone',
  'accounts.timezone.modal.warning.title': 'Time Zone Warning',
  'accounts.user.detail.action.cancel': 'Cancel',
  'accounts.user.detail.action.changePassword': 'Change Password',
  'accounts.user.detail.action.resetPassword': 'Reset Password',
  'accounts.user.detail.action.save': 'Save',
  'accounts.user.detail.contact': 'Contact information',
  'accounts.user.detail.delete.error.title': 'Error deleting user',
  'accounts.user.detail.delete.text': ' blocked',
  'accounts.user.detail.delete.title': 'Success',
  'accounts.user.detail.form.city': 'City',
  'accounts.user.detail.form.firstName': 'First name',
  'accounts.user.detail.form.lastName': 'Last name',
  'accounts.user.detail.form.role': 'Role',
  'accounts.user.detail.form.state': 'State',
  'accounts.user.detail.form.street1': 'Address line 1',
  'accounts.user.detail.form.street2': 'Address line 2',
  'accounts.user.detail.form.title': 'Title',
  'accounts.user.detail.form.username': 'Email address',
  'accounts.user.detail.form.zip': 'Zip Code',
  'accounts.user.detail.info.createdAt': 'Date Joined',
  'accounts.user.detail.info.projects': 'Projects',
  'accounts.user.detail.info.role': 'Role',
  'accounts.user.detail.info.status': 'Status',
  'accounts.user.detail.info.teams': 'Teams',
  'accounts.user.detail.info.updatedAt': 'Last modified',
  'accounts.user.detail.personal': 'Personal information',
  'accounts.user.detail.remote.restore.success': 'Remote Organization switched successfully',
  'accounts.user.detail.remote.update.success': 'Original Organization restored successfully',
  'accounts.user.detail.tab.details': 'Details',
  'accounts.user.detail.tab.myteams': 'My Teams',
  'accounts.user.detail.tab.permissions': 'Permissions',
  'accounts.user.detail.tab.remote': 'Remote Organizations',
  'accounts.user.detail.tab.remote.body':
    "Select an organization and press 'SET ACTIVE' to refresh \n the page and switch organizations",
  'accounts.user.detail.tab.remote.restore.btn': 'Set Original Organization',
  'accounts.user.detail.tab.remote.select.btn': 'Set Active',
  'accounts.user.detail.tab.remote.select.label': 'Active Organization',
  'accounts.user.detail.tab.remote.title': 'REMOTE ORGANIZATIONS',
  'accounts.user.detail.tab.teams': 'Teams',
  'accounts.users.add.copy.error':
    'Password cannot be copied to the clipboard directly. Please select the password from the input and copy it manually',
  'accounts.users.add.copy.success': 'Password copied to the clipboard',
  'accounts.users.create.error.title': 'Error',
  'accounts.users.create.success.text': 'User created.',
  'accounts.users.create.success.title': 'Success',
  'accounts.users.invite.deactivate.success': 'Remote user was deactivated successfully',
  'accounts.users.invite.error.title': 'Error',
  'accounts.users.invite.resend.error.title': 'Error',
  'accounts.users.invite.resend.success.text': 'User re-invited.',
  'accounts.users.invite.resend.success.title': 'Success',
  'accounts.users.invite.success.text': 'User invited.',
  'accounts.users.invite.success.title': 'Success',
  'accounts.users.list.actions.activate': 'Activate user',
  'accounts.users.list.actions.add': 'Add',
  'accounts.users.list.actions.deactivate': 'Deactivate user',
  'accounts.users.list.actions.edit.profile': 'Edit profile',
  'accounts.users.list.actions.recend.invite': 'Resend invite',
  'accounts.users.list.actions.remove': 'Remove user',
  'accounts.users.list.actions.search': 'Search users',
  'accounts.users.list.actions.view.profile': 'View profile',
  'accounts.users.list.addUserButtonLabel': 'Add',
  'accounts.users.list.btnAdd': 'Add',
  'accounts.users.list.btnCancel': 'Cancel',
  'accounts.users.list.btnInvite': 'Invite',
  'accounts.users.list.firstName.label': 'First name',
  'accounts.users.list.headCells.actions': 'Actions',
  'accounts.users.list.headCells.date.createdAt': 'Date Added',
  'accounts.users.list.headCells.date.deleted': 'Date removed',
  'accounts.users.list.headCells.date.expirationDate': 'Expiration',
  'accounts.users.list.headCells.date.updated': 'Last Updated',
  'accounts.users.list.headCells.email': 'Email',
  'accounts.users.list.headCells.firstName': 'First Name',
  'accounts.users.list.headCells.lastName': 'Last Name',
  'accounts.users.list.headCells.name': 'Name',
  'accounts.users.list.headCells.password': 'Password',
  'accounts.users.list.headCells.role': 'Role',
  'accounts.users.list.headCells.status': 'Status',
  'accounts.users.list.headCells.teams': '# Teams',
  'accounts.users.list.headCells.teams.date.added': 'Date added',
  'accounts.users.list.headCells.title': 'Title',
  'accounts.users.list.headCells.username': 'Username',
  'accounts.users.list.lastName.label': 'Last name',
  'accounts.users.list.noData': 'No users to show',
  'accounts.users.list.resendInviteButton': 'Resend invite',
  'accounts.users.list.searchField.label': 'Search',
  'accounts.users.list.status.active': 'Active',
  'accounts.users.list.status.deleted': 'Inactive',
  'accounts.users.list.status.disabled': 'Disabled',
  'accounts.users.list.status.inactive': 'Invite Sent',
  'accounts.users.list.teams': 'Teams',
  'accounts.users.list.title': 'Users',
  'accounts.users.list.username.label': 'Email',
  'accounts.users.tab.active': 'Active Users',
  'accounts.users.tab.inactive': 'Inactive Users',
  'accounts.users.update.success.text': 'User was updated successfully.',
  'address.address1': 'Address line 1',
  'address.address2': 'Address line 2',
  'address.city': 'City',
  'address.fullName': 'Full Name',
  'address.state': 'State',
  'address.street1': 'Address line 1',
  'address.street2': 'Address line 2',
  'address.zip': 'Zip Code',
  'attachment.previous': 'Previous attachement',
  'auth.deleteSessions.button.submit': 'Close sessions',
  'auth.deleteSessions.description': 'You can close sessions on all other devices here.',
  'auth.deleteSessions.title': 'Close all sessions',
  'auth.emailConfirm.button.login': 'Go to login',
  'auth.emailConfirm.description': 'Your email has been successfully verified.',
  'auth.emailConfirm.expired':
    'The verification code you have entered is invalid or expired. Please enter a valid verification code or request a new one.',
  'auth.emailConfirm.title': 'Email Confirmation',
  'auth.enableUser.button.submit': 'Enable user',
  'auth.enableUser.description':
    'You can re-enable user if it was blocked due to failed login attempts and you are sure that everything is fine.',
  'auth.enableUser.title': 'Enable user',
  'auth.forgot.back': 'Back to login',
  'auth.forgot.button.submit': 'Email Me',
  'auth.forgot.description': 'Enter your email address below to reset your password.',
  'auth.forgot.title': 'Forgot Your Password?',
  'auth.forgotConfirm.button.resend': 'Resend email',
  'auth.forgotConfirm.description': 'You will receive a message if your email is registered.',
  'auth.forgotConfirm.response.success': 'Resend email successful.',
  'auth.forgotConfirm.title': 'Reset link sent',
  'auth.invite.setPassword.button.submit': 'Submit',
  'auth.invite.setPassword.description': 'Setup password and press submit.',
  'auth.invite.setPassword.password': 'Password',
  'auth.invite.setPassword.passwordConfirm': 'Confirm password',
  'auth.invite.setPassword.title': 'Set password',
  'auth.login.button.login': 'Login',
  'auth.login.createAccount': "Don't have an account? Please contact",
  'auth.login.createAccount.email': 'account.request@dnascript.com',
  'auth.login.error': 'Login error. Please, try later.',
  'auth.login.forgotPassword': 'Forgot password?',
  'auth.login.title': 'Login to Your Account',
  'auth.newPassword.button.submit': 'Submit',
  'auth.newPassword.description': 'Setup new password and press submit.',
  'auth.newPassword.password': 'New password',
  'auth.newPassword.passwordConfirm': 'Confirm password',
  'auth.newPassword.title': 'Set new password',
  'auth.register.haveAccount': 'Already have an account?',
  'calendar.invalidSlot.text': 'You cannot select a slot in the past.',
  'calendar.invalidSlot.title': 'Invalid date/time',
  'common.alert.errors.closeButton': 'Clear all errors',
  'common.alert.errors.title': 'Error',
  'common.alert.success.title': 'Success',
  'common.alert.warning.title': 'Warning',
  'common.alert.warnings.closeButton': 'Clear all warnings',
  'common.alert.warnings.title': 'Warnings',
  'common.button.cancel': 'Cancel',
  'common.button.close': 'Close',
  'common.button.confirm': 'Confirm',
  'common.button.download': 'Download',
  'common.button.send': 'Send',
  'common.noDataFound': 'No data found',
  'common.password.tip':
    'Please, avoid short common words. Passwords must be at least 8 characters containing lower and uppercase letters & numbers & special characters ~!@#$%^&*()_+-=',
  'common.toast.success.title': 'Success',
  'customProcessDefinitionFile.option.download': 'Download Custom Process',
  'customProcesses.list.noData': 'No custom process definition files available',
  'customProcesses.list.table.id': 'Id',
  'customProcesses.list.table.id.filter': 'Filter by Id',
  'customProcesses.list.table.name': 'Name',
  'customProcesses.list.table.name.filter': 'Filter by Custom process name',
  'customProcesses.list.table.organizationId': 'Organization Id',
  'customProcesses.list.table.organizationId.filter': 'Filter by Organization Id',
  'customProcesses.list.table.search.placeholder': 'Search custom processes',
  'customProcesses.listPage.header.add.button.title': 'Add custom process',
  'customProcesses.listPage.title': 'Custom process definition files',
  'dashboard.page.caption': 'Check out your files and the content you’ve uploaded.',
  'dashboard.page.title': 'Hi {name}! Welcome back to the Dashboard.',
  'dashboard.widget.activeJobs.calendarButton': 'View calendar',
  'dashboard.widget.activeJobs.headings.deviceList': 'Instruments',
  'dashboard.widget.activeJobs.jobScheduleText': 'Job Schedule: ',
  'dashboard.widget.activeJobs.noData': 'No active print jobs',
  'dashboard.widget.activeJobs.rangeDurationText': 'Estimated Duration: {value}',
  'dashboard.widget.activeJobs.rangeProgressText': 'Job Progress: {value}% completed',
  'dashboard.widget.activeJobs.rangeText': '{from} - {to}',
  'dashboard.widget.activeJobs.remainingText': 'Time Remaining: {value}',
  'dashboard.widget.activeJobs.title': 'Active Print Jobs',
  'dashboard.widget.activityFeed.noActivityFeed': 'No recent activity',
  'dashboard.widget.activityFeed.title': 'Notifications',
  'dashboard.widget.activityFeed.trackPage': 'TRACK PAGE',
  'dashboard.widget.printJob.btnTitle': 'View sequence files',
  'dashboard.widget.printJob.title': 'View and edit your sequences',
  'dashboard.widget.recentWork.edited': 'Edited ',
  'dashboard.widget.recentWork.noRecentWork': 'No recent work',
  'dashboard.widget.recentWork.title': 'Recent Activity',
  'dashboard.widget.recentWork.type.all': 'All Types',
  'dashboard.widget.recentWork.type.runs': 'Runs',
  'dashboard.widget.recentWork.type.templates': 'Sequence Files',
  'dashboard.widget.recentWork.uploaded': 'Uploaded ',
  'dashboard.widget.reports.btnTitle': 'View runs and results',
  'dashboard.widget.reports.title': 'View your runs and results',
  'dashboard.widget.uploadSequence.btnTitle': 'Upload Sequence File',
  'dashboard.widget.uploadSequence.title': 'Ready to get started?',
  'dates.filter.custom': 'Custom',
  'dates.filter.last30days': 'Last 30 days',
  'dates.filter.last7days': 'Last 7 days',
  'downloads.listPage.button.download.title': 'Download',
  'downloads.listPage.table.header.name': 'Name',
  'downloads.listPage.table.header.releaseDate': 'Release date',
  'downloads.listPage.table.header.version': 'Version',
  'downloads.listPage.table.search.placeholder': 'Search software package',
  'downloads.noData': 'No software packages available at this moment.',
  'error.text.idIsNotDefined': 'There is no id defined',
  'error.title.create': 'Creation Error',
  'error.title.delete': 'Deletion Error',
  'error.title.duplicate': 'Duplicate error',
  'error.title.instrument.create': 'Instrument Creation Error.',
  'error.title.instrument.delete': 'Instrument Removal Error',
  'error.title.instrument.update': 'Instrument Update Error.',
  'error.title.project.create': 'Project create error...',
  'error.title.project.delete': 'Project archive error...',
  'error.title.template.delete': '{templateName} Archive Error',
  'error.title.template.error.missingSequences': 'Sequence file is missing sequences.',
  'error.title.template.update': 'Sequence file Update Error',
  'general.form.validation.fields.email.invalid': 'Invalid email address.',
  'general.form.validation.fields.integer': 'Value should be an integer',
  'general.form.validation.fields.onlyNumbers': 'Allow only numbers',
  'general.form.validation.fields.positiveNumber': 'Only strictly positive numbers are allowed',
  'general.form.validation.fields.required': 'This field is required',
  'general.form.validation.fields.zip': 'Allow only numbers and uppercase letters',
  'general.form.validation.name.maxLength': 'The {label} is longer than {length} characters',
  'general.form.validation.password.confirmationRequired': 'Confirmation password is required',
  'general.form.validation.password.currentRequired': 'Current password is required',
  'general.form.validation.password.doesNotMatchNew': 'Does not match new password',
  'general.form.validation.password.minLength': 'Password should have at least 8 characters',
  'general.form.validation.password.newPasswordRequired': 'New password is required',
  'general.form.validation.password.shouldHaveLowercase':
    "The password you entered doesn't meet the requirements. Please include a lowercase letter.",
  'general.form.validation.password.shouldHaveNumber': 'Should have number',
  'general.form.validation.password.shouldHaveSpecialCharacter': 'Should have special character',
  'general.form.validation.password.shouldHaveUppercase': 'Should have uppercase letter',
  'general.license.expired':
    'License expired. Update organization settings in order to use instrument. Queues runs will be unable to run and may need to be rescheduled.',
  'general.license.expiring':
    'Your SYNTAX Console software license will expire in {daysBeforeExpiration} days. Please contact your sales representative to renew this license.',
  'general.license.settings': 'Settings',
  'general.somethingWentWrong': 'Something went wrong',
  'idna.plate.create.v2.steps.1': 'Pick kit',
  'idna.plate.create.v2.steps.2': 'Upload sequence',
  'idna.plate.create.v2.steps.3': 'Edit sequence',
  'idna.plate.create.v2.steps.4': 'Summary',
  'idna.plate.create.v2.summary.button.new': 'New iDNA plate',
  'idna.plate.create.v2.summary.button.open': 'Open uploaded iDNA plate',
  'idna.plate.create.v2.summary.description': 'View your iDNA plate or create a new one',
  'idna.plate.create.v2.summary.title': 'Your custom iDNA plate is uploaded',
  'instrument.button.delete': 'Remove instrument',
  'instrument.details.info.installationDate': 'INSTALLATION DATE',
  'instrument.details.info.lastServiceDate': 'LAST SERVICE DATE',
  'instrument.details.info.lastUpdated': 'Last Updated',
  'instrument.details.info.location': 'LOCATION',
  'instrument.details.info.members': 'MEMBERS',
  'instrument.details.info.model': 'MODEL',
  'instrument.details.info.serialNumber': 'SERIAL NUMBER',
  'instrument.details.info.systemVersion': 'SYSTEM VERSION',
  'instrument.details.info.title': 'Details',
  'instrument.details.members.all': 'All',
  'instrument.details.tabs.history': 'RUNS',
  'instrument.details.tabs.history.search': 'Search synthesis runs',
  'instrument.details.tabs.queue': 'QUEUE',
  'instrument.details.tabs.status': 'STATUS',
  'instrument.form.admin': 'Instrument Admin',
  'instrument.form.allRequired': 'All fields are required.',
  'instrument.form.configuration': 'instrument configuration',
  'instrument.form.credentialsForm.explanation': 'Password you have generated that can be used to connect to Console',
  'instrument.form.credentialsForm.submitButton.label': 'Generate the password',
  'instrument.form.credentialsForm.submitButton.label.again': 'Generate a new password',
  'instrument.form.credentialsForm.title': 'Client credential',
  'instrument.form.installationDate': 'Installation Date',
  'instrument.form.location': 'Location',
  'instrument.form.members.add': 'Member',
  'instrument.form.members.add.button': 'Add Member',
  'instrument.form.members.restrict': 'Restrict access to a group of users.',
  'instrument.form.members.table.actions': 'Actions',
  'instrument.form.members.table.name': 'Name',
  'instrument.form.model': 'Model',
  'instrument.form.name': 'Instrument Name',
  'instrument.form.note': '*Reagent levels are updated after the instrument is in use.',
  'instrument.form.photo': 'Instrument Photo',
  'instrument.form.reminders': 'Quarterly preventative maintenance service reminders.',
  'instrument.form.requireApprove': 'Require Admin to approve print jobs for this instrument.',
  'instrument.form.serialNumber': 'Serial Number',
  'instrument.form.sync.button': 'Synchronize Key',
  'instrument.form.sync.failed': 'Synchronize key failed',
  'instrument.form.sync.note': 'Synchronizes instrument keys between your console and the syntax instrument.',
  'instrument.form.sync.success': 'Key synchronized.',
  'instrument.form.systemVersion': 'System Version',
  'instrument.form.type': 'Instrument',
  'instrument.manage.password.copy.error':
    'Password cannot be copied to the clipboard directly. Please select the key from the input and copy it manually',
  'instrument.manage.password.copy.success': 'Password copied to the clipboard.',
  'instrument.manage.tabs.details': 'Details',
  'instrument.manage.tabs.users': 'Users',
  'instrument.notFound': 'Instrument not found',
  'instrument.printQueue.title': 'Print Queue',
  'instrument.reagent.NUCLEOTIDES': 'NUCLEOTIDES',
  'instrument.reagent.PSP': 'PSP Reagents',
  'instrument.reagent.SYNTH': 'SYN Reagents',
  'instrument.reagent.cycles.plate': '{plateType} plate',
  'instrument.reagent.cycles.remaining': 'Cycles Remaining',
  'instrument.reagent.expired': 'Expired',
  'instrument.reagent.not.available': 'Not Available',
  'instrument.reagent.status': 'Reagents Status',
  'instrument.status.AVAILABLE': 'Available',
  'instrument.status.DELETED': 'Deleted',
  'instrument.status.INSERVICE': 'Deleted',
  'instrument.status.INUSE': 'In Use',
  'instrument.status.OFFLINE': 'Offline',
  'instrument.status.UNAVAILABLE': 'Unavailable',
  'instruments.button.new': 'Add instrument',
  'instruments.create.tab.details': 'Details',
  'instruments.create.tab.members': 'Members',
  'instruments.create.title': 'New instrument',
  'instruments.delete.success.description': 'Instrument deleted.',
  'instruments.delete.success.title': 'Success',
  'instruments.details.button.download.logs': 'Download logs',
  'instruments.details.button.manage': 'Manage instrument',
  'instruments.details.title': 'Instrument details',
  'instruments.manage.title': 'Manage Instrument',
  'instruments.modal.download.logs.body': 'Select a date range to download logs',
  'instruments.modal.download.logs.error': 'Selected date range should not exceed 7 days',
  'instruments.modal.download.logs.notice':
    'Downloading logs over an extended date range may timeout due to file size. Please narrow the date range if this occurs and try again.',
  'instruments.modal.download.logs.timeout': 'The instrument logs are not reachable now.',
  'instruments.modal.download.logs.timeout.title': 'Instrument Logs Timeout',
  'instruments.modal.download.logs.title': 'Download logs',
  'instruments.noinstruments.text':
    'No instruments have been added. Configure an instrument by clicking the \n “Add Instrument” button.',
  'instruments.noinstruments.title': 'No instruments',
  'instruments.title': 'Instruments',
  'instruments.update.success.description': 'Instrument updated.',
  'instruments.update.success.title': 'Success',
  'licenseUpdate.confirmationModal.note':
    'Updating the license key will take several minutes. Please confirm you`d like to make this change.',
  'licenseUpdate.confirmationModal.submit': 'Confirm',
  'licenseUpdate.confirmationModal.title': 'Are you sure you want to add new confirmation key?',
  'licenseUpdate.errorModal.note': 'The license you uploaded/entered is invalid or expired.\nPlease try again.',
  'licenseUpdate.errorModal.title': 'Invalid license',
  'licenseUpdate.successModal.note':
    'Then instruments and cloud software are updating to communicate properly. Some print jobs may need to be updated. The license is valid from {validFrom} to {validTo}.',
  'licenseUpdate.successModal.title': 'License key has been added',
  'licenseUpdate.updateResult.contactSupport1': 'Please contact DNA Script Support ',
  'licenseUpdate.updateResult.contactSupport2': ' if you need helps or encounter any issues.',
  'licenseUpdate.updateResult.success': 'License key has been updated successfully.',
  'manageTeam.modal.button.cancel': 'Cancel',
  'manageTeam.modal.button.submit': 'Submit',
  'manageTeam.modal.form.admin': 'Admin',
  'manageTeam.modal.form.description': 'Description',
  'manageTeam.modal.form.teamName': 'Team name',
  'manageTeam.modal.title': 'Manage Team',
  'members.popup.column.role.admin': 'Admin',
  'members.popup.column.role.approver': 'Manager',
  'members.popup.column.role.member': 'Member',
  'members.popup.column.role.owner': 'Owner',
  'members.popup.column.role.users.all': 'Members',
  'members.popup.column.title.role': 'Role',
  'members.popup.column.title.users': 'Members',
  'modal.create.customProcessDefinitionFile.confirm': 'Create',
  'modal.create.customProcessDefinitionFile.data': 'Custom process data',
  'modal.create.customProcessDefinitionFile.invalid.name': 'Special characters (\\ / : * ? " < > |) are not allowed.',
  'modal.create.customProcessDefinitionFile.invalidData': 'Invalid data provided',
  'modal.create.customProcessDefinitionFile.name': 'Custom process definition file name',
  'modal.create.customProcessDefinitionFile.title': 'Create new custom process definition file',
  'modal.create.customProcessDefinitionFile.unknownError':
    'Cannot validate data at this moment. Please try again later',
  'modal.delete.cancel': 'Cancel',
  'modal.delete.customProcessDefinitionFile.confirm': 'Delete custom process',
  'modal.delete.customProcessDefinitionFile.note':
    'Deleting this custom process is permanent. The data cannot be recovered.',
  'modal.delete.customProcessDefinitionFile.title': 'Are you sure you want to delete this custom process?',
  'modal.delete.instrument.confirm': 'Remove instrument',
  'modal.delete.instrument.note':
    'Removing this instrument is permanent. If you decide to add this instrument again you’ll need to go through the setup process.  All print jobs in the queue will be removed.',
  'modal.delete.instrument.title': 'Are you sure you want to remove this instrument?',
  'modal.delete.plate.confirm': 'Archive plate',
  'modal.delete.plate.note': 'Archiving this plate is not permanent. The data can be recovered any time.',
  'modal.delete.progress.instrument.body':
    'A run is currently in progress on this instrument. Abort the run or wait for it to finish before attempting to remove this instrument again.',
  'modal.delete.progress.instrument.title': 'Unable to remove instrument.',
  'modal.delete.project.confirm': 'Archive project',
  'modal.delete.project.note': 'Archiving this project is not permanent. The data can be recovered any time.',
  'modal.delete.project.success': 'Project was archived successfully',
  'modal.delete.project.title': 'Are you sure you want to archive this project?',
  'modal.delete.run.confirm': 'Delete run',
  'modal.delete.run.note':
    'Removing this run is permanent. The data will be unrecoverable if you choose to delete this run.',
  'modal.delete.run.title': 'Are you sure you want to remove this run?',
  'modal.delete.sequenceFile.confirm': 'Archive file',
  'modal.delete.sequenceFile.note':
    'Archiving this sequence file is permanent. The data will be unrecoverable if you choose to archive this sequence file.',
  'modal.delete.sequenceFile.title': 'Are you sure you want to archive this sequence file?',
  'modal.delete.team.confirm': 'Archive team',
  'modal.delete.team.note': 'Archiving this team is not permanent. The data can be recovered any time.',
  'modal.delete.team.title': 'Are you sure you want to archive this team?',
  'modal.duplicate.plate.confirm': 'Duplicate',
  'modal.duplicate.plate.copyOf': 'copy of',
  'modal.duplicate.plate.error.name': 'Plate name is the same as the Parent plate name.',
  'modal.duplicate.plate.note': 'This creates a new file based on the current file.',
  'modal.duplicate.plate.title': 'Duplicate',
  'modal.file.close': 'Close',
  'modal.restore.plate.confirm': 'Are you sure you want to restore this plate?',
  'modal.restore.plate.title': 'Restore plate',
  'modal.team.user.remove.body':
    "Removing this user will disable this user's ability to have access to team projects. Profile information will be retained. You can readd this user to team anytime.",
  'modal.team.user.remove.title': 'Are you sure you want to remove this user from team?',
  'modal.upload.processing': 'Processing...',
  'modal.user.deactivate.body':
    'Deactivating this user will disable this users ability to sign into the platform. Profile information will be retained. You can reactivate this user anytime.',
  'modal.user.deactivate.title': 'Are you sure you want to deactivate this user?',
  'modal.user.regenerate.confirm.body':
    'Updating the AES key will take several minutes. Please confirm that you’d like to make this change.',
  'modal.user.regenerate.confirm.button.title': 'Confirm',
  'modal.user.regenerate.confirm.title': 'Are you sure you want to change AES key?',
  'nav.accounts': 'Accounts',
  'nav.company': 'DNA Script.',
  'nav.copyright': 'All Rights Reserved.',
  'nav.documentation': 'Documentation',
  'nav.downloads': 'Downloads',
  'nav.help': 'Help',
  'nav.home': 'Home',
  'nav.instruments': 'Instruments',
  'nav.instruments.fleet': 'Instruments',
  'nav.organization': 'Organization',
  'nav.privacy': 'Privacy Policy',
  'nav.service': 'Service',
  'nav.service.details': 'Troubleshooting',
  'nav.store': 'Store',
  'nav.support': 'Support',
  'nav.synthesisData': 'Synthesis Data',
  'nav.synthesisData.customProcessDefinitionFiles': 'Custom process definition files',
  'nav.synthesisData.plates': 'iDNA',
  'nav.synthesisData.projects': 'Projects',
  'nav.synthesisData.projects.design': 'Assembly Projects',
  'nav.synthesisData.projects.team': 'Team Projects',
  'nav.synthesisData.runs': 'Runs',
  'nav.synthesisData.templates': 'Sequence Files',
  'nav.teams': 'Teams',
  'nav.terms': 'Terms of Use',
  'nav.users': 'Users',
  'noaccess.page.button': 'Go to home',
  'noaccess.page.text':
    "You don't have permission to access this page. Contact an administrator \n or go back to the home page.",
  'noaccess.page.title': 'Access Denied',
  'notFound.description': 'The requested page is not found.',
  'notFound.title': 'Page not found',
  'notification.modal.actualRunTime': 'ACTUAL RUN TIME',
  'notification.modal.finishTime': 'FINISH TIME',
  'notification.modal.instrument': 'INSTRUMENT',
  'notification.modal.startTime': 'START TIME',
  'notification.modal.subTitle': 'Scheduled by',
  'notification.modal.text.deleted': 'This run was deleted {time}',
  'notification.modal.timeEstimate': 'TIME ESTIMATE',
  'notification.modal.title': 'Run ID ',
  'notification.modal.title.completed': ' This run successfully completed {time}. View details below.',
  'notification.modal.title.error': ' This run encountered an error {time}. View details below.',
  'notification.modal.title.progress': ' This run started {time}. View details below.',
  'notification.modal.view': 'VIEW RUN',
  'organization.account.AESKey.alert.success': 'Encryption key reset successfully',
  'organization.account.AESKey.copy.error':
    'Installation key cannot be copied to the clipboard directly. Please select the key from the input and copy it manually.',
  'organization.account.AESKey.copy.success': 'Installation key copied to the clipboard.',
  'organization.account.adminSettings': 'Admin settings',
  'organization.account.aesKey': 'Installation Key',
  'organization.account.alert.success': 'Organization details updated.',
  'organization.account.companyInfo': 'Company information',
  'organization.account.companyName': 'Company Name',
  'organization.account.customerId': 'Customer ID',
  'organization.account.dateCreated': 'DATE CREATED',
  'organization.account.downloadLogins': 'Download login attempts',
  'organization.account.downloadLogins.error': 'Error downloading login attempts',
  'organization.account.emailAddress': 'Email address',
  'organization.account.expires': 'Expires {expirationDate}',
  'organization.account.lastUpdated': 'LAST UPDATED',
  'organization.account.licenseKey': 'License Key',
  'organization.account.maxLoginFailedAttempts': 'Max failed login attempts',
  'organization.account.organizationContact': 'Organization Contact',
  'organization.account.status': 'STATUS',
  'organization.account.title': 'Title',
  'organization.address.city': 'City',
  'organization.address.country': 'Country',
  'organization.address.phoneNumber': 'Phone number',
  'organization.address.state': 'State',
  'organization.address.street1': 'Address line 1',
  'organization.address.street2': 'Address line 2',
  'organization.address.timezone': 'Time Zone',
  'organization.address.zipcode': 'Zip Code',
  'organization.contact.firstName': 'First Name',
  'organization.contact.lastName': 'Last Name',
  'organization.header.button.cancel': 'Cancel',
  'organization.header.button.save': 'Save',
  'organization.header.button.uploadLicense': 'Upload file',
  'organization.pageTitle': 'Organization',
  'organization.shipping.add': 'Add Shipping Address',
  'organization.tab.admin': 'Admin',
  'organization.tab.details': 'Details',
  'organization.tab.fse': 'FSE Access',
  'organization.tab.instruments': 'Instruments',
  'organization.tab.policies': 'Policies',
  'organizations.table.clearOrganization.popup.body':
    'Are you sure that you want to clear organization data? The action cannot be reverted.',
  'organizations.table.clearOrganization.popup.title': 'Clear organization data',
  'organizations.table.details.back': 'Back to Organizations',
  'organizations.table.details.description': 'Manage organizations and their account permissions here.',
  'plate.create.v2.confirm.idnaPlateNameChangeWarning':
    'File name automatically changed because an existing file with that name already exists. Rename if needed.',
  'plate.create.v2.confirm.setName': 'Edit sequence file name',
  'plate.create.v2.confirm.setProject': 'Assign to project',
  'plate.create.v2.confirm.setiDnaName': 'Edit iDNA plate name',
  'plate.create.v2.edit.selectPlateTitle': 'Select Custom iDNA Plate (optional)',
  'plate.create.v2.filename.empty': 'Untitled',
  'plate.create.v2.infos.idna': 'Using iDNA kit: ',
  'plate.create.v2.infos.kit': 'Kit type',
  'plate.create.v2.kit.info.description': 'The maximum length is {maximumLength}',
  'plate.create.v2.kit.info.title': 'Select this for SYNTAX {kitLabel} Run Kits',
  'plate.create.v2.steps.1': 'Pick kit',
  'plate.create.v2.steps.2': 'Upload sequence',
  'plate.create.v2.steps.3': 'Edit sequence',
  'plate.create.v2.steps.4': 'Summary',
  'plate.create.v2.summary.button.new': 'New Sequence file',
  'plate.create.v2.summary.button.open': 'Open uploaded file',
  'plate.create.v2.summary.description': 'View your sequence file or create a new one',
  'plate.create.v2.summary.iDNA': 'iDNA Plate',
  'plate.create.v2.summary.kit': 'Kit',
  'plate.create.v2.summary.name': 'Name',
  'plate.create.v2.summary.problems': 'Problems',
  'plate.create.v2.summary.problems.errors': 'Errors',
  'plate.create.v2.summary.problems.warnings': 'Warnings',
  'plate.create.v2.summary.statistics': 'Statistics',
  'plate.create.v2.summary.statistics.averageLength': 'Average sequence length',
  'plate.create.v2.summary.statistics.longest': 'Longest-shortest sequence',
  'plate.create.v2.summary.statistics.mods': 'Mods',
  'plate.create.v2.summary.statistics.wells': 'Wells with a sequence',
  'plate.create.v2.summary.title': 'Your sequence file is uploaded',
  'plate.create.v2.switch': 'Plate view',
  'plate.create.v2.table.head.concentration': 'Concentration',
  'plate.create.v2.table.head.idna': 'Leading sequence',
  'plate.create.v2.table.head.length': 'Length',
  'plate.create.v2.table.head.normalization': 'Normalization',
  'plate.create.v2.table.head.sequence': 'Sequence',
  'plate.create.v2.table.head.sequenceName': 'Sequence Name',
  'plate.create.v2.table.head.well': 'Well',
  'plates.create.completed.error': 'Plate contains errors',
  'plates.create.completed.error.text': 'Please, review and correct errors before finalizing',
  'plates.create.error': 'Plate create error...',
  'plates.create.success': 'Plate was uploaded successfully.',
  'plates.create.success.text':
    'The template was created successfully. \nEditing is optional, click "Save" to proceed with the summary',
  'plates.create.title': 'Add Plate',
  'plates.create.uploadFile.dragAndDropArea.description':
    'Drag and drop files into this box or click the button below to select from file browser. Valid files must follow the plate files provided at the top right corner of this page.',
  'plates.detailsPage.buttons.cancel': 'Cancel',
  'plates.detailsPage.buttons.save': 'Save',
  'plates.detailsPage.buttons.update': 'Update',
  'plates.detailsPage.detail.update.error.title': 'Plate Update Error',
  'plates.detailsPage.detail.update.success.description': 'Plate updated.',
  'plates.detailsPage.detail.update.success.title': 'Success',
  'plates.detailsPage.details.form.invalid.name': 'Special characters (\\ / : * ? " < > |) are not allowed.',
  'plates.detailsPage.details.modal.concentration.lowest': 'Lowest concentration',
  'plates.detailsPage.details.modal.concentration.none': 'None',
  'plates.detailsPage.details.modal.concentration.target': 'Target concentration',
  'plates.detailsPage.details.modal.name': 'Sequence File Name',
  'plates.detailsPage.details.modal.title': 'Sequence File Settings',
  'plates.detailsPage.details.name': 'Plate name',
  'plates.detailsPage.details.parentName': 'Parent plate',
  'plates.detailsPage.details.plateSize': 'Plate size',
  'plates.detailsPage.details.scale': 'Scale (pm)',
  'plates.detailsPage.details.update.error.allWellsEmpty':
    'All wells are empty. There should be a sequence in at least one well.',
  'plates.detailsPage.details.update.error.emptyName': 'Plate name should not be empty. Please fill it on Details tab.',
  'plates.detailsPage.details.update.error.general':
    'There is one or more wells containing errors in the plate. Please fix them before saving!',
  'plates.detailsPage.error': 'Review all warnings and clear all errors before printing',
  'plates.detailsPage.indicator.idna': 'iDNA',
  'plates.detailsPage.tabs.details': 'Details',
  'plates.detailsPage.tabs.plate': 'Plate',
  'plates.errors.outOfDate': 'This plate is outdated. You can delete it',
  'plates.listPage.archived': 'Archived',
  'plates.listPage.header.add.button.title': 'Add plate',
  'plates.listPage.my.plates': 'My Plates',
  'plates.listPage.table.header.id': 'Design ID',
  'plates.listPage.table.header.id.filter': 'Filter by ID',
  'plates.listPage.table.header.name': 'Name',
  'plates.listPage.table.header.name.filter': 'Filter by name',
  'plates.listPage.table.header.partNumber': 'Part #',
  'plates.listPage.table.header.partNumber.filter': 'Filter by Part #',
  'plates.listPage.tabs.all': 'All',
  'plates.listPage.title': 'Custom iDNA plate',
  'plates.myPlates.select.label': 'Choose My Plate',
  'plates.myPlates.select.placeholder': 'Select',
  'plates.noData': 'No plates available',
  'plates.platePreview.btnTitle': 'Plate Preview',
  'plates.platePreview.title': 'Preview Plate',
  'plates.table.search.placeholder': 'Search plates',
  'printDetails.estimatedReagents.initialVolume': 'Initial Volume (mL)',
  'printDetails.estimatedReagents.itemName': 'Item name',
  'printDetails.modal.buttons.addAnotherNotification': 'ADD ANOTHER NOTIFICATION',
  'printDetails.modal.buttons.addNotification': 'ADD NOTIFICATION',
  'printDetails.modal.centrifugationType': 'PROCESS',
  'printDetails.modal.description': 'Description',
  'printDetails.modal.instrument': 'INSTRUMENT',
  'printDetails.modal.itemList': 'ITEM LIST',
  'printDetails.modal.kit': 'KIT TYPE',
  'printDetails.modal.labels.email': 'Email',
  'printDetails.modal.labels.emailAddress': 'Email address',
  'printDetails.modal.labels.phoneNumber': 'Phone number',
  'printDetails.modal.labels.sms': 'SMS',
  'printDetails.modal.length': 'LENGTH (nt)',
  'printDetails.modal.modifiedBase': 'MODIFIED BASES',
  'printDetails.modal.noRunDetails': 'Cannot find print details',
  'printDetails.modal.notifications': 'Notifications',
  'printDetails.modal.notificationsDescription':
    'Opt in to receive notifications about this print job. Notifications may include prep, supply levels and print completion.',
  'printDetails.modal.oligos': '# OLIGOS',
  'printDetails.modal.scheduledDate': 'SCHEDULED DATE',
  'printDetails.modal.subTitle': 'Scheduled by',
  'printDetails.modal.timeEstimate': 'TIME ESTIMATE',
  'printDetails.modal.title': 'Run ID {id}',
  'profile.button.logout': 'Log Out',
  'profile.pageTitle': 'My Profile',
  'profile.success.text': 'Profile updated.',
  'profile.success.title': 'Success',
  'project.create.table.actions': 'Actions',
  'project.create.table.name': 'Name',
  'project.create.table.role': 'Role',
  'project.noRuns.helper.text': 'You haven’t planned any runs yet. Click the button below to get started.',
  'projects.button.new': 'New project',
  'projects.create.button.back': 'Back',
  'projects.create.button.cancel': 'Cancel',
  'projects.create.button.next': 'Next',
  'projects.create.button.save': 'Save',
  'projects.create.form.description': 'Project description',
  'projects.create.form.name': 'Project name',
  'projects.create.form.owner': 'Project owner',
  'projects.create.member.add.name': 'Pick member',
  'projects.create.members.button.add': 'Add',
  'projects.create.members.role.ADMIN': 'Admin',
  'projects.create.members.role.MANAGER': 'Manager',
  'projects.create.members.role.OWNER': 'Owner',
  'projects.create.members.role.READ': 'Viewer',
  'projects.create.members.role.WRITE': 'Member',
  'projects.create.team.add': 'Pick team',
  'projects.create.title': 'New project',
  'projects.design.title': 'Assembly Projects',
  'projects.detail.button.manageProject': 'Manage project',
  'projects.detail.button.print.gene': 'Print Gene',
  'projects.detail.card.passing': 'Passing',
  'projects.detail.card.runs': 'Runs',
  'projects.detail.card.title.completed': 'Completed',
  'projects.detail.card.title.costCenter': 'Cost Center',
  'projects.detail.card.title.dateCreated': 'Date created',
  'projects.detail.card.title.details': 'Details',
  'projects.detail.card.title.drafts': 'Sequence files',
  'projects.detail.card.title.errors': 'Invalid',
  'projects.detail.card.title.files': 'Valid',
  'projects.detail.card.title.lastModified': 'Last Modified',
  'projects.detail.card.title.oligos': 'Oligos',
  'projects.detail.card.title.projectOwner': 'Project owner',
  'projects.detail.card.title.queued': 'Queued',
  'projects.detail.card.title.teamMembers': 'Team members',
  'projects.detail.table.noData': 'No synthesis runs available',
  'projects.detail.table.print.search.placeholder': 'Search sequence files',
  'projects.detail.table.printJobs.customProcessDefinitionFile': 'Process definition file',
  'projects.detail.table.printJobs.customProcessDefinitionFile.filter': 'Filter by Process definition file',
  'projects.detail.table.printJobs.designId': 'Design ID',
  'projects.detail.table.printJobs.designId.filter': 'Filter by iDNA plate',
  'projects.detail.table.printJobs.kitType': 'Kit',
  'projects.detail.table.printJobs.kitType.filter': 'Filter by Kit',
  'projects.detail.table.printJobs.name': 'Name',
  'projects.detail.table.printJobs.name.filter': 'Filter by sequence name',
  'projects.detail.table.printJobs.numberOfRuns': '# of Runs',
  'projects.detail.table.printJobs.oligos': '# Oligos',
  'projects.detail.table.printJobs.plate': 'Plate',
  'projects.detail.table.printJobs.plate.filter': 'Filter by Plate',
  'projects.detail.table.printJobs.plateId': 'Plate ID',
  'projects.detail.table.printJobs.project': 'Project',
  'projects.detail.table.printJobs.status': 'Status',
  'projects.detail.table.printJobs.status.filter': 'Filter by status',
  'projects.detail.table.printJobs.updated': 'Last modified',
  'projects.detail.table.runs.assignedInstrument': 'Instrument',
  'projects.detail.table.runs.assignedInstrument.filter': 'Filter by instrument name',
  'projects.detail.table.runs.completed': '# Completed',
  'projects.detail.table.runs.creator': 'Creator',
  'projects.detail.table.runs.creator.filter': 'Filter by creator',
  'projects.detail.table.runs.dateCompleted': 'Date completed',
  'projects.detail.table.runs.id': 'Run ID',
  'projects.detail.table.runs.id.filter': 'Filter by run id',
  'projects.detail.table.runs.name': 'Name',
  'projects.detail.table.runs.name.filter': 'Sequence file name',
  'projects.detail.table.runs.numberOfPlates': '# Plates',
  'projects.detail.table.runs.passingAndTotalOligos': '# Passing / Total Oligos / %',
  'projects.detail.table.runs.project': 'Project',
  'projects.detail.table.runs.project.filter': 'Filter by project name',
  'projects.detail.table.runs.queued': '# Queued',
  'projects.detail.table.runs.sizeRange': 'Size Range',
  'projects.detail.table.runs.startDate': 'Start Date',
  'projects.detail.table.runs.status': 'Status',
  'projects.detail.table.runs.status.filter': 'Filter by status',
  'projects.detail.table.runs.synthesis': 'Synthesis Run',
  'projects.detail.table.search.placeholder': 'Search synthesis runs',
  'projects.detail.table.sequenceFiles.plate': 'Plate',
  'projects.detail.table.sequenceFiles.plate.filter': 'Filter by plate type',
  'projects.detail.tabs.gene.copyVector': 'Copy the vector sequence to the clipboard',
  'projects.detail.tabs.gene.vector': 'Vector',
  'projects.detail.tabs.instructions.check.failed.wells':
    'Check the run report for all failed plates. Editing the concentration is only available on failed plates, but all wells are editable.',
  'projects.detail.tabs.instructions.completed': 'The latest synthesis run is completed',
  'projects.detail.tabs.instructions.condition':
    'Instructions can only be downloaded once all runs of a synthesis run are completed.',
  'projects.detail.tabs.instructions.done':
    'Download the instruction set for Hamilton to move on to the next step. A configuration file is also included.',
  'projects.detail.tabs.instructions.download.btn': 'Download INSTRUCTIONS',
  'projects.detail.tabs.instructions.download.error': 'Could not download instruction file.',
  'projects.detail.tabs.instructions.failed': 'Run was failed, try to re-print the gene or contact the administrator.',
  'projects.detail.tabs.instructions.failed.wells':
    'Multiple failed wells. Reprint the failed wells on a new plate in order to proceed to download instructions and continue on to the next step.',
  'projects.detail.tabs.instructions.failed.wells.title': 'Gene project has failed',
  'projects.detail.tabs.instructions.modal.btn': 'Got it',
  'projects.detail.tabs.instructions.modal.text':
    'Create a folder after downloading all the files and move them in there. You’ll need to take that folder and select it after inserting it into Hamilton. The configuration file, instruction set, and deck check should all be included. Follow the on screen instructions, then return here after completed if needed.',
  'projects.detail.tabs.instructions.modal.title': 'Your files are downloading…',
  'projects.detail.tabs.instructions.not.all.oligos':
    'Plates are not 100% passing, instruction set cannot be generated.',
  'projects.detail.tabs.instructions.not.completed': 'All runs ({countOfRuns}) have not yet completed.',
  'projects.detail.tabs.instructions.remaining.plural': 'There are ({runsInQueue}) runs remaining.',
  'projects.detail.tabs.instructions.remaining.singular': 'There is ({runsInQueue}) run remaining.',
  'projects.detail.tabs.instructions.reprint.btn': 'REPRINT FAILED WELL(S)',
  'projects.detail.tabs.printJobs': 'Sequence Files',
  'projects.detail.tabs.runs': 'Runs',
  'projects.manage.button.cancel': 'Cancel',
  'projects.manage.button.delete': 'Archive project',
  'projects.manage.button.save': 'Save',
  'projects.manage.tabs.details': 'Details',
  'projects.manage.tabs.users': 'Users',
  'projects.manage.title': 'Manage Project',
  'projects.noData': 'No projects available',
  'projects.table.action.option.restore': 'Restore',
  'projects.table.archived': 'Archived Date',
  'projects.table.errors.incomplete.body': 'This project is deleted.',
  'projects.table.errors.incomplete.title': 'Project is not available.',
  'projects.table.members': 'Members',
  'projects.table.members.all': 'All',
  'projects.table.name': 'Project name',
  'projects.table.name.filter': 'Filter by name',
  'projects.table.oligos': '# Passing / \n total oligos',
  'projects.table.oligosMore': '% Passing oligos',
  'projects.table.queued': 'Queued / Completed',
  'projects.table.restore.modal.text': 'Are you sure you want to restore this project?',
  'projects.table.restore.modal.title': 'Restore project',
  'projects.table.restore.success.text': 'Project was restored successfully',
  'projects.table.runs': '#Valid / total \n sequence files',
  'projects.table.searchPlaceholder': 'Search projects',
  'projects.table.updated': 'Last updated',
  'projects.tabs.all': 'All',
  'projects.tabs.archived': 'Archived',
  'projects.tabs.my': 'My Projects',
  'projects.title': 'Projects',
  'reacttable.refreshdata': 'Refresh Data',
  'reports.backButton': 'Back to runs',
  'reports.create.error': 'Report create error...',
  'reports.create.select.serialNumber': 'Select Instrument',
  'reports.helpSidebar.description.body':
    'Reports can be found under (Instrument name > Exports > Reports > reportname.json / reportname.srpt).',
  'reports.helpSidebar.description.title': 'Before starting, correctly insert and prepare your device',
  'reports.helpSidebar.title': '* Plug in your USB device *',
  'reports.table.reportDetails.amountUsed': 'Amount used (mL)',
  'reports.table.reportDetails.daysOnSystem': 'Days on system',
  'reports.table.reportDetails.expirationDate': 'Expiration',
  'reports.table.reportDetails.lot': 'Lot #',
  'reports.table.reportDetails.part': 'Part #',
  'reports.table.reportDetails.plate': 'Plate',
  'reports.table.reportDetails.reagentName': 'Reagent name',
  'reports.title': 'Sync Run Reports',
  'reports.uploadFiles.dragAndDropArea.button': 'Upload reports',
  'reports.uploadFiles.dragAndDropArea.description': 'To upload multiple report files at once.',
  'reports.uploadFiles.dragAndDropArea.title': 'Drag & Drop or Select report files',
  'reports.uploadFiles.files.grid.status.fileInvalidType': 'Invalid file type.',
  'reports.uploadFiles.files.grid.titles.date': 'Date',
  'reports.uploadFiles.files.grid.titles.fileName': 'Print Job',
  'reports.uploadFiles.files.grid.titles.project': 'Project',
  'reports.uploadFiles.files.grid.titles.status': 'Status',
  'runs.create.deleteFiles.success': '{fileName} successfully archived',
  'runs.create.error': 'Run create error...',
  'runs.create.suggested.description':
    'Pick a date and time below to schedule your job. Note: the estimate includes time to prep the instrument as well as the entire printing process.',
  'runs.create.suggested.title': 'Next available time: {date} / Total job time: {diff} (estimate).',
  'runs.create.upload.files.grid.instrument.STX-100': 'Compatible with SYNTAX STX-100',
  'runs.create.upload.files.grid.instrument.STX-200': 'Compatible with SYNTAX STX-200',
  'runs.create.uploadFile.dragAndDropArea.button': 'Upload file',
  'runs.create.uploadFile.dragAndDropArea.description':
    'Drag and drop a file into this box or click the button below to select it from the file browser. It must use the same formatting as the templates you can download on the right.',
  'runs.create.uploadFile.dragAndDropArea.title': 'Drag & Drop or select file',
  'runs.create.uploadFiles.back.button.title': 'Back to project',
  'runs.create.uploadFiles.done.button.title': 'Done',
  'runs.create.uploadFiles.files.detail.averageLength': 'Average Length',
  'runs.create.uploadFiles.files.detail.errors': 'Errors',
  'runs.create.uploadFiles.files.detail.longestOligo': 'Longest Oligo',
  'runs.create.uploadFiles.files.detail.shortestOligo': 'Shortest Oligo',
  'runs.create.uploadFiles.files.detail.totalOligo': 'Total Oligo',
  'runs.create.uploadFiles.files.detail.warnings': 'Warnings',
  'runs.create.uploadFiles.files.details': 'Sequence file Details',
  'runs.create.uploadFiles.files.grid.status.completed.description': 'File completed successfully',
  'runs.create.uploadFiles.files.grid.status.errors.description': 'File uploaded successfully but it contains errors.',
  'runs.create.uploadFiles.files.grid.status.failed.description': 'File upload failed.',
  'runs.create.uploadFiles.files.grid.status.fileInvalidType.description': 'File type must be .csv or .xls(.xlsx)',
  'runs.create.uploadFiles.files.grid.status.fileTooLarge.description': 'File is larger than 1 megabyte.',
  'runs.create.uploadFiles.files.grid.status.somethingGoesWrong.description': 'Something goes wrong.',
  'runs.create.uploadFiles.files.grid.status.uploading.title': 'Uploading...',
  'runs.create.uploadFiles.files.grid.status.valid.description': 'File processed successfully.',
  'runs.create.uploadFiles.files.grid.status.warnings.description': 'File uploaded but contains warnings.',
  'runs.create.uploadFiles.files.grid.step.kit': 'Select Kit Type',
  'runs.create.uploadFiles.files.grid.step.plate': 'Select Custom iDNA Plate (optional)',
  'runs.create.uploadFiles.files.grid.step.plate.helper': 'Don’t see your custom iDNA design? Upload a plate.',
  'runs.create.uploadFiles.files.grid.step.plate.idna.upload': 'Upload iDNA plate',
  'runs.create.uploadFiles.files.grid.step.plate.idna.upload.parseError': 'Could not parse file',
  'runs.create.uploadFiles.files.grid.step.plate.idna.upload.warning': 'Please select plate type and kit type',
  'runs.create.uploadFiles.files.grid.step.plate.idna.uploadFiles': 'Upload files',
  'runs.create.uploadFiles.files.grid.step.plate.is.idna': 'Custom iDNA',
  'runs.create.uploadFiles.files.grid.step.upload': 'Upload files or copy and paste sequences',
  'runs.create.uploadFiles.files.grid.step.upload.or': 'or',
  'runs.create.uploadFiles.files.grid.titles.actions': 'Actions',
  'runs.create.uploadFiles.files.grid.titles.fileName': 'Name',
  'runs.create.uploadFiles.files.grid.titles.oligosFound': 'Oligos Found',
  'runs.create.uploadFiles.files.grid.titles.plateSize': 'Plate size',
  'runs.create.uploadFiles.files.grid.titles.status': 'Status',
  'runs.create.uploadFiles.manual.modal.button.cancel': 'Cancel',
  'runs.create.uploadFiles.manual.modal.button.save': 'Save',
  'runs.create.uploadFiles.manual.modal.fields.content': 'FASTA Sequence',
  'runs.create.uploadFiles.manual.modal.fields.title': 'FASTA Sequence name',
  'runs.create.uploadFiles.manual.modal.fields.title.invalid':
    'Special characters (\\ / : * ? " < > |) are not allowed.',
  'runs.create.uploadFiles.manual.modal.subTitle': 'Copy and paste or manually enter sequences',
  'runs.create.uploadFiles.manual.modal.title': 'Input sequences',
  'runs.create.uploadFiles.manualTextArea.button': 'Input Sequences',
  'runs.create.uploadFiles.manualTextArea.description1':
    'Type or paste your oligo sequence(s) in the following format:',
  'runs.create.uploadFiles.manualTextArea.description2': '  >name of oligo',
  'runs.create.uploadFiles.manualTextArea.description3': '  AGCTAGCTAGCTAGC',
  'runs.create.uploadFiles.manualTextArea.description4':
    'Names (denoted by >rows) are optional. Multiple sequences can be entered (use one line per oligo). Concentration will be set to the default value of the kit type.',
  'runs.create.uploadFiles.manualTextArea.title': 'Input sequences',
  'runs.create.uploadFiles.selectPlateSetup.label.custom': 'Select iDNA plate',
  'runs.create.uploadFiles.selectPlateSetup.label.default': 'Standard 96 well iDNA',
  'runs.create.uploadFiles.title': 'Upload Sequence File',
  'runs.create.uploadFiles.typeFiles.download.title': 'Download Templates',
  'runs.details.aside.actual': 'Actual',
  'runs.details.aside.actualVolume': 'Actual Volume',
  'runs.details.aside.concentration': 'CONCENTRATION',
  'runs.details.aside.lastUpdated': '*Last updated',
  'runs.details.aside.leading.sequence': 'LEADING SEQUENCE ',
  'runs.details.aside.nts': 'Length',
  'runs.details.aside.score': 'Complexity Score',
  'runs.details.aside.sequence': 'SEQUENCE',
  'runs.details.aside.sequenceName': 'SEQUENCE NAME',
  'runs.details.aside.sequencePreview': 'Sequence Preview',
  'runs.details.aside.tab.inspector': 'Inspector',
  'runs.details.aside.tab.inspector.desc':
    'Hover over any well to view detailed information. Click to lock the information in place.',
  'runs.details.aside.target': 'Target',
  'runs.details.aside.yield': 'YIELD',
  'runs.details.concentration.update.success': 'Run report concentration was updated successfully.',
  'runs.details.download': 'Report',
  'runs.details.download.pqa': 'Report PQA',
  'runs.details.downloadSynthesisResults': 'Synthesis results',
  'runs.details.legend': 'Legend',
  'runs.details.legend.concentration': 'Min. - Max. target concentration',
  'runs.details.legend.mod.ACCEPTABLE': 'Acceptable Coupling Efficiency',
  'runs.details.legend.mod.LOW_COUPLING': 'Low Coupling Efficiency',
  'runs.details.legend.mod.NON_QUANTIFIABLE': 'Non-quantifiable',
  'runs.details.legend.mod.NO_MODIFICATION': 'No modification',
  'runs.details.legend.mod.SUCCESSFUL_COUPLING': 'Successful Coupling Efficiency',
  'runs.details.plateView.concentration.tab.actual': 'Actual',
  'runs.details.plateView.concentration.tab.target': 'Target',
  'runs.details.plateView.details': 'Report details',
  'runs.details.plateView.passingOligos': '% Passing Oligos',
  'runs.details.plateView.sizeRange': 'Size Range',
  'runs.details.plateView.tab.concentration': 'Concentration',
  'runs.details.plateView.tab.modifications': 'Modifications',
  'runs.details.plateView.tab.volume': 'Volume',
  'runs.details.plateView.tab.yield': 'Yield',
  'runs.details.plateView.totalOligos': 'Total Oligos',
  'runs.details.pqa.info.comment': 'Comment',
  'runs.details.pqa.info.comment.placeholder': 'Comment on the run',
  'runs.details.pqa.info.conditionName': 'Condition Name',
  'runs.details.pqa.info.conditionName.placeholder': 'Fill Netsuite Task number',
  'runs.details.pqa.info.consoleVersion': 'Console Version',
  'runs.details.pqa.info.location': 'Location',
  'runs.details.pqa.info.plateId': 'Plate ID',
  'runs.details.pqa.info.plateId.placeholder': 'Fill name of plate ID',
  'runs.details.pqa.info.syntaxNumber': 'Syntax Number',
  'runs.details.pqa.tab': 'PQA Information',
  'runs.details.pqa.update.error.title': 'Updating error',
  'runs.details.pqa.update.success.description': 'PQA information was updated successfully.',
  'runs.details.pqa.update.success.titles': 'Success',
  'runs.details.reportDetails.creator': 'Creator',
  'runs.details.reportDetails.details': 'Details',
  'runs.details.reportDetails.endDate': 'End date',
  'runs.details.reportDetails.instrument': 'Instrument',
  'runs.details.reportDetails.instrumentVersion': 'Instrument version',
  'runs.details.reportDetails.legend': 'Legend',
  'runs.details.reportDetails.oligos': 'Oligos',
  'runs.details.reportDetails.project': 'Project',
  'runs.details.reportDetails.runId': 'Run ID',
  'runs.details.reportDetails.size': 'Size',
  'runs.details.reportDetails.startDate': 'Start date',
  'runs.details.save': 'Save',
  'runs.details.set.concentration': 'Set new concentration',
  'runs.details.tab.reportDetails': 'Report Details',
  'runs.details.unsupportedPlateType': 'Unsupported plate type',
  'runs.download.instructions': 'Download Instruction',
  'runs.download.printJob': 'Download Print Job',
  'runs.download.runReport': 'Download Report',
  'runs.download.runSynthesisReport': 'Download Synthesis Results',
  'runs.download.view.report': 'View Report',
  'runs.errors.error.body': 'The run report encountered an error. Please check error log.',
  'runs.errors.error.title': 'Run Report encountered an error.',
  'runs.errors.incomplete.body': 'The run report is still processing. Please try again later.',
  'runs.errors.incomplete.title': 'Run Report is not completed yet.',
  'runs.errors.invalidBase.label': 'Invalid base(s):',
  'runs.errors.invalidCharacter': 'Special characters (\\ / : * ? " < > |) are not allowed.',
  'runs.errors.invalidModeBase.label': 'Invalid mod base(s):',
  'runs.errors.position.label': 'Sequence should use modification at the beginning or at the end:',
  'runs.iDnaPlateEditor.deleted': 'Plate deleted.',
  'runs.iDnaPlateEditor.notAvailable': 'Error',
  'runs.listPage.header.sync.button.title': 'Sync run reports',
  'runs.listPage.tabs.all': 'All',
  'runs.listPage.tabs.myRuns': 'My runs',
  'runs.listPage.title': 'Runs',
  'runs.noData': 'No runs available',
  'runs.option.archive': 'Archive',
  'runs.option.cancel': 'Cancel',
  'runs.option.delete': 'Delete',
  'runs.option.download': 'Download Print Job',
  'runs.option.duplicate': 'Duplicate',
  'runs.option.edit': 'Edit Run',
  'runs.option.reschedule': 'Reschedule',
  'runs.option.view': 'View Details',
  'runs.plateEditor.deleted': 'This sequence file is deleted.',
  'runs.plateEditor.editWindow.buttons.update': 'Save',
  'runs.plateEditor.editWindow.mods.click': 'Click to change mod',
  'runs.plateEditor.editWindow.sequenceField.additional.title': 'Normalization Options',
  'runs.plateEditor.editWindow.sequenceField.error.maxMods':
    'Maximum amount of {maxMods} modifications already added to sequence',
  'runs.plateEditor.editWindow.sequenceField.error.modsNotAllowed': 'Modifications are not supported',
  'runs.plateEditor.editWindow.sequenceField.error.notFound': 'Modifications not found',
  'runs.plateEditor.editWindow.sequenceField.leading.sequence': 'LEADING SEQUENCE',
  'runs.plateEditor.editWindow.sequenceField.mods.btn': 'MODIFICATIONS',
  'runs.plateEditor.editWindow.sequenceField.title': 'Sequence ({value} nts max)',
  'runs.plateEditor.editWindow.sequenceName.placeholder': 'Enter sequence name',
  'runs.plateEditor.editWindow.sequenceName.title': 'Sequence name',
  'runs.plateEditor.header.actions.button.title': 'ACTIONS',
  'runs.plateEditor.header.cancel.button.title': 'Cancel',
  'runs.plateEditor.header.clear.button.title': 'Clear all fields',
  'runs.plateEditor.header.delete.button.title': 'Archive',
  'runs.plateEditor.header.done.button.title': 'Done',
  'runs.plateEditor.header.download.button.title': 'Download',
  'runs.plateEditor.header.duplicate.button.title': 'Duplicate',
  'runs.plateEditor.header.exportAsCsv.button.title': 'Export as CSV',
  'runs.plateEditor.header.info.score.modal.accepted.description':
    'No errors have been found. We do not expect any difficulties printing this.',
  'runs.plateEditor.header.info.score.modal.accepted.title': 'Standard',
  'runs.plateEditor.header.info.score.modal.difficult.description':
    'The sequence may be more difficult than more common sequences. Proceeding with the sequence may result in reduced yield and/or concentration. Optimizing sequence design is recommended.',
  'runs.plateEditor.header.info.score.modal.difficult.title': 'Difficult',
  'runs.plateEditor.header.info.score.modal.error.description':
    'The sequence contains errors. Please click on the sequence to get more details on how to resolve them.',
  'runs.plateEditor.header.info.score.modal.error.title': 'Error',
  'runs.plateEditor.header.info.score.title': 'Complexity Score',
  'runs.plateEditor.header.info.sequence.concentration.minMax': 'Min. - Max. target concentration',
  'runs.plateEditor.header.info.sequence.greyCircle.firstSentence':
    'Full circle represent a {value}nt oligo length. The number in the well represents the length of the oligo.',
  'runs.plateEditor.header.info.sequence.greyCircle.secondSentence':
    'The background of the well represents the target concentration, shown below.',
  'runs.plateEditor.header.info.sequence.modal.title': 'Sequence Legend',
  'runs.plateEditor.header.info.sequence.title': 'Sequence',
  'runs.plateEditor.header.multiple.values.title': 'Multiple values',
  'runs.plateEditor.header.multiple.wells.title': 'Multiple wells',
  'runs.plateEditor.header.print.button.title': 'Schedule a print',
  'runs.plateEditor.header.printNow.license.expired': 'Renew license to print this job.',
  'runs.plateEditor.header.printScheduleModal.header.caption':
    'Select an instrument to download the sample sheet and print.',
  'runs.plateEditor.header.printScheduleModal.input.duration.label': 'Duration',
  'runs.plateEditor.header.printScheduleModal.select.item.today': 'Today',
  'runs.plateEditor.header.printScheduleModal.warning.body':
    'Synthesis run incompatible with installed kit. You may add this run to the queue, but the current kit must be used or discarded before this run can proceed.',
  'runs.plateEditor.header.printScheduleModal.warning.title': 'Warning: Synthesis run incompatible with installed kit',
  'runs.plateEditor.header.reprint.header.caption': 'Select an instrument to re-print.',
  'runs.plateEditor.header.reprint.header.title': 'Re-print failed wells',
  'runs.plateEditor.header.reset.button.title': 'Reset',
  'runs.plateEditor.header.reset.button.tooltip': 'Restore this well to the last saved state.',
  'runs.plateEditor.header.restore.button.title': 'Restore',
  'runs.plateEditor.header.search.field.placeholder': 'Enter Sequence',
  'runs.plateEditor.header.undo.button.title': 'Undo',
  'runs.plateEditor.plate.restored.successfully': 'IDNA plate was restored successfully.',
  'runs.plateEditor.popper.header.nts': 'Length',
  'runs.plateEditor.popper.header.prefix': 'Leading Sequence',
  'runs.plateEditor.popper.header.score': 'Complexity Score',
  'runs.plateEditor.popper.header.score.unavailable': 'Unavailable',
  'runs.plateEditor.popper.header.sequence': 'SEQUENCE',
  'runs.plateEditor.popper.info.description':
    'Click on any well to view and edit a sequence. If there are warnings or errors, correct them before \n attempting to run a print job.',
  'runs.plateEditor.popper.info.title': 'Sequence editor',
  'runs.plateEditor.popper.title': 'Click to view details',
  'runs.plateEditor.popper.warning.title': 'Click to fix warning(s)',
  'runs.plateEditor.prevent.logout':
    'You have unsaved changes. Are you sure you want to log out? All unsaved changes will be lost.',
  'runs.plateEditor.prevent.navigation':
    'You have unsaved changes. Save before leaving this page. Are you sure you want to leave?',
  'runs.plateEditor.statuses.active': 'Standard',
  'runs.plateEditor.statuses.error': 'Error',
  'runs.plateEditor.statuses.warn': 'Difficult',
  'runs.pqa.wait.message':
    'Please do not press any button while the information is collecting. It will take several seconds.',
  'runs.pqa.wait.title': 'Please wait',
  'runs.status.aborted': 'Aborted',
  'runs.status.completed': 'Completed',
  'runs.status.deleted': 'Deleted',
  'runs.status.failed': 'Failed',
  'runs.status.inProgress': 'In Progress',
  'runs.status.inQueue': 'Queued',
  'runs.status.processCompleted': 'Process Completed',
  'schedule.form.button.cancel': 'Cancel',
  'schedule.form.button.confirm': 'Confirm',
  'schedule.form.centrifugationProcess.label': 'Process',
  'schedule.form.customProcessDefinitionFile.label': 'Custom process definition file',
  'schedule.form.customProcessDefinitionFile.title': 'Custom process definition file',
  'schedule.form.date.label': 'Date',
  'schedule.form.description':
    'We’ve automatically assigned the best time available. You can adjust this time based by dragging the box to another time.',
  'schedule.form.description.error': 'Description must be between 0 and {length} characters.',
  'schedule.form.description.label': 'Description (optional)',
  'schedule.form.instrument.label': 'Instrument',
  'schedule.form.instrument.title': 'Available at selected time',
  'schedule.form.now.value': 'Now',
  'schedule.form.reagents.title': 'Current Reagents Level',
  'schedule.form.resupplyNote': 'Resupply may be required',
  'schedule.form.startTime.label': 'Start time',
  'schedule.form.title': 'Best time available',
  'schedule.form.version.label': 'Run Version',
  'schedule.form.version.title': 'Run Version',
  'schedule.form.viewReagentsLevel': 'View Reagents Level',
  'schedule.noInstruments.description': 'None of the existing instruments have sufficient reagents.',
  'schedule.noInstruments.title': 'No Available Instruments',
  'scheduler.addForm.instrumentError': 'Please select an instrument.',
  'scheduler.form.centrifugation.option.default': 'Fully Automated',
  'scheduler.form.centrifugation.option.elution.96': 'Elution via Centrifugation',
  'scheduler.form.centrifugation.option.elution.hx': 'Post-Desalt and Elution via Centrifugation',
  'scheduler.form.centrifugation.option.quantification': 'Centrifugation of the Oligo Prior to Quantification',
  'scheduler.genePrint.success': 'Printing has been scheduled successfully.',
  'sequenceFiles.upload.syntax': 'Upload to SYNTAX',
  'sequences.table.errors.incomplete.body': 'This sequence file is deleted.',
  'sequences.table.errors.incomplete.title': 'Sequence file is not available.',
  'sequences.table.search.placeholder': 'Search sequence files',
  'services.page.title': 'Service & Support Troubleshooting',
  'services.page.title.microservice': 'Microservice Status',
  'settings.tab.details.kits.heading': 'Kit type configuration',
  'template.alert.locked.note':
    "This plate has already been ordered and is no longer editable. Plates can only be edited up until they are ordered. Duplicate this plate if you'd like to use it as a template for a new plate.",
  'template.alert.locked.title': 'Non-editable plate.',
  'template.congratulations.button.download': 'Download Print Job',
  'template.congratulations.dropdownTitle': 'ACTIONS',
  'template.congratulations.info':
    'Review the details below and make any edits up to the date and time of your scheduled job.',
  'template.congratulations.item.list.download': 'Download Item List',
  'template.congratulations.title': 'Congratulations your run job has been scheduled',
  'template.noData': 'No sequence files available',
  'template.outdatedNote': 'This sequence file is outdated. You can delete it.',
  'template.printNow.congratulations.title': 'Congratulations your run is ready to print',
  'template.restore.modal.text': 'Are you sure you want to restore this sequence file?',
  'template.restore.modal.title': 'Restore sequence file',
  'template.zoom': 'Zoom',
  'templates.listPage.header.add.button.title': 'New sequence file',
  'templates.listPage.status.invalid': 'Invalid',
  'templates.listPage.status.valid': 'Valid',
  'templates.listPage.tabs.all': 'All',
  'templates.listPage.tabs.archieved': 'Archived',
  'templates.listPage.tabs.myTemplates': 'My Sequence Files',
  'templates.listPage.title': 'Sequence Files',
  'templates.option.delete': 'Archive',
  'templates.option.download': 'Export as CSV',
  'templates.option.restore': 'Restore',
  'templates.option.scheduleForLater': 'Schedule for later',
  'templates.toast.restore.success.text': 'Sequence file restored successfully',
  'templates.toast.update.success.text': 'Sequence file updated.',
  'templates.toast.update.success.title': 'Success',
  'unauthorized.description':
    'we break down barriers to deliver faster results, more efficient workflows, and infinite possibilities.',
  'unauthorized.title': 'DNA ON DEMAND',
  'wip.title': 'Work in progress...',
} as const;

export default translations;
