/* eslint-disable max-len */
import { noop } from 'lodash-es';
import * as React from 'react';
import { Redirect, Route, Switch, BrowserRouter, BrowserRouterProps } from 'react-router-dom';

import { AuthLayout, MainLayout } from 'src/components/layout';
import RestrictedRoute from 'src/components/routes/RestrictedRoute';
import { ERoutes } from 'src/config/routes';
import UserContainer, { UserProvider } from 'src/containers/user';
import { DownloadsPage } from 'src/pages/downloads/DownloadsPage';
import GeneProjectsRoutes from 'src/pages/projects/GeneProjectRouter';
import { useDatadogTracking } from 'src/services/datadog/rum';
import { FeatureFlagsProvider } from 'src/services/flagsmith/FeatureFlags';

const ProjectsRoutesLazy = React.lazy(() => import(/* webpackChunkName: "ProjectsRoutes" */ 'src/pages/projects'));
const MainPageLazy = React.lazy(() => import(/* webpackChunkName: "MainPage" */ 'src/pages/main'));
const NotFoundPageLazy = React.lazy(() => import(/* webpackChunkName: "NotFoundPage" */ 'src/pages/notFound'));
const ReportsPageLazy = React.lazy(() => import(/* webpackChunkName: "ReportsPage" */ 'src/pages/reports'));
const ProfilePageLazy = React.lazy(() => import(/* webpackChunkName: "ProfilePage" */ 'src/pages/profile'));
const AuthRoutersLazy = React.lazy(() => import(/* webpackChunkName: "AuthRouters" */ 'src/pages/auth'));
const InstrumentsRoutesLazy = React.lazy(
  () => import(/* webpackChunkName: "InstrumentsRoutes" */ 'src/pages/instruments'),
);
const AccountsRoutersLazy = React.lazy(() => import(/* webpackChunkName: "AccountsRouters" */ 'src/pages/users'));
const PlatesRoutesLazy = React.lazy(() => import(/* webpackChunkName: "PlatesRoutes" */ 'src/pages/plates'));
const ServicePageLazy = React.lazy(() => import(/* webpackChunkName: "ServicePage" */ 'src/pages/service'));
const RunsRoutesLazy = React.lazy(() => import(/* webpackChunkName: "RunsRoutes" */ 'src/pages/runs/RunsRouters'));
const SampleSheetsRoutesLazy = React.lazy(
  () => import(/* webpackChunkName: "SampleSheetsRoutes" */ 'src/pages/sampleSheets/SampleSheetsRoutes'),
);

const CustomProcessDefinitionFilesLazy = React.lazy(
  () =>
    import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: "CustomProcessDefinitionFiles" */ 'src/pages/customProcessDefinitionFiles/CustomProcessDefinitionFiles'
    ),
);

const BrowserWithChildren = (props: React.PropsWithChildren<BrowserRouterProps>) => {
  const { children, ...browserProps } = props;
  return <BrowserRouter {...browserProps}>{children}</BrowserRouter>;
};

const App = () => {
  useDatadogTracking();

  return (
    <UserProvider onLogin={noop} onLogout={noop}>
      <UserContainer>
        {({ userLoading, userProfile, userPermissions, organization }) => {
          if (userLoading || userProfile === undefined) {
            return null;
          }

          if (userProfile) {
            const { pages, project } = userPermissions;
            return (
              <FeatureFlagsProvider>
                <MainLayout>
                  <Switch>
                    <RestrictedRoute accessible={pages.canViewDashboardSection} exact path={ERoutes.HOMEPAGE}>
                      <React.Suspense fallback={null}>
                        <MainPageLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute
                      accessible={pages.canViewDownloadsSection}
                      component={DownloadsPage}
                      exact
                      path={ERoutes.DOWNLOADS}
                    />
                    <RestrictedRoute accessible={pages.canViewServiceSection} exact path={ERoutes.SERVICE}>
                      <React.Suspense fallback={null}>
                        <ServicePageLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute accessible={pages.canViewProfileSection} exact path={ERoutes.PROFILE}>
                      <React.Suspense fallback={null}>
                        <ProfilePageLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute accessible={project.canCreateRuns} exact path={ERoutes.REPORTS}>
                      <React.Suspense fallback={null}>
                        <ReportsPageLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute accessible={pages.canViewInstrumentsSection} path={ERoutes.INSTRUMENTS}>
                      <React.Suspense fallback={null}>
                        <InstrumentsRoutesLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute accessible={pages.canViewAccountsSection} path={ERoutes.ACCOUNTS}>
                      <React.Suspense fallback={null}>
                        <AccountsRoutersLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute accessible={pages.canViewProjectsSection} path={ERoutes.APPLICATIONS__PROJECTS}>
                      <React.Suspense fallback={null}>
                        <ProjectsRoutesLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute
                      accessible={organization?.geneEnabled && pages.canViewProjectsSection}
                      component={GeneProjectsRoutes}
                      path={ERoutes.APPLICATIONS__PROJECTS__DESIGN}
                    />
                    <RestrictedRoute accessible={pages.canViewFilesSection} path={ERoutes.APPLICATIONS__SEQUENCE_FILES}>
                      <React.Suspense fallback={null}>
                        <SampleSheetsRoutesLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute accessible={pages.canViewReportsSection} path={ERoutes.APPLICATIONS__RUNS}>
                      <React.Suspense fallback={null}>
                        <RunsRoutesLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <RestrictedRoute accessible={pages.canViewPlatesSection} path={ERoutes.APPLICATIONS__PLATES}>
                      <React.Suspense fallback={null}>
                        <PlatesRoutesLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    {/* TODO: what are access rules */}
                    <RestrictedRoute accessible path={ERoutes.CUSTOM_PROCESSES}>
                      <React.Suspense fallback={null}>
                        <CustomProcessDefinitionFilesLazy />
                      </React.Suspense>
                    </RestrictedRoute>
                    <Route path={ERoutes.AUTH}>
                      <Redirect to={ERoutes.HOMEPAGE} />
                    </Route>
                    <Route>
                      <React.Suspense fallback={null}>
                        <NotFoundPageLazy />
                      </React.Suspense>
                    </Route>
                  </Switch>
                </MainLayout>
              </FeatureFlagsProvider>
            );
          }

          return (
            <AuthLayout>
              <BrowserWithChildren>
                <Switch>
                  <Route path={ERoutes.AUTH}>
                    <React.Suspense fallback={null}>
                      <AuthRoutersLazy />
                    </React.Suspense>
                  </Route>
                  <Route>
                    <Redirect to={ERoutes.AUTH__LOGIN} />
                  </Route>
                </Switch>
              </BrowserWithChildren>
            </AuthLayout>
          );
        }}
      </UserContainer>
    </UserProvider>
  );
};

export default App;
