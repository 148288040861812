import { Button } from '@dna-script-inc/shared-ui-library';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
`;

export const StyledBackdrop = styled.div``;

export const StyledSequenceText = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00932em;
`;

export const StyledHighLights = styled(StyledSequenceText)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 21px 0 7px;
`;

export const StyledLabel = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
`;

export const StyledPopper = styled(Popper)`
  z-index: 100;
`;
export const StyledMessage = styled(Box)`
  font-size: 12px;
  color: white;
  letter-spacing: 0;
  line-height: 1.2;
  background-color: rgb(137 140 142);
  white-space: pre-line;
`;

interface IModPopupTheme {
  visible: boolean | undefined;
}

export const ModsDropdown = styled.div<IModPopupTheme>`
  position: absolute;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
  max-width: 184px;
  max-height: 310px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background: #ffffff;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  & > ul {
    padding-left: 0;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  top: -18px;
  font-size: 12px;
  padding: 4px;
  & .MuiButton-startIcon {
    margin-left: -3px;
    margin-right: 4px;
  }
`;

export const StyledError = styled.p`
  color: #b8342a;
  font-size: 13px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  position: absolute;
  padding: 12px 12px 12px 18px;
  width: 200px;
  background: #fff;
  z-index: 1;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledMenuItem = styled(MenuItem)`
  min-width: 220px;
  font-size: 16px;
  padding-left: 24px;
`;

export const CheckBoxLabel = styled.p`
  color: #9ea0a5;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
`;

export const StyledParagraph = styled.p`
  font-style: italic;
  padding: 21px 0 7px;
`;
