import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';

import { useTranslation } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

interface ICheckboxProps {
  label: TTranslationKeys;
}

const Checkbox: React.FC<ICheckboxProps & CheckboxProps> = ({ label, ...props }) => {
  const t = useTranslation();

  return <FormControlLabel control={<MuiCheckbox name="checkedB" color="primary" {...props} />} label={t(label)} />;
};

export default Checkbox;
