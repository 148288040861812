interface IStorage {
  clear: () => void;
  get: <T>(key: string, defaultValue?: T) => T | null;
  getStorage: () => Storage;
  remove: (key: string) => void;
  set: (key: string, value: unknown) => void;
}

const makeStorage = (storage: Storage): IStorage => ({
  clear(): void {
    storage.clear();
  },

  get<T>(key: string, defaultValue?: T): T | null {
    const value = storage.getItem(key);
    if (value) return JSON.parse(value) as T;
    return defaultValue || null;
  },

  getStorage() {
    return storage;
  },

  remove(key: string): void {
    storage.removeItem(key);
  },

  set(key: string, value: unknown): void {
    storage.setItem(key, JSON.stringify(value));
  },
});

export default {
  LOCAL: makeStorage(localStorage),
  SESSION: makeStorage(sessionStorage),
};
