import { Tooltip } from '@dna-script-inc/shared-ui-library';
import React from 'react';

import { RunStatus } from '__generated__/globalTypes';
import { useTranslation } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

import { ETagColor, Tag } from './Tag';

interface IRunStatusTagProps {
  status: RunStatus;
}

export function getColor(status: RunStatus) {
  switch (status) {
    case RunStatus.COMPLETED:
    case RunStatus.PROCESS_COMPLETED: {
      return ETagColor.GREEN;
    }
    case RunStatus.IN_PROGRESS: {
      return ETagColor.ORANGE;
    }
    case RunStatus.IN_QUEUE: {
      return ETagColor.BLUE;
    }
    default: {
      return ETagColor.RED;
    }
  }
}

export function getLabel(status: RunStatus): TTranslationKeys {
  switch (status) {
    case RunStatus.COMPLETED: {
      return 'runs.status.completed';
    }
    case RunStatus.PROCESS_COMPLETED: {
      return 'runs.status.processCompleted';
    }
    case RunStatus.IN_PROGRESS: {
      return 'runs.status.inProgress';
    }
    case RunStatus.IN_QUEUE: {
      return 'runs.status.inQueue';
    }
    case RunStatus.FAILED: {
      return 'runs.status.failed';
    }
    case RunStatus.ABORTED: {
      return 'runs.status.aborted';
    }
    case RunStatus.DELETED: {
      return 'runs.status.deleted';
    }
    default: {
      return '';
    }
  }
}

export const RunStatusTag: React.FC<IRunStatusTagProps> = ({ status }) => {
  const t = useTranslation();
  const label = t(getLabel(status));
  return (
    <Tooltip title={label}>
      <Tag color={getColor(status)}>{label}</Tag>
    </Tooltip>
  );
};
