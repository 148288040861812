import WarningIcon from '@mui/icons-material/Warning';
import Grid from '@mui/material/Grid';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import styled from 'styled-components';

const StyledWarningIcon = styled(WarningIcon)`
  color: ${(props) => props.theme.colors.primary.warning[500]};
`;

const WarningsContainer = styled(Grid)`
  box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.primary.warning[500]};
  border-radius: 4px;
  color: #663a01;
  padding: 10px;
  margin-bottom: 20px;
`;

interface IWarningsModalSectionProps {
  // eslint-disable-next-line react/require-default-props
  warnings?: Pick<ISequenceMessage, 'message'>[];
}

// eslint-disable-next-line react/display-name
const WarningsModalSection = React.memo(({ warnings }: IWarningsModalSectionProps) => {
  if (!warnings || isEmpty(warnings)) return null;
  return (
    <WarningsContainer container spacing={1}>
      <Grid item xs={2}>
        <StyledWarningIcon />
      </Grid>
      <Grid item xs={10}>
        {warnings.map(({ message }) => (
          <div key={message}>{message}</div>
        ))}
      </Grid>
    </WarningsContainer>
  );
});

export default WarningsModalSection;
