import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/containers/i18n';

function usePreventNavigation(isNavigationDisabled: boolean) {
  const history = useHistory();
  const t = useTranslation();

  React.useEffect(() => {
    if (isNavigationDisabled) {
      window.onbeforeunload = (e) => {
        const dialogText = t('runs.plateEditor.prevent.navigation');
        e.returnValue = dialogText;
        return dialogText;
      };
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isNavigationDisabled, t]);

  React.useEffect(() => {
    // eslint-disable-next-line consistent-return
    const unblockNavigation = history.block((tx) => {
      if (isNavigationDisabled) {
        if (window.confirm(t('runs.plateEditor.prevent.navigation'))) {
          unblockNavigation();
          history.push(tx.pathname);
        }
        unblockNavigation();
        return false;
      }
      unblockNavigation();
      history.push(tx.pathname);
    });
  }, [history, isNavigationDisabled, t]);
}

export default usePreventNavigation;
