/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useMutation } from '@apollo/client';

import { NormalizationType, SequenceInfoDTO, SequenceStatus } from '__generated__/globalTypes';
import { useToast } from 'src/containers/toast';
import { useRunContext } from 'src/pages/runs/container';
import { MUTATION_UPDATE_TEMPLATE } from 'src/services/gql/mutations/templates';
import { useCurrentTemplate } from 'src/stores/sequenceEditor/hooks';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';
import { getErrorMessage } from 'src/utils/errors';

const useUpdateTemplate = () => {
  const [updateTemplate] = useMutation(MUTATION_UPDATE_TEMPLATE, {
    refetchQueries: ['TemplateById'],
  });

  const showToast = useToast();
  const { setLoadingSaveTemplate, templateName } = useRunContext();
  const currentTemplate = useCurrentTemplate();
  const { initializeTemplateDetails, templateDetailsLastSave } = useSequenceEditorStore();

  return async () => {
    if (!currentTemplate) return;
    if (currentTemplate.sequences?.every((x) => !x.data)) {
      showToast({
        isError: true,
        textToTranslate: 'error.title.template.error.missingSequences',
        title: 'error.title.template.update',
      });
      setLoadingSaveTemplate(false);
      return;
    }
    try {
      if (currentTemplate?.sequences) {
        setLoadingSaveTemplate(true);
        const { sequences, id: templateId } = currentTemplate;
        const sequencesForUpdate: SequenceInfoDTO[] = sequences.map((value) => {
          const { id, well, dataChunks, name, targetConcentration, normalizationType, status, usePlateNorm } = value;
          const newUsePlateNorm = normalizationType === NormalizationType.TARGET ? false : usePlateNorm;

          return {
            data: (dataChunks ?? []).join(''),
            id,
            name,
            normalizationType,
            status: status ?? SequenceStatus.ACTIVE,
            targetConcetration: targetConcentration,
            usePlateNorm: newUsePlateNorm,
            well,
          };
        });

        const result = await updateTemplate({
          variables: {
            name: templateName === templateDetailsLastSave?.name ? null : templateName,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            normalizationType: currentTemplate?.normalizationType as NormalizationType,
            runPurityEstimate: true,
            sequences: sequencesForUpdate,
            targetConcentration: currentTemplate?.targetConcentration,
            templateId,
          },
        });
        const updatedTemplate = result.data?.updateTemplate;
        if (updatedTemplate) {
          showToast({
            textToTranslate: 'templates.toast.update.success.text',
            title: 'templates.toast.update.success.title',
          });
          const newTemplate = { ...currentTemplate, ...updatedTemplate };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: type issue with errors field in TSequence
          initializeTemplateDetails(newTemplate);
          setLoadingSaveTemplate(false);
        }
      }
    } catch (e) {
      setLoadingSaveTemplate(false);
      showToast({
        isError: true,
        text: getErrorMessage(e),
        title: 'error.title.template.update',
      });
    }
  };
};

export default useUpdateTemplate;
