import { graphql } from 'src/gql';

export const PROJECT_FRAGMENT = graphql(`
  fragment ProjectItemInTableDTO on ProjectDTO {
    creator {
      id
      firstName
      lastName
    }
    admin {
      id
      firstName
      lastName
    }
    completedRuns
    countOfDraftTemplates
    countOfErrorTemplates
    countOfOligos
    countOfPassedOligos
    countOfReadyTemplates
    countOfRuns
    countOfWarningTemplates
    createdAt
    id
    name
    percentOfOligosMore200
    percentOfPassedOligos
    restrictedAccess
    runsInQueue
    status
    updatedAt
  }
`);

export const QUERY_PROJECTS_LIST = graphql(`
  query ProjectsList($status: Status!, $projectType: ProjectType!) {
    getAllProjects(filter: { status: $status, projectType: $projectType }) {
      data {
        ...ProjectItemInTableDTO
      }
      totalElementsCount
    }
  }
`);

export const QUERY_MY_PROJECTS_LIST = graphql(`
  query MyProjectsList($status: Status!, $projectType: ProjectType!) {
    getMyProjects(filter: { status: $status, projectType: $projectType }) {
      data {
        ...ProjectItemInTableDTO
      }
      totalElementsCount
    }
  }
`);

export const QUERY_PROJECT_DETAIL = graphql(`
  query ProjectDetail($id: ID!) {
    getProjectDetailsById(id: $id) {
      budget
      completedRuns
      countOfDraftTemplates
      countOfErrorTemplates
      countOfRuns
      countOfTemplates
      createdAt
      currency
      description
      expense
      id
      name
      percentOfPassedOligos
      projectType
      restrictedAccess
      runsInQueue
      updatedAt
      owner {
        id
        firstName
        lastName
      }
      admin {
        id
        firstName
        lastName
      }
      members {
        id
        firstName
        lastName
        accessPermission
        roles {
          id
          name
          type
          authority
        }
      }
      organization {
        id
        name
      }
      geneDetail {
        vector {
          id
          name
          sequence
        }
        vectorPrimers
      }
    }
  }
`);

export const QUERY_PROJECT_TEMPLATES = graphql(`
  query ProjectTemplates($projectId: ID!) {
    getTemplatesPerProject(filter: { projectId: $projectId }) {
      data {
        countOfClicks
        countOfMods
        countOfRuns
        countOfSequences
        createdAt
        exportMetaLink
        format
        id
        kitType
        longestSequence
        name
        parentIDNAId
        parentIDNAName
        partNumber
        plateType
        shortestSequence
        status
        updatedAt
        creator {
          id
          firstName
          lastName
        }
      }
      totalElementsCount
    }
  }
`);

export const QUERY_PROJECT_TEMPLATE_SIMPLIFIED = graphql(`
  query ProjectTemplatesIDs($projectId: ID!) {
    getTemplatesPerProject(filter: { projectId: $projectId }) {
      data {
        id
        kitType
      }
      totalElementsCount
    }
  }
`);

export const QUERY_PROJECT_RUNS = graphql(`
  query ProjectRuns($projectId: ID!) {
    getRunsPerProject(filter: { projectId: $projectId }) {
      data {
        countOfMods
        countOfPassedSequences
        countOfTotalSequences
        endDate
        id
        kitType
        longestSequence
        metaLink
        name
        parentIDNAId
        parentIDNAName
        percentOfPassedOligos
        plateType
        reportMetaLink
        scheduledStartDate
        shortestSequence
        status
        updatedAt
        version
        assignedInstrument {
          id
          name
        }
        creator {
          id
          firstName
          lastName
        }
        customProcess {
          id
          name
        }
      }
      totalElementsCount
    }
  }
`);

export const QUERY_DEFAULT_PROJECT = graphql(`
  query GetDefaultProjectForUser {
    getDefaultProjectForUser {
      id
      name
    }
  }
`);

export const QUERY_GET_USERS_FOR_NEW_PROJECT = graphql(`
  query GetUsersForNewProject {
    getUsersForNewProject {
      owner {
        id
        firstName
        lastName
      }
      admin {
        id
        firstName
        lastName
      }
      members {
        id
        firstName
        lastName
        accessPermission
        roles {
          id
          name
          type
          authority
        }
      }
    }
  }
`);

export const QUERY_SHORT_RUNS_GROUPS_PER_PROJECT = graphql(`
  query ProjectRunsGroupsShort($projectId: ID!) {
    getRunGroupsPerProject(filter: { projectId: $projectId }) {
      data {
        name
        version
        queued
        completed
        status
        numberOfPlates
        runs {
          id
          name
          status
          countOfPassedSequences
          countOfTotalSequences
          percentOfPassedOligos
          version
        }
      }
      totalElementsCount
    }
  }
`);
