import { useEffect, useState } from 'react';

import { useTranslation } from 'src/containers/i18n';
import { useToast } from 'src/containers/toast';
import { handlePrivateDownload } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/errors';

function useDownloadInstructionsFile(getInstructionFile: () => Promise<null | Blob>, callbackDownload?: () => void) {
  const [instructionFile, setInstructionFile] = useState<null | Blob>(null);
  const showToast = useToast();
  const t = useTranslation();

  useEffect(() => {
    getInstructionFile().then((file) => {
      if (file) {
        setInstructionFile(file);
      }
    });
  }, [getInstructionFile]);

  const handleDownloadInstructions = () => {
    callbackDownload?.();
    try {
      if (!instructionFile) {
        throw new Error(t('projects.detail.tabs.instructions.download.error'));
      }
      handlePrivateDownload(URL.createObjectURL(instructionFile), 'instructions.zip');
    } catch (err) {
      showToast({
        isError: true,
        text: getErrorMessage(err),
      });
    }
  };

  return { handleDownloadInstructions, instructionFile };
}

export default useDownloadInstructionsFile;
