import * as React from 'react';

import { PlateType } from '__generated__/globalTypes';
import {
  GetAllKitTypesInfoQuery,
  GetSupportedIdnaKitTypesQuery,
  GetSupportedKitTypesQuery,
  InstrumentVersion,
} from 'src/gql/graphql';
import { IUserPermissions } from 'src/utils/permissions/constants';

export interface IUserContext {
  allKitTypesInfo: NonNullable<GetAllKitTypesInfoQuery['getAllKitTypesInfo']>;
  allowedPlateTypes: { disabled?: boolean; label: number; value: PlateType }[];
  hasPrintingEnabled: () => boolean;
  hasValidLicense: () => boolean;
  isCloud: () => boolean;
  latestOnPremiseLicense: Omit<ILicense, 'installationName'> | undefined | null;
  organization: IOrganizationAccount | null | undefined;
  supportedInstrumentVersions: Record<InstrumentVersion, { label: string; version: InstrumentVersion }>;
  supportedKits: NonNullable<
    NonNullable<NonNullable<NonNullable<GetSupportedKitTypesQuery['getSupportedKitTypes']>[0]>['kitInfo']>[0]
  >[];
  supportedKitTypes: NonNullable<GetSupportedKitTypesQuery['getSupportedKitTypes']>;
  supportedIDNAKitTypes: NonNullable<GetSupportedIdnaKitTypesQuery['getSupportedIDNAKitTypes']>;
  supportedPlateTypes: (PlateType | null)[] | null;
  updateOrganization: (organization: IOrganizationAccount) => void;
  userIsAdmin: () => boolean;
  userLoading: boolean;
  userLogin: (user: IUser) => void;
  userLogout: () => void;
  userOrOrganizationLoading: boolean;
  userPermissions: IUserPermissions;
  userProfile: IUser | null | undefined;
  userUpdateProfile: (profile: Partial<IUser>) => void;
}

const UserContext: React.Context<IUserContext> = React.createContext<IUserContext>({} as IUserContext);

export default UserContext;
