import { Button } from '@dna-script-inc/shared-ui-library';
import Delete from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { AccessPermission } from '__generated__/globalTypes';
import { Avatar, StyledRoundedPaper } from 'src/components/common';
import { UserSelect } from 'src/components/form';
import TeamSelect from 'src/components/form/TeamSelect';
import { TUser } from 'src/components/form/types';
import { Translate } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { TTranslationKeys } from 'src/i18n';
import { useProjectManageContext } from 'src/pages/projects/containers/projectManage';
import { getPermissionForProject } from 'src/utils/permissions/project';
import { isNotEmpty } from 'src/utils/ui';

/**
 * Two different cards, one for teams, the other for users
 * Add teams as a restriction for project
 * Check use / access after
 */

const StyledPaper = styled(StyledRoundedPaper)`
  position: relative;
  padding: 20px;
`;

const StyledTable = styled(Table)`
  margin-top: 20px;
`;

const UsersRestrictWrapper = styled.div`
  margin: 5px 0 15px;

  > span {
    margin-left: 10px;
  }
`;

const AddForm = styled.div`
  max-width: 500px;
  margin-top: 20px;
  display: flex;
  align-items: flex-end;

  > * + * {
    margin-left: 30px;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 10px;
  }
`;

const FIXED_ROLES: AccessPermission[] = [AccessPermission.ADMIN];

type TAccessPermission = 'ADMIN' | 'MANAGER' | 'OWNER' | 'READ' | 'WRITE';

const AccessPermissionTranslationMap: Record<TAccessPermission, TTranslationKeys> = {
  ADMIN: 'projects.create.members.role.ADMIN',
  MANAGER: 'projects.create.members.role.MANAGER',
  OWNER: 'projects.create.members.role.OWNER',
  READ: 'projects.create.members.role.READ',
  WRITE: 'projects.create.members.role.WRITE',
};

const ProjectUsersForm = () => {
  const { project, addMembers, deleteMember, setFieldValue } = useProjectManageContext();
  const { userProfile, userPermissions } = useUserContext();
  const permissions = getPermissionForProject(userProfile, project);

  const canManageProjectAccess =
    permissions.projects.settings.canManageAccess || userPermissions.project.canManageAccess;

  const [users, setUsers] = React.useState<TUser[]>([]);
  const [teams, setTeams] = React.useState<IGroup[]>([]);
  const { owner, members, restrictedAccess } = project;

  const handleAddUsers = () => {
    if (users) {
      addMembers(users);
      setUsers([]);
    }
  };
  const handleAddTeams = () => {
    if (teams) {
      teams.forEach((team) => {
        const teamMembers = (team.users ?? [])?.map((member) => ({
          firstName: member.firstName ?? '',
          id: member.id ?? '',
          lastName: member.lastName ?? '',
        }));
        addMembers(teamMembers);
      });
      setTeams([]);
    }
  };

  const membersIds = useMemo(() => members?.map((member) => member?.id)?.filter(isNotEmpty) ?? [], [members]);
  const alreadyAddedUsers: string[] = React.useMemo(() => {
    if (!owner?.id) {
      return membersIds;
    }
    return membersIds?.concat(owner.id);
  }, [membersIds, owner?.id]);

  const handleRestricted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldValue('restrictedAccess', checked);
  };

  return (
    <>
      <UsersRestrictWrapper>
        <Switch
          id="projectRestrictedAccess"
          checked={Boolean(restrictedAccess)}
          onChange={handleRestricted}
          disabled={!canManageProjectAccess}
        />
        <span>
          <Translate id="instrument.form.members.restrict" />
        </span>
      </UsersRestrictWrapper>
      <StyledPaper>
        <AddForm>
          <TeamSelect
            label="projects.create.team.add"
            id="teamName"
            disabled={!restrictedAccess}
            value={teams}
            onChange={setTeams}
            multiple
            disableCloseOnSelect
          />
          <Button variant="secondary-gray" size="sm" id="add" onClick={handleAddTeams}>
            <Translate id="projects.create.members.button.add" />
          </Button>
        </AddForm>
        <AddForm>
          <UserSelect
            label="projects.create.member.add.name"
            id="memberName"
            disabled={!restrictedAccess}
            value={users}
            onChange={setUsers}
            userFilter={(u) => !alreadyAddedUsers.includes(u.id) && !users?.map((x) => x.id)?.includes(u.id)}
            multiple
            disableCloseOnSelect
          />
          <Button variant="secondary-gray" size="sm" id="add" onClick={handleAddUsers}>
            <Translate id="projects.create.members.button.add" />
          </Button>
        </AddForm>
        <StyledTable id="projectMemberTable">
          <TableHead>
            <TableRow>
              <TableCell>
                <Translate id="project.create.table.name" />
              </TableCell>
              <TableCell>
                <Translate id="project.create.table.role" />
              </TableCell>
              <TableCell>
                <Translate id="project.create.table.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restrictedAccess &&
              members?.map((member) => {
                if (!member) {
                  return null;
                }
                const { id, accessPermission, firstName, lastName } = member;
                const isOwner = owner?.id === id;
                const canEdit =
                  accessPermission && !FIXED_ROLES.includes(accessPermission as AccessPermission) && !isOwner;
                const userAccessPermission = isOwner ? 'OWNER' : (accessPermission as TAccessPermission);

                return (
                  <TableRow key={uuid()}>
                    <TableCell>
                      <NameWrapper>
                        <Avatar user={member} />
                        <span>
                          {firstName} {lastName}
                        </span>
                      </NameWrapper>
                    </TableCell>
                    <TableCell>
                      {userAccessPermission && <Translate id={AccessPermissionTranslationMap[userAccessPermission]} />}
                    </TableCell>
                    <TableCell>
                      {canEdit && (
                        <Button
                          variant="tertiary-gray"
                          id="deleteMember"
                          size="small"
                          onClick={() => deleteMember(member)}
                        >
                          <Delete />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </StyledTable>
      </StyledPaper>
    </>
  );
};

export default ProjectUsersForm;
