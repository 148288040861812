import Paper from '@mui/material/Paper';
import styled from 'styled-components';

import { WEBAPP_BORDER_RADIUS } from 'src/config/constants';

const StyledRoundedPaper = styled(Paper)`
  border-radius: ${WEBAPP_BORDER_RADIUS};
`;

export default StyledRoundedPaper;
