import * as React from 'react';
import styled, { css } from 'styled-components';

const StyledContentContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IStyledContentContainerProps {
  disabled?: boolean;
  radius?: string;
  selected?: boolean;
}

const CoverContainer = styled(StyledContentContainer)<IStyledContentContainerProps>`
  opacity: 0;
  ${(props) =>
    props.selected &&
    css`
      border-radius: ${props.radius};
      opacity: 1;
      border: 2px solid #050734;
    `}
  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.colors.primary.gray[200]};
      opacity: 0.7;
    `}
`;

interface ISequenceItemProps {
  // eslint-disable-next-line react/require-default-props
  radius?: string;
  searchMatched: boolean;
  // eslint-disable-next-line react/require-default-props
  selected?: boolean;
}

// eslint-disable-next-line react/display-name
const CoverBox = React.memo(({ selected = false, searchMatched, radius = '50%' }: ISequenceItemProps) => {
  return <CoverContainer selected={selected} disabled={!searchMatched} radius={radius} />;
});

export default CoverBox;
