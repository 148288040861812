import { z } from 'zod';

import { NormalizationType } from '__generated__/globalTypes';

export type TTemplate = Partial<ICreateProjectTemplate> & Partial<IProjectTemplate> & { localId?: string };

const WellValidationObject = z.object({
  concentration: z.number(),
  idnaSequence: z.string().optional(),
  name: z.string(),
  normalization: z.enum([NormalizationType.NONE, NormalizationType.TARGET, NormalizationType.LOWEST]),
  sequence: z.string(),
  well: z.string(),
});
const WellValidationObjectWithoutNormalization = WellValidationObject.omit({ normalization: true });
export const WellValidationArrayUpload = WellValidationObjectWithoutNormalization.array();
export type TWell = z.infer<typeof WellValidationObject>;
