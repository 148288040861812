import React from 'react';

import { formatErrorMessage } from 'src/utils/common';

import { Center } from './common';

interface ICenteredGraphqlErrorProps {
  error: {
    message: string;
  };
}

export const CenteredGraphqlError: React.FC<ICenteredGraphqlErrorProps> = ({ error }) => {
  return <Center>{formatErrorMessage(error.message)}</Center>;
};
