import React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';

const Item = styled.div`
  padding: 4px 48px 4px 8px;
  border-bottom: 1px solid #eee;
`;
const Styled = {
  Container: styled.div`
    display: grid;
    grid-template-columns: auto auto;
  `,
  HeaderItem: styled(Item)`
    padding-bottom: 8px;
    font-weight: 600;
  `,
  Item,
};

interface IEstimatedReagentsProps {
  estimatedReagents: ICreateProjectRun['estimatedReagents'];
}

export const EstimatedReagents = ({ estimatedReagents }: IEstimatedReagentsProps) => {
  return (
    <Styled.Container>
      <Styled.HeaderItem>
        <Translate id="printDetails.estimatedReagents.itemName" />
      </Styled.HeaderItem>
      <Styled.HeaderItem>
        <Translate id="printDetails.estimatedReagents.initialVolume" />
      </Styled.HeaderItem>
      {estimatedReagents.map(({ code, bottles }) => {
        return [
          ...bottles.map((reagentBottle) => [
            <Styled.Item key={`${code}-${reagentBottle.label}`}>{reagentBottle.label}</Styled.Item>,
            <Styled.Item key={`${code}-${reagentBottle.volumeMl}`}>{reagentBottle.volumeMl}</Styled.Item>,
          ]),
        ];
      })}
    </Styled.Container>
  );
};
