import { LinkButton as DNALinkedButton, LinkButtonProps } from '@dna-script-inc/shared-ui-library';
import React from 'react';
import { useHistory } from 'react-router-dom';

type TLinkButtonProps = LinkButtonProps & {
  to: string;
};

export default function LinkButton({ title = '', to, ...props }: TLinkButtonProps) {
  const history = useHistory();

  const handleRedirect = () => {
    history.push(to);
  };

  return <DNALinkedButton noWrap onClick={handleRedirect} title={title} {...props} />;
}
