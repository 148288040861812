// eslint-disable-next-line no-restricted-imports
import { Theme } from '@mui/material';
import { createGlobalStyle } from 'styled-components';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export default createGlobalStyle<{ theme: Theme }>`
  :focus {
    outline: 0;
  }

  html,
  body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  body {
    overscroll-behavior: none;
    position: relative;
    color: ${({ theme }) => theme.colors.primary.base.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }


  html,
  body,
  input,
  button,
  select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary.blue_dark[600]};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
  }

  button {
    border: none;
    background: none;
  }

  #app {
    height: 100%;
  }
`;
