import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--page-header-height) - var(--page-top-padding) - var(--page-bottom-padding));
`;

export const PageCenter = styled.div`
  display: flex;
  flex-basis: 50vh;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px - 96px - 32px);
`;

export default Center;
