import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { noop } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';

import { ModalOnHoverProvider, useModalOnHoverContext } from 'src/components/modalOnHover/Context/ModalOnHoverProvider';

const ChildrenWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const PopperContainer = styled(Popper)`
  margin: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  pointer-events: auto;
  text-align: center;
  z-index: 1301;
  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: ' ';
      position: absolute;
      top: -5px; // we account for the PopperContainer padding
      left: 0;
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[x-placement*='top'] .arrow {
    bottom: -10px;
    :after {
      transform: rotate(45deg);
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[x-placement*='bottom'] .arrow {
    :after {
      transform: rotate(225deg);
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

type TTitleProps = React.PropsWithChildren<{
  disabledHover?: boolean;
  disabledOnClick?: boolean;
  onClick?: () => void;
  placement?: PopperPlacementType;
  popperContent: React.ReactNode | null;
  withArrow?: boolean;
}>;

const Index = ({
  children,
  popperContent,
  onClick,
  disabledHover,
  disabledOnClick,
  withArrow = false,
  placement,
}: TTitleProps) => {
  const { anchorEl, arrowRef, setAnchorEl, setArrowRef } = useModalOnHoverContext();
  const open = Boolean(anchorEl);
  const handlePopoverOpen: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (disabledHover && !disabledOnClick) {
    return <ChildrenWrapper onClick={onClick}>{children}</ChildrenWrapper>;
  }

  if (disabledHover && disabledOnClick) {
    return <>children</>;
  }

  return (
    <ChildrenWrapper onClick={onClick} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      {children}
      <PopperContainer
        id="mouse-over-popover"
        open={open}
        placement={placement}
        anchorEl={anchorEl}
        onResize={undefined}
        onResizeCapture={undefined}
        modifiers={[
          {
            enabled: withArrow,
            name: 'arrow',
            options: {
              element: arrowRef,
              padding: 10,
            },
            phase: 'main',
          },
          {
            enabled: true,
            fn: noop,
            name: 'custom',
            options: {
              boundariesElement: 'scrollParent',
            },
            phase: 'main',
          },
        ]}
      >
        {withArrow && <div className="arrow" ref={setArrowRef} />}
        {popperContent}
      </PopperContainer>
    </ChildrenWrapper>
  );
};

const ModalOnHover: React.FC<TTitleProps> = (props) => {
  return (
    <ModalOnHoverProvider>
      <Index {...props} />
    </ModalOnHoverProvider>
  );
};
export default ModalOnHover;
