/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useFeatureFlags } from './FeatureFlags';

type TFlags = {
  isMultiGeneEnabled: boolean;
  isVectorCloningEnabled: boolean;
  isHighPlexEnabled: boolean;
  hasManualLicenseInputEnabled: boolean;
  hasSequenceListViewEnabled: boolean;
};

function useGetFlags(): TFlags {
  const { flags } = useFeatureFlags();

  return {
    hasManualLicenseInputEnabled: Boolean(flags?.license_input_manually?.enabled),
    hasSequenceListViewEnabled: Boolean(flags?.sequence_list_view_enabled?.enabled),
    isHighPlexEnabled: Boolean(flags?.high_plex?.enabled),
    isMultiGeneEnabled: Boolean(flags?.gene_multi_gene?.enabled),
    isVectorCloningEnabled: Boolean(flags?.gene_vector_cloning?.enabled),
  };
}

export default useGetFlags;
