/* eslint-disable no-underscore-dangle */
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import { hasAccessToDatadog } from 'src/config/env';
import { useUserContext } from 'src/hooks';

const initializeDatadog = () => {
  const applicationVersion = __APP_VERSION__;
  if (!hasAccessToDatadog()) {
    return;
  }

  datadogRum.init({
    applicationId: window.injectedEnv.__DD_APP_ID__,
    clientToken: window.injectedEnv.__DD_CLIENT_TOKEN__,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags'],
    env: window.injectedEnv.ENVIRONMENT,
    service: 'webapp',
    sessionReplaySampleRate: 20,
    sessionSampleRate: 100,
    site: 'us3.datadoghq.com',
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: applicationVersion,
  });

  datadogRum.startSessionReplayRecording();
};

initializeDatadog();

export const useDatadogTracking = () => {
  const { userProfile } = useUserContext();
  const { username, id, firstName, lastName } = userProfile ?? {};
  const name = `${firstName ?? ''} ${lastName ?? ''}`;

  useEffect(() => {
    if (!hasAccessToDatadog() || !username) {
      return;
    }
    datadogRum.setUser({
      email: username,
      id,
      name,
    });
  }, [id, name, username]);
};
