import * as React from 'react';

import ToastContext, { IToastContext } from './ToastContext';

export default function useToast() {
  const toastContext = React.useContext<IToastContext>(ToastContext);
  const toast = React.useMemo(() => {
    return toastContext.current.toastShow;
  }, [toastContext]);
  return toast;
}
