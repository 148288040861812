import { useTheme } from '@mui/material';
import uniqBy from 'lodash-es/uniqBy';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { StyledChip } from 'src/components/common/StyledChip';
import { Translate, useTranslation } from 'src/containers/i18n';
import { TemplateStatus } from 'src/gql/graphql';

const INVALID_TEMPLATE_STATUSES = [TemplateStatus.ERROR, TemplateStatus.OUTDATED];

function useGetTemplateStatusColumn<
  T extends {
    status?: TemplateStatus | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  const invalidTemplateStatusLabel = t('templates.listPage.status.invalid');
  const validTemplateStatusLabel = t('templates.listPage.status.valid');
  const getTemplateStatusLabel = (status: TemplateStatus | null | undefined) => {
    if (status && INVALID_TEMPLATE_STATUSES.includes(status)) {
      return invalidTemplateStatusLabel;
    }
    return validTemplateStatusLabel;
  };

  const filterSelectOptions = uniqBy(
    Object.values(TemplateStatus).map((k) => {
      const label = getTemplateStatusLabel(k);
      return { text: label, value: label };
    }),
    (s) => s.text,
  );

  return {
    Cell: ({ row }) => {
      const isStatusInvalid = row.original.status && INVALID_TEMPLATE_STATUSES.includes(row.original.status);
      const theme = useTheme();
      const { primary, secondary } = theme.colors;
      const backgroundColor = isStatusInvalid ? primary.error[700] : secondary.green[400];
      const labelId = isStatusInvalid ? 'templates.listPage.status.invalid' : 'templates.listPage.status.valid';
      return (
        <StyledChip
          $backgroundColor={backgroundColor}
          color="secondary"
          label={<Translate id={labelId} />}
          size="small"
        />
      );
    },
    Header: () => <Translate id="projects.detail.table.printJobs.status" testid="status-header" />,
    accessorFn: (row) => getTemplateStatusLabel(row.status),
    enableGlobalFilter: false,
    filterSelectOptions,
    filterVariant: 'multi-select',
    header: t('projects.detail.table.printJobs.status'),
    muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.printJobs.status.filter') },
  };
}

export default useGetTemplateStatusColumn;
