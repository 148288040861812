import React from 'react';

import { CenteredGraphqlError } from 'src/components/CenteredGraphqlError';
import { AccessDenied } from 'src/components/common';
import { ACCESS_DENIED_ERROR, PAGE_NOT_FOUND, SEQUENCE_NOT_FOUND } from 'src/config/constants';
import { formatErrorMessage } from 'src/utils/common';

interface IFormattedErrorProps {
  error: {
    message: string;
  };
}

function FormattedError(props: IFormattedErrorProps) {
  const { error } = props;
  const errorMsg = formatErrorMessage(error.message);

  if (
    errorMsg?.trim().toLowerCase() === ACCESS_DENIED_ERROR ||
    errorMsg?.trim().toLowerCase() === PAGE_NOT_FOUND ||
    errorMsg?.trim().toLowerCase() === SEQUENCE_NOT_FOUND
  ) {
    return <AccessDenied error={errorMsg} />;
  }
  return <CenteredGraphqlError error={error} />;
}

export default FormattedError;
