import { PageHeader, ConsoleLogo } from '@dna-script-inc/shared-ui-library';
import { Tabs } from '@mui/base/Tabs';
import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LicenseExpired } from 'src/components/common';
import { ERoutes } from 'src/config/routes';
import { useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import Storage from 'src/utils/storage';
import { getAccessibleNavItems } from 'src/utils/user';

import ApplicationsMenu from './ApplicationsMenu';
import { StyledTab, StyledTabsList } from './HeaderTabs';

import getNavItems from '../Sidebar/navItems';

const HeaderUserBoxLazy = React.lazy(() => import(/* webpackChunkName: "HeaderUserBox" */ './HeaderUserBox'));
const HeaderSettingsBoxLazy = React.lazy(() => import(/* webpackChunkName: "HeaderUserBox" */ './HeaderSettingsBox'));

const RightComponent = () => {
  const { organization } = useUserContext();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {organization?.geneEnabled && <ApplicationsMenu />}
      <React.Suspense fallback={null}>
        <HeaderSettingsBoxLazy />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <HeaderUserBoxLazy />
      </React.Suspense>
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    'aria-controls': `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`,
  };
}

const MiddleComponent = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}) => {
  const { organization, userProfile } = useUserContext();
  const organizationNavigationItems = getNavItems(organization);
  const accessibleNavItems = userProfile
    ? getAccessibleNavItems(organization, userProfile, organizationNavigationItems)
    : [];
  const t = useTranslation();
  const history = useHistory();

  const handleChange = (_event: React.SyntheticEvent | null, newValue: number | null | string) => {
    setSelectedTab(newValue as string);
    const tableTabs = Object.keys(Storage.SESSION.getStorage())?.filter((key) => key.includes('TABLE')) || [];
    tableTabs.forEach((key) => {
      Storage.SESSION.remove(key);
    });
  };

  return (
    <Tabs value={selectedTab} onChange={handleChange}>
      <StyledTabsList>
        {accessibleNavItems.map((navItem) => {
          return (
            <div
              key={navItem.to}
              onClick={(e) => {
                if (e.metaKey) {
                  window.open(`${window.location.origin.toString()}${navItem.to}`, '_blank');
                  return;
                }
                history.push(navItem.to);
              }}
            >
              <StyledTab value={navItem.to} {...a11yProps(0)}>
                {t(navItem.label ?? '')}
              </StyledTab>
            </div>
          );
        })}
      </StyledTabsList>
    </Tabs>
  );
};

function Header() {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState('');

  return (
    <>
      <PageHeader
        leftIcon={<ConsoleLogo />}
        middleContent={<MiddleComponent selectedTab={selectedTab} setSelectedTab={setSelectedTab} />}
        rightContent={<RightComponent />}
        onLogoClick={() => {
          history.push(ERoutes.HOMEPAGE);
          setSelectedTab('');
        }}
      />
      <LicenseExpired />
    </>
  );
}

export default Header;
