import { useQuery } from '@apollo/client';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ProjectType } from '__generated__/globalTypes';
import { AccessDenied } from 'src/components/common';
import { PageCenteredSpinner } from 'src/components/common/CenteredSpinner';
import { PageWrapper } from 'src/components/layout';
import { useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { useTabs } from 'src/hooks/useTabs';
import { QUERY_PROJECT_DETAIL } from 'src/services/gql/queries/projects';
import { getPermissionForProject } from 'src/utils/permissions/project';

import GeneCard from './components/GeneCard';
import InstructionSet from './components/InstructionSet';
import ProjectCard from './components/ProjectCard';
import ProjectHeader from './components/ProjectHeader';
import ProjectsTable from './components/ProjectsTable';

const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    border-bottom: 2px solid #cfd8dc;
  }
`;

export type TTab = 'printJobs' | 'runs';

interface IParams {
  id: string;
  tab?: TTab;
}

const ProjectsDetailPage = () => {
  const params = useParams<IParams>();
  const t = useTranslation();

  const location = useLocation();
  const { tab, handleChangeTab } = useTabs(params.tab || 'printJobs', location.pathname);

  const {
    userProfile,
    userPermissions: {
      project: { canViewPrintJobs, canViewRuns },
    },
  } = useUserContext();

  const { data, loading, error, refetch } = useQuery(QUERY_PROJECT_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: { id: params.id },
  });

  const refetchProject = () =>
    refetch({
      id: params.id,
    });

  const projectDetail = useMemo(() => data?.getProjectDetailsById, [data]);

  if (loading || !projectDetail) {
    return <PageCenteredSpinner />;
  }

  if (error) {
    return <AccessDenied error={error?.message} />;
  }

  const permissions = getPermissionForProject(userProfile, projectDetail);

  const isShowPrintJobs = canViewPrintJobs || permissions.projects.details.canViewPrintJobs;
  const isShowRuns = canViewRuns || permissions.projects.details.canViewRuns;
  const isAssemblyProject = projectDetail.projectType === ProjectType.GENE;
  const isShowInstructions = isAssemblyProject && (projectDetail?.countOfRuns ?? 0) > 0;

  const geneDetail = projectDetail?.geneDetail;

  return (
    <PageWrapper>
      {isShowInstructions && <InstructionSet projectDetail={projectDetail} />}
      <ProjectHeader projectDetail={projectDetail} />
      <ProjectCard projectDetail={projectDetail} />
      {isAssemblyProject && geneDetail && <GeneCard geneDetail={geneDetail} />}
      <StyledTabs indicatorColor="primary" onChange={handleChangeTab} textColor="primary" value={tab}>
        {isShowPrintJobs && <Tab id="printJobs" label={`${t('projects.detail.tabs.printJobs')}`} value="printJobs" />}
        {isShowRuns && <Tab id="runs" label={`${t('projects.detail.tabs.runs')}`} value="runs" />}
      </StyledTabs>
      <ProjectsTable projectDetail={projectDetail} refetchProject={refetchProject} tab={tab} />
    </PageWrapper>
  );
};

export default ProjectsDetailPage;
