import { FormattedTime, DF } from '@dna-script-inc/shared-ui-library';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { TTranslationKeys } from 'src/i18n';

function useGetCreatedAtColumn<
  T extends {
    createdAt?: string | null;
  },
>(header: TTranslationKeys = 'projects.detail.table.printJobs.updated'): MRT_ColumnDef<T> {
  const t = useTranslation();
  const { userProfile } = useUserContext();

  return {
    Cell: ({ row }) => (
      <FormattedTime
        date={row.original.createdAt ?? ''}
        format={DF.SHORT_DATE_TIME_FORMAT}
        timeZone={userProfile?.timeZone?.id}
      />
    ),
    Header: () => <Translate id={header} />,
    accessorFn: (row) => row.createdAt,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t(header),
    id: 'createdAt',
  };
}

export default useGetCreatedAtColumn;
