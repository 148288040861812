import { TabsList } from '@mui/base';
import { buttonClasses } from '@mui/base/Button';
import { Tab, tabClasses } from '@mui/base/Tab';
import styled from 'styled-components';

export const StyledTab = styled(Tab)`
  font-family: 'DINNext';
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  padding: 12px;
  margin: 6px 6px;
  border: none;
  height: 40px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #344054;
  }

  &.${tabClasses.selected} {
    background-color: #344054;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const StyledTabsList = styled(TabsList)`
  border-radius: 12px;
  word-break: keep-all;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
