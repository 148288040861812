import { graphql } from 'src/gql';

export const MUTATION_PLATE_CREATE = graphql(`
  mutation PlateCreate($fileName: String, $content: String!, $plateType: PlateType, $kitType: KitType) {
    createIDNAPlate(inputData: { fileName: $fileName, content: $content, plateType: $plateType, kitType: $kitType }) {
      id
      name
      status
      format
      createdAt
      updatedAt
      plateType
      kitType
      creator {
        id
        firstName
        lastName
      }
      countOfRuns
      countOfMods
      countOfSequences
      shortestSequence
      longestSequence
      averageSequence
      errors {
        ...TemplateMessageDTOFragment
      }
      warnings {
        ...TemplateMessageDTOFragment
      }
    }
  }
`);

export const MUTATION_PLATE_UPDATE = graphql(`
  mutation PlateUpdate($inputData: TemplateUpdateDTO!) {
    updateIDNATemplate(inputData: $inputData) {
      ...TemplateDetailsDTOFragment
    }
  }
`);

export const MUTATION_UPDATE_TEMPLATE = graphql(`
  mutation UpdateTemplate(
    $templateId: ID!
    $name: String
    $sequences: [SequenceInfoDTO!]!
    $normalizationType: NormalizationType
    $targetConcentration: Float
    $runPurityEstimate: Boolean
    $parentPlateId: ID
  ) {
    updateTemplate(
      update: {
        templateId: $templateId
        name: $name
        sequences: $sequences
        normalizationType: $normalizationType
        targetConcentration: $targetConcentration
        runPurityEstimate: $runPurityEstimate
        parentPlateId: $parentPlateId
      }
    ) {
      id
      name
      status
      errors {
        ...TemplateMessageDTOFragment
      }
      warnings {
        ...TemplateMessageDTOFragment
      }
      plateType
      prefixes {
        wellIndex
        nucChunks
        data
      }
      sequences {
        ...SequenceDTOFragment
      }
    }
  }
`);

export const MUTATION_COPY_TEMPLATE = graphql(`
  mutation CopyTemplate($parentId: ID!, $name: String!) {
    copyIDNATemplate(inputData: { templateId: $parentId, name: $name }) {
      id
    }
  }
`);

export const MUTATION_DELETE_TEMPLATE = graphql(`
  mutation DeleteTemplate($id: ID!) {
    deleteTemplate(templateId: $id) {
      status
      message
    }
  }
`);

export const MUTATION_DELETE_IDNA_TEMPLATE = graphql(`
  mutation DeleteIdnaTemplate($id: ID!) {
    deleteIDNATemplate(templateId: $id) {
      status
      message
    }
  }
`);

export const MUTATION_RESTORE_TEMPLATE = graphql(`
  mutation RestoreTemplate($id: ID!) {
    restoreTemplate(templateId: $id) {
      status
      message
    }
  }
`);

export const MUTATION_RESTORE_IDNA_TEMPLATE = graphql(`
  mutation RestoreIDNATemplate($id: ID!) {
    restoreIDNATemplate(templateId: $id) {
      status
      message
    }
  }
`);
