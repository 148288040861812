import { GetOrganizationDataQuery } from 'src/gql/graphql';

export function transformToOrganizationAccount(
  organizationData: GetOrganizationDataQuery['getOrganizationData'] | undefined,
) {
  if (!organizationData) {
    return organizationData ?? null;
  }
  return {
    ...organizationData,
    clicksEnabled: organizationData.clicksEnabled ?? false,
    customProcessEnabled: organizationData.customProcessEnabled ?? false,
    degeneratesEnabled: organizationData.degeneratesEnabled ?? false,
    geneEnabled: organizationData.geneEnabled ?? false,
    modsEnabled: organizationData.modsEnabled ?? false,
    printingEnabled: organizationData.printingEnabled ?? false,
  } as IOrganizationAccount;
}
