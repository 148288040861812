import Box from '@mui/material/Box';
import React from 'react';
import styled from 'styled-components';

import { KitTypeChip } from 'src/components/KitTypeChip';
import { TRunDetails } from 'src/pages/runs/pages/RunDetailsPage/types';

const StyledKitTypeChipContainer = styled.span`
  margin-left: 16px;
`;

interface IRunDetailsTitleProps {
  runDetails: TRunDetails | null;
  titleTranslationId: string;
}

export const RunDetailsTitle = ({ titleTranslationId, runDetails }: IRunDetailsTitleProps) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      sx={{
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
      }}
    >
      <p>{titleTranslationId}</p>
      {runDetails && (
        <StyledKitTypeChipContainer>
          <KitTypeChip type={runDetails.kitType} isWidthAuto />
        </StyledKitTypeChipContainer>
      )}
    </Box>
  );
};
