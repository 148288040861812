import styled, { css } from 'styled-components';

import { SequenceScore } from '__generated__/globalTypes';

interface IStatusColorCircleProps {
  status?: string;
}

export const StatusColorCircle = styled.span<IStatusColorCircleProps>`
  display: inline-block;
  border-radius: 50%;
  margin-right: 4px;
  margin-bottom: 1px;
  height: 8px;
  width: 8px;
  ${(props) =>
    props.status === SequenceScore.UNKNOWN &&
    css`
      background-color: ${props.theme.colors.primary.gray[300]};
    `}
  ${(props) =>
    props.status === SequenceScore.STANDARD &&
    css`
      background-color: ${props.theme.colors.secondary.green[400]};
    `}
    ${(props) =>
    (props.status === SequenceScore.DIFFICULT || props.status === SequenceScore.EXTREME) &&
    css`
      background-color: ${props.theme.colors.primary.warning[500]};
    `}
    ${(props) =>
    props.status === SequenceScore.IMPOSSIBLE &&
    css`
      background-color: ${props.theme.colors.primary.error[700]};
    `}
`;
