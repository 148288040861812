/* eslint-disable react/display-name */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Translate } from 'src/containers/i18n';

import NewFileButton from './NewFileButton';

type TUploadSequenceFileButtonProps = {
  route: string;
};

const UploadSequenceFileButton = React.forwardRef(
  ({ route }: TUploadSequenceFileButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const history = useHistory();

    return (
      <NewFileButton
        id="addSequenceFiles"
        onClick={() => {
          history.push(route);
        }}
        ref={ref}
      >
        <Translate id="templates.listPage.header.add.button.title" />
      </NewFileButton>
    );
  },
);

export default UploadSequenceFileButton;
