import { uniqBy } from 'lodash-es';
import React, { useContext } from 'react';

import { AccessPermission } from '__generated__/globalTypes';
import { TUserRole } from 'src/components/form/types';

export interface IProjectManage {
  admin?: IShortUserInfoWithNull | null;
  budget?: number | null;
  description?: string | null;
  id?: string | null;
  members?: (IProjectMember | null)[] | null;
  name?: string | null;
  owner?: IShortUserInfoWithNull | null;
  restrictedAccess?: boolean | null;
}

interface IProjectManageContainerProps {
  project: IProjectManage;
}

interface IProjectManageContext {
  addMembers: (users: (IShortUserInfo & { role?: TUserRole })[]) => void;
  deleteMember: (member: IProjectMember) => void;
  getMembersByAccessPermission: (role: string) => IProjectMember[];
  project: IProjectManage;
  setAdmin: (user: IShortUserInfo) => void;
  setFieldValue: (field: keyof IProjectManage, value: any) => void;
}

const ProjectManageContext: React.Context<IProjectManageContext> = React.createContext<IProjectManageContext>(
  {} as IProjectManageContext,
);

export const ProjectManageProvider = ({ children, project }: React.PropsWithChildren<IProjectManageContainerProps>) => {
  const [internalProject, setInternalProject] = React.useState<IProjectManage>({
    restrictedAccess: false,
    ...project,
    members: project.members || [],
  });

  const setFieldValue = (field: string, value: string) => {
    setInternalProject((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const addMembers = (users: IShortUserInfo[]) => {
    setInternalProject((prevState) => ({
      ...prevState,
      members: uniqBy(
        [
          ...(prevState.members ?? []),
          ...users.map((user) => {
            const previousMember = prevState.members?.find((member) => member?.id === user.id);
            const accessPermission = previousMember ? previousMember.accessPermission : AccessPermission.WRITE;
            return {
              accessPermission,
              firstName: user.firstName,
              id: user.id,
              lastName: user.lastName,
            };
          }),
        ],
        'id',
      ),
    }));
  };

  const deleteMember = (member: IProjectMember) => {
    setInternalProject((prevState) => {
      const members = [...(prevState.members ?? [])];
      const index = members.indexOf(member);
      if (index > -1) {
        members.splice(index, 1);
      }

      return {
        ...prevState,
        members,
      };
    });
  };

  const setAdmin = (user: IShortUserInfo) => {
    setInternalProject((prevState) => {
      const { members } = prevState;
      const adminIndex = members?.findIndex((member) => member?.accessPermission === AccessPermission.ADMIN);
      if (typeof adminIndex === 'number' && adminIndex > -1) {
        members?.splice(adminIndex, 1);
      }

      return {
        ...prevState,
        members: [
          ...(members ?? []),
          {
            accessPermission: AccessPermission.ADMIN,
            firstName: user.firstName,
            id: user.id,
            lastName: user.lastName,
          },
        ],
      };
    });
  };

  const getMembersByAccessPermission = (accessPermission: string): IProjectMember[] => {
    const { members } = internalProject;
    return (
      members?.filter(
        (member): member is IProjectMember => Boolean(member) && member?.accessPermission === accessPermission,
      ) ?? []
    );
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context: IProjectManageContext = {
    addMembers,
    deleteMember,
    getMembersByAccessPermission,
    project: internalProject,
    setAdmin,
    setFieldValue,
  };

  return <ProjectManageContext.Provider value={context}>{children}</ProjectManageContext.Provider>;
};

export function useProjectManageContext() {
  return useContext<IProjectManageContext>(ProjectManageContext);
}
