import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';

function useGetSizeRangeColumn<
  T extends {
    longestSequence?: number | null;
    shortestSequence?: number | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Header: () => <Translate id="projects.detail.table.runs.sizeRange" testid="size-range-header" />,
    accessorFn: (row) => `${row.shortestSequence ?? ''}-${row.longestSequence ?? ''}`,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.detail.table.runs.sizeRange'),
    size: 80,
  };
}

export default useGetSizeRangeColumn;
