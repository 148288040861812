import React from 'react';

import { ProjectSchedulerProvider } from './ProjectSchedulerContainer';
import ProjectSchedulerContext, { IProjectSchedulerContext } from './ProjectSchedulerContext';

export { ProjectSchedulerProvider };

export function useProjectSchedulerContext() {
  return React.useContext<IProjectSchedulerContext>(ProjectSchedulerContext);
}
