import Grid from '@mui/material/Grid';
import React from 'react';
import styled from 'styled-components';

import { StyledRoundedPaper } from 'src/components/common';
import { TextInput, UserSelect } from 'src/components/form';
import { TUser } from 'src/components/form/types';
import { useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { useProjectManageContext, IProjectManage } from 'src/pages/projects/containers/projectManage';
import { getPermissionForProject } from 'src/utils/permissions/project';

const StyledPaper = styled(StyledRoundedPaper)`
  margin-top: 30px;
  padding: 20px;
`;

const RightGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  margin-bottom: 16px;
`;

const ProjectMainForm: React.FC = () => {
  const { userProfile, userPermissions } = useUserContext();
  const t = useTranslation();
  const { project, setFieldValue } = useProjectManageContext();

  const handleUpdateField = (field: keyof IProjectManage) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (field === 'budget') {
      setFieldValue(field, value.replace(/\D/g, ''));
    } else {
      setFieldValue(field, value);
    }
  };

  const permissions = getPermissionForProject(userProfile, project);

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Row>
            <TextInput
              label={t('projects.create.form.name')}
              id="projectName"
              required
              value={project.name}
              error={(project?.name ?? '').trim().length <= 0}
              onChange={handleUpdateField('name')}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Row>

          <Row>
            <TextInput
              multiline
              rows={4}
              id="projectDescription"
              label={t('projects.create.form.description')}
              value={project.description}
              onChange={handleUpdateField('description')}
            />
          </Row>
        </Grid>
        <RightGrid item xs={6}>
          <div>
            <Row>
              <UserSelect
                label="projects.create.form.owner"
                id="projectOwner"
                value={project.owner as TUser}
                onChange={(value) => setFieldValue('owner', value)}
                disabled={!permissions.projects.settings.canSetOwner || !userPermissions.project.canSetOwner}
              />
            </Row>
          </div>
        </RightGrid>
      </Grid>
    </StyledPaper>
  );
};

export default ProjectMainForm;
