import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import React from 'react';
import styled from 'styled-components';

import { StyledTabs } from 'src/components/common';
import { useTranslation } from 'src/containers/i18n';
import { useTabs } from 'src/hooks/useTabs';

const StyledTab = styled(Tab)`
  min-width: auto;
  width: 100%;
`;

enum ETemplateDetailsRightAside {
  WELL_NAME = 'WELL_NAME',
}

export interface ISequenceRightAsideProps {
  isMultipleWells?: boolean;
  wellName: string;
}

export const SequenceRightAside = ({
  wellName,
  children,
  isMultipleWells,
}: React.PropsWithChildren<ISequenceRightAsideProps>) => {
  const { tab, handleChangeTab } = useTabs(ETemplateDetailsRightAside.WELL_NAME);
  const t = useTranslation();

  const tabTitle = isMultipleWells ? t('runs.plateEditor.header.multiple.wells.title') : wellName;

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item xs={isMultipleWells ? 8 : 4}>
          <StyledTabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}>
            <StyledTab
              id={ETemplateDetailsRightAside.WELL_NAME}
              value={ETemplateDetailsRightAside.WELL_NAME}
              label={tabTitle || ''}
            />
          </StyledTabs>
        </Grid>

        <Grid item xs={8}>
          <Box mt={3} display="flex" justifyContent="flex-end" whiteSpace="nowrap">
            <Box pl={1} display="inline-block" />
          </Box>
        </Grid>
      </Grid>
      <Divider />

      <Box pl={2} pr={2} mt={1}>
        {tab === ETemplateDetailsRightAside.WELL_NAME && children}
      </Box>
    </>
  );
};
