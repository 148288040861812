import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import styled from 'styled-components';

import { LinkButton } from 'src/components/form';
import { ERoutes } from 'src/config/routes';
import { Translate } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';

const StyledErrorIcon = styled(ReportProblemOutlinedIcon)`
  font-size: 100px;
  color: #ff772e;
`;

const StyledParagraph = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  white-space: break-spaces;
  text-align: center;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  margin: 0 auto;
`;

function EmptyInstruments() {
  const {
    userPermissions: { instruments },
  } = useUserContext();

  return (
    <StyledPaper elevation={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" p={5}>
        <StyledErrorIcon color="primary" />
        <Typography variant="h4" paragraph>
          <Translate id="instruments.noinstruments.title" />
        </Typography>
        <StyledParagraph paragraph>
          <Translate id="instruments.noinstruments.text" />
        </StyledParagraph>
        {instruments.canCreateInstruments && (
          <LinkButton id="goToHome" uppercase to={ERoutes.INSTRUMENTS__CREATE}>
            <Translate id="instruments.button.new" />
          </LinkButton>
        )}
      </Box>
    </StyledPaper>
  );
}

export default EmptyInstruments;
