import { FormattedTime, DF } from '@dna-script-inc/shared-ui-library';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import styled from 'styled-components';

import useGetCreatorColumn from 'src/components/table/columns/useGetCreatorColumn';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { TTranslationKeys } from 'src/i18n';
import { getColorFromPassingPercent } from 'src/utils/ui';

import { IProjectWithUsers } from './types';

import { ETab } from '../../ProjectsListPage';

const ProjectName = styled.span`
  word-break: break-all;
`;

const StyledColorCell = styled.div<{ color: string }>`
  font-size: 14px;
  color: ${(props) => props.color};
  font-weight: 500;
`;

function useGetProjectsColumns(creators: string[], status: ETab) {
  const t = useTranslation();
  const { userProfile } = useUserContext();

  const nameColumn: MRT_ColumnDef<IProjectWithUsers> = {
    Cell: ({ renderedCellValue }) => <ProjectName>{renderedCellValue}</ProjectName>,
    Header: () => <Translate id="projects.table.name" testid="project-name-header" />,
    accessorFn: (row) => row.name ?? '',
    header: t('projects.table.name'),
    muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.table.name.filter') },
  };
  const ownerColumn = useGetCreatorColumn<IProjectWithUsers>(creators);
  const countOfOligosColumns: MRT_ColumnDef<IProjectWithUsers> = {
    Cell: ({ row }) => `${row.original?.countOfPassedOligos ?? ''}/${row.original?.countOfOligos ?? ''}`,
    Header: () => <Translate id="projects.table.oligos" testid="passing-total-oligos-header" />,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.table.oligos'),
  };
  const oligosPassedColumn: MRT_ColumnDef<IProjectWithUsers> = {
    Cell: ({ row: { original } }) =>
      original && original.percentOfPassedOligos != null ? (
        <StyledColorCell color={getColorFromPassingPercent(original.percentOfPassedOligos)}>
          {original.percentOfPassedOligos}%
        </StyledColorCell>
      ) : null,
    Header: () => <Translate id="projects.table.oligosMore" testid="passing-oligos-header" />,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.table.oligosMore'),
  };
  const runsColumn: MRT_ColumnDef<IProjectWithUsers> = {
    Cell: ({ row: { original: project } }) => {
      const validSequenceFilesCount = (project.countOfDraftTemplates ?? 0) + (project.countOfWarningTemplates ?? 0);
      const totalSequenceFiles =
        (project.countOfDraftTemplates ?? 0) +
        (project.countOfWarningTemplates ?? 0) +
        (project.countOfErrorTemplates ?? 1);
      const formattedString = `${validSequenceFilesCount}/${totalSequenceFiles}`;
      return formattedString;
    },
    Header: () => <Translate id="projects.table.runs" testid="valid-total-sequence-header" />,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.table.runs'),
  };
  const queuedColumn: MRT_ColumnDef<IProjectWithUsers> = {
    Cell: ({ row: { original: project } }) => `${project.runsInQueue ?? 0}/${project.completedRuns ?? 1}`,
    Header: () => <Translate id="projects.table.queued" testid="queued-completed-header" />,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.table.queued'),
  };
  const getUpdateAtColumn = (title: TTranslationKeys): MRT_ColumnDef<IProjectWithUsers> => ({
    Cell: ({ row: { original: project } }) =>
      project.updatedAt ? (
        <FormattedTime
          date={project.updatedAt}
          format={DF.SHORT_DATE_TIME_FORMAT}
          timeZone={userProfile?.timeZone?.id}
        />
      ) : null,
    Header: () => <Translate id={title} testid="last-updated-header" />,
    accessorFn: (row) => row.updatedAt,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t(title),
    id: 'updatedAt',
  });

  if (status === ETab.ARCHIVED) {
    const archivedColumns: MRT_ColumnDef<IProjectWithUsers>[] = [
      nameColumn,
      ownerColumn,
      getUpdateAtColumn('projects.table.archived'),
    ];
    return archivedColumns;
  }
  const columns: MRT_ColumnDef<IProjectWithUsers>[] = [
    nameColumn,
    ownerColumn,
    countOfOligosColumns,
    oligosPassedColumn,
    runsColumn,
    queuedColumn,
    getUpdateAtColumn('projects.table.updated'),
  ];
  return columns;
}

export default useGetProjectsColumns;
