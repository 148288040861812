import Autocomplete from '@mui/material/Autocomplete';
import sortBy from 'lodash-es/sortBy';
import React from 'react';

import { TextInput } from 'src/components/form';
import countries, { ICountry } from 'src/config/countries';
import { useTranslation } from 'src/containers/i18n';

function countryToFlag(isoCode: string): string {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const getCountryByCode = (code?: string): ICountry | undefined => {
  return countries.find((country) => country.code === code);
};

export interface ICountrySelectProps {
  disabled?: boolean;
  id?: string;
  onChange: (value: string) => void;
  value?: string;
  variant?: 'outlined' | 'standard';
}

const sortedCountries = sortBy(countries, 'label');

const CountrySelect: React.FC<ICountrySelectProps> = ({
  value,
  onChange,
  disabled = false,
  variant = 'standard',
  id = 'country',
}) => {
  const t = useTranslation();
  return (
    <Autocomplete
      disablePortal
      autoHighlight
      disabled={disabled}
      fullWidth
      getOptionLabel={(option) => option.label}
      id={id}
      onChange={(_e, selectedValue) => {
        if (selectedValue) {
          onChange(selectedValue.code);
        }
      }}
      options={sortedCountries}
      renderInput={(params) => (
        <TextInput
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          label={t('organization.address.country')}
          variant={variant}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} style={{ fontSize: 15 }}>
          <span style={{ fontSize: 18, marginRight: 10 }}>{countryToFlag(option.code)}</span>
          {option.label}
        </li>
      )}
      value={getCountryByCode(value)}
    />
  );
};

export default CountrySelect;
