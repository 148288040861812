import { useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';

import { Avatar } from 'src/components/common';
import { useTranslation } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';
import { QUERY_USERS_SHORT_LIST } from 'src/services/gql/queries/user';
import { isNotEmpty } from 'src/utils/ui';

import { TUser } from './types';

interface IUserSelectProps<T> {
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  id?: string;
  label: TTranslationKeys;
  multiple?: boolean;
  onChange: (value: T) => void;
  userFilter?: (user: TUser) => boolean;
  value?: T;
}

const UserSelect = <T extends TUser | TUser[]>({
  label,
  value,
  onChange,
  id,
  disabled,
  userFilter = () => true,
  multiple = false,
  disableCloseOnSelect = false,
}: IUserSelectProps<T>) => {
  const t = useTranslation();

  const { data, loading } = useQuery(QUERY_USERS_SHORT_LIST, {
    fetchPolicy: 'network-only',
  });

  const users: TUser[] = React.useMemo(() => {
    const allUsers = (data?.getUsers?.data || []).filter(isNotEmpty).map((u) => ({
      firstName: u.firstName ?? '',
      id: u.id,
      lastName: u.lastName ?? '',
      role: u.role
        ? {
            id: u.role.id,
            name: u.role.name ?? '',
            type: u.role.type ?? null,
          }
        : undefined,
    }));
    return allUsers.filter(userFilter);
  }, [data, userFilter]);

  return (
    <Autocomplete
      fullWidth
      disableClearable
      options={users}
      loading={loading}
      disabled={disabled}
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      id={id}
      autoHighlight
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Avatar user={option} />
          <span style={{ fontSize: 16, marginLeft: 10 }}>{`${option.firstName} ${option.lastName}`}</span>
        </li>
      )}
      value={value}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(_e, selectedValue) => onChange(selectedValue)}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          id={id}
          label={t(label)}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};

export default UserSelect;
