import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';

// eslint-disable-next-line prefer-arrow-callback
const TextInput: React.FC<TextFieldProps> = React.forwardRef(function TextInput(
  { label, placeholder, helperText, ...props },
  ref,
) {
  return (
    <MuiTextField
      variant="standard"
      fullWidth
      label={label || undefined}
      placeholder={placeholder || undefined}
      helperText={helperText || undefined}
      ref={ref}
      {...props}
      InputProps={{ ...(props.InputProps || {}) }}
    />
  );
});

export default TextInput;
