import { ApolloError } from '@apollo/client';

import { EUploadFileStatuses } from 'src/config/uploadFile';

export interface IExtendedApolloError extends Omit<ApolloError, 'networkError'> {
  networkError: (Error & { response: Response }) | null;
}

export function handleNetworkError(error: IExtendedApolloError): Response | null {
  if (error.networkError) {
    return error.networkError.response;
  }

  return null;
}

export function getErrorMessage(error: ApolloError | Error | unknown) {
  if (error instanceof ApolloError) {
    return error.graphQLErrors?.length ? error.graphQLErrors[0].message : error.message;
  }
  return (error as Error)?.message;
}

export function getErrorMessages(error: ApolloError | unknown) {
  if (error instanceof ApolloError) {
    return error.graphQLErrors?.length ? error.graphQLErrors.map((x) => x.message) : [error.message];
  }
  return [(error as Error)?.message];
}

export const getLocalErrorStatusForFiles = (errorType: string) => {
  switch (errorType) {
    case 'file-invalid-type':
      return EUploadFileStatuses.FILE_INVALID_TYPE;
    case 'file-too-large':
      return EUploadFileStatuses.FILE_TOO_LARGE;
    default:
      return EUploadFileStatuses.SOMETHING_GOES_WRONG;
  }
};
