/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  DataGrid,
  GridCallbackDetails,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  GridRowParams,
  MuiEvent,
  GridToolbar,
} from '@mui/x-data-grid';
import { omit, orderBy } from 'lodash-es';
import React, { useMemo } from 'react';

import { KitType, NormalizationType, PlateType } from '__generated__/globalTypes';
import { useTranslation } from 'src/containers/i18n';
import { TemplateByIdQuery } from 'src/gql/graphql';
import useGetModsAndClicksStructure from 'src/hooks/useGetModsAndClicksStructure';
import { useKitTypeConcentrationLimits } from 'src/hooks/useKitTypeConcentrationLimits';

import {
  ConcentrationEditCell,
  NormalizationEditCell,
  NormalizationRenderCell,
  SequenceCell,
  SequenceEditCell,
} from './SequenceFileSheetCells';
import { StyledPaper } from './common';

import { TWellStatus } from '../../sequenceFileDetails/components/helpers';
import { TWell } from '../types';

type TTemplateDetails = NonNullable<TemplateByIdQuery['getTemplateDetails']>;
export type TTemplateDetailsWell = NonNullable<TTemplateDetails['sequences']>[0];

export type TRow = TWell & {
  id: string;
  status?: TWellStatus;
  plateType: PlateType;
  savedSequence?: TTemplateDetailsWell;
  modsAndClicksStructure?: ReturnType<typeof useGetModsAndClicksStructure>;
};

type TSequenceFileSheetProps = {
  plate: TWell[];
  savedPlate: TemplateByIdQuery['getTemplateDetails'];
  setPlate: (plate: TWell[]) => void;
  readOnly?: boolean;
  kitType: KitType;
  plateType: PlateType;
  handleRowClick?: (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => void;
};

const getConcentrationFromNormalization = (
  row: TRow,
  currentNormalization?: NormalizationType,
  defaultConcentration?: number,
) => {
  if (row.normalization === currentNormalization) {
    return row.concentration;
  }
  if (row.normalization === NormalizationType.TARGET) {
    return defaultConcentration || 0;
  }
  if (row.normalization === NormalizationType.LOWEST) {
    return -1;
  }
  if (row.normalization === NormalizationType.NONE) {
    return 0;
  }
  return row.concentration;
};

const getNormalizationFromConcentration = (row: TRow, currentConcentration?: number) => {
  if (row.concentration === currentConcentration || row.normalization === NormalizationType.TARGET) {
    return row.normalization;
  }
  if (row.concentration > 0) {
    return NormalizationType.TARGET;
  }
  return row.normalization;
};

function SequenceFileSheet({
  plate,
  setPlate,
  savedPlate,
  readOnly = false,
  kitType,
  plateType,
  handleRowClick,
}: TSequenceFileSheetProps) {
  const modsAndClicksStructure = useGetModsAndClicksStructure(kitType);
  const { min: defaultConcentration } = useKitTypeConcentrationLimits(kitType);

  const rows: TRow[] = useMemo(
    () =>
      orderBy(
        plate.map((p) => ({
          id: p.well,
          modsAndClicksStructure,
          plateType,
          savedSequence: savedPlate?.sequences?.find((w) => w?.well === p.well),
          ...p,
          wellIndex: [...(savedPlate?.sequences ?? []), ...(savedPlate?.emptySequences ?? [])]?.find(
            (w) => w?.well === p.well,
          )?.wellIndex,
        })),
        // NOTE: we are sorting by wellIndex per request in https://dnascript.atlassian.net/browse/CN-16378
        'wellIndex',
      ),
    [plate, plateType, savedPlate, modsAndClicksStructure],
  );

  const t = useTranslation();

  const columns = useMemo<GridColDef[]>(() => {
    const baseColumns: GridColDef[] = [
      {
        editable: false,
        field: 'well',
        headerName: t('plate.create.v2.table.head.well'),
        width: 80,
      },
      {
        editable: !readOnly,
        field: 'name',
        flex: 1,
        headerName: t('plate.create.v2.table.head.sequenceName'),
        maxWidth: 200,
      },
      {
        editable: !readOnly,
        field: 'sequence',
        flex: 4,
        headerName: t('plate.create.v2.table.head.sequence'),
        minWidth: 200,
        renderCell: SequenceCell,
        renderEditCell: SequenceEditCell,
      },
      {
        editable: !readOnly,
        field: 'concentration',
        headerName: t('plate.create.v2.table.head.concentration'),
        renderEditCell: (params: GridRenderEditCellParams) => <ConcentrationEditCell {...params} kitType={kitType} />,
        type: 'number',
      },
      {
        editable: !readOnly,
        field: 'normalization',
        headerName: t('plate.create.v2.table.head.normalization'),
        renderCell: NormalizationRenderCell,
        renderEditCell: NormalizationEditCell,
        type: 'singleSelect',
        valueOptions: [
          { label: t('plates.detailsPage.details.modal.concentration.none'), value: NormalizationType.NONE },
          { label: t('plates.detailsPage.details.modal.concentration.target'), value: NormalizationType.TARGET },
          { label: t('plates.detailsPage.details.modal.concentration.lowest'), value: NormalizationType.LOWEST },
        ],
        width: 100,
      },
      {
        field: 'length',
        headerName: t('plate.create.v2.table.head.length'),
        type: 'number',
        valueGetter: (params) => (params.row as TWell).sequence.length,
        width: 70,
      },
    ];
    if (plate.some((p) => p.idnaSequence)) {
      baseColumns.splice(3, 0, {
        editable: false,
        field: 'idnaSequence',
        flex: 2,
        headerName: t('plate.create.v2.table.head.idna'),
        minWidth: 100,
      });
    }
    return baseColumns;
  }, [kitType, plate, readOnly, t]);

  const processRowUpdate = (row: TRow, oldRow: TRow) => {
    const newRow = {
      ...row,
      concentration: getConcentrationFromNormalization(row, oldRow.normalization, defaultConcentration),
      normalization: getNormalizationFromConcentration(row, oldRow.concentration),
    };
    const newPlate = plate.map((r) => (r.well === row.well ? omit(newRow, ['id']) : r));
    setPlate(newPlate);
    return newRow;
  };

  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <StyledPaper>
      <DataGrid
        rows={rows}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={columns}
        disableRowSelectionOnClick
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        processRowUpdate={processRowUpdate}
        onRowClick={handleRowClick}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
      />
    </StyledPaper>
  );
}

export default SequenceFileSheet;
