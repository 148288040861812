/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AlertCircle, AlertTriangle } from '@dna-script-inc/shared-ui-library';
import { Box, FormControl, MenuItem, Popover, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { noop } from 'lodash-es';
import React from 'react';

import { NormalizationType, SequenceStatus } from '__generated__/globalTypes';
import { SequenceConcentration } from 'src/components/sequences/SequenceConcentration';
import {
  InlineEditor,
  TSequenceForInlineEditor,
} from 'src/components/sequences/sequence/SequenceField/InlineEditor/InlineEditor';
import { useTranslation } from 'src/containers/i18n';
import { getMaxAllowedMods } from 'src/utils/mods';

import { TRow, TTemplateDetailsWell } from './SequenceFileSheet';

import { TWell } from '../types';

export const getSequenceObject = (value: string) => {
  const sequence = value;
  const sequenceWithClick = sequence;
  const sequenceList = sequence.split('');
  return {
    data: sequenceWithClick,
    dataChunks: sequenceList,
    nucChunks: sequenceList,
    nucErrors: undefined,
    nucWarnings: undefined,
    status: SequenceStatus.ACTIVE,
    well: 'A1',
  };
};

export const getSequenceObjectFromSavedSequence = (savedSequence: TTemplateDetailsWell | undefined) => {
  return {
    data: savedSequence?.data ?? '',
    dataChunks: savedSequence?.dataChunks ?? [],
    nucChunks: savedSequence?.nucChunks ?? [],
    nucErrors: savedSequence?.nucErrors ?? undefined,
    nucWarnings: savedSequence?.nucWarnings ?? undefined,
    status: savedSequence?.status ?? SequenceStatus.ACTIVE,
    well: savedSequence?.well ?? 'A1',
  };
};

const SequenceObjectStatus = ({ status }: { status?: SequenceStatus | undefined | null }) => {
  const theme = useTheme();
  if (status === SequenceStatus.ERROR) {
    return <AlertCircle htmlColor={theme.colors.primary.error[600]} />;
  }
  if (status === SequenceStatus.WARN) {
    return <AlertTriangle htmlColor={theme.colors.primary.warning[600]} />;
  }
  return null;
};

const SequenceCommonCell = (
  props: GridRenderEditCellParams<TRow> & { handleChange: (newSequence: string) => void; isEditing?: boolean },
) => {
  const {
    value,
    row: { plateType, modsAndClicksStructure, savedSequence },
    handleChange,
    isEditing = false,
  } = props;

  const sequenceObject = getSequenceObjectFromSavedSequence(savedSequence) ?? getSequenceObject(value as string);
  const key = sequenceObject.dataChunks.join('') + sequenceObject.well;

  return (
    <Box key={key} justifyContent="space-between" display="flex" alignItems="center" width="100%">
      <Box sx={{ width: '100%', wordBreak: 'break-all' }}>
        <InlineEditor
          maxAllowedMods={getMaxAllowedMods(plateType)}
          modsAndClicksStructure={modsAndClicksStructure}
          onChange={handleChange}
          onEditorReady={noop}
          sequence={sequenceObject as TSequenceForInlineEditor}
          shouldFocus={isEditing}
        />
      </Box>
      <SequenceObjectStatus status={sequenceObject.status} />
    </Box>
  );
};

export const SequenceEditCell = (props: GridRenderEditCellParams<TRow>) => {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (newSequence: string) => {
    apiRef.current.setEditCellValue({ field, id, value: newSequence });
  };

  return <SequenceCommonCell {...props} handleChange={handleChange} isEditing />;
};

export const SequenceCell = (props: GridRenderEditCellParams<TRow>) => {
  return <SequenceCommonCell {...props} handleChange={noop} />;
};

const useGetNormalizationTypes = () => {
  const t = useTranslation();
  return [
    {
      label: t('plates.detailsPage.details.modal.concentration.none'),
      value: NormalizationType.NONE,
    },
    {
      label: t('plates.detailsPage.details.modal.concentration.target'),
      value: NormalizationType.TARGET,
    },
    {
      label: t('plates.detailsPage.details.modal.concentration.lowest'),
      value: NormalizationType.LOWEST,
    },
  ];
};

export const NormalizationRenderCell = (props: GridRenderCellParams<TRow>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value } = props;
  const normalizationTypes = useGetNormalizationTypes();
  return normalizationTypes.find((normalization) => normalization.value === value)?.label ?? '';
};

export const NormalizationEditCell = (props: GridRenderEditCellParams<TRow>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const normalizationTypes = useGetNormalizationTypes();

  const handleValueChange = (event: SelectChangeEvent<any>) => {
    const newValue = event.target.value as TWell['normalization']; // The new value entered by the user
    const isValid = apiRef.current.setEditCellValue({ field, id, value: newValue });

    if (isValid) {
      apiRef.current.stopCellEditMode({ field, id });
    }
  };

  return (
    <FormControl fullWidth>
      <Select value={value as string} label="Normalization" onChange={handleValueChange}>
        {normalizationTypes.map((normalization) => {
          return (
            <MenuItem key={normalization.value} value={normalization.value}>
              {normalization.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const ConcentrationEditCell = (props: GridRenderEditCellParams<TRow>) => {
  const { id, value, field, kitType } = props;
  const apiRef = useGridApiContext();
  const handleConcentrationChange = (newConcentration: number) => {
    apiRef.current.setEditCellValue({ field, id, value: newConcentration });
  };
  const cellRef = apiRef.current.getCellElement(id, field);

  return (
    <Popover
      open
      anchorEl={cellRef}
      onClose={() => {
        apiRef.current.stopCellEditMode({ field, id });
      }}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      PaperProps={{
        sx: {
          height: '100px',
          padding: '20px',
          width: '300px',
        },
      }}
    >
      <SequenceConcentration
        kitType={kitType}
        disabled={false}
        value={value as number}
        disableConcentration={false}
        onChange={handleConcentrationChange}
      />
    </Popover>
  );
};
