import React, { FC, useMemo } from 'react';

import { SequenceScore, NormalizationType, SequenceStatus, PlateType } from '__generated__/globalTypes';
import { ISequenceChartProps } from 'src/components/sequences/sequence/SequenceChart/SequenceChart.interfaces';
import {
  SequenceChartBlock,
  SequenceChartInnerCircle,
} from 'src/components/sequences/sequence/SequenceChart/v2-96/SequenceChart.styled';
import { useKitTypeConcentrationLimits } from 'src/hooks/useKitTypeConcentrationLimits';
import { usePlateDetailsLogic } from 'src/hooks/usePlateDetailsLogic';
import { useRunContext } from 'src/pages/runs/container';
import { LOCALLY_CHANGED } from 'src/stores/sequenceEditor/constants';
import { useWellSequenceScore } from 'src/stores/sequenceEditor/hooks';
import { getCurrentWellState } from 'src/stores/sequenceEditor/selectors';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';
import { markSelectedWells, calcNumberOfNucs, isDisabledNormalization } from 'src/utils/nucleotide';

import { StyledErrorIcon, StyledSaveIcon, StyledWarningIcon } from '../../emptySequence';

export const SequenceChart: FC<ISequenceChartProps> = ({ sequence, isSelected, kitType }) => {
  const { min, max } = useKitTypeConcentrationLimits(kitType);
  const { templateDetail } = useRunContext();
  const { plate } = usePlateDetailsLogic();

  const { nucChunks, status } = sequence;
  const currentSequence = useSequenceEditorStore(getCurrentWellState(sequence.well));

  const targetConcentration = currentSequence?.targetConcentration || sequence.targetConcetration;
  const normalizationType = currentSequence?.normalizationType || sequence.normalizationType;
  const isTargetNormalization = normalizationType === NormalizationType.TARGET;

  const currentConcentration = useMemo(() => {
    if (isDisabledNormalization(normalizationType) || normalizationType === NormalizationType.LOWEST) {
      return undefined;
    }

    return targetConcentration;
  }, [normalizationType, targetConcentration]);

  const concentrationPercentage = useMemo(() => {
    return Math.max(0, Math.min(100, (((currentConcentration || 0) - min) / (max - min)) * 100));
  }, [currentConcentration, max, min]);

  const score = useWellSequenceScore(sequence.well);
  const borderStyle = markSelectedWells(isSelected);

  const ntsValue = calcNumberOfNucs(nucChunks);

  const isLocallyChanged = useMemo(() => {
    return score === LOCALLY_CHANGED;
  }, [score]);

  const isWarning = useMemo(() => {
    return (
      score === SequenceScore.DIFFICULT ||
      score === SequenceScore.EXTREME ||
      (!isLocallyChanged && status === SequenceStatus.WARN)
    );
  }, [isLocallyChanged, score, status]);

  const isError = useMemo(() => {
    return score === SequenceScore.IMPOSSIBLE || (!isLocallyChanged && status === SequenceStatus.ERROR);
  }, [isLocallyChanged, score, status]);

  const WELL_RADIUS =
    templateDetail?.plateType === PlateType.PLATE_384 || plate?.plateType === PlateType.PLATE_384 ? '4px' : '50%';

  return (
    <SequenceChartBlock
      isTarget={isTargetNormalization}
      radius={WELL_RADIUS}
      position={concentrationPercentage}
      borderStyle={borderStyle}
    >
      {!isWarning && (score === SequenceScore.STANDARD || score === SequenceScore.UNKNOWN) ? (
        ntsValue
      ) : (
        <SequenceChartInnerCircle radius={WELL_RADIUS}>
          {isLocallyChanged && <StyledSaveIcon />}
          {isError && <StyledErrorIcon />}
          {isWarning && <StyledWarningIcon />}
        </SequenceChartInnerCircle>
      )}
    </SequenceChartBlock>
  );
};
