import { useMutation } from '@apollo/client';
import { Button, ConfirmModal } from '@dna-script-inc/shared-ui-library';
import MoreVert from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ProjectType, Status } from '__generated__/globalTypes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useToast } from 'src/containers/toast';
import { useToggle } from 'src/hooks/useToggle';
import { MUTATION_RESTORE_PROJECT } from 'src/services/gql/mutations/projects';
import { QUERY_PROJECTS_LIST, QUERY_MY_PROJECTS_LIST } from 'src/services/gql/queries/projects';
import { getErrorMessage } from 'src/utils/errors';
import { isNotEmpty, stopPropagationClick } from 'src/utils/ui';

const ActionsWrapper = styled.div`
  display: flex;
`;

interface IProjectActionsProps {
  refetch: () => void;
  projectId: string;
}

const ProjectActions = ({ projectId, refetch }: IProjectActionsProps) => {
  const showToast = useToast();
  const t = useTranslation();

  const refetchQueries = useMemo(() => {
    const queries = [
      {
        query: QUERY_MY_PROJECTS_LIST,
        variables: {
          projectType: ProjectType.SYNTAX,
          status: Status.ACTIVE,
        },
      },
      {
        query: QUERY_PROJECTS_LIST,
        variables: {
          projectType: ProjectType.SYNTAX,
          status: Status.ACTIVE,
        },
      },
      {
        query: QUERY_PROJECTS_LIST,
        variables: {
          projectType: ProjectType.SYNTAX,
          status: Status.DELETED,
        },
      },
    ];
    return queries.filter(isNotEmpty);
  }, []);

  const [restoreProject] = useMutation(MUTATION_RESTORE_PROJECT, {
    awaitRefetchQueries: true,
    onCompleted() {
      showToast({
        textToTranslate: 'projects.table.restore.success.text',
        title: 'common.toast.success.title',
      });
    },
    onError(e) {
      showToast({
        isError: true,
        text: getErrorMessage(e),
      });
    },
    refetchQueries,
    variables: {
      id: projectId,
    },
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    isOpen: hasRestoreConfirmationOpened,
    close: handleRestoreConfirmationClose,
    open: handleRestoreConfirmationOpen,
  } = useToggle();

  const handleMoreClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <ActionsWrapper onClick={stopPropagationClick}>
      <Button variant="tertiary-gray" className="test-dropdownIBtn" onClick={(e) => handleMoreClick(e)} size="sm">
        <MoreVert />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="templateOptions"
        keepMounted
        onClick={stopPropagationClick}
        onClose={handleMenuClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={handleRestoreConfirmationOpen}>
          <Translate id="projects.table.action.option.restore" />
        </MenuItem>
      </Menu>
      {hasRestoreConfirmationOpened && (
        <ConfirmModal
          title={t('projects.table.restore.modal.title')}
          isOpen
          onSubmit={() => {
            restoreProject();
            refetch();
            handleRestoreConfirmationClose();
          }}
          onReject={handleRestoreConfirmationClose}
          rejectButtonTitle={t('common.button.cancel')}
          submitButtonTitle={t('common.button.confirm')}
        >
          <Translate id="projects.table.restore.modal.text" />
        </ConfirmModal>
      )}
    </ActionsWrapper>
  );
};

export default ProjectActions;
