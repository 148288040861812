import { useMutation } from '@apollo/client';
import { Button, CancelButton, Tooltip, DateTime } from '@dna-script-inc/shared-ui-library';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import Place from '@mui/icons-material/Place';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { first } from 'lodash-es';
import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ISimpleInstrument } from 'src/@types/instruments';
import type { ICalendarItem } from 'src/components/calendar/Calendar';
import { TextInput } from 'src/components/form';
import Translate from 'src/containers/i18n/Translate';
import useTranslation from 'src/containers/i18n/useTranslation';
import { useToast } from 'src/containers/toast';
import useUserContext from 'src/hooks/useUserContext';
import { MUTATION_PRINT_GENE, MUTATION_REPRINT_GENE_WELLS } from 'src/services/gql/mutations/runs';
import { getErrorMessage } from 'src/utils/errors';

import ReagentsChart from './chart/ReagentsChart';

const Wrapper = styled.div`
  margin: 32px 20px 24px;
`;

const Form = styled.div``;

const Hint = styled.div`
  font-size: 11px;
  margin-top: -21px;
  margin-bottom: 25px;

  > span {
    margin-left: 8px;
    color: #2e84c2;
    cursor: pointer;
  }
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 25px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Icon = styled.div``;

const Elements = styled.div`
  flex-grow: 1;
  margin-left: 20px;
`;

const Buttons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 16px;
  }
`;

const Instrument = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 14px;
  }
`;

const ListSubheader = styled.div`
  margin: 5px 12px 0;
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const Dot = styled.div<{ color: string }>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: ${(props) => props.color};
`;

const TooltipWrapper = styled.div`
  color: #000;

  > strong {
    font-size: 14px;
    font-weight: 500;
  }

  > p {
    margin-top: 12px;
    font-weight: 400;
  }
`;

const useStyles = makeStyles({
  arrow: {
    '&:before': {
      boxShadow: '1px 1px 2px 0 rgba(60, 64, 67, 0.3)',
    },
    color: '#fff',
    left: '16px !important',
  },
  tooltip: {
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15)',
    left: -20,
    padding: 16,
    top: -28,
  },
});

interface IPrintGeneFormProps {
  getColor?: (id: string) => string;
  instruments: ISimpleInstrument[];
  isReprintWells?: boolean;
  item: ICalendarItem;
  onCancel: () => void;
  onChange: (item: { endDate?: DateTime; instrumentId?: string; startDate?: DateTime }) => void;
  onConfirmed: () => void;
  projectId: string;
  versions?: string[];
}

const PrintGeneForm: FC<IPrintGeneFormProps> = ({
  projectId,
  item,
  onCancel,
  instruments,
  onConfirmed,
  onChange,
  getColor,
  versions,
  isReprintWells,
}) => {
  const [showReagents, toggleReagents] = useState<boolean>(false);
  const [showTooltip, toggleTooltip] = useState<boolean>(false);
  const [version, setVersion] = useState<string>(first(versions) || '');
  const reagentRef = useRef<HTMLDivElement>(null);
  const showToast = useToast();
  const classes = useStyles();
  const t = useTranslation();

  const [reprintFailedWells, { loading: loadingReprint }] = useMutation(MUTATION_REPRINT_GENE_WELLS, {
    onCompleted: () => {
      showToast({
        textToTranslate: 'scheduler.genePrint.success',
      });
      onConfirmed();
    },
    onError: (e) => {
      showToast({
        isError: true,
        text: getErrorMessage(e),
      });
    },
    refetchQueries: ['ProjectDetail', 'ProjectRunsGroups', 'ProjectRunsGroupsShort', 'ProjectTemplates'],
    variables: {
      instrumentId: item.instrumentId as string,
      projectId,
      version,
    },
  });

  const [createRun, { loading }] = useMutation(MUTATION_PRINT_GENE, {
    onCompleted: () => {
      showToast({
        textToTranslate: 'scheduler.genePrint.success',
      });
      onConfirmed();
    },
    onError: (e) => {
      showToast({
        isError: true,
        text: getErrorMessage(e),
      });
    },
    refetchQueries: ['ProjectDetail', 'ProjectRunsGroups', 'ProjectRunsGroupsShort'],
    variables: {
      instrumentId: item.instrumentId as string,
      projectId,
    },
  });

  const handleSave = () => {
    if (!item.instrumentId) {
      showToast({
        isError: true,
        textToTranslate: 'scheduler.addForm.instrumentError',
      });
      return;
    }
    if (isReprintWells) {
      reprintFailedWells();
    } else {
      createRun();
    }
  };

  const {
    userPermissions: { sequenceFiles },
  } = useUserContext();

  const handleInstrumentIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        instrumentId: e.target.value,
      });
    },
    [onChange],
  );

  useEffect(() => {
    setTimeout(() => {
      if (item.suggested) {
        toggleTooltip(true);
      }
    }, 500);
  }, [item]);

  const isDisabledConfirm = loading || !item?.instrumentId || !sequenceFiles.canSchedule;

  return (
    <>
      <Tooltip
        arrow
        classes={classes}
        open={showTooltip}
        placement="top-start"
        title={
          <TooltipWrapper>
            <strong>
              <Translate id="schedule.form.title" />
            </strong>
            <p>
              <Translate id="schedule.form.description" />
            </p>
          </TooltipWrapper>
        }
      >
        <Wrapper>
          <Form>
            <Header>
              {isReprintWells ? (
                <>
                  <Typography variant="h3">
                    <Translate id="runs.plateEditor.header.reprint.header.title" />
                  </Typography>
                  <Typography variant="caption">
                    <Translate id="runs.plateEditor.header.reprint.header.caption" />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h3">
                    <Translate id="sequenceFiles.upload.syntax" />
                  </Typography>
                  <Typography variant="caption">
                    <Translate id="runs.plateEditor.header.printScheduleModal.header.caption" />
                  </Typography>
                </>
              )}
            </Header>
            <Row>
              <Icon>
                <Place />
              </Icon>
              <Elements>
                <StyledTextInput
                  key={item.instrumentId}
                  color="secondary"
                  id="instrument"
                  label={t('schedule.form.instrument.label')}
                  onChange={handleInstrumentIdChange}
                  select
                  size="small"
                  value={item.instrumentId}
                  variant="outlined"
                >
                  <ListSubheader>
                    <Translate id="schedule.form.instrument.title" />
                  </ListSubheader>
                  {instruments.map((instrument) => (
                    <MenuItem key={instrument.id} value={instrument.id}>
                      <Instrument>
                        {getColor && <Dot color={getColor(instrument.id)} />}
                        <span>{instrument.name}</span>
                      </Instrument>
                    </MenuItem>
                  ))}
                </StyledTextInput>
                {item.instrumentId && (
                  <Hint ref={reagentRef}>
                    {/* TODO: translate */}
                    Resupply may be required
                    <span onClick={() => toggleReagents(true)}>View Reagents Level</span>
                  </Hint>
                )}
              </Elements>
            </Row>
            {isReprintWells && versions && (
              <Row>
                <Icon>
                  <FormatAlignLeft />
                </Icon>
                <Elements>
                  <StyledTextInput
                    key={version}
                    color="secondary"
                    id="version"
                    label={t('schedule.form.version.label')}
                    onChange={(ev) => setVersion(ev.target.value)}
                    select
                    size="small"
                    value={version}
                    variant="outlined"
                  >
                    <ListSubheader>
                      <Translate id="schedule.form.version.title" />
                    </ListSubheader>
                    {versions.map((v) => (
                      <MenuItem key={v} value={v}>
                        <Instrument>
                          <span>{v}</span>
                        </Instrument>
                      </MenuItem>
                    ))}
                  </StyledTextInput>
                </Elements>
              </Row>
            )}
          </Form>
          <Buttons>
            <CancelButton onClick={onCancel}>
              <Translate id="schedule.form.button.cancel" />
            </CancelButton>
            <Button
              disabled={isDisabledConfirm}
              id="confirm"
              loading={loading || loadingReprint}
              onClick={handleSave}
              variant="primary"
            >
              <Translate id="schedule.form.button.confirm" />
            </Button>
          </Buttons>
        </Wrapper>
      </Tooltip>
      <Popover anchorEl={reagentRef.current} onClose={() => toggleReagents(false)} open={showReagents}>
        {item.instrumentId && <ReagentsChart instrumentId={item.instrumentId} />}
      </Popover>
    </>
  );
};

export default PrintGeneForm;
