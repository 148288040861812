import { PlateSize } from '@dna-script-inc/shared-ui-library';
import { Box, Slider, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { PLATE_SIZE_384 } from 'src/config/constants';
import { Translate } from 'src/containers/i18n';

const MIN_SCALE = 1;
const MAX_SCALE = 3;

type TZoomControlProps = {
  scale: number;
  setScale: (scale: number) => void;
};

export const ZoomControl = ({ scale, setScale }: TZoomControlProps) => {
  const handleScaleChange = (_event: Event, newValue: number | number[]) => {
    setScale(newValue as number);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginX: '8px',
        maxWidth: '300px',
        width: '50%',
        zIndex: 10,
      }}
    >
      <Typography sx={{ marginRight: '8px' }}>
        <Translate id="template.zoom" />
      </Typography>
      <Slider step={0.2} size="small" defaultValue={1} value={scale} onChange={handleScaleChange} min={1} max={3} />
    </Box>
  );
};

export default function useZoomPlate(plateSize: PlateSize) {
  const [scale, setScale] = useState(1);

  const is384 = plateSize === PLATE_SIZE_384;

  const handleWheelEvent = useCallback(
    (event: WheelEvent) => {
      if (!event.metaKey || !is384) {
        return;
      }
      event.preventDefault();
      setScale((oldScale) => {
        const newScale = Math.min(Math.max(oldScale - event.deltaY / 1000, MIN_SCALE), MAX_SCALE);
        return newScale;
      });
    },
    [is384],
  );
  return {
    ZoomComponent: is384 ? <ZoomControl scale={scale} setScale={setScale} /> : null,
    handleWheelEvent,
    scale,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setScale: is384 ? setScale : () => {},
  };
}
