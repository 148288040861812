import Typography from '@mui/material/Typography';
import React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

const TitleWrapper = styled.div`
  margin: 14px 32px 0 0;
`;

interface ITitleProps {
  title: TTranslationKeys;
}

const Title: React.FC<ITitleProps> = ({ title }) => (
  <TitleWrapper>
    <Typography variant="h2">
      <Translate id={title} />
    </Typography>
  </TitleWrapper>
);

export default Title;
