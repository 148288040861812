import { Button, DateTime } from '@dna-script-inc/shared-ui-library';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DAYS_UNTIL_LICENSE_UPDATE_NOTIFICATION } from 'src/config/constants';
import { ERoutes } from 'src/config/routes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { useToggle } from 'src/hooks/useToggle';

const StyledWarningIcon = styled(WarningIcon)`
  font-size: 25px;
  margin: 0 10px;
  color: #ffffff;
`;

const StyledParagraph = styled(Typography)`
  color: #ffffff;
  font-size: 14px;
  white-space: break-spaces;
  margin-bottom: 0;
`;

const StyledBox = styled(Box)`
  background: #ff9800;
  flex: 1 1 100%;
`;

const StyledLink = styled(Link)`
  color: #ffffff;
  margin: 0 10px;
  text-transform: uppercase;
`;

const StyledIconButton = styled(Button)`
  color: #ffffff;
  margin: 0 10px;
`;

function LicenseExpired() {
  const t = useTranslation();
  const {
    userPermissions: { pages },
    hasValidLicense,
    latestOnPremiseLicense,
    isCloud,
  } = useUserContext();
  const { isOpen, close } = useToggle(true);
  const endLicense = DateTime.fromISO(latestOnPremiseLicense?.validTill ?? '');

  const isExpiringSoon = useMemo(() => {
    return latestOnPremiseLicense && DateTime.now().plus({ days: DAYS_UNTIL_LICENSE_UPDATE_NOTIFICATION }) > endLicense;
  }, [endLicense, latestOnPremiseLicense]);

  if (isCloud()) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  if (hasValidLicense() && !isExpiringSoon) {
    return null;
  }

  return (
    <StyledBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <StyledWarningIcon color="primary" />
        <StyledParagraph paragraph>
          {!hasValidLicense() ? (
            <Translate id="general.license.expired" />
          ) : (
            isExpiringSoon &&
            t('general.license.expiring', {
              daysBeforeExpiration: Math.floor(DateTime.now().until(endLicense).length('days')),
            })
          )}
        </StyledParagraph>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {pages.canViewOrganization && (
          <StyledLink id="goToOrganizationSettings" to={ERoutes.ACCOUNTS__ORGANIZATION}>
            <Translate id="general.license.settings" />
          </StyledLink>
        )}

        <StyledIconButton variant="tertiary-gray" aria-label="close" onClick={close}>
          <CloseIcon />
        </StyledIconButton>
      </Box>
    </StyledBox>
  );
}

export default LicenseExpired;
