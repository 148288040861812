/* eslint-disable no-param-reassign */
import { WritableDraft } from 'immer/dist/internal';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';

import { NormalizationType, SequenceStatus } from '__generated__/globalTypes';
import { CLEARED_SEQUENCE_FIELDS } from 'src/config/constants';
import { TPlateDetailsRaw, TTemplateDetailsRaw } from 'src/types';

import { ISequenceEditorState, TSequence, TTemplateDetails } from './types';

export const convertTemplateDetailsSequences = (
  sequences: TTemplateDetailsRaw['sequences'] | TPlateDetailsRaw['sequences'] | null,
) => {
  return (sequences ?? [])
    .map((sequence) => ({
      ...(sequence ?? {}),
      data: sequence?.data ?? '',
      id: sequence?.id,
      name: sequence?.name ?? '',
      normalizationType: sequence?.normalizationType ?? NormalizationType.NONE,
      status: sequence?.status ?? SequenceStatus.ACTIVE,
      targetConcentration: sequence?.targetConcetration,
      usePlateNorm: Boolean(sequence?.usePlateNorm),
      well: sequence?.well,
      wellIndex: sequence?.wellIndex,
    }))
    .filter((sequence) => Boolean(sequence.well) && typeof sequence.wellIndex === 'number') as TSequence[];
};

export const pushNewSequenceStateToWell = (
  state: WritableDraft<ISequenceEditorState>,
  well: string,
  sequence: TSequence,
) => {
  state.sequencesHistory[well].currentIndex += 1;
  state.sequencesHistory[well].states.push(sequence);
};

export const pushNewSequenceStateToWellFromPreviousState = (
  state: WritableDraft<ISequenceEditorState>,
  well: string,
  sequence: Partial<TSequence>,
) => {
  const { states: sequenceStates } = state.sequencesHistory[well];
  const lastWellState = sequenceStates.at(-1);
  if (!lastWellState) return;
  const newState = {
    ...lastWellState,
    ...sequence,
  };
  if (isEqual(newState, lastWellState)) {
    return;
  }
  state.sequencesHistory[well].currentIndex += 1;
  state.sequencesHistory[well].states.push(newState);
};

export const getDefaultSequence = (
  well: string,
  wellIndex: number,
  template: TTemplateDetails,
  defaultValue: number,
): TSequence => ({
  ...CLEARED_SEQUENCE_FIELDS,
  normalizationType: template?.normalizationType ?? NormalizationType.NONE,
  status: SequenceStatus.ACTIVE,
  targetConcentration:
    template?.normalizationType === NormalizationType.TARGET ? template.targetConcentration : defaultValue,
  usePlateNorm: true,
  well,
  wellIndex,
});

const getPropertiesToUseToCompareSequences = (sequence: TSequence) => {
  return pick(sequence, [
    'data',
    'dataChunks',
    'modsCount',
    'name',
    'normalizationType',
    'nucChunks',
    'nucErrors',
    'nucWarnings',
    'prefixDataChunks',
    'prefixNucChunks',
    'score',
    'status',
    'targetConcentration',
    'usePlateNorm',
    'warnings',
    'well',
    'wellIndex',
  ]);
};

export const areWellStatesDifferent = (newSequence: TSequence | null, oldSequence: TSequence | null | undefined) => {
  if (!newSequence) {
    return false;
  }
  if (!oldSequence) {
    const isNewSequenceEmpty = !newSequence.data && !newSequence.name;
    if (isNewSequenceEmpty) {
      return false;
    }
    return true;
  }
  return !isEqual(getPropertiesToUseToCompareSequences(newSequence), getPropertiesToUseToCompareSequences(oldSequence));
};
