/* eslint-disable camelcase */
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import styled from 'styled-components';

import { Translate, useTranslation } from 'src/containers/i18n';
import { getPercentDisplayWith2FractionDigits, isNotEmpty } from 'src/utils/ui';

const PercentWrapper = styled.span`
  margin-left: 10px;
`;

function useGetPassingPercentageColumn<
  T extends {
    countOfTotalSequences?: number | null;
    countOfPassedSequences?: number | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Cell: ({ row: { original: run } }) => {
      return run.countOfTotalSequences && isNotEmpty(run.countOfPassedSequences) ? (
        <>
          {run.countOfPassedSequences}/{run.countOfTotalSequences}
          <PercentWrapper>
            {getPercentDisplayWith2FractionDigits(run.countOfTotalSequences, run.countOfPassedSequences)}
          </PercentWrapper>
        </>
      ) : (
        '-'
      );
    },
    Header: () => (
      <Translate id="projects.detail.table.runs.passingAndTotalOligos" testid="passing-total-oligos-header" />
    ),
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.detail.table.runs.passingAndTotalOligos'),
  };
}

export default useGetPassingPercentageColumn;
