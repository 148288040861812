/* eslint-disable react/display-name */
import { Box } from '@mui/material';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { KitType } from '__generated__/globalTypes';
import { TSequenceInGrid } from 'src/components/sequences/hooks/types';
import CoverBox from 'src/components/sequences/sequence/coverBox';
import SequenceItem from 'src/components/sequences/sequence/sequenceItem';

const AnchorHelper = styled.div`
  left: calc(50% - 40px);
  top: -25px;
  position: absolute;
`;

interface ITemplateDetailsCellProps {
  isPlate384?: boolean;
  kitType: KitType | undefined | null;
  search: (sequence: TSequenceInGrid | undefined) => boolean;
  sequence?: TSequenceInGrid;
}

export const SequenceFileDetailsCell = forwardRef<HTMLDivElement, ITemplateDetailsCellProps>(
  ({ sequence, search, isPlate384, kitType }, ref) => {
    const searchMatched = search(sequence);
    const isSelected = sequence?.isSelected;

    return (
      <Box position="relative" width="100%" height={0} paddingBottom="100%">
        {sequence?.well?.toLowerCase() === 'a1' && <AnchorHelper ref={ref} />}
        <SequenceItem isSelected={isSelected} kitType={kitType} sequence={sequence as unknown as ISequence} />
        <CoverBox radius={isPlate384 ? '4px' : undefined} searchMatched={searchMatched} selected={isSelected} />
      </Box>
    );
  },
);
