import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ERoutes } from 'src/config/routes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { stopPropagationClick } from 'src/utils/ui';

const StyledTableLink = styled.div`
  display: flex;
  align-items: center;
  color: #0668b2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const DesignIdColumn = ({
  parentIDNAId,
  cellValue,
}: {
  cellValue: React.ReactNode;
  parentIDNAId: string | null | undefined;
}) => {
  const history = useHistory();
  if (!parentIDNAId) {
    return null;
  }
  return (
    <StyledTableLink
      onClick={(e) => {
        stopPropagationClick(e);
        if (parentIDNAId) {
          history.push(ERoutes.APPLICATIONS__PLATES__plateId__DETAILS.replace(':plateId', parentIDNAId));
        }
      }}
    >
      {cellValue}
    </StyledTableLink>
  );
};

function useGetDesignIdColumn<
  T extends {
    parentIDNAId?: string | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Cell: ({ renderedCellValue, row }) => (
      <DesignIdColumn cellValue={renderedCellValue} parentIDNAId={row.original.parentIDNAId} />
    ),
    Header: () => <Translate id="projects.detail.table.printJobs.designId" testid="idna-id-header" />,
    accessorFn: (row) => row.parentIDNAId ?? '',
    header: t('projects.detail.table.printJobs.designId'),
    muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.printJobs.designId.filter') },
    size: 80,
  };
}

export default useGetDesignIdColumn;
