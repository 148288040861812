import { useQuery } from '@apollo/client';
import {
  Button,
  ErrorModal,
  RunReport,
  RunReportProps,
  SaveButton,
  WarningModal,
} from '@dna-script-inc/shared-ui-library';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas-pro';
import { PDFDocument } from 'pdf-lib';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { KitType, PlateType, ProjectType, RunStatus } from '__generated__/globalTypes';
import { CenteredSpinner, FormattedError } from 'src/components/common';
import { Dropdown } from 'src/components/common/Dropdown';
import { PageHeading } from 'src/components/layout/PageHeading';
import { TBreadcrumbsItem } from 'src/components/nav/Breadcrumbs';
import { isRunCompleted } from 'src/components/table/runsTable/constants';
import { PLATE_SIZE_384, PLATE_SIZE_96 } from 'src/config/constants';
import { ERoutes } from 'src/config/routes';
import { useApplication } from 'src/containers/application';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useToast } from 'src/containers/toast';
import { RoleType } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import { RunDetailsTitle } from 'src/pages/runs/pages/RunDetailsPage/components/PlateDetailsTitle';
import { TRunDetails } from 'src/pages/runs/pages/RunDetailsPage/types';
import { ERROR_STATUSES } from 'src/services/gql/models/run';
import { QUERY_RUN_DETAILS } from 'src/services/gql/queries/runs';
import { getDownloadLink } from 'src/utils/common';
import { isNotEmpty } from 'src/utils/ui';

import { ButtonsWrapper } from './components/PlateDetailsTitle/styled';
import { PqaRunInfo } from './components/PqaRunInfoPage';
import useGetReportTranslations from './useGetReportTranslations';

import { RunReportProvider } from '../../hooks/UpdateRunReport/RunReportContainer';
import { useRunReportUpdate } from '../../hooks/UpdateRunReport/useRunReportUpdate';
import useZoomPlate from '../sequenceFileDetails/components/useZoomPlate';

// eslint-disable-next-line max-len
const breadcrumbs = (
  title: string,
  projectId?: string,
  projectName?: string,
  projectType?: ProjectType,
): TBreadcrumbsItem[] =>
  projectId && projectName
    ? [
        {
          link:
            projectType === ProjectType.GENE ? ERoutes.APPLICATIONS__PROJECTS__DESIGN : ERoutes.APPLICATIONS__PROJECTS,
          title: 'projects.title',
        },
        {
          customTitle: projectName,
          link:
            projectType === ProjectType.GENE
              ? ERoutes.APPLICATIONS__PROJECTS__DESIGN__DETAILS__id.replace(':id', projectId)
              : ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', projectId),
        },
        {
          customTitle: title,
        },
      ]
    : [
        {
          link: ERoutes.APPLICATIONS__RUNS,
          title: 'nav.synthesisData.runs',
        },
        {
          customTitle: title,
        },
      ];

enum ETabsForPqa {
  CONCENTRATION = 'concentration',
  MODIFICATIONS = 'modifications',
  PQA_INFO = 'pqaInfo',
  REPORT_DETAILS = 'report-details',
  VOLUME = 'volume',
  YIELD = 'yield',
}

interface IRunDetailsPageContentProps {
  runDetails: TRunDetails;
  title: string;
  projectId: string;
}

const RunDetailsControls = ({
  runDetails,
  isFse,
  isPqaDownloading,
  handlePqaDownload,
}: {
  runDetails: TRunDetails | null;
  isFse: boolean;
  isPqaDownloading: boolean;
  handlePqaDownload: () => void;
}) => {
  const { loading, updateRunConcentration, isRunReportWellsUpdated, isEditableGeneProject } = useRunReportUpdate();
  const isUpdateConcentrationDisabled = isRunReportWellsUpdated || loading;

  const handleDownload = () => {
    if (runDetails?.reportMetaLink) {
      const url = getDownloadLink(runDetails.reportMetaLink);
      window.open(url);
    }
  };

  const handleSynthesisResultsDownload = () => {
    if (runDetails?.reportMetaLink) {
      const url = `${getDownloadLink(runDetails.reportMetaLink)}synthesis`;
      window.open(url);
    }
  };

  if (isEditableGeneProject) {
    return (
      <Box display="flex" flexDirection="row">
        {runDetails?.reportMetaLink && (
          <Dropdown
            buttonTitle="runs.plateEditor.header.actions.button.title"
            id="run-report-dropdown"
            loading={false}
            shouldCloseOnClick
          >
            <ButtonsWrapper>
              <MenuItem id="cancel" onClick={handleDownload}>
                <Translate id="runs.details.download" />
              </MenuItem>
              <MenuItem id="cancel" onClick={handleSynthesisResultsDownload}>
                <Translate id="runs.details.downloadSynthesisResults" />
              </MenuItem>
            </ButtonsWrapper>
          </Dropdown>
        )}

        <Box flex="0 0 auto" pl={1}>
          <SaveButton
            key={String(isUpdateConcentrationDisabled)}
            disabled={isUpdateConcentrationDisabled}
            onClick={() => updateRunConcentration()}
          >
            <Translate id="runs.details.save" />
          </SaveButton>
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="row">
      {runDetails && isFse && (
        <Box flex="0 0 auto" pl={1}>
          <Button
            variant="primary"
            endIcon={<CloudDownloadIcon />}
            onClick={handlePqaDownload}
            disabled={isPqaDownloading}
            size="md"
          >
            <Translate id="runs.details.download.pqa" />
          </Button>
        </Box>
      )}
      {runDetails?.reportMetaLink && (
        <Box flex="0 0 auto" pl={1}>
          <Button variant="secondary" endIcon={<CloudDownloadIcon />} onClick={handleDownload} size="md">
            <Translate id="runs.details.download" />
          </Button>
        </Box>
      )}
      {runDetails?.reportMetaLink && (
        <Box flex="0 0 auto" pl={1}>
          <Button
            variant="secondary"
            endIcon={<CloudDownloadIcon />}
            onClick={handleSynthesisResultsDownload}
            size="md"
          >
            <Translate id="runs.details.downloadSynthesisResults" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

const ALLOWED_CODES = ['-', 'NTP', 'PSP', 'WSH', 'SYN', 'DES', 'NEZ', 'PLT'] as const;
type TAllowedCode = (typeof ALLOWED_CODES)[number];

const convertReagentCode = (code: string | null | undefined) => {
  if (!code) {
    return '-';
  }
  if ((ALLOWED_CODES as unknown as string[]).includes(code)) {
    return code as TAllowedCode;
  }
  return '-';
};

const RunDetailsPageContent = ({ runDetails, title, projectId }: IRunDetailsPageContentProps) => {
  const history = useHistory();
  const showToast = useToast();
  const t = useTranslation();
  const translations = useGetReportTranslations();
  const { getConcentrationLimitsByKitType } = useApplication();
  const { updatedWells, setUpdatedWells } = useRunReportUpdate();
  const { userProfile } = useUserContext();
  const reportRef = useRef<HTMLDivElement | null>(null);
  const [tabs, setTabs] = useState<string[]>([]);
  const [isPqaDownloading, setIsPqaDownloading] = useState(false);
  const isFSE = userProfile?.role.type === RoleType.SERVICE;

  useEffect(() => {
    const reportWrapperRef = reportRef.current;
    if (reportWrapperRef) {
      const tabElements = reportWrapperRef.querySelectorAll<HTMLElement>('.MuiTab-root');
      const tabData = Array.from(tabElements)
        .map((el) => el.getAttribute('id') || '')
        .filter((tab) => (Object.values(ETabsForPqa) as string[]).includes(tab));
      setTabs(tabData);
    }
  }, []);

  const run: RunReportProps['run'] = {
    ...runDetails,
    countOfClicks: runDetails?.countOfClicks ?? 0,
    countOfMods: runDetails?.countOfMods ?? 0,
    countOfPassedSequences: runDetails?.countOfPassedSequences ?? 0,
    creator: {
      firstName: runDetails?.creator?.firstName ?? '',
      id: runDetails?.creator?.id ?? '',
      lastName: runDetails?.creator?.lastName ?? '',
    },
    description: runDetails?.description ?? '',
    endTime: runDetails?.endTime ?? '',
    id: runDetails?.id ?? '',
    instrument: {
      id: runDetails?.instrument?.id ?? '',
      name: runDetails?.instrument?.name ?? '',
      version: runDetails?.instrument?.version ?? '',
    },
    kitType: runDetails?.kitType ?? KitType.B3_96,
    longestSequence: runDetails?.longestSequence ?? 0,
    name: runDetails?.name ?? '',
    percentOfPassedSequences: runDetails?.percentOfPassedSequences ?? 0,
    plateType: runDetails?.plateType ?? PlateType.PLATE_96,
    project: {
      id: runDetails?.project?.id ?? '',
      name: runDetails?.project?.name ?? '',
      projectType: runDetails?.project?.projectType ?? ProjectType.GENE,
    },
    reagentGroups: (runDetails?.reagentGroups ?? []).map((reagentGroup) => ({
      code: convertReagentCode(reagentGroup?.code),
      name: reagentGroup?.name ?? '',
      reagents: (reagentGroup?.reagents ?? []).filter(isNotEmpty).map((reagent) => {
        const days = parseInt(reagent?.days ?? '0', 10);
        const daysString = days < 1 ? '<1' : days.toString();
        return {
          amount: reagent?.amount ?? null,
          code: reagent?.code ?? '',
          days: daysString,
          displayName: reagent?.displayName ?? '',
          expireDate: reagent?.expireDate ?? '',
          lotNumber: reagent?.lotNumber ?? '',
          partNumber: reagent?.partNumber ?? '',
        };
      }),
    })),
    reportMetaLink: runDetails?.reportMetaLink ?? '',
    scheduledEndTime: runDetails?.scheduledEndTime ?? '',
    scheduledStartTime: runDetails?.scheduledStartTime ?? '',
    sequences: (runDetails?.sequences ?? []).map((sequence) => ({
      ...sequence,
      actualConcentration: sequence?.actualConcentration ?? 0,
      clicksCount: sequence?.clicksCount ?? 0,
      crossElution: sequence?.crossElution
        ? {
            wellsFrom: sequence?.crossElution?.wellsFrom?.filter(isNotEmpty) ?? [],
            wellsTo: sequence?.crossElution?.wellsTo?.filter(isNotEmpty) ?? [],
          }
        : undefined,
      data: sequence?.data ?? '',
      dataChunks: sequence?.dataChunks ?? [],
      errorType: sequence?.errorType ?? '',
      lastUpdatedDate: sequence?.lastUpdatedDate ?? '',
      length: sequence?.length ?? 0,
      modStatus: sequence?.modStatus ?? null,
      modified: sequence?.modified ?? false,
      modsCount: sequence?.modsCount ?? 0,
      name: sequence?.name ?? '',
      normalizationType: sequence?.normalizationType ?? null,
      nucChunks: sequence?.nucChunks ?? [],
      prefixData: sequence?.prefixData ?? null,
      prefixDataChunks: sequence?.prefixDataChunks ?? [],
      prefixNucChunks: sequence?.prefixNucChunks ?? [],
      purity: sequence?.purity ?? 0,
      score: sequence?.score ?? null,
      status: sequence?.status ?? null,
      targetConcetration: sequence?.targetConcetration ?? 0,
      validationLength: sequence?.validationLength ?? 0,
      volume: sequence?.volume ?? 0,
      well: sequence?.well ?? '',
      wellIndex: sequence?.wellIndex ?? 0,
      yield: sequence?.yield ?? 0,
    })),
    shortestSequence: runDetails?.shortestSequence ?? 0,
    startTime: runDetails?.startTime ?? '',
    status: runDetails?.status ?? RunStatus.COMPLETED,
    volumeSettings: runDetails?.volumeSettings ?? { max: 12, min: 4 },
    yieldSettings: runDetails?.yieldSettings ?? { values: [] },
  };

  const handleGeneratePdf = async () => {
    setIsPqaDownloading(true);
    showToast({
      textToTranslate: 'runs.pqa.wait.message',
      title: 'runs.pqa.wait.title',
    });
    const pdfDoc = await PDFDocument.create();
    for (let i = 0; i < tabs.length; i++) {
      const tabElement = reportRef.current!.querySelector(`[id="${tabs[i]}"]`);
      if (tabElement && tabElement instanceof HTMLButtonElement) {
        tabElement.click();
      }

      // eslint-disable-next-line
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for the content to render

      if (reportRef.current !== null) {
        // eslint-disable-next-line
        const canvas = await html2canvas(reportRef.current);
        const imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line
        const img = await pdfDoc.embedPng(imgData);

        const page = pdfDoc.addPage([img.width, img.height]);
        page.drawImage(img, {
          height: img.height,
          width: img.width,
          x: 0,
          y: 0,
        });
      }
    }

    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes]), `${run.id}-${run.name || ''}-PQA-Report.pdf`);

    setIsPqaDownloading(false);
  };

  const onClose = useCallback(() => history.push(ERoutes.APPLICATIONS__RUNS), [history]);

  const kitConcentrationLimits = useMemo(
    () => getConcentrationLimitsByKitType(runDetails.kitType),
    [getConcentrationLimitsByKitType, runDetails.kitType],
  );

  const { scale, handleWheelEvent } = useZoomPlate(
    run.plateType === PlateType.PLATE_384 ? PLATE_SIZE_384 : PLATE_SIZE_96,
  );

  if (ERROR_STATUSES.includes(runDetails.status as unknown as RunStatus)) {
    return (
      <ErrorModal
        title={t('runs.errors.error.title')}
        isOpen
        onReject={onClose}
        rejectButtonTitle={t('common.button.close')}
      >
        {runDetails?.failMessage || <Translate id="runs.errors.error.body" />}
      </ErrorModal>
    );
  }

  if (!isRunCompleted(runDetails?.status ?? '')) {
    return (
      <WarningModal
        title={t('runs.errors.incomplete.title')}
        isOpen
        onReject={onClose}
        rejectButtonTitle={t('common.button.close')}
      >
        {runDetails?.failMessage || <Translate id="runs.errors.incomplete.body" />}
      </WarningModal>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageHeading
          breadcrumbs={breadcrumbs(
            title,
            projectId,
            runDetails.project?.name ?? '',
            runDetails.project?.projectType ?? undefined,
          )}
          renderTitle={() => <RunDetailsTitle runDetails={runDetails} titleTranslationId={title} />}
          renderControls={() => (
            <RunDetailsControls
              runDetails={runDetails}
              isFse={isFSE}
              isPqaDownloading={isPqaDownloading}
              handlePqaDownload={handleGeneratePdf}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} ref={reportRef}>
        <RunReport
          updatedWells={updatedWells}
          setUpdatedWells={setUpdatedWells}
          kitConcentrationLimits={kitConcentrationLimits}
          run={run}
          sidebarTopScrollPosition={96}
          tabsSetting={
            isFSE
              ? {
                  pqaInfo: {
                    color: 'secondary',
                    component: () => PqaRunInfo({ runDetails }),
                    customLabel: t('runs.details.pqa.tab'),
                    order: -1,
                  },
                }
              : undefined
          }
          translations={translations}
          plateProps={{
            onWheelEvent: handleWheelEvent,
            scale,
          }}
        />
      </Grid>
    </Grid>
  );
};

export const RunDetailsPage = () => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { handleGetSequenceConcentrationLimits } = useApplication();

  const { data, loading, error } = useQuery(QUERY_RUN_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted(responseData) {
      if (responseData.getRunReportDetails?.project?.projectType === ProjectType.GENE) {
        handleGetSequenceConcentrationLimits(ProjectType.GENE);
      }
    },
    variables: {
      projectId: projectId || '',
      runId: id,
      shouldIncludeProject: Boolean(projectId),
    },
  });

  if (loading) {
    return <CenteredSpinner />;
  }

  if (error) {
    return <FormattedError error={error} />;
  }

  const title = data?.getRunReportDetails?.name || '';
  const runDetails = data?.getRunReportDetails as TRunDetails;

  return (
    <RunReportProvider runDetails={runDetails}>
      <RunDetailsPageContent runDetails={runDetails} title={title} projectId={projectId} />
    </RunReportProvider>
  );
};
