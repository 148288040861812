import { Tooltip } from '@dna-script-inc/shared-ui-library';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Info from '@mui/icons-material/Info';
import { Box, Paper, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import styled from 'styled-components';

import { KitType } from '__generated__/globalTypes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { TTranslationKeys } from 'src/i18n';
import { InstrumentType, TKitProperties } from 'src/utils/runs';

import { StepContainer } from './Step';

const Container = styled(Grid)`
  border: 1px solid #dbdbdb;
  margin-bottom: 30px;
`;

const TitleRow = styled(Grid)`
  padding: 24px 14px;
  border-bottom: 1px solid #dbdbdb;
  & p {
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary.gray[900]};
  }
  flex: 1;
`;

const KitColumn = styled(Grid)<{ $isChecked?: boolean }>`
  flex: 1;
  border: 1px solid #dbdbdb;
  padding: 20px 24px 16px;
  background-color: ${(props) => (props.$isChecked ? 'RGBA(5, 9, 53, 0.15)' : 'transparent')};
  & label {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  & .MuiRadio-root {
    margin-top: -17px;
    align-self: flex-start;
  }
  & .MuiFormControlLabel-label {
    color: RGBA(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500;
    white-space: pre-wrap;
  }
`;

const KitMark = styled.span`
  background: ${(props) => props.theme.colors.primary.gray[300]};
  border-radius: 4px;
  width: 40px;
  height: 30px;
  text-align: center;
  margin-left: 10px;
  display: inline-block;
`;

const InstrumentTypeTranslation: Record<InstrumentType, TTranslationKeys> = {
  [InstrumentType.STX100]: 'runs.create.upload.files.grid.instrument.STX-100',
  [InstrumentType.STX200]: 'runs.create.upload.files.grid.instrument.STX-200',
};

type TPickKitTypeProps = {
  instrumentsKits: TKitProperties[][];
  selectedKitType?: KitType | null;
  isIdna?: boolean;
  handleKitChange: (ev: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

export default function PickKitType({ instrumentsKits, selectedKitType, handleKitChange, isIdna }: TPickKitTypeProps) {
  const t = useTranslation();
  const { supportedKits } = useUserContext();

  return (
    <StepContainer>
      <Paper sx={{ padding: '16px' }}>
        <FormControl
          variant="standard"
          component="fieldset"
          sx={{
            width: '100%',
          }}
        >
          <RadioGroup aria-label="kitType" name="kitType" value={selectedKitType} onChange={handleKitChange}>
            {instrumentsKits.map((groups) => (
              <Container key={groups[0].value} container>
                <TitleRow item>
                  <Typography>
                    <Translate id={InstrumentTypeTranslation[groups[0].instrument]} />
                  </Typography>
                </TitleRow>
                <Grid container>
                  {groups.map((kit) => {
                    const supportedKit = supportedKits.find((sKit) => sKit.kitType === kit.value);
                    if (!supportedKit) {
                      return null;
                    }

                    const {
                      plateConfig: { maxIdnaMers, maxErrorMers },
                    } = supportedKit;
                    return (
                      <KitColumn xs={4} $isChecked={kit.value === selectedKitType} item key={kit.value}>
                        <FormControlLabel
                          labelPlacement="start"
                          value={kit.value}
                          control={<Radio checkedIcon={<CheckCircleIcon />} />}
                          label={
                            <Box display="flex" flexDirection="row">
                              <p>{kit.templateLabel}</p>
                              <>
                                {kit?.mark && <KitMark>{kit.mark}</KitMark>}
                                <Tooltip
                                  title={t('plate.create.v2.kit.info.title', { kitLabel: kit.label })}
                                  description={t('plate.create.v2.kit.info.description', {
                                    maximumLength: isIdna && maxIdnaMers ? maxIdnaMers : maxErrorMers,
                                  })}
                                >
                                  <Info
                                    sx={{
                                      marginLeft: '4px',
                                    }}
                                  />
                                </Tooltip>
                              </>
                            </Box>
                          }
                        />
                      </KitColumn>
                    );
                  })}
                </Grid>
              </Container>
            ))}
          </RadioGroup>
        </FormControl>
      </Paper>
    </StepContainer>
  );
}
