import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useUserContext } from 'src/hooks';

import { AccessDenied } from '../common';

interface IRestrictedRouteProps extends RouteProps {
  accessible?: boolean;
}

const RestrictedRoute: FC<IRestrictedRouteProps> = (props) => {
  const { accessible = true, component, ...rest } = props;
  const { userOrOrganizationLoading } = useUserContext();
  if (userOrOrganizationLoading) {
    return null;
  }

  return <Route {...rest} component={accessible ? component : AccessDenied} />;
};

export default RestrictedRoute;
