import { useCallback, useState } from 'react';

export function useToggle(initialValue = false) {
  const [isOpen, setIsOpen] = useState(initialValue);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const toggle = useCallback(() => {
    setIsOpen((currentState) => !currentState);
  }, [setIsOpen]);

  return {
    close,
    isOpen,
    open,
    toggle,
  };
}
