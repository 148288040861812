import { useQuery } from '@apollo/client';

import { Direction } from '__generated__/globalTypes';
import { QUERY_GROUPS_LIST } from 'src/services/gql/queries/group';

const ACTIVE_STATUS = 'ACTIVE';
const ITEMS_PER_REQUEST = 10000;

export const useGroupsList = (
  orderDirection = Direction.DESC,
  orderField = 'id',
  size = ITEMS_PER_REQUEST,
  status = ACTIVE_STATUS,
) => {
  const { data, loading, error, refetch } = useQuery(QUERY_GROUPS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      orderDirection,
      orderField,
      page: 0,
      searchText: '',
      size,
      status,
    },
  });

  return {
    data,
    error,
    loading,
    refetch,
  };
};
