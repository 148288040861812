import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import { Button, CancelButton, DeleteModal, SaveButton } from '@dna-script-inc/shared-ui-library';
import { Grid } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { AccessPermission, ProjectType, Status } from '__generated__/globalTypes';
import Title from 'src/components/common/Title';
import Breadcrumbs from 'src/components/nav/Breadcrumbs';
import { ERoutes } from 'src/config/routes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useToast } from 'src/containers/toast';
import { useUserContext } from 'src/hooks';
import { useToggle } from 'src/hooks/useToggle';
import { useProjectManageContext } from 'src/pages/projects/containers/projectManage';
import { MUTATION_DELETE_PROJECT, MUTATION_PROJECT_UPDATE } from 'src/services/gql/mutations/projects';
import { QUERY_MY_PROJECTS_LIST, QUERY_PROJECTS_LIST } from 'src/services/gql/queries/projects';
import { getErrorMessage } from 'src/utils/errors';
import { getPermissionForProject } from 'src/utils/permissions/project';
import { validateProjectName } from 'src/utils/validator';

const useGetProjectQueriesToRefetch = () => {
  const {
    userPermissions: {
      project: { canViewAllProjects },
    },
  } = useUserContext();

  const queries: InternalRefetchQueriesInclude = [
    {
      query: QUERY_MY_PROJECTS_LIST,
      variables: {
        projectType: ProjectType.SYNTAX,
        status: Status.ACTIVE,
      },
    },
  ];

  if (canViewAllProjects) {
    queries.push(
      ...[
        {
          query: QUERY_PROJECTS_LIST,
          variables: {
            projectType: ProjectType.SYNTAX,
            status: Status.ACTIVE,
          },
        },
        {
          query: QUERY_PROJECTS_LIST,
          variables: {
            projectType: ProjectType.SYNTAX,
            status: Status.DELETED,
          },
        },
      ],
    );
  }

  return queries;
};

const ProjectsManageHeader = () => {
  const { project: projectDetails } = useProjectManageContext();
  const {
    userProfile,
    userPermissions: {
      project: { canArchiveProject },
    },
  } = useUserContext();
  const permissions = getPermissionForProject(userProfile, projectDetails);
  const { isOpen, open, close } = useToggle();
  const t = useTranslation();

  const { project, getMembersByAccessPermission } = useProjectManageContext();
  const history = useHistory();
  const showToast = useToast();
  const [updateProjectMutation] = useMutation(MUTATION_PROJECT_UPDATE);

  const projectQueriesToRefetch = useGetProjectQueriesToRefetch();

  const [deleteProject, { loading }] = useMutation(MUTATION_DELETE_PROJECT, {
    onCompleted: () => {
      history.push(ERoutes.APPLICATIONS__PROJECTS);
      showToast({
        text: t('modal.delete.project.success'),
        title: 'common.toast.success.title',
      });
      close();
    },
    onError: (e) => {
      showToast({
        isError: true,
        text: getErrorMessage(e),
        title: 'error.title.project.delete',
      });
      close();
    },
    refetchQueries: projectQueriesToRefetch,
    variables: {
      id: projectDetails.id || '',
    },
  });

  const handleSave = async () => {
    // NOTE: using getMembersByAccessPermission only because project admins manipulation uses members array
    // admin from project used as fallback
    const admin = getMembersByAccessPermission(AccessPermission.ADMIN)[0] || project.admin;

    try {
      await updateProjectMutation({
        variables: {
          admin: {
            id: admin.id,
          },
          budget: project.budget || 0,
          // TODO: remove hardcode
          currency: 'USD',
          description: project.description || '',
          id: project.id!,
          name: project?.name ?? '',
          owner: {
            id: project?.owner?.id ?? '',
          },
          restrictedAccess: project.restrictedAccess,
          users: getMembersByAccessPermission(AccessPermission.WRITE).map(({ id }) => ({ id })),
        },
      });
      history.push(ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', String(project.id)));
    } catch (e) {
      showToast({
        isError: true,
        text: getErrorMessage(e),
        title: 'error.title.project.create',
      });
    }
  };

  const isValid: boolean = validateProjectName(project.name || '');

  return (
    <>
      <Breadcrumbs
        items={[
          {
            link: ERoutes.APPLICATIONS__PROJECTS,
            title: 'projects.title',
          },
          {
            customTitle: project?.name ?? '',
            link: ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', String(project.id)),
          },
          {
            title: 'projects.manage.title',
          },
        ]}
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Title title="projects.manage.title" />
        </Grid>
        <Grid item xs={6} container spacing={1} justifyContent="flex-end">
          {canArchiveProject && permissions.projects.settings.canArchiveProject && (
            <Grid item>
              <Button id="deleteDraft" onClick={open} variant="secondary-gray" destructive>
                <Translate id="projects.manage.button.delete" />
              </Button>
            </Grid>
          )}
          <Grid item>
            <CancelButton
              id="cancel"
              onClick={() => {
                history.push(ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', String(project.id)));
              }}
            >
              <Translate id="projects.manage.button.cancel" />
            </CancelButton>
          </Grid>
          <Grid item>
            <SaveButton disabled={!isValid} id="save" onClick={handleSave}>
              <Translate id="projects.manage.button.save" />
            </SaveButton>
          </Grid>
        </Grid>
      </Grid>
      {isOpen && (
        <DeleteModal
          title={t('modal.delete.project.title')}
          isOpen
          onSubmit={deleteProject}
          onReject={close}
          rejectButtonTitle={t('common.button.cancel')}
          submitButtonTitle={t('modal.delete.project.confirm')}
          loading={loading}
        >
          <Translate id="modal.delete.project.note" />
        </DeleteModal>
      )}
    </>
  );
};

export default ProjectsManageHeader;
