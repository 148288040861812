import { graphql } from 'src/gql';

export const QUERY_ALL_CUSTOM_PROCESS_DEFINITION_FILES = graphql(`
  query AllCustomProcesses {
    getAllCustomProcesses {
      data {
        id
        name
        organizationId
        downloadUrl
      }
    }
  }
`);

export const QUERY_IS_VALID_CUSTOM_PROCESS = graphql(`
  query IsValidCustomProcess($process: String!) {
    isValidCustomProcess(process: $process)
  }
`);
