import MaterialChip, { ChipProps } from '@mui/material/Chip';
import styled from 'styled-components';

export interface IStyledChipTheme extends ChipProps {
  $backgroundColor: string;
}

export const StyledChip = styled(MaterialChip)<IStyledChipTheme>`
  background-color: ${(props) => props.$backgroundColor};
  line-height: initial;
`;
