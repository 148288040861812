import { Button, DeleteModal, Tooltip, DateTime, DateUtils } from '@dna-script-inc/shared-ui-library';
import Close from '@mui/icons-material/Close';
import { FormControlLabel, FormGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import MuiSwitch from '@mui/material/Switch';
import Box from '@mui/system/Box';
import React, { useCallback, useMemo, useState } from 'react';
import { matchPath, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { KitType, NormalizationType, ProjectType, TemplateStatus } from '__generated__/globalTypes';
import { DeleteMenuItem } from 'src/components/DeleteMenuItem';
import { KitTypeChip } from 'src/components/KitTypeChip';
import { ETagColor, Tag } from 'src/components/Tag';
import { Dropdown } from 'src/components/common/Dropdown';
import { LinkButton } from 'src/components/form';
import { PageHeading } from 'src/components/layout/PageHeading';
import { TBreadcrumbsItem } from 'src/components/nav/Breadcrumbs';
import { ERoutes } from 'src/config/routes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useCanDeleteSequenceFile } from 'src/hooks/useCanDeleteSequenceFile';
import useDeleteTemplate from 'src/hooks/useDeleteTemplate';
import usePreventNavigation from 'src/hooks/usePreventNavigation';
import useUserContext from 'src/hooks/useUserContext';
import { FileEditableTitle } from 'src/pages/runs/components/FileEditableTitle';
import { useRunContext } from 'src/pages/runs/container';
import useGetFlags from 'src/services/flagsmith/useGetFlags';
import { useIsTemplateOwner, useIsTemplateStateChanged } from 'src/stores/sequenceEditor/hooks';
import { getDownloadLink, handlePrivateDownload } from 'src/utils/common';
import { StackSequencesService } from 'src/utils/nucleotide';
import { getPlateSizeLabelByValue } from 'src/utils/runs';

import { useToggle } from '../../../hooks/useToggle';
import { useUpdateTemplate } from '../hooks';
import ConfirmationModal from '../pages/scheduler/components/ConfirmationModal';
import PrintNowModal from '../pages/scheduler/components/PrintNowModal';

const AlertWrapper = styled.div`
  margin-bottom: 16px;
`;

const ROUTES_TO_DISABLE_EDIT_BTN = [
  ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__SCHEDULE,
  ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__SCHEDULE,
];

const Header = () => {
  const {
    isUsingPlateView,
    loadingSaveTemplate,
    printDetail,
    projectDetail,
    setIsUsingPlateView,
    setPrintDetail,
    suggestedSlot,
    templateDetail,
    templateName,
  } = useRunContext();
  const history = useHistory();

  const updateTemplate = useUpdateTemplate();
  const isOwner = useIsTemplateOwner();
  const t = useTranslation();

  const { pathname } = useLocation();
  const { close, open, isOpen } = useToggle();
  const { close: closeConfirmation, open: openConfirmation, isOpen: showConfirmation } = useToggle();
  const { projectId } = useParams<{ projectId: string }>();
  const [showSuggested, toggleSuggested] = React.useState(false);
  const {
    hasPrintingEnabled,
    hasValidLicense,
    userPermissions: { project, sequenceFiles },
  } = useUserContext();

  const isGeneProject = templateDetail?.projectType === ProjectType.GENE;
  const isOldKitType = templateDetail?.kitType === KitType.B3_96 || templateDetail?.kitType === KitType.B4_96;

  React.useEffect(() => {
    toggleSuggested(!!suggestedSlot);
  }, [suggestedSlot]);

  const isWellStateChanged = useIsTemplateStateChanged();
  const printIsDisabled =
    templateDetail?.status === 'ERROR' ||
    !hasValidLicense() ||
    loadingSaveTemplate ||
    isOldKitType ||
    isGeneProject ||
    (!project.canEditProject && !sequenceFiles.canPrint) ||
    isWellStateChanged;

  const isSaveDisabled = (!project.canEditProject && !isOwner) || !isWellStateChanged || isGeneProject;

  const { deleteTemplate, loading } = useDeleteTemplate({
    fileName: templateDetail?.name ?? '',
    id: templateDetail?.id,
    projectId: projectDetail?.id || '',
  });
  const [hasDeleteConfirmationOpened, setHasDeleteConfirmationOpened] = useState(false);

  const handleDeleteConfirmationOpen = useCallback(() => {
    setHasDeleteConfirmationOpened(true);
  }, [setHasDeleteConfirmationOpened]);

  const plateTypeLabel = useMemo(
    () => getPlateSizeLabelByValue(templateDetail?.plateType),
    [templateDetail?.plateType],
  );

  const handleDeleteConfirmationClose = useCallback(() => {
    setHasDeleteConfirmationOpened(false);
  }, [setHasDeleteConfirmationOpened]);

  const handleDelete = useCallback(async () => {
    if (!templateDetail) {
      throw new Error('No template detail present');
    }
    const { errors: errorsDelete } = await deleteTemplate({
      variables: {
        id: templateDetail?.id,
      },
    });

    StackSequencesService.clearStackById(templateDetail?.id);

    if (errorsDelete && errorsDelete.length > 0) {
      throw errorsDelete[0];
    }

    if (projectDetail) {
      history.push(ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', projectDetail?.id));
    } else {
      history.push(ERoutes.APPLICATIONS__SEQUENCE_FILES);
    }
  }, [deleteTemplate, templateDetail, projectDetail, history]);

  // const linkToSchedule = projectDetail
  //     ? ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__SCHEDULE
  //         .replace(
  //             ':projectId',
  //             projectDetail.id
  //         ).replace(':templateId', templateDetail?.id)
  //     : ERoutes
  //         .APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__SCHEDULE
  //         .replace(':templateId', templateDetail?.id);

  const canDeleteSequenceFile = useCanDeleteSequenceFile(templateDetail?.creator);

  const buttons = (
    <div>
      <MenuItem
        disabled={!templateDetail?.exportMetaLink}
        onClick={() => {
          if (templateDetail?.exportMetaLink) {
            const url = getDownloadLink(templateDetail.exportMetaLink);
            handlePrivateDownload(url);
          }
        }}
      >
        <Translate id="runs.plateEditor.header.exportAsCsv.button.title" />
      </MenuItem>
      {/* we hide scheduler for v5.3, we'll bring it back when have a more reliable connection from instrument

            {hasPrintingEnabled() && (
                <MenuItem
                    id="print"
                    disabled={printIsDisabled}
                    onClick={() => {
                        setStep(1);
                        history.push(linkToSchedule);
                    }}
                >
                    <Translate id="runs.plateEditor.header.print.button.title" />
                </MenuItem>
            )} */}
      <DeleteMenuItem
        disabled={!canDeleteSequenceFile || loadingSaveTemplate || isGeneProject}
        id="deleteDraft"
        onClick={handleDeleteConfirmationOpen}
      >
        <Translate id="runs.plateEditor.header.delete.button.title" />
      </DeleteMenuItem>
    </div>
  );

  const location = useLocation();

  let content: React.ReactNode | null = null;
  switch (templateDetail?.status) {
    case TemplateStatus.OUTDATED:
      content = (
        <div>
          <MenuItem
            id="cancel"
            onClick={() => {
              history.push(
                projectDetail
                  ? ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', projectDetail.id)
                  : ERoutes.APPLICATIONS__SEQUENCE_FILES,
              );
            }}
          >
            <Translate id="runs.plateEditor.header.cancel.button.title" />
          </MenuItem>
          <DeleteMenuItem
            disabled={!project.canManageAccess || isGeneProject}
            id="deleteDraft"
            onClick={handleDeleteConfirmationOpen}
          >
            <Translate id="runs.plateEditor.header.delete.button.title" />
          </DeleteMenuItem>
        </div>
      );
      break;
    case TemplateStatus.DELETE:
      content = null;
      break;
    default: {
      const hasProjectDetailsActionsMenu =
        projectDetail &&
        matchPath(
          location.pathname,
          ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__DETAILS,
        );
      const hasSequenceFilesActionsMenu =
        !projectDetail &&
        matchPath(location.pathname, ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__DETAILS);
      if (hasProjectDetailsActionsMenu || hasSequenceFilesActionsMenu) {
        content = (
          <div>
            {hasProjectDetailsActionsMenu && projectDetail && (
              <>
                {buttons}
                <MenuItem
                  disabled={loadingSaveTemplate}
                  id="cancel"
                  onClick={() =>
                    history.push(ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', projectDetail.id))
                  }
                >
                  <Translate id="runs.plateEditor.header.cancel.button.title" />
                </MenuItem>
              </>
            )}
            {hasSequenceFilesActionsMenu && (
              <>
                {buttons}
                <MenuItem
                  disabled={loadingSaveTemplate}
                  id="cancel"
                  onClick={() => {
                    history.push(ERoutes.APPLICATIONS__SEQUENCE_FILES);
                  }}
                >
                  <Translate id="runs.plateEditor.header.cancel.button.title" />
                </MenuItem>
              </>
            )}
          </div>
        );
      }
      break;
    }
  }

  let breadcrumbs: TBreadcrumbsItem[] = [];
  if (projectId && projectDetail) {
    breadcrumbs = [
      {
        link: isGeneProject ? ERoutes.APPLICATIONS__PROJECTS__DESIGN : ERoutes.APPLICATIONS__PROJECTS,
        title: isGeneProject ? 'nav.synthesisData.projects.design' : 'projects.title',
      },
      {
        customTitle: projectDetail?.name ?? '',
        link: isGeneProject
          ? ERoutes.APPLICATIONS__PROJECTS__DESIGN__DETAILS__id.replace(':id', projectDetail?.id)
          : ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', projectDetail?.id),
      },
      {
        customTitle: templateDetail?.name ?? '',
      },
    ];
  } else {
    breadcrumbs = [
      {
        link: ERoutes.APPLICATIONS__SEQUENCE_FILES,
        title: 'nav.synthesisData.templates',
      },
      {
        customTitle: templateDetail?.name ?? '',
      },
    ];
  }

  const isSchedulePage = (path: string) => {
    return matchPath(pathname, {
      path,
    });
  };

  const isEditTitleBtnDisabled =
    ROUTES_TO_DISABLE_EDIT_BTN.some(isSchedulePage) ||
    templateDetail?.status === TemplateStatus.DELETE ||
    isGeneProject;

  usePreventNavigation(!isSaveDisabled);

  const { hasSequenceListViewEnabled } = useGetFlags();

  return (
    <>
      <Collapse in={showSuggested && templateDetail?.status !== TemplateStatus.OUTDATED}>
        <AlertWrapper>
          <Alert
            action={
              <Button
                aria-label="close"
                variant="tertiary-gray"
                onClick={() => {
                  toggleSuggested(false);
                }}
                size="sm"
              >
                <Close fontSize="inherit" />
              </Button>
            }
            severity="info"
          >
            <AlertTitle>
              <Translate
                data={{
                  date: suggestedSlot?.startDate?.toFormat('MM/DD/YYYY [at] h:mma') || '',
                  diff: DateUtils.getDuration(
                    suggestedSlot?.startDate ?? DateTime.now(),
                    suggestedSlot?.endDate ?? DateTime.now(),
                  ),
                }}
                id="runs.create.suggested.title"
              />
            </AlertTitle>
            <Translate id="runs.create.suggested.description" />
          </Alert>
        </AlertWrapper>
      </Collapse>
      <PageHeading
        breadcrumbs={breadcrumbs}
        renderTitle={() => (
          <Grid container>
            <Grid container item xs={6} alignItems="center">
              <FileEditableTitle
                disabled={Boolean(isEditTitleBtnDisabled) || !sequenceFiles.canEditPlate}
                normalization={templateDetail?.normalizationType as NormalizationType}
                value={templateName}
              >
                <Grid item>
                  <Tag color={ETagColor.BLUE}>
                    {Boolean(templateDetail?.prefixes?.length) && (
                      <>
                        <Translate id="plates.detailsPage.indicator.idna" />
                        <span style={{ whiteSpace: 'pre' }}>{' / '}</span>
                      </>
                    )}
                    {plateTypeLabel}
                  </Tag>
                </Grid>
                <Grid item display="flex" flexDirection="row" alignItems="center">
                  {templateDetail?.kitType && <KitTypeChip type={templateDetail.kitType} />}
                  {hasSequenceListViewEnabled && (
                    <FormGroup
                      sx={{
                        marginLeft: '8px',
                        padding: '0px 4px',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <MuiSwitch
                            checked={isUsingPlateView}
                            onChange={(e) => setIsUsingPlateView(e.target.checked)}
                          />
                        }
                        label={t('plate.create.v2.switch')}
                      />
                    </FormGroup>
                  )}
                </Grid>
              </FileEditableTitle>
            </Grid>

            <Grid item container spacing={2} xs={6} justifyContent="flex-end">
              {content && (
                <Grid item>
                  <Dropdown
                    buttonTitle="runs.plateEditor.header.actions.button.title"
                    id="my-sequences-grid"
                    loading={loadingSaveTemplate}
                    shouldCloseOnClick
                    variant="secondary"
                  >
                    {content}
                  </Dropdown>
                </Grid>
              )}
              {projectDetail && templateDetail && (
                <Route
                  exact
                  path={
                    // eslint-disable-next-line max-len
                    ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__SCHEDULE
                  }
                >
                  <Grid item>
                    <LinkButton
                      uppercase
                      title={
                        printDetail
                          ? t('runs.plateEditor.header.done.button.title')
                          : t('runs.plateEditor.header.cancel.button.title')
                      }
                      // eslint-disable-next-line max-len
                      to={ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__DETAILS.replace(
                        ':projectId',
                        projectDetail.id,
                      ).replace(':templateId', templateDetail.id)}
                    />
                  </Grid>
                </Route>
              )}
              {!projectDetail && (
                <Route exact path={ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__SCHEDULE}>
                  {!!printDetail && (
                    <Grid item>
                      <LinkButton
                        title={t('runs.plateEditor.header.done.button.title')}
                        to={ERoutes.APPLICATIONS__SEQUENCE_FILES}
                        uppercase
                      />
                    </Grid>
                  )}
                  {!printDetail && templateDetail && (
                    <Grid item>
                      <LinkButton
                        uppercase
                        title={t('runs.plateEditor.header.cancel.button.title')}
                        // eslint-disable-next-line max-len
                        to={ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__DETAILS.replace(
                          ':templateId',
                          templateDetail.id,
                        )}
                      />
                    </Grid>
                  )}
                </Route>
              )}
              <Switch>
                <Route
                  component={() => null}
                  exact
                  path={ERoutes.APPLICATIONS__SEQUENCE_FILES__TEMPLATE__templateId__SCHEDULE}
                />
                <Route
                  component={() => null}
                  exact
                  path={
                    // eslint-disable-next-line max-len
                    ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__SCHEDULE
                  }
                />
                <Route>
                  <Grid item>
                    <Button
                      variant="secondary"
                      disabled={isSaveDisabled}
                      loading={loadingSaveTemplate}
                      id="btnUpdate"
                      onClick={() => updateTemplate()}
                      uppercase
                    >
                      <Translate id="runs.plateEditor.editWindow.buttons.update" />
                    </Button>
                  </Grid>
                  {hasPrintingEnabled() && (
                    <Grid item>
                      <Tooltip
                        title={!hasValidLicense() ? t('runs.plateEditor.header.printNow.license.expired') : ''}
                        placement="top"
                      >
                        <Box>
                          <Button variant="primary" disabled={printIsDisabled} id="printNow" onClick={open} uppercase>
                            <Translate id="sequenceFiles.upload.syntax" />
                          </Button>
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
                </Route>
              </Switch>
            </Grid>
          </Grid>
        )}
      />
      {hasDeleteConfirmationOpened && (
        <DeleteModal
          title={t('modal.delete.sequenceFile.title')}
          isOpen
          onSubmit={handleDelete}
          onReject={handleDeleteConfirmationClose}
          rejectButtonTitle={t('common.button.cancel')}
          submitButtonTitle={t('modal.delete.sequenceFile.confirm')}
          loading={loading}
        >
          <Translate id="modal.delete.sequenceFile.note" />
        </DeleteModal>
      )}
      {templateDetail && (
        <PrintNowModal
          isOpen={isOpen}
          onCancel={close}
          onCreateRunSuccess={setPrintDetail}
          onSubmit={() => {
            close();
            openConfirmation();
          }}
          templateId={templateDetail.id}
          kitType={templateDetail.kitType}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          onClose={closeConfirmation}
          printDetail={printDetail}
          title="template.printNow.congratulations.title"
        />
      )}
    </>
  );
};

export default Header;
