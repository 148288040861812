import { graphql } from 'src/gql';

export const QUERY_INSTALLATION_FILES = graphql(`
  query InstallationFilesPage($page: Int!, $size: Int!, $orderField: String!, $orderDirection: Direction!) {
    getInstallationFiles(
      filter: { page: { page: $page, size: $size }, sort: { field: $orderField, direction: $orderDirection } }
    ) {
      data {
        fileName
        releaseVersion
        releaseDate
      }
      totalElementsCount
    }
  }
`);
