import { ConfirmModal } from '@dna-script-inc/shared-ui-library';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';

interface IDownloadingModalProps {
  handleSubmit: () => void;
  isVisible: boolean;
}

const DownloadingModal: React.FC<IDownloadingModalProps> = ({ isVisible, handleSubmit }) => {
  const t = useTranslation();
  return (
    <ConfirmModal
      isOpen={isVisible}
      onSubmit={() => handleSubmit()}
      submitButtonTitle={t('projects.detail.tabs.instructions.modal.btn')}
      title={t('projects.detail.tabs.instructions.modal.title')}
    >
      <Translate id="projects.detail.tabs.instructions.modal.text" />
    </ConfirmModal>
  );
};

export default DownloadingModal;
