import { DateTime } from '@dna-script-inc/shared-ui-library';
import { luxonLocalizer } from 'react-big-calendar';

const localizer = luxonLocalizer(DateTime);

localizer.formats = {
  ...localizer.formats,
  eventTimeRangeEndFormat: localizer.formats.eventTimeRangeFormat,
  eventTimeRangeStartFormat: localizer.formats.eventTimeRangeFormat,
};

export default localizer;
