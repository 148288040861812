import Password from 'secure-random-password';

import { SequenceStatus } from '__generated__/globalTypes';

interface IConstantsConfig<T = any> {
  [key: string]: T;
}

export const TOAST_DEFAULT_TIMEOUT = 5000;

export const STORAGE: IConstantsConfig<string> = {
  CART: '_cart',
  KEY_AUTH_TOKEN: '_authToken',
  KEY_LOCALE: '_locale',
};

export const I18N_CONFIG = {
  AVAILABLE_LOCALES: ['en'],
  DEFAULT_LOCALE: 'en',
};

export const FEATURE_TOGGLES = {
  REAGENTS_USE_ESTIMATE: false,
};

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_NAME_LENGTH = 255;
export const MAX_TEAM_NAME_LENGTH = 500;

export const MAX_FILE_SIZE_TO_UPLOAD = 1048576;

export const getPassword = () => {
  return Password.randomPassword({
    characters: [Password.lower, Password.upper, Password.digits, '~!@#$%^&*()_+-='],
    length: MIN_PASSWORD_LENGTH,
  });
};

export const NAME_REGEX_PATTER = /^[^\\/:*?"<>|]*$/;

export const PLATE_NAME_REGEXP_PATTERN = NAME_REGEX_PATTER;

export const PLATE_SIZE_96 = 96;
export const PLATE_SIZE_384 = 384;

export const CLEARED_SEQUENCE_FIELDS = {
  data: '',
  dataChunks: [],
  errorScoreValue: undefined,
  errors: undefined,
  length: undefined,
  modsCount: undefined,
  name: '',
  nucChunks: [],
  nucErrors: undefined,
  nucWarnings: undefined,
  prefixDataChunks: undefined,
  prefixNucChunks: undefined,
  score: undefined,
  status: SequenceStatus.DELETED,
  warnings: undefined,
};

export const ACCESS_DENIED_ERROR = 'access denied';

export const PAGE_NOT_FOUND = 'page not found.';

export const SEQUENCE_NOT_FOUND = 'sequence file not found.';

export const WEBAPP_BORDER_RADIUS = '4px';

export const INSTRUMENT_TYPES = [
  {
    label: 'SYNTAX System',
    value: 'SYNTAX System',
  },
];

export const DAYS_UNTIL_LICENSE_UPDATE_NOTIFICATION = 31; // moment does not include last day

export const ALLOWED_FILETYPES = ['.csv', '.xls', '.xlsx'];
