import { noop } from 'lodash-es';
import React from 'react';

import { KitType, ProjectType } from '__generated__/globalTypes';

import { DEFAULT_CONCENTRATION_KEY, DEFAULT_CONCENTRATION_LIMITS } from './constants';

export interface IConcentrationLimits {
  defaultValue: number;
  max: number;
  min: number;
}

export interface IApplicationContext {
  getConcentrationLimitsByKitType: (
    kitType: KitType | undefined | null | typeof DEFAULT_CONCENTRATION_KEY,
  ) => IConcentrationLimits;
  handleGetSequenceConcentrationLimits: (projectType: ProjectType) => void;
}

const initialContext: IApplicationContext = {
  getConcentrationLimitsByKitType: () => DEFAULT_CONCENTRATION_LIMITS,
  handleGetSequenceConcentrationLimits: noop,
};

const ApplicationContext = React.createContext(initialContext);

export default ApplicationContext;
