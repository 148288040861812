import { BaseEditor, Descendant } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

export enum ENodeType {
  ERROR = 'ERROR',
  MOD = 'MOD',
  WARNING = 'WARNING',
}

export type TModElement = {
  bgColor: string;
  children: Descendant[];
  chunk: string;
  message: string;
  type: ENodeType.MOD;
};

export type TErrorElement = {
  bgColor: string;
  children: Descendant[];
  chunk: string;
  message: string;
  type: ENodeType.ERROR;
};

export type TWarningElement = {
  bgColor: string;
  children: Descendant[];
  chunk: string;
  text: string;
  message: string;
  type: ENodeType.WARNING;
};

type TParagraphElement = {
  align?: string;
  children: Descendant[];
  type: 'paragraph';
};

export type TCustomElement = TParagraphElement | TModElement | TErrorElement | TWarningElement;

type TCustomText = {
  bold?: boolean;
  code?: boolean;
  italic?: boolean;
  text: string;
};

type TEmptyText = {
  text: string;
};

type TCustomEditor = BaseEditor & ReactEditor & HistoryEditor;

declare module 'slate' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface CustomTypes {
    Editor: TCustomEditor;
    Element: TCustomElement;
    Text: TCustomText | TEmptyText;
  }
}
