import noop from 'lodash-es/noop';
import { createContext } from 'react';

interface IModalOnHoverContext {
  anchorEl: null | HTMLElement;
  arrowRef: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setArrowRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const initialModalOnHoverContext = {
  anchorEl: null,
  arrowRef: null,
  setAnchorEl: noop,
  setArrowRef: noop,
};

export const ModalOnHoverContext = createContext<IModalOnHoverContext>(initialModalOnHoverContext);
