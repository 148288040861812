import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { isNotEmpty } from 'src/utils/ui';

import CloseButton from './CloseButton';

const ErrorsContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const StyledAlert = styled(Alert)`
  width: 100%;
`;

interface IErrorsProps {
  errors: (ITemplateMessage | null)[] | null;
}

// eslint-disable-next-line prefer-arrow-callback
const Errors = React.memo(function Errors({ errors }: IErrorsProps) {
  const [localErrors, setLocalErrors] = useState<ITemplateMessage[] | null>(null);
  const close = () => setLocalErrors(null);

  React.useEffect(() => {
    if (errors) {
      setLocalErrors(errors.filter(isNotEmpty));
    }
  }, [errors, errors?.length]);

  if (!localErrors?.length) return null;

  return (
    <ErrorsContainer container>
      <StyledAlert
        severity="error"
        action={
          <CloseButton onClick={close}>
            <Translate id="common.alert.errors.closeButton" />
          </CloseButton>
        }
      >
        <AlertTitle>
          <Translate id="common.alert.errors.title" />
        </AlertTitle>
        {localErrors.map(({ message }) => (
          <div key={message}>{message}</div>
        ))}
      </StyledAlert>
    </ErrorsContainer>
  );
});

export default Errors;
