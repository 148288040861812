/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CancelButton, SaveButton, Modal } from '@dna-script-inc/shared-ui-library';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import TextInput from 'src/components/form/TextInput';
import { NAME_REGEX_PATTER } from 'src/config/constants';
import { Translate, useTranslation } from 'src/containers/i18n';
import { FormRow, FormWrapper } from 'src/pages/auth/pages/components/styled';
import { getErrorForHelperText } from 'src/utils/reactHookForm';
import { shouldNotHaveOnlySpaces } from 'src/utils/validator';

const TextArea = styled(TextInput)`
  width: 620px;
`;

const StyledModal = styled(Modal)`
  .modal-content {
    max-height: max-content;
  }
`;

const StyledTextTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 400;
`;

const StyledTextSubTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
`;

type TFormValues = { content: string; name: string };

interface IGeneralModalProps {
  onClose: () => void;
  onSubmit: (value: TFormValues) => void;
  open: boolean;
}

const AddSequenceModal: React.FC<IGeneralModalProps> = ({ onClose, onSubmit, open }) => {
  const t = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TFormValues>();

  const nameRegister = register('name', {
    pattern: {
      message: t('runs.create.uploadFiles.manual.modal.fields.title.invalid'),
      value: NAME_REGEX_PATTER,
    },
    required: t('general.form.validation.fields.required'),
    validate: {
      shouldNotHaveOnlySpaces,
    },
  });

  const contentRegister = register('content', {
    required: t('general.form.validation.fields.required'),
    validate: {
      shouldNotHaveOnlySpaces,
    },
  });
  return (
    <StyledModal open={open} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs>
          <StyledTextTitle>
            <Translate id="runs.create.uploadFiles.manual.modal.title" />
          </StyledTextTitle>
          <StyledTextSubTitle>
            <Translate id="runs.create.uploadFiles.manual.modal.subTitle" />
          </StyledTextSubTitle>
        </Grid>
      </Grid>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <TextInput
            id="fastaSeqName"
            variant="outlined"
            color="secondary"
            label={t('runs.create.uploadFiles.manual.modal.fields.title')}
            fullWidth
            error={Boolean(errors.name)}
            helperText={getErrorForHelperText(errors.name)}
            inputRef={nameRegister.ref}
            {...nameRegister}
          />
        </FormRow>
        <FormRow>
          <TextArea
            id="fastaContent"
            variant="outlined"
            color="secondary"
            multiline
            label={t('runs.create.uploadFiles.manual.modal.fields.content')}
            fullWidth
            rows={10}
            maxRows={10}
            error={Boolean(errors.content)}
            helperText={getErrorForHelperText(errors.content)}
            inputRef={contentRegister.ref}
            {...contentRegister}
          />
        </FormRow>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginTop: '16px' }}>
          <Grid item>
            <CancelButton id="cancel" onClick={onClose}>
              <Translate id="runs.create.uploadFiles.manual.modal.button.cancel" />
            </CancelButton>
          </Grid>
          <Grid item>
            <SaveButton id="save" onClick={handleSubmit(onSubmit)}>
              <Translate id="runs.create.uploadFiles.manual.modal.button.save" />
            </SaveButton>
          </Grid>
        </Grid>
      </FormWrapper>
    </StyledModal>
  );
};

export default AddSequenceModal;
