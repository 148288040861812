import { TContent } from 'src/components/layout/Sidebar/navItems';
import { ERoutes } from 'src/config/routes';
import { getUserPermissions } from 'src/utils/permissions/general';

import { IUserPermissions } from './permissions/constants';

function isAccessibleRoute(userPermissions: IUserPermissions, route: string | undefined) {
  switch (route) {
    case ERoutes.HOMEPAGE: {
      return userPermissions.pages.canViewDashboardSection;
    }
    case ERoutes.DOWNLOADS: {
      return userPermissions.pages.canViewDownloadsSection;
    }
    case ERoutes.SERVICE: {
      return userPermissions.pages.canViewServiceSection;
    }
    case ERoutes.SUPPORT: {
      return userPermissions.pages.canViewSupportSection;
    }
    case ERoutes.APPLICATIONS: {
      return (
        userPermissions.pages.canViewProjectsSection ||
        userPermissions.pages.canViewFilesSection ||
        userPermissions.pages.canViewReportsSection ||
        userPermissions.pages.canViewPlatesSection
      );
    }
    case ERoutes.APPLICATIONS__PROJECTS: {
      return userPermissions.pages.canViewProjectsSection;
    }
    case ERoutes.APPLICATIONS__PROJECTS__DESIGN: {
      return userPermissions.pages.canViewDesignSection;
    }
    case ERoutes.APPLICATIONS__SEQUENCE_FILES: {
      return userPermissions.pages.canViewFilesSection;
    }
    case ERoutes.APPLICATIONS__RUNS: {
      return userPermissions.pages.canViewReportsSection;
    }
    case ERoutes.APPLICATIONS__PLATES: {
      return userPermissions.pages.canViewPlatesSection;
    }
    case ERoutes.ACCOUNTS: {
      return (
        userPermissions.pages.canViewUsers ||
        userPermissions.pages.canViewOrganization ||
        userPermissions.pages.canViewTeams
      );
    }
    case ERoutes.ACCOUNTS__ORGANIZATION: {
      return userPermissions.pages.canViewOrganization;
    }
    case ERoutes.ACCOUNTS__TEAMS: {
      return userPermissions.pages.canViewTeams;
    }
    case ERoutes.ACCOUNTS__USERS: {
      return userPermissions.pages.canViewUsers;
    }
    case ERoutes.INSTRUMENTS__PRINT_QUEUE: {
      return userPermissions.instruments.canViewQueue;
    }
    case ERoutes.INSTRUMENTS: {
      return userPermissions.pages.canViewInstrumentsSection;
    }
    case ERoutes.INSTRUMENTS__CREATE: {
      return userPermissions.instruments.canCreateInstruments;
    }
    case ERoutes.INSTRUMENTS__MANAGE__id: {
      return userPermissions.instruments.canManageInstruments;
    }
    case ERoutes.CUSTOM_PROCESSES: {
      return userPermissions.customProcesses.canCreateCustomProcesses;
    }
    default: {
      return true;
    }
  }
}

export function getAccessibleNavItems(
  organization: IOrganizationAccount | null | undefined,
  userProfile: IUser,
  navItems: TContent[],
) {
  const userPermissions = getUserPermissions(userProfile, organization);
  return navItems.filter((x) => {
    return isAccessibleRoute(userPermissions, x.to);
  });
}

export const userStatusLabels = {
  ACTIVE: 'accounts.users.list.status.active',
  DELETED: 'accounts.users.list.status.deleted',
  INACTIVE: 'accounts.users.list.status.inactive',
} as const;
