/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { InternalRefetchQueriesInclude, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, CancelButton, HelixLoader, WarningModal } from '@dna-script-inc/shared-ui-library';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import TuneIcon from '@mui/icons-material/Tune';
import { Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { ISimpleInstrument } from 'src/@types/instruments';
import type { ICalendarItem } from 'src/components/calendar/Calendar';
import { TextInput } from 'src/components/form';
import Translate from 'src/containers/i18n/Translate';
import useTranslation from 'src/containers/i18n/useTranslation';
import { useToast } from 'src/containers/toast';
import { CentrifugationType, KitType } from 'src/gql/graphql';
import { useGetCustomProcessDefinitionFiles } from 'src/hooks/useGetCustomProcessDefinitionFiles';
import { useToggle } from 'src/hooks/useToggle';
import useUserContext from 'src/hooks/useUserContext';
import { TTranslationKeys } from 'src/i18n';
import { useRunContext } from 'src/pages/runs/container';
import { MUTATION_CREATE_RUN_SCHEDULE } from 'src/services/gql/mutations/runs';
import { QUERY_VALIDATE_INSTRUMENT_KIT_TYPE } from 'src/services/gql/queries/instruments';
import { getErrorMessage } from 'src/utils/errors';

import ReagentsChart from './chart/ReagentsChart';

import { QUERY_RUNS_ORGANIZATION, QUERY_RUNS_USER } from '../../../../../services/gql/queries/runs';
import { GET_CENTRIFUGATION_PROCESSES } from '../../../../../services/gql/queries/templates';

const Wrapper = styled.div`
  margin: 32px 20px 24px;
`;

const Form = styled.div``;

const Hint = styled.div`
  font-size: 11px;
  margin-top: -21px;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-start;
`;

const HintSpan = styled.span`
  margin-left: 8px;
  color: ${(props) => props.theme.colors.secondary.blue_light[600]};
  cursor: pointer;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 25px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Icon = styled.div``;

const Elements = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const Buttons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 16px;
  }
`;

const Instrument = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 14px;
  }
`;

const ListSubheader = styled.div`
  margin: 5px 12px 0;
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const Dot = styled.div<{ color: string }>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: ${(props) => props.color};
`;

const TooltipWrapper = styled.div`
  color: #000;

  > strong {
    font-size: 14px;
    font-weight: 500;
  }

  > p {
    margin-top: 12px;
    font-weight: 400;
  }
`;

const SpinnerWrapper = styled.div`
  width: 460px;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles({
  arrow: {
    '&:before': {
      boxShadow: '1px 1px 2px 0 rgba(60, 64, 67, 0.3)',
    },
    color: '#fff',
    left: '16px !important',
  },
  tooltip: {
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15)',
    left: -20,
    padding: 16,
    top: -28,
  },
});

interface IAddFormProps {
  getColor?: (id: string) => string;
  instruments: ISimpleInstrument[];
  item: ICalendarItem;
  onCancel: () => void;
  onChange: (
    item: Partial<
      Pick<
        ICalendarItem,
        'startDate' | 'endDate' | 'instrumentId' | 'customProcessDefinitionFileId' | 'centrifugationType'
      >
    >,
  ) => void;
  onConfirmed: () => void;
  onCreateRunFailure: () => void;
  onCreateRunSuccess: (projectRun: ICreateProjectRun) => void;
  printNow?: boolean;
  templateId: string;
  kitType: KitType | null | undefined;
}

enum ValidationResult {
  FAILED = 'FAILED',
  OK = 'OK',
  VALIDATION_CAN_NOT_BE_COMPLETED = 'VALIDATION_CAN_NOT_BE_COMPLETED',
  VALIDATION_IS_NOT_SUPPORTED = 'VALIDATION_IS_NOT_SUPPORTED',
}

export const CENTRIFUGATION_LABEL_MAPPING = (highPlex: boolean): Record<CentrifugationType, TTranslationKeys> => ({
  [CentrifugationType.DEFAULT]: 'scheduler.form.centrifugation.option.default',
  [CentrifugationType.ELUTION]: highPlex
    ? 'scheduler.form.centrifugation.option.elution.hx'
    : 'scheduler.form.centrifugation.option.elution.96',
  [CentrifugationType.QUANTIFICATION]: 'scheduler.form.centrifugation.option.quantification',
});

const MAXIMUM_DESCRIPTION_LENGTH = 500;

const useQueriesToFetchAfterRunCreated = () => {
  const {
    userProfile,
    userPermissions: {
      runReports: { canViewAllReports },
    },
  } = useUserContext();
  const userId = userProfile?.id ?? '';

  const queriesToRefetch = useMemo(() => {
    const queries: InternalRefetchQueriesInclude = [
      {
        query: QUERY_RUNS_USER,
        variables: { filter: { userId } },
      },
    ];
    if (canViewAllReports) {
      queries.push({
        query: QUERY_RUNS_ORGANIZATION,
        variables: { filter: { userId } },
      });
    }
    return queries;
  }, [canViewAllReports, userId]);

  return queriesToRefetch;
};

const AddForm = ({
  templateId,
  kitType,
  onCreateRunSuccess,
  item,
  onCancel,
  printNow,
  instruments,
  onConfirmed,
  onCreateRunFailure,
  onChange,
  getColor,
}: IAddFormProps) => {
  const showToast = useToast();
  const classes = useStyles();
  const t = useTranslation();

  const [description, setDescription] = useState('');
  const [showKitWarning, setShowKitWarning] = useState(false);
  const { refetchTemplate } = useRunContext();

  const queriesToRefetch = useQueriesToFetchAfterRunCreated();
  const is384 = kitType === KitType.B5_HPLEX_384;

  const { data: centrifugationProcesses } = useQuery(GET_CENTRIFUGATION_PROCESSES, {
    variables: {
      kitType: kitType ?? KitType.B5_HPURE_96,
    },
  });

  const [createRun, { loading }] = useMutation(MUTATION_CREATE_RUN_SCHEDULE, {
    refetchQueries: queriesToRefetch,
  });

  const [hasSavingInProgress, setHasSavingInProgress] = useState(false);
  const handleSave = async () => {
    if (!item.instrumentId) {
      showToast({
        isError: true,
        textToTranslate: 'scheduler.addForm.instrumentError',
      });
      return;
    }
    setHasSavingInProgress(true);
    try {
      const { data: runData } = await createRun({
        variables: {
          centrifugationType: item.centrifugationType,
          customProcessId: item.customProcessDefinitionFileId,
          description,
          instrumentId: item.instrumentId,
          templateId,
        },
      });
      if (runData) {
        onCreateRunSuccess(runData.createRunScheduler as ICreateProjectRun);
      }
      onConfirmed();
    } catch (e) {
      showToast({
        isError: true,
        text: getErrorMessage(e),
      });
      onCreateRunFailure();
    } finally {
      setHasSavingInProgress(false);
      refetchTemplate();
    }
  };

  const [validateInstrumentKit, { loading: loadingKitType }] = useLazyQuery(QUERY_VALIDATE_INSTRUMENT_KIT_TYPE, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ validateInstrumentKitType }) => {
      const isWarningDisplayed = validateInstrumentKitType?.result === ValidationResult.FAILED;
      setShowKitWarning(isWarningDisplayed);
      if (!isWarningDisplayed) {
        await handleSave();
      }
    },
    onError: (e) => {
      showToast({
        isError: true,
        text: getErrorMessage(e),
      });
    },
  });

  const {
    userPermissions: { sequenceFiles },
    organization,
  } = useUserContext();

  const handleInstrumentIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        instrumentId: e.target.value,
      });
    },
    [onChange],
  );

  const handleCentrifugationTypeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        centrifugationType: e.target.value as CentrifugationType,
      });
    },
    [onChange],
  );

  const handleCustomProcessDefinitionFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        customProcessDefinitionFileId: e.target.value,
      });
    },
    [onChange],
  );

  const handleValidate = () => {
    if (!item.instrumentId) {
      showToast({
        isError: true,
        textToTranslate: 'scheduler.addForm.instrumentError',
      });
      return;
    }

    validateInstrumentKit({
      variables: {
        description,
        instrumentId: item.instrumentId,
        templateId,
      },
    });
  };

  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (item.suggested) {
        setShowTooltip(true);
      }
    }, 500);
  }, [item]);

  const hasInvalidDescription = useMemo(() => {
    return description.length > MAXIMUM_DESCRIPTION_LENGTH;
  }, [description]);

  const { loading: loadingCustomProcessDefinitionFiles, customProcessDefinitionFiles } =
    useGetCustomProcessDefinitionFiles();
  const hasConfirmDisabled =
    loadingCustomProcessDefinitionFiles ||
    hasInvalidDescription ||
    !sequenceFiles.canSchedule ||
    !item.instrumentId ||
    hasSavingInProgress;

  const reagentRef = useRef<HTMLDivElement>(null);
  const { isOpen: shouldShowReagents, open: showReagents, close: hideReagents } = useToggle();
  if (loadingKitType) {
    return (
      <SpinnerWrapper>
        <HelixLoader />
      </SpinnerWrapper>
    );
  }

  if (showKitWarning) {
    return (
      <WarningModal
        title={t('runs.plateEditor.header.printScheduleModal.warning.title')}
        isOpen
        onSubmit={handleSave}
        onReject={onCancel}
        loading={loading}
        rejectButtonTitle={t('common.button.cancel')}
        submitButtonTitle={t('common.button.send')}
      >
        <Translate id="runs.plateEditor.header.printScheduleModal.warning.body" />
      </WarningModal>
    );
  }

  return (
    <>
      <Tooltip
        arrow
        classes={classes}
        open={showTooltip}
        placement="top-start"
        title={
          <TooltipWrapper>
            <strong>
              <Translate id="schedule.form.title" />
            </strong>
            <p>
              <Translate id="schedule.form.description" />
            </p>
          </TooltipWrapper>
        }
      >
        <Wrapper>
          <Form>
            {printNow && (
              <Header>
                <Typography variant="h3">
                  <Translate id="sequenceFiles.upload.syntax" />
                </Typography>
                <Typography variant="caption">
                  <Translate id="runs.plateEditor.header.printScheduleModal.header.caption" />
                </Typography>
              </Header>
            )}
            <Row>
              <Elements>
                <StyledTextInput
                  key={item.instrumentId}
                  color="secondary"
                  id="instrument"
                  label={t('schedule.form.instrument.label')}
                  onChange={handleInstrumentIdChange}
                  select
                  size="small"
                  value={item.instrumentId}
                  variant="outlined"
                >
                  <ListSubheader>
                    <Translate id="schedule.form.instrument.title" />
                  </ListSubheader>
                  {instruments.map((instrument) => (
                    <MenuItem key={instrument.id} value={instrument.id}>
                      <Instrument>
                        {getColor && <Dot color={getColor(instrument.id)} />}
                        <span>{instrument.name}</span>
                      </Instrument>
                    </MenuItem>
                  ))}
                </StyledTextInput>
                {item.instrumentId && (
                  <Hint ref={reagentRef}>
                    <Translate id="schedule.form.resupplyNote" />
                    <HintSpan onClick={showReagents}>
                      <Translate id="schedule.form.viewReagentsLevel" />
                    </HintSpan>
                  </Hint>
                )}
              </Elements>
            </Row>
            {centrifugationProcesses?.getCentrifugationProcesses &&
              centrifugationProcesses.getCentrifugationProcesses.length > 1 && (
                <Row>
                  <StyledTextInput
                    color="secondary"
                    id="centrifugation_process"
                    label={t('schedule.form.centrifugationProcess.label')}
                    onChange={handleCentrifugationTypeChange}
                    select
                    size="small"
                    value={item.centrifugationType}
                    variant="outlined"
                  >
                    {centrifugationProcesses.getCentrifugationProcesses.map((x) => {
                      return (
                        <MenuItem key={x} value={x}>
                          {t(CENTRIFUGATION_LABEL_MAPPING(is384)[x])}
                        </MenuItem>
                      );
                    })}
                  </StyledTextInput>
                </Row>
              )}
            {organization?.customProcessEnabled && (
              <Row>
                <Icon>
                  <TuneIcon />
                </Icon>
                <Elements>
                  <StyledTextInput
                    key={item.customProcessDefinitionFileId}
                    color="secondary"
                    id="customProcessDefinitionFile"
                    label={t('schedule.form.customProcessDefinitionFile.label')}
                    onChange={handleCustomProcessDefinitionFileChange}
                    select
                    size="small"
                    value={item.customProcessDefinitionFileId}
                    variant="outlined"
                  >
                    <ListSubheader>
                      <Translate id="schedule.form.customProcessDefinitionFile.title" />
                    </ListSubheader>
                    {customProcessDefinitionFiles?.map(({ id, name }) => (
                      <MenuItem key={id} value={id || ''}>
                        <Instrument>
                          <span>{name}</span>
                        </Instrument>
                      </MenuItem>
                    ))}
                  </StyledTextInput>
                </Elements>
              </Row>
            )}
            <Row>
              <Icon>
                <FormatAlignLeft />
              </Icon>
              <Elements>
                <StyledTextInput
                  color="secondary"
                  error={hasInvalidDescription}
                  helperText={
                    hasInvalidDescription &&
                    t('schedule.form.description.error', { length: MAXIMUM_DESCRIPTION_LENGTH })
                  }
                  id="description"
                  label={t('schedule.form.description.label')}
                  multiline
                  onChange={(e) => setDescription(e.target.value)}
                  rows={5}
                  size="small"
                  value={description}
                  variant="outlined"
                />
              </Elements>
            </Row>
          </Form>
          <Buttons>
            <CancelButton onClick={onCancel}>
              <Translate id="schedule.form.button.cancel" />
            </CancelButton>
            <Button
              variant="primary"
              disabled={hasConfirmDisabled}
              id="confirm"
              loading={loading}
              onClick={handleValidate}
            >
              <Translate id="schedule.form.button.confirm" />
            </Button>
          </Buttons>
        </Wrapper>
      </Tooltip>
      <Popover anchorEl={reagentRef.current} onClose={hideReagents} open={shouldShowReagents}>
        {item.instrumentId && <ReagentsChart instrumentId={item.instrumentId} />}
      </Popover>
    </>
  );
};

export default AddForm;
