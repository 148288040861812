import Close from '@mui/icons-material/Close';
import * as React from 'react';
import styled from 'styled-components';

const StyledCloseButton = styled.div`
  cursor: pointer;
  font-weight: 600;
  height: fit-content;
  max-width: max-content;
  align-self: flex-start;
  margin-top: 8px;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const StyledCloseIcon = styled(Close)`
  font-size: 18px;
  margin-left: 8px;
`;

interface ICloseButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

const CloseButton = ({ children, onClick }: ICloseButtonProps) => {
  return (
    <StyledCloseButton aria-label="close" onClick={onClick}>
      {children}
      <StyledCloseIcon />
    </StyledCloseButton>
  );
};

export default CloseButton;
