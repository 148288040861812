import { graphql } from 'src/gql';

export const GET_ORGANIZATION_DATA = graphql(`
  query GetOrganizationData {
    getOrganizationData {
      id
      status
      companyName
      aesKey
      license {
        code
        validFrom
        validTill
      }
      contact {
        firstName
        lastName
        email
        title
        workNumber
        mobileNumber
      }
      address {
        country
        state
        city
        street1
        street2
        zip
      }
      createdAt
      updatedAt
      timeZone {
        id
        label
        offset
      }
      maxLoginFailedAttempts
      printingEnabled
      geneEnabled
      clicksEnabled
      modsEnabled
      degeneratesEnabled
      customProcessEnabled
    }
  }
`);

export const GET_ORGANIZATION_LOGIN_EVENTS = graphql(`
  query GetLoginEvents($filter: LoginEventFilterDTO) {
    getLoginEvents(filter: $filter) {
      data {
        id
        username
        ipAddress
        type
        remarks
        createdAt
      }
      totalElementsCount
    }
  }
`);

export const REMOTE_ORGANIZATION_FRAGMENT = graphql(`
  fragment RemoteOrganizationFragment on OrganizationRemoteUserDTO {
    id
    user {
      id
      username
      firstName
      lastName
    }
    organization {
      id
      name
    }
    role {
      id
      name
      description
      type
      authority
    }
    status
    expirationDate
    createdAt
  }
`);

export const GET_REMOTE_ORGANIZATIONS = graphql(`
  query GetRemoteOrganizations {
    getRemoteOrganizations {
      id
      user {
        id
        username
        firstName
        lastName
      }
      organization {
        id
        name
      }
      role {
        id
        name
        description
        type
        authority
      }
      status
      expirationDate
      createdAt
    }
  }
`);
