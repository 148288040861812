import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { KitType } from '__generated__/globalTypes';
import { StyledRoundedCard } from 'src/components/common';
import { TextInput } from 'src/components/form';
import { useTranslation } from 'src/containers/i18n';

import InfoScoreModal from './infoScoreModal';
import InfoSequenceModal from './infoSequenceModal';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

const SearchWrapper = styled.div`
  width: 400px;
  .MuiOutlinedInput-input {
    padding: 10px 10px 10px 0;
  }
`;

const StyledCard = styled(StyledRoundedCard)`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .MuiOutlinedInput-input {
    padding: 10px 10px 10px 0;
  }
`;

interface IHeaderProps {
  kitType: KitType | undefined | null;
  search: string;
  setSearch: (search: string) => void;
  ZoomComponent: React.ReactNode;
}

const HeaderPanel: React.FC<IHeaderProps> = ({ search, setSearch, kitType, ZoomComponent }) => {
  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setSearch(e.target.value);
    },
    [setSearch],
  );
  const t = useTranslation();

  return (
    <StyledCard
      sx={{
        marginBottom: '24px',
        marginTop: '16px',
      }}
    >
      <SearchWrapper>
        <TextInput
          color="secondary"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={onSearch}
          placeholder={t('runs.plateEditor.header.search.field.placeholder')}
          value={search}
          variant="outlined"
        />
      </SearchWrapper>
      {ZoomComponent}
      <InfoWrapper>
        <InfoScoreModal />
        <InfoSequenceModal kitType={kitType} />
      </InfoWrapper>
    </StyledCard>
  );
};

export default HeaderPanel;
