import { Indicators } from '@dna-script-inc/shared-ui-library';
import { Typography } from '@mui/material';
import React from 'react';
import { useTheme } from 'styled-components';

import { ReagentUsageUnion } from '__generated__/globalTypes';
import { IInstrumentStatus } from 'src/@types/instruments';
import { REAGENT_CODE_NUCLEOTIDES, REAGENT_CODE_PSP, REAGENT_CODE_SYNTH } from 'src/config/reagents';
import { TInstrumentStatusReagent } from 'src/types';

function ReagentsIndicators({
  reagents,
  height,
}: {
  reagents: Record<ReagentUsageUnion, TInstrumentStatusReagent>;
  height: number;
}) {
  const theme = useTheme();
  const nucl: IInstrumentStatus = reagents[REAGENT_CODE_NUCLEOTIDES];
  const psp: IInstrumentStatus = reagents[REAGENT_CODE_PSP];
  const synth: IInstrumentStatus = reagents[REAGENT_CODE_SYNTH];

  return (
    <Indicators
      height={`${height}px`}
      showAnimation
      levels={[
        {
          color: theme.reagents.SYNTH.main,
          title: (
            <Typography variant="subtitle2" color="#58595b" fontWeight={700}>
              SYN
            </Typography>
          ),
          value: synth.volumePercentageLeft || 1,
        },
        {
          color: theme.reagents.NUCLEOTIDES.main,
          title: (
            <Typography variant="subtitle2" color="#58595b" fontWeight={700}>
              NUC
            </Typography>
          ),
          value: nucl.volumePercentageLeft || 1,
        },
        {
          color: theme.reagents.PSP.main,
          title: (
            <Typography variant="subtitle2" color="#58595b" fontWeight={700}>
              PSP
            </Typography>
          ),
          value: psp.volumePercentageLeft || 1,
        },
      ]}
      spacing={2}
      showScale
    />
  );
}

export default ReagentsIndicators;
