import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ProjectType } from '__generated__/globalTypes';
import MRTable from 'src/components/table/MRTable';
import { getUniqueCreatorsFromList } from 'src/components/table/columns/useGetCreatorColumn';
import { TemplateActions, TTemplateWithKitType } from 'src/components/table/sequenceFiles/constants';
import { ERoutes } from 'src/config/routes';
import { useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { useProjectManageContext } from 'src/pages/projects/containers/projectManage';
import { QUERY_PROJECT_TEMPLATES } from 'src/services/gql/queries/projects';
import { TTemplatePerProject } from 'src/types';
import { getPermissionForProject } from 'src/utils/permissions/project';
import { isNotEmpty } from 'src/utils/ui';

import useGetSequenceColumnsInProject from './useGetSequenceColumnsInProject';

interface ITableProps {
  projectType: string;
  refetchProject: () => Promise<unknown>;
  emptyData?: JSX.Element;
}

const Table = ({ projectType, refetchProject, emptyData }: ITableProps) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const t = useTranslation();

  const {
    userProfile,
    userPermissions: {
      sequenceFiles: { canViewPrintJobDetails },
    },
  } = useUserContext();
  const { project } = useProjectManageContext();

  const {
    data,
    loading,
    refetch: refetchTemplates,
  } = useQuery(QUERY_PROJECT_TEMPLATES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { projectId: id },
  });

  const sequenceFiles = (data?.getTemplatesPerProject?.data || []).filter(isNotEmpty);
  const creators = getUniqueCreatorsFromList(sequenceFiles);
  const { designColumns, geneColumnsInProject } = useGetSequenceColumnsInProject(creators);

  if (sequenceFiles.length === 0 && emptyData && !loading) {
    return emptyData;
  }
  const permissions = project && getPermissionForProject(userProfile, project);

  const canAccessJobDetails = canViewPrintJobDetails || permissions?.projects?.details?.canViewPrintJobDetails;

  const isGeneProject = projectType === ProjectType.GENE;
  const columns = isGeneProject ? geneColumnsInProject : designColumns;

  const navigateToTemplateDetails = (template: TTemplatePerProject) => {
    if (isGeneProject) {
      history.push(
        ERoutes.APPLICATIONS__PROJECTS__DESIGN__projectId__RUNS__TEMPLATE__templateId__DETAILS.replace(
          ':projectId',
          String(id),
        ).replace(':templateId', template.id),
      );
      return;
    }
    history.push(
      ERoutes.APPLICATIONS__PROJECTS__DETAILS__projectId__RUNS__TEMPLATE__templateId__DETAILS.replace(
        ':projectId',
        String(id),
      ).replace(':templateId', template.id),
    );
  };

  const handleRefetch = () => Promise.all([refetchTemplates({ projectId: id }), refetchProject()]);

  return (
    <MRTable
      columns={columns}
      data={sequenceFiles}
      emptyDataId="template.noData"
      enableRowActions={!isGeneProject}
      initialState={{ sorting: [{ desc: true, id: isGeneProject ? 'createdAt' : 'updatedAt' }] }}
      loading={loading}
      muiTableBodyRowProps={({ row: { original: template } }) => ({
        onClick: () => {
          if (!canAccessJobDetails || !template.id) {
            return;
          }
          navigateToTemplateDetails(template);
        },
        sx: {
          cursor: 'pointer',
        },
      })}
      refetch={handleRefetch}
      renderRowActions={({ row }) => (
        <TemplateActions refetch={handleRefetch} template={row.original as unknown as TTemplateWithKitType} />
      )}
      searchPlaceholder={t('projects.detail.table.print.search.placeholder')}
      tableId={isGeneProject ? 'geneProjectTemplates' : 'projectTemplates'}
    />
  );
};

export default Table;
