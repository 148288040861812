import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 16px;
`;

export const SubText = styled.p`
  text-align: center;
  padding: 20px 0;

  > a {
    margin-left: 10px;
  }

  > .contact-email {
    margin-left: 5px;
  }
`;

export const Link = styled(RouterLink)`
  &:hover {
    text-decoration: underline;
  }
`;

export const FormWrapper = styled.form`
  margin-top: 20px;
`;

export const FormRow = styled.div`
  & + & {
    margin-top: 30px;
  }
`;

export const ErrorText = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.primary.error[600]};
`;

export const Buttons = styled.div`
  margin-top: 18px;
  margin-bottom: 8px;
  justify-content: center;
  display: flex;
`;

export const ButtonsWrapper = styled.div<{ width: string }>`
  text-align: center;
  margin-top: 10px;
`;
