import { AccessPermission, RoleType } from '__generated__/globalTypes';

import { DEFAULT_ORGANIZATION_PERMISSIONS, DEFAULT_TEAMS_PERMISSIONS, DEFAULT_USERS_PERMISSIONS } from './account';
import {
  DEFAULT_GENERAL_PLATE_EDITOR_PERMISSIONS,
  DEFAULT_INSTRUMENTS_PERMISSIONS,
  DEFAULT_INVENTORY_PERMISSIONS,
  DEFAULT_PAGES_ACCESS,
  DEFAULT_PERMISSIONS,
  DEFAULT_REPORTS_PERMISSIONS,
  DEFAULT_RUNS_PERMISSIONS,
  IUserPermissions,
} from './constants';
import {
  DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
  DEFAULT_DETAILS_PERMISSIONS,
  DEFAULT_PROJECT_LIST_PERMISSIONS,
  DEFAULT_SETTINGS_PERMISSIONS,
} from './project';

type TLocalUserWithRole = Pick<IUser, 'role' | 'cloud'>;
type TLocalUserRequiredTypes = Required<TLocalUserWithRole>;
type TOrganizationRequiredTypes = Required<Pick<IOrganizationAccount, 'printingEnabled' | 'geneEnabled'>>;

export function hasRole(role: AccessPermission | RoleType, user: IUser | null | undefined) {
  return user?.role.type?.toLowerCase() === role.toLowerCase();
}

function getAdminPermissions(user: TLocalUserRequiredTypes, organization: TOrganizationRequiredTypes) {
  const { cloud } = user;
  const { printingEnabled, geneEnabled } = organization;

  return {
    accounts: {
      ...DEFAULT_ORGANIZATION_PERMISSIONS,
      ...DEFAULT_USERS_PERMISSIONS,
      ...DEFAULT_TEAMS_PERMISSIONS,
      canActivateTeam: true,
      canAddTeamMember: true,
      canAddUser: true,
      canChangePasswordForUser: true,
      canCreateTeam: true,
      canDeactivateUser: true,
      canDeleteTeamMember: true,
      canDisableTeam: true,
      canEditOrganization: true,
      canEditPermissions: true,
      canEditProfile: true,
      canEditTeam: true,
      canEditUsers: true,
      canFilterUsers: true,
      canReactivateUser: true,
      canResentInvite: true,
      canSearchUsers: true,
      canSortUsers: true,
      canViewPermissions: false,
      canViewTeamDetails: true,
      canViewTeamName: true,
      canViewUsersDetails: true,
    },
    customProcesses: DEFAULT_CUSTOM_PROCESSES_PERMISSIONS,
    instruments: {
      ...DEFAULT_INSTRUMENTS_PERMISSIONS,
      canCreateInstruments: true,
      canDeleteInstruments: true,
      canDownloadLogs: true,
      canFilterInstruments: true,
      canManageInstrumentUsers: true,
      canManageInstruments: true,
      canSortInstruments: true,
      canViewInstrumentDetails: true,
      canViewQueue: false,
      canViewRunHistory: true,
    },
    inventory: !cloud
      ? { ...DEFAULT_INVENTORY_PERMISSIONS }
      : {
          ...DEFAULT_INVENTORY_PERMISSIONS,
          canAddInventoryItem: true,
          canEditInventory: true,
          canReorder: true,
          canViewKitDetails: true,
          canViewKitsList: true,
        },
    pages: {
      ...DEFAULT_PAGES_ACCESS,
      canViewAccountsSection: true,
      canViewApplicationSection: true && printingEnabled,
      canViewDashboardSection: true,
      canViewDesignSection: geneEnabled,
      canViewDownloadsSection: true && cloud,
      canViewFilesSection: true && printingEnabled,
      canViewInstrumentsSection: true && printingEnabled,
      canViewInventorySection: false && cloud,
      canViewOrderListSection: false && cloud,
      canViewOrganization: true,
      canViewPlatesSection: true && printingEnabled,
      canViewProductsSection: false && cloud,
      canViewProfileSection: true,
      canViewProjectsSection: true && printingEnabled,
      canViewQuoteListSection: false && cloud,
      canViewReportsSection: true && printingEnabled,
      canViewServiceSection: true,
      canViewStoreSection: false && cloud,
      canViewSupportSection: true,
      canViewTeams: true,
      canViewUsers: true,
    },
    plateEditor: {
      ...DEFAULT_GENERAL_PLATE_EDITOR_PERMISSIONS,
      canAccessPlateSettings: true,
      canAddPlate: true,
      canEditPlate: true,
      canOrderPlate: true,
      canViewPlatesDetails: true,
    },
    project: {
      ...DEFAULT_PROJECT_LIST_PERMISSIONS,
      ...DEFAULT_DETAILS_PERMISSIONS,
      ...DEFAULT_SETTINGS_PERMISSIONS,
      canArchiveProject: true,
      canCreateProject: true,
      canCreateRuns: true,
      canDownloadSample: true,
      canEditProject: true,
      canFilterProject: true,
      canManageAccess: true,
      canSearchProject: true,
      canSetApprover: true,
      canSetOwner: true,
      canSortProjects: true,
      canViewAllProjects: true,
      canViewProjectDetails: true,
      canViewProjectSettings: true,
      canViewRuns: true,
    },
    runReports: {
      ...DEFAULT_REPORTS_PERMISSIONS,
      canDelete: true,
      canDownloadItemList: true,
      canDownloadReport: true,
      canSyncRunReports: true,
      canViewAllReports: true,
      canViewReportDetails: true && printingEnabled,
      canViewReportsList: true,
      canViewRunReportData: true,
      canViewRunsDetails: true,
    },
    sequenceFiles: {
      ...DEFAULT_RUNS_PERMISSIONS,
      canCancel: true,
      canDelete: true,
      canDownloadSample: true,
      canEditPlate: true,
      canPrint: true,
      canReschedule: true,
      canSchedule: true,
      canUploadFile: true && printingEnabled,
      canViewAllFiles: true,
      canViewConfirmation: true,
      canViewPrintJobDetails: true,
    },
  };
}

function getServicePermissions(_user: TLocalUserRequiredTypes, organization: TOrganizationRequiredTypes) {
  const { printingEnabled } = organization;

  return {
    ...DEFAULT_PERMISSIONS,
    accounts: {
      ...DEFAULT_ORGANIZATION_PERMISSIONS,
      ...DEFAULT_USERS_PERMISSIONS,
      ...DEFAULT_TEAMS_PERMISSIONS,
      canEditOrganization: true,
      canEditProfile: true,
    },
    instruments: {
      ...DEFAULT_INSTRUMENTS_PERMISSIONS,
      canCreateInstruments: true,
      canDeleteInstruments: true,
      canDownloadLogs: true,
      canFilterInstruments: true,
      canManageInstruments: true,
      canSortInstruments: true,
      canViewInstrumentDetails: true,
      canViewQueue: false,
      canViewRunHistory: true,
    },
    inventory: DEFAULT_INVENTORY_PERMISSIONS,
    pages: {
      ...DEFAULT_PAGES_ACCESS,
      canViewDashboardSection: true,
      canViewFilesSection: printingEnabled,
      canViewInstrumentsSection: printingEnabled,
      canViewOrganization: true,
      canViewPlatesSection: printingEnabled,
      canViewProfileSection: true,
      canViewProjectsSection: printingEnabled,
      canViewReportsSection: printingEnabled,
      canViewServiceSection: true,
    },
    plateEditor: {
      ...DEFAULT_GENERAL_PLATE_EDITOR_PERMISSIONS,
      canAccessPlateSettings: true,
      canAddPlate: true,
      canEditPlate: true,
      canViewPlatesDetails: true,
    },
    project: {
      ...DEFAULT_PROJECT_LIST_PERMISSIONS,
      ...DEFAULT_DETAILS_PERMISSIONS,
      ...DEFAULT_SETTINGS_PERMISSIONS,
      canArchiveProject: true,
      canCreateProject: true,
      canCreateRuns: true,
      canEditProject: true,
      canFilterProject: true,
      canSearchProject: true,
      canSortProjects: true,
      canViewAllProjects: true,
      canViewProjectDetails: true,
      canViewProjectSettings: true,
    },
    runReports: {
      ...DEFAULT_REPORTS_PERMISSIONS,
      canDelete: true,
      canDownloadItemList: true,
      canDownloadReport: true,
      canViewReportDetails: true && printingEnabled,
      canViewRunReportData: true,
      canViewRunsDetails: true,
    },
    sequenceFiles: {
      ...DEFAULT_RUNS_PERMISSIONS,
      canDelete: true,
      canDownloadSample: true,
      canEditPlate: true,
      canPrint: true,
      canSchedule: true,
      canUploadFile: printingEnabled,
      canViewPrintJobDetails: true,
    },
  };
}

function getMemberPermissions(user: TLocalUserRequiredTypes, organization: TOrganizationRequiredTypes) {
  const { cloud } = user;
  const { printingEnabled, geneEnabled } = organization;

  return {
    ...DEFAULT_PERMISSIONS,
    accounts: {
      ...DEFAULT_ORGANIZATION_PERMISSIONS,
      ...DEFAULT_USERS_PERMISSIONS,
      ...DEFAULT_TEAMS_PERMISSIONS,
      canAddTeamMember: true,
      canEditProfile: true,
      canFilterUsers: true,
      canSearchUsers: true,
      canSortUsers: true,
      canViewTeamDetails: true,
      canViewTeamName: true,
      canViewUsersDetails: true,
    },
    instruments: {
      ...DEFAULT_INSTRUMENTS_PERMISSIONS,
      canFilterInstruments: true,
      canSortInstruments: true,
      canViewInstrumentDetails: true,
      canViewQueue: false,
      canViewRunHistory: true,
    },
    inventory: cloud
      ? {
          ...DEFAULT_INVENTORY_PERMISSIONS,
          canViewKitDetails: true,
          canViewKitsList: true,
        }
      : {
          ...DEFAULT_INVENTORY_PERMISSIONS,
        },
    pages: {
      ...DEFAULT_PAGES_ACCESS,
      canViewAccountsSection: true,
      canViewApplicationSection: true && printingEnabled,
      canViewDashboardSection: true,
      canViewDesignSection: geneEnabled,
      canViewFilesSection: true && printingEnabled,
      canViewInstrumentsSection: true && printingEnabled,
      canViewInventorySection: false && cloud,
      canViewOrderListSection: false && cloud,
      canViewPlatesSection: true && printingEnabled,
      canViewProductsSection: false && cloud,
      canViewProfileSection: true,
      canViewProjectsSection: true && printingEnabled,
      canViewQuoteListSection: false && cloud,
      canViewReportsSection: true && printingEnabled,
      canViewStoreSection: false && cloud,
      canViewSupportSection: true,
      canViewTeams: true,
      canViewUsers: true,
    },
    plateEditor: {
      ...DEFAULT_GENERAL_PLATE_EDITOR_PERMISSIONS,
      canAccessPlateSettings: true,
      canAddPlate: true,
      canViewPlatesDetails: true,
    },
    project: {
      ...DEFAULT_PROJECT_LIST_PERMISSIONS,
      ...DEFAULT_DETAILS_PERMISSIONS,
      ...DEFAULT_SETTINGS_PERMISSIONS,
      canArchiveProject: true,
      canCreateProject: true,
      canCreateRuns: true,
      canDownloadSample: true,
      canEditProject: true,
      canFilterProject: true,
      canSearchProject: true,
      canSortProjects: true,
      canViewAllProjects: true,
      canViewProjectDetails: true,
      canViewProjectSettings: true,
    },
    runReports: {
      canDelete: true,
      canDownloadItemList: true,
      canDownloadReport: true,
      canSyncRunReports: true,
      canViewAllReports: true,
      canViewReportDetails: true && printingEnabled,
      canViewReportsList: true,
      canViewRunReportData: true,
      canViewRunsDetails: true,
    },
    sequenceFiles: {
      canCancel: true,
      canDelete: true,
      canDownloadSample: true,
      canEditPlate: true,
      canPrint: true,
      canReschedule: true,
      canSchedule: true,
      canUploadFile: true && printingEnabled,
      canViewAllFiles: true,
      canViewConfirmation: true,
      canViewPrintJobDetails: true,
    },
  };
}

export function getUserPermissions(
  user: TLocalUserWithRole | null | undefined,
  organization: TOrganizationRequiredTypes | null | undefined,
): IUserPermissions {
  if (!user || !user.role.type || !organization) {
    return DEFAULT_PERMISSIONS;
  }

  const userWithoutUndefinedDefaults = {
    ...user,
    cloud: Boolean(user.cloud),
  };

  switch (user.role.type.toLowerCase()) {
    case RoleType.ADMIN.toLowerCase():
    case AccessPermission.ADMIN.toLowerCase(): {
      return getAdminPermissions(userWithoutUndefinedDefaults, organization);
    }
    case RoleType.SERVICE.toLowerCase(): {
      return getServicePermissions(userWithoutUndefinedDefaults, organization);
    }
    case RoleType.USER.toLowerCase():
    case AccessPermission.WRITE.toLowerCase(): {
      return getMemberPermissions(userWithoutUndefinedDefaults, organization);
    }
    default: {
      return DEFAULT_PERMISSIONS;
    }
  }
}
