import React from 'react';

import { ApplicationProvider } from './ApplicationContainer';
import ApplicationContext from './ApplicationContext';

function useApplication() {
  return React.useContext(ApplicationContext);
}

export { ApplicationProvider, useApplication };
