import * as React from 'react';

import { I18nContext } from 'src/containers/i18n';

import { II18nContext } from './I18nContext';

export default function useTranslation() {
  const { translate } = React.useContext<II18nContext>(I18nContext);
  return translate;
}
