import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import styled from 'styled-components';

import { LinkButton } from 'src/components/form';
import { ERoutes } from 'src/config/routes';
import { Translate } from 'src/containers/i18n';
import { formatErrorMessage } from 'src/utils/common';

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 100px;
  color: #ff772e;
`;

const StyledParagraph = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  white-space: break-spaces;
`;

const StyledPaper = styled(Paper)`
  max-width: 1230px;
  margin: 0 auto;
`;

interface IAccessDeniedProps {
  error?: string;
  errorDescription?: string;
}

function AccessDenied(props: IAccessDeniedProps) {
  const { error, errorDescription } = props;

  return (
    <StyledPaper elevation={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" p={5}>
        <StyledErrorIcon color="primary" />
        <Typography variant="h4" paragraph>
          {error ? formatErrorMessage(error) : <Translate id="noaccess.page.title" />}
        </Typography>
        <StyledParagraph paragraph>{errorDescription || <Translate id="noaccess.page.text" />}</StyledParagraph>
        <LinkButton id="goToHome" uppercase to={ERoutes.HOMEPAGE}>
          <Translate id="noaccess.page.button" />
        </LinkButton>
      </Box>
    </StyledPaper>
  );
}

export default AccessDenied;
