import { LinkButton } from '@dna-script-inc/shared-ui-library';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import { PlateType } from '__generated__/globalTypes';
import { Translate } from 'src/containers/i18n';
import useUserContext from 'src/hooks/useUserContext';

const StyledTile = styled.span`
  color: RGBA(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  margin-bottom: 16px;
`;

export interface IFile {
  label: string;
  title: string;
  type: PlateType;
}

interface IDownloadSamplesProps {
  sampleFiles: IFile[];
}

const DownloadSamples: React.FC<IDownloadSamplesProps> = ({ sampleFiles }) => {
  const { supportedPlateTypes } = useUserContext();
  const theme = useTheme();

  return (
    <Paper
      sx={{
        padding: '16px',
      }}
    >
      <StyledTile>
        <Translate id="runs.create.uploadFiles.typeFiles.download.title" />
      </StyledTile>

      <Grid container spacing={1} marginBottom="8px">
        {sampleFiles
          .filter((f) => supportedPlateTypes?.includes(f.type))
          .map((file) => (
            <Grid key={file.title} item xs={6} justifyContent="start" display="flex">
              <LinkButton size="sm" href={`/templates/${file.label}`}>
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontWeight: 500,
                  }}
                >
                  {file.title}
                </Typography>
              </LinkButton>
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
};

export default DownloadSamples;
