import { STORAGE, I18N_CONFIG } from 'src/config/constants';
import Storage from 'src/utils/storage';

const extractLocaleFromBrowser = (): string => navigator.language.split(/[-_]/)[0];

const getInitialLocale = (): string => {
  const locale = extractLocaleFromBrowser();
  return I18N_CONFIG.AVAILABLE_LOCALES.includes(locale) ? locale : I18N_CONFIG.DEFAULT_LOCALE;
};

const setLocale = (locale: string) => {
  Storage.LOCAL.set(STORAGE.KEY_LOCALE, locale);
};

export const getLocale = (): string => {
  let locale = Storage.LOCAL.get<string>(STORAGE.KEY_LOCALE);
  if (!locale) {
    locale = getInitialLocale();
    setLocale(locale);
  }
  return locale;
};
