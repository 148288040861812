/* eslint-disable no-restricted-imports */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, themeOptions, Tooltip } from '@dna-script-inc/shared-ui-library';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, createTheme, Grid, ThemeOptions, ThemeProvider } from '@mui/material';
import { Updater, PaginationState, VisibilityState, SortingState } from '@tanstack/react-table';
import { isEmpty } from 'lodash-es';
import merge from 'lodash-es/merge';
import MaterialReactTable, {
  MaterialReactTableProps,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { useTranslation } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';
import Storage from 'src/utils/storage';

interface IDNAMaterialReactTableProps<TData extends Record<string, any>> extends MaterialReactTableProps<TData> {
  emptyDataId?: TTranslationKeys;
  loading?: boolean;
  refetch: () => void;
  searchPlaceholder?: string;
  tableId: string;
}

const tableTheme = createTheme(
  merge(themeOptions, {
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0px 8px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            height: '24px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '4px',
            paddingRight: '8px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: '8px',
          },
        },
      },
    },
    palette: {
      background: {
        default: themeOptions.colors.primary.base.white,
      },
    },
  } as ThemeOptions) as unknown as ThemeOptions,
);

type TTableParameters = {
  globalSearch: string;
  pagination: PaginationState;
  sorting: SortingState;
  visibility: VisibilityState;
};

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_TABLE_PARAMETERS = {
  globalSearch: '',
  pagination: {
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  sorting: [],
  visibility: {},
};

function MRTable<TData extends Record<string, any>>(props: IDNAMaterialReactTableProps<TData>) {
  const {
    tableId,
    refetch,
    columns,
    data,
    emptyDataId,
    initialState,
    loading = false,
    searchPlaceholder,
    ...restProps
  } = props;

  const t = useTranslation();
  const tableKey = `table-${tableId ?? 'unknown'}`;
  const [tableData, setTableData] = useState<TTableParameters>(() => {
    const { sorting: initialStateSorting } = initialState ?? {};
    try {
      const savedTableData = Storage.SESSION.get<string>(tableKey);
      const oldTableData = JSON.parse(savedTableData ?? '') as TTableParameters;
      return {
        ...oldTableData,
        sorting: isEmpty(oldTableData.sorting) && initialStateSorting ? initialStateSorting : oldTableData.sorting,
      };
    } catch {
      return { ...DEFAULT_TABLE_PARAMETERS, sorting: initialStateSorting ?? [] };
    }
  });

  const handlePaginationChange = (updater: Updater<PaginationState>) => {
    setTableData((prevTableData) => {
      const { pagination: prevPagination } = prevTableData;
      const newPagination = updater instanceof Function ? updater(prevPagination) : updater;
      return { ...prevTableData, pagination: newPagination };
    });
  };

  const handleGlobalFilterChange = (newGlobalSearch: string | undefined) => {
    setTableData((prevTableData) => {
      return { ...prevTableData, globalSearch: newGlobalSearch ?? '' };
    });
  };

  const handleVisibilityChange = (updater: Updater<VisibilityState>) => {
    setTableData((prevTableData) => {
      const { visibility: prevVisibility } = prevTableData;
      const newVisibility = updater instanceof Function ? updater(prevVisibility) : updater;
      return { ...prevTableData, visibility: newVisibility };
    });
  };

  const handleSortingChange = (updater: Updater<SortingState>) => {
    setTableData((prevTableData) => {
      const { sorting: prevSorting } = prevTableData;
      const newSorting = updater instanceof Function ? updater(prevSorting) : updater;
      return { ...prevTableData, sorting: newSorting };
    });
  };

  useEffect(() => {
    Storage.SESSION.set(tableKey, JSON.stringify(tableData));
  }, [tableData, tableKey]);

  const { globalSearch, visibility, pagination, sorting } = tableData;

  return (
    <ThemeProvider theme={tableTheme}>
      <StyledThemeProvider theme={tableTheme}>
        <MaterialReactTable
          columns={columns}
          data={data}
          defaultColumn={{ maxSize: 1000, minSize: 20, size: 100 }}
          enableColumnFilters
          enableDensityToggle={false}
          enableFilterMatchHighlighting
          enableFullScreenToggle={false}
          enableHiding
          enableRowActions
          initialState={{
            showColumnFilters: false,
            showGlobalFilter: true,
          }}
          localization={{
            ...(emptyDataId ? { noResultsFound: t(emptyDataId) } : {}),
          }}
          muiSearchTextFieldProps={{
            id: 'search-box',
            placeholder: searchPlaceholder,
            sx: { minWidth: '400px' },
            variant: 'outlined',
          }}
          muiSelectCheckboxProps={{
            color: 'secondary',
            sx: { p: 0 },
          }}
          muiTableBodyCellProps={{ sx: { color: 'rgba(0, 0, 0, 0.87)', wordBreak: 'break-word' } }}
          muiTableHeadCellFilterTextFieldProps={{
            size: 'small',
            sx: { m: '0.5rem 0', minWidth: 'initial' },
            variant: 'outlined',
          }}
          muiTableHeadProps={{ sx: { opacity: 1 } }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, DEFAULT_PAGE_SIZE, 100],
            showFirstButton: false,
            showLastButton: false,
          }}
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          onColumnVisibilityChange={handleVisibilityChange}
          onGlobalFilterChange={handleGlobalFilterChange}
          onPaginationChange={handlePaginationChange}
          onSortingChange={handleSortingChange}
          positionActionsColumn="last"
          positionGlobalFilter="left"
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Tooltip arrow title={t('reacttable.refreshdata')}>
                    <Button variant="tertiary-gray" onClick={refetch}>
                      <RefreshIcon />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <MRT_ToggleFiltersButton table={table} />
                </Grid>
                <Grid item>
                  <MRT_ShowHideColumnsButton table={table} />
                </Grid>
              </Grid>
            </Box>
          )}
          state={{ columnVisibility: visibility, globalFilter: globalSearch, isLoading: loading, pagination, sorting }}
          {...restProps}
        />
      </StyledThemeProvider>
    </ThemeProvider>
  );
}

export default MRTable;
