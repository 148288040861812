import { useMutation } from '@apollo/client';
import React, { useMemo, useState } from 'react';

import { ProjectType, WellInfoDTO } from '__generated__/globalTypes';
import { useToast } from 'src/containers/toast';
import { TRunDetails } from 'src/pages/runs/pages/RunDetailsPage/types';
import { MUTATION_UPDATE_RUN_CONCENTRATION } from 'src/services/gql/mutations/runs';
import { getErrorMessage } from 'src/utils/errors';

import { IWell, RunReportContext } from './useRunReportUpdate';

interface IRunReportProvider {
  runDetails: TRunDetails;
}

export const RunReportProvider = ({ children, runDetails }: React.PropsWithChildren<IRunReportProvider>) => {
  const showToast = useToast();

  const [updatedWells, setUpdatedWells] = useState<IWell[]>([]);

  const isRunReportWellsUpdated = useMemo(() => {
    return !updatedWells?.some((w) => {
      return w?.actualConcentration !== runDetails.sequences?.find((s) => s?.well === w?.well)?.actualConcentration;
    });
  }, [runDetails.sequences, updatedWells]);

  const [updateRunConcentration, { loading }] = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    MUTATION_UPDATE_RUN_CONCENTRATION,
    {
      onCompleted: () => {
        showToast({
          textToTranslate: 'runs.details.concentration.update.success',
          title: 'common.toast.success.title',
        });
      },
      onError: (e) => {
        const message = getErrorMessage(e);
        showToast({
          isError: true,
          text: message,
          title: 'common.alert.errors.title',
        });
      },
      refetchQueries: ['QueryRunDetails'],
      variables: {
        projectId: runDetails.project?.id || '',
        runId: runDetails.id || '',
        wellInfo: updatedWells as WellInfoDTO[],
      },
    },
  );

  const isEditableGeneProject =
    runDetails.project?.projectType === ProjectType.GENE && runDetails.percentOfPassedSequences !== 100;

  const value = useMemo(() => {
    return {
      isEditableGeneProject,
      isRunReportWellsUpdated,
      loading,
      setUpdatedWells,
      updateRunConcentration,
      updatedWells,
    };
  }, [isEditableGeneProject, isRunReportWellsUpdated, loading, updateRunConcentration, updatedWells, setUpdatedWells]);

  return <RunReportContext.Provider value={value}>{children}</RunReportContext.Provider>;
};
