import { Tooltip, Button, TooltipProps } from '@dna-script-inc/shared-ui-library';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';
import styled from 'styled-components';

import { SequenceStatus } from '__generated__/globalTypes';
import { Translate, useTranslation } from 'src/containers/i18n';

const TooltipWithProps = (props: TooltipProps) => (
  <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
);

const StyledTooltip = styled(TooltipWithProps)`
  & .tooltip {
    background: #888b8d;
    border: 1px solid #4a4a4a;
    margin-right: -35px;
    margin-top: -25px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-left: -1rem;
  width: calc(100% + 2rem);
  margin-top: 0.5rem;
`;

const ModalContainer = styled.div`
  text-align: left;
  font-size: 14px;
  white-space: pre-wrap;
`;

export interface ISequenceRightAsideControls {
  handleDelete: () => void;
  handleReset: () => void;
  handleUndo: () => void;
  isDisabled?: boolean;
  isMultipleWells?: boolean;
  isWellStateLocallyChanged?: boolean;
  status?: SequenceStatus | string;
}

export const SequenceRightAsideControls = ({
  children,
  handleDelete,
  handleReset,
  handleUndo,
  isDisabled,
  isMultipleWells,
  isWellStateLocallyChanged,
  status,
}: React.PropsWithChildren<ISequenceRightAsideControls>) => {
  const t = useTranslation();
  return (
    <ModalContainer id="plate-editor">
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <StyledTooltip title={t('runs.plateEditor.header.reset.button.tooltip')} placement="left-start">
            <Box>
              <Button
                variant="secondary-gray"
                id="btnReset"
                disabled={isDisabled || !isWellStateLocallyChanged}
                onClick={handleReset}
              >
                <Translate id="runs.plateEditor.header.reset.button.title" />
              </Button>
            </Box>
          </StyledTooltip>
        </Grid>
        <Grid item>
          <StyledTooltip title="Undo last action taken on this well." placement="left-start">
            <Box>
              <Button
                variant="secondary-gray"
                id="btnUndo"
                disabled={isDisabled || !isWellStateLocallyChanged}
                onClick={handleUndo}
              >
                <Translate id="runs.plateEditor.header.undo.button.title" />
              </Button>
            </Box>
          </StyledTooltip>
        </Grid>

        <Grid item>
          <Button
            variant="secondary-gray"
            id="btnDelete"
            disabled={status === undefined || status === SequenceStatus.DELETED || isDisabled || isMultipleWells}
            onClick={handleDelete}
          >
            <Translate id="runs.plateEditor.header.clear.button.title" />
          </Button>
        </Grid>
      </Grid>
      <StyledDivider />
      <Box mb={2} />
      {children}
    </ModalContainer>
  );
};
