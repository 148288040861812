import React, { useMemo } from 'react';

import I18nContext, { II18nContext } from 'src/containers/i18n/I18nContext';
import Locales, { II18nData, TTranslationKeys } from 'src/i18n';
import { getLocale } from 'src/utils/i18n';

const STATE_LOCALE = getLocale();

const replaceTranslationPlaceholders = (translation: string, data: II18nData): string => {
  return Object.keys(data).reduce(
    (result, dataKey) => result.replace(new RegExp(`{${dataKey}}`, 'g'), String(data[dataKey])),
    translation,
  );
};

export const translate = (key: TTranslationKeys | undefined, data?: II18nData): string => {
  if (!key) return '';
  const translation = Locales[STATE_LOCALE][key];

  if (!data) {
    return translation;
  }

  return replaceTranslationPlaceholders(translation, data);
};

export const I18nProvider = ({ children }: React.PropsWithChildren<Record<string, unknown>>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const context: II18nContext = useMemo(
    () => ({
      locale: STATE_LOCALE,
      t: translate,
      translate,
    }),
    [],
  );

  return <I18nContext.Provider value={context}>{children}</I18nContext.Provider>;
};

export default I18nContext.Consumer;
