import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { KitType } from 'src/gql/graphql';
import { IModsAndClicksStructure } from 'src/services/gql/models/run';
import { GET_ACTIVE_MODS_AND_CLICKS } from 'src/services/gql/queries/templates';
import { isNotEmpty } from 'src/utils/ui';

export default function useGetModsAndClicksStructure(kitType: KitType | undefined | null) {
  const { data: dataMods } = useQuery(GET_ACTIVE_MODS_AND_CLICKS, {
    fetchPolicy: 'no-cache',
    skip: !kitType,
    variables: {
      kitType: kitType ?? KitType.B5_HMASS_96,
    },
  });

  const modsAndClicksStructure = useMemo(() => {
    const emptyReturn: IModsAndClicksStructure = {
      modsAndClicks: [],
      validClickPairs: [],
    };
    const activeModsAndClicks = dataMods?.getActiveModsAndClicks;
    if (!activeModsAndClicks) {
      return emptyReturn;
    }
    return {
      modsAndClicks: activeModsAndClicks.modsAndClicks,
      validClickPairs: (activeModsAndClicks.validClickPairs ?? []).filter(isNotEmpty),
    };
  }, [dataMods]);

  return modsAndClicksStructure;
}
