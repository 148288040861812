import { Tooltip } from '@dna-script-inc/shared-ui-library';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'src/containers/i18n';
import { useGroupsList } from 'src/hooks/useGroupsList';
import { TTranslationKeys } from 'src/i18n';
import { isNotEmpty } from 'src/utils/ui';

interface ITeamSelectProps<T> {
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  id?: string;
  label: TTranslationKeys;
  multiple?: boolean;
  onChange: (value: T) => void;
  value?: T;
}

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;

const TeamSelect = <T extends IGroup | IGroup[]>({
  label,
  value,
  onChange,
  id,
  disabled,
  multiple = false,
  disableCloseOnSelect = false,
}: ITeamSelectProps<T>) => {
  const t = useTranslation();

  const { data: groupsData, loading } = useGroupsList();

  const teams: IGroup[] = React.useMemo(() => {
    const groups = (groupsData?.allGroups?.data || []).filter(isNotEmpty);
    return groups.map(
      (g) =>
        ({
          creator: g.creator,
          creatorFirstName: g.creator?.firstName ?? '',
          creatorLastName: g.creator?.lastName ?? '',
          description: g.description,
          id: g.id,
          name: g.name,
          status: g.status,
          users: g.users?.map((u) => ({
            firstName: u?.firstName,
            id: u?.id,
            lastName: u?.lastName,
            role: undefined,
            status: undefined,
            timeZone: undefined,
            userName: undefined,
          })),
        } as IGroup),
    );
  }, [groupsData?.allGroups?.data]);

  return (
    <Autocomplete
      fullWidth
      disableClearable
      options={teams}
      loading={loading}
      disabled={disabled}
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      id={id}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        const tooltipTitle = (
          <div>
            {option.users
              ?.map((u) => `${u.firstName ?? ''} ${u.lastName ?? ''}`)
              .map((s) => (
                <li key={s}>{s}</li>
              ))}
          </div>
        );
        return (
          <li {...props}>
            <Tooltip title={tooltipTitle}>
              <div>
                <StyledAvatar> {option?.name.trim()[0]} </StyledAvatar>
              </div>
            </Tooltip>
            {option?.name}
          </li>
        );
      }}
      value={value}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(_e, selectedValue) => onChange(selectedValue)}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          id={id}
          label={t(label)}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};

export default TeamSelect;
