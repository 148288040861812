/* eslint-disable max-len */
import React from 'react';

export default function ApplicationsIcon({ color = '#D0D5DD' }: { color?: string }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99998 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99998 3.33334C9.53974 3.33334 9.16665 3.70644 9.16665 4.16668C9.16665 4.62691 9.53974 5.00001 9.99998 5.00001Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99998 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10C10.8333 9.53977 10.4602 9.16668 9.99998 9.16668C9.53974 9.16668 9.16665 9.53977 9.16665 10C9.16665 10.4602 9.53974 10.8333 9.99998 10.8333Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99998 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99998 15C9.53974 15 9.16665 15.3731 9.16665 15.8333C9.16665 16.2936 9.53974 16.6667 9.99998 16.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 5.00001C16.2935 5.00001 16.6666 4.62691 16.6666 4.16668C16.6666 3.70644 16.2935 3.33334 15.8333 3.33334C15.3731 3.33334 15 3.70644 15 4.16668C15 4.62691 15.3731 5.00001 15.8333 5.00001Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 10.8333C16.2935 10.8333 16.6666 10.4602 16.6666 10C16.6666 9.53977 16.2935 9.16668 15.8333 9.16668C15.3731 9.16668 15 9.53977 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 16.6667C16.2935 16.6667 16.6666 16.2936 16.6666 15.8333C16.6666 15.3731 16.2935 15 15.8333 15C15.3731 15 15 15.3731 15 15.8333C15 16.2936 15.3731 16.6667 15.8333 16.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16665 5.00001C4.62688 5.00001 4.99998 4.62691 4.99998 4.16668C4.99998 3.70644 4.62688 3.33334 4.16665 3.33334C3.70641 3.33334 3.33331 3.70644 3.33331 4.16668C3.33331 4.62691 3.70641 5.00001 4.16665 5.00001Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16665 10.8333C4.62688 10.8333 4.99998 10.4602 4.99998 10C4.99998 9.53977 4.62688 9.16668 4.16665 9.16668C3.70641 9.16668 3.33331 9.53977 3.33331 10C3.33331 10.4602 3.70641 10.8333 4.16665 10.8333Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16665 16.6667C4.62688 16.6667 4.99998 16.2936 4.99998 15.8333C4.99998 15.3731 4.62688 15 4.16665 15C3.70641 15 3.33331 15.3731 3.33331 15.8333C3.33331 16.2936 3.70641 16.6667 4.16665 16.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
