import { HelixLoader } from '@dna-script-inc/shared-ui-library';
import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { FormattedError } from 'src/components/common';
import { Translate } from 'src/containers/i18n';
import { useInstrumentStatus } from 'src/hooks/useInstrumentStatus';
import ReagentsIndicators from 'src/pages/instruments/pages/list/components/chart/ReagentsIndicators';

import { SpinnerWrapper } from './styled';

const Title = styled(Box)`
  margin-left: -35px;
  font-weight: 700;
`;

const ChartWrapper = styled.div`
  width: 400px;
  padding: 10px 45px 40px;
`;

interface IReagentsChartProps {
  instrumentId: string;
}

const ReagentsChart: React.FC<IReagentsChartProps> = ({ instrumentId }) => {
  const { reagents, loading, error } = useInstrumentStatus(instrumentId);

  if (loading) {
    return (
      <SpinnerWrapper>
        <HelixLoader width={100} />
      </SpinnerWrapper>
    );
  }

  if (error) {
    return <FormattedError error={error} />;
  }

  return (
    <ChartWrapper>
      <Title
        sx={{
          marginBottom: '8px',
        }}
      >
        <Translate id="schedule.form.reagents.title" />
      </Title>
      <ReagentsIndicators reagents={reagents} height={120} />
    </ChartWrapper>
  );
};

export default ReagentsChart;
