import { useQuery } from '@apollo/client';
import { Button, ErrorModal } from '@dna-script-inc/shared-ui-library';
import MoreVert from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MRT_ColumnDef } from 'material-react-table';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { RunStatusTag } from 'src/components/RunStatusTag';
import MRTable from 'src/components/table/MRTable';
import useGetAssignedInstrumentColumn from 'src/components/table/columns/useGetAssignedInstrumentColumn';
import useGetEndDateColumn from 'src/components/table/columns/useGetEndDateColumn';
import useGetIdColumn from 'src/components/table/columns/useGetIdColumn';
import useGetKitTypeColumn from 'src/components/table/columns/useGetKitTypeColumn';
import useGetNameColumn from 'src/components/table/columns/useGetNameColumn';
import useGetPassingPercentageColumn from 'src/components/table/columns/useGetPassingPercentageColumn';
import useGetSizeRangeColumn from 'src/components/table/columns/useGetSizeRangeColumn';
import useDownloadInstructionsFile from 'src/components/table/runsTable/useDownloadInstructionsFile';
import { ERoutes } from 'src/config/routes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { RunStatus } from 'src/gql/graphql';
import { useToggle } from 'src/hooks/useToggle';
import useUserContext from 'src/hooks/useUserContext';
import { downloadInstructionsSetZip } from 'src/services/api/common';
import { QUERY_PROJECT_RUNS } from 'src/services/gql/queries/projects';
import { QUERY_GET_RUNS_GROUPS_PER_PROJECT } from 'src/services/gql/queries/runs';
import { TRunFromGroupPerProject, TRunGroupPerProject } from 'src/types';
import { stopPropagationClick } from 'src/utils/ui';

export const PAGE_SIZE = 20;

interface ITableProps {
  refetchProject: () => Promise<unknown>;
  emptyData?: JSX.Element;
}

const ActionsWrapper = styled.div`
  display: flex;
`;

interface IParams {
  id: string;
}

type TRun = IProjectGroupRun | IProjectRun | TRunGroupPerProject | TRunFromGroupPerProject;

const GeneRunActions = ({ run, isGroup = false }: { isGroup?: boolean; run: TRun }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const params = useParams<IParams>();
  const { id } = params;
  const history = useHistory();

  const {
    userPermissions: {
      runReports: { canViewRunReportData },
    },
  } = useUserContext();

  const handleMoreClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const hasMenuOptions = RunStatus.COMPLETED === run?.status;

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const getInstructionFile = useCallback(async () => {
    const version = 'version' in run ? run.version ?? undefined : undefined;
    const versionString = typeof version === 'number' ? version.toString() : version;
    // eslint-disable-next-line no-return-await, @typescript-eslint/no-unsafe-return
    return await downloadInstructionsSetZip(id, versionString);
  }, [id, run]);

  const { handleDownloadInstructions, instructionFile } = useDownloadInstructionsFile(getInstructionFile);

  const shouldDisplayDownloadInstructions = useMemo(() => {
    if (!isGroup || !canViewRunReportData) return false;
    if (!('numberOfPlates' in run && 'completed' in run && 'runs' in run)) return false;
    const { numberOfPlates, completed, runs } = run;
    if (!runs) return false;
    const isAllOligosPassed = runs?.every((r) => r?.percentOfPassedOligos === 100);
    return numberOfPlates === completed && isAllOligosPassed && Boolean(instructionFile);
  }, [canViewRunReportData, instructionFile, isGroup, run]);

  const menuItems: JSX.Element[] = [];
  if (shouldDisplayDownloadInstructions) {
    menuItems.push(
      <MenuItem
        onClick={() => {
          handleDownloadInstructions();
          handleClose();
        }}
      >
        <Translate id="runs.download.instructions" />
      </MenuItem>,
    );
  }
  if (!isGroup) {
    menuItems.push(
      <MenuItem
        disabled={!canViewRunReportData}
        onClick={() => {
          const runId = 'id' in run ? run.id : undefined;
          history.push(
            ERoutes.APPLICATIONS__PROJECTS__DETAILS__id__REPORT__reportId.replace(':projectId', String(id)).replace(
              ':id',
              String(runId),
            ),
          );
        }}
      >
        <Translate id="runs.download.view.report" />
      </MenuItem>,
    );
  }

  if (!menuItems.length) return null;

  return (
    <>
      <ActionsWrapper onClick={stopPropagationClick}>
        {hasMenuOptions && (
          <Button
            variant="tertiary-gray"
            className="test-dropdownIBtn"
            disabled={!canViewRunReportData}
            onClick={handleMoreClick}
            size="sm"
          >
            <MoreVert />
          </Button>
        )}
      </ActionsWrapper>
      <Menu
        anchorEl={anchorEl}
        id="geneRunOptions"
        keepMounted
        onClick={stopPropagationClick}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {menuItems}
      </Menu>
    </>
  );
};

function useGetProjectsColumns() {
  const t = useTranslation();
  const nameColumn = useGetNameColumn<IProjectGroupRun>({
    filterTitle: 'projects.detail.table.runs.synthesis',
    headerTitle: 'projects.detail.table.runs.synthesis',
  });

  const statusColumn: MRT_ColumnDef<IProjectGroupRun> = useMemo(
    () => ({
      Cell: ({ renderedCellValue }) => <RunStatusTag status={renderedCellValue as RunStatus} />,
      Header: () => <Translate id="projects.detail.table.runs.status" />,
      accessorFn: (row) => row.status ?? '',
      header: t('projects.detail.table.runs.status'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.status') },
    }),
    [t],
  );

  const numberOfPlatesColumn: MRT_ColumnDef<IProjectGroupRun> = useMemo(
    () => ({
      Header: () => <Translate id="projects.detail.table.runs.numberOfPlates" />,
      accessorFn: (row) => row.numberOfPlates ?? '',
      header: t('projects.detail.table.runs.numberOfPlates'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.numberOfPlates') },
    }),
    [t],
  );

  const countOfQueuedColumn: MRT_ColumnDef<IProjectGroupRun> = useMemo(
    () => ({
      Header: () => <Translate id="projects.detail.table.runs.queued" />,
      accessorFn: (row) => row.queued ?? '',
      header: t('projects.detail.table.runs.queued'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.queued') },
    }),
    [t],
  );

  const countOfCompletedColumn: MRT_ColumnDef<IProjectGroupRun> = useMemo(
    () => ({
      Header: () => <Translate id="projects.detail.table.runs.completed" />,
      accessorFn: (row) => row.completed ?? '',
      header: t('projects.detail.table.runs.completed'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.completed') },
    }),
    [t],
  );

  return useMemo(
    () => [nameColumn, statusColumn, numberOfPlatesColumn, countOfQueuedColumn, countOfCompletedColumn],
    [nameColumn, statusColumn, numberOfPlatesColumn, countOfQueuedColumn, countOfCompletedColumn],
  );
}

function useGetGroupedRunColumns() {
  const t = useTranslation();

  const groupedRunNameColumn = useGetNameColumn<TRunFromGroupPerProject>({
    filterTitle: 'projects.detail.table.runs.name',
    headerTitle: 'projects.detail.table.runs.name',
  });

  const groupedRunStatusColumn: MRT_ColumnDef<TRunFromGroupPerProject> = useMemo(
    () => ({
      Cell: ({ renderedCellValue }) => <RunStatusTag status={renderedCellValue as RunStatus} />,
      Header: () => <Translate id="projects.detail.table.runs.status" />,
      accessorFn: (row) => row.status ?? '',
      header: t('projects.detail.table.runs.status'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.status') },
    }),
    [t],
  );

  const kitTypeColumn = useGetKitTypeColumn<TRunFromGroupPerProject>();
  const creatorColumn: MRT_ColumnDef<TRunFromGroupPerProject> = useMemo(
    () => ({
      Header: () => <Translate id="projects.detail.table.runs.creator" />,
      accessorFn: (row) => (row?.creator ? `${row.creator.firstName ?? ''} ${row.creator.lastName ?? ''}` : ''),
      header: t('projects.detail.table.runs.creator'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.creator') },
    }),
    [t],
  );
  const assignedInstrumentColumn = useGetAssignedInstrumentColumn<TRunFromGroupPerProject>();

  const idColumn = useGetIdColumn<TRunFromGroupPerProject>({
    filterTitle: 'projects.detail.table.runs.id',
    headerTitle: 'projects.detail.table.runs.id',
  });

  const passingAndTotalOligosColumn = useGetPassingPercentageColumn<TRunFromGroupPerProject>();

  const sizeRangeColumn = useGetSizeRangeColumn<TRunFromGroupPerProject>();

  const endDateColumn = useGetEndDateColumn<TRunFromGroupPerProject>();

  return useMemo(
    () => [
      groupedRunNameColumn,
      groupedRunStatusColumn,
      kitTypeColumn,
      creatorColumn,
      assignedInstrumentColumn,
      idColumn,
      passingAndTotalOligosColumn,
      sizeRangeColumn,
      endDateColumn,
    ],
    [
      groupedRunNameColumn,
      groupedRunStatusColumn,
      kitTypeColumn,
      creatorColumn,
      assignedInstrumentColumn,
      idColumn,
      passingAndTotalOligosColumn,
      sizeRangeColumn,
      endDateColumn,
    ],
  );
}

const ProjectGeneRunsTable = ({ refetchProject, emptyData }: ITableProps) => {
  const { isOpen, close } = useToggle(false);
  const t = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { loading } = useQuery(QUERY_PROJECT_RUNS, {
    fetchPolicy: 'network-only',
    variables: {
      projectId: id,
    },
  });

  const {
    data: dataGroups,
    loading: loadingGroups,
    refetch: getRunGroups,
  } = useQuery(QUERY_GET_RUNS_GROUPS_PER_PROJECT, {
    fetchPolicy: 'network-only',
    variables: {
      projectId: id,
    },
  });

  const runs: IProjectGroupRun[] = useMemo(
    () => (dataGroups?.getRunGroupsPerProject?.data || []) as unknown as IProjectGroupRun[],
    [dataGroups],
  );

  const columns = useGetProjectsColumns();

  const genePageSubColumns = useGetGroupedRunColumns();

  if (runs.length === 0 && emptyData && !loading) {
    return emptyData;
  }

  return (
    <>
      <MRTable
        columns={columns}
        data={runs}
        emptyDataId="runs.noData"
        loading={loading || loadingGroups}
        refetch={() => {
          return Promise.all([getRunGroups(), refetchProject()]);
        }}
        renderRowActions={({ row }) => <GeneRunActions isGroup run={row.original as TRun} />}
        renderDetailPanel={({ row }) => (
          <MRTable<TRunFromGroupPerProject>
            columns={genePageSubColumns}
            data={row.original.runs}
            emptyDataId="runs.noData"
            refetch={() => Promise.resolve()}
            renderRowActions={({ row: subRow }) => subRow.original && <GeneRunActions run={subRow.original} />}
            searchPlaceholder={t('downloads.listPage.table.search.placeholder')}
            tableId="downloads"
          />
        )}
        searchPlaceholder={t('downloads.listPage.table.search.placeholder')}
        tableId="downloads"
      />
      <ErrorModal
        title={t('runs.errors.incomplete.title')}
        isOpen={isOpen}
        onReject={close}
        rejectButtonTitle={t('common.button.close')}
      >
        <Translate id="runs.errors.incomplete.body" />
      </ErrorModal>
    </>
  );
};

export default ProjectGeneRunsTable;
