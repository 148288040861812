import { Tooltip, Button } from '@dna-script-inc/shared-ui-library';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

import { useTranslation, Translate } from 'src/containers/i18n';
import { TGeneDetail } from 'src/types';

interface IGeneCardProps {
  geneDetail: TGeneDetail;
}

const GeneCard = ({ geneDetail }: IGeneCardProps) => {
  const theme = useTheme();
  const t = useTranslation();
  const vector = geneDetail?.vector;
  if (!vector) {
    return null;
  }
  const { sequence: vectorSequence } = vector;
  const vectorWithoutPrimers = vectorSequence;

  return (
    <Card sx={{ marginY: '24px', padding: '24px' }}>
      <Grid container spacing={2} justifyContent="space-between" direction="row">
        <Grid item xs={11}>
          <Typography variant="subtitle1" noWrap>
            <Translate id="projects.detail.tabs.gene.vector" />
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ float: 'right' }}>
            <Tooltip title={t('projects.detail.tabs.gene.copyVector')} placement="left">
              <Button
                variant="tertiary-gray"
                onClick={() => {
                  navigator?.clipboard?.writeText?.(vectorSequence);
                }}
              >
                <ContentCopyIcon />
              </Button>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ maxHeight: '200px', overflow: 'scroll', wordBreak: 'break-all' }}>
            <Typography variant="body1" color={theme.colors.primary.error[600]} component="span">
              {vectorWithoutPrimers}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GeneCard;
