import { ISequenceEditorState, TSequenceHistory } from './types';

export const isSelectingAtLeastOneWellSelector = (state: ISequenceEditorState) => state.selectedWells.length > 0;

export const isSelectingMoreThanOneWellSelector = (state: ISequenceEditorState) => state.selectedWells.length > 1;

export const getCurrentWellState = (well: string) => (state: ISequenceEditorState) => {
  const wellState = state.sequencesHistory?.[well];
  if (!wellState) return null;
  const { states, currentIndex } = wellState;
  return states[currentIndex];
};

export const getLastWellStateSaved = (well: string) => (state: ISequenceEditorState) => {
  const wellState = (state.templateDetailsLastSave?.sequences ?? []).find((seq) => seq.well === well);
  return wellState ?? null;
};

export const getCurrentSequencesFromSequenceHistory = (sequencesHistory: TSequenceHistory) => {
  return Object.values(sequencesHistory)
    .map((sequenceHistory) => {
      const { states, currentIndex } = sequenceHistory;
      return states[currentIndex];
    })
    .flat();
};
