import { Button, DNAButtonProps } from '@dna-script-inc/shared-ui-library';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Menu, { MenuProps } from '@mui/material/Menu';
import React, { useState } from 'react';

import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

export interface IDropdownProps extends Partial<Pick<DNAButtonProps, 'size' | 'variant'>> {
  buttonTitle: TTranslationKeys;
  id: string;
  loading?: boolean;
  menuProps?: Pick<MenuProps, 'keepMounted'>;
  shouldCloseOnClick?: boolean;
}

export const Dropdown = ({
  id,
  buttonTitle,
  children,
  loading,
  shouldCloseOnClick,
  size = 'md',
  menuProps,
  variant = 'secondary',
}: React.PropsWithChildren<IDropdownProps>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        endIcon={<ArrowDropDown />}
        loading={loading}
        onClick={handleClick}
        size={size}
        uppercase
        variant={variant}
      >
        <Translate id={buttonTitle} />
      </Button>
      <Menu
        {...menuProps}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id={id}
        onClick={shouldCloseOnClick ? handleClose : undefined}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {children}
      </Menu>
    </>
  );
};
