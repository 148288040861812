/* eslint-disable react/display-name */
import Paper from '@mui/material/Paper';
import React, { Suspense, useCallback } from 'react';

import { getKeyFromSequence } from 'src/components/sequences/sequence/SequenceField/utils';
import Translate from 'src/containers/i18n/Translate';
import { TSequence } from 'src/stores/sequenceEditor/types';

import { TSequenceAcceptedByInlineEditor } from './InlineEditor/InlineEditor';
import { clearSequenceFieldText } from './sequenceFieldRules';
import { StyledBackdrop, StyledContainer, StyledHighLights, StyledLabel, StyledMessage, StyledPopper } from './styled';

const InlineEditorLazy = React.lazy(() =>
  import(/* webpackChunkName: "InlineEditor" */ './InlineEditor/InlineEditor').then((module) => ({
    default: module.InlineEditor,
  })),
);
export interface ILeadingSequenceFieldEditableProps {
  disabled?: boolean;
  onChange: (value: string) => void;
  sequence?: Pick<TSequence, 'id' | 'well' | 'status' | 'dataChunks' | 'nucErrors' | 'nucWarnings' | 'data'>;
}

let changeFreezeTimeoutId = 0;
export const LeadingSequenceFieldEditable = React.memo(
  ({ onChange, sequence, disabled }: ILeadingSequenceFieldEditableProps) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const onChangeSlate = useCallback(
      (value: string) => {
        clearTimeout(changeFreezeTimeoutId);
        const strChunks = clearSequenceFieldText(value);
        changeFreezeTimeoutId = window.setTimeout(() => {
          onChange(strChunks);
        }, 100);
      },
      [onChange],
    );

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);
    const open = Boolean(anchorEl);

    const close = useCallback(() => {
      setAnchorEl(undefined);
    }, [setAnchorEl]);

    const handleMouseOver = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const mark = (e.target as HTMLElement).closest('mark');
        if (mark) {
          setAnchorEl(mark);
        } else {
          close();
        }
      },
      [setAnchorEl, close],
    );

    return (
      <div>
        <StyledContainer>
          <StyledBackdrop>
            <StyledLabel>
              <Translate id="runs.plateEditor.editWindow.sequenceField.leading.sequence" />
            </StyledLabel>
            <StyledHighLights id="sequence" onMouseOver={handleMouseOver}>
              <Suspense fallback={null}>
                <InlineEditorLazy
                  key={getKeyFromSequence(sequence as TSequenceAcceptedByInlineEditor) || ''}
                  sequence={sequence as TSequenceAcceptedByInlineEditor}
                  onChange={onChangeSlate}
                  readOnly={disabled}
                />
              </Suspense>
            </StyledHighLights>
          </StyledBackdrop>
        </StyledContainer>

        <StyledPopper
          open={open}
          anchorEl={anchorEl}
          onMouseLeave={close}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <Paper>
            <StyledMessage p={1}>{anchorEl && anchorEl.dataset.title}</StyledMessage>
          </Paper>
        </StyledPopper>
      </div>
    );
  },
);
