import Check from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

const StyledPaper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckCircle = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #4bb667;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: #fff;
  }
`;

const Title = styled(Typography)`
  margin: 25px 0;
`;

const SubTitle = styled.p`
  font-size: 14px;
  color: #777;
  max-width: 320px;
  text-align: center;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #eee;
  margin: 25px 0;
`;

interface ICongratulationBlockProps {
  info?: TTranslationKeys;
  title?: TTranslationKeys;
}

const CongratulationBlock: React.FC<ICongratulationBlockProps> = (props) => {
  const { title = 'template.congratulations.title', info = 'template.congratulations.info' } = props;

  return (
    <StyledPaper>
      <CheckCircle>
        <Check />
      </CheckCircle>
      <Title variant="h4">
        <Translate id={title} />
      </Title>
      <SubTitle>
        <Translate id={info} />
      </SubTitle>
      <Line />
    </StyledPaper>
  );
};

export default CongratulationBlock;
