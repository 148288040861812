import { Button, ConfirmModal, DeleteModal } from '@dna-script-inc/shared-ui-library';
import MoreVert from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { DeleteMenuItem } from 'src/components/DeleteMenuItem';
import { Translate, useTranslation } from 'src/containers/i18n';
import { KitType, ProjectType, TemplateStatus } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import { useCanDeleteSequenceFile } from 'src/hooks/useCanDeleteSequenceFile';
import useDeleteTemplate from 'src/hooks/useDeleteTemplate';
import useRestoreTemplate from 'src/hooks/useRestoreTemplates';
import { useToggle } from 'src/hooks/useToggle';
import ConfirmationModal from 'src/pages/runs/pages/scheduler/components/ConfirmationModal';
import PrintNowModal from 'src/pages/runs/pages/scheduler/components/PrintNowModal';
import { TTemplatePerUserDTO } from 'src/types';
import { handlePrivateDownload } from 'src/utils/common';
import { StackSequencesService } from 'src/utils/nucleotide';
import { stopPropagationClick } from 'src/utils/ui';

const TEMPLATE_STATUSES = [TemplateStatus.ERROR, TemplateStatus.OUTDATED];

const ActionsWrapper = styled.div`
  display: flex;
`;

export type TTemplateWithKitType = TTemplatePerUserDTO & { kitType?: KitType };

interface ITemplateActionsProps {
  refetch: () => Promise<unknown>;
  template: TTemplateWithKitType;
}

export const TemplateActions: React.FC<ITemplateActionsProps> = ({ template, refetch }) => {
  const [printDetail, setPrintDetail] = useState<ICreateProjectRun | null>(null);
  const [currentTemplate, setCurrentTemplate] = useState<null | TTemplateWithKitType>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore projectType doesn't exist in project
  const isGeneProject = template?.project?.projectType === ProjectType.GENE;

  const { deleteTemplate, loading } = useDeleteTemplate({
    fileName: template?.name ?? '',
    id: template?.id,
    projectId: template?.project?.id || '',
  });
  const restoreTemplate = useRestoreTemplate(String(template?.id));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasDeleteConfirmationOpened, setHasDeleteConfirmationOpened] = useState(false);
  const {
    isOpen: hasRestoreConfirmationOpened,
    close: handleRestoreConfirmationClose,
    open: handleRestoreConfirmationOpen,
  } = useToggle();
  const t = useTranslation();
  const {
    userPermissions: { project, sequenceFiles },
    hasValidLicense,
  } = useUserContext();

  const handleMoreClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, selectedTemplate: TTemplatePerUserDTO) => {
      setCurrentTemplate(selectedTemplate as TTemplateWithKitType);
      setAnchorEl(event.currentTarget);
    },
    [setCurrentTemplate, setAnchorEl],
  );

  const handleDownload = React.useCallback((selectedTemplate: TTemplatePerUserDTO) => {
    if (selectedTemplate?.exportMetaLink) {
      handlePrivateDownload(selectedTemplate.exportMetaLink);
    }
  }, []);

  const handleDeleteConfirmationClose = useCallback(() => {
    setHasDeleteConfirmationOpened(false);
    setCurrentTemplate(null);
  }, [setCurrentTemplate, setHasDeleteConfirmationOpened]);

  const handleDelete = async () => {
    if (currentTemplate) {
      await deleteTemplate({
        variables: {
          id: currentTemplate.id,
        },
      });
      StackSequencesService.clearStackById(currentTemplate.id);
    }
    await refetch();
    handleDeleteConfirmationClose();
    return Promise.resolve();
  };

  const hasMenuOptions = true;

  const { toggle, isOpen } = useToggle();
  const { toggle: toggleConfirmation, isOpen: showConfirmation } = useToggle();

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleCreateRunSuccess = (createRunPrintDetail: ICreateProjectRun) => {
    setPrintDetail({
      ...createRunPrintDetail,
      kitType: currentTemplate?.kitType ?? '',
    });
  };

  const handlePrintNowOptionClick = useCallback(() => {
    toggle();
    handleMenuClose();
  }, [toggle, handleMenuClose]);

  const handleExportOptionClick = useCallback(() => {
    handleDownload(template);
    handleMenuClose();
  }, [handleDownload, template, handleMenuClose]);

  const handleDeleteOptionClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setHasDeleteConfirmationOpened(true);
      handleMenuClose();
    },
    [setHasDeleteConfirmationOpened, handleMenuClose],
  );

  const canDeleteSequenceFile = useCanDeleteSequenceFile(template.creator) && !isGeneProject;

  const isPrintDisabled =
    !hasValidLicense() ||
    (template.status && TEMPLATE_STATUSES.includes(template.status)) ||
    !(project.canEditProject || sequenceFiles.canPrint) ||
    isGeneProject;

  return (
    <ActionsWrapper onClick={stopPropagationClick}>
      {hasMenuOptions && (
        <Button
          variant="tertiary-gray"
          className="test-dropdownIBtn"
          onClick={(e) => handleMoreClick(e, template)}
          size="sm"
        >
          <MoreVert />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        id="templateOptions"
        keepMounted
        onClick={stopPropagationClick}
        onClose={handleMenuClose}
        open={!!anchorEl}
      >
        {template.status === TemplateStatus.DELETE ? (
          <MenuItem onClick={handleRestoreConfirmationOpen}>
            <Translate id="templates.option.restore" />
          </MenuItem>
        ) : (
          <div>
            {sequenceFiles.canPrint && (
              <MenuItem disabled={isPrintDisabled} onClick={handlePrintNowOptionClick}>
                <Translate id="sequenceFiles.upload.syntax" />
              </MenuItem>
            )}

            <MenuItem disabled={Boolean(!template?.exportMetaLink)} onClick={handleExportOptionClick}>
              <Translate id="templates.option.download" />
            </MenuItem>
            {/* <MenuItem>
              <Translate id="templates.option.scheduleForLater" />
            </MenuItem> */}
            <DeleteMenuItem disabled={!canDeleteSequenceFile} onClick={handleDeleteOptionClick}>
              <Translate id="templates.option.delete" />
            </DeleteMenuItem>
          </div>
        )}
      </Menu>
      {currentTemplate?.id && (
        <PrintNowModal
          isOpen={isOpen}
          onCancel={toggle}
          onCreateRunSuccess={handleCreateRunSuccess}
          onSubmit={() => {
            toggle();
            toggleConfirmation();
          }}
          templateId={currentTemplate.id}
          kitType={currentTemplate.kitType}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          onClose={() => {
            refetch();
            toggleConfirmation();
          }}
          printDetail={printDetail}
          title="template.printNow.congratulations.title"
        />
      )}
      {hasDeleteConfirmationOpened && (
        <DeleteModal
          title={t('modal.delete.sequenceFile.title')}
          isOpen
          onSubmit={handleDelete}
          onReject={handleDeleteConfirmationClose}
          rejectButtonTitle={t('common.button.cancel')}
          submitButtonTitle={t('modal.delete.sequenceFile.confirm')}
          loading={loading}
        >
          <Translate id="modal.delete.sequenceFile.note" />
        </DeleteModal>
      )}
      {hasRestoreConfirmationOpened && (
        <ConfirmModal
          title={t('template.restore.modal.title')}
          isOpen
          onSubmit={() => {
            restoreTemplate();
            refetch();
            handleRestoreConfirmationClose();
          }}
          onReject={handleRestoreConfirmationClose}
          rejectButtonTitle={t('common.button.cancel')}
          submitButtonTitle={t('common.button.confirm')}
        >
          <Translate id="template.restore.modal.text" />
        </ConfirmModal>
      )}
    </ActionsWrapper>
  );
};
