import Typography from '@mui/material/Typography';
import noop from 'lodash-es/noop';
import React from 'react';
import styled from 'styled-components';

import Breadcrumbs, { TBreadcrumbsItem } from 'src/components/nav/Breadcrumbs';
import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`;

const TitleWrapper = styled.div`
  margin-top: 14px;
`;

const DEFAULT_RENDER_TITLE = () => null;

interface IPageHeadingProps {
  breadcrumbs?: TBreadcrumbsItem[];
  renderControls?: () => React.ReactNode;
  renderTitle?: () => React.ReactNode;
  title?: TTranslationKeys;
}

export const PageHeading: React.FC<IPageHeadingProps> = ({
  breadcrumbs = [],
  title,
  renderTitle = DEFAULT_RENDER_TITLE,
  renderControls = noop,
}) => {
  return (
    <>
      <HeaderWrapper>
        <Breadcrumbs items={breadcrumbs} />
        <>{renderControls()}</>
      </HeaderWrapper>
      <TitleWrapper>
        <Typography variant="h2" noWrap id="title">
          {title ? <Translate id={title} /> : renderTitle()}
        </Typography>
      </TitleWrapper>
    </>
  );
};
