import { graphql } from 'src/gql';

export const QUERY_GROUPS_LIST = graphql(`
  query GroupsList(
    $searchText: String!
    $page: Int!
    $size: Int!
    $orderField: String!
    $orderDirection: Direction!
    $status: String
  ) {
    allGroups(
      filter: {
        searchText: $searchText
        page: { page: $page, size: $size }
        sort: { field: $orderField, direction: $orderDirection }
        status: $status
      }
    ) {
      data {
        id
        name
        description
        status
        creator {
          id
          firstName
          lastName
        }
        updatedAt: lastUpdatedDate
        users {
          id
          firstName
          lastName
        }
      }
      totalElementsCount
    }
  }
`);

export const QUERY_GROUP_DETAILS = graphql(`
  query GroupById($groupId: ID!) {
    getGroupById(groupId: $groupId) {
      id
      name
      description
      status
      creator {
        id
        firstName
        lastName
      }
      updatedAt: lastUpdatedDate
      users {
        id
        firstName
        lastName
        status
        role {
          id
          name
          description
          type
          authority
        }
        dateAdded
      }
    }
  }
`);
