import { useLazyQuery } from '@apollo/client';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import styled from 'styled-components';

import { QUERY_DESIGN_CONTEXT_SWITCH } from 'src/services/gql/queries/service';

import ApplicationsIcon from './ApplicationsIcon';
import StyledIconButton from './StyledIconButton';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    {...props}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    elevation={2}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
  />
))(() => ({
  '& .MuiPaper-root': {
    '& .MuiMenu-list': {
      padding: '8px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: 0,
      },
      '&.Mui-selected': {
        backgroundColor: '#D8D8D8',
      },
      '&:active': {
        backgroundColor: 'RGBA(254, 254, 254, 0.87)',
      },
      padding: '6px 16px',
    },
    borderRadius: 0,
    color: 'rgb(55, 65, 81)',

    marginTop: 8,
    minWidth: 180,
  },
}));

const IconWrapper = styled(Box)`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #fff;
`;

const StyledKeyboardDownIcon = styled(KeyboardArrowDownIcon)`
  color: RGBA(0, 0, 0, 0.87);
`;

const StyledListItemIcon = styled(ListItemIcon)`
  justify-content: flex-end;
`;

function ApplicationsMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [getDesignContextSwitch, { data }] = useLazyQuery(QUERY_DESIGN_CONTEXT_SWITCH);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    getDesignContextSwitch();
  }, [getDesignContextSwitch]);

  const navigateToGeneDesign = () => {
    window.location.href = data?.designContextSwitch || '';
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <StyledIconButton data-testid="application-menu-header" variant="tertiary-gray" onClick={handleClick} size="md">
        <ApplicationsIcon />
      </StyledIconButton>
      <StyledMenu anchorEl={anchorEl} id="apps-menu" onClose={handleClose} open={open}>
        <MenuItem
          onClick={handleClose}
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <ListItemIcon>
            <ApplicationsIcon color="rgb(55, 65, 81)" />
          </ListItemIcon>
          <ListItemText>Applications</ListItemText>
          <StyledListItemIcon>
            <IconWrapper>
              <StyledKeyboardDownIcon
                style={{
                  fontSize: '24px',
                }}
              />
            </IconWrapper>
          </StyledListItemIcon>
        </MenuItem>
        <Divider />
        <MenuItem onClick={navigateToGeneDesign}>
          <ListItemIcon>
            <IconWrapper bgcolor="#050935">
              <BubbleChartRoundedIcon />
            </IconWrapper>
          </ListItemIcon>
          <ListItemText>Designer</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose} selected>
          <ListItemIcon>
            <IconWrapper bgcolor="#FF5843">
              <ApartmentOutlinedIcon />
            </IconWrapper>
          </ListItemIcon>
          <ListItemText>Console</ListItemText>
        </MenuItem>
      </StyledMenu>
    </>
  );
}

export default ApplicationsMenu;
