/* eslint-disable react/require-default-props */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import styled from 'styled-components';

import { KitType, NormalizationType } from '__generated__/globalTypes';
import { SequenceConcentration } from 'src/components/sequences/SequenceConcentration';
import { useTranslation } from 'src/containers/i18n';

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;

const StyledForm = styled(FormControl)`
  width: 100%;
`;

interface INormalizationTypeFormProps {
  concentrationValue?: number;
  disableConcentration?: boolean;
  disabled?: boolean;
  handleConcentrationChange: (val: number) => void;
  handleNormalizationChange: (ev: any) => void;
  normalizationType?: NormalizationType | string;
  kitType: KitType | undefined | null;
}

function NormalizationTypeForm({
  normalizationType,
  handleNormalizationChange,
  concentrationValue,
  handleConcentrationChange,
  disabled,
  disableConcentration,
  kitType,
}: INormalizationTypeFormProps) {
  const t = useTranslation();

  return (
    <StyledForm>
      <RadioGroup
        aria-label="normalization"
        name="normalizationType"
        value={normalizationType}
        onChange={handleNormalizationChange}
      >
        <StyledFormControlLabel
          value={NormalizationType.NONE}
          control={<Radio />}
          disabled={disabled}
          label={t('plates.detailsPage.details.modal.concentration.none')}
        />
        <StyledFormControlLabel
          value={NormalizationType.LOWEST}
          control={<Radio />}
          disabled={disabled}
          label={t('plates.detailsPage.details.modal.concentration.lowest')}
        />
        <Grid container direction="column">
          <StyledFormControlLabel
            value={NormalizationType.TARGET}
            disabled={disabled}
            control={<Radio />}
            label={t('plates.detailsPage.details.modal.concentration.target')}
          />

          <Box ml={1}>
            <SequenceConcentration
              kitType={kitType}
              disabled={disabled || disableConcentration}
              value={concentrationValue || 0}
              disableConcentration={disableConcentration}
              onChange={handleConcentrationChange}
            />
          </Box>
        </Grid>
      </RadioGroup>
    </StyledForm>
  );
}

export default NormalizationTypeForm;
