import { HelixLoader } from '@dna-script-inc/shared-ui-library';
import React from 'react';

import Center, { PageCenter } from './Center';

export const CenteredSpinner = () => {
  return (
    <Center>
      <HelixLoader />
    </Center>
  );
};

export const PageCenteredSpinner = () => {
  return (
    <PageCenter>
      <HelixLoader />
    </PageCenter>
  );
};
