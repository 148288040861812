import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { TOAST_DEFAULT_TIMEOUT } from 'src/config/constants';

import ToastComponent from './ToastComponent';
import ToastContext, { IToastContext, IToastItem, TToastPayload } from './ToastContext';

type TToastContainerProps = React.PropsWithChildren<Record<string, unknown>>;

const MAXIMUM_VISIBLE_TOASTS = 6;

const Root = styled.div`
  position: fixed;
  top: 90px;
  right: 12px;
  width: 360px;
  z-index: 99999;
`;

export function ToastProvider({ children }: TToastContainerProps) {
  const modalRoot = useRef<HTMLElement | null>(null);

  const [items, setItems] = useState<IToastItem[]>([]);
  const refContext = useRef<IToastContext | null>(null);

  useEffect(() => {
    modalRoot.current = document.createElement('div');
    document.body.appendChild(modalRoot.current);

    return () => {
      if (modalRoot.current) {
        document.body.removeChild(modalRoot.current);
      }
    };
  }, []);

  const hide = (id: string) => {
    setItems((currentItems) => {
      const filteredItems = currentItems.filter((item) => item?.id !== id);
      return filteredItems;
    });
  };

  const show = (payload: TToastPayload) => {
    const id = v4();

    setItems((state) => state.concat({ ...payload, id }));
    const shouldShow = payload.persist ?? payload.isError;

    if (!shouldShow) {
      setTimeout(() => {
        hide(id);
      }, payload.timeout || TOAST_DEFAULT_TIMEOUT);
    }
  };

  const renderItems = () => {
    items.length = Math.min(MAXIMUM_VISIBLE_TOASTS, items.length);
    return items.map((item) => <ToastComponent key={item.id} onClose={hide} {...item} />);
  };

  refContext.current = {
    toastShow: show,
  } as unknown as IToastContext;

  return (
    <ToastContext.Provider value={refContext as unknown as IToastContext}>
      {children}
      {modalRoot.current && createPortal(<Root>{renderItems()}</Root>, modalRoot.current)}
    </ToastContext.Provider>
  );
}

export default ToastContext.Consumer;
