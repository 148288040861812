import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export function getErrorForHelperText(
  message: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
): string | undefined {
  if (typeof message === 'string' || message === undefined) {
    return message;
  }
  return getErrorForHelperText(message.message);
}
