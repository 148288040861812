import { useQuery } from '@apollo/client';
import { Button } from '@dna-script-inc/shared-ui-library';
import GetAppIcon from '@mui/icons-material/GetApp';
import uniqueId from 'lodash-es/uniqueId';
import React, { useMemo } from 'react';

import { PageWrapper } from 'src/components/layout';
import { PageHeading } from 'src/components/layout/PageHeading';
import MRTable from 'src/components/table/MRTable';
import useGetFileNameColumn from 'src/components/table/columns/useGetFileNameColumn';
import useGetReleaseDateColumn from 'src/components/table/columns/useGetReleaseDateColumn';
import useGetReleaseVersionColumn from 'src/components/table/columns/useGetReleaseVersionColumn';
import { TableWrapper } from 'src/components/table/components/styled';
import { ERoutes } from 'src/config/routes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { QUERY_INSTALLATION_FILES } from 'src/services/gql/queries/installationFiles';
import { TInstallationFile } from 'src/types';
import { handlePrivateDownload } from 'src/utils/common';

type TExtendedInstallationFile = TInstallationFile & {
  id: string;
  downloadLink: string;
};

function useGetDownloadsTableColumns() {
  const fileNameColumn = useGetFileNameColumn<TExtendedInstallationFile>();
  const versionColumn = useGetReleaseVersionColumn<TExtendedInstallationFile>();
  const releaseDateColumn = useGetReleaseDateColumn<TExtendedInstallationFile>();

  return useMemo(
    () => [fileNameColumn, versionColumn, releaseDateColumn],
    [fileNameColumn, versionColumn, releaseDateColumn],
  );
}

const DownloadsTable = () => {
  const t = useTranslation();

  const { data, loading, refetch } = useQuery(QUERY_INSTALLATION_FILES, {
    fetchPolicy: 'network-only',
  });

  const files = useMemo(
    () => (data?.getInstallationFiles?.data?.filter((x) => x !== null) || []) as TInstallationFile[],
    [data],
  );

  const tableData = useMemo(() => {
    return (
      files.map((file) => {
        return {
          ...file,
          downloadLink: `/api/installation-files/download/${file.fileName ?? ''}`,
          id: uniqueId('file_'),
        };
      }) || []
    );
  }, [files]);

  const columns = useGetDownloadsTableColumns();

  return (
    <PageWrapper>
      <PageHeading
        breadcrumbs={[
          {
            link: ERoutes.DOWNLOADS,
            title: 'nav.downloads',
          },
        ]}
        renderTitle={() => <Translate id="nav.downloads" />}
      />
      <TableWrapper>
        <MRTable
          columns={columns}
          data={tableData}
          emptyDataId="downloads.noData"
          initialState={{ sorting: [{ desc: true, id: 'releaseDate' }] }}
          loading={loading}
          refetch={() => refetch()}
          renderRowActions={({ row }) => (
            <Button
              startIcon={<GetAppIcon />}
              variant="primary"
              uppercase
              onClick={() => handlePrivateDownload(row.original.downloadLink, row.original.fileName ?? undefined)}
            >
              <Translate id="downloads.listPage.button.download.title" />
            </Button>
          )}
          searchPlaceholder={t('downloads.listPage.table.search.placeholder')}
          tableId="downloads"
        />
      </TableWrapper>
    </PageWrapper>
  );
};

export default DownloadsTable;
