interface IDefaultUsers {
  canAddUser: boolean;
  canChangePasswordForUser: boolean;
  canDeactivateUser: boolean;
  canEditPermissions: boolean;
  canEditProfile: boolean;
  canEditUsers: boolean;
  canFilterUsers: boolean;
  canReactivateUser: boolean;
  canResentInvite: boolean;
  canSearchUsers: boolean;
  canSortUsers: boolean;
  canViewPermissions: boolean;
  canViewUsersDetails: boolean;
}

interface IDefaultTeams {
  canActivateTeam: boolean;
  canAddTeamMember: boolean;
  canCreateTeam: boolean;
  canDeleteTeamMember: boolean;
  canDisableTeam: boolean;
  canEditTeam: boolean;
  canViewTeamDetails: boolean;
  canViewTeamName: boolean;
}

interface IDefaultOrganization {
  canEditOrganization: boolean;
}

export interface IDefaultAccountsPermissions extends IDefaultUsers, IDefaultTeams, IDefaultOrganization {}

const DEFAULT_USERS_PERMISSIONS: IDefaultUsers = {
  canAddUser: false,
  canChangePasswordForUser: false,
  canDeactivateUser: false,
  canEditPermissions: false,
  canEditProfile: false,
  canEditUsers: false,
  canFilterUsers: false,
  canReactivateUser: false,
  canResentInvite: false,
  canSearchUsers: false,
  canSortUsers: false,
  canViewPermissions: false,
  canViewUsersDetails: false,
};

const DEFAULT_TEAMS_PERMISSIONS: IDefaultTeams = {
  canActivateTeam: false,
  canAddTeamMember: false,
  canCreateTeam: false,
  canDeleteTeamMember: false,
  canDisableTeam: false,
  canEditTeam: false,
  canViewTeamDetails: false,
  canViewTeamName: false,
};

const DEFAULT_ORGANIZATION_PERMISSIONS: IDefaultOrganization = {
  canEditOrganization: false,
};

export { DEFAULT_ORGANIZATION_PERMISSIONS, DEFAULT_TEAMS_PERMISSIONS, DEFAULT_USERS_PERMISSIONS };
