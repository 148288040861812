/* eslint-disable react/display-name */
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import isEmpty from 'lodash-es/isEmpty';
import React, { FC, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';
import { useRunContext } from 'src/pages/runs/container';
import { IModsAndClicksStructure } from 'src/services/gql/models/run';
import { TSequence } from 'src/stores/sequenceEditor/types';
import { MAX_ALLOWED_MODS, getMaxAllowedMods } from 'src/utils/mods';

import type { IInlineEditorApi, TSequenceAcceptedByInlineEditor } from './InlineEditor/InlineEditor';
import { START_EDITOR_POSITION } from './InlineEditor/constants';
import { clearSequenceFieldText, MOD_REGEX } from './sequenceFieldRules';
import {
  StyledBackdrop,
  StyledButton,
  StyledContainer,
  StyledHighLights,
  StyledLabel,
  StyledMessage,
  StyledParagraph,
  StyledPopper,
} from './styled';
import { getKeyFromSequence } from './utils';

const InlineEditorLazy = React.lazy(() =>
  import('./InlineEditor/InlineEditor').then((module) => ({
    default: module.InlineEditor,
  })),
);

export interface ISequenceFieldEditableProps {
  composeModsChunksFromString: (str: string) => string[];
  disabled?: boolean;
  isMultipleWells?: boolean;
  modsAndClicksRegex: RegExp;
  modsAndClicksStructure: IModsAndClicksStructure | undefined;
  multipleWellsLabel?: string;
  onChange: (value: string) => void;
  sequence: TSequence;
}

export const SequenceFieldEditable: FC<ISequenceFieldEditableProps> = ({
  onChange,
  sequence,
  disabled,
  isMultipleWells,
  multipleWellsLabel,
  modsAndClicksStructure,
  composeModsChunksFromString,
  modsAndClicksRegex,
}) => {
  const { dataConfigs, templateDetail } = useRunContext();

  const editableRef = useRef<HTMLDivElement | null>(null);
  const modsBtnRef = useRef<HTMLDivElement | null>(null);
  const t = useTranslation();

  const hasModsEnabled = useMemo(
    () => !isEmpty(modsAndClicksStructure?.modsAndClicks),
    [modsAndClicksStructure?.modsAndClicks],
  );

  const onChangeSlate = useCallback(
    (value: string) => {
      let strChunks = clearSequenceFieldText(value);
      let chunksArr;

      if (hasModsEnabled && strChunks.search(modsAndClicksRegex) !== -1) {
        chunksArr = composeModsChunksFromString(strChunks);
      } else {
        chunksArr = strChunks.trim().split('');
      }
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
      const modsArr = strChunks.match(MOD_REGEX) || [];
      const isModsExceeded = modsArr.length >= MAX_ALLOWED_MODS;

      if (hasModsEnabled && chunksArr.includes('/') && !isModsExceeded) {
        strChunks = chunksArr.join('');
      }

      onChange(strChunks);
    },
    [composeModsChunksFromString, hasModsEnabled, modsAndClicksRegex, onChange],
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);
  const open = Boolean(anchorEl);

  const close = useCallback(() => {
    setAnchorEl(undefined);
  }, [setAnchorEl]);

  const [inlineEditorApi, setInlineEditorApi] = useState<IInlineEditorApi | undefined>(undefined);

  const handleDocumentClick = useCallback(
    ({ target }: MouseEvent) => {
      if (!editableRef.current?.contains(target as Node) && !modsBtnRef.current?.contains(target as Node)) {
        inlineEditorApi?.endModInput();
      }
    },
    [inlineEditorApi],
  );

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  const handleMouseOver: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const mark = (e.target as HTMLElement).closest('mark');
      if (mark) {
        setAnchorEl(mark);
      } else {
        close();
      }
    },
    [setAnchorEl, close],
  );

  const handleAddModificationButtonClick = useCallback(() => {
    inlineEditorApi?.startModInput({
      at:
        sequence?.dataChunks?.[0]?.match(modsAndClicksRegex) || inlineEditorApi?.hasEditorClicked
          ? undefined
          : START_EDITOR_POSITION,
    });
  }, [inlineEditorApi, sequence?.dataChunks, modsAndClicksRegex]);

  return (
    <div>
      <StyledContainer>
        <StyledBackdrop>
          <StyledLabel>
            {t('runs.plateEditor.editWindow.sequenceField.title', {
              value: dataConfigs?.getPlateConfigs?.maxErrorMers || '',
            })}
          </StyledLabel>
          {!isMultipleWells && hasModsEnabled && (
            <div ref={modsBtnRef}>
              <StyledButton
                aria-haspopup="true"
                disabled={disabled}
                onClick={handleAddModificationButtonClick}
                startIcon={<AddIcon />}
                uppercase
                variant="secondary-gray"
              >
                <Translate id="runs.plateEditor.editWindow.sequenceField.mods.btn" />
              </StyledButton>
            </div>
          )}

          {isMultipleWells ? (
            <StyledParagraph> {multipleWellsLabel} </StyledParagraph>
          ) : (
            <StyledHighLights ref={editableRef} id="sequence" onMouseOver={handleMouseOver}>
              <Suspense fallback={null}>
                <InlineEditorLazy
                  key={getKeyFromSequence(sequence as TSequenceAcceptedByInlineEditor) || ''}
                  maxAllowedMods={getMaxAllowedMods(templateDetail?.plateType)}
                  modsAndClicksStructure={modsAndClicksStructure}
                  onChange={onChangeSlate}
                  onEditorReady={setInlineEditorApi}
                  readOnly={disabled || isMultipleWells}
                  sequence={sequence as TSequenceAcceptedByInlineEditor}
                />
              </Suspense>
            </StyledHighLights>
          )}
        </StyledBackdrop>
      </StyledContainer>

      <StyledPopper
        anchorEl={anchorEl}
        onMouseLeave={close}
        onResize={undefined}
        onResizeCapture={undefined}
        open={open}
      >
        <Paper>
          <StyledMessage p={1}>{anchorEl && anchorEl.dataset.title}</StyledMessage>
        </Paper>
      </StyledPopper>
    </div>
  );
};
