import { FormattedTime } from '@dna-script-inc/shared-ui-library';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { StyledRoundedCard } from 'src/components/common';
import { Translate } from 'src/containers/i18n';
import { ProjectDetailQuery } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import { ErrorText } from 'src/pages/auth/pages/components/styled';
import { getFormattedPrice } from 'src/utils/handleCurrency';
import { getColorFromPassingPercent } from 'src/utils/ui';

const StyledGrid = styled(Grid)`
  margin: 20px 0 20px 20px;
  font-weight: 400;
  position: relative;
`;

const HiddenGrid = styled(Grid)`
  display: none;
`;

const BigCardCell = styled.div`
  margin-right: 20px;
`;

const ThirdMetricLine = styled(Grid)`
  margin-top: 5px;
  font-size: 12px;
`;

const DetailsGridHeader = styled(Grid)`
  font-size: 12px;
  margin-bottom: 15px;
  color: #9ea0a5;
  text-transform: uppercase;
  font-weight: 500;
`;

const DetailsGridRow = styled(Grid)`
  margin-right: 10px;
`;

const DetailsGrid = styled(Grid)`
  font-size: 14px;
`;

const StyledErrorText = styled(ErrorText)`
  text-align: left;
`;

const CellHeading = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  white-space: nowrap;
  margin-right: 25px;
`;

const StyledColorCell = styled.h3<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: 500;
  margin-bottom: 0;
`;

const DivDivider = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 26px;
  right: 0;
  width: 1px;
  background: #eeeff8;
  overflow: hidden;
`;

interface IProjectCardProps {
  projectDetail: NonNullable<ProjectDetailQuery['getProjectDetailsById']>;
}

const ProjectCard = ({ projectDetail }: IProjectCardProps) => {
  const {
    currency,
    budget,
    expense,
    countOfDraftTemplates,
    members = [],
    countOfErrorTemplates,
    runsInQueue,
    completedRuns,
    percentOfPassedOligos,
    createdAt,
    updatedAt,
    owner,
    restrictedAccess,
  } = projectDetail;
  const { userProfile } = useUserContext();

  const formattedBudget = useMemo(() => {
    return getFormattedPrice(budget ?? 0, currency);
  }, [currency, budget]);
  const formattedExpense = useMemo(() => {
    return getFormattedPrice(expense ?? 0, currency);
  }, [currency, expense]);
  return (
    <StyledRoundedCard sx={{ marginY: '24px' }}>
      <Grid container direction="row" wrap="nowrap" spacing={0}>
        <StyledGrid xs={2} item>
          <CellHeading>
            <Translate id="projects.detail.card.title.drafts" />
          </CellHeading>
          <DetailsGrid
            container
            direction="row"
            sx={{
              marginTop: '16px',
            }}
          >
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <h3 id="countOfDraftTemplates">{countOfDraftTemplates ?? '-'}</h3>
                <ThirdMetricLine>
                  <Translate id="projects.detail.card.title.files" />
                </ThirdMetricLine>
              </Grid>
            </DetailsGridRow>
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <StyledErrorText>
                  <span id="countOfErrorTemplates">{countOfErrorTemplates ?? '-'}</span>
                </StyledErrorText>
                <ThirdMetricLine>
                  <Translate id="projects.detail.card.title.errors" />
                </ThirdMetricLine>
              </Grid>
            </DetailsGridRow>
          </DetailsGrid>
          <DivDivider />
        </StyledGrid>
        <StyledGrid xs={2} item>
          <CellHeading>
            <Translate id="projects.detail.card.runs" />
          </CellHeading>
          <DetailsGrid
            container
            direction="row"
            sx={{
              marginTop: '16px',
            }}
          >
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <h3 id="runsInQueue">{runsInQueue ?? 0}</h3>
                <ThirdMetricLine>
                  <Translate id="projects.detail.card.title.queued" />
                </ThirdMetricLine>
              </Grid>
            </DetailsGridRow>
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <h3 id="completedRuns">{completedRuns ?? 0}</h3>
                <ThirdMetricLine>
                  <Translate id="projects.detail.card.title.completed" />
                </ThirdMetricLine>
              </Grid>
            </DetailsGridRow>
          </DetailsGrid>
          <DivDivider />
        </StyledGrid>
        <StyledGrid xs={1} item>
          <CellHeading>
            <Translate id="projects.detail.card.title.oligos" />
          </CellHeading>
          <DetailsGrid
            container
            direction="row"
            sx={{
              marginTop: '16px',
            }}
          >
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <StyledColorCell
                  color={getColorFromPassingPercent(percentOfPassedOligos ?? 0)}
                  id="percentOfPassedOligos"
                >
                  {percentOfPassedOligos}
                </StyledColorCell>
                <ThirdMetricLine>
                  % <Translate id="projects.detail.card.passing" />
                </ThirdMetricLine>
              </Grid>
            </DetailsGridRow>
          </DetailsGrid>
          <DivDivider />
        </StyledGrid>
        <HiddenGrid item>
          <BigCardCell>
            <CellHeading>
              <Translate id="projects.detail.card.title.costCenter" />
            </CellHeading>
            <h3 id="budget">{formattedBudget}</h3>
            <ThirdMetricLine>/{formattedExpense}</ThirdMetricLine>
          </BigCardCell>
          <DivDivider />
        </HiddenGrid>
        <StyledGrid xs={7} item>
          <CellHeading>
            <Translate id="projects.detail.card.title.details" />
          </CellHeading>
          <DetailsGrid
            container
            direction="row"
            sx={{
              marginTop: '16px',
            }}
          >
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <DetailsGridHeader item xs>
                  <Translate id="projects.detail.card.title.projectOwner" />
                </DetailsGridHeader>
                <Grid id="projectOwner" item xs>
                  {owner?.firstName} {owner?.lastName}
                </Grid>
              </Grid>
            </DetailsGridRow>
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <DetailsGridHeader item xs>
                  <Translate id="projects.detail.card.title.teamMembers" />
                </DetailsGridHeader>
                <Grid id="totalMembers" item xs>
                  {restrictedAccess ? members?.length : <Translate id="projects.table.members.all" />}
                </Grid>
              </Grid>
            </DetailsGridRow>
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <DetailsGridHeader item xs>
                  <Translate id="projects.detail.card.title.dateCreated" />
                </DetailsGridHeader>
                <Grid id="createdAt" item xs>
                  <FormattedTime date={createdAt ?? null} timeZone={userProfile?.timeZone?.id} />
                </Grid>
              </Grid>
            </DetailsGridRow>
            <DetailsGridRow item xs>
              <Grid container direction="column">
                <DetailsGridHeader item xs>
                  <Translate id="projects.detail.card.title.lastModified" />
                </DetailsGridHeader>
                <Grid id="updatedAt" item xs>
                  <FormattedTime date={updatedAt ?? null} timeZone={userProfile?.timeZone?.id} />
                </Grid>
              </Grid>
            </DetailsGridRow>
          </DetailsGrid>
        </StyledGrid>
      </Grid>
    </StyledRoundedCard>
  );
};

export default ProjectCard;
