import { Tooltip } from '@dna-script-inc/shared-ui-library';
import React from 'react';

import { KitType } from '__generated__/globalTypes';
import { getKitTypeColorByValue, getKitTypeLabelByValue, isKitV1Instrument } from 'src/utils/runs';

import { StyledChip } from './common/StyledChip';

interface IKitTypeChipProps {
  type?: KitType | null;
  isWidthAuto?: boolean;
}

const MAX_KIT_NAME_LENGTH = 10;

export const KitTypeChip = ({ type, isWidthAuto = false }: IKitTypeChipProps) => {
  if (!type) {
    return null;
  }

  const chipColor = getKitTypeColorByValue(type);
  const chipLabel = getKitTypeLabelByValue(type);
  const isV1Instrument = isKitV1Instrument(type);

  if (isV1Instrument) {
    return (
      <StyledChip
        $backgroundColor={chipColor}
        label={chipLabel}
        size="small"
        style={{
          borderRadius: '4px',
          fontWeight: 'bold',
          height: '32px',
          minWidth: '45px',
        }}
      />
    );
  }

  return (
    <Tooltip title={chipLabel}>
      <StyledChip
        $backgroundColor={chipColor}
        color={type === KitType.B3_96 ? 'secondary' : undefined}
        label={chipLabel}
        size="small"
        style={{
          maxWidth: chipLabel.length > MAX_KIT_NAME_LENGTH && !isWidthAuto ? '100px' : 'auto',
        }}
      />
    </Tooltip>
  );
};
