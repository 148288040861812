import { ERoutes } from 'src/config/routes';
import { TTranslationKeys } from 'src/i18n';

export type TContent = {
  label: TTranslationKeys;
  to: string;
};

const getNavItems = (
  organization: Pick<IOrganizationAccount, 'geneEnabled' | 'customProcessEnabled'> | null | undefined,
): TContent[] => {
  const baseNavItems: TContent[] = [
    {
      label: 'nav.synthesisData.projects.design',
      to: ERoutes.APPLICATIONS__PROJECTS__DESIGN,
    },
    {
      label: 'nav.synthesisData.projects.team',
      to: ERoutes.APPLICATIONS__PROJECTS,
    },
    {
      label: 'nav.synthesisData.templates',
      to: ERoutes.APPLICATIONS__SEQUENCE_FILES,
    },
    {
      label: 'nav.synthesisData.runs',
      to: ERoutes.APPLICATIONS__RUNS,
    },
    {
      label: 'nav.synthesisData.plates',
      to: ERoutes.APPLICATIONS__PLATES,
    },
    {
      label: 'nav.synthesisData.customProcessDefinitionFiles',
      to: ERoutes.CUSTOM_PROCESSES,
    },
    {
      label: 'nav.instruments.fleet',
      to: ERoutes.INSTRUMENTS__OVERVIEW,
    },
  ];
  return baseNavItems.filter(
    (x) =>
      (x.to !== ERoutes.APPLICATIONS__PROJECTS__DESIGN || organization?.geneEnabled) &&
      (x.to !== ERoutes.CUSTOM_PROCESSES || organization?.customProcessEnabled),
  );
};

export default getNavItems;
