import Card from '@mui/material/Card';
import styled from 'styled-components';

import { WEBAPP_BORDER_RADIUS } from 'src/config/constants';

const StyledRoundedCard = styled(Card)`
  border-radius: ${WEBAPP_BORDER_RADIUS};
`;

export default StyledRoundedCard;
