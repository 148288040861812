import { ApolloProvider } from '@apollo/client';
import { theme } from '@dna-script-inc/shared-ui-library';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { BrowserRouter as Router, BrowserRouterProps } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import App from 'src/boot/App';
import GlobalStyles from 'src/config/theme/styles';
import { I18nProvider } from 'src/containers/i18n';
import { ToastProvider } from 'src/containers/toast';
import { UserProvider } from 'src/containers/user';
import apolloClient from 'src/services/gql';
import '../services/datadog/rum';

const RouterWithChildren = (props: React.PropsWithChildren<BrowserRouterProps>) => {
  const { children, ...browserProps } = props;
  return <Router {...browserProps}>{children}</Router>;
};

const Root: React.FC = () => (
  <ApolloProvider client={apolloClient}>
    <MaterialThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StyledThemeProvider theme={theme}>
          <I18nProvider>
            <UserProvider>
              <RouterWithChildren>
                <>
                  <CssBaseline />
                  <GlobalStyles theme={theme} />
                  <ToastProvider>
                    <App />
                  </ToastProvider>
                </>
              </RouterWithChildren>
            </UserProvider>
          </I18nProvider>
        </StyledThemeProvider>
      </LocalizationProvider>
    </MaterialThemeProvider>
  </ApolloProvider>
);

export default Root;
