import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';
import { TRunForUser, TWithoutTypename } from 'src/types';

function useGetCustomProcessDefinitionFileColumn<
  T extends {
    customProcess?: TWithoutTypename<TRunForUser['customProcess']> | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Cell: ({ row }) => row.original.customProcess?.name,
    Header: () => (
      <Translate id="projects.detail.table.printJobs.customProcessDefinitionFile" testid="process-def-file-header" />
    ),
    accessorFn: (row) => row.customProcess?.name ?? '',
    header: t('projects.detail.table.printJobs.customProcessDefinitionFile'),
    muiTableHeadCellFilterTextFieldProps: {
      placeholder: t('projects.detail.table.printJobs.customProcessDefinitionFile.filter'),
    },
    size: 120,
  };
}

export default useGetCustomProcessDefinitionFileColumn;
