import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { getLabel, RunStatusTag } from 'src/components/RunStatusTag';
import { Translate, useTranslation } from 'src/containers/i18n';
import { RunStatus } from 'src/gql/graphql';

function useGetRunStatusColumn<
  T extends {
    status?: string | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Cell: ({ row }) => <RunStatusTag status={row.original.status as RunStatus} />,
    Header: () => <Translate id="projects.detail.table.runs.status" testid="status-header" />,
    accessorFn: (run) => run.status,
    enableGlobalFilter: false,
    filterSelectOptions: Object.values(RunStatus).map((s) => ({ text: t(getLabel(s)), value: s })),
    filterVariant: 'multi-select',
    header: t('projects.detail.table.runs.status'),
    muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.status.filter') },
    size: 100,
  };
}

export default useGetRunStatusColumn;
