import { Button } from '@dna-script-inc/shared-ui-library';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import styled from 'styled-components';

import { NormalizationType } from '__generated__/globalTypes';
import { useToggle } from 'src/hooks/useToggle';

import EditTemplateModal from './EditTemplateModal';

const StyledTitle = styled.div`
  word-break: break-all;
  white-space: initial;
`;

interface IFileEditableTitleProps {
  disabled?: boolean;
  normalization?: NormalizationType;
  value: string;
}

export const FileEditableTitle = ({
  value,
  children,
  disabled,
  normalization,
}: React.PropsWithChildren<IFileEditableTitleProps>) => {
  const { isOpen, open: makeEditable, close } = useToggle(false);

  return (
    <Grid container item spacing={1}>
      <Grid item>
        <StyledTitle>{value}</StyledTitle>
      </Grid>
      {children}
      <Grid item>
        <Box>
          <Button variant="tertiary-gray" disabled={disabled} onClick={makeEditable} size="lg">
            <SettingsIcon />
          </Button>
        </Box>
      </Grid>

      {isOpen && <EditTemplateModal value={value} normalization={normalization} onClose={close} />}
    </Grid>
  );
};
