import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Nucleo, SequenceMessageGroup } from '__generated__/globalTypes';
import { Translate } from 'src/containers/i18n';

const StyledErrorIcon = styled(ErrorIcon)`
  color: ${(props) => props.theme.colors.primary.error[700]};
`;

const ErrorsContainer = styled(Grid)`
  box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.primary.error[700]};
  border-radius: 4px;
  color: #6a201c;
  padding: 10px;
  margin-bottom: 20px;
`;

interface IErrorsModalSectionProps {
  data?: string | string[];
  errors?: Pick<ISequenceMessage, 'message'>[];
  nucErrors?: INucErrors[];
  nucleos?: string[];
}

interface INucleoError {
  chunk: string;
  group: SequenceMessageGroup;
  length: number;
  message: string;
  nucleo: Nucleo;
  position: number;
}

function getPositions<T extends { position: number }>(errors: T[]) {
  return errors
    .map((x) => x.position)
    .sort((a, b) => a - b)
    .join(', ');
}

const NUCLEO_MODS_WITH_ERRORS = [Nucleo.UNKNOWN, Nucleo.MOD_UNKNOWN, Nucleo.CLICK];

const ErrorsModalSection = ({ errors, nucErrors, nucleos = [], data = [] }: IErrorsModalSectionProps) => {
  const location = useLocation();
  const isIDNAPlatePage = location.pathname.includes('plates');

  const [idnaNucErrors, setIdnaNucErrors] = React.useState<string[]>([]);
  const nucNewErrors = useMemo(() => {
    // workaround cause backend doesn't want to support proper structure
    const nucErrorsCloned = nucErrors ? [...nucErrors] : [];
    const baseForNucNewErrors: INucleoError[] = [];
    const idnaErrors: string[] = [];
    nucleos.forEach((nucleo, i) => {
      if (NUCLEO_MODS_WITH_ERRORS.includes(nucleo as Nucleo)) {
        const nucError = nucErrorsCloned.find((x) => x.index === i);
        if (nucError) {
          baseForNucNewErrors.push({
            chunk: data[nucError.index],
            group: nucError.group as SequenceMessageGroup,
            length: nucError.length,
            message: nucError.message,
            nucleo: nucleo as Nucleo,
            position: nucError.index + 1,
          });
        }
      } else {
        const nucError = nucErrors?.find((x) => x.index === i);
        if (nucError) {
          idnaErrors.push(nucError.message);
        }
        setIdnaNucErrors(idnaErrors);
      }
    });
    return baseForNucNewErrors;
  }, [data, nucErrors, nucleos]);

  const modBaseErrors = useMemo(
    () => nucNewErrors.filter((x) => x.group === SequenceMessageGroup.MOD_UNKNOWN),
    [nucNewErrors],
  );

  const baseErrors = useMemo(
    () =>
      nucNewErrors.filter(
        (x) => x.group === SequenceMessageGroup.UNKNOWN || x.group === SequenceMessageGroup.NUC_UNKNOWN,
      ),
    [nucNewErrors],
  );

  const positionErrors = useMemo(
    () => nucNewErrors.filter((x) => x.group === SequenceMessageGroup.CLICK_POSITION),
    [nucNewErrors],
  );

  if (!errors && !nucNewErrors?.length) {
    return null;
  }

  return (
    <ErrorsContainer container spacing={1}>
      <Grid item xs={2}>
        <StyledErrorIcon />
      </Grid>
      <Grid item xs={10}>
        {errors && errors.map(({ message }) => <div key={message}>{message}</div>)}
        {isIDNAPlatePage &&
          idnaNucErrors.length > 0 &&
          idnaNucErrors.map((message) => <div key={message}>{message}</div>)}

        {positionErrors.length > 0 && (
          <>
            <Translate id="runs.errors.position.label" /> {getPositions(positionErrors)}
          </>
        )}
        {nucNewErrors.some((x) => x.nucleo === Nucleo.UNKNOWN || x.nucleo === Nucleo.MOD_UNKNOWN) && (
          <div>
            {modBaseErrors.length > 0 && (
              <>
                <Translate id="runs.errors.invalidModeBase.label" /> {getPositions(modBaseErrors)}
              </>
            )}
            {modBaseErrors.length > 0 && baseErrors.length > 0 && <br />}
            {baseErrors.length > 0 && (
              <>
                <Translate id="runs.errors.invalidBase.label" /> {getPositions(baseErrors)}
              </>
            )}
          </div>
        )}
      </Grid>
    </ErrorsContainer>
  );
};

export default ErrorsModalSection;
