import { getContrastRatio } from '@mui/material/styles';

export function getColorFromPassingPercent(passingPercent: number) {
  if (passingPercent < 60) {
    return '#D93025';
  }
  if (passingPercent < 70) {
    return '#E37400';
  }
  return '#1E8E3E';
}

export function stopPropagationClick(e: React.MouseEvent) {
  e.stopPropagation();
}

const LIGHT = '#FFFFFF';
const DARK = '#000000';

export function getContrastTextColor(backgroundColor: string) {
  const contrastForLightColor = getContrastRatio(LIGHT, backgroundColor);
  const contrastForDarkColor = getContrastRatio(DARK, backgroundColor);
  if (contrastForLightColor > contrastForDarkColor) {
    return LIGHT;
  }
  return DARK;
}

export function isNotEmpty<TValue>(value: TValue | null | undefined | void): value is TValue {
  return value !== null && value !== undefined;
}

export const getPercentDisplayWith2FractionDigits = (total: number | undefined, number: number) => {
  return total ? `${((number / total) * 100).toFixed(2)}%` : '';
};
