import { Page } from '@dna-script-inc/shared-ui-library';
import React from 'react';

import Header from './Header/Header';

interface ILayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<ILayoutProps> = ({ children }) => {
  return <Page headerComponent={<Header />}>{children}</Page>;
};

export default MainLayout;
