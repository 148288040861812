import { useMutation, useQuery } from '@apollo/client';
import { SaveButton, CancelButton } from '@dna-script-inc/shared-ui-library';
import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { StyledRoundedCard } from 'src/components/common';
import { Row, TextInput } from 'src/components/form';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useToast } from 'src/containers/toast';
import useUserContext from 'src/hooks/useUserContext';
import { MUTATION_UPDATE_PQA_RUN_INFO } from 'src/services/gql/mutations/runs';
import { QUERY_REDBOX_VERSION } from 'src/services/gql/queries/service';
import { getErrorMessage } from 'src/utils/errors';

import { PqaRunStatistic } from './PqaRunStatistic';

import { TRunDetails } from '../types';

const StyledFormCard = styled(StyledRoundedCard)`
  margin-top: 37px;
  padding: 24px;
`;

const TwoColumnsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 130px;
`;

interface IPqaRunInfoProps {
  runDetails: TRunDetails;
}

interface IPqaRunInfoFormValues {
  comment: string;
  conditionName: string;
  plateTitle: string;
}

export const PqaRunInfo = ({ runDetails }: IPqaRunInfoProps) => {
  const showToast = useToast();
  const t = useTranslation();

  const { data: redboxVersion } = useQuery(QUERY_REDBOX_VERSION);

  const [updatePqaInfo] = useMutation(MUTATION_UPDATE_PQA_RUN_INFO, {
    refetchQueries: [`QueryRunDetails`],
  });

  const { userProfile } = useUserContext();

  const formValues = useMemo(() => {
    return {
      comment: runDetails.pqaInfo?.comment ?? '',
      conditionName: runDetails.pqaInfo?.conditionName ?? '',
      plateTitle: runDetails.pqaInfo?.plateTitle ?? '',
    };
  }, [runDetails]);

  const { register, handleSubmit, reset } = useForm<IPqaRunInfoFormValues>({
    defaultValues: formValues,
  });

  const onSubmit = async (values: IPqaRunInfoFormValues) => {
    return updatePqaInfo({
      variables: {
        comment: values.comment,
        conditionName: values.conditionName,
        plateTitle: values.plateTitle,
        runId: runDetails.id || '',
      },
    })
      .then(() => {
        showToast({
          textToTranslate: 'runs.details.pqa.update.success.description',
          title: 'runs.details.pqa.update.success.titles',
        });
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        showToast({
          isError: true,
          text: message,
          title: 'runs.details.pqa.update.error.title',
        });
      });
  };

  return (
    <div>
      <form>
        <StyledFormCard>
          <TwoColumnsLayout>
            <div>
              <Row>
                <TextInput
                  inputProps={{
                    readOnly: true,
                  }}
                  label={t('runs.details.pqa.info.location')}
                  value={userProfile?.organizationName}
                />
              </Row>
              <Row>
                <TextInput
                  inputProps={{
                    readOnly: true,
                  }}
                  label={t('runs.details.pqa.info.syntaxNumber')}
                  value={runDetails.instrument?.serialNumber}
                />
              </Row>
              <Row>
                <TextInput
                  inputProps={{
                    readOnly: true,
                  }}
                  label={t('runs.details.pqa.info.consoleVersion')}
                  value={
                    redboxVersion?.getRedboxVersion || '  '
                  } /* added spaces as version is too short and is covered with label */
                />
              </Row>
            </div>
            <div>
              <Row>
                <TextInput
                  inputProps={{
                    maxLength: 100,
                  }}
                  label={t('runs.details.pqa.info.conditionName')}
                  placeholder={t('runs.details.pqa.info.conditionName.placeholder')}
                  {...register('conditionName')}
                />
              </Row>
              <Row>
                <TextInput
                  inputProps={{
                    maxLength: 100,
                  }}
                  label={t('runs.details.pqa.info.plateId')}
                  placeholder={t('runs.details.pqa.info.plateId.placeholder')}
                  {...register('plateTitle')}
                />
              </Row>
              <Row>
                <TextInput
                  inputProps={{
                    maxLength: 1000,
                  }}
                  label={t('runs.details.pqa.info.comment')}
                  placeholder={t('runs.details.pqa.info.comment.placeholder')}
                  {...register('comment')}
                />
              </Row>
            </div>
          </TwoColumnsLayout>
          <Grid container justifyContent="space-between">
            <CancelButton onClick={() => reset(formValues)}>
              <Translate id="projects.create.button.cancel" />
            </CancelButton>
            <SaveButton type="submit" onClick={handleSubmit(onSubmit)}>
              <Translate id="runs.details.save" />
            </SaveButton>
          </Grid>
        </StyledFormCard>
      </form>
      <PqaRunStatistic runId={runDetails.id || ''} />
    </div>
  );
};
