import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { ReagentUsageUnion } from 'src/gql/graphql';
import { QUERY_INSTRUMENT_STATUS } from 'src/services/gql/queries/instruments';
import { TInstrumentStatusReagent } from 'src/types';
import { isNotEmpty } from 'src/utils/ui';

export const useInstrumentStatus = (id: string) => {
  const { data, loading, error } = useQuery(QUERY_INSTRUMENT_STATUS, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });

  const reagents = useMemo(() => {
    const dataReagents = (data?.getInstrumentStatus?.reagents ?? []).filter(isNotEmpty);
    return dataReagents.reduce((accumulated, item) => {
      return {
        ...accumulated,
        [item.reagentUsageUnion]: item,
      };
    }, {} as Record<ReagentUsageUnion, TInstrumentStatusReagent>);
  }, [data]);

  return {
    cycles: data?.getInstrumentStatus?.cycles,
    error,
    loading: loading || !data,
    reagents,
  };
};
