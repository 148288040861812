/* eslint-disable react/jsx-no-constructed-context-values */
import { DateTime } from '@dna-script-inc/shared-ui-library';
import * as colors from '@mui/material/colors';
import React from 'react';

import { ISimpleInstrument } from 'src/@types/instruments';

import ProjectSchedulerContext, { IProjectSchedulerContext, IScheduleItem, TView } from './ProjectSchedulerContext';

const COLORS: string[] = [
  colors.blue[500],
  colors.red[500],
  colors.yellow[500],
  colors.green[500],
  colors.purple[400],
  colors.cyan[600],
  colors.deepOrange[400],
  colors.lime[800],
  colors.indigo[400],
  colors.pink[300],
  colors.teal[700],
  colors.pink[700],
];

export const getColor = (instrumentId: string, instruments: ISimpleInstrument[]): string => {
  const index = instruments.findIndex((instrument) => instrument.id === instrumentId);

  return COLORS[index % COLORS.length];
};

interface IProjectSchedulerContainerProps {
  instruments: ISimpleInstrument[];
}

interface IProjectSchedulerContainerState {
  date: DateTime;
  instruments: ISimpleInstrument[];
  schedule: IScheduleItem[];
  selected: ISimpleInstrument[];
  view: TView;
}

export class ProjectSchedulerProvider extends React.Component<
  React.PropsWithChildren<IProjectSchedulerContainerProps>,
  IProjectSchedulerContainerState
> {
  readonly state: IProjectSchedulerContainerState = {
    date: DateTime.now(),
    instruments: this.props.instruments,
    schedule: [],
    selected: this.props.instruments,
    view: 'week',
  };

  render() {
    const context: IProjectSchedulerContext = {
      ...this.state,
      getColor: this.getColor,
      isSelected: this.isSelected,
      onNext: this.next,
      onPrev: this.prev,
      setSchedule: this.setSchedule,
      setView: this.setView,
      toggleInstrument: this.toggleInstrument,
    };

    return <ProjectSchedulerContext.Provider value={context}>{this.props.children}</ProjectSchedulerContext.Provider>;
  }

  private toggleInstrument = () => {
    this.setState((prevState) => {
      const selected = [...prevState.selected];

      return {
        selected,
      };
    });
  };

  private setView = (view: TView) => {
    this.setState({ view });
  };

  private next = () => {
    this.setState((prevState) => ({
      date: prevState.date.plus({ [prevState.view === 'month' ? 'month' : 'week']: 1 }),
    }));
  };

  private prev = () => {
    this.setState((prevState) => ({
      date: prevState.date.minus({ [prevState.view === 'month' ? 'month' : 'week']: 1 }),
    }));
  };

  private getColor = (instrumentId: string): string => {
    return getColor(instrumentId, this.state.instruments);
  };

  private isSelected = (instrument: ISimpleInstrument): boolean => {
    return this.state.selected.includes(instrument);
  };

  private setSchedule = (schedule: IScheduleItem[]) => {
    this.setState({
      schedule,
    });
  };
}

export default ProjectSchedulerContext.Consumer;
