import en from 'src/i18n/en';

type TTranslation = typeof en;
export type TTranslationKeys = keyof TTranslation;
type TTranslations = Record<string, Readonly<TTranslation>>;

export interface II18nData {
  [key: string]: string | number;
}

const translations: TTranslations = {
  en,
};

export default translations;
