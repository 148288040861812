import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import useGetCreatedAtColumn from 'src/components/table/columns/useGetCreatedAtColumn';
import useGetCreatorColumn from 'src/components/table/columns/useGetCreatorColumn';
import useGetDesignIdColumn from 'src/components/table/columns/useGetDesignIdColumn';
import useGetKitTypeColumn from 'src/components/table/columns/useGetKitTypeColumn';
import useGetNameColumn from 'src/components/table/columns/useGetNameColumn';
import useGetTemplateStatusColumn from 'src/components/table/columns/useGetTemplateStatusColumn';
import useGetUpdatedAtColumn from 'src/components/table/columns/useGetUpdatedAtColumn';
import { Translate, useTranslation } from 'src/containers/i18n';
import { TTemplatePerProject } from 'src/types';

function useGetSequenceColumnsInProject(creators: string[]) {
  const nameColumn = useGetNameColumn<TTemplatePerProject>({
    filterTitle: 'projects.detail.table.printJobs.name.filter',
    headerTitle: 'projects.detail.table.printJobs.name',
  });
  const kitTypeColumn = useGetKitTypeColumn<TTemplatePerProject>();
  const parentIdnaColumn = useGetDesignIdColumn<TTemplatePerProject>();
  const ownerColumn = useGetCreatorColumn<TTemplatePerProject>(creators);
  const statusColumn = useGetTemplateStatusColumn<TTemplatePerProject>();
  const updatedAtColumn = useGetUpdatedAtColumn<TTemplatePerProject>();
  const createdAtColumn = useGetCreatedAtColumn<TTemplatePerProject>();

  const t = useTranslation();
  const numberOfRunsColumn: MRT_ColumnDef<TTemplatePerProject> = {
    Header: () => <Translate id="projects.detail.table.printJobs.numberOfRuns" />,
    accessorKey: 'countOfRuns',
    header: t('projects.detail.table.printJobs.numberOfRuns'),
  };
  const oligosColumn: MRT_ColumnDef<TTemplatePerProject> = {
    Header: () => <Translate id="projects.detail.table.printJobs.oligos" />,
    accessorKey: 'countOfSequences',
    header: t('projects.detail.table.printJobs.oligos'),
  };
  const plateIdColumn: MRT_ColumnDef<TTemplatePerProject> = {
    Header: () => <Translate id="projects.detail.table.printJobs.plateId" />,
    accessorKey: 'id',
    header: t('projects.detail.table.printJobs.plateId'),
  };

  const designColumns: MRT_ColumnDef<TTemplatePerProject>[] = [
    nameColumn,
    statusColumn,
    kitTypeColumn,
    parentIdnaColumn,
    ownerColumn,
    updatedAtColumn,
  ];
  const geneColumnsInProject: MRT_ColumnDef<TTemplatePerProject>[] = [
    nameColumn,
    ownerColumn,
    statusColumn,
    kitTypeColumn,
    numberOfRunsColumn,
    oligosColumn,
    plateIdColumn,
    createdAtColumn,
  ];

  return { designColumns, geneColumnsInProject };
}

export default useGetSequenceColumnsInProject;
