import { DateTime } from '@dna-script-inc/shared-ui-library';
import React from 'react';

import { ISimpleInstrument } from 'src/@types/instruments';

export type TView = 'month' | 'week';

export interface IScheduleItem {
  end: string;
  instrumentId: string;
  start: string;
}

export interface IProjectSchedulerContext {
  date: DateTime;
  getColor: (instrumentId: string) => string;
  instruments: ISimpleInstrument[];
  isSelected: (instrument: ISimpleInstrument) => boolean;
  onNext: () => void;
  onPrev: () => void;
  selected: ISimpleInstrument[];
  setSchedule: (schedule: IScheduleItem[]) => void;
  setView: (view: TView) => void;
  toggleInstrument: () => void;
  view: TView;
}

const ProjectSchedulerContext: React.Context<IProjectSchedulerContext> = React.createContext<IProjectSchedulerContext>(
  {} as IProjectSchedulerContext,
);

export default ProjectSchedulerContext;
