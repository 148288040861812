import * as React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'src/containers/i18n';
import { II18nData, TTranslationKeys } from 'src/i18n';

interface ITranslateProps {
  data?: II18nData;
  id: TTranslationKeys | undefined;
  testid?: string | '';
}

const TranslateSpan = styled.span`
  display: block;
`;

const Translate: React.FC<ITranslateProps> = ({ id, data, testid }) => {
  const translate = useTranslation();
  const value = translate(id, data);

  return <TranslateSpan data-testid={testid}>{value}</TranslateSpan>;
};

export default Translate;
