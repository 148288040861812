import { useMutation } from '@apollo/client';
import {
  PlateGridV3,
  ErrorModal,
  addPositionToSequence,
  usePlateGridCommonProps,
} from '@dna-script-inc/shared-ui-library';
import Info from '@mui/icons-material/Info';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import InfoPopper from 'src/components/InfoPopper';
import HeaderPanel from 'src/components/sequences/headerPanel';
import { TSequenceInGrid } from 'src/components/sequences/hooks/types';
import { PLATE_SIZE_384, PLATE_SIZE_96 } from 'src/config/constants';
import { ERoutes } from 'src/config/routes';
import { Translate } from 'src/containers/i18n';
import useTranslation from 'src/containers/i18n/useTranslation';
import useToast from 'src/containers/toast/useToast';
import { PlateType, TemplateStatus } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import { useToggle } from 'src/hooks/useToggle';
import { useRunContext } from 'src/pages/runs/container';
import { MUTATION_UPDATE_USER_PREFERENCES } from 'src/services/gql/mutations/user';
import { useCurrentTemplate } from 'src/stores/sequenceEditor/hooks';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';
import { getErrorMessage } from 'src/utils/errors';
import { scrollToRef } from 'src/utils/scroll';

import { SequenceFileDetailsCell } from './SequenceFileDetailsCell';
import SequenceFileSheetDetails from './SequenceFileSheetDetails';
import useGetDefaultPropertiesOfEmptyWell from './useGetDefaultPropertiesOfEmptyWell';
import useZoomPlate from './useZoomPlate';

const StyledInfoPopperContent = styled.div`
  max-width: 340px;
`;

const PlateGridContainer = styled(Box)`
  width: 100%;
  overflow: scroll;
`;

const SequenceFilePlate = () => {
  const myRef = useRef<HTMLDivElement>(null);
  const plateRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const history = useHistory();
  const { userProfile, userUpdateProfile } = useUserContext();
  const showToast = useToast();
  const { isUsingPlateView } = useRunContext();
  const t = useTranslation();
  const { search: searchText, setSearch, clickOnWell, selectWells } = useSequenceEditorStore();
  const templateDetail = useCurrentTemplate();
  const { isOpen: isInfoPopperOpen, open: openInfoPopper, close: closeInfoPopper } = useToggle(false);
  const theme = useTheme();
  const isPlate384 = templateDetail?.plateType === PlateType.PLATE_384;
  const plateSize = isPlate384 ? PLATE_SIZE_384 : PLATE_SIZE_96;
  const { handleWheelEvent, scale, ZoomComponent } = useZoomPlate(plateSize);

  const [updateUserPreferences] = useMutation(MUTATION_UPDATE_USER_PREFERENCES, {
    onError(e) {
      const error = getErrorMessage(e);
      showToast({
        isError: true,
        text: error,
        title: 'common.alert.errors.title',
      });
    },
  });

  const handleCloseInfoPopper = useCallback(() => {
    if (userProfile) {
      updateUserPreferences({
        variables: {
          tooltipOnSeqPageIsClosed: true,
        },
      }).then(() => {
        userUpdateProfile({
          tooltipOnSeqPageIsClosed: true,
        });
      });
    }
    closeInfoPopper();
  }, [userProfile, closeInfoPopper, updateUserPreferences, userUpdateProfile]);

  useEffect(() => {
    scrollToRef(scrollRef.current, 50);
  }, []);

  useEffect(() => {
    if (!userProfile?.tooltipOnSeqPageIsClosed) {
      openInfoPopper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = useCallback(
    (sequence: TSequenceInGrid | undefined): boolean => {
      if (!searchText.length) {
        return true;
      }
      const dataChunksString = sequence?.dataChunks?.join('')?.toLowerCase();
      const sequenceName = sequence?.name?.toLowerCase();
      const searchTextFormatted = searchText.toLowerCase();
      return (
        Boolean(dataChunksString?.includes?.(searchTextFormatted)) ||
        Boolean(sequenceName?.includes(searchTextFormatted))
      );
    },
    [searchText],
  );

  const onClose = useCallback(() => history.push(ERoutes.APPLICATIONS__SEQUENCE_FILES), [history]);

  const prefixes = useMemo(() => templateDetail?.prefixes ?? [], [templateDetail]);
  const addPrefixesToSequences = useCallback(
    (sequences: TSequenceInGrid[]): TSequenceInGrid[] => {
      return sequences.map((sequence) => {
        const wellIndex = sequence?.wellIndex;
        const prefix = (prefixes ?? []).find((v) => v && v.wellIndex === wellIndex)?.nucChunks;
        return { ...sequence, prefixes: prefix };
      });
    },
    [prefixes],
  );

  const sequences: TSequenceInGrid[] = useMemo(() => {
    return addPrefixesToSequences(
      [...(templateDetail?.sequences ?? []), ...(templateDetail?.emptySequences ?? [])].map(addPositionToSequence),
    );
  }, [addPrefixesToSequences, templateDetail?.sequences, templateDetail?.emptySequences]);
  const selectWellsCallback = (selectedSequences: TSequenceInGrid[], isSelectingMultipleWells?: boolean) => {
    if (selectedSequences.length === 1) {
      const sequence = selectedSequences[0];
      const wellName = sequence.well;
      clickOnWell(wellName, sequence.wellIndex, Boolean(isSelectingMultipleWells));
    } else {
      selectWells(selectedSequences.map((s) => s.well));
    }
  };

  const getDefaultPropertiesOfEmptyWell = useGetDefaultPropertiesOfEmptyWell();

  const {
    columns,
    handleSelect,
    rows,
    sequences: sequenceFileWells,
    resetState,
  } = usePlateGridCommonProps(plateSize, sequences, selectWellsCallback, {
    getDefaultPropertiesOfEmptyWell,
    useMultiSelect: true,
  });

  useEffect(() => {
    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCell = useCallback(
    (sequence: TSequenceInGrid | undefined) => {
      return (
        <SequenceFileDetailsCell
          ref={myRef}
          isPlate384={isPlate384}
          kitType={templateDetail?.kitType}
          search={search}
          sequence={sequence}
        />
      );
    },
    [isPlate384, search, templateDetail?.kitType],
  );

  if (templateDetail?.status === TemplateStatus.DELETE) {
    return (
      <ErrorModal
        title={t('sequences.table.errors.incomplete.title')}
        isOpen
        onReject={onClose}
        rejectButtonTitle={t('common.button.close')}
      >
        <Translate id="runs.plateEditor.deleted" />
      </ErrorModal>
    );
  }

  return (
    <>
      {templateDetail?.status === TemplateStatus.ERROR && (
        <Box
          sx={{
            color: theme.colors.primary.error[700],
            fontSize: '14px',
            fontWeight: 500,
          }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
        >
          <Translate id="plates.detailsPage.error" />
          <Info />
        </Box>
      )}
      <HeaderPanel
        ZoomComponent={ZoomComponent}
        setSearch={setSearch}
        kitType={templateDetail?.kitType}
        search={searchText}
      />

      <InfoPopper
        anchorEl={myRef.current}
        handleClose={handleCloseInfoPopper}
        id="transitions-popper"
        isOpen={isInfoPopperOpen}
      >
        <StyledInfoPopperContent>
          <Typography fontSize="bold">
            <Translate id="runs.plateEditor.popper.info.title" />
          </Typography>
          <Typography sx={{ marginTop: '4px' }}>
            <Translate id="runs.plateEditor.popper.info.description" />
          </Typography>
        </StyledInfoPopperContent>
      </InfoPopper>

      <div ref={scrollRef} />

      {isUsingPlateView ? (
        <PlateGridContainer>
          <PlateGridV3
            columns={columns}
            handleSelect={handleSelect}
            ref={plateRef}
            renderCell={renderCell}
            rows={rows}
            scale={scale}
            sequences={sequenceFileWells}
            shouldBuildIn={false}
            onWheelEvent={plateSize === PLATE_SIZE_384 ? handleWheelEvent : noop}
            size={plateSize}
          />
        </PlateGridContainer>
      ) : (
        templateDetail?.kitType &&
        templateDetail?.plateType && (
          <SequenceFileSheetDetails
            sequences={sequenceFileWells}
            kitType={templateDetail?.kitType}
            plateType={templateDetail?.plateType}
          />
        )
      )}
    </>
  );
};

export default SequenceFilePlate;
