/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';

import { KitType, ProjectType } from '__generated__/globalTypes';
import { GET_SEQUENCE_CONCENTRATION_LIMITS } from 'src/services/gql/queries/templates';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';

import ApplicationContext from './ApplicationContext';
import { DEFAULT_CONCENTRATION_KEY, DEFAULT_CONCENTRATION_LIMITS } from './constants';

export const ApplicationProvider = ({ children }: React.PropsWithChildren<Record<string, unknown>>) => {
  const { setSequenceConcentrationDefault, sequenceConcentrationDefault } = useSequenceEditorStore();

  const [getSequenceConcentrationLimits] = useLazyQuery(GET_SEQUENCE_CONCENTRATION_LIMITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const concentrationLimits = Object.fromEntries(
        data.getAllSequenceConcentrationLimitsByProjectType.map((sequenceConcentration) => {
          const { min, max, defaultValue, kitType } = sequenceConcentration;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const getValueIfNumberElse0 = (v: any) => (typeof defaultValue === 'number' ? (v as number) : 0);
          return [
            kitType ?? 'default',
            {
              defaultValue: getValueIfNumberElse0(defaultValue),
              max: getValueIfNumberElse0(max),
              min: getValueIfNumberElse0(min),
            },
          ];
        }),
      );
      setSequenceConcentrationDefault({
        [DEFAULT_CONCENTRATION_KEY]: DEFAULT_CONCENTRATION_LIMITS,
        ...concentrationLimits,
      });
    },
  });

  const getConcentrationLimitsByKitType = useCallback(
    (kitType: KitType | undefined | null | typeof DEFAULT_CONCENTRATION_KEY) => {
      return (
        sequenceConcentrationDefault[kitType || DEFAULT_CONCENTRATION_KEY] ||
        sequenceConcentrationDefault[DEFAULT_CONCENTRATION_KEY]
      );
    },
    [sequenceConcentrationDefault],
  );

  React.useEffect(() => {
    getSequenceConcentrationLimits({
      variables: {
        projectType: ProjectType.SYNTAX,
      },
    });
  }, [getSequenceConcentrationLimits]);

  const handleGetSequenceConcentrationLimits = React.useCallback(
    (projectType: ProjectType) => {
      getSequenceConcentrationLimits({
        variables: {
          projectType,
        },
      });
    },
    [getSequenceConcentrationLimits],
  );

  const applicationContext = useMemo(
    () => ({ getConcentrationLimitsByKitType, handleGetSequenceConcentrationLimits }),
    [getConcentrationLimitsByKitType, handleGetSequenceConcentrationLimits],
  );

  return <ApplicationContext.Provider value={applicationContext}>{children}</ApplicationContext.Provider>;
};

export default ApplicationContext.Consumer;
