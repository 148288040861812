import { graphql } from 'src/gql';

export const MUTATION_USER_CHANGE_PASSWORD = graphql(`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
    }
  }
`);

export const MUTATION_USER_RESET_PASSWORD = graphql(`
  mutation ChangePasswordForUser($newPassword: String!, $userId: ID!) {
    changePasswordForUser(newPassword: $newPassword, userId: $userId) {
      message
    }
  }
`);

export const MUTATION_USER_UPDATE = graphql(`
  mutation UserUpdate(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $workPhone: String
    $cellPhone: String
    $title: String
    $address: AddressInputDTO!
    $roleId: ID
    $groupIds: [ID!]
    $timeZoneId: String
  ) {
    updateUser(
      user: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        workPhone: $workPhone
        cellPhone: $cellPhone
        title: $title
        address: $address
        roleId: $roleId
        groupIds: $groupIds
        timeZoneId: $timeZoneId
      }
    ) {
      id
      username
      firstName
      lastName
      title
      status
      cellPhone
      workPhone
      address {
        country
        state
        city
        street1
        street2
        zip
      }
      role {
        name
        description
        type
      }
      groups {
        id
        name
        description
        status
        creatorFirstName
        creatorLastName
        updatedAt: lastUpdatedDate
        totalGroupMembers
      }
      countOfProjects
      updatedAt
      createdAt
      timeZone {
        id
        label
        offset
      }
    }
  }
`);

export const MUTATION_USER_PROFILE_UPDATE = graphql(`
  mutation UserProfileUpdate(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $workPhone: String
    $cellPhone: String
    $title: String
    $address: AddressInputDTO!
    $timeZoneId: String
  ) {
    updateUserProfile(
      user: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        workPhone: $workPhone
        cellPhone: $cellPhone
        title: $title
        address: $address
        timeZoneId: $timeZoneId
      }
    ) {
      id
      username
      firstName
      lastName
      title
      status
      cellPhone
      workPhone
      timeZone {
        id
        label
        offset
      }
      address {
        country
        state
        city
        street1
        street2
        zip
      }
      role {
        name
        description
        type
      }
      groups {
        id
        name
        description
        status
        creatorFirstName
        creatorLastName
        updatedAt: lastUpdatedDate
        totalGroupMembers
      }
      countOfProjects
      updatedAt
      createdAt
      timeZone {
        id
        label
        offset
      }
    }
  }
`);

export const MUTATION_DELETE_USER = graphql(`
  mutation DeleteUserById($id: ID!) {
    deleteUser(id: $id) {
      message
    }
  }
`);

export const MUTATION_USER_INVITE = graphql(`
  mutation UserInvite(
    $firstName: String!
    $lastName: String!
    $username: String!
    $groupIds: [ID!]!
    $roleId: ID!
    $title: String
  ) {
    inviteUser(
      user: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        groupIds: $groupIds
        roleId: $roleId
        title: $title
      }
    ) {
      message
    }
  }
`);

export const MUTATION_USER_CREATE = graphql(`
  mutation UserCreate(
    $firstName: String!
    $lastName: String!
    $password: String!
    $username: String!
    $groupIds: [ID!]!
    $roleId: ID!
    $title: String
  ) {
    addUser(
      user: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        password: $password
        groupIds: $groupIds
        roleId: $roleId
        title: $title
      }
    ) {
      message
    }
  }
`);

export const MUTATION_USER_INVITE_RESEND = graphql(`
  mutation UserInviteResend($email: String!) {
    resendInvitationEmail(email: $email) {
      message
    }
  }
`);

export const MUTATION_USER_REACTIVATE = graphql(`
  mutation UserReactivate($id: ID!) {
    reactivateUser(id: $id) {
      status
      message
    }
  }
`);

export const MUTATION_UPDATE_USER_PREFERENCES = graphql(`
  mutation UpdateUserPreferences($tooltipOnSeqPageIsClosed: Boolean!) {
    updateUserPreferences(inputData: { tooltipOnSeqPageIsClosed: $tooltipOnSeqPageIsClosed }) {
      status
      message
    }
  }
`);

export const MUTATION_INVITE_REMOTE_USER = graphql(`
  mutation InviteRemoteUser($email: String!, $roleId: ID!, $expirationDate: String) {
    inviteRemoteUser(inputData: { email: $email, roleId: $roleId, expirationDate: $expirationDate }) {
      status
      message
    }
  }
`);

export const MUTATION_UPDATE_REMOTE_USER = graphql(`
  mutation UpdateRemoteUser($id: ID!, $roleId: ID!, $expirationDate: String) {
    updateRemoteUser(inputData: { id: $id, roleId: $roleId, expirationDate: $expirationDate }) {
      status
      message
    }
  }
`);

export const MUTATION_DEACTIVATE_REMOTE_USER = graphql(`
  mutation DeactivateRemoteUser($organizationRemoteUserId: ID!) {
    deactivateRemoteUser(organizationRemoteUserId: $organizationRemoteUserId) {
      status
      message
    }
  }
`);
