import { useQuery } from '@apollo/client';
import { ErrorModal } from '@dna-script-inc/shared-ui-library';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MRTable from 'src/components/table/MRTable';
import { getUniqueCreatorsFromList } from 'src/components/table/columns/useGetCreatorColumn';
import { isRunCompleted, RunActions } from 'src/components/table/runsTable/constants';
import { ERoutes } from 'src/config/routes';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { useToggle } from 'src/hooks/useToggle';
import { useProjectManageContext } from 'src/pages/projects/containers/projectManage';
import { QUERY_PROJECT_RUNS } from 'src/services/gql/queries/projects';
import { TRunInProject } from 'src/types';
import { getPermissionForProject } from 'src/utils/permissions/project';
import { isNotEmpty } from 'src/utils/ui';

import useGetProjectRunsColumns from './useGetProjectRunsColumns';

interface ITableProps {
  refetchProject: () => Promise<unknown>;
  emptyData?: JSX.Element;
}

const ProjectRunsTable = ({ refetchProject, emptyData }: ITableProps) => {
  const history = useHistory();
  const t = useTranslation();
  const { project } = useProjectManageContext();
  const { isOpen, close, open: handleInvalidReport } = useToggle(false);

  const {
    userProfile,
    userPermissions: {
      runReports: { canViewRunReportData },
    },
  } = useUserContext();
  const { id } = useParams<{ id: string }>();
  const { data, loading, refetch } = useQuery(QUERY_PROJECT_RUNS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      projectId: id,
    },
  });

  const runs = useMemo(() => (data?.getRunsPerProject?.data ?? []).filter(isNotEmpty), [data]);
  const creators = getUniqueCreatorsFromList(runs);
  const columns = useGetProjectRunsColumns(creators);

  const permissions = project && getPermissionForProject(userProfile, project);

  const canAccessRunDetails = canViewRunReportData || permissions?.projects?.details?.canViewRunReportDetails;

  const handleRowClick = (run: TRunInProject) => {
    if (!isRunCompleted(run?.status)) {
      handleInvalidReport();
      return;
    }
    if (!canAccessRunDetails || !run?.id) {
      return;
    }
    const newPath = ERoutes.APPLICATIONS__PROJECTS__DETAILS__id__REPORT__reportId.replace(
      ':projectId',
      String(id),
    ).replace(':id', String(run?.id));
    history.push(newPath);
  };

  if (runs.length === 0 && emptyData && !loading) {
    return emptyData;
  }

  const refetchRuns = () =>
    Promise.all([
      refetch({
        projectId: id,
      }),
      refetchProject(),
    ]);

  return (
    <>
      <MRTable
        columns={columns}
        data={runs}
        emptyDataId="runs.noData"
        initialState={{ sorting: [{ desc: true, id: 'updatedAt' }] }}
        loading={loading}
        muiTableBodyRowProps={({ row: { original: run } }) => ({
          onClick: () => {
            handleRowClick(run);
          },
          sx: {
            cursor: 'pointer',
          },
        })}
        refetch={refetchRuns}
        renderRowActions={({ row }) => <RunActions refetch={refetchRuns} run={row.original as IProjectRun} />}
        searchPlaceholder={t('projects.detail.table.search.placeholder')}
        tableId="projectRuns"
      />
      <ErrorModal
        title={t('runs.errors.incomplete.title')}
        isOpen={isOpen}
        onReject={close}
        rejectButtonTitle={t('common.button.close')}
      >
        <Translate id="runs.errors.incomplete.body" />
      </ErrorModal>
    </>
  );
};

export default ProjectRunsTable;
