import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import { useTranslation } from 'src/containers/i18n';
import { useToast } from 'src/containers/toast';
import { MUTATION_DELETE_TEMPLATE } from 'src/services/gql/mutations/templates';
import { QUERY_PROJECT_TEMPLATES } from 'src/services/gql/queries/projects';
import { QUERY_ALL_TEMPLATES, QUERY_ARCHIVED_TEMPLATES, QUERY_MY_TEMPLATES } from 'src/services/gql/queries/templates';
import { getErrorMessage } from 'src/utils/errors';
import { isNotEmpty } from 'src/utils/ui';

import { useGetSequenceQueryFilters } from './useGetSequenceQueryFilters';

type TTemplateToDelete = { fileName: string; id: string | undefined; localId?: string; projectId: string };

function useDeleteTemplate(
  template: TTemplateToDelete,
  options?: { onCompletedCallback: (t: TTemplateToDelete) => void },
) {
  const showToast = useToast();
  const t = useTranslation();

  const filter = useGetSequenceQueryFilters();
  const refetchQueries = useMemo(() => {
    const queries = [
      'ProjectDetail',
      template.projectId
        ? {
            query: QUERY_PROJECT_TEMPLATES,
            variables: { projectId: template.projectId },
          }
        : undefined,
      {
        query: QUERY_MY_TEMPLATES,
        variables: { filter },
      },
      {
        query: QUERY_ALL_TEMPLATES,
        variables: { filter },
      },
      {
        query: QUERY_ARCHIVED_TEMPLATES,
        variables: { filter },
      },
    ];
    return queries.filter(isNotEmpty);
  }, [template.projectId, filter]);
  const [deleteTemplateById, { loading }] = useMutation(MUTATION_DELETE_TEMPLATE, {
    awaitRefetchQueries: true,
    onCompleted() {
      options?.onCompletedCallback?.(template);
      showToast({
        text: t('runs.create.deleteFiles.success', { fileName: template.fileName || '' }),
        title: 'common.toast.success.title',
      });
    },
    onError(e) {
      showToast({
        isError: true,
        text: getErrorMessage(e),
        title: 'error.title.template.delete',
        titleData: { templateName: template.fileName || '' },
      });
    },
    refetchQueries,
    variables: {
      id: template.id ?? '',
    },
  });

  return { deleteTemplate: deleteTemplateById, loading };
}

export default useDeleteTemplate;
