import ApiConfig from 'src/config/api';

export const composeUrl = (path: string, params: any = null) => {
  let url = path;
  if (!url.startsWith('https://')) {
    url = ApiConfig.API_ROOT + path;
  }

  const searchParams = new URLSearchParams(params);
  if (params !== null) {
    url += `?${searchParams.toString()}`;
  }

  return url;
};
