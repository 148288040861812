import { useLazyQuery } from '@apollo/client';
import React from 'react';

import { QUERY_PROJECT_TEMPLATE_SIMPLIFIED } from 'src/services/gql/queries/projects';
import { isNotEmpty } from 'src/utils/ui';

export function useTemplates(projectId: string) {
  const [getProjectTemplates, { data, loading }] = useLazyQuery(QUERY_PROJECT_TEMPLATE_SIMPLIFIED, {
    variables: {
      projectId,
    },
  });

  React.useEffect(() => {
    getProjectTemplates();
  }, [getProjectTemplates]);

  return {
    loading,
    templates: data?.getTemplatesPerProject?.data?.filter(isNotEmpty) || [],
  };
}
