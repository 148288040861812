import { useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Direction, KitType } from '__generated__/globalTypes';
import { PlatesForDropdownQuery, PlatesForDropdownQueryVariables } from 'src/gql/graphql';
import { QUERY_PLATES_FOR_DROPDOWN } from 'src/services/gql/queries/templates';

export const getMyPlatesQueryOptions = (kitType?: KitType) => {
  return {
    fetchPolicy: 'network-only',
    variables: {
      orderDirection: Direction.DESC,
      orderField: 'name',
      ...(kitType && { kitType }),
    },
  } as QueryHookOptions<PlatesForDropdownQuery, PlatesForDropdownQueryVariables> | undefined;
};

export function useMyPlatesLazyQuery(kitType?: KitType) {
  return useLazyQuery(QUERY_PLATES_FOR_DROPDOWN, getMyPlatesQueryOptions(kitType));
}
