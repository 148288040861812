import { Button } from '@dna-script-inc/shared-ui-library';
import Close from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import React from 'react';
import styled from 'styled-components';

import { Dropdown } from 'src/components/common/Dropdown';
import { FEATURE_TOGGLES } from 'src/config/constants';
import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';
import { getPrintJobPdfDetails, handlePrivateDownload } from 'src/utils/common';

import CongratulationBlock from './confirmation/CongratulationBlock';
import PrintDetails from './confirmation/PrintDetails';
import ReagentUsage from './confirmation/ReagentUsage';

const Wrapper = styled(Paper)`
  width: 880px;
  padding: 32px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * + * {
    margin-left: 20px;
  }
`;

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    margin-top: 5px;
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  padding: 8px 50px 8px 30px;
`;

const Content = styled.div`
  display: flex;
  margin-top: 20px;
`;

const RightSide = styled.div`
  margin-left: 20px;
`;

interface IConfirmationModalProps {
  info?: TTranslationKeys;
  onClose: () => void;
  printDetail: ICreateProjectRun | null;
  title?: TTranslationKeys;
}

const ConfirmationModal = ({ printDetail, onClose, title, info }: IConfirmationModalProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!printDetail) {
    return null;
  }

  return (
    <Dialog open disableEscapeKeyDown maxWidth="md" transitionDuration={500} data-testid="pj-ready-to-print-dialog">
      <Wrapper>
        <Buttons>
          <Dropdown id="print-job-ready-to-print" buttonTitle="template.congratulations.dropdownTitle">
            <MenuItem onClick={() => getPrintJobPdfDetails(printDetail.id)}>
              <Translate id="template.congratulations.item.list.download" />
            </MenuItem>
            <MenuItem disabled={!printDetail?.metalink} onClick={() => handlePrivateDownload(printDetail.metalink)}>
              <Translate id="template.congratulations.button.download" />
            </MenuItem>
          </Dropdown>
          <Button variant="tertiary-gray" onClick={onClose} size="lg" data-testid="close-dialog">
            <Close />
          </Button>
          <StyledPopover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          >
            <List>
              <StyledListItemButton>
                <Translate id="runs.option.download" />
              </StyledListItemButton>
              <StyledListItemButton>
                <Translate id="runs.option.reschedule" />
              </StyledListItemButton>
              <StyledListItemButton>
                <Translate id="runs.option.duplicate" />
              </StyledListItemButton>
              <StyledListItemButton>
                <Translate id="runs.option.cancel" />
              </StyledListItemButton>
            </List>
          </StyledPopover>
        </Buttons>
        <CongratulationBlock title={title} info={info} />
        <Content>
          <PrintDetails printDetail={printDetail} />
          {FEATURE_TOGGLES.REAGENTS_USE_ESTIMATE && (
            <RightSide>
              <ReagentUsage />
            </RightSide>
          )}
        </Content>
      </Wrapper>
    </Dialog>
  );
};

export default ConfirmationModal;
