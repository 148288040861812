import Box from '@mui/material/Box';
import React from 'react';

import { RightSidebarContainer } from 'src/components/RightSidebarContainer';
import { SequenceRightAside } from 'src/components/sequences/SequenceRightAside/SequenceRightAside';
import { Translate } from 'src/containers/i18n';
// eslint-disable-next-line max-len
import { useSelectedSequenceState } from 'src/stores/sequenceEditor/hooks';
import {
  isSelectingAtLeastOneWellSelector,
  isSelectingMoreThanOneWellSelector,
} from 'src/stores/sequenceEditor/selectors';
import useSequenceEditorStore from 'src/stores/sequenceEditor/sequenceEditorStore';

import { TemplateDetailsEditor } from '../onSelect/modal';

const SequenceFileDetailsRightAsideContent = () => {
  const selectedSequence = useSelectedSequenceState();
  const isMultipleWells = useSequenceEditorStore(isSelectingMoreThanOneWellSelector);

  if (!selectedSequence) {
    return null;
  }

  return (
    <SequenceRightAside isMultipleWells={isMultipleWells} wellName={selectedSequence.well}>
      <TemplateDetailsEditor />
    </SequenceRightAside>
  );
};

export const TemplateDetailsRightAside = () => {
  const isSelectingAtLeastOneWell = useSequenceEditorStore(isSelectingAtLeastOneWellSelector);

  return (
    <RightSidebarContainer>
      {isSelectingAtLeastOneWell ? (
        <SequenceFileDetailsRightAsideContent />
      ) : (
        <Box mt={4} pl={2} pr={2}>
          <Translate id="runs.plateEditor.popper.info.description" />
        </Box>
      )}
    </RightSidebarContainer>
  );
};
