import { Box } from '@mui/material';
import React from 'react';

export function StepContainer({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Box
      sx={{
        marginTop: '20px',
        padding: '16px',
      }}
    >
      {children}
    </Box>
  );
}

export function StepHeader({
  sequenceFileInfos,
  buttons,
}: {
  sequenceFileInfos: React.ReactNode;
  buttons: React.ReactNode;
}) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      {sequenceFileInfos}
      {buttons}
    </Box>
  );
}
