import { useMemo } from 'react';

import useUserContext from './useUserContext';

export function useCanDeleteSequenceFile(creator: Pick<IShortUserInfo, 'id'> | undefined | null) {
  const {
    userPermissions: { sequenceFiles },
    userProfile,
  } = useUserContext();

  const isCreator = useMemo(
    () => userProfile !== null && userProfile !== undefined && creator?.id === userProfile?.id,
    [userProfile, creator],
  );

  return sequenceFiles.canDelete || isCreator;
}
