import Tab from '@mui/material/Tab';
import * as React from 'react';

import { StyledTabs } from 'src/components/common';
import { PageWrapper } from 'src/components/layout';
import { useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';
import { useTabs } from 'src/hooks/useTabs';

import ProjectsHeader from './components/ProjectsHeader';
import ProjectsListTable from './components/Table/ProjectsListTable';

export enum ETab {
  ALL = 'all',
  ARCHIVED = 'archived',
  MY = 'my',
}

const PROJECTS_TABLE = 'PROJECTS_TABLE';

interface IProjectsListPageProps {
  isDesignProjects?: boolean;
}

const ProjectsListPage: React.FC<IProjectsListPageProps> = ({ isDesignProjects }) => {
  const t = useTranslation();
  const {
    userPermissions: {
      project: { canViewAllProjects },
    },
  } = useUserContext();

  const { tab, handleChangeTab } = useTabs(ETab.MY, PROJECTS_TABLE);

  return (
    <PageWrapper>
      <ProjectsHeader isDesignProjects={isDesignProjects} />
      <StyledTabs indicatorColor="primary" onChange={handleChangeTab} textColor="primary" value={tab}>
        <Tab label={`${t('projects.tabs.my')}`} value="my" />
        {canViewAllProjects && <Tab label={`${t('projects.tabs.all')}`} value="all" />}
        {canViewAllProjects && <Tab label={`${t('projects.tabs.archived')}`} value="archived" />}
      </StyledTabs>
      {tab === ETab.MY && <ProjectsListTable isDesignProjects={isDesignProjects} status={ETab.MY} />}
      {tab === ETab.ALL && canViewAllProjects && (
        <ProjectsListTable isDesignProjects={isDesignProjects} status={ETab.ALL} />
      )}
      {tab === ETab.ARCHIVED && canViewAllProjects && (
        <ProjectsListTable isDesignProjects={isDesignProjects} status={ETab.ARCHIVED} />
      )}
    </PageWrapper>
  );
};

export default ProjectsListPage;
