/* eslint-disable no-underscore-dangle */
export const isProd = () => import.meta.env.PROD;

const hasInternetAccessEnv = window.injectedEnv?.HAS_INTERNET_ACCESS === 'true';

export const hasInternetAccess = () => hasInternetAccessEnv;

export const hasAccessToDatadog = () => {
  return hasInternetAccess() && isProd() && Boolean(window.injectedEnv?.__DD_APP_ID__);
};
