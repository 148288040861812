import * as React from 'react';

import { II18nData, TTranslationKeys } from 'src/i18n';

export interface II18nContext {
  locale: string;
  t: (key: TTranslationKeys | undefined, data?: II18nData) => string;
  translate: (key: TTranslationKeys | undefined, data?: II18nData) => string;
}

const I18nContext: React.Context<II18nContext> = React.createContext<II18nContext>({} as II18nContext);

export default I18nContext;
