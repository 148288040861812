import { graphql } from 'src/gql';

export const MUTATION_CREATE_RUN_SCHEDULE = graphql(`
  mutation CreateRunScheduler(
    $templateId: ID!
    $instrumentId: ID!
    $description: String
    $customProcessId: ID
    $centrifugationType: CentrifugationType
  ) {
    createRunScheduler(
      inputData: {
        centrifugationType: $centrifugationType
        templateId: $templateId
        instrumentId: $instrumentId
        description: $description
        customProcessId: $customProcessId
      }
    ) {
      id
      instrument {
        id
        name
      }
      createdTime
      scheduledStartTime
      scheduledEndTime
      startTime
      endTime
      status
      description
      metalink
      centrifugationType
      user {
        id
        firstName
        lastName
      }
      longestSequence
      shortestSequence
      modifiedCount
      clicksCount
      sequenceCount
      durationInSeconds
      estimatedReagents {
        code
        displayName
        amount
        bottles {
          label
          volumeMl
        }
      }
      idnaTemplate {
        id
        name
      }
    }
  }
`);

export const MUTATION_PRINT_GENE = graphql(`
  mutation CreateGeneRunScheduler($projectId: ID!, $instrumentId: ID!) {
    createGeneRunScheduler(inputData: { projectId: $projectId, instrumentId: $instrumentId }) {
      id
      instrument {
        id
        name
        serialNumber
        version
      }
      sheetId
      scheduledStartTime
      scheduledEndTime
      startTime
      createdTime
      endTime
      status
      description
      metalink
      longestSequence
      shortestSequence
      sequenceCount
      user {
        id
        firstName
        lastName
        role
      }
      durationInSeconds
    }
  }
`);

export const MUTATION_UPLOAD_RUN_REPORT = graphql(`
  mutation UploadRunReport($fileName: String, $content: String!, $serialNumber: String!) {
    uploadRunReport(inputData: { fileName: $fileName, content: $content, serialNumber: $serialNumber }) {
      id
      projectId
      projectName
      instrumentId
      scheduledStartTime
      scheduledEndTime
      startTime
      endTime
      metaLink
      reportMetaLink
      description
      status
    }
  }
`);

export const MUTATION_DELETE_RUN = graphql(`
  mutation DeleteRun($id: ID!) {
    deleteRun(runId: $id) {
      status
      message
    }
  }
`);

export const MUTATION_REPRINT_GENE_WELLS = graphql(`
  mutation ReprintFailedWells($projectId: ID!, $instrumentId: ID!, $version: ID) {
    reprintFailedWells(inputData: { projectId: $projectId, instrumentId: $instrumentId, version: $version })
  }
`);

export const MUTATION_UPDATE_RUN_CONCENTRATION = graphql(`
  mutation UpdateConcentration($runId: ID!, $projectId: ID!, $wellInfo: [WellInfoDTO!]!) {
    updateConcentration(inputData: { runId: $runId, projectId: $projectId, wellInfo: $wellInfo })
  }
`);

export const MUTATION_UPDATE_PQA_RUN_INFO = graphql(`
  mutation UpdatePqaRunInfo($runId: ID!, $conditionName: String, $plateTitle: String, $comment: String) {
    updatePqaRunInfo(
      inputData: { runId: $runId, conditionName: $conditionName, plateTitle: $plateTitle, comment: $comment }
    ) {
      status
      message
    }
  }
`);
