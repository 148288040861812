/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { NormalizationType, Nucleo, SequenceStatus } from '__generated__/globalTypes';
import { CLEARED_SEQUENCE_FIELDS } from 'src/config/constants';
import { DEFAULT_CONCENTRATION_KEY, DEFAULT_CONCENTRATION_LIMITS } from 'src/containers/application/constants';

import { resetSequenceData } from './events';
import {
  pushNewSequenceStateToWell,
  convertTemplateDetailsSequences,
  getDefaultSequence,
  pushNewSequenceStateToWellFromPreviousState,
} from './helpers';
import { ISequenceEditorState } from './types';

const useSequenceEditorStore = create<ISequenceEditorState>()(
  devtools(
    immer((set) => ({
      changeTemplateSettings: (params) =>
        set((state) => {
          if (!state.templateDetails) {
            return;
          }
          const { templateName, normalizationType, targetConcentration, usePlateNorm } = params;
          const isTargetNormalization = normalizationType === NormalizationType.TARGET;
          const shouldApplyTargetConcentration =
            usePlateNorm && isTargetNormalization && typeof targetConcentration === 'number';
          state.templateDetails = {
            ...state.templateDetails,
            name: templateName,
            normalizationType,
            targetConcentration: targetConcentration ?? null,
          };

          const applyTemplateChangesToSequences = () => {
            const defaultConcentration =
              state.sequenceConcentrationDefault[state.templateDetails?.kitType || DEFAULT_CONCENTRATION_KEY]
                .defaultValue;
            Object.entries(state.sequencesHistory).forEach(([well, sequenceHistory]) => {
              const { states: sequenceStates } = sequenceHistory;
              if (!sequenceStates.length) {
                return;
              }
              const lastState = sequenceStates.at(-1);
              if (!lastState || !usePlateNorm) {
                return;
              }
              const targetConcentrationToApply = shouldApplyTargetConcentration
                ? targetConcentration
                : lastState.targetConcentration ?? defaultConcentration;
              pushNewSequenceStateToWell(state, well, {
                ...lastState,
                normalizationType,
                status: SequenceStatus.ACTIVE,
                targetConcentration: targetConcentrationToApply,
                usePlateNorm: true,
              });
            });
          };
          applyTemplateChangesToSequences();
        }),
      clearWellFields: (well) =>
        set((state) => {
          const { states: sequenceStates } = state.sequencesHistory[well];
          const firstWellState = sequenceStates.at(0);
          if (!firstWellState) return;
          pushNewSequenceStateToWell(state, well, {
            ...firstWellState,
            ...CLEARED_SEQUENCE_FIELDS,
            normalizationType: NormalizationType.NONE,
            targetConcentration: null,
          });
          resetSequenceData();
        }),
      clickOnWell: (well, wellIndex, isUsingMultiSelect) =>
        set((state) => {
          const { selectedWells, sequencesHistory, templateDetails } = state;
          if (!templateDetails) return;
          const isWellAlreadySelected = selectedWells.includes(well);
          const getNewSelectedWells = () => {
            if (isWellAlreadySelected) {
              if (isUsingMultiSelect) {
                return selectedWells.filter((w) => w === well);
              }
              return [];
            }
            if (isUsingMultiSelect) {
              return selectedWells.concat([well]);
            }
            return [well];
          };

          const newSelectedWells = getNewSelectedWells();
          state.selectedWells = newSelectedWells;

          const isWellInSequenceHistory = Object.keys(sequencesHistory).includes(well);
          if (!isWellInSequenceHistory) {
            state.sequencesHistory[well] = {
              currentIndex: 0,
              states: [
                getDefaultSequence(
                  well,
                  wellIndex,
                  templateDetails,
                  state.sequenceConcentrationDefault[templateDetails?.kitType || DEFAULT_CONCENTRATION_KEY]
                    ?.defaultValue,
                ),
              ],
            };
          }
        }),
      initializeTemplateDetails: (templateDetails) =>
        set((state) => {
          if (!templateDetails) return;
          const templateSequencesWithWell = convertTemplateDetailsSequences(templateDetails.sequences);
          const newSequencesHistory = Object.fromEntries(
            templateSequencesWithWell?.map((sequence) => [sequence.well, { currentIndex: 0, states: [sequence] }]),
          );
          const convertedTemplateDetails = {
            ...templateDetails,
            emptySequences: convertTemplateDetailsSequences(templateDetails.emptySequences),
            sequences: templateSequencesWithWell,
          };
          state.sequencesHistory = newSequencesHistory;
          state.templateDetails = convertedTemplateDetails;
          state.templateDetailsLastSave = convertedTemplateDetails;
          state.selectedWells = [];
        }),
      loading: false as boolean,
      resetSelectedWells: () =>
        set((state) => {
          state.selectedWells.forEach((well) => {
            const { states: sequenceStates } = state.sequencesHistory[well];
            const firstWellState = sequenceStates.at(0);
            if (!firstWellState) return;
            state.sequencesHistory[well].currentIndex = 0;
            state.sequencesHistory[well].states = [{ ...firstWellState }];
            resetSequenceData();
          });
        }),
      search: '',
      selectWells: (wells) => set({ selectedWells: wells }),
      selectedWells: [] as string[],
      sequenceConcentrationDefault: {
        [DEFAULT_CONCENTRATION_KEY]: DEFAULT_CONCENTRATION_LIMITS,
      },
      sequencesHistory: {},
      setLoading: (loading) => set({ loading }),
      setSearch: (search) => set({ search }),
      setSequenceConcentrationDefault: (sequenceConcentrationDefault) => set({ sequenceConcentrationDefault }),
      templateDetails: null,
      templateDetailsLastSave: null,
      undoLastActionOnSelectedWells: () =>
        set((state) => {
          state.selectedWells.forEach((well) => {
            const { states: sequenceStates, currentIndex } = state.sequencesHistory[well];
            const newIndex = Math.max(0, currentIndex - 1);
            if (sequenceStates.length < 2) return;
            state.sequencesHistory[well].currentIndex = newIndex;
            const lastState = state.sequencesHistory[well].states.pop();
            const newCurrentState = sequenceStates[newIndex];
            if (newCurrentState.data !== lastState?.data) {
              resetSequenceData();
            }
          });
        }),
      updateConcentration: (concentration) =>
        set((state) => {
          state.selectedWells.forEach((selectedWell) => {
            const { states: sequenceStates } = state.sequencesHistory[selectedWell];
            if (!sequenceStates.length) return;
            const lastState = sequenceStates.at(-1);
            if (!lastState) {
              return;
            }
            pushNewSequenceStateToWell(state, selectedWell, {
              ...lastState,
              status: SequenceStatus.ACTIVE,
              targetConcentration: concentration,
              usePlateNorm:
                typeof lastState.targetConcentration === 'number' &&
                concentration === state.templateDetails?.targetConcentration &&
                lastState.usePlateNorm,
            });
          });
        }),
      updateNormalization: (normalization) =>
        set((state) => {
          state.selectedWells.forEach((selectedWell) => {
            pushNewSequenceStateToWellFromPreviousState(state, selectedWell, {
              normalizationType: normalization,
              status: SequenceStatus.ACTIVE,
              usePlateNorm: false,
            });
          });
        }),
      updateTemplateDetails: (template) => set({ templateDetails: template }),
      updateWellConcentration: (well, concentration) =>
        set((state) => {
          pushNewSequenceStateToWellFromPreviousState(state, well, {
            status: SequenceStatus.ACTIVE,
            targetConcentration: concentration,
          });
        }),
      updateWellName: (well, name) =>
        set((state) => {
          pushNewSequenceStateToWellFromPreviousState(state, well, {
            name,
            status: SequenceStatus.ACTIVE,
          });
        }),
      updateWellSequence: (well, payload) =>
        set((state) => {
          const { sequence, chunks } = payload;
          pushNewSequenceStateToWellFromPreviousState(state, well, {
            data: sequence,
            dataChunks: chunks,
            nucChunks: chunks as Nucleo[],
            status: SequenceStatus.ACTIVE,
          });
        }),
    })),
    { name: 'sequence-editor' },
  ),
);

export default useSequenceEditorStore;
