import Switch, { SwitchProps } from '@mui/material/Switch';
import React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 14px;
  }
`;

interface IToggleProps {
  label: TTranslationKeys;
}

const Toggle: React.FC<IToggleProps & SwitchProps> = ({ label, ...props }) => (
  <Wrapper>
    <span>
      <Translate id={label} />
    </span>
    <Switch color="primary" {...props} />
  </Wrapper>
);

export default Toggle;
