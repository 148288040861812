import Box from '@mui/material/Box';
import styled from 'styled-components';

export const PageContainer = styled(Box)`
  flex: 1;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const PageWrapper = styled.div`
  flex: 1;
  display: flex;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  .MuiTableSortLabel-icon {
    opacity: 0.3;
  }
`;

export default PageWrapper;
