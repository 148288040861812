import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  min-height: 46px;
  width: 100%;
  margin-bottom: 16px;

  > div + div {
    margin-left: 16px;
  }
`;

export default Row;
