import { useTheme } from '@mui/material';
import noop from 'lodash-es/noop';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor, Editor, Location, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, RenderLeafProps, Slate, withReact } from 'slate-react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { useToggle } from 'src/hooks/useToggle';
import { IModsAndClicksStructure } from 'src/services/gql/models/run';
import { isNotEmpty } from 'src/utils/ui';

import { Element, Leaf, TRenderElementPropsWithMod } from './components/Element';
import useResetEditorState from './useResetEditorState';

import { TCustomElement, ENodeType, TModElement } from '../custom-type';
import { ModsDropdown, StyledError, StyledMenuItem } from '../styled';
import { getAmountOfMods, getSlateState, getValidInsertedMods, serialize } from '../utils';

const StyledMenuGroupItem = styled(StyledMenuItem)`
  padding-left: 5px;
  font-weight: bold;
  pointer-events: none;
`;

const ModsLimitError = styled(StyledError)`
  word-break: break-word;
`;

export interface IInlineEditorApi {
  endModInput: () => void;
  hasEditorClicked: boolean;
  startModInput: (params?: { at?: Location; startingMod?: string }) => void;
}

const withSequenceElements = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  const INLINE_AND_VOID_TYPES: string[] = [ENodeType.MOD, ENodeType.ERROR, ENodeType.WARNING];

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => {
    return INLINE_AND_VOID_TYPES.includes(element.type) || isInline(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) => {
    return INLINE_AND_VOID_TYPES.includes(element.type) || isVoid(element);
  };

  return editor;
};

function getModNode(mod: string): TModElement {
  return {
    bgColor: '#BBDEFB',
    children: [{ text: '' }],
    chunk: mod,
    message: mod,
    type: ENodeType.MOD,
  };
}

const insertMod = (editor: Editor, chunk: string, at?: Location) => {
  Transforms.insertNodes(editor, getModNode(chunk), { at });
  Transforms.move(editor);
};

function getModes(modsAndClicksStructure: IModsAndClicksStructure | undefined, search: string, insertedMods: string[]) {
  if (!modsAndClicksStructure?.modsAndClicks || !modsAndClicksStructure.validClickPairs) {
    return {
      filteredMods: [],
      suggestions: [],
    };
  }

  const suggestedQuenchers = insertedMods
    .map((x) => modsAndClicksStructure?.validClickPairs?.filter((p) => p.fluorophore === x).map((p) => p?.quencher))
    .flat();

  const suggestedFluorophores = insertedMods
    .map((x) => modsAndClicksStructure?.validClickPairs?.filter((p) => p.quencher === x).map((p) => p?.fluorophore))
    .flat();

  const suggestions = Array.from(new Set([...suggestedQuenchers, ...suggestedFluorophores].filter(isNotEmpty)));

  const filteredMods =
    modsAndClicksStructure.modsAndClicks
      .filter(isNotEmpty)
      .filter((c) => !search || c.toLowerCase().includes(search.toLowerCase()))
      .sort((a) => (suggestions.includes(a) ? -1 : 1)) || [];

  return {
    filteredMods,
    suggestions,
  };
}
export type TSequenceAcceptedByInlineEditor = Pick<
  IPartialSequence,
  'id' | 'well' | 'nucWarnings' | 'nucErrors' | 'data'
>;

export type TSequenceForInlineEditor = Pick<
  IPartialSequence,
  'status' | 'dataChunks' | 'nucErrors' | 'nucWarnings' | 'data'
>;

export interface IInlineEditorProps {
  maxAllowedMods?: number;
  modsAndClicksStructure?: IModsAndClicksStructure;
  onChange?: (value: string) => void;
  onEditorReady?: (params: IInlineEditorApi) => void;
  readOnly?: boolean;
  sequence?: TSequenceForInlineEditor;
  shouldFocus?: boolean;
}

export const InlineEditor = ({
  maxAllowedMods = 0,
  modsAndClicksStructure,
  onChange = noop,
  onEditorReady = noop,
  readOnly = false,
  sequence,
  shouldFocus = false,
}: IInlineEditorProps) => {
  const theme = useTheme();
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search] = useState('');
  const renderElement = (props: TRenderElementPropsWithMod<TCustomElement>) => <Element {...props} />;
  const editor = useMemo(() => withSequenceElements(withReact(withHistory(createEditor()))), []);

  const mods = useMemo(
    () => modsAndClicksStructure?.modsAndClicks?.filter(isNotEmpty) || undefined,
    [modsAndClicksStructure],
  );

  const { filteredMods, suggestions } = getModes(modsAndClicksStructure, search, getValidInsertedMods(editor.children));

  const slateState = useMemo(
    () => getSlateState({ chunks: sequence?.dataChunks ?? [], mods, sequence, theme }),
    [sequence, mods, theme],
  );

  useResetEditorState(editor, slateState);

  useEffect(() => {
    if (shouldFocus && editor) {
      ReactEditor.focus(editor);
    }
  }, [editor, shouldFocus]);

  const { isOpen, open, close } = useToggle();

  const [isReplacement, setIsReplacement] = useState(false);

  const [insertPosition, setInsertPosition] = useState<Location | undefined>(undefined);

  const startModInput = useCallback(
    (params?: { at?: Location; startingMod?: string }) => {
      if (mods && params?.startingMod) {
        setIndex(mods.indexOf(params.startingMod));
        setIsReplacement(true);
      }
      setInsertPosition(params?.at);
      open();
    },
    [open, setIsReplacement, setIndex, mods, setInsertPosition],
  );

  const endModInput = useCallback(() => {
    setInsertPosition(undefined);
    setIsReplacement(false);
    setIndex(0);
    close();
  }, [close, setIsReplacement, setIndex, setInsertPosition]);

  const onModSelection = useCallback(
    (mod?: string) => {
      const position = editor.selection?.anchor.path.slice(0, 2);
      if (isReplacement) {
        Transforms.setNodes(editor, getModNode(mod || filteredMods[index]), { at: position });
      } else {
        if (target) {
          Transforms.select(editor, target);
          setTarget(undefined);
        }
        insertMod(editor, mod || filteredMods[index], insertPosition);
      }
      endModInput();
    },
    [index, target, endModInput, isReplacement, editor, filteredMods, insertPosition],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { selection } = editor;
      const anchorPath = selection ? selection.anchor.path : null;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const selected: TCustomElement | null =
        selection !== null && selection.anchor !== null && anchorPath && anchorPath.length >= 2
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Problem in custom-type.ts
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            editor.children[anchorPath[0]]?.children?.[anchorPath[1]]
          : null;

      if (isOpen) {
        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault();
            // TODO: replace with %
            const prevIndex = index >= filteredMods.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          }
          case 'ArrowUp': {
            event.preventDefault();
            // TODO: replace with %
            const nextIndex = index <= 0 ? filteredMods.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          }
          case 'Tab':
          case 'Enter': {
            event.preventDefault();
            onModSelection();
            break;
          }
          case 'Escape': {
            event.preventDefault();
            setTarget(undefined);
            endModInput();
            break;
          }
          default: {
            event.preventDefault();
          }
        }
        return;
      }
      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          if (selected?.type === ENodeType.MOD) {
            startModInput({
              startingMod: selected.chunk,
            });
          }
          break;
        }
        // NOTE: we don't allow newlines in input
        case 'Enter': {
          event.preventDefault();
          break;
        }
        // NOTE: slash triggers the mods input only
        case '/': {
          event.preventDefault();
          if (mods && mods.length > 0) {
            startModInput();
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [editor, isOpen, index, filteredMods.length, onModSelection, endModInput, startModInput, mods],
  );

  const [hasEditorClicked, setHasEditorClicked] = useState(false);

  const onClick = useCallback(() => {
    setHasEditorClicked(true);
  }, [setHasEditorClicked]);

  useEffect(() => {
    onEditorReady({ endModInput, hasEditorClicked, startModInput });
  }, [onEditorReady, startModInput, endModInput, hasEditorClicked]);

  const hasMaxModsExceeded = getAmountOfMods(editor.children) >= maxAllowedMods;

  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

  return (
    <Slate
      editor={editor}
      value={[
        {
          children: slateState.length > 0 ? slateState : [{ text: '' }],
          type: 'paragraph',
        },
      ]}
      onChange={(value) => {
        const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
        if (isAstChange) {
          onChange(serialize(value));
        }
      }}
    >
      <Editable
        readOnly={readOnly}
        renderElement={(props) => renderElement({ ...props, startModInput })}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        placeholder=""
        onClick={onClick}
      />
      {mods === undefined && isOpen && (
        <ModsLimitError>
          <Translate id="runs.plateEditor.editWindow.sequenceField.error.modsNotAllowed" />
        </ModsLimitError>
      )}
      {mods !== undefined &&
        (hasMaxModsExceeded && !isReplacement ? (
          isOpen && (
            <ModsLimitError>
              <Translate
                id="runs.plateEditor.editWindow.sequenceField.error.maxMods"
                data={{
                  maxMods: maxAllowedMods,
                }}
              />
            </ModsLimitError>
          )
        ) : (
          <ModsDropdown visible={isOpen}>
            <ul>
              {filteredMods.length > 0 ? (
                filteredMods.map((mod, i) => {
                  const modElement = (
                    <StyledMenuItem
                      key={mod}
                      style={{
                        background: i === index ? '#B4D5FF' : 'transparent',
                        padding: '2px 10px',
                      }}
                      onClick={() => onModSelection(mod)}
                    >
                      {mod}
                    </StyledMenuItem>
                  );
                  if (suggestions.includes(mod) && i === 0) {
                    return [<StyledMenuGroupItem key="suggested">Suggested</StyledMenuGroupItem>, modElement];
                  }
                  if (!suggestions.includes(mod) && i > 0 && suggestions.includes(filteredMods[i - 1])) {
                    return [<StyledMenuGroupItem key="other">Other</StyledMenuGroupItem>, modElement];
                  }
                  return modElement;
                })
              ) : (
                <StyledMenuItem>
                  <Translate id="runs.plateEditor.editWindow.sequenceField.error.notFound" />
                </StyledMenuItem>
              )}
            </ul>
          </ModsDropdown>
        ))}
    </Slate>
  );
};
