import { useState, useCallback } from 'react';

import Storage from 'src/utils/storage';

export function useTabs<T>(defaultTab: T, type?: string) {
  const [tab, setTab] = useState<T>(() => {
    if (type) {
      return Storage.SESSION.get<T>(type) || defaultTab;
    }
    return defaultTab;
  });
  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, newValue: T) => {
      if (type) {
        Storage.SESSION.set(type, newValue);
      }

      setTab(newValue);
    },
    [setTab, type],
  );

  return {
    handleChangeTab,
    setTab,
    tab,
  };
}
