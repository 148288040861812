import { Button } from '@dna-script-inc/shared-ui-library';
import UploadIcon from '@mui/icons-material/Backup';
import { Box, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import styled from 'styled-components';

import { Translate } from 'src/containers/i18n';
import { TTranslationKeys } from 'src/i18n';

const StyledUploadIcon = styled(UploadIcon)`
  font-size: 35px;
  color: RGBA(0, 0, 0, 0.87);
  margin-right: 12px;
`;

export interface IUploadingAreaProps {
  buttonTitleTranslationId: TTranslationKeys;
  descriptionTranslationId: TTranslationKeys;
  disabled?: boolean;
  titleTranslationId: TTranslationKeys;
}

export const DEFAULT_UPLOADING_AREA_ACCEPT = ['.csv', 'application/json', '.xls', '.xlsx'];

export const UploadingArea: React.FC<IUploadingAreaProps> = ({
  titleTranslationId,
  descriptionTranslationId,
  buttonTitleTranslationId,
  disabled,
}) => (
  <Paper
    sx={{
      padding: '24px',
    }}
  >
    <Grid container direction="column">
      <Grid item>
        <Box display="flex" flexDirection="row" alignItems="center">
          <StyledUploadIcon />
          <Typography variant="h4">
            <Translate id={titleTranslationId} />
          </Typography>
        </Box>
      </Grid>
      <Grid sx={{ paddingTop: '8px' }} item>
        <Typography>
          <Translate id={descriptionTranslationId} />
        </Typography>
      </Grid>
      <Grid sx={{ paddingTop: '8px' }} item>
        <Button variant="secondary" disabled={disabled} uppercase>
          <Translate id={buttonTitleTranslationId} />
        </Button>
      </Grid>
    </Grid>
  </Paper>
);
