import { GetActiveModsAndClicksQuery, RunStatus } from 'src/gql/graphql';
import { TNonNullableArrayItem } from 'src/types';

type TGetActiveModsAndClicks = NonNullable<GetActiveModsAndClicksQuery['getActiveModsAndClicks']>;

export const ERROR_STATUSES = [RunStatus.FAILED, RunStatus.ABORTED, RunStatus.DELETED];
export const PROGRESS_STATUSES = [RunStatus.IN_QUEUE, RunStatus.IN_PROGRESS];

export interface IModsAndClicksStructure extends Pick<TGetActiveModsAndClicks, 'modsAndClicks'> {
  validClickPairs: Omit<TNonNullableArrayItem<TGetActiveModsAndClicks['validClickPairs']>, '__typename'>[] | null;
}
