import { Tooltip } from '@dna-script-inc/shared-ui-library';
import Typography from '@mui/material/Typography';
import uniq from 'lodash-es/uniq';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';

import { getCreatorDisplayName } from '../runsTable/helpers';

export type TCreator =
  | {
      firstName?: string | null;
      lastName?: string | null;
    }
  | null
  | undefined;

export const getUniqueCreatorsFromList = (data: { creator?: TCreator }[]) =>
  uniq(
    data
      .map((r) => r.creator)
      .map(getCreatorDisplayName)
      .sort(),
  );

function useGetCreatorColumn<
  T extends {
    creator?: TCreator;
  },
>(creators: string[]): MRT_ColumnDef<T> {
  const t = useTranslation();

  return {
    Cell: ({ renderedCellValue }) => (
      <Tooltip placement="bottom-start" title={renderedCellValue}>
        <Typography overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <span>{renderedCellValue}</span>
        </Typography>
      </Tooltip>
    ),
    Header: () => <Translate id="projects.detail.table.runs.creator" testid="creator-header" />,
    accessorFn: (row) => getCreatorDisplayName(row.creator),
    enableGlobalFilter: true,
    filterSelectOptions: creators.map((creator) => ({ text: creator, value: creator })),
    filterVariant: 'multi-select',
    header: t('projects.detail.table.runs.creator'),
    muiTableHeadCellFilterTextFieldProps: { placeholder: t('projects.detail.table.runs.creator.filter') },
  };
}

export default useGetCreatorColumn;
