import { useQuery } from '@apollo/client';
import { ErrorModal } from '@dna-script-inc/shared-ui-library';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ProjectType, Status } from '__generated__/globalTypes';
import MRTable from 'src/components/table/MRTable';
import { getUniqueCreatorsFromList } from 'src/components/table/columns/useGetCreatorColumn';
import { TableWrapper } from 'src/components/table/components/styled';
import { ERoutes } from 'src/config/routes';
import { useTranslation, Translate } from 'src/containers/i18n';
import { MyProjectsListQuery, ProjectsListQuery } from 'src/gql/graphql';
import { useUserContext } from 'src/hooks';
import { useToggle } from 'src/hooks/useToggle';
import { ETab } from 'src/pages/projects/pages/list/ProjectsListPage';
import { QUERY_PROJECTS_LIST, QUERY_MY_PROJECTS_LIST } from 'src/services/gql/queries/projects';
import { QUERY_USERS_SHORT_LIST } from 'src/services/gql/queries/user';
import { isNotEmpty } from 'src/utils/ui';

import ProjectActions from './ProjectActions';
import { IProjectWithUsers } from './types';
import useGetProjectsColumns from './useGetProjectsColumns';

function getDataProjects(data: MyProjectsListQuery | ProjectsListQuery) {
  if ('getAllProjects' in data) {
    return data.getAllProjects?.data ?? [];
  }
  if ('getMyProjects' in data) {
    return data.getMyProjects?.data ?? [];
  }
  return [];
}

interface ITableProps {
  isDesignProjects?: boolean;
  status: ETab;
}

const ProjectsListTable = ({ status, isDesignProjects }: ITableProps) => {
  const { isOpen, close, open: handleArchivedProject } = useToggle(false);
  const t = useTranslation();
  const requestStatus = status === 'archived' ? Status.DELETED : Status.ACTIVE;
  const isMyProjects = status === 'my';
  const isArchivedProjects = status === 'archived';
  const {
    userPermissions: {
      project: { canViewProjectDetails, canSearchProject, canSortProjects },
    },
  } = useUserContext();
  const history = useHistory();

  const { data: usersData, loading: loadingUsers } = useQuery(QUERY_USERS_SHORT_LIST);
  const allUsers = useMemo(() => (usersData?.getUsers?.data || []) as Partial<IUser>[], [usersData?.getUsers?.data]);
  const {
    data,
    loading,
    refetch: refetchProjects,
  } = useQuery<MyProjectsListQuery | ProjectsListQuery>(isMyProjects ? QUERY_MY_PROJECTS_LIST : QUERY_PROJECTS_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      projectType: isDesignProjects ? ProjectType.GENE : ProjectType.SYNTAX,
      status: requestStatus,
    },
  });

  const projects: IProjectWithUsers[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const dataProjects = getDataProjects(data).filter(isNotEmpty);

    return dataProjects.map((p) => ({ ...p, allUsers }));
  }, [data, allUsers]);

  const creators = getUniqueCreatorsFromList(projects);
  const columns = useGetProjectsColumns(creators, status);

  const refetch = () => {
    refetchProjects();
  };

  return (
    <TableWrapper>
      <MRTable
        columns={columns}
        data={projects}
        emptyDataId="projects.noData"
        enableFilters={canSearchProject}
        enableGlobalFilter={canSearchProject}
        enableRowActions={isArchivedProjects}
        enableSorting={canSortProjects}
        initialState={{ sorting: [{ desc: true, id: 'updatedAt' }] }}
        loading={loading || loadingUsers}
        muiTableBodyRowProps={({ row: { original: project } }) => ({
          onClick: () => {
            if (!project.id) {
              return;
            }
            if (status === ETab.ARCHIVED) {
              handleArchivedProject();
              return;
            }
            if (isDesignProjects) {
              history.push(ERoutes.APPLICATIONS__PROJECTS__DESIGN__DETAILS__id.replace(':id', project.id));
              return;
            }
            if (canViewProjectDetails) {
              history.push(ERoutes.APPLICATIONS__PROJECTS__DETAILS__id.replace(':id', project.id));
            }
          },
          sx: {
            cursor: 'pointer',
          },
        })}
        refetch={refetch}
        renderRowActions={({ row }) => <ProjectActions refetch={refetch} projectId={row.original.id} />}
        searchPlaceholder={t('projects.table.searchPlaceholder')}
        tableId="projects"
      />

      <ErrorModal
        title={t('projects.table.errors.incomplete.title')}
        isOpen={isOpen}
        onReject={close}
        rejectButtonTitle={t('common.button.close')}
      >
        <Translate id="projects.table.errors.incomplete.body" />
      </ErrorModal>
    </TableWrapper>
  );
};

export default ProjectsListTable;
