import { Button } from '@dna-script-inc/shared-ui-library';
import CreateIcon from '@mui/icons-material/Create';
import { Box, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import styled from 'styled-components';

import { Translate, useTranslation } from 'src/containers/i18n';

const StyledUploadIcon = styled(CreateIcon)`
  font-size: 35px;
  color: RGBA(0, 0, 0, 0.87);
  margin-right: 12px;
`;

interface IManualTextAreaProps {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  openAddSequenceModal: () => void;
}

const ManualTextArea = ({ openAddSequenceModal, disabled = false }: IManualTextAreaProps) => {
  const t = useTranslation();

  return (
    <Paper>
      <Grid container direction="column" sx={{ padding: '24px' }}>
        <Grid item>
          <Box display="flex" flexDirection="row" alignItems="center">
            <StyledUploadIcon />
            <Typography variant="h4">
              <Translate id="runs.create.uploadFiles.manualTextArea.title" />
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ paddingTop: '8px' }}>
          <Typography whiteSpace="pre-wrap">
            <span>
              {t('runs.create.uploadFiles.manualTextArea.description1')}
              <br />
              {t('runs.create.uploadFiles.manualTextArea.description2')}
              <br />
              {t('runs.create.uploadFiles.manualTextArea.description3')}
              <br />
              {t('runs.create.uploadFiles.manualTextArea.description4')}
            </span>
          </Typography>
        </Grid>
        <Grid item sx={{ paddingTop: '8px' }}>
          <Button id="btnAddSequences" variant="secondary" uppercase onClick={openAddSequenceModal} disabled={disabled}>
            <Translate id="runs.create.uploadFiles.manualTextArea.button" />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManualTextArea;
