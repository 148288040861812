import { OperationVariables, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import { QUERY_ALL_CUSTOM_PROCESS_DEFINITION_FILES } from 'src/services/gql/queries/customProcessDefinitionFiles';
import { TCustomProcessDTO } from 'src/types';
import { isNotEmpty } from 'src/utils/ui';

import useUserContext from './useUserContext';

export function useGetCustomProcessDefinitionFiles(options?: Partial<OperationVariables>) {
  const { organization } = useUserContext();
  const [getAllCustomProcesses, { refetch, data, loading }] = useLazyQuery(
    QUERY_ALL_CUSTOM_PROCESS_DEFINITION_FILES,
    options,
  );

  useEffect(() => {
    if (organization?.customProcessEnabled) {
      getAllCustomProcesses();
    }
  }, [getAllCustomProcesses, organization?.customProcessEnabled]);

  const customProcessDefinitionFiles: TCustomProcessDTO[] = data?.getAllCustomProcesses?.data?.filter(isNotEmpty) || [];

  return {
    customProcessDefinitionFiles,
    loading,
    refetch,
  };
}
