import { useLazyQuery, useQuery } from '@apollo/client';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { EVENT_SESSION_EXPIRED } from 'src/config/events';
import { ERoutes } from 'src/config/routes';
import { GET_ORGANIZATION_DATA } from 'src/services/gql/queries/organization';
import { QUERY_SUPPORTED_INSTRUMENT_VERSIONS } from 'src/services/gql/queries/service';
import {
  GET_ALL_KIT_TYPES_INFO,
  GET_SUPPORTED_IDNA_KIT_TYPES,
  GET_SUPPORTED_KIT_TYPES,
} from 'src/services/gql/queries/templates';
import { QUERY_CURRENT_USER } from 'src/services/gql/queries/user';
import Events from 'src/utils/events';
import { getUserPermissions } from 'src/utils/permissions/general';
import { PLATE_SIZES } from 'src/utils/runs';
import { isNotEmpty } from 'src/utils/ui';

import UserContext, { IUserContext } from './UserContext';
import { transformToOrganizationAccount } from './utils';

import { InstrumentVersion, PlateType } from '../../gql/graphql';

interface IAuthContainerProps {
  onLogin?: (userId: string) => void;
  onLogout?: () => void;
}

const UserProvider = ({ children, onLogout, onLogin }: React.PropsWithChildren<IAuthContainerProps>) => {
  const [profile, setProfile] = useState<IUser | null | undefined>(undefined);
  const [organization, setOrganization] = useState<IOrganizationAccount | null | undefined>(undefined);
  const history = useHistory();

  const { data: currentUserData, loading: currentUserLoading } = useQuery(QUERY_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });
  const [getOrganization, { loading: organizationLoading }] = useLazyQuery(GET_ORGANIZATION_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setOrganization(transformToOrganizationAccount(data.getOrganizationData));
    },
  });

  const [getSupportedKitTypes, { data: dataKitTypes }] = useLazyQuery(GET_SUPPORTED_KIT_TYPES, {
    fetchPolicy: 'network-only',
  });
  const [getSupportedIDNAKitTypes, { data: dataIDNAKitTypes }] = useLazyQuery(GET_SUPPORTED_IDNA_KIT_TYPES, {
    fetchPolicy: 'network-only',
  });
  const [getAllKitTypesInfo, { data: dataAllKitTypesInfo }] = useLazyQuery(GET_ALL_KIT_TYPES_INFO, {
    fetchPolicy: 'no-cache',
  });

  const [getSupportedInstrumentVersions, { data: dataInstrumentVersions }] = useLazyQuery(
    QUERY_SUPPORTED_INSTRUMENT_VERSIONS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const initializeOrganizationData = useCallback(async () => {
    return Promise.all([
      getOrganization(),
      getSupportedKitTypes(),
      getSupportedIDNAKitTypes(),
      getAllKitTypesInfo(),
      getSupportedInstrumentVersions(),
    ]);
  }, [
    getAllKitTypesInfo,
    getOrganization,
    getSupportedInstrumentVersions,
    getSupportedKitTypes,
    getSupportedIDNAKitTypes,
  ]);

  const login = useCallback(
    (user: IUser) => {
      if (onLogin) {
        onLogin(user.id);
      }
      setProfile(user);
      initializeOrganizationData();
    },
    [initializeOrganizationData, onLogin],
  );

  useEffect(() => {
    if (currentUserData?.getCurrentUser) {
      login(currentUserData.getCurrentUser as IUser);
      getOrganization();
    }
  }, [currentUserData, getOrganization, login]);

  const logout = useCallback(() => {
    setProfile(null);
    if (onLogout) {
      onLogout();
    }
  }, [setProfile, onLogout]);

  const update = useCallback(
    (profileUpdate: Partial<IUser>) => {
      setProfile((prevProfile) => {
        return {
          ...prevProfile,
          ...profileUpdate,
        } as IUser;
      });
    },
    [setProfile],
  );

  const updateOrganization = useCallback(
    (organizationUpdate: IOrganizationAccount) => {
      setOrganization((prevOrganization) => {
        return {
          ...prevOrganization,
          ...organizationUpdate,
        };
      });
    },
    [setOrganization],
  );

  const isAdmin = useCallback(() => {
    return profile?.role.type?.toLowerCase() === 'admin';
  }, [profile]);

  useEffect(() => {
    Events.on(EVENT_SESSION_EXPIRED, logout);
    return () => {
      Events.off(EVENT_SESSION_EXPIRED, logout);
    };
  }, [logout]);

  const hasValidLicense = useCallback(() => {
    return Boolean(profile?.validLicense);
  }, [profile]);

  const hasPrintingEnabled = useCallback(() => {
    return Boolean(organization?.printingEnabled);
  }, [organization]);

  const isCloud = useCallback(() => {
    return Boolean(profile?.cloud);
  }, [profile]);

  const supportedPlateTypes = useMemo(
    () => dataKitTypes?.getSupportedKitTypes?.map((x) => x?.plateType).filter(isNotEmpty) || null,
    [dataKitTypes],
  );

  const supportedKitTypes = useMemo(() => {
    return (dataKitTypes?.getSupportedKitTypes || []).map((k) => {
      if (k?.plateType === PlateType.PLATE_96) {
        return {
          kitInfo: (k.kitInfo ?? []).filter((kit) => !['B3_96', 'B4_96'].includes(kit?.kitType ?? '')),
          plateType: PlateType.PLATE_96,
        };
      }
      return k;
    });
  }, [dataKitTypes]);

  const supportedKits = supportedKitTypes
    ?.map((x) => x?.kitInfo)
    .flat()
    .filter(isNotEmpty);

  const allowedPlateTypes = useMemo(() => {
    if (!supportedPlateTypes) {
      return PLATE_SIZES;
    }
    return PLATE_SIZES.map((option) => ({ ...option, disabled: !supportedPlateTypes.includes(option.value) }));
  }, [supportedPlateTypes]);

  const supportedIDNAKitTypes = useMemo(() => {
    return dataIDNAKitTypes?.getSupportedIDNAKitTypes || [];
  }, [dataIDNAKitTypes]);

  const allKitTypesInfo = useMemo(
    () => dataAllKitTypesInfo?.getAllKitTypesInfo?.filter(isNotEmpty) || [],
    [dataAllKitTypesInfo?.getAllKitTypesInfo],
  );

  const supportedInstrumentVersions = useMemo(() => {
    const getLabelFromVersion = (version: InstrumentVersion): string => {
      const re = /^V_\d+/;
      const regularExpressionResult = re.exec(version);
      if (!regularExpressionResult) {
        return '';
      }
      const majorVersionNumber = regularExpressionResult[0].replace('V_', '');
      return `${majorVersionNumber}.0+`;
    };
    const supportedVersions = dataInstrumentVersions?.getSupportedInstrumentVersions ?? [];
    return Object.fromEntries(
      supportedVersions.map((version) => [version, { label: getLabelFromVersion(version), version }]),
    ) as Record<InstrumentVersion, { label: string; version: InstrumentVersion }>;
  }, [dataInstrumentVersions]);

  useEffect(() => {
    if (history && profile?.changePasswordAfterFirstLogin) {
      history.push(ERoutes.PROFILE);
    }
  }, [history, profile]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context: IUserContext = {
    allKitTypesInfo,
    allowedPlateTypes,
    hasPrintingEnabled,
    hasValidLicense,
    isCloud,
    latestOnPremiseLicense: organization?.license,
    organization,
    supportedIDNAKitTypes,
    supportedInstrumentVersions,
    supportedKitTypes,
    supportedKits,
    supportedPlateTypes,
    updateOrganization,
    userIsAdmin: isAdmin,
    userLoading: currentUserLoading,
    userLogin: login,
    userLogout: logout,
    userOrOrganizationLoading: currentUserLoading || !organization || organizationLoading,
    userPermissions: getUserPermissions(profile, organization),
    userProfile: profile,
    userUpdateProfile: update,
  };

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};
export { UserProvider };

export default UserContext.Consumer;
