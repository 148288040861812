export const REAGENT_CODE_SYNTH = 'SYNTH';
export const REAGENT_CODE_PSP = 'PSP';
export const REAGENT_CODE_NUCLEOTIDES = 'NUCLEOTIDES';
export const REAGENT_CODE_W1 = 'W1';
export const REAGENT_CODE_A = 'dA';
export const REAGENT_CODE_C = 'dC';
export const REAGENT_CODE_G = 'dG';
export const REAGENT_CODE_T = 'dT';
export const REAGENT_CODE_DES = 'DES';
export const REAGENT_CODE_EB = 'EB';
export const REAGENT_CODE_PLT = 'PLT';

export const REAGENTS_COLORS = {
  [REAGENT_CODE_A]: ['#cbead4', '#49b867'],
  [REAGENT_CODE_C]: ['#cbead4', '#49b867'],
  [REAGENT_CODE_DES]: ['#d1dadd', '#607d8c'],
  [REAGENT_CODE_EB]: ['#d0d5ee', '#5b6bc2'],
  [REAGENT_CODE_G]: ['#ffefd4', '#ffcc6c'],
  [REAGENT_CODE_NUCLEOTIDES]: ['#b8d5ea', '#0168b4'],
  [REAGENT_CODE_PLT]: [],
  [REAGENT_CODE_PSP]: ['#cbead4', '#49b867'],
  [REAGENT_CODE_SYNTH]: ['#fed2b3', '#fa6301'],
  [REAGENT_CODE_T]: ['#c0c5fb', '#4b5df8'],
  [REAGENT_CODE_W1]: ['#e3e3c0', '#a09f20'],
};
