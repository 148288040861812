import { FormattedTime, DF } from '@dna-script-inc/shared-ui-library';
import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';

function useGetEndDateColumn<
  T extends {
    endDate?: string | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();
  const { userProfile } = useUserContext();

  return {
    Cell: ({ row }) => (
      <FormattedTime
        date={row.original.endDate ?? ''}
        format={DF.SHORT_DATE_TIME_FORMAT}
        timeZone={userProfile?.timeZone?.id}
      />
    ),
    Header: () => <Translate id="projects.detail.table.runs.dateCompleted" testid="date-completed-header" />,
    accessorFn: (row) => row.endDate,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: t('projects.detail.table.runs.dateCompleted'),
    id: 'endDate',
  };
}

export default useGetEndDateColumn;
