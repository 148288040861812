import React from 'react';

import RunContainer, { RunProvider } from './RunContainer';
import RunContext, { IRunContext } from './RunContext';

export { RunProvider };

export function useRunContext() {
  return React.useContext<IRunContext>(RunContext);
}

export default RunContainer;
