const API_ROOT = '/api';

const ENDPOINTS = {
  Download: '/download/content/:fileName',
  DownloadInstructions: '/gene-assembly/download/instruction-set',
  DownloadReport: '/download/report/content/:fileName',
  FastaUpload: '/fasta/upload',
  Flags: '/flags',
  GetDownloadFiles: '/get-download-files',
  GetFiles: '/get-files',

  Upload: '/upload',
  UploadMultiple: '/upload-multiple-files',

  User: {
    Profile: '/user/profile',
  },
};

export default {
  API_ROOT,
  ENDPOINTS,
};
