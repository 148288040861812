/* eslint-disable max-len */
import { useMutation } from '@apollo/client';
import { Button, DeleteModal } from '@dna-script-inc/shared-ui-library';
import MoreVert from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { DeleteMenuItem } from 'src/components/DeleteMenuItem';
import { Translate, useTranslation } from 'src/containers/i18n';
import { useToast } from 'src/containers/toast';
import { RunStatus } from 'src/gql/graphql';
import useUserContext from 'src/hooks/useUserContext';
import { MUTATION_DELETE_RUN } from 'src/services/gql/mutations/runs';
import { formatErrorMessage, getDownloadLink, getPrintJobPdfDetails, handlePrivateDownload } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/errors';
import { stopPropagationClick } from 'src/utils/ui';

export const isRunCompleted = (status: string | null | undefined) => {
  return status === RunStatus.COMPLETED || status === RunStatus.PROCESS_COMPLETED;
};

const ActionsWrapper = styled.div`
  display: flex;
`;

interface IRunActionsProps {
  refetch: () => Promise<unknown>;
  run: IProjectRun;
}

export const RunActions = ({ run, refetch }: IRunActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRun, setCurrentRun] = useState<null | IProjectRun>(null);
  const [hasDeleteConfirmationOpened, setHasDeleteConfirmationOpened] = useState(false);
  const showToast = useToast();
  const t = useTranslation();
  const handleDeleteConfirmationClose = useCallback(() => {
    setHasDeleteConfirmationOpened(false);
    setCurrentRun(null);
  }, [setCurrentRun, setHasDeleteConfirmationOpened]);

  const [deleteRun, { loading }] = useMutation(MUTATION_DELETE_RUN, {
    onError: (err) => {
      showToast({
        isError: true,
        text: getErrorMessage(err),
      });
      handleDeleteConfirmationClose();
    },
  });
  const {
    userProfile,
    userPermissions: {
      plateEditor,
      runReports: { canDownloadReport, canDelete, canDownloadItemList },
    },
  } = useUserContext();

  const handleMoreClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, selectedRun: IProjectRun) => {
      setCurrentRun(selectedRun);
      setAnchorEl(event.currentTarget);
    },
    [setCurrentRun, setAnchorEl],
  );

  const getPrivateDownloadFile = (link: string) => {
    fetch(link)
      .then((res) => {
        if (res?.url) {
          handlePrivateDownload(res.url);
        }
      })
      .catch((err: string) => {
        showToast({
          isError: true,
          text: formatErrorMessage(err),
        });
      });
  };

  const handleDownload = (projectRun: IProjectRun) => {
    if (projectRun?.metaLink) {
      getPrivateDownloadFile(projectRun.metaLink);
    }
  };

  const handleCsvDownload = (projectRun: IProjectRun) => {
    if (projectRun?.reportMetaLink) {
      const url = getDownloadLink(projectRun.reportMetaLink);
      window.open(url);
    }
  };

  const handleSynthesisCsvDownload = (projectRun: IProjectRun) => {
    if (projectRun?.reportMetaLink) {
      const url = `${getDownloadLink(projectRun.reportMetaLink)}synthesis`;
      window.open(url);
    }
  };

  const handleDelete = useCallback(async () => {
    if (currentRun) {
      await deleteRun({
        variables: {
          id: currentRun.id,
        },
      });
    }
    await refetch();
    handleDeleteConfirmationClose();
    return Promise.resolve();
  }, [currentRun, refetch, handleDeleteConfirmationClose, deleteRun]);

  const isOwner = useMemo(() => {
    return run.creator?.id === userProfile?.id;
  }, [run, userProfile]);

  const hasMenuOptions =
    (
      [
        RunStatus.IN_QUEUE,
        RunStatus.COMPLETED,
        RunStatus.PROCESS_COMPLETED,
        RunStatus.FAILED,
        RunStatus.IN_PROGRESS,
      ] as string[]
    ).includes(run?.status) ||
    (run?.metaLink && canDownloadReport) ||
    (run?.reportMetaLink && canDownloadReport);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <ActionsWrapper onClick={stopPropagationClick}>
        {hasMenuOptions && (
          <Button
            variant="tertiary-gray"
            className="test-dropdownIBtn"
            disabled={!(plateEditor.canEditPlate || isOwner || canDownloadItemList)}
            onClick={(e) => handleMoreClick(e, run)}
            size="sm"
          >
            <MoreVert />
          </Button>
        )}
      </ActionsWrapper>
      <Menu
        anchorEl={anchorEl}
        id="runOptions"
        keepMounted
        onClick={stopPropagationClick}
        onClose={handleClose}
        open={!!anchorEl}
      >
        {/* <MenuItem>
                  <Translate id="runs.option.view" />
              </MenuItem>
              <MenuItem>
                  <Translate id="runs.option.edit" />
              </MenuItem>
              <MenuItem>
                  <Translate id="runs.option.reschedule" />
              </MenuItem>
              <MenuItem>
                  <Translate id="runs.option.duplicate" />
              </MenuItem>
              <MenuItem>
                  <Translate id="runs.option.cancel" />
              </MenuItem> */}
        {run?.metaLink && canDownloadReport && (
          <MenuItem
            disabled={!run?.metaLink}
            onClick={() => {
              handleDownload(run);
              handleClose();
            }}
          >
            <Translate id="runs.download.printJob" />
          </MenuItem>
        )}
        {run?.reportMetaLink && canDownloadReport && (
          <div>
            <MenuItem
              disabled={!run?.reportMetaLink}
              onClick={() => {
                handleCsvDownload(run);
                handleClose();
              }}
            >
              <Translate id="runs.download.runReport" />
            </MenuItem>
            <MenuItem
              disabled={!run?.reportMetaLink}
              onClick={() => {
                handleSynthesisCsvDownload(run);
                handleClose();
              }}
            >
              <Translate id="runs.download.runSynthesisReport" />
            </MenuItem>
          </div>
        )}
        <MenuItem
          disabled={!canDownloadItemList}
          onClick={() => {
            getPrintJobPdfDetails(run.id);
            handleClose();
          }}
        >
          <Translate id="template.congratulations.item.list.download" />
        </MenuItem>

        {currentRun?.status === RunStatus.IN_QUEUE && (
          <DeleteMenuItem
            disabled={!canDelete}
            onClick={(e) => {
              e.stopPropagation();
              setHasDeleteConfirmationOpened(true);
              handleClose();
            }}
          >
            <Translate id="runs.option.delete" />
          </DeleteMenuItem>
        )}
      </Menu>
      {hasDeleteConfirmationOpened && (
        <DeleteModal
          title={t('modal.delete.run.title')}
          isOpen
          onSubmit={handleDelete}
          onReject={handleDeleteConfirmationClose}
          rejectButtonTitle={t('common.button.cancel')}
          submitButtonTitle={t('modal.delete.run.confirm')}
          loading={loading}
        >
          <Translate id="modal.delete.run.note" />
        </DeleteModal>
      )}
    </>
  );
};
