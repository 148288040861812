/* eslint-disable camelcase */
import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo } from 'react';

import { Translate, useTranslation } from 'src/containers/i18n';

function useGetReleaseDateColumn<
  T extends {
    releaseDate?: string | null;
  },
>(): MRT_ColumnDef<T> {
  const t = useTranslation();

  return useMemo(
    () => ({
      Header: () => <Translate id="downloads.listPage.table.header.releaseDate" />,
      accessorFn: (row) => row.releaseDate,
      header: t('downloads.listPage.table.header.releaseDate'),
      muiTableHeadCellFilterTextFieldProps: { placeholder: t('downloads.listPage.table.header.releaseDate') },
    }),
    [t],
  );
}

export default useGetReleaseDateColumn;
