import { Button, IconFolderPlus } from '@dna-script-inc/shared-ui-library';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeading } from 'src/components/layout/PageHeading';
import { ERoutes } from 'src/config/routes';
import { Translate } from 'src/containers/i18n';
import { useUserContext } from 'src/hooks';

interface IProjectsHeaderProps {
  isDesignProjects?: boolean;
}
const ProjectsHeader: React.FC<IProjectsHeaderProps> = ({ isDesignProjects }) => {
  const {
    userPermissions: {
      project: { canCreateProject },
    },
  } = useUserContext();
  const history = useHistory();

  return (
    <PageHeading
      breadcrumbs={[
        {
          title: isDesignProjects ? 'projects.design.title' : 'projects.title',
        },
      ]}
      renderControls={() =>
        canCreateProject &&
        !isDesignProjects && (
          <Button
            startIcon={<IconFolderPlus />}
            variant="primary"
            id="newProject"
            onClick={() => {
              history.push(ERoutes.APPLICATIONS__PROJECTS__CREATE);
            }}
            size="md"
          >
            <Translate id="projects.button.new" />
          </Button>
        )
      }
      renderTitle={() => <Translate id={isDesignProjects ? 'projects.design.title' : 'projects.title'} />}
    />
  );
};

export default ProjectsHeader;
